import * as yup from 'yup'
import { REQUIRED_FIELD, INVALID_PERCENTAGE, NULL_PERCENTAGE, ONLY_DIGITS } from 'constants/errors'
import { integerReg, percentageReg } from 'constants/regExp'

export const assignLenderSchema = yup.object().shape({
  syndicatorId: yup.string().required(REQUIRED_FIELD),
  dealId: yup.string().required(REQUIRED_FIELD),
  lendingPercentage: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(percentageReg, INVALID_PERCENTAGE)
    .test('lendingPercentage', NULL_PERCENTAGE, (val) => {
      if (!val) return false
      return !(Number(val) === 0)
    }),
  lendingAmount: yup.string().required(REQUIRED_FIELD).matches(integerReg, ONLY_DIGITS),
  isLead: yup.boolean(),
})

import React, { FC, useCallback, useRef, useState } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useTableComponentStyle } from 'UI/Table/style'
import { IFlexDisbursement } from 'typescript/interfaces/deals'
import { convertDate, convertToPriceFormat } from 'utils/formatters'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { deleteFlexDisbursement, getFlexScheduleByDeal } from 'services/deals'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { ReactComponent as DeleteCircleIcon } from 'assets/svg/DeleteCircleIcon.svg'
import IconButton from '@material-ui/core/IconButton'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import ModalComponent from 'UI/Modal'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress } from '@material-ui/core'

const TableFlexSchedule = TableComponent as TableComponentType<IFlexDisbursement>

interface FlexScheduleTableProps {
  firstDisbursement: IFlexDisbursement
}

const FlexScheduleTable: FC<FlexScheduleTableProps> = ({ firstDisbursement }) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryCache = useQueryCache()
  const { id } = useParams<{ id: string }>()
  const classes = useTableComponentStyle()
  const { data, isLoading } = useQuery(['flexSchedule', id], () => getFlexScheduleByDeal(id), {
    retry: false,
    cacheTime: 0,
    enabled: id,
  })

  const [stateDeleteDisbursement, setStateDeleteDisbursement] = useState({
    id: 0,
    open: false,
  })

  const [deleteDisbursementMut, { isLoading: isLoadingDeleteDisburseent }] = useMutation(deleteFlexDisbursement, {
    onSuccess: () => {
      queryCache.fetchQuery(['flexSchedule', id], () => getFlexScheduleByDeal(id), {
        retry: false,
        cacheTime: 0,
        enabled: id,
      })
      setStateDeleteDisbursement({
        open: false,
        id: 0,
      })
      enqueueSnackbar('Disbursement successfully deleted')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleDeleteDisbursement = useCallback((disbursementId: number) => {
    setStateDeleteDisbursement({
      id: disbursementId,
      open: true,
    })
  }, [])

  const columnsRef = useRef<IColumn<IFlexDisbursement>[]>([
    {
      label: 'Disbursement Due Date',
      field: 'date',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertDate(rowData.date),
    },
    {
      label: 'Disbursement Amount',
      field: 'amount',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertToPriceFormat(rowData.amount),
    },
    {
      label: '',
      field: 'delete',
      render: (rowData) => (
        <IconButton
          disabled={rowData.id < 0}
          size="small"
          onClick={() => {
            handleDeleteDisbursement(rowData.id)
          }}
        >
          <DeleteCircleIcon />
        </IconButton>
      ),
    },
  ])
  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableFlexSchedule columns={columnsRef.current} data={[firstDisbursement, ...(data ? data.data : [])]} />
      )}
      <ModalComponent open={stateDeleteDisbursement.open}>
        <ConfirmationContent
          isLoading={isLoadingDeleteDisburseent}
          handleCancel={() =>
            setStateDeleteDisbursement({
              id: 0,
              open: false,
            })
          }
          handleConfirm={() => deleteDisbursementMut(stateDeleteDisbursement.id)}
          text="Are you sure you want to delete disbursement?"
        />
      </ModalComponent>
    </>
  )
}

export default FlexScheduleTable

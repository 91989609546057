import * as yup from 'yup'
import { INVALID_PRICE_FORMAT, REQUIRED_FIELD, ONLY_DIGITS } from 'constants/errors'
import { priceReg, integerReg } from 'constants/regExp'

export const schemaExtendDeal = yup.object().shape({
  type: yup.string().required(REQUIRED_FIELD),
  amount: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(priceReg, INVALID_PRICE_FORMAT)
    .matches(integerReg, ONLY_DIGITS)
    .test('isMoreThan', 'Amount more than payback amount', function (value) {
      if (!Number.isNaN(parseFloat(value || '0')) && !Number.isNaN(parseFloat(this.parent.paybackAmount))) {
        return parseFloat(value || '0') < parseFloat(this.parent.paybackAmount)
      }
      return true
    })
    .test('is-more-than', 'Negative value and zero are forbidden', (val) => {
      if (!parseFloat(val || '0')) return false
      return parseFloat(val || '0') > 0
    }),
})

import React, { FC, useMemo, useContext } from 'react'
import Box from '@material-ui/core/Box'
import DrawerMenu from 'components/Drawer'
import Container from '@material-ui/core/Container'
import { useAppStyle } from 'containers/App/style'
import Header from 'components/Header'
import { Switch, Route, Redirect, generatePath } from 'react-router-dom'
import {
  DASHBOARD_URL,
  CLIENTS_BASE_URL,
  COMPANIES_URL,
  OUTHOUSE_COMPANIES_URL,
  ISO_BASE_URL,
  SYNDICATORS_URL,
  REP_BASE_URL,
  VENDOR_BASE_URL,
  DEAL_BASE_URL,
  NOTIFICATIONS_BASE_URL,
  QUES_BASE_URL,
  ACH_DEBIT_URL,
  ACH_CREDIT_URL,
  TRANSACTIONS_URL,
  NACHA_LIST_URL,
  TODAYS_PAYMENT_REPORT_URL,
  WITHDRAWAL_URL,
  UPLOAD_USER_PAGE_DOCS,
  USER_ROLES_URL,
  USER_MANAGEMENT_URL,
  REP_OVERALL_DEALS_URL,
  REQUEST_FUNDER_INFO_URL,
  REP_PERFORMANCE_URL,
  APPROVE_SYNDICATION_LIMIT_URL,
  VIEW_CLIENT_URL,
  NEW_CLIENT_URL,
  PORTFOLIO_SUMMARY_URL,
  DEALS_LIST_URL,
  SYNDICATOR_OWN_URL,
  IMPORT_LEGAL_FEES,
  ACH_FEES_URL,
  BANKS_URL,
  HOLIDAYS_URL,
  EXPERIAN_REPORT_URL,
  PRBC_REPORT_URL,
  EQUIFAX_REPORT_URL,
  LIEN_SOLUTIONS_FILING_URL,
  ENHANCED_REPORT_URL,
} from 'constants/routes'

import DashboardPage from 'pages/Dashboard'
import ClientsContainer from 'containers/Clients'
import IsoContainer from 'containers/ISO'
import CompaniesContainer from 'containers/Companies'
import OuthouseCompaniesContainer from 'containers/OuthouseCompanies'
import SyndicatorsContainer from 'containers/Syndicators'
import RepContainer from 'containers/Rep'
import VendorsContainer from 'containers/Vendors'
import DealContainer from 'containers/Deal'
import NotificationsContainer from 'containers/Notifications'
import QuesContainer from 'containers/Ques'
import AchDebitPage from 'pages/AchDebit'
import AchCreditPage from 'pages/AchCredit'
import TransactionPage from 'pages/TransactionPage'
import NachaPage from 'pages/Nacha'
import TodaysPaymentReportPage from 'pages/TodaysPaymentReport'
import WithdrawalActivityPage from 'pages/WithdrawalActivity'
import UploadUserPageDocsPage from 'pages/UploadUserPageDocs'
import UserRolesPage from 'pages/UserRoles'
import UserManagementPage from 'pages/UserManagement'
import RepOverallDealsSummaryPage from 'pages/RepOverallDealsSummary'
import RequestFunderInfoPage from 'pages/RequestFunderInfo'
import RepPerformancePage from 'pages/RepPerformance'
import ApproveSyndicationLimitPage from 'pages/ApproveSyndicationLimit'
import ImportMassLegalFeesPage from 'pages/ImportMassLegalFees'
import { UserContext } from 'contexts/userContext'
import { usePermission } from 'hooks/usePermission'
import {
  ACH_MODULE_PERM,
  APPROVE_FUNDERS_LIMIT_PERM,
  DASHBOARD_MODULE_PERM,
  IMPORT_MASS_LEGAL_FEES_PERM,
  DEAL_MODULE_PERM,
  NACHA_LIST_PERM,
  NOTIFICATIONS_CREATE_PERM,
  NOTIFICATIONS_VIEW_PERM,
  OVERALL_DEALS_MODULE_PERM,
  PORTFOLIO_SUMMARY_PERM,
  REQUEST_FUNDER_INFO_PERM,
  TODAY_PAYMENT_REPORT_PERM,
  USER_MODULE_PERM,
  USER_ROLES_MODULE_PERM,
  BANK_MODULE_PERM,
  OUTHOUSE_COMPANY_MODULE_PERM,
  INHOUSE_COMPANY_MODULE_PERM,
  FUNDING_QUES_PERM,
  WITHDRAWAL_QUES_PERM,
  HOLIDAYS_PERM,
  INTEGRATIONS_PERM,
} from 'constants/permissions'
import PortfolioSummaryPage from 'pages/PortfolioSummary'
import AchFeesPage from 'pages/AchFees'
import BanksContainer from 'containers/Banks'
import HolidaysPage from 'pages/Holidays'
import { EquifaxReportPage } from 'pages/EquifaxReport'
import { ExperianReportPage } from 'pages/ExperianReport'
import { EnhancedReportPage } from 'pages/EnhancedReport'
import { PrbcReportPage } from 'pages/PrbcReport'

const AppContainer: FC = () => {
  const classes = useAppStyle()
  const userContext = useContext(UserContext)
  const { hasPermission } = usePermission()

  const redirectUrl = useMemo(() => {
    const { roles } = userContext.state
    if (roles.isAdmin) return DASHBOARD_URL
    else if (roles.isClient) {
      if (userContext.state.client)
        return generatePath(VIEW_CLIENT_URL, {
          id: userContext.state.client.id,
        })
      else return NEW_CLIENT_URL
    } else if (roles.isISO) {
      return generatePath(DEALS_LIST_URL, {
        type: 'submitted',
      })
    } else if (roles.isSyndicator) {
      return SYNDICATOR_OWN_URL
    } else if (roles.isRep) {
      return generatePath(DEALS_LIST_URL, {
        type: 'funded',
      })
    } else if (roles.isFunder) {
      return generatePath(DEALS_LIST_URL, {
        type: 'funded',
      })
    } else if (roles.isCollector) {
      return generatePath(DEALS_LIST_URL, {
        type: 'my-deals',
      })
    }
    return DASHBOARD_URL
  }, [userContext.state])

  return (
    <Box>
      <DrawerMenu />
      <Container
        maxWidth={false}
        classes={{
          root: classes.container,
        }}
      >
        <Header />
        <Box>
          <Switch>
            {hasPermission(HOLIDAYS_PERM) && <Route path={HOLIDAYS_URL} component={HolidaysPage} />}
            {hasPermission(IMPORT_MASS_LEGAL_FEES_PERM) && <Route path={IMPORT_LEGAL_FEES} component={ImportMassLegalFeesPage} />}
            {hasPermission(NACHA_LIST_PERM) && <Route path={NACHA_LIST_URL} component={NachaPage} />}
            <Route path={REP_PERFORMANCE_URL} component={RepPerformancePage} />
            <Route path={WITHDRAWAL_URL} component={WithdrawalActivityPage} />
            <Route path={TRANSACTIONS_URL} component={TransactionPage} />
            {/*{hasPermission(LEGAL_FEE_PERM) && <Route path={LEGAL_FEES} component={LegalFeesPage} />}*/}
            {hasPermission(TODAY_PAYMENT_REPORT_PERM) && <Route path={TODAYS_PAYMENT_REPORT_URL} component={TodaysPaymentReportPage} />}
            {hasPermission(ACH_MODULE_PERM) && <Route path={ACH_FEES_URL} component={AchFeesPage} />}
            {hasPermission(ACH_MODULE_PERM) && <Route path={ACH_CREDIT_URL} component={AchCreditPage} />}
            {hasPermission(ACH_MODULE_PERM) && <Route path={ACH_DEBIT_URL} component={AchDebitPage} />}
            <Route path={CLIENTS_BASE_URL} component={ClientsContainer} />
            {(hasPermission(INHOUSE_COMPANY_MODULE_PERM) || hasPermission(OUTHOUSE_COMPANY_MODULE_PERM)) && (
              <Route path={COMPANIES_URL} component={CompaniesContainer} />
            )}
            {hasPermission(OUTHOUSE_COMPANY_MODULE_PERM) && <Route path={OUTHOUSE_COMPANIES_URL} component={OuthouseCompaniesContainer} />}
            {hasPermission(BANK_MODULE_PERM) && <Route path={BANKS_URL} component={BanksContainer} />}
            <Route path={SYNDICATORS_URL} component={SyndicatorsContainer} />
            {hasPermission(DASHBOARD_MODULE_PERM) && <Route path={DASHBOARD_URL} component={DashboardPage} />}
            {hasPermission(DEAL_MODULE_PERM) && <Route path={DEAL_BASE_URL} component={DealContainer} />}
            <Route path={ISO_BASE_URL} component={IsoContainer} />
            <Route path={REP_BASE_URL} component={RepContainer} />
            {(hasPermission(NOTIFICATIONS_CREATE_PERM) || hasPermission(NOTIFICATIONS_VIEW_PERM)) && (
              <Route path={NOTIFICATIONS_BASE_URL} component={NotificationsContainer} />
            )}
            <Route path={VENDOR_BASE_URL} component={VendorsContainer} />
            {(hasPermission(FUNDING_QUES_PERM) || hasPermission(WITHDRAWAL_QUES_PERM)) && <Route path={QUES_BASE_URL} component={QuesContainer} />}
            <Route path={UPLOAD_USER_PAGE_DOCS} component={UploadUserPageDocsPage} />
            {hasPermission(USER_ROLES_MODULE_PERM) && <Route path={USER_ROLES_URL} component={UserRolesPage} />}
            {hasPermission(INTEGRATIONS_PERM) && <Route path={LIEN_SOLUTIONS_FILING_URL} />}
            {hasPermission(INTEGRATIONS_PERM) && <Route path={EQUIFAX_REPORT_URL} component={EquifaxReportPage} />}
            {hasPermission(INTEGRATIONS_PERM) && <Route path={EXPERIAN_REPORT_URL} component={ExperianReportPage} />}
            {hasPermission(INTEGRATIONS_PERM) && <Route path={ENHANCED_REPORT_URL} component={EnhancedReportPage} />}
            {hasPermission(INTEGRATIONS_PERM) && <Route path={PRBC_REPORT_URL} component={PrbcReportPage} />}
            {hasPermission(USER_MODULE_PERM) && <Route path={USER_MANAGEMENT_URL} component={UserManagementPage} />}
            {hasPermission(REQUEST_FUNDER_INFO_PERM) && <Route path={REQUEST_FUNDER_INFO_URL} component={RequestFunderInfoPage} />}
            {hasPermission(APPROVE_FUNDERS_LIMIT_PERM) && <Route path={APPROVE_SYNDICATION_LIMIT_URL} component={ApproveSyndicationLimitPage} />}
            {hasPermission(PORTFOLIO_SUMMARY_PERM) && <Route path={PORTFOLIO_SUMMARY_URL} component={PortfolioSummaryPage} />}
            {hasPermission(OVERALL_DEALS_MODULE_PERM) && <Route path={REP_OVERALL_DEALS_URL} component={RepOverallDealsSummaryPage} />}
            <Redirect to={redirectUrl} />
          </Switch>
        </Box>
      </Container>
    </Box>
  )
}

export default AppContainer

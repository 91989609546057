import React, { FC, Fragment } from 'react'
import { Box, Button, FormHelperText, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { IFlexDisbursement } from 'typescript/interfaces/deals'
import * as yup from 'yup'
import DatePicker from 'UI/DatePicker/DatePicker'
import { FLEX_NEED_TO_BE_LOWER_THAN_ADVANCED_AMOUNT, INVALID_DATE_MESSAGE, ONLY_DIGITS, REQUIRED_FIELD } from 'constants/errors'
import { integerReg } from 'constants/regExp'
import convertDateForPicker from 'utils/convertDateForPicker'
import isValidDate from 'utils/isValidDate'

const useStyles = makeStyles((theme) => ({
  icon: {
    '& path': {
      fill: theme.palette.info.contrastText,
    },
  },
  iconRemove: {
    transform: 'rotate(45deg)',
  },
}))

const validationSchema = yup.object({
  schedule: yup.array().of(
    yup.object().shape({
      date: yup
        .string()
        .nullable()
        .required(REQUIRED_FIELD)
        .test('date', INVALID_DATE_MESSAGE, (val) => isValidDate(val)),
      amount: yup.string().required(REQUIRED_FIELD).matches(integerReg, ONLY_DIGITS),
    }),
  ),
  flexAmount: yup.number().test('flexAmount', FLEX_NEED_TO_BE_LOWER_THAN_ADVANCED_AMOUNT, function (val) {
    const totalDisbursement = (this.parent.schedule as { amount: string }[]).map(({ amount }) => Number(amount) || 0).reduce((a, b) => a + b, 0)
    const totalFlex = (Number(val) || 0) + totalDisbursement
    return totalFlex <= (Number(this.parent.advanceAmount) || 0)
  }),
})

export interface IFlexDisbursementForm {
  schedule: IFlexDisbursement[]
  advanceAmount: number
  flexAmount: number
}

interface FlexDisbursementFormProps {
  initialValues: IFlexDisbursementForm
  firstDisbursement: IFlexDisbursement
  onSubmit: (values: IFlexDisbursementForm) => void
  onResize: () => void
  classes: any
}

const FlexDisbursementForm: FC<FlexDisbursementFormProps> = ({ initialValues, firstDisbursement, onSubmit, onResize }) => {
  const today = new Date()
  const s = useStyles()
  const formik = useFormik<IFlexDisbursementForm>({
    validationSchema,
    initialValues,
    enableReinitialize: false,
    onSubmit,
  })

  const { values, touched, errors, handleSubmit, handleChange, setFieldValue, setFieldTouched } = formik

  const createDisbursementWithId = (): IFlexDisbursement => {
    const maxId = values.schedule.reduce((max, obj) => Math.max(max, obj.id), 0)
    return { id: maxId + 1 }
  }

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={(e) => {
          setFieldTouched('schedule')
          handleSubmit(e)
        }}
      >
        <FieldArray
          name="schedule"
          render={({ push, remove }) => (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Box mb={3}>
                  <Typography variant="body1">Flex disbursements</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <DatePicker disabled fullWidth type="keyboard" label="Deal Start Date" value={firstDisbursement.date || null} onChange={() => {}} />
              </Grid>
              <Grid item xs={5}>
                <TextField disabled fullWidth variant="outlined" label="Amount" value={firstDisbursement.amount ?? ''} />
              </Grid>
              <Grid item xs={3}>
                <Button disabled fullWidth startIcon={<PlusIcon className={`${s.icon} ${s.iconRemove}`} />} variant="contained" color="inherit">
                  Remove
                </Button>
              </Grid>
              {values.schedule.map(({ id, date, amount }, index) => (
                <Fragment key={id}>
                  <Grid item xs={4}>
                    <DatePicker
                      fullWidth
                      type="keyboard"
                      disablePast
                      minDate={today}
                      label="Due Date"
                      name={`schedule[${index}].date`}
                      value={date || null}
                      onChange={(x) => setFieldValue(`schedule[${index}].date`, convertDateForPicker(x))}
                      error={Boolean(touched.schedule?.[index]?.date && (errors.schedule?.[index] as any)?.date)}
                      helperText={touched.schedule?.[index]?.date && (errors.schedule?.[index] as any)?.date}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Amount"
                      name={`schedule[${index}].amount`}
                      value={amount ?? ''}
                      onChange={handleChange}
                      error={Boolean(touched.schedule?.[index]?.amount && (errors.schedule?.[index] as any)?.amount)}
                      helperText={touched.schedule?.[index]?.amount && (errors.schedule?.[index] as any)?.amount}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      onClick={() => {
                        remove(index)
                        onResize()
                      }}
                      startIcon={<PlusIcon className={`${s.icon} ${s.iconRemove}`} />}
                      variant="contained"
                      color="inherit"
                    >
                      Remove
                    </Button>
                  </Grid>
                </Fragment>
              ))}
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    push(createDisbursementWithId())
                    onResize()
                  }}
                  startIcon={<PlusIcon className={s.icon} />}
                  variant="contained"
                  color="inherit"
                >
                  Add disbursement
                </Button>
              </Grid>
              <Grid item xs={12}>
                {Boolean(errors.flexAmount) && <FormHelperText error>{errors.flexAmount}</FormHelperText>}
                <Box mt={3}>
                  <Button type="submit" color="primary" variant="contained">
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        />
      </form>
    </FormikProvider>
  )
}

export default FlexDisbursementForm

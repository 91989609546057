const removeEmptyAttributes = <T extends object = object>(object: T): Partial<T> => {
  const newObject: Partial<T> = {}
  for (const key in object) {
    if (object[key] !== undefined) {
      newObject[key] = object[key]
    }
  }
  return newObject
}

export default removeEmptyAttributes

import { IDealDocumentConnectParamsResponse } from 'typescript/interfaces/deals'

export const validateAllDocuments = (documents?: IDealDocumentConnectParamsResponse[]) => {
  if (!documents) return false

  const existing: any = {
    VoidedCheck: false,
    Application: false,
    DriverLicense: false,
  }
  documents.forEach((doc) => {
    if (!existing[doc.documentType]) {
      existing[doc.documentType] = true
    }
  })
  return Object.values(existing).every((v) => v)
}

import React, { FC, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import DatePicker from 'UI/DatePicker/DatePicker'
import SelectComponent from 'UI/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import Box from '@material-ui/core/Box'
import Button from 'UI/Button/ButtonWithPreloader'
import { ICreateReverseSchedule, IPaymentScheduleCalculationParams } from 'typescript/interfaces/deals'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'
import { paymentFrequency } from 'constants/paymentFrequency'
import { useQuery } from 'react-query'
import { PaymentFrequencyEnum } from 'typescript/interfaces/paymentFrequency'
import { getTomorrowDate } from 'utils/dateUtils'
import { calculatePaymentSchedule } from 'services/deals'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { schemaReverseSchedule } from './schema'

interface IReverseScheduleProps {
  dealId: string
  disbursementFrequency: PaymentFrequencyEnum
  loading?: boolean
  cancel: () => void
  submit: (values: ICreateReverseSchedule) => void
}

const useStyles = makeStyles(() => ({
  preloaderOuterContainer: {
    position: 'relative',
  },
  preloaderInnerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  preloader: {
    margin: 10,
    zIndex: 1,
  },
}))

const ReverseScheduleForm: FC<IReverseScheduleProps> = ({ dealId, disbursementFrequency, loading, cancel, submit }) => {
  const tomorrow = getTomorrowDate()
  const [endDate, setEndDate] = useState<string>('')
  const [scheduleParams, setScheduleParams] = useState<IPaymentScheduleCalculationParams>({ frequency: disbursementFrequency })
  const classes = useStyles()

  const { handleSubmit, values, errors, touched, handleChange, setFieldValue } = useFormik<ICreateReverseSchedule>({
    initialValues: {
      dealId,
      startDate: '',
      term: undefined,
      reverseAmount: undefined,
    },
    validationSchema: schemaReverseSchedule,
    onSubmit: submit,
  })

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setScheduleParams({ startDate: values.startDate, frequency: disbursementFrequency, term: values.term })
    }, 500)

    return () => clearTimeout(debounceTimer)
  }, [values.startDate, values.term])

  const { isLoading } = useQuery(['calculatePaymentSchedule', scheduleParams], () => calculatePaymentSchedule(scheduleParams), {
    enabled: scheduleParams.startDate && scheduleParams.term,
    onSuccess: ({ data: schedule }) => setEndDate(schedule.pop() ?? ''),
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={9}>
        <Grid item xs={12}>
          <DatePicker
            minDate={tomorrow}
            disablePast
            fullWidth
            label="Start Date"
            name="startDate"
            type="keyboard"
            error={Boolean(touched.startDate && errors.startDate) || !isValidDate(values.startDate)}
            helperText={(touched.startDate && errors.startDate) || (!isValidDate(values.startDate) && 'MM/DD/YYYY')}
            value={values.startDate || null}
            onChange={(date) => {
              return setFieldValue('startDate', convertDateForPicker(date))
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.preloaderOuterContainer}>
            <Box className={classes.preloaderInnerContainer}>
              {isLoading && <CircularProgress color="primary" size={20} className={classes.preloader} />}
            </Box>
            <DatePicker disabled fullWidth label="End Date" type="keyboard" value={endDate || null} onChange={() => {}} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SelectComponent disabled label="Disbursement Frequency" fullWidth value={disbursementFrequency}>
            {paymentFrequency.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </SelectComponent>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            inputProps={{ step: 1 }}
            label="Num Of Transactions"
            name="term"
            value={values.term ?? ''}
            onChange={handleChange}
            error={Boolean(touched.term && errors.term)}
            helperText={touched.term && errors.term}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Amount"
            name="reverseAmount"
            value={values.reverseAmount ?? ''}
            onChange={handleChange}
            error={Boolean(touched.reverseAmount && errors.reverseAmount)}
            helperText={touched.reverseAmount && errors.reverseAmount}
          />
        </Grid>
      </Grid>
      <Box mt={10}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button onClick={cancel} fullWidth color="secondary" variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button loading={loading} fullWidth color="primary" variant="contained" type="submit">
              Schedule Disbursement
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default ReverseScheduleForm

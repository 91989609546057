import * as yup from 'yup'
import { REQUIRED_FIELD, ONLY_DIGITS } from 'constants/errors'
import { integerReg } from 'constants/regExp'
import { isAfter, isBefore, isEqual } from 'date-fns'

export const schemaSchedule = yup.object().shape({
  dealId: yup.string().required(REQUIRED_FIELD),
  startDate: yup
    .string()
    .nullable()
    .required(REQUIRED_FIELD)
    .test('isLessThan', 'Start Date should be less than End Date', function (value) {
      if (value && this.parent.endDate) {
        return isBefore(new Date(value), new Date(this.parent.endDate)) || isEqual(new Date(value), new Date(this.parent.endDate))
      }
      return true
    })
    .test('isNotFutureData', 'Start date should be a future date', function (value) {
      if (value) {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        tomorrow.setHours(12, 0, 0, 0)

        return !isBefore(new Date(value), tomorrow)
      }
      return true
    }),
  endDate: yup
    .string()
    .nullable()
    .required(REQUIRED_FIELD)
    .test('isLessThan', 'End Date should be more than Start Date', function (value) {
      if (value && this.parent.startDate) {
        return isAfter(new Date(value), new Date(this.parent.startDate)) || isEqual(new Date(value), new Date(this.parent.startDate))
      }
      return true
    }),
  paymentsCount: yup.string().required(REQUIRED_FIELD),
  // .matches(integerReg, MUST_BE_INTEGER)
  // .test('integer', MIN_VALUE_IS_ONE, function (val) {
  //   return parseInt(val || '') >= 1
  // }),
  paymentFrequency: yup.string().required(REQUIRED_FIELD),
  frequentPayment: yup.string().required(REQUIRED_FIELD).matches(integerReg, ONLY_DIGITS),
  bankAccount: yup.string().required(REQUIRED_FIELD),
})

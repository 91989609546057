import { Button, makeStyles, Switch, Typography } from '@material-ui/core'
import React from 'react'
import cn from 'classnames'

interface Props {
  variants: { title: string; value: string }[]
  value: string
  onClick?: () => void
  className?: string
}

const useStyles = makeStyles((theme) => ({
  selectedCheck: {
    transform: 'rotate(180deg)',
  },
  switchContainer: {
    background: '#F6FBFF',
    border: '1px solid #249AF3',
    height: '3rem',
  },
  text: {
    fontWeight: 500,
    fontSize: '1.0625rem',
    color: theme.palette.secondary.dark,
  },
  selected: {
    color: theme.palette.secondary.main,
  },
}))

const SwitchExtended = ({ variants, value, onClick, className }: Props) => {
  const s = useStyles()
  return (
    <Button
      className={cn(s.switchContainer, className)}
      variant="outlined"
      fullWidth
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}
    >
      <Typography className={cn(s.text, value === variants[0].value && s.selected)}>{variants[0].title}</Typography>
      <Switch className={cn(value === variants[0].value && s.selectedCheck)} defaultChecked color="secondary" />
      <Typography className={cn(s.text, value === variants[1].value && s.selected)}>{variants[1].title}</Typography>
    </Button>
  )
}

export default SwitchExtended

import makeStyles from '@material-ui/core/styles/makeStyles'

export const useAppStyle = makeStyles((theme) => ({
  container: {
    paddingLeft: 'calc(6rem + 3.25rem)',
    paddingRight: '3.25rem',
    minHeight: '100vh',
    '@media screen and (min-width: 1280px) and (max-width: 1600px)': {
      paddingLeft: 'calc(6rem + 1.5rem)',
      paddingRight: '1.5rem',
    },
  },
}))

import React, { FC, useEffect } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustSquareIcon.svg'
import Paper from '@material-ui/core/Paper/Paper'

import SearchField from 'UI/SearchField'
import { BANKS_NEW_URL } from 'constants/routes'
import { useHistory } from 'react-router-dom'
import { usePaginationList } from 'hooks/usePaginationList'
import { useMutation } from 'react-query'
import { IBankResponse, SortFieldsBanks } from 'typescript/interfaces/banks'
import { getBanks } from 'services/banks'
import { TableBanks, useBanks } from 'hooks/useBanks'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const BanksListContainer: FC = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  const { push } = useHistory()
  const { pagination, setPagination, handleFetchMore, handleSort } = usePaginationList<IBankResponse>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsBanks.bankName,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getBanksMut, { isLoading }] = useMutation(getBanks, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const { columns } = useBanks()

  useEffect(() => {
    getBanksMut({
      PageSize: 20,
      PageIndex: pagination.page,
      Query: pagination.search,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder])

  return (
    <Paper className={additionalStyle.wrapper} elevation={0}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          Banks
        </Typography>
        <Box display="flex" alignItems="center">
          <Box mr="3rem">
            <SearchField label="Search bank" handleSearch={(search) => null} />
          </Box>

          <Button onClick={() => push(BANKS_NEW_URL)} variant="text" color="secondary" startIcon={<PlusIcon />}>
            Add new bank
          </Button>
        </Box>
      </Box>

      <TableBanks
        handleGetMore={handleFetchMore}
        loading={isLoading}
        columns={columns}
        handleSortChange={handleSort}
        data={pagination.data || []}
        hasMore={pagination.hasMore}
      />
    </Paper>
  )
}

export default BanksListContainer

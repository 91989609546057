import React, { FC, useCallback, useRef, useState } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useTableComponentStyle } from 'UI/Table/style'
import { IReverseScheduleListItem } from 'typescript/interfaces/deals'
import { convertDate, convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { deleteReverseSchedule, getReverseSchedulesByDeal } from 'services/deals'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { ReactComponent as DeleteCircleIcon } from 'assets/svg/DeleteCircleIcon.svg'
import IconButton from '@material-ui/core/IconButton'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import ModalComponent from 'UI/Modal'
import { PaymentFrequencyEnum } from 'typescript/interfaces/paymentFrequency'

interface ReverseSchedulesTableProps {
  disbursementFrequency?: PaymentFrequencyEnum
}

const TableReverseSchedules = TableComponent as TableComponentType<IReverseScheduleListItem>

const ReverseSchedulesTable: FC<ReverseSchedulesTableProps> = ({ disbursementFrequency }) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryCache = useQueryCache()
  const { id } = useParams<{ id: string }>()
  const classes = useTableComponentStyle()
  const { data, isLoading } = useQuery(['reverseSchedulesDeal', id], () => getReverseSchedulesByDeal(id), {
    retry: false,
    cacheTime: 0,
    enabled: id,
  })

  const [stateDeleteSchedule, setStateDeleteSchedule] = useState({
    id: 0,
    open: false,
  })

  const [deleteReverseScheduleMut, { isLoading: isLoadingDeleteSchedule }] = useMutation(deleteReverseSchedule, {
    onSuccess: () => {
      queryCache.fetchQuery(['reverseSchedulesDeal', id], () => getReverseSchedulesByDeal(id), {
        retry: false,
        cacheTime: 0,
        enabled: id,
      })
      setStateDeleteSchedule({
        open: false,
        id: 0,
      })
      enqueueSnackbar('Disbursement schedule successfully deleted')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleDeleteReverseSchedule = useCallback((idReverseSchedule: number) => {
    setStateDeleteSchedule({
      id: idReverseSchedule,
      open: true,
    })
  }, [])

  const columnsRef = useRef<IColumn<IReverseScheduleListItem>[]>([
    {
      label: 'Start Date',
      field: 'startDate',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertDate(rowData.startDate),
    },
    {
      label: 'End Date',
      field: 'endDate',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertDate(rowData.endDate),
    },
    {
      label: 'Next Payment Date',
      field: 'nextPaymentDate',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertDate(rowData.nextPaymentDate),
    },
    {
      label: 'Amount',
      field: 'reverseAmount',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertToPriceFormat(rowData.reverseAmount),
    },
    {
      label: 'Term',
      field: 'term',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertEmptyField(rowData.term),
    },
    {
      label: 'Frequency',
      field: 'disbursementFrequency',
      bodyCellClassName: classes.accentCell,
      render: () => convertEmptyField(disbursementFrequency),
    },
    {
      label: 'Status',
      field: 'status',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertEmptyField(rowData.status),
    },
    {
      label: '',
      field: 'delete',
      render: (rowData) => (
        <IconButton
          size="small"
          onClick={() => {
            handleDeleteReverseSchedule(rowData.id)
          }}
        >
          <DeleteCircleIcon />
        </IconButton>
      ),
    },
  ])
  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableReverseSchedules columns={columnsRef.current} data={data ? data.data : []} />
      )}
      <ModalComponent open={stateDeleteSchedule.open}>
        <ConfirmationContent
          isLoading={isLoadingDeleteSchedule}
          handleCancel={() =>
            setStateDeleteSchedule({
              id: 0,
              open: false,
            })
          }
          handleConfirm={() => deleteReverseScheduleMut(stateDeleteSchedule.id)}
          text="Are you sure you want to delete disbursement schedule?"
        />
      </ModalComponent>
    </>
  )
}

export default ReverseSchedulesTable

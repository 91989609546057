import { Box, Paper } from '@material-ui/core'
import { NOTIFICATIONS_RECEIVED_URL } from 'constants/routes'
import { usePaginationList } from 'hooks/usePaginationList'

import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { getAllNewNotifications, getAllReceivedNotifications, getNewNotificationsCount, getNotification } from 'services/notifications'
import { INotification } from 'typescript/interfaces/notifications'
import NotificationBody from './NotificationBody'
import NotificationsChatList from './NotificationsChatList'
import NotificationsHeader from './NotificationsHeader'

const NotificationReceivedContainer = () => {
  const [tab, setTab] = useState<'new' | 'all'>('all')
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const [prevCount, setPrevCount] = useState<null | number>(null)
  const [mutateGetNotifications, { isLoading }] = useMutation(getAllReceivedNotifications, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [mutateGetAllNewNotifications, { isLoading: isNewLoading }] = useMutation(getAllNewNotifications, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const { refetch, data: countData } = useQuery('notifs-count', getNewNotificationsCount)

  const [markNotifAsRead] = useMutation(getNotification, {
    onSuccess(res, notifId) {
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.map((notif) => (notif.id === +notifId ? { ...notif, isRead: true } : notif)),
      }))
      setPrevCount((old) => old! - 1)
      refetch()
    },
  })

  const { pagination, setPagination, handleFetchMore } = usePaginationList<INotification>({
    initState: {
      data: [],
      search: '',
      order: 'Date',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const getNotifications = tab === 'all' ? mutateGetNotifications : mutateGetAllNewNotifications

  useEffect(() => {
    if (prevCount !== null) {
      getNotifications({
        PageSize: 20,
        PageIndex: pagination.page,
        Query: pagination.search,
        SortField: pagination.order,
        SortOrder: pagination.sortOrder,
      })
    }
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder, getNotifications])

  useEffect(() => {
    if (countData && prevCount === countData.data) {
      return
    }
    if (countData) {
      setPagination((prevState) => ({
        ...prevState,
        data: [],
        hasMore: false,
        page: 0,
      }))
      getNotifications({
        PageSize: 20,
        PageIndex: pagination.page,
        Query: pagination.search,
        SortField: pagination.order,
        SortOrder: pagination.sortOrder,
      })
      setPrevCount(countData?.data)
    }
  }, [countData?.data, getNotifications])

  const selectedNotif = pagination.data.find((notif) => `${notif.id}` === id) ?? null

  const handleTabChange = (tabV: 'new' | 'all') => {
    if (tabV !== tab) {
      setTab(tabV)
      setPagination((prevState) => ({
        ...prevState,
        data: [],
        hasMore: false,
        page: 0,
      }))
      push(NOTIFICATIONS_RECEIVED_URL.replace(':id?', ''))
    }
  }

  useEffect(() => {
    if (id && !selectedNotif?.isRead) {
      markNotifAsRead(id)
    }
  }, [id])

  return (
    <Box component={Paper}>
      <NotificationsHeader onTabChange={handleTabChange} tab={tab} />
      <Box display="flex" justifyContent="space-between">
        <NotificationsChatList
          hasMore={pagination.hasMore}
          loading={isLoading || isNewLoading}
          onFetchMore={handleFetchMore}
          selectedNotification={id}
          notifications={pagination.data}
        />
        {selectedNotif && <NotificationBody notification={selectedNotif} />}
      </Box>
    </Box>
  )
}

export default NotificationReceivedContainer

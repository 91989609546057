import { Box, CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { useQuery } from 'react-query'
import { getInhouseCompany } from 'services/companies'
import { CompanyType } from 'typescript/interfaces/companies'
import { COMPANY_EDIT_URL, OUTHOUSE_COMPANY_EDIT_URL } from 'constants/routes'
import AllInfo from 'components/ViewCompany/AllInfo'

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '3rem',
    fontWeight: 500,
    lineHeight: '3rem',
    paddingTop: '0.75rem',
  },
}))
const ViewCompany = () => {
  const { goBack, push } = useHistory()
  const s = useStyles()
  const { id } = useParams<{ id: string }>()
  const { isLoading, data } = useQuery(['company', +id!], () => getInhouseCompany(id || ''), {
    retry: false,
  })

  const handleEditClick = () => {
    push((data?.data.companyType === CompanyType.InHouse ? COMPANY_EDIT_URL : OUTHOUSE_COMPANY_EDIT_URL).replace(':id', id))
  }

  return (
    <Box mb="2rem">
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="calc(100vh - 98px - 2rem)">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" mb="2rem" alignItems="center">
            <Box mr="1.5rem">
              <IconButton onClick={goBack} color="inherit">
                <ArrowLeftIcon />
              </IconButton>
            </Box>
            <Typography className={s.pageTitle} color="textSecondary">
              {data!.data.name}
            </Typography>
          </Box>
          <AllInfo company={data!.data} onEdit={handleEditClick} />
        </>
      )}
    </Box>
  )
}

export default ViewCompany

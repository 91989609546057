export const valiadateFloatWithTwoDigits = (value: number) => {
  if (!value) return true
  if (Number.isNaN(value)) return false

  if (value.toString().split('.')[1]) {
    return value.toString().split('.')[1].length <= 2
  }

  return true
}

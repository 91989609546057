import React, { FC, useState, useEffect } from 'react'
import { ReactComponent as SearchIcon } from 'assets/svg/SearchIcon.svg'
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useLocation } from 'react-router-dom'

interface ISearchField extends StandardTextFieldProps {
  handleSearch: (value: string) => void
  enableEmptySearch?: boolean
  isClearAfterLocationChange?: boolean
}

const useSearchFieldStyle = makeStyles((theme) => ({
  inputLabel: {
    '&.shrink': {
      transform: 'translate(2.875rem, 0.95rem) scale(1)',
    },
    '&.focused': {
      transform: 'translate(10px, -18px) scale(0.875)',
    },
    '&.MuiFormLabel-filled': {
      transform: 'translate(10px, -18px) scale(0.875)',
    },
  },
}))

const SearchField: FC<ISearchField> = ({ handleSearch, enableEmptySearch = true, onChange, ...rest }) => {
  const classes = useSearchFieldStyle()
  const [search, setSearch] = useState('')
  const location = useLocation()

  useEffect(() => {
    setSearch('')
  }, [location.pathname])

  return (
    <TextField
      {...rest}
      variant="outlined"
      value={search}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          shrink: 'shrink',
          focused: 'focused',
        },
      }}
      onChange={(e) => {
        e.persist()
        setSearch(e.target.value)
        onChange && onChange(e)
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 13 && (search || enableEmptySearch)) {
          handleSearch(search)
        }
      }}
      fullWidth
      InputProps={{
        startAdornment: (
          <SearchIcon
            onClick={() => {
              if (search || enableEmptySearch) {
                handleSearch(search)
              }
            }}
            style={{ cursor: 'pointer' }}
          />
        ),
      }}
    />
  )
}

export default SearchField

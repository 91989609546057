import React, { FC } from 'react'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import useTheme from '@material-ui/core/styles/useTheme'
import { IChartProps } from 'typescript/interfaces/dashboard'
import { convertDate } from 'utils/formatters'

const TermFactorAvgChartDetailed: FC<IChartProps> = ({ stats }) => {
  const theme = useTheme()

  const data = stats?.data?.map(({ date, factor }) => ({ name: convertDate(new Date(date)), factor }))

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <YAxis
          allowDecimals={false}
          tick={{
            fill: theme.palette.text.primary,
            strokeWidth: 1,
          }}
          minTickGap={0}
          tickCount={4}
          width={25}
          axisLine={false}
          tickLine={false}
          interval="preserveStartEnd"
          tickMargin={0}
          padding={{
            top: 0,
            bottom: 0,
          }}
        />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip labelFormatter={(index) => data?.[index as number]?.name} />
        <Area type="monotone" dataKey="factor" stroke={theme.palette.primary.main} fillOpacity={0.3} fill={theme.palette.primary.main} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default TermFactorAvgChartDetailed

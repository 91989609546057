import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useTableComponentStyle } from 'UI/Table/style'
import { IColumn } from 'typescript/interfaces/tableUi'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as StopIcon } from 'assets/svg/StopIcon.svg'
import { convertDate, convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { AchEntity } from 'typescript/interfaces/achDebit'
import { AchSortFields } from 'typescript/enums/ach'
import cn from 'classnames'
import Box from '@material-ui/core/Box'

export const TableAchCredit = TableComponent as TableComponentType<AchEntity>

interface IUseAchCreditProps {
  handleEdit: (id: number, date: MaterialUiPickersDate | string, category: string | null) => void
  handleStop: (id: number, category: string | null) => void
}

export const useAchCredit = (props: IUseAchCreditProps) => {
  const { handleEdit, handleStop } = props
  const classes = useTableComponentStyle()
  const columns = useRef<Array<IColumn<AchEntity>>>([
    {
      label: 'Payment #',
      sorting: true,
      field: AchSortFields.paymentId,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => <Box className={cn({ [classes.errorColumn]: data.status === 'Stopped' })}>{convertEmptyField(data.paymentId)}</Box>,
    },
    {
      label: 'Deal #',
      sorting: true,
      field: AchSortFields.dealId,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => <Box className={cn({ [classes.errorColumn]: data.status === 'Stopped' })}>{convertEmptyField(data.dealId)}</Box>,
    },
    {
      label: 'Deal Name',
      sorting: true,
      field: AchSortFields.dealName,
      bodyCellClassName: classes.accentCell,
      render: (data) => <Box className={cn({ [classes.errorColumn]: data.status === 'Stopped' })}>{convertEmptyField(data.dealName)}</Box>,
    },
    {
      label: 'Payment',
      sorting: true,
      field: AchSortFields.payment,
      bodyCellClassName: classes.secondaryDarkCell,
      render: (data) => <Box className={cn({ [classes.errorColumn]: data.status === 'Stopped' })}>{convertToPriceFormat(data.payment)}</Box>,
    },
    {
      label: 'Schedule For',
      sorting: true,
      field: AchSortFields.scheduleFor,
      bodyCellClassName: classes.secondaryDarkCell,
      render: (data) => <Box className={cn({ [classes.errorColumn]: data.status === 'Stopped' })}>{convertDate(data.scheduleFor)}</Box>,
    },
    {
      label: 'Company',
      sorting: true,
      field: AchSortFields.companyName,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => <Box className={cn({ [classes.errorColumn]: data.status === 'Stopped' })}>{convertEmptyField(data.company)}</Box>,
    },
    {
      label: 'Edit',
      render: (data) => {
        return (
          <IconButton disabled={data.status === 'Stopped'} size="small" onClick={() => handleEdit(data.paymentId, data.scheduleFor, data.category)}>
            <EditIcon />
          </IconButton>
        )
      },
      field: 'edit',
    },
    {
      label: 'Stop',
      render: (data) => {
        return (
          <IconButton disabled={data.status === 'Stopped'} size="small" onClick={() => handleStop(data.paymentId, data.category)}>
            <StopIcon />
          </IconButton>
        )
      },
      field: 'Stop',
    },
  ])

  return {
    columns: columns.current,
  }
}

import { fade } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { bg2 } from 'containers/MaterialUiContainer/mainTheme'

export const useHeaderStyle = makeStyles((theme) => ({
  wrapperLogo: {
    display: 'inline-flex',
    cursor: 'pointer',
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    top: 0,
    maxHeight: 98,
    zIndex: 10,
    marginLeft: theme.spacing(-13),
    marginRight: theme.spacing(-13),
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
    // marginLeft: theme.spacing(-13),
    // marginRight: theme.spacing(-13),
    // paddingLeft: theme.spacing(13),
    // paddingRight: theme.spacing(13),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    borderBottom: (props: any) => (props.trigger ? `1px solid ${theme.palette.info.light}` : 'none'),
    '@media screen and (min-width: 1280px) and (max-width: 1600px)': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  inputSearch: {
    padding: `1rem 0.875rem`,
  },
  popover: {
    // minWidth: '17.5rem',
    // padding: '0',
    overflow: 'inherit',
    // position: 'relative',
    '&:after': {
      width: 0,
      height: 0,
      borderLeft: '0.8rem solid transparent',
      borderRight: '0.8rem solid transparent',
      borderBottom: `0.8rem solid ${theme.palette.common.white}`,
      top: '-0.8rem',
      right: '1.2rem',
      display: 'table',
      content: '""',
      position: 'absolute',
    },
  },
  iconContainer: {
    border: `1px solid ${fade(theme.palette.secondary.main, 0.2)}`,
    background: bg2,
    padding: '0.4rem',
    marginRight: '0.75rem',
    borderRadius: '0.375rem',
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItem: {
    height: '3.25rem',
  },
}))

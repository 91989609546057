import makeStyles from '@material-ui/core/styles/makeStyles'
import { fade } from '@material-ui/core'

export const useImportMassLegalFeesStyle = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  uploadButton: {
    border: '1px solid #A2B3C1',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      border: '1px solid #A2B3C1',
      backgroundColor: fade(theme.palette.text.primary, 0.25),
    },
  },
  boxFileName: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    color: theme.palette.secondary.dark,
    '& svg': {
      marginRight: theme.spacing(2),
      '& path': {
        fill: theme.palette.text.primary,
      },
    },
  },
}))

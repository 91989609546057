import { Box } from '@material-ui/core'
import RootRef from '@material-ui/core/RootRef'
import debounce from 'lodash.debounce'
import React, { FC, useEffect, useRef, useState } from 'react'

const CalculateRestHeightBlock: FC = ({ children }) => {
  const wrapperTableRef = useRef<HTMLDivElement | undefined>()
  const [maxHeight, setMaxHeight] = useState<string>()
  useEffect(() => {
    const updateMaxHeight = debounce(() => {
      const { clientHeight } = document.documentElement
      setMaxHeight(wrapperTableRef.current ? `${clientHeight - wrapperTableRef.current.offsetTop}px` : 'inherit')
    }, 300)
    updateMaxHeight()
    window.addEventListener('resize', updateMaxHeight)
    return () => window.removeEventListener('resize', updateMaxHeight)
  }, [])

  return (
    <RootRef rootRef={wrapperTableRef}>
      <Box component="div" style={{ maxHeight }}>
        {children}
      </Box>
    </RootRef>
  )
}

export default CalculateRestHeightBlock

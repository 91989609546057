import React, { FC, useMemo } from 'react'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import useTheme from '@material-ui/core/styles/useTheme'
import { IChartProps } from 'typescript/interfaces/dashboard'
import { convertDate, formatBigNumber } from 'utils/formatters'
import { addDays } from 'date-fns'

const DailyChartDetailed: FC<IChartProps> = ({ stats }) => {
  const theme = useTheme()
  const data = stats?.data
    ?.map(({ date, frequent }) => ({ name: convertDate(new Date(date)), frequent }))
    .concat([
      {
        name: convertDate(new Date()),
        frequent: stats ? stats.todayFrequent : 0,
      },
      {
        name: convertDate(addDays(new Date(), 1)),
        frequent: stats ? stats.tomorrowFrequent : 0,
      },
    ])

  const fontSizeHtml = parseFloat(getComputedStyle(document.documentElement).fontSize)

  const maxLength = useMemo(() => {
    return data
      ? data
          .reduce((acc, val) => {
            return acc < val.frequent ? val.frequent : acc
          }, 0)
          .toString().length * fontSizeHtml
      : 0
  }, [data])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <YAxis
          tick={{
            fill: theme.palette.text.primary,
            strokeWidth: 1,
          }}
          minTickGap={0}
          tickCount={4}
          allowDecimals={false}
          width={maxLength}
          axisLine={false}
          tickLine={false}
          interval="preserveStartEnd"
          tickMargin={0}
          tickFormatter={(item) => formatBigNumber(item)}
          padding={{
            top: 0,
            bottom: 0,
          }}
        />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip labelFormatter={(index) => data?.[index as number]?.name} />
        <Area type="monotone" dataKey="frequent" stroke={theme.palette.primary.main} fillOpacity={0.3} fill={theme.palette.primary.main} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default DailyChartDetailed

import React, { useRef } from 'react'
import { IRepListItem, SortFieldRep } from 'typescript/interfaces/rep'
import { IColumn } from 'typescript/interfaces/tableUi'
import { Link, generatePath, useHistory } from 'react-router-dom'
import { REP_INFO_URL, TRANSACTIONS_URL, WITHDRAWAL_URL } from 'constants/routes'
import { useTableComponentStyle } from 'UI/Table/style'
import { Box } from '@material-ui/core'
import Button from 'UI/Button/ButtonWithPreloader'
import TableComponent, { TableComponentType } from 'UI/Table'
import { convertDate, convertToPriceFormat } from 'utils/formatters'
import { TRANSACTION_PERM, WITHDRAWAL_PERM } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'

export const TableRep = TableComponent as TableComponentType<IRepListItem>

export const useRep = () => {
  const { hasPermission, isOwnRep } = usePermission()
  const classes = useTableComponentStyle()
  const { push } = useHistory()
  const columns = useRef<Array<IColumn<IRepListItem>>>([
    {
      label: 'REP #',
      field: SortFieldRep.id,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Link
          to={generatePath(REP_INFO_URL, {
            id: data.id.toString(),
          })}
        >
          {data.id}
        </Link>
      ),
    },
    {
      label: 'Business Name',
      field: SortFieldRep.businessName,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box whiteSpace="nowrap" maxWidth={220} textOverflow="ellipsis" overflow="hidden">
          {data.businessName}
        </Box>
      ),
    },
    {
      label: 'First Name',
      field: SortFieldRep.firstName,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => data.firstName,
    },
    {
      label: 'Last Name',
      field: SortFieldRep.lastName,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => data.lastName,
    },
    {
      label: 'Phone',
      field: SortFieldRep.phone,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => <Box whiteSpace="nowrap">{data.phone}</Box>,
    },
    {
      label: 'State',
      field: SortFieldRep.state,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => data.state.name,
    },
    {
      label: 'Of Deals',
      field: SortFieldRep.ofDeals,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => data.dealsCount,
    },
    {
      label: 'Current Balance',
      field: SortFieldRep.availableBalance,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.userPage.availableBalance),
    },
    {
      label: 'Last Transactions',
      field: SortFieldRep.lastTransactions,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.lastTransactionDate),
    },
    ...(hasPermission(TRANSACTION_PERM) || hasPermission(WITHDRAWAL_PERM)
      ? [
          {
            label: '',
            field: 'actions',
            render: (data: IRepListItem) => (
              <Box display="flex" alignItems="center">
                {(hasPermission(TRANSACTION_PERM) || isOwnRep(data.id)) && (
                  <Box mr="1rem">
                    <Button
                      onClick={() =>
                        push(
                          generatePath(TRANSACTIONS_URL, {
                            id: data.id,
                            type: 'rep',
                            businessName: data.businessName,
                          }),
                        )
                      }
                      color="secondary"
                      variant="outlined"
                      size="small"
                    >
                      View Trs
                    </Button>
                  </Box>
                )}
                {(hasPermission(WITHDRAWAL_PERM) || isOwnRep(data.id)) && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={() =>
                      push(
                        generatePath(WITHDRAWAL_URL, {
                          id: data.id,
                          type: 'rep',
                          businessName: data.businessName,
                        }),
                      )
                    }
                  >
                    Withdrawals
                  </Button>
                )}
              </Box>
            ),
          },
        ]
      : []),
  ])

  return {
    columns: columns.current,
  }
}

export enum SortFieldsDeal {
  id = 'Id',
  businessName = 'BusinessName',
  advanced = 'Advanced',
  payback = 'Payback',
  daily = 'Daily',
  weekly = 'Weekly',
  term = 'Term',
  net = 'Net',
  collected = 'Collected',
  paidOff = 'PaidOff',
  remaining = 'Remaining',
  rep = 'Rep',
  funder = 'Funder',
  label = 'Label',
  inhouseCompany = 'InhouseCompany',
  pnl = 'Pnl',
  status = 'Status',
  paidStatus = 'PaidStatus',
}

export enum DealsStatus {
  new = 'New',
  declined = 'Declined',
  approved = 'Approved',
  outhouseApproved = 'OuthouseFunded',
  inUnderwriting = 'InUnderwriting',
  readyToFund = 'ReadyToFund',
  pending = 'Pending',
}

export enum DealsType {
  inhouse = 'InHouse',
  outhouse = 'OutHouse',
}

export enum ExtendDealType {
  flex = 'Flex',
  reverse = 'Reverse',
}

export enum RepPortfolioSummarySortFields {
  company = 'Company',
  dealId = 'DealId',
  startDate = 'StartDate',
  iso = 'Iso',
  status = 'Status',
  factor = 'Factor',
  frequentPayment = 'FrequentPayment',
  advanceAmount = 'AdvanceAmount',
  isoFee = 'IsoFee',
  payback = 'Payback',
  totalCommission = 'TotalCommission',
  psf = 'Psf',
}

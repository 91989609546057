import React, { FC, ReactElement, useMemo } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete/Autocomplete'
import { useQuery } from 'react-query'
import { Value } from '@material-ui/lab'
import { getDealLight } from 'services/deals'
import { ILightDeal, IGetDealsLight } from 'typescript/interfaces/deals'

interface IAutoCompleteProps {
  dealId: any
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void
  error?: boolean
  helperText?: string | false
  label?: string | ReactElement
  paramsRequest?: Partial<IGetDealsLight>
}

const DealAutoComplete: FC<IAutoCompleteProps> = ({ dealId, onChange, helperText, error, label = 'Deal', paramsRequest = {} }) => {
  const { data, isLoading } = useQuery(['dealLight', paramsRequest], () => getDealLight({ PageSize: 4000, ...paramsRequest }))
  const arrayDeals = useMemo(() => {
    return data ? data.data : []
  }, [data])
  const selectedOptionDeal = useMemo(() => {
    if (data) {
      const findDeal = data.data.find((c) => c.id === dealId) || null
      return findDeal
    }
    return null
  }, [data, dealId])
  return (
    <Autocomplete
      value={selectedOptionDeal}
      loading={isLoading}
      options={arrayDeals}
      getOptionLabel={(option: ILightDeal) => {
        return `#${option.id}`
      }}
      getOptionSelected={(option: ILightDeal, value) => {
        if (value && value.id) return option.id === value.id
        return false
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={helperText}
          error={error}
          label={label}
          InputProps={{
            // autoComplete: "off",
            ref: params.InputProps.ref,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} />
              </>
            ),
          }}
          variant="outlined"
        />
      )}
    />
  )
}

export default DealAutoComplete

import {
  API_GET_ISO,
  API_CREATE_ISO,
  API_GET_ISO_BY_ID,
  API_EDIT_ISO,
  API_GET_ISO_LIGHT,
  API_DELETE_ISO_DOCUMENT,
  API_CONNECT_ISO_DOCUMENTS_TO_ISO,
  API_DELETE_NACHA_DOC,
} from 'constants/apiUrl'
import { IIsoInitialValues } from 'components/ISOForm'
import { privateInstance } from 'services/axios/privateInstance'
import { IIsoListItem, IIsoEntity, IIsoListItemLight, IConnectIsoFileLinksToIsoParams } from 'typescript/interfaces/iso'
import { AxiosResponse } from 'axios'
import { IPagination } from 'typescript/interfaces/pagination'
import { storageInstance } from 'services/axios/storageInstance'
import { IDealDocumentConnectParams, IDealDocumentConnectParamsResponse } from 'typescript/interfaces/deals'
import { getDownloadUrl, uploadToS3 } from 'services/storage'
import { generatePath } from 'react-router-dom'

export const createIso = (values: IIsoInitialValues) => {
  return privateInstance.post<IIsoInitialValues, AxiosResponse<IIsoListItem>>(API_CREATE_ISO, values)
}

export const getIsoList = async (params: IPagination) => {
  return privateInstance.get<IPagination, AxiosResponse<IIsoListItem[]>>(API_GET_ISO, {
    params: params,
  })
}

export const getIsoLight = async (params: IPagination) => {
  return privateInstance.get<IPagination, AxiosResponse<IIsoListItemLight[]>>(API_GET_ISO_LIGHT, {
    params: params,
  })
}

export const getIsoById = (id: string) => {
  return privateInstance.get<{ id: string }, AxiosResponse<IIsoEntity>>(API_GET_ISO_BY_ID.replace(':id', id))
}

export const editIsoById = (values: IIsoInitialValues, id: string) => {
  return privateInstance.put<IIsoInitialValues, AxiosResponse<IIsoListItem>>(API_EDIT_ISO, {
    ...values,
    id: id,
  })
}

export const deleteFileFromIso = (id: number) => storageInstance.delete(API_DELETE_ISO_DOCUMENT.replace(':id', id.toString()))

export const deleteNachaFile = (id: number) =>
  storageInstance.delete(
    generatePath(API_DELETE_NACHA_DOC, {
      id: id,
    }),
  )

export const connectUploadedUrlToIso = async (body: IConnectIsoFileLinksToIsoParams) =>
  storageInstance.post<IDealDocumentConnectParams, AxiosResponse<IDealDocumentConnectParamsResponse>>(API_CONNECT_ISO_DOCUMENTS_TO_ISO, body)

export const uploadIsoDocuments = async (documents: File[], isoId: number | string) => {
  const links = await Promise.all(
    documents.map((doc) => {
      return getDownloadUrl({
        DocumentName: doc.name,
        DocumentDestination: 'Iso',
        DestinationObjectId: isoId,
      })
    }),
  )

  const uploadedFilesLinks = await Promise.all(links.map((link, i) => uploadToS3(documents[i], link.data)))

  const connectedUploadedUrls = await Promise.all(
    uploadedFilesLinks.map((uploadedLink, i) => {
      return connectUploadedUrlToIso({
        documentUrl: links[i].data.split('?')[0],
        description: documents[i].name,
        isoId,
      })
    }),
  )

  return connectedUploadedUrls
}

import React, { FC } from 'react'
import { useFormik } from 'formik'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from 'UI/Button/ButtonWithPreloader'
import { schemaSetLimit } from 'components/SetLimit/schema'
import { ISetLimitModalData } from 'typescript/interfaces/rep'

interface ISetLimitProps {
  handleSubmit: (values: ISetLimitModalData) => void
  handleCancel: () => void
  isLoading?: boolean
  initialValues?: ISetLimitModalData
}

const SetLimitForm: FC<ISetLimitProps> = ({ handleSubmit, handleCancel, isLoading, initialValues }) => {
  const { handleSubmit: submit, errors, touched, values, handleChange } = useFormik<ISetLimitModalData>({
    validationSchema: schemaSetLimit,
    initialValues: {
      representativeId: initialValues?.representativeId ?? null,
      limit: initialValues?.limit ?? null,
    },
    onSubmit: handleSubmit,
  })
  return (
    <Box width={320} maxWidth="100%">
      <form onSubmit={submit}>
        <Box pt="1rem">
          <TextField
            label="Limit"
            variant="outlined"
            fullWidth
            name="limit"
            onChange={handleChange}
            value={values.limit}
            error={Boolean(touched.limit && errors.limit)}
            helperText={touched.limit && errors.limit}
          />
        </Box>
        <Box mt="2rem" display="flex" alignItems="center" justifyContent="flex-end">
          <Button disabled={isLoading} color="primary" variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Box ml="1rem">
            <Button loading={isLoading} color="primary" variant="contained" type="submit">
              Approve
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default SetLimitForm

import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import ReactQuill from 'react-quill'
import cn from 'classnames'
import { ReactQuillComponentProps } from './types'
import 'react-quill/dist/quill.snow.css'
import { availableFormats } from './constants'

const useStyles = makeStyles((theme) => ({
  rte: {
    '& .ql-toolbar.ql-snow': {
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      borderColor: theme.palette.divider,
    },
    '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
      borderColor: theme.palette.divider,
    },
    '& .ql-snow .ql-stroke': {
      stroke: theme.palette.text.primary,
    },
    '& .ql-snow .ql-picker.ql-expanded .ql-picker-options': {
      zIndex: 100,
    },
    '& .ql-snow .ql-picker': {
      color: theme.palette.secondary.dark,
    },
    '& .ql-editor': {
      minHeight: '10rem',
    },
  },
  rteError: {
    '& .ql-toolbar.ql-snow': {
      borderColor: theme.palette.error.main,
    },
    '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
      borderColor: theme.palette.error.main,
    },
  },

  formHelper: {
    fontSize: '0.875rem',
    color: theme.palette.error.main,
    marginTop: '0.4rem',
  },
}))

interface Props extends ReactQuillComponentProps {
  error?: boolean
  helperText?: string | false
}

const RTEComponent = ({ onChange, error, helperText, defaultValue, ...rest }: Props) => {
  const s = useStyles()
  return (
    <>
      <ReactQuill
        defaultValue={defaultValue}
        onChange={onChange}
        formats={availableFormats}
        placeholder="Type your message"
        theme="snow"
        className={cn(s.rte, error && s.rteError)}
        {...rest}
      />
      <Typography className={s.formHelper}>{helperText}</Typography>
    </>
  )
}

export default RTEComponent

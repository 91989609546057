import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { convertEmptyField } from 'utils/formatters'
import { IState } from 'typescript/interfaces/companies'

interface IBusinessInfoProps {
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  state: IState
  businessName: string
}

const BusinessInfo: FC<IBusinessInfoProps> = ({ city, street, zipCode, federalTaxId, state, businessName }) => {
  return (
    <>
      <Box width="100%" display="flex" justifyContent="flex-end" p="1rem" />
      <Box pb="2rem" px="3rem">
        <Box mb="2rem">
          <Typography variant="h3" color="textSecondary">
            Business Information
          </Typography>
        </Box>
        <Grid container>
          <Grid item container xs={6} spacing={3}>
            <Grid item xs={6}>
              <Typography>Business Name</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textSecondary">{convertEmptyField(businessName)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Federal Tax ID</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textSecondary">{convertEmptyField(federalTaxId)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>City</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textSecondary">{convertEmptyField(city)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Street</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textSecondary">{convertEmptyField(street)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>State</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textSecondary">{convertEmptyField(state.name)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Zip Code</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textSecondary">{convertEmptyField(zipCode)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BusinessInfo

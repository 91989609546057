import React, { FC } from 'react'
import { Box, fade } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as ExpandIcon } from 'assets/svg/ExpandIcon.svg'
import Paper from '@material-ui/core/Paper/Paper'
import IconButton from '@material-ui/core/IconButton'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'
import useTheme from '@material-ui/core/styles/useTheme'
import { IChartProps } from 'typescript/interfaces/dashboard'
import { converToTwoSignsAfterDot } from 'utils/formatters'

const PaidOffAvg: FC<IChartProps> = ({ additionalRem = 0, onCollapse, header }) => {
  const theme = useTheme()
  const fontSizeHtml = parseFloat(getComputedStyle(document.documentElement).fontSize)

  const data = [
    { name: 'Not paid', value: 100 - (header?.paidOffAvg ?? 0) },
    { name: 'Paid', value: header?.paidOffAvg },
  ]

  return (
    <Paper elevation={0}>
      <Box height="100%" py="1rem" px="1rem" display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Paid Off AVG</Typography>
            <IconButton onClick={onCollapse} size="small">
              <SvgIcon fontSize="small" viewBox="0 0 16 16" component={ExpandIcon} />
            </IconButton>
          </Box>
          <Box>
            <Typography style={{ fontWeight: 600 }} variant="h4" color="textSecondary">
              {converToTwoSignsAfterDot(header?.paidOffAvg)}%
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <ResponsiveContainer width="100%" height={fontSizeHtml * (6.45 + additionalRem)}>
            <PieChart>
              <Pie
                dataKey="value"
                startAngle={90}
                endAngle={450}
                data={data}
                innerRadius={fontSizeHtml * (2.087 + additionalRem / 3)}
                outerRadius={fontSizeHtml * (2.212 + additionalRem / 3)}
              >
                <Tooltip labelFormatter={(index) => data?.[index as number]?.name} />
                <Cell strokeWidth={0} fill="#D0DDE8" />
                <Cell strokeWidth={0} fill={theme.palette.primary.main} />
              </Pie>
              <Pie
                dataKey="value"
                startAngle={90}
                endAngle={450}
                data={data}
                innerRadius={fontSizeHtml * (2.087 + additionalRem / 3)}
                outerRadius={fontSizeHtml * (3.237 + additionalRem / 3)}
              >
                <Tooltip labelFormatter={(index) => data?.[index as number]?.name} />
                <Cell strokeWidth={0} fill="transparent" />
                <Cell strokeWidth={0} fill={fade(theme.palette.primary.light, 0.15)} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Paper>
  )
}

export default PaidOffAvg

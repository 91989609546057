import { TableCell, TableRow } from '@material-ui/core'
import { TableSyndicatedDealStats, useSyndicatedDealStats } from 'hooks/useSyndicatedDeals'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getSyndicatedDealStats } from 'services/syndicators'
import { ISyndicatorReportListItem } from 'typescript/interfaces/syndicators'
import { useSyndicatedReportStatsStyles } from './style'

interface ISyndicatedDealStatsProps {
  row: ISyndicatorReportListItem
}

const SyndicatedDealStats = ({ row }: ISyndicatedDealStatsProps) => {
  const { columns } = useSyndicatedDealStats()
  const classes = useSyndicatedReportStatsStyles()
  const { id } = useParams<{ id: string }>()

  const { data = { data: [] }, isLoading } = useQuery(
    ['syndicated-deal-stat', row.deal, id],
    () => getSyndicatedDealStats({ DealId: `${row.deal}`, SyndicatorId: id }),
    {
      enabled: Boolean(row.tableOnlyRowExpanded && row.deal),
    },
  )

  return (
    <TableRow className={classes.rowBg}>
      <TableCell className={classes.tableCellContainer} colSpan={13}>
        <TableSyndicatedDealStats tableContainerClassName={classes.tableContainer} columns={columns} data={data.data} loading={isLoading} />
      </TableCell>
    </TableRow>
  )
}

export default SyndicatedDealStats

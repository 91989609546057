import React, { useState, useEffect } from 'react'
import { useTableComponentStyle } from 'UI/Table/style'
import { IColumn } from 'typescript/interfaces/tableUi'
import { IBankAccountClient } from 'typescript/interfaces/clients'
import TableComponent, { TableComponentType } from 'UI/Table'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteCircleIcon.svg'
import Checkbox from 'UI/Checkbox'

export const TableClientBankAccounts = TableComponent as TableComponentType<IBankAccountClient>

interface IUseClientBankAccountsProps {
  handleEdit: (id: number) => void
  handleDelete: (id: number) => void
  handleSetMain: (id?: number | string) => void
  readOnly: boolean
}

export const useClientBankAccounts = ({ handleEdit, handleDelete, handleSetMain, readOnly }: IUseClientBankAccountsProps) => {
  const classes = useTableComponentStyle()
  const [columns, setColumns] = useState<IColumn<IBankAccountClient>[]>([])

  useEffect(() => {
    setColumns([
      {
        label: 'Bank Account #',
        field: 'bankAccount',
        bodyCellClassName: classes.secondaryDarkCell,
        render: (data) => data.bankAccount.bankAccount,
      },
      {
        label: 'Bank Routing #',
        field: 'bankRouting',
        bodyCellClassName: classes.secondaryDarkCell,
        render: (data) => data.bankAccount.bankRouting,
      },
      {
        label: 'Bank Account Type',
        field: 'bankAccountType',
        bodyCellClassName: classes.secondaryDarkCell,
        render: (data) => data.bankAccount.bankAccountType,
      },
      {
        label: 'Bank Name',
        field: 'bankName',
        bodyCellClassName: classes.secondaryDarkCell,
        render: (data) => data.bankAccount.bankName,
      },
      ...(!readOnly
        ? [
            {
              label: 'Is Main',
              field: 'isMain',
              bodyCellClassName: classes.secondaryDarkCell,
              render: (data: IBankAccountClient) => (
                <Checkbox
                  disabled={data.isMain}
                  name="isMain"
                  onChange={(e) => handleSetMain(data.bankAccount.id)}
                  checked={data.isMain}
                  color="primary"
                />
              ),
            },
          ]
        : []),
      ...(!readOnly
        ? [
            {
              field: 'edit',
              label: 'Edit',
              render: (data: IBankAccountClient) => (
                <IconButton size="small" onClick={() => handleEdit(data.bankAccount.id ? +data.bankAccount.id : 0)}>
                  <EditIcon />
                </IconButton>
              ),
            },
          ]
        : []),
      ...(!readOnly
        ? [
            {
              field: 'delete',
              label: 'Delete',
              render: (data: IBankAccountClient) => (
                <IconButton size="small" onClick={() => handleDelete(data.bankAccount.id ? +data.bankAccount.id : 0)}>
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ]
        : []),
    ])
  }, [])

  return {
    columns,
  }
}

import React, { useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import { Box } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CalendarIcon } from 'assets/svg/CalendarIcon.svg'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { useLastDealsSecondViewStyle } from 'components/Dashboard/style'
import TableComponent, { TableComponentType } from 'UI/Table'
import { zeroInsteadNull, convertEmptyField, convertDate, formatBigNumber, converToTwoSignsAfterDot, convertToPriceFormat } from 'utils/formatters'
import { RepPortfolioSummarySortFields } from 'typescript/enums/deals'
import { IRepPortfolioSummaryRecord } from 'typescript/interfaces/deals'
import { useTableComponentStyle } from 'UI/Table/style'
import { DEAL_INFO_URL } from 'constants/routes'
import { Link, generatePath } from 'react-router-dom'

export const TableOverallDealsSummary = TableComponent as TableComponentType<IRepPortfolioSummaryRecord>

export const useOverallDealsSummary = () => {
  const classes = useLastDealsSecondViewStyle()
  const tableClasses = useTableComponentStyle()
  const columns = useRef<Array<IColumn<IRepPortfolioSummaryRecord>>>([
    {
      label: 'Merchant',
      field: 'merchant',
      sorting: false,
      headerCellClassName: classes.headerCell,
      bodyCellClassName: tableClasses.secondaryDarkCell,
      render: (data) => convertEmptyField(data.merchant),
    },
    {
      label: 'Deal #',
      field: RepPortfolioSummarySortFields.dealId,
      sorting: true,
      headerCellClassName: classes.headerCell,
      bodyCellClassName: tableClasses.secondaryDarkCell,
      render: (data) => <Link to={generatePath(DEAL_INFO_URL, { id: data.dealId })}>{convertEmptyField(data.dealId)}</Link>,
    },
    {
      label: 'Company',
      field: RepPortfolioSummarySortFields.company,
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => convertEmptyField(data.company),
    },
    {
      label: 'Start date',
      sorting: true,
      field: RepPortfolioSummarySortFields.startDate,
      bodyCellClassName: tableClasses.accentCell,
      headerCellClassName: classes.headerCell,
      render: (data) => {
        return (
          data.startDate && (
            <Box fontSize="1rem" display="flex" justifyContent="center">
              <Tooltip title={convertDate(data.startDate)} placement="top">
                <SvgIcon fontSize="inherit" viewBox="0 0 16 16" component={CalendarIcon} />
              </Tooltip>
            </Box>
          )
        )
      },
    },
    {
      label: 'End date',
      sorting: false,
      field: 'endDate',
      headerCellClassName: classes.headerCell,
      bodyCellClassName: tableClasses.secondaryBodyCell,
      render: (data) => {
        return (
          data.endDate && (
            <Box fontSize="1rem" display="flex" justifyContent="center">
              <Tooltip title={convertDate(data.endDate)} placement="top">
                <SvgIcon fontSize="inherit" viewBox="0 0 16 16" component={CalendarIcon} />
              </Tooltip>
            </Box>
          )
        )
      },
    },
    {
      label: 'ISO',
      field: 'iso',
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => convertEmptyField(data.iso),
    },
    {
      label: 'Status',
      field: 'status',
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => convertEmptyField(data.status),
    },

    {
      label: 'Factor rate',
      field: RepPortfolioSummarySortFields.factor,
      bodyCellClassName: tableClasses.secondaryBodyCell,
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => converToTwoSignsAfterDot(data.factor),
    },

    {
      label: 'ACH Payment',
      field: RepPortfolioSummarySortFields.frequentPayment,
      sorting: true,
      headerCellClassName: classes.headerCell,
      bodyCellClassName: classes.accentCell,
      render: (data) => formatBigNumber(data.frequentPayment),
    },
    {
      label: 'Advance amount',
      field: RepPortfolioSummarySortFields.advanceAmount,
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => formatBigNumber(data.advanceAmount),
    },
    {
      label: 'ISO FEE',
      field: RepPortfolioSummarySortFields.isoFee,
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => convertToPriceFormat(data.isoFee),
    },

    {
      label: 'Payback amount',
      field: RepPortfolioSummarySortFields.payback,
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => formatBigNumber(data.paybackAmount),
    },
    {
      label: 'Paid Off',
      field: 'paidOff',
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => `${zeroInsteadNull(data.paidOff)}%`,
    },
    {
      label: 'Commission To Rep',
      field: RepPortfolioSummarySortFields.totalCommission,
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => formatBigNumber(data.commissionToRep),
    },
    {
      label: 'PSF',
      field: RepPortfolioSummarySortFields.psf,
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => formatBigNumber(data.psf),
    },
    {
      label: 'Remain payback',
      field: 'remain-payback',
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => formatBigNumber(data.paybackAmount - data.totalPaid),
    },
  ])

  return {
    columns: columns.current,
  }
}

import React, { FC, useCallback, useMemo, useState } from 'react'
import { usePopoverStyles } from 'containers/Deal/DealInfo/style'
import { Box, IconButton, MenuItem, Popover, PopoverProps, Typography } from '@material-ui/core'
import { DealsStatus, DealsType } from 'typescript/enums/deals'
import { DEAL_SCHEDULE_PAYMENT_PERM } from 'constants/permissions'
import { IInhouseDealResponse, ISchedulePaymentInitValues } from 'typescript/interfaces/deals'
import { usePermission } from 'hooks/usePermission'
import cn from 'classnames'
import { ReactComponent as CancelIcon } from 'assets/svg/CloseIcon.svg'
import SchedulePaymentForm from 'components/SchedulePaymentForm'
import ModalComponent from 'UI/Modal'
import { useMutation, useQueryCache } from 'react-query'
import { createSchedule, deleteAllSchedules, getDeal, getSchedulesByDeal } from 'services/deals'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'

interface Props extends PopoverProps {
  deal?: IInhouseDealResponse
}

const SchedulePopover: FC<Props> = ({ anchorEl, open, onClose, deal, ...rest }) => {
  const classes = useAdditionalMaterialStyle()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<{ id: string }>()
  const queryCache = useQueryCache()
  const { hasPermission } = usePermission()
  const s = usePopoverStyles()
  const [openSchedule, setOpenSchedule] = useState(false)
  const [openDeleteSchedule, setOpenDeleteSchedule] = useState(false)

  const handleClosePopover = useCallback(() => {
    // @ts-ignore
    onClose(new Event('click'), 'backdropClick')
  }, [])

  const disableSchedule = useMemo(() => {
    return deal?.status !== DealsStatus.approved || deal?.type === DealsType.outhouse || !hasPermission(DEAL_SCHEDULE_PAYMENT_PERM)
  }, [deal])

  const [createScheduleMut, { isLoading }] = useMutation(createSchedule, {
    onSuccess: () => {
      queryCache.fetchQuery(['inhouse-deal', id], () => getDeal(id))
      queryCache.fetchQuery(['schedulesDeal', id], () => getSchedulesByDeal(id), {
        retry: false,
        cacheTime: 0,
        enabled: id,
      })
      enqueueSnackbar('Schedule payment successfully created')
      setOpenSchedule(false)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [deleteAllSchedulesMut, { isLoading: isLoadingDeleteAllSchedules }] = useMutation(deleteAllSchedules, {
    onSuccess: () => {
      queryCache.fetchQuery(['inhouse-deal', id], () => getDeal(id))
      queryCache.fetchQuery(['schedulesDeal', id], () => getSchedulesByDeal(id), {
        retry: false,
        cacheTime: 0,
        enabled: id,
      })
      setOpenDeleteSchedule(false)
      enqueueSnackbar('Schedules successfully deleted')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSubmit = useCallback(
    (values: ISchedulePaymentInitValues) => {
      delete values.bankAccount
      createScheduleMut(values)
    },
    [createScheduleMut],
  )

  return (
    <>
      <Popover
        classes={{ paper: s.popover }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...rest}
      >
        <MenuItem
          className={s.menuItem}
          disabled={disableSchedule}
          onClick={() => {
            setOpenSchedule(true)
            handleClosePopover()
          }}
        >
          <Typography variant="h4" color="textSecondary">
            New Schedule
          </Typography>
        </MenuItem>
        <MenuItem
          className={s.menuItem}
          disabled={disableSchedule}
          onClick={() => {
            setOpenDeleteSchedule(true)
            handleClosePopover()
          }}
        >
          <Typography variant="h4" color="textSecondary">
            Delete All Schedules
          </Typography>
        </MenuItem>
      </Popover>
      <ModalComponent open={openSchedule}>
        <Box maxWidth={500}>
          <Box display="flex" justifyContent="flex-end" mt="-1rem" mr="-1rem">
            <IconButton
              color="secondary"
              size="small"
              onClick={() => setOpenSchedule(false)}
              classes={{
                root: cn(classes.closeTrsModalButton),
              }}
            >
              <CancelIcon />
            </IconButton>
          </Box>
          <Typography variant="h2" color="textSecondary">
            Schedule Recurring Payments - Deal #{id}
          </Typography>
          {deal && (
            <Box mt="2rem">
              <SchedulePaymentForm
                endDate={deal.plannedEndDate}
                bankAccount={deal.bankAccount?.bankAccount}
                dealId={id}
                cancel={() => setOpenSchedule(false)}
                submit={handleSubmit}
                loading={isLoading}
              />
            </Box>
          )}
        </Box>
      </ModalComponent>
      <ModalComponent open={openDeleteSchedule}>
        <ConfirmationContent
          isLoading={isLoadingDeleteAllSchedules}
          handleCancel={() => setOpenDeleteSchedule(false)}
          handleConfirm={() => deleteAllSchedulesMut(id)}
          text="Are you sure you want to delete all schedules?"
        />
      </ModalComponent>
    </>
  )
}

export default SchedulePopover

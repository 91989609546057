import React, { useRef } from 'react'
import { SortFieldRep } from 'typescript/interfaces/rep'
import { IColumn } from 'typescript/interfaces/tableUi'
import { Link } from 'react-router-dom'
import { BANK_URL } from 'constants/routes'
import { useTableComponentStyle } from 'UI/Table/style'
import { Box } from '@material-ui/core'
import TableComponent, { TableComponentType } from 'UI/Table'
import { convertEmptyField } from 'utils/formatters'
import { IBankResponse, SortFieldsBanks } from 'typescript/interfaces/banks'

export const TableBanks = TableComponent as TableComponentType<IBankResponse>

export const useBanks = () => {
  const classes = useTableComponentStyle()
  const columns = useRef<Array<IColumn<IBankResponse>>>([
    {
      label: 'Bank name',
      field: SortFieldsBanks.bankName,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => <Link to={BANK_URL.replace(':id', `${data.id}`)}>{data.userPage.bankName}</Link>,
    },
    {
      label: 'Street',
      field: SortFieldsBanks.street,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.street),
    },
    {
      label: 'City',
      field: SortFieldsBanks.city,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.city),
    },
    {
      label: 'Zip code',
      field: SortFieldsBanks.zipCode,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.zipCode),
    },
    {
      label: 'Bank routing',
      field: SortFieldsBanks.bankRouting,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => <Box whiteSpace="nowrap">{convertEmptyField(data.bankRouting)}</Box>,
    },
    {
      label: 'Account number',
      field: SortFieldsBanks.accountNumber,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.accountNumber),
    },
    {
      label: 'State',
      field: SortFieldRep.state,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.state.name),
    },
  ])

  return {
    columns: columns.current,
  }
}

import React from 'react'

import { Box, Button, Checkbox, FormControlLabel, makeStyles, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'

import { schemaConfirmSignUp } from './schema'

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: 24,
  },
  input2: {
    marginBottom: 16,
  },
  button: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 48,
    color: '#4C5878',
  },
  smallText: {
    marginBottom: 36,
    fontSize: 19,
  },
  link: {
    color: '#249AF3',
    textDecoration: 'none',
    fontSize: 19,
  },
  checkboxLabel: {
    color: '#4C5878',
    fontSize: 17,
    marginBottom: -3,
  },
}))

const ConfirmSignUpForm = () => {
  const s = useStyles()
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: '',
      accept: false,
    },
    validationSchema: schemaConfirmSignUp,
    onSubmit: () => {},
  })
  return (
    <Box
      width="520px"
      maxWidth="80%"
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Typography className={s.text} align="center">
        Welcome!
      </Typography>
      <Typography className={s.smallText} align="center">
        Set a secure password for signing in to FundersLink and accessing your data
      </Typography>

      <TextField
        size="small"
        className={s.input2}
        variant="outlined"
        fullWidth
        label="Password"
        name="password"
        onChange={handleChange}
        value={values.password}
        error={!!errors.password && !!touched.password}
        helperText={!!touched.password && errors.password}
      />
      <FormControlLabel
        classes={{
          label: s.checkboxLabel,
        }}
        label="I accept the Terms of Service"
        onChange={handleChange}
        control={<Checkbox color="primary" checked={values.accept} name="accept" />}
      />
      <Button type="submit" className={s.button} color="primary" variant="contained" fullWidth>
        Confirm
      </Button>
    </Box>
  )
}

export default ConfirmSignUpForm

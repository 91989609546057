import React, { FC, useMemo } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete/Autocomplete'
import { useQuery } from 'react-query'
import { Value } from '@material-ui/lab'
import { Chip, makeStyles } from '@material-ui/core'
import { getRolesLight } from 'services/users'
import { IRole } from 'typescript/interfaces/users'

interface IAutoCompleteProps {
  roles: any[]
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void
  error?: boolean
  helperText?: string | false
  label?: string
}

export const useMultipleAutocompleteStyles = makeStyles((theme) => ({
  inputRoot: {
    paddingTop: '0.25rem !important',
    paddingBottom: '0.25rem !important',
    paddingRight: '1rem !important',
  },
}))

const RolesAutoComplete: FC<IAutoCompleteProps> = ({ roles, onChange, helperText, error, label = 'Roles' }) => {
  const { data, isLoading } = useQuery(['roles'], () => getRolesLight({ PageSize: 4000 }))
  const s = useMultipleAutocompleteStyles()

  const arrayRoles = useMemo(() => {
    return data ? data.data : []
  }, [data])

  return (
    <Autocomplete
      multiple
      value={roles}
      onChange={onChange}
      options={arrayRoles}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option: IRole) => option.name}
      renderTags={(tagValue, getTagProps) => tagValue.map((option: IRole, index) => <Chip label={option.name} {...getTagProps({ index })} />)}
      classes={{ inputRoot: s.inputRoot }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            ref: params.InputProps.ref,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} />
              </>
            ),
          }}
          helperText={helperText}
          error={error}
          label={label}
          variant="outlined"
        />
      )}
    />
  )
}

export default RolesAutoComplete

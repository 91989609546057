import React, { FC, useEffect } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import SearchField from 'UI/SearchField'
import Paper from '@material-ui/core/Paper/Paper'
import { usePaginationList } from 'hooks/usePaginationList'
import { useRequestFundersInfo, TableRequestFundersInfo } from 'hooks/useRequestFundersInfo'
import { useMutation } from 'react-query'
import { getFunders } from 'services/rep'
import { IRequestFunderListItem } from 'typescript/interfaces/rep'
import { requestCooperation, requestInfo } from 'services/syndicators'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const RequestFunderInfoContainer: FC = () => {
  const additionalStyles = useAdditionalMaterialStyle()

  const { pagination, handleFetchMore, handleSort, handleSearch, setPagination } = usePaginationList<IRequestFunderListItem>({
    initState: {
      data: [],
      search: '',
      order: 'id',
      sortOrder: 'DESC',
      page: 0,
      hasMore: false,
      syndicatorId: '',
    },
  })

  const [getDealListMut, { isLoading }] = useMutation(getFunders, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [requestCooperationMut, { isLoading: isCooperationLoading }] = useMutation(requestCooperation, {
    onSuccess: () => {
      if (pagination.page === 0) {
        getDealListMut(
          {
            PageSize: 20,
            PageIndex: pagination.page,
            SortField: pagination.order,
            SortOrder: pagination.sortOrder,
          },
          {
            onSuccess: (data) => {
              setPagination((prevState) => ({
                ...prevState,
                data: [...data.data],
                hasMore: data.data.length === 20,
              }))
            },
          },
        )
      } else {
        setPagination((prevState) => ({ ...prevState, data: [], page: 0 }))
      }
    },
  })

  const [requestInfoMut, { isLoading: isInfoLoading }] = useMutation(requestInfo, {
    onSuccess: (data) => {
      if (pagination.page === 0) {
        setPagination((prevState) => ({ ...prevState, data: [] }))
        getDealListMut({
          PageSize: 20,
          PageIndex: pagination.page,
          SortField: pagination.order,
          SortOrder: pagination.sortOrder,
        })
      } else {
        setPagination((prevState) => ({ ...prevState, data: [], page: 0 }))
      }
    },
  })

  useEffect(() => {
    getDealListMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      Query: pagination.search,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.syndicatorId, pagination.search])

  const { columns } = useRequestFundersInfo({
    handleRequestInfo: requestInfoMut,
    handleCooperationInfo: requestCooperationMut,
    isCooperationLoading,
    isInfoLoading,
  })

  return (
    <Paper className={additionalStyles.wrapper} elevation={0}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          Funders
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width={240}>
            <SearchField handleSearch={handleSearch} fullWidth label="Search by business name" />
          </Box>
        </Box>
      </Box>
      <Box>
        <TableRequestFundersInfo
          columns={columns}
          data={pagination.data}
          handleSortChange={handleSort}
          hasMore={pagination.hasMore}
          handleGetMore={handleFetchMore}
          loading={isLoading}
        />
      </Box>
    </Paper>
  )
}

export default RequestFunderInfoContainer

import * as yup from 'yup'
import { REQUIRED_FIELD } from 'constants/errors'
import { strongPass } from 'constants/regExp'

export const schemaSignUp = yup.object().shape({
  email: yup.string(),
  password: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(
      strongPass,
      'Password must ' +
        'contains minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)',
    ),
  confirmPassword: yup
    .string()
    .required(REQUIRED_FIELD)
    .oneOf([yup.ref('password')], 'Passwords must match'),
})

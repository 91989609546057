import * as yup from 'yup'
import { REQUIRED_FIELD } from 'constants/errors'

export const schemaNewCommentLogForm = yup.object().shape({
  //   message: yup.string().required(REQUIRED_FIELD),
  text: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('only empty characters', REQUIRED_FIELD, function () {
      const message = this.parent.text ?? ''
      return message.replace(/\n/g, '').replace(/ /g, '')
    }),
})

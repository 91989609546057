import React, { FC, useRef, useEffect } from 'react'
import { useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField/TextField'
import RequiredOption from 'UI/Select/RequiredOption'
import DatePicker from 'UI/DatePicker/DatePicker'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import SelectComponent from 'UI/Select'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/svg/ArrowRightIcon.svg'
import { IOwnerInitValue, IOwnerInfoFormProps } from 'typescript/interfaces/owners'
import ReactInputMask from 'react-input-mask'
import { schemaOwners } from 'components/ClientForms/OwnerForm/schema'
import { useStates } from 'hooks/useStates'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'

const defaultInitOwnerValues: IOwnerInitValue = {
  clientId: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  title: '',
  ownership: '',
  ssn: '',
  ownershipDate: '',
  driverLicense: '',
  dlExpiredDate: '',
  stateIssuedDL: '',
  stateId: '',
  city: '',
  street: '',
  zipCode: '',
  email: '',
  mobile: '',
  fax: '',
}

const OwnerForm: FC<IOwnerInfoFormProps> = ({
  initialValues = defaultInitOwnerValues,
  submit,
  loading,
  countOwners,
  createMode = true,
  handleClickBack,
}) => {
  const variantSubmit = useRef<number>(1)
  const { data: dataStates } = useStates()
  const { handleSubmit, values, setFieldValue, touched, errors, handleChange, resetForm } = useFormik({
    onSubmit: (vals) => {
      submit.handler(vals, variantSubmit.current)
    },
    initialValues: initialValues,
    validationSchema: schemaOwners,
  })

  useEffect(() => {
    if (countOwners) resetForm()
  }, [countOwners])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <TextField
            name="firstName"
            value={values.firstName}
            InputProps={{
              style: {
                fontFamily: 'system-ui', //To show system symbols
              },
            }}
            error={Boolean(errors.firstName && touched.firstName)}
            helperText={touched.firstName && errors.firstName}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            label={<RequiredOption label="First Name" />}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="lastName"
            value={values.lastName}
            InputProps={{
              style: {
                fontFamily: 'system-ui', //To show system symbols
              },
            }}
            error={Boolean(errors.lastName && touched.lastName)}
            helperText={touched.lastName && errors.lastName}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            label={<RequiredOption label="Last Name" />}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            name="birthDate"
            value={values.birthDate ? values.birthDate : null}
            onChange={(date) => setFieldValue('birthDate', convertDateForPicker(date))}
            error={Boolean(touched.birthDate && errors.birthDate) || !isValidDate(values.birthDate)}
            helperText={(touched.birthDate && errors.birthDate) || (!isValidDate(values.birthDate) && 'MM/DD/YYYY')}
            inputVariant="outlined"
            variant="dialog"
            type="keyboard"
            label={<RequiredOption label="Date Of Birth" />}
          />
        </Grid>
      </Grid>
      <Box mt="1rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <TextField
              name="title"
              value={values.title}
              InputProps={{
                style: {
                  fontFamily: 'system-ui', //To show system symbols
                },
              }}
              error={Boolean(errors.title && touched.title)}
              helperText={touched.title && errors.title}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="Title" />}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="ownership"
              value={values.ownership}
              error={Boolean(errors.ownership && touched.ownership)}
              helperText={touched.ownership && errors.ownership}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="Ownership %" />}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="ssn"
              value={values.ssn}
              error={Boolean(errors.ssn && touched.ssn)}
              helperText={touched.ssn && errors.ssn}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="SSN" />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="1rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <DatePicker
              name="ownershipDate"
              value={values.ownershipDate ? values.ownershipDate : null}
              onChange={(date) => setFieldValue('ownershipDate', convertDateForPicker(date))}
              fullWidth
              inputVariant="outlined"
              variant="dialog"
              type="keyboard"
              label="Ownership Date"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="driverLicense"
              value={values.driverLicense}
              error={Boolean(errors.driverLicense && touched.driverLicense)}
              helperText={touched.driverLicense && errors.driverLicense}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="Driver's License" />}
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              name="dlExpiredDate"
              value={values.dlExpiredDate ? values.dlExpiredDate : null}
              onChange={(date) => setFieldValue('dlExpiredDate', convertDateForPicker(date))}
              error={Boolean(touched.dlExpiredDate && errors.dlExpiredDate) || !isValidDate(values.dlExpiredDate)}
              helperText={(touched.dlExpiredDate && errors.dlExpiredDate) || (!isValidDate(values.dlExpiredDate) && 'MM/DD/YYYY')}
              inputVariant="outlined"
              variant="dialog"
              type="keyboard"
              label={<RequiredOption label="DL Exp Date" />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="1rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <TextField
              name="stateIssuedDL"
              value={values.stateIssuedDL}
              error={Boolean(errors.stateIssuedDL && touched.stateIssuedDL)}
              helperText={touched.stateIssuedDL && errors.stateIssuedDL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="State Issued DL" />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="3rem" mx="-3rem">
        <Divider />
      </Box>
      <Box mt="3rem">
        <Typography variant="h3" color="textSecondary">
          Home Address
        </Typography>
      </Box>
      <Box mt="3rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <TextField
              name="street"
              value={values.street}
              error={Boolean(errors.street && touched.street)}
              helperText={touched.street && errors.street}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="Home Address" />}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="city"
              value={values.city}
              error={Boolean(errors.city && touched.city)}
              helperText={touched.city && errors.city}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="City" />}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectComponent
              name="stateId"
              value={values.stateId}
              onChange={handleChange}
              error={Boolean(errors.stateId && touched.stateId)}
              helperText={touched.stateId && errors.stateId}
              label={<RequiredOption label="State" />}
            >
              {dataStates &&
                dataStates.data.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.name}
                  </MenuItem>
                ))}
            </SelectComponent>
          </Grid>
        </Grid>
        <Box mt="1rem">
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <TextField
                name="zipCode"
                value={values.zipCode}
                error={Boolean(errors.zipCode && touched.zipCode)}
                helperText={touched.zipCode && errors.zipCode}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                label={<RequiredOption label="Zip Code" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt="3rem" mx="-3rem">
        <Divider />
      </Box>
      <Box mt="3rem">
        <Typography variant="h3" color="textSecondary">
          Contact Information
        </Typography>
      </Box>
      <Box mt="3rem">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <TextField
              name="email"
              value={values.email}
              error={Boolean(errors.email && touched.email)}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="Email" />}
            />
          </Grid>
          <Grid item xs={4}>
            <ReactInputMask name="mobile" mask="(999)-999-9999" value={values.mobile} onChange={handleChange}>
              {(props: any) => (
                <TextField
                  {...props}
                  error={Boolean(errors.mobile && touched.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  label={<RequiredOption label="Mobile" />}
                  variant="outlined"
                  fullWidth
                />
              )}
            </ReactInputMask>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="fax"
              value={values.fax}
              error={Boolean(errors.fax && touched.fax)}
              helperText={touched.fax && errors.fax}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label="Fax"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="3rem" display="flex" alignItems="center">
        <Box mr={5}>
          <Button color="secondary" onClick={handleClickBack} variant="contained" startIcon={<ArrowLeftIcon />}>
            Back
          </Button>
        </Box>
        {createMode && (
          <Box mr={5}>
            <Button loading={loading} color="primary" type="submit" onClick={() => (variantSubmit.current = 2)} variant="contained">
              Add Another Owner
            </Button>
          </Box>
        )}
        <Button
          loading={loading}
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => (variantSubmit.current = 1)}
          endIcon={<ArrowRightIcon />}
        >
          {submit.label}
        </Button>
      </Box>
    </form>
  )
}

export default OwnerForm

import { Box, Button, IconButton, Paper, SvgIcon, Typography } from '@material-ui/core'
import React, { FC, useCallback, useMemo, useEffect } from 'react'
import { ReactComponent as ArrowLeft } from 'assets/svg/ArrowLeftIcon.svg'
import { ReactComponent as FilterIcon } from 'assets/svg/FilterIcon.svg'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import DatePicker from 'UI/DatePicker/DatePicker'
import { useFormik } from 'formik'
import CustomIconButton from 'UI/Button/IconButton'
import { usePaginationList } from 'hooks/usePaginationList'
import { endOfDay, startOfMonth } from 'date-fns'
import { ChartEnum, ChartEnumKey } from 'typescript/enums/charts'
import { DASHBOARD_VIEW_FIRST, DASHBOARD_VIEW_FIRST_WITH_CHART, DASHBOARD_VIEW_SECOND } from 'constants/routes'
import { ReactComponent as CollapseIcon } from 'assets/svg/CollapseIcon.svg'
import { IStatisticWithChartsResponse } from 'typescript/interfaces/deals'
import { converToTwoSignsAfterDot, convertToPriceFormatWithoutCents } from 'utils/formatters'
import { IChartProps } from 'typescript/interfaces/dashboard'
import { useQuery } from 'react-query'
import { getDealStatsForChart } from 'services/deals'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'
import { useDetailedChartStyles } from '../style'
import { schemaDetailedChartFilters } from '../schema'
import DailyChartDetailed from './DailyChartDetailed'
import PaidOffAvgDetailed from './PaidOffAvgDetailed'
import PNLChartDetailed from './PNLDetailed'
import TermFactorAvgChartDetailed from './TermFactorAvgDetailed'

interface IDetailedChartProps {
  stats?: IStatisticWithChartsResponse
}

const DetailedChart: FC<IDetailedChartProps> = () => {
  const s = useDetailedChartStyles()
  const { push } = useHistory()
  const { chart, from } = useParams<{ chart: ChartEnumKey; from: '1' | '2' }>()
  const { values, touched, errors, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      from: startOfMonth(new Date()),
      to: endOfDay(new Date()),
    },
    validationSchema: schemaDetailedChartFilters,
    onSubmit: (formValues) => {
      if (formValues.from === pagination.from && formValues.to === pagination.to) {
        return
      }
      setPagination((prevState) => ({ ...prevState, ...formValues }))
    },
  })

  useEffect(() => {
    if (chart === 'paidOffAvg') {
      resetForm()
      setPagination((prevState) => ({
        ...prevState,
        from: startOfMonth(new Date()),
        to: endOfDay(new Date()),
      }))
    }
  }, [chart])

  const { pagination, setPagination } = usePaginationList({
    initState: {
      data: [],
      search: '',
      order: '',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
      from: startOfMonth(new Date()).toISOString(),
      to: endOfDay(new Date()).toISOString(),
    },
  })

  const loadStats = useCallback(
    () =>
      getDealStatsForChart({
        From: pagination.from,
        To: pagination.to,
      }),
    [pagination.from, pagination.to],
  )

  const { data: chartsData } = useQuery(
    ['charts-data', pagination.from, pagination.to],
    loadStats,
    // eslint-disable-next-line function-paren-newline
  )

  const charts: { [key: string]: FC<IChartProps> } = {
    daily: DailyChartDetailed,
    paidOffAvg: PaidOffAvgDetailed,
    pnl: PNLChartDetailed,
    termFactor: TermFactorAvgChartDetailed,
  }

  const ChartToShow = charts[chart]

  const title = useMemo(() => {
    switch (chart) {
      case 'daily': {
        return convertToPriceFormatWithoutCents(chartsData?.data?.frequent)
      }
      case 'pnl': {
        return (
          chartsData &&
          chartsData.data.pnl !== undefined &&
          (chartsData.data.pnl >= 0
            ? convertToPriceFormatWithoutCents(chartsData.data.pnl)
            : `(${convertToPriceFormatWithoutCents(Math.abs(chartsData.data.pnl))})`)
        )
      }
      case 'paidOffAvg': {
        return `${converToTwoSignsAfterDot(chartsData?.data?.paidOff)}%`
      }
      case 'termFactor': {
        return `${converToTwoSignsAfterDot(chartsData?.data?.factor)}`
      }
      default: {
        return 'n/a'
      }
    }
  }, [chart, chartsData])

  const handleBack = () => push(from === '1' ? DASHBOARD_VIEW_FIRST : DASHBOARD_VIEW_SECOND)

  return (
    <Box width="calc(100% - 1.5rem)" component={Paper} p="2rem" height="calc(100vh - 9rem)" display="flex" flexDirection="column" position="relative">
      <Box position="absolute" top="0.5rem" right="0.5rem">
        <IconButton onClick={handleBack} size="small">
          <SvgIcon fontSize="small" viewBox="0 0 16 16" component={CollapseIcon} />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" mb="1rem">
        <Box display="flex">
          <Box mr="1.5rem">
            <IconButton color="secondary" onClick={handleBack} className={s.navButton}>
              <ArrowLeft />
            </IconButton>
          </Box>
          <Box>
            <Typography color="textSecondary" className={s.title}>
              {ChartEnum[chart]}
            </Typography>
            <Typography noWrap color="textSecondary" className={s.value}>
              {title}
            </Typography>
          </Box>
        </Box>
        {chart !== 'paidOffAvg' && (
          <Box display="flex" alignItems="center">
            <Box maxWidth="274px">
              <DatePicker
                fullWidth
                label="From"
                maxDate={values.to}
                name="from"
                value={values.from || null}
                error={Boolean(touched.from && errors.from) || !isValidDate(values.from)}
                helperText={(touched.from && errors.from) || (!isValidDate(values.from) && 'MM/DD/YYYY')}
                type="keyboard"
                onChange={(date) => setFieldValue('from', convertDateForPicker(date))}
              />
            </Box>
            <Box maxWidth="274px" ml="1rem">
              <DatePicker
                fullWidth
                label="To"
                name="to"
                minDate={values.from}
                value={values.to || null}
                error={Boolean(touched.to && errors.to) || !isValidDate(values.to)}
                helperText={(touched.to && errors.to) || (!isValidDate(values.to) && 'MM/DD/YYYY')}
                type="keyboard"
                onChange={(date) => setFieldValue('to', convertDateForPicker(date))}
              />
            </Box>
            <Box minWidth={36} width={138} ml={3}>
              <CustomIconButton onClick={() => handleSubmit()} size="small" color="secondary" width="100%" height={36}>
                <SvgIcon fontSize="inherit" viewBox="0 0 16 13" color="secondary" component={FilterIcon} />
              </CustomIconButton>
            </Box>
          </Box>
        )}
      </Box>
      <Box flex="1">
        <ChartToShow stats={chartsData?.data} />
      </Box>
      <Box mt="1rem" display="flex" alignItems="center">
        {Object.entries(ChartEnum).map(([key, value]) => (
          <Box mr="0.75rem">
            <Button
              color="secondary"
              variant={key === chart ? 'outlined' : 'contained'}
              size="small"
              key={key}
              className={s.btn}
              onClick={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { view: 1, chart: key }))}
            >
              {value}
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default DetailedChart

import {
  API_GET_USERS,
  API_GET_USERS_LIGHT,
  API_GET_ROLES,
  API_GET_ROLES_LIGHT,
  API_ADD_ROLE,
  API_DELETE_ROLE,
  API_SET_USER_ROLES,
  API_GET_CURRENT_USER,
  API_GET_EMAIL_BY_TOKEN,
  API_UPDATE_USER,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { AxiosResponse } from 'axios'
import { IPagination } from 'typescript/interfaces/pagination'
import { ICreateRoleForm, IRole, IRoleLight, ISetUserRoles, IUser, IUserEntity, IUserLight } from 'typescript/interfaces/users'
import { publicInstance } from 'services/axios/publicInstance'
import { generatePath } from 'react-router-dom'

export const getUsersLight = (params: IPagination) =>
  privateInstance.get<IPagination<IUserLight>, AxiosResponse<Array<IUserLight>>>(API_GET_USERS_LIGHT, {
    params,
  })

export const getRolesLight = (params: IPagination) =>
  privateInstance.get<IRole, AxiosResponse<Array<IRoleLight>>>(API_GET_ROLES_LIGHT, {
    params,
  })

export const getRoles = (params: IPagination) =>
  publicInstance.get<IRole, AxiosResponse<Array<IRole>>>(API_GET_ROLES, {
    params,
  })

export const addNewRole = (body: ICreateRoleForm) => publicInstance.post<IRole, AxiosResponse<IRole>>(API_ADD_ROLE, body)

export const deleteRole = (id: string) => publicInstance.delete<IRole, AxiosResponse<null>>(API_DELETE_ROLE.replace(':id', id))

export const getUsers = (params: IPagination) =>
  publicInstance.get<IRole, AxiosResponse<Array<IUser>>>(API_GET_USERS, {
    params,
  })

export const setUserRoles = (body: ISetUserRoles) =>
  publicInstance.post<IRole, AxiosResponse<any>>(API_SET_USER_ROLES, { userId: body.userId, rolesIds: body.roles.map((role) => role.id) })

export const getCurrentUser = () => publicInstance.get<void, AxiosResponse<IUserEntity>>(API_GET_CURRENT_USER)

export const getEmailByToken = (token: string) =>
  publicInstance.get<{ token: string }, AxiosResponse<IUserEntity>>(
    generatePath(API_GET_EMAIL_BY_TOKEN, {
      token: token,
    }),
  )

export const updateUser = (values: Partial<IUser>) => publicInstance.post<Partial<IUser>, AxiosResponse<boolean>>(API_UPDATE_USER, values)

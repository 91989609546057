import React, { FC, useEffect } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import SearchField from 'UI/SearchField'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as AddSquareIcon } from 'assets/svg/PlustSquareIcon.svg'
import { REP_ADD_NEW_URL } from 'constants/routes'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import Paper from '@material-ui/core/Paper/Paper'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { getRepList } from 'services/rep'
import { IRepListItem, SortFieldRep } from 'typescript/interfaces/rep'
import { TableRep, useRep } from 'hooks/useRep'
import { usePaginationList } from 'hooks/usePaginationList'
import { exportRep } from 'services/export'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { ExportType } from 'typescript/enums/export'
import { downloadFile } from 'utils/downloadFile'
import { usePermission } from 'hooks/usePermission'
import { CREATE_REP_PERM } from 'constants/permissions'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const RepListContainer: FC = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  const { hasPermission } = usePermission()
  const { push } = useHistory()
  const { columns } = useRep()
  const { enqueueSnackbar } = useSnackbar()
  const { pagination, setPagination, handleFetchMore, handleSort, handleSearch } = usePaginationList<IRepListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldRep.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getRep, { isLoading }] = useMutation(getRepList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [exportRepExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportRep, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    getRep({
      PageSize: 20,
      PageIndex: pagination.page,
      Query: pagination.search,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder])

  return (
    <Paper className={additionalStyle.wrapper} elevation={0}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          REP LIST
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width={240} mr={5}>
            <SearchField handleSearch={handleSearch} fullWidth label="Search Representative" />
          </Box>
          {hasPermission(CREATE_REP_PERM) && (
            <Button variant="text" color="secondary" startIcon={<AddSquareIcon />} onClick={() => push(REP_ADD_NEW_URL)}>
              Add New Rep
            </Button>
          )}
          <Box ml={5}>
            <Button
              loading={isLoadingExportExcel}
              onClick={() => {
                exportRepExcelMut({
                  ExportType: ExportType.excel,
                })
              }}
              variant="text"
              color="secondary"
              startIcon={<ExcelIcon />}
            >
              Export to Excel
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableRep
          loading={isLoading}
          columns={columns}
          data={pagination.data}
          handleSortChange={handleSort}
          hasMore={pagination.hasMore}
          handleGetMore={handleFetchMore}
        />
      </Box>
    </Paper>
  )
}

export default RepListContainer

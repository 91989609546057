import React, { FC, useCallback, useEffect, useRef } from 'react'
import RootRef from '@material-ui/core/RootRef'
import { Box, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SelectGroup from 'UI/Select/SelectGroup'
import { useSelectComponentStyle } from 'UI/Select'
import CustomIconButton from 'UI/Button/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as FilterIcon } from 'assets/svg/FilterIcon.svg'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { useLastDealsSecondViewStyle } from 'components/Dashboard/style'
import Paper from '@material-ui/core/Paper'
import FooterTableLatestDeals from 'components/Dashboard/FooterLatestDealsSecondDashboard'
import { TableLastDealsSecondView, useFullDeals } from 'hooks/useFullDeals'
import { DealsStatus, SortFieldsDeal } from 'typescript/enums/deals'
import { IDealsFilters, IDealsListItem } from 'typescript/interfaces/deals'
import { usePaginationList } from 'hooks/usePaginationList'
import { useFormik } from 'formik'
import { schemaLatestDealsFilters } from 'containers/Deal/LatestDeals/schema'
import { useMutation, useQuery } from 'react-query'
import { getDealList, getDealStats } from 'services/deals'
import ISOAutoComplete from 'components/ISOAutoComplete'
import RepAutoComplete from 'components/RepAutoComplete'
import { useLatestDealsStyle } from 'containers/Deal/LatestDeals/style'
import { downloadFile } from 'utils/downloadFile'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { exportDeals } from 'services/export'
import { ExportType } from 'typescript/enums/export'
import { useSnackbar } from 'notistack'
import ClientAutoComplete from 'components/ClientAutoComplete'
import SearchField from 'UI/SearchField'

const LatestDealsSecondView: FC = () => {
  const classes = useLastDealsSecondViewStyle()
  const tableWrapperRef = useRef<HTMLDivElement>()
  const { columns } = useFullDeals({ view: 'dashboard' })
  const { enqueueSnackbar } = useSnackbar()

  const selectGroupClasses = useSelectComponentStyle()
  const s = useLatestDealsStyle()

  const { setPagination, handleFetchMore, handleSort, pagination, handleSearch } = usePaginationList<IDealsListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsDeal.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const handleFiltersSubmit = (filtersToSet: IDealsFilters) => {
    if (
      filtersToSet.repId === pagination.repId &&
      filtersToSet.isoId === pagination.isoId &&
      filtersToSet.labelId === pagination.labelId &&
      filtersToSet.clientId === pagination.clientId &&
      filtersToSet.from === pagination.from &&
      filtersToSet.to === pagination.to &&
      filtersToSet.search === pagination.search
    )
      return
    setPagination((prevState) => ({ ...prevState, ...filtersToSet, page: 0, data: [] }))
  }

  const { values: filters, setValues, handleSubmit, errors, touched, handleChange } = useFormik<IDealsFilters>({
    initialValues: {
      repId: undefined,
      isoId: undefined,
      labelId: undefined,
      from: undefined,
      to: undefined,
      clientId: undefined,
      search: undefined,
    },
    validationSchema: schemaLatestDealsFilters,
    onSubmit: handleFiltersSubmit,
  })

  const handleRepChange = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    setValues((prevState) => ({
      ...prevState,
      repId: value?.map((v: any) => v.id),
    }))
  }, [])

  const handleIsoChange = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    setValues((prevState) => ({
      ...prevState,
      isoId: value?.map((v: any) => v.id),
    }))
  }, [])

  const handleClientChange = useCallback((event: React.ChangeEvent<{}>, value: any[]) => {
    setValues((prevState) => ({
      ...prevState,
      clientId: value?.map((v: any) => v.id),
    }))
  }, [])

  const handleRangeChange = useCallback((event: any) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }, [])

  const [getDealListMut, { isLoading }] = useMutation(getDealList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 50,
      }))
    },
  })

  const { data: dealStats } = useQuery(
    ['deal-stats', pagination.search, pagination.from, pagination.to, pagination.repId, pagination.isoId, pagination.clientId],
    () => {
      return getDealStats({
        Statuses: [DealsStatus.approved],
        Query: pagination.search,
        From: pagination.from,
        To: pagination.to,
        RepresentativeId: pagination.repId,
        IsoId: pagination.isoId,
        ClientId: pagination.clientId,
      })
    },
  )

  useEffect(() => {
    getDealListMut({
      PageSize: 50,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      Query: pagination.search,
      RepresentativeId: pagination.repId,
      ClientId: pagination.clientId,
      IsoId: pagination.isoId,
      From: pagination.from,
      To: pagination.to,
      Statuses: [DealsStatus.approved],
    })
  }, [
    pagination.page,
    pagination.order,
    pagination.sortOrder,
    pagination.search,
    pagination.repId,
    pagination.isoId,
    pagination.from,
    pagination.to,
    pagination.clientId,
  ])

  const [exportDealsExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportDeals, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  return (
    <Box>
      <Grid container alignItems="center" justify="space-around" className={classes.roundBorder}>
        <Grid item xs={5}>
          <Box display="flex" alignItems="center">
            <Typography color="textSecondary">Filter:</Typography>
            <Box flex={1} ml={2}>
              <SelectGroup>
                <ClientAutoComplete multiple onChange={handleClientChange} className={s.autocomplete} clientId={filters.clientId} />
                <RepAutoComplete multiple onChange={handleRepChange} className={s.autocomplete} repId={filters.repId} />
                <ISOAutoComplete multiple onChange={handleIsoChange} className={s.autocomplete} isoId={filters.isoId} />
              </SelectGroup>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" alignItems="center" pl={5}>
            <Typography color="textSecondary" noWrap>
              Advanced Range:
            </Typography>
            <Box display="flex" flex={1} ml={2} minWidth={150} flexShrink={0}>
              <TextField
                label="From"
                name="from"
                onChange={handleRangeChange}
                variant="outlined"
                fullWidth
                error={Boolean(touched.from && errors.from)}
                helperText={touched.from && errors.from}
                value={filters.from}
                className={selectGroupClasses.disabledFormControlRightBorder}
              />
              <TextField
                label="To"
                name="to"
                error={Boolean(touched.to && errors.to)}
                helperText={touched.to && errors.to}
                onChange={handleRangeChange}
                variant="outlined"
                fullWidth
                value={filters.to}
                className={selectGroupClasses.disabledFormControlLeftBorder}
              />
            </Box>
            <Box minWidth={36} width={50} ml={3}>
              <CustomIconButton onClick={() => handleSubmit()} size="small" color="secondary" width="100%" height={36}>
                <SvgIcon fontSize="inherit" viewBox="0 0 16 13" color="secondary" component={FilterIcon} />
              </CustomIconButton>
            </Box>
            <Box minWidth={135} ml={3}>
              <SearchField handleSearch={handleSearch} name="search" onChange={handleChange} label="Search the deal" fullWidth />
            </Box>
            <Box fontSize={17}>
              <Button
                variant="text"
                color="secondary"
                loading={isLoadingExportExcel}
                disabled={isLoadingExportExcel}
                onClick={() =>
                  exportDealsExcelMut({
                    ExportType: ExportType.excel,
                    DealStatuses: [DealsStatus.approved],
                  })
                }
                startIcon={<SvgIcon viewBox="0 0 13 17" color="inherit" fontSize="inherit" component={ExcelIcon} />}
              >
                Export to Excel
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <RootRef rootRef={tableWrapperRef}>
        <Box className={classes.wrapperTable}>
          <Paper elevation={0} className={classes.paperTable}>
            <div
              style={{
                maxHeight: tableWrapperRef.current ? `calc(100vh - ${tableWrapperRef.current.offsetTop + 2}px)` : 'initial',
              }}
            >
              <TableLastDealsSecondView
                tableContainerClassName={classes.tableContainer}
                handleSortChange={handleSort}
                stickyHeader
                columns={columns}
                loading={isLoading}
                handleGetMore={handleFetchMore}
                data={pagination.data}
                hasMore={pagination.hasMore}
                lazy
                Footer={() => <FooterTableLatestDeals stats={dealStats?.data} />}
              />
            </div>
          </Paper>
        </Box>
      </RootRef>
    </Box>
  )
}

export default LatestDealsSecondView

import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertEmptyField } from 'utils/formatters'
import { IUser } from 'typescript/interfaces/users'
import { Box, useTheme } from '@material-ui/core'
import Button from 'UI/Button/ButtonWithPreloader'
import Link from '@material-ui/core/Link'

export const TableUserManagement = TableComponent as TableComponentType<IUser>

interface IUseUserManagementTableProps {
  handleChangeRolesClick: (user: IUser) => void
  handleImpersonateClick?: (user: IUser) => void
  impersonateLoading?: boolean
  handleEdit?: (data: IUser) => void
  resetPassword?: (data: IUser) => void
}
export const useUserManagementTable = ({
  handleChangeRolesClick,
  handleImpersonateClick,
  impersonateLoading,
  handleEdit,
  resetPassword,
}: IUseUserManagementTableProps) => {
  const classes = useTableComponentStyle()
  const theme = useTheme()
  const columns = useRef<Array<IColumn<IUser>>>([
    {
      label: 'User Name',
      field: 'email',
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => (
        <Link
          color="inherit"
          href="#"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            handleEdit && handleEdit(data)
          }}
        >
          {data.email}
        </Link>
      ),
    },
    {
      label: 'First Name',
      field: 'name',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.firstName),
    },
    {
      label: 'Last Name',
      field: 'lastName',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.lastName),
    },
    {
      label: 'Phone',
      field: 'phone',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.phone),
    },
    {
      label: 'Active',
      field: 'isActive',
      bodyCellClassName: classes.accentCell,
      render: (data) => (data.isActive ? 'YES' : 'NO'),
    },
    {
      label: 'Roles',
      field: 'userRoles',
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box display="flex">
          {data.userRoles.map((role) => (
            <Box
              width="fit-content"
              px="1rem"
              mr="0.5rem"
              py="0.5rem"
              borderRadius="0.5rem"
              border={`1px solid ${theme.palette.divider}`}
              key={role.id}
            >
              {role.name}
            </Box>
          ))}
        </Box>
      ),
    },
    {
      label: '',
      field: 'reset',
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box>
          <Button onClick={() => resetPassword && resetPassword(data)} color="secondary" variant="outlined" size="small">
            Reset Password
          </Button>
        </Box>
      ),
    },
    {
      label: '',
      field: 'roles',
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box>
          <Button onClick={() => handleChangeRolesClick(data)} color="secondary" variant="outlined" size="small">
            Change Roles
          </Button>
        </Box>
      ),
    },
    {
      label: '',
      field: 'operations',
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box>
          <Button loading={impersonateLoading} onClick={() => handleImpersonateClick?.(data)} color="secondary" variant="outlined" size="small">
            Impersonate User
          </Button>
        </Box>
      ),
    },
  ])

  return {
    columns: columns.current,
  }
}

import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import DatePicker from 'UI/DatePicker/DatePicker'
import SelectComponent from 'UI/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { PaymentFrequencySchedule } from 'typescript/enums/payments'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import Box from '@material-ui/core/Box'
import Button from 'UI/Button/ButtonWithPreloader'
import { ISchedulePaymentInitValues } from 'typescript/interfaces/deals'
import { schemaSchedule } from 'components/SchedulePaymentForm/schema'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'

interface ISchedulePaymentProps {
  dealId: string
  cancel: () => void
  submit: (values: ISchedulePaymentInitValues) => void
  bankAccount: string | undefined
  endDate?: string
  loading?: boolean
}

const SchedulePaymentForm: FC<ISchedulePaymentProps> = ({ cancel, submit, dealId, bankAccount, loading, endDate }) => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(12, 0, 0, 0)
  const { handleSubmit, values, errors, touched, handleChange, setFieldValue } = useFormik<ISchedulePaymentInitValues>({
    initialValues: {
      dealId: dealId,
      startDate: '',
      endDate: endDate || '',
      paymentFrequency: '',
      frequentPayment: '',
      bankAccount: bankAccount || '',
      paymentsCount: '',
    },
    validationSchema: schemaSchedule,
    onSubmit: submit,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={9}>
        <Grid item xs={12}>
          <DatePicker
            minDate={tomorrow}
            disablePast
            fullWidth
            label="Start Date"
            name="startDate"
            type="keyboard"
            error={Boolean(touched.startDate && errors.startDate) || !isValidDate(values.startDate)}
            helperText={(touched.startDate && errors.startDate) || (!isValidDate(values.startDate) && 'MM/DD/YYYY')}
            value={values.startDate || null}
            onChange={(date) => {
              return setFieldValue('startDate', convertDateForPicker(date))
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            minDate={tomorrow}
            disablePast
            fullWidth
            label="End Date"
            name="endDate"
            type="keyboard"
            error={Boolean(touched.endDate && errors.endDate) || !isValidDate(values.endDate)}
            helperText={(touched.endDate && errors.endDate) || (!isValidDate(values.endDate) && 'MM/DD/YYYY')}
            value={values.endDate || null}
            onChange={(date) => setFieldValue('endDate', convertDateForPicker(date))}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectComponent
            value={values.paymentFrequency}
            name="paymentFrequency"
            label="Payment Frequency"
            onChange={handleChange}
            error={Boolean(touched.paymentFrequency && errors.paymentFrequency)}
            helperText={touched.paymentFrequency && errors.paymentFrequency}
          >
            <MenuItem value={PaymentFrequencySchedule.daily}>{PaymentFrequencySchedule.daily}</MenuItem>
            <MenuItem value={PaymentFrequencySchedule.weekly}>{PaymentFrequencySchedule.weekly}</MenuItem>
          </SelectComponent>
        </Grid>
        <Grid item xs={12}>
          <SelectComponent
            name="paymentsCount"
            label="Num Of Transactions"
            value={values.paymentsCount}
            onChange={handleChange}
            error={Boolean(touched.paymentsCount && errors.paymentsCount)}
            helperText={touched.paymentsCount && errors.paymentsCount}
          >
            <MenuItem value="1">Single</MenuItem>
            <MenuItem value="2">Double</MenuItem>
            <MenuItem value="3">Triple</MenuItem>
          </SelectComponent>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Bank Account"
            name="bankAccount"
            disabled
            value={values.bankAccount}
            onChange={handleChange}
            error={Boolean(touched.bankAccount && errors.bankAccount)}
            helperText={touched.bankAccount && errors.bankAccount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Amount"
            name="frequentPayment"
            onChange={handleChange}
            error={Boolean(touched.frequentPayment && errors.frequentPayment)}
            helperText={touched.frequentPayment && errors.frequentPayment}
          />
        </Grid>
      </Grid>
      <Box mt={10}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button onClick={cancel} fullWidth color="secondary" variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button loading={loading} fullWidth color="primary" variant="contained" type="submit">
              Schedule Payments
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default SchedulePaymentForm

import React, { FC } from 'react'
import { fade } from '@material-ui/core'

import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'
import useTheme from '@material-ui/core/styles/useTheme'
import { IChartProps } from 'typescript/interfaces/dashboard'

const PaidOffAvgDetailed: FC<IChartProps> = ({ stats }) => {
  const theme = useTheme()
  const fontSizeHtml = parseFloat(getComputedStyle(document.documentElement).fontSize)

  const data = [
    { name: 'Not paid', value: 100 - (stats?.paidOff ?? 0) },
    { name: 'Paid', value: stats?.paidOff },
  ]

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie dataKey="value" startAngle={90} endAngle={450} data={data} innerRadius={fontSizeHtml * 10.43} outerRadius={fontSizeHtml * 11.06}>
          <Tooltip labelFormatter={(index) => data?.[index as number]?.name} />
          <Cell strokeWidth={0} fill="#D0DDE8" />
          <Cell strokeWidth={0} fill={theme.palette.primary.main} />
        </Pie>
        <Pie dataKey="value" startAngle={90} endAngle={450} data={data} innerRadius={fontSizeHtml * 10.43} outerRadius={fontSizeHtml * 16.19}>
          <Tooltip labelFormatter={(index) => data?.[index as number]?.name} />
          <Cell strokeWidth={0} fill="transparent" />
          <Cell strokeWidth={0} fill={fade(theme.palette.primary.light, 0.15)} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PaidOffAvgDetailed

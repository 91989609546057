import React, { FC, useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, YAxis, XAxis } from 'recharts'
import useTheme from '@material-ui/core/styles/useTheme'
import { IPerformanceDealStatus } from 'typescript/interfaces/rep'

interface IStatusesChartProps {
  performanceDealStatuses: IPerformanceDealStatus[]
}

const StatusesChart: FC<IStatusesChartProps> = ({ performanceDealStatuses }) => {
  const theme = useTheme()

  const data = performanceDealStatuses.map((i) => ({
    name: i.paidStatus,
    val: i.dealsCount,
  }))

  const maxLength = useMemo(() => {
    return (
      data.reduce((acc, val) => {
        return acc < val.name.length ? val.name.length : acc
      }, 0) * 7
    )
  }, [data])

  return (
    <Box p={5}>
      <Typography>Statuses</Typography>
      <Box mt="1rem" height="15rem" width="100%" mb="1rem">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid horizontal={false} strokeDasharray="3 3" />
            <XAxis hide axisLine={false} type="number" />
            <YAxis
              tick={{
                fill: theme.palette.text.primary,
                strokeWidth: 1,
              }}
              dataKey="name"
              type="category"
              minTickGap={0}
              tickCount={5}
              allowDecimals={false}
              width={maxLength}
              axisLine={false}
              tickLine={false}
              interval="preserveStartEnd"
              tickMargin={0}
              padding={{
                top: 0,
                bottom: 0,
              }}
            />
            <Tooltip key="name" />
            <Bar fillOpacity={0.3} fill={theme.palette.primary.main} dataKey="val">
              <LabelList
                dataKey="name"
                content={(props) => {
                  const { x, y, width, height } = props
                  return (
                    <g>
                      <rect x={(x || 0) + (width || 0) - 4} y={y} height={height} width={4} fill={theme.palette.primary.main} />
                    </g>
                  )
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default StatusesChart

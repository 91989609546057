import React, { useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import { SortFieldsClients, IClientListItem } from 'typescript/interfaces/clients'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ShareIcon } from 'assets/svg/ShareClientIcon.svg'
import TableComponent, { TableComponentType } from 'UI/Table'
import { Link } from 'react-router-dom'
import { VIEW_CLIENT_URL } from 'constants/routes'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertDate, convertEmptyField } from 'utils/formatters'

export const TableClients = TableComponent as TableComponentType<IClientListItem>

export const useClients = () => {
  const classes = useTableComponentStyle()

  const columns = useRef<Array<IColumn<IClientListItem>>>([
    {
      label: 'Client Id',
      sorting: true,
      field: SortFieldsClients.id,
      render: (data) => <Link to={VIEW_CLIENT_URL.replace(':id(\\d+)', data.id)}>{data.id}</Link>,
    },
    {
      label: 'Legal Name',
      field: SortFieldsClients.legalName,
      sorting: true,
      render: (data) => data.businessName,
      bodyCellClassName: classes.accentCell,
    },
    {
      label: 'Joined On',
      field: SortFieldsClients.joinedOn,
      sorting: true,
      render: (data) => convertDate(data.joinedOn),
      bodyCellClassName: classes.secondaryBodyCell,
    },
    {
      label: 'Gross Annual Income',
      field: SortFieldsClients.grossAnnualIncome,
      sorting: true,
      render: (data) => data.grossAnnualIncome,
      bodyCellClassName: classes.accentCell,
    },
    {
      label: 'Expected Grades',
      field: SortFieldsClients.expectedGrades,
      sorting: true,
      render: (data) => data.expectedGrade,
      bodyCellClassName: classes.accentCell,
    },
    {
      label: 'Actual Grade',
      field: SortFieldsClients.actualGrade,
      sorting: true,
      render: (data) => convertEmptyField(data.actualGrade),
      bodyCellClassName: classes.accentCell,
    },
    {
      label: 'Rep Name',
      field: SortFieldsClients.repName,
      sorting: false,
      render: (data) => convertEmptyField(data.repName),
      bodyCellClassName: classes.accentCell,
    },
    {
      label: 'Share',
      field: 'share',
      render: (data) => {
        return (
          <IconButton size="small">
            <ShareIcon />
          </IconButton>
        )
      },
    },
  ])

  return {
    columns: columns.current,
  }
}

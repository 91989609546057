import { Box } from '@material-ui/core'
import SignInForm from 'components/SignInForm'
import React from 'react'

const SignInPage = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <SignInForm />
    </Box>
  )
}

export default SignInPage

import { Box, Button, makeStyles, Paper, Typography, useTheme } from '@material-ui/core'
import { COMPANY_NEW_URL, COMPANY_URL } from 'constants/routes'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { generatePath, Link, useHistory } from 'react-router-dom'
import { getCompanies } from 'services/companies'
import TableComponent, { TableComponentType } from 'UI/Table'
import { CompanyListItem, CompanySortFields, CompanyType } from 'typescript/interfaces/companies'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustSquareIcon.svg'
import { useSnackbar } from 'notistack'
import { PAGE_SIZE } from 'constants/pagination'
import { IColumn } from 'typescript/interfaces/tableUi'
import SearchField from 'UI/SearchField'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const TableClients = TableComponent as TableComponentType<CompanyListItem>

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2.25rem',
    fontWeight: 500,
  },
  companyNameLink: {
    fontSize: '1.1875rem',
    color: theme.palette.text.secondary,
    textDecoration: 'none',
  },
  tableIdHeaderCell: {
    paddingLeft: '3rem',
  },
  tableIdBodyCell: {
    paddingLeft: '3rem',
  },
  button: {
    fontSize: '1.25rem',
  },
}))

const CompaniesListContainer = () => {
  const s = useStyles()
  const additionalStyle = useAdditionalMaterialStyle()
  const theme = useTheme()
  const snack = useSnackbar()
  const { push } = useHistory()

  const columnsRef = useRef<IColumn<CompanyListItem>[]>([
    {
      label: 'Company #',
      sortField: CompanySortFields.id,
      field: 'id',
      cellStyle: {
        fontSize: '0.875rem',
        color: theme.palette.text.hint,
        width: '11rem',
      },
      sorting: true,
      bodyCellClassName: s.tableIdBodyCell,
      headerCellClassName: s.tableIdHeaderCell,
    },
    {
      label: 'Company Name',
      sortField: CompanySortFields.name,
      field: 'name',
      sorting: true,
      render: (dataRow: CompanyListItem) => {
        return (
          <Link className={s.companyNameLink} to={generatePath(COMPANY_URL, { id: dataRow.id })}>
            {dataRow.name}
          </Link>
        )
      },
    },
    {
      label: 'Tax ID',
      field: 'federalTaxId',
      sortField: CompanySortFields.federalTaxId,
      sorting: true,
      cellStyle: { color: theme.palette.secondary.dark, fontSize: '1.0625rem' },
    },
  ])

  const [pagination, setPagination] = useState<{
    data: Array<CompanyListItem>
    search: string
    order: any
    sortOrder: 'ASC' | 'DESC'
    page: number
    hasMore: boolean
  }>({
    data: [],
    search: '',
    order: CompanySortFields.id,
    sortOrder: 'DESC',
    page: 0,
    hasMore: true,
  })

  const [loadCompanies, { isLoading }] = useMutation(getCompanies, {
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
    onSuccess(companiesData) {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...companiesData.data],
        hasMore: companiesData.data.length === 20,
      }))
    },
  })

  const handleSearch = useCallback((search) => {
    setPagination((prevState) => ({
      ...prevState,
      data: prevState.search !== search ? [] : prevState.data,
      page: 0,
      search: search,
    }))
  }, [])

  const handleSort = useCallback((order, orderSor) => {
    setPagination((prevState) => ({
      ...prevState,
      data: [],
      order: order,
      sortOrder: orderSor,
    }))
  }, [])

  const handleFetchMore = useCallback(() => {
    setPagination((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }))
  }, [])

  useEffect(() => {
    loadCompanies({
      PageSize: PAGE_SIZE,
      PageIndex: pagination.page,
      Query: pagination.search,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      CompanyType: CompanyType.InHouse,
    })
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder])

  return (
    <Paper className={additionalStyle.wrapper}>
      <Box p="3rem" pb="1rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={s.title} variant="h2" color="textSecondary">
          Inhouse Companies
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={240} mr={5}>
            <SearchField handleSearch={handleSearch} fullWidth label="Search by company name" />
          </Box>
          <Button onClick={() => push(COMPANY_NEW_URL)} className={s.button} startIcon={<PlusIcon />} color="secondary">
            Add New Company
          </Button>
        </Box>
      </Box>

      <TableClients
        handleGetMore={handleFetchMore}
        loading={isLoading}
        columns={columnsRef.current}
        handleSortChange={handleSort}
        data={pagination.data || []}
        hasMore={pagination.hasMore}
      />
    </Paper>
  )
}

export default CompaniesListContainer

import React, { FC, useCallback } from 'react'
import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { getIsoById, editIsoById } from 'services/iso'
import ISOForm, { IIsoInitialValues } from 'components/ISOForm'
import { ISO_INFO_URL } from 'constants/routes'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import { BANK_ACCOUNT_ALREADY_USED, SOMETHING_WENT_WRONG } from 'constants/errors'

const EditIsoContainer: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { data, isLoading } = useQuery(['isoById', id], () => getIsoById(id), {
    retry: false,
    cacheTime: 0,
  })

  const [editIso, { isLoading: isLoadingEdit }] = useMutation((values: IIsoInitialValues) => editIsoById(values, id), {
    onSuccess: () => {
      enqueueSnackbar('Iso successfully edited')
      push(
        generatePath(ISO_INFO_URL, {
          id,
        }),
      )
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'ISO_FOR_COMPANY_EXISTS') enqueueSnackbar('ISO with the current company already exists')
      else if (err.response && err.response.data.description === 'ISO_FOR_USER_EXISTS') enqueueSnackbar('ISO with the current email already exists')
      else if (err.response?.data.description === 'BANK_ACCOUNT_USED') enqueueSnackbar(BANK_ACCOUNT_ALREADY_USED)
      else if (err.response && err.response.data.descripiton === 'ANOTHER_COMPANY_ATTACHED_TO_USER')
        enqueueSnackbar('User with the current email already exists')
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleEditIso = useCallback(
    (values) => {
      editIso(values)
    },
    [editIso],
  )

  return isLoading || !data ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <ISOForm
      label={`Edit ISO ${data.data.businessName}`}
      loading={isLoadingEdit}
      submitHandler={handleEditIso}
      initialValues={{
        nameOnCheck: data.data.nameOnCheck,
        businessName: data.data.businessName,
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        email: data.data.email,
        phone: data.data.phone,
        fax: data.data.fax,
        mobile: data.data.mobile,
        userPage: data.data.userPage,
        city: data.data.city,
        street: data.data.street,
        zipCode: data.data.zipCode,
        federalTaxId: data.data.federalTaxId,
        stateId: data.data.state.id,
      }}
    />
  )
}

export default EditIsoContainer

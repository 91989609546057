import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertDate, convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { Box } from '@material-ui/core'
import { IDealPayment, ITransactionListItem } from 'typescript/interfaces/transactions'
import { SortFieldDealPayments, SortFieldsTransactionsList } from 'typescript/enums/transactions'
import { usePermission } from 'hooks/usePermission'
import { EDIT_DEAL_PAYMENT } from 'constants/permissions'
import { Link } from 'react-router-dom'

export const TableTransactions = TableComponent as TableComponentType<ITransactionListItem>
export const TableDealPayments = TableComponent as TableComponentType<IDealPayment>

interface IUseTransactionStateProp {
  handleEditPaymentDeal?: (id: number) => void
}

export const useTransactions: (props?: IUseTransactionStateProp) => any = ({ handleEditPaymentDeal } = {}) => {
  const { hasPermission } = usePermission()
  const classes = useTableComponentStyle({})
  const columns = useRef<Array<IColumn<ITransactionListItem>>>([
    {
      label: '#',
      field: SortFieldsTransactionsList.id,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => data.id,
    },
    {
      label: 'Trs Date',
      field: SortFieldsTransactionsList.creationDate,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (date) => convertDate(date.paymentDate),
    },
    {
      label: 'Prev. Balance',
      field: 'prevBalance',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.prevBalance),
    },
    {
      label: 'Amount',
      field: SortFieldsTransactionsList.amount,
      bodyCellClassName: classes.accentCell,
      sorting: true,
      render: (data) => (data.payment < 0 ? `(${convertToPriceFormat(Math.abs(data.payment))})` : convertToPriceFormat(data.payment)),
    },
    {
      label: 'Deal',
      field: SortFieldsTransactionsList.dealNumber,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.dealId),
    },
    {
      label: 'Deal Name',
      field: SortFieldsTransactionsList.dealName,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.dealName),
    },
    {
      label: 'Trs Type',
      field: SortFieldsTransactionsList.type,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => data.type,
    },
    {
      label: 'Category',
      field: SortFieldsTransactionsList.category,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => data.category,
    },
    {
      label: 'Comments',
      field: SortFieldsTransactionsList.comments,
      bodyCellClassName: classes.accentCell,
      sorting: true,
      render: (data) => <Box maxWidth={230}>{convertEmptyField(data.comments)}</Box>,
    },
  ])

  const columnsDealTransactions = useRef<Array<IColumn<IDealPayment>>>([
    {
      label: '#',
      sorting: true,
      field: SortFieldDealPayments.id,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) =>
        hasPermission(EDIT_DEAL_PAYMENT) ? (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault()
              handleEditPaymentDeal && handleEditPaymentDeal(+data.id)
            }}
          >
            {data.id}
          </Link>
        ) : (
          data.id
        ),
    },
    {
      label: 'Payment',
      sorting: true,
      field: SortFieldDealPayments.amount,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.payment),
    },
    {
      label: 'Bank Account',
      field: 'bankAccount',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertEmptyField(data.bankAccount),
    },
    {
      label: 'Debit/Credit',
      field: 'direction',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => {
        return data.direction
        // switch (data.type) {
        //   case 'DealToPage': {
        //     return 'C'
        //   }
        //   case 'PageToFun': {
        //     return 'D'
        //   }
        //   case 'DealToFun': {
        //     return 'F'
        //   }
        //   default: {
        //     return 'n/a'
        //   }
        // }
      },
    },
    {
      label: 'Scheduled date',
      field: 'scheduleDate',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertDate(data.scheduledDate),
    },
    {
      label: 'Payment date',
      field: SortFieldsTransactionsList.date,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertDate(data.paymentDate),
    },
    {
      label: 'Provider',
      field: 'provider',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertEmptyField(data.provider),
    },
    {
      label: 'Status',
      field: 'status',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertEmptyField(data.status),
    },
    {
      label: 'Trs Type',
      sorting: true,
      field: SortFieldsTransactionsList.type,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertEmptyField(data.category),
    },
    // {
    //   label: 'Category',
    //   sorting: true,
    //   field: SortFieldsTransactionsList.category,
    //   bodyCellClassName: classes.secondaryBodyCell,
    //   render: (data) => convertEmptyField(data.category),
    // },
    {
      label: 'Comments',
      sorting: true,
      field: SortFieldsTransactionsList.comments,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertEmptyField(data.comments),
    },
  ])

  return {
    columns: columns.current,
    columnsDealTransactions: columnsDealTransactions.current,
  }
}

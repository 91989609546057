import React, { FC, useCallback, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { useSyndicatorReport, TableSyndicatorReport } from 'hooks/useSyndicatorReport'
import { makeStyles, Paper, SvgIcon, Typography } from '@material-ui/core'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as FilterIcon } from 'assets/svg/FilterIcon.svg'
import { ReactComponent as DocumentOutlined } from 'assets/svg/DocumentOutlined.svg'
import SyndicatorReportTableFooter from 'components/SyndicatorReportTableFooter'
import { usePaginationList } from 'hooks/usePaginationList'
import { ISyndicatorReportFilters, ISyndicatorReportListItem, SyndicatorDealsSortingFields } from 'typescript/interfaces/syndicators'
import { getSyndicator, getSyndicatorReport, getSyndicatorReportTotal } from 'services/syndicators'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { convertEmptyField } from 'utils/formatters'
import { exportSyndicatorReport } from 'services/export'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { downloadFile } from 'utils/downloadFile'
import Button from 'UI/Button/ButtonWithPreloader'
import { ExportType } from 'typescript/enums/export'
import SyndicatedDealStats from 'components/SyndicatedDealStats'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import SearchField from 'UI/SearchField'
import CalculateRestHeightBlock from 'components/CalculateRestHeightBlock'
import PaidStatusAutoComplete from 'components/Dashboard/PaidStatusAutoComplete'
import CustomIconButton from 'UI/Button/IconButton'
import { useFormik } from 'formik'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2.25rem',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  button: {
    fontSize: '1.25rem',
  },
  roundBorder: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '6px',
  },
  paidStatusAutoComplete: {
    marginTop: -4,
    width: '100%',
  },
}))

const SyndicatorReportContainer: FC = () => {
  const s = useStyles()
  const additionalStyle = useAdditionalMaterialStyle()
  const { id } = useParams<{ id: string }>()
  const { data: syndicatorData } = useQuery(['syndicatorById', id], () => getSyndicator(id))

  const { enqueueSnackbar } = useSnackbar()

  const [exportSyndicatorReportExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportSyndicatorReport, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [exportSyndicatorReportPdfMut, { isLoading: isLoadingExportPdf }] = useMutation(exportSyndicatorReport, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleFiltersSubmit = (filtersToSet: ISyndicatorReportFilters) => {
    if (filtersToSet.paidStatuses?.toString() === pagination.paidStatuses?.toString()) {
      return
    }

    setPagination((prevState) => ({
      ...prevState,
      ...filtersToSet,
      page: 0,
      hasMore: true,
      data: [],
    }))
  }

  const { values: filters, setValues, handleSubmit } = useFormik<ISyndicatorReportFilters>({
    initialValues: {
      SyndicatorId: id,
      paidStatuses: [],
    },
    onSubmit: handleFiltersSubmit,
  })

  const handleChangePaidStatuses = useCallback((_e: React.ChangeEvent<{}>, values) => {
    setValues((prevState) => ({
      ...prevState,
      paidStatuses: values,
    }))
  }, [])

  const { setPagination, handleFetchMore, handleSort, pagination, handleSearch } = usePaginationList<ISyndicatorReportListItem>({
    initState: {
      data: [],
      search: '',
      order: SyndicatorDealsSortingFields.deal,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
      paidStatuses: [],
    },
  })

  const [getSyndicatorReportMut, { isLoading: isDealsLoading }] = useMutation(getSyndicatorReport, {
    onSuccess: (syndicatorReportData) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...syndicatorReportData.data.map((row) => ({ ...row, tableOnlyRowExpanded: false }))],
        hasMore: syndicatorReportData.data.length === 20,
      }))
    },
  })

  const [getSyndicatorReportTotalMut, { data: dataTotal }] = useMutation(getSyndicatorReportTotal)

  useEffect(() => {
    getSyndicatorReportMut({
      PageSize: 20,
      Query: pagination.search,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      filter: {
        SyndicatorId: id,
        paidStatuses: pagination.paidStatuses,
      },
    })
  }, [pagination.search, pagination.page, pagination.order, pagination.sortOrder, pagination.paidStatuses, id])

  useEffect(() => {
    getSyndicatorReportTotalMut({
      Query: pagination.search,
      filter: {
        SyndicatorId: id,
        paidStatuses: pagination.paidStatuses,
      },
    })
  }, [pagination.paidStatuses, pagination.search, id])

  const handleExpand = (dealId: number) => {
    setPagination((prevState) => {
      const newData = [...prevState.data]
      const rowToBeChanged = newData.find((row) => row.deal === dealId)
      rowToBeChanged!.tableOnlyRowExpanded = !rowToBeChanged!.tableOnlyRowExpanded

      return {
        ...prevState,
        data: newData,
      }
    })
  }

  const { columns } = useSyndicatorReport({ handleExpand })

  return (
    <Paper className={additionalStyle.wrapper}>
      <Box p="2rem" position="relative">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr="1rem">
            <Typography className={s.title} variant="h2" color="textSecondary">
              {convertEmptyField(syndicatorData?.data.businessName)}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box minWidth={200} mr="1rem">
              <SearchField handleSearch={handleSearch} name="search" label="Search" fullWidth />
            </Box>
            <Button
              loading={isLoadingExportExcel}
              disabled={isLoadingExportExcel || isLoadingExportPdf}
              onClick={() => exportSyndicatorReportExcelMut({ SyndicatorId: +id, ExportType: ExportType.excel })}
              variant="text"
              className={s.button}
              color="secondary"
              startIcon={<ExcelIcon />}
            >
              Export to Excel
            </Button>
            <Button
              loading={isLoadingExportPdf}
              disabled={isLoadingExportExcel || isLoadingExportPdf}
              variant="text"
              onClick={() => exportSyndicatorReportPdfMut({ SyndicatorId: +id, ExportType: ExportType.pdf })}
              className={s.button}
              color="secondary"
              startIcon={<DocumentOutlined />}
            >
              Export to PDF
            </Button>
          </Box>
        </Box>
        <Box p="1.5rem 1rem" className={s.roundBorder} mt="1.6rem">
          <Box display="flex" alignItems="center">
            <Box mr="1.5rem">
              <Typography color="textSecondary" noWrap>
                Filter:
              </Typography>
            </Box>
            <Box mb="-0.25rem" ml={2} minWidth={250}>
              <PaidStatusAutoComplete
                label="Paid Status"
                className={s.paidStatusAutoComplete}
                paidStatuses={filters.paidStatuses ?? []}
                onChange={handleChangePaidStatuses}
              />
            </Box>
            <Box minWidth={36} width={138} ml={3}>
              <CustomIconButton onClick={() => handleSubmit()} size="small" color="secondary" width="100%" height={36}>
                <SvgIcon fontSize="inherit" viewBox="0 0 16 13" color="secondary" component={FilterIcon} />
              </CustomIconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <CalculateRestHeightBlock>
        <TableSyndicatorReport
          stickyHeader
          columns={columns}
          handleSortChange={handleSort}
          loading={isDealsLoading}
          handleGetMore={handleFetchMore}
          data={pagination.data}
          hasMore={pagination.hasMore}
          expandable
          ExpandedComponent={SyndicatedDealStats}
          Footer={() => <SyndicatorReportTableFooter stats={dataTotal?.data} />}
        />
      </CalculateRestHeightBlock>
    </Paper>
  )
}

export default SyndicatorReportContainer

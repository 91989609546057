import React, { FC, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'
import { convertToPriceFormat } from 'utils/formatters'
import DatePicker from 'UI/DatePicker/DatePicker'
import Button from 'UI/Button/ButtonWithPreloader'
import Paper from '@material-ui/core/Paper/Paper'
import { useACHCreditStyle } from 'containers/AchCredit/style'
import { useAchCredit, TableAchCredit } from 'hooks/useAchCredit'
import { usePaginationList } from 'hooks/usePaginationList'
import { AchEntity } from 'typescript/interfaces/achDebit'
import { useMutation } from 'react-query'
import { getAchList, processFees } from 'services/ach'
import { useAch } from 'hooks/useAch'
import SearchField from 'UI/SearchField'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const AchFeesContainer: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useACHCreditStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const { setPagination, pagination, handleFetchMore, handleSort, handleSearch } = usePaginationList<AchEntity>({
    initState: {
      date: new Date().toISOString(),
      data: [],
      search: '',
      order: '',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })
  const [getAchFees, { isLoading }] = useMutation(getAchList)

  const [processFeesMut, { isLoading: isLoadingProcessing }] = useMutation(processFees, {
    onSuccess: () => {
      setPagination((prevState) => ({
        ...prevState,
        data: [],
      }))
      enqueueSnackbar('Fees successfully processed')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    if (pagination.date) {
      getAchFees(
        {
          PageSize: 20,
          PageIndex: pagination.page,
          Query: pagination.search,
          SortField: pagination.order,
          SortOrder: pagination.sortOrder,
          Date: pagination.date,
          Type: 'Fees',
        },
        {
          onSuccess: (data) => {
            setPagination((prevState) => ({
              ...prevState,
              data: [...prevState.data, ...data.data.transactions],
              hasMore: data.data.transactions.length === 20,
              totalPayments: data.data.totalPayment,
            }))
          },
        },
      )
    }
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder, pagination.date])

  const { setStopState, setEditAchState, ModalEdit, ModalStop } = useAch({
    callBackEdit: () => {
      if (pagination.page === 0)
        getAchFees(
          {
            PageSize: 20,
            PageIndex: pagination.page,
            Query: pagination.search,
            SortField: pagination.order,
            SortOrder: pagination.sortOrder,
            Date: pagination.date,
            Type: 'Fees',
          },
          {
            onSuccess: (data) => {
              setPagination((prevState) => ({
                ...prevState,
                page: 0,
                data: [...data.data.transactions],
                hasMore: data.data.transactions.length === 20,
              }))
            },
          },
        )
      else
        setPagination((prevState) => ({
          ...prevState,
          page: 0,
          data: [],
          hasMore: true,
        }))
    },
    callBackStop: (id) => {
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.map((ach) => ({
          ...ach,
          status: ach.paymentId === id ? 'Stopped' : ach.status,
        })),
      }))
    },
  })

  const { columns } = useAchCredit({
    handleStop: (id, category) => {
      setStopState({
        open: true,
        id: id,
        category: category,
      })
    },
    handleEdit: (id, date, category) => {
      setEditAchState({
        open: true,
        id: id,
        date: date,
        category: category,
      })
    },
  })

  return (
    <Paper className={additionalStyle.wrapper} elevation={0}>
      <Box py="3rem" px="3rem">
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="h3" color="textSecondary">
              Manage CRM Fees
            </Typography>
          </Box>
          <Box m="0 auto">
            {pagination.totalPayments !== undefined && (
              <>
                <Typography gutterBottom className={cn(classes.darkTypography)}>
                  Total Payments
                </Typography>
                <Typography color="textSecondary" variant="h4">
                  {convertToPriceFormat(pagination.totalPayments)}
                </Typography>
              </>
            )}
          </Box>
          <Box>
            <SearchField label="Search the deal" handleSearch={(search) => handleSearch(search)} />
          </Box>
          <Box ml="1rem">
            <DatePicker
              disablePast
              label="Select Date"
              type="keyboard"
              value={pagination.date}
              onChange={(date) => {
                setPagination((prevState) => ({
                  ...prevState,
                  date: date,
                  data: [],
                  hasMore: true,
                  page: 0,
                }))
              }}
            />
          </Box>
          <Box ml="1rem" display="flex" alignItems="center">
            <Button variant="contained" color="primary" loading={isLoadingProcessing} onClick={() => processFeesMut(pagination.date)}>
              Process Fees
            </Button>
          </Box>
        </Box>
      </Box>
      <Box mt="2rem">
        <TableAchCredit
          hasMore={pagination.hasMore}
          handleGetMore={handleFetchMore}
          columns={columns}
          data={pagination.data}
          loading={isLoading}
          handleSortChange={handleSort}
        />
      </Box>
      {ModalEdit}
      {ModalStop}
    </Paper>
  )
}

export default AchFeesContainer

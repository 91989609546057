import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import RolesAutoComplete from 'components/RolesAutoComplete'
import UsersAutoComplete from 'components/UsersAutoComplete'
import { NOTIFICATIONS_CREATE_WITH_ROLES_PERM } from 'constants/permissions'
import { useFormik } from 'formik'
import { usePermission } from 'hooks/usePermission'
import React from 'react'
import { INewNotificationInitialValues } from 'typescript/interfaces/notifications'
import RTEComponent from 'UI/RTE'
import { schemaNewNotificationForm } from './schema'

interface INewNotificationFormProps {
  initValues?: INewNotificationInitialValues
  submit: (values: INewNotificationInitialValues) => void
  loading?: boolean
}

const defaultInitValues: INewNotificationInitialValues = {
  usersId: [],
  rolesId: [],
  subject: '',
  message: '',
  shortMessage: '',
}

const NewNotificationForm = ({ initValues = defaultInitValues, submit, loading }: INewNotificationFormProps) => {
  const { handleSubmit, values, errors, touched, handleChange, setFieldValue } = useFormik({
    initialValues: initValues,
    onSubmit: submit,
    validationSchema: schemaNewNotificationForm,
  })
  const { hasPermission } = usePermission()

  return (
    <Box component="form" onSubmit={(e: any) => handleSubmit(e)}>
      <Typography variant="h2" color="textSecondary">
        New Notification
      </Typography>
      <Box mt="3rem">
        <Grid md={12} lg={8} spacing={8} container>
          <Grid item xs={6}>
            <UsersAutoComplete
              users={values.usersId}
              label="Users to notify"
              error={Boolean(touched.usersId && errors.usersId)}
              helperText={touched.usersId && (errors.usersId as string)}
              onChange={(e, value) => {
                if (value) handleChange({ target: { name: 'usersId', value } })
              }}
            />
          </Grid>
          <Grid item xs={6} />
          {hasPermission(NOTIFICATIONS_CREATE_WITH_ROLES_PERM) && (
            <>
              <Grid item xs={6}>
                <RolesAutoComplete
                  roles={values.rolesId}
                  label="Roles to notify"
                  error={Boolean(touched.rolesId && errors.rolesId)}
                  helperText={touched.rolesId && (errors.rolesId as string)}
                  onChange={(e, value) => {
                    if (value) handleChange({ target: { name: 'rolesId', value } })
                  }}
                />
              </Grid>
              <Grid item xs={6} />
            </>
          )}
          <Grid item xs={6}>
            <TextField
              name="subject"
              variant="outlined"
              error={Boolean(touched.subject && errors.subject)}
              helperText={touched.subject && errors.subject}
              label="Subject"
              value={values.subject}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <RTEComponent
              defaultValue={values.message}
              onChange={(content, delta, source, editor) => {
                setFieldValue('shortMessage', editor.getText())
                setFieldValue('message', editor.getHTML())
              }}
              placeholder="Type your message"
              theme="snow"
              error={Boolean(touched.shortMessage && errors.shortMessage)}
              helperText={touched.shortMessage && errors.shortMessage}
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={loading} type="submit" variant="contained" color="primary">
              Send Notification
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default NewNotificationForm

import {
  FIELD_MUST_CONTAIN_THREE_SYMBOLS,
  INVALID_EMAIL,
  INVALID_FEDERAL_TAX_ID,
  INVALID_MANAGEMENT_FEE_VALUE,
  INVALID_MOBILE_NUMBER,
  ONLY_LETTERS,
  REQUIRED_FIELD,
  ZIP_CODE_FORMAT_ERROR,
} from 'constants/errors'
import * as yup from 'yup'
import { userPageSchema } from 'components/VendorForm/schema'
import { zipCodeReg, managementFeeReg } from 'constants/regExp'

export const schemaAddEditSyndicators = (isEdit: boolean) =>
  yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    phone: yup.string().required(REQUIRED_FIELD),
    fax: yup.string(),
    mobile: yup.string().test('isFullMobileNumber', INVALID_MOBILE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0) || !val),
    email: yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
    nameOnCheck: yup.string(),
    nameOnBankAccount: yup.string(),
    userPage: userPageSchema(isEdit),
    label: yup
      .string()
      .max(3, FIELD_MUST_CONTAIN_THREE_SYMBOLS)
      .min(3, FIELD_MUST_CONTAIN_THREE_SYMBOLS)
      .test('isLetters', ONLY_LETTERS, (val) => /([a-zA-Z]){3}/.test(`${val}`)),
    businessName: yup.string().required(REQUIRED_FIELD),
    city: yup.string().required(REQUIRED_FIELD),
    street: yup.string().required(REQUIRED_FIELD),
    stateId: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    federalTaxId: yup
      .string()
      .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val))
      .required(REQUIRED_FIELD),
    zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
    managementFee: yup.string().required(REQUIRED_FIELD).matches(managementFeeReg, INVALID_MANAGEMENT_FEE_VALUE),
  })

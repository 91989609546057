import React, { FC, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import SearchField from 'UI/SearchField'
import Paper from '@material-ui/core/Paper/Paper'
import { useMutation } from 'react-query'
import { usePaginationList } from 'hooks/usePaginationList'
import { useNotificationSent, TableNotificationsSent } from 'hooks/useNotificationsSent'
import { INotification } from 'typescript/interfaces/notifications'
import { getSentNotifications } from 'services/notifications'
import { useSnackbar } from 'notistack'
import SentNotificationModalContent from 'components/SentNotificationModalContent'
import ModalComponent from 'UI/Modal'
import { useTableComponentStyle } from 'UI/Table/style'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { useHistory } from 'react-router-dom'
import { useLatestDealsStyle } from 'containers/Deal/LatestDeals/style'

interface INotificationModal {
  open: boolean
  notification: null | INotification
}

const SentNotificationsList: FC = () => {
  const s = useLatestDealsStyle()
  const { goBack } = useHistory()
  const [sentNotificationModalInfo, setSentNotificationModalInfo] = useState<INotificationModal>({
    open: false,
    notification: null,
  })

  const handleNotificationClick = (notification: INotification) => {
    setSentNotificationModalInfo({
      notification,
      open: true,
    })
  }

  const handleNotificationModalClose = () => {
    setSentNotificationModalInfo({
      notification: null,
      open: false,
    })
  }
  const classes = useTableComponentStyle()
  const { columns } = useNotificationSent({ onNotificationClick: handleNotificationClick })
  const snack = useSnackbar()
  const { pagination, setPagination, handleFetchMore, handleSort, handleSearch } = usePaginationList<INotification>({
    initState: {
      data: [],
      search: '',
      order: 'Date',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getNotifications, { isLoading }] = useMutation(getSentNotifications, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
  })

  useEffect(() => {
    getNotifications({
      PageSize: 20,
      PageIndex: pagination.page,
      Query: pagination.search,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder])

  return (
    <Paper elevation={0}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box mr="1.5rem">
            <IconButton color="secondary" onClick={goBack} className={s.navButton}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
          <Typography style={{ marginTop: '0.5rem' }} variant="h2" color="textSecondary">
            Notifications Sent
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box width={240} mr={5}>
            <SearchField handleSearch={handleSearch} fullWidth label="Search message" />
          </Box>
        </Box>
      </Box>
      <Box>
        <TableNotificationsSent
          loading={isLoading}
          columns={columns}
          data={pagination.data}
          tableClassName={classes.fixed}
          handleSortChange={handleSort}
          hasMore={pagination.hasMore}
          handleGetMore={handleFetchMore}
        />
      </Box>
      <ModalComponent open={sentNotificationModalInfo.open} onClose={handleNotificationModalClose}>
        <SentNotificationModalContent handleCancel={handleNotificationModalClose} notification={sentNotificationModalInfo.notification} />
      </ModalComponent>
    </Paper>
  )
}

export default SentNotificationsList

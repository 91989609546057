import React, { FC } from 'react'
import { Box } from '@material-ui/core'

interface IRequiredOptionProps {
  label: string
}

const RequiredOption: FC<IRequiredOptionProps> = ({ label }) => {
  return (
    <Box display="flex" alignItems="center">
      {label}
      <Box ml={1} color="error.main">
        *
      </Box>
    </Box>
  )
}

export default RequiredOption

import * as yup from 'yup'
import { INVALID_SYMBOLS, NUMBER_CANNOT_BE_NEGATIVE, REQUIRED_FIELD } from 'constants/errors'
import { valiadateFloatWithTwoDigits } from 'utils/validateFloatWithTwoDigits'

export const schemaSetLimit = yup.object().shape({
  limit: yup
    .number()
    .typeError(INVALID_SYMBOLS)
    .positive(NUMBER_CANNOT_BE_NEGATIVE)
    .test('float', INVALID_SYMBOLS, function () {
      return valiadateFloatWithTwoDigits(+this.parent.limit)
    })
    .required(REQUIRED_FIELD),
})

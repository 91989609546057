import makeStyles from '@material-ui/core/styles/makeStyles'
import { fade, Theme } from '@material-ui/core'

interface IUseTableComponentStyleProps {
  stickyHeader?: boolean
}

const getStyleTable = makeStyles<Theme, IUseTableComponentStyleProps>((theme) => ({
  errorColumn: {
    color: theme.palette.error.main,
  },
  sortingBox: {
    display: 'flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
  },
  accentCell: {
    color: theme.palette.text.secondary,
  },
  secondaryBodyCell: {
    color: theme.palette.text.hint,
  },
  secondaryDarkCell: {
    color: theme.palette.secondary.dark,
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: fade(theme.palette.common.white, 0.3),
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadMoreButton: {
    backgroundColor: theme.palette.info.light,
    border: '1px solid transparent',
  },
  bodyCell: {
    color: theme.palette.secondary.dark,
  },
  fz19: {
    fontSize: '1.1875rem',
  },
  fixed: {
    tableLayout: 'fixed',
  },
  headerTable: {
    '& th': {
      backgroundColor: (props) => (props.stickyHeader ? theme.palette.common.white : 'inherit'),
    },
  },
  allowTextWraping: {
    whiteSpace: 'normal',
  },
}))

const useTableComponentStyle = (propsTableStyle?: IUseTableComponentStyleProps) => {
  return getStyleTable(propsTableStyle || {})
}

export { useTableComponentStyle }

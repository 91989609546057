import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { API_REFRESH_TOKEN, API_AUTH_URL } from 'constants/apiUrl'
import { JWT_TOKEN, REFRESH_TOKEN, USER_ROLE } from 'constants/localStorageKeys'
import { SIGN_IN_URL } from 'constants/routes'
import { history } from 'services/history'

export const requestInterceptorCallback = (config: AxiosRequestConfig) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(JWT_TOKEN)}`
  const userRole = localStorage.getItem(USER_ROLE)
  const url: string = config.url || ''
  if (userRole) {
    config.headers.SelectedRole =
      userRole === 'Funder' &&
      ((url.indexOf('/Syndicator') >= 0 && url.indexOf('/Syndicator/light') < 0) ||
        url.indexOf('/Representative/funders') >= 0 ||
        url.indexOf('/Representative/approvedFunders') >= 0 ||
        url.indexOf('/Storage/syndicatorDocuments') >= 0 ||
        url.indexOf('/Export/syndicator') >= 0)
        ? 'Syndicator'
        : userRole
  }
  return config
}

const publicInstance = axios.create({ baseURL: API_AUTH_URL })

type ExtendedAxiosRequestConfig = AxiosRequestConfig & { retry: boolean }

export const refreshAccessToken = async () => {
  const refToken = localStorage.getItem(REFRESH_TOKEN)
  if (refToken) {
    const response = await publicInstance.post(API_REFRESH_TOKEN, { refreshToken: refToken })
    localStorage.setItem(JWT_TOKEN, response.data.token)
    localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken)
    return response
  }
  return false
}

// eslint-disable-next-line
const requestPublicInterceptor = publicInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(JWT_TOKEN)}`
    return config
  },
  function (error: AxiosError) {
    return Promise.reject(error)
  },
)

// eslint-disable-next-line
const responsePrivateInterceptor = publicInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    return response
  },
  async function (error: AxiosError) {
    const originalRequest = error.config as ExtendedAxiosRequestConfig
    if (
      (error.response?.status === 500 || error.response?.status === 401 || error.response?.status === 404) &&
      originalRequest.url === API_REFRESH_TOKEN
    ) {
      localStorage.removeItem(JWT_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      history.push(SIGN_IN_URL)
      return Promise.reject(error)
    }
    return Promise.reject(error)
  },
)

createAuthRefreshInterceptor(publicInstance, refreshAccessToken)

export { publicInstance }

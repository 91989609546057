import * as yup from 'yup'
import {
  INVALID_SYMBOLS,
  NUMBERS_ONLY,
  NUMBER_CANNOT_BE_NEGATIVE,
  FROM_VALUE_MUST_BE_LESS_THAN_TO,
  TO_VALUE_MUST_BE_MORE_THAN_FROM,
} from 'constants/errors'
import { valiadateFloatWithTwoDigits } from 'utils/validateFloatWithTwoDigits'

export const schemaLatestDealsFilters = yup.object().shape({
  from: yup
    .number()
    .test('float', INVALID_SYMBOLS, function () {
      return valiadateFloatWithTwoDigits(+this.parent.psfFee)
    })
    .test('not more than to', FROM_VALUE_MUST_BE_LESS_THAN_TO, function () {
      const to = +this.parent.to || Number.MAX_SAFE_INTEGER
      const from = +this.parent.from || 0
      return to >= from
    })
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
  to: yup
    .number()
    .test('float', INVALID_SYMBOLS, function () {
      return valiadateFloatWithTwoDigits(+this.parent.psfFee)
    })
    .test('not less than from', TO_VALUE_MUST_BE_MORE_THAN_FROM, function () {
      const to = +this.parent.to || Number.MAX_SAFE_INTEGER
      const from = +this.parent.from || 0
      return to >= from
    })
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
})

import React, { FC, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import { useTheme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { convertDate, convertToPriceFormat } from 'utils/formatters'
import { IEquityData } from 'typescript/interfaces/rep'

interface IEquityChartProps {
  data: IEquityData[]
}

const EquityChartComponent: FC<IEquityChartProps> = ({ data }) => {
  const fontSizeHtml = parseFloat(getComputedStyle(document.documentElement).fontSize)
  const theme = useTheme()

  const convertedData = useMemo(() => {
    return data.map((item) => ({
      date: convertDate(item.reportDate),
      pnl: item.pnlTotal,
    }))
  }, [data])

  const maxLength = useMemo(() => {
    return data
      ? convertToPriceFormat(
          data.reduce((acc, val) => {
            return acc < Math.abs(val.pnlTotal) ? val.pnlTotal : acc
          }, 0),
        ).toString().length *
          (fontSizeHtml / 2)
      : fontSizeHtml * 2
  }, [data, fontSizeHtml])

  return (
    <Box p={5}>
      <Typography>Equity</Typography>
      <Box mt="1rem" height="15rem" width="100%" mb="1rem">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={convertedData}
            margin={{
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <YAxis
              tick={{
                fill: theme.palette.text.primary,
                strokeWidth: 1,
              }}
              tickFormatter={(val) => convertToPriceFormat(val)}
              minTickGap={0}
              tickCount={5}
              allowDecimals={false}
              width={maxLength}
              axisLine={false}
              tickLine={false}
              interval="preserveStartEnd"
              tickMargin={0}
              padding={{
                top: 0,
                bottom: 0,
              }}
            />
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <Tooltip labelFormatter={(index) => convertedData[index as number].date} />
            <Area type="monotone" dataKey="pnl" stroke={theme.palette.primary.main} fillOpacity={0.3} fill={theme.palette.primary.main} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default EquityChartComponent

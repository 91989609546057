export enum AchSortFields {
  paymentId = 'PaymentId',
  dealId = 'DealId',
  dealName = 'DealName',
  payment = 'Payment',
  scheduleFor = 'ScheduleFor',
  companyName = 'CompanyName',
  lastPaymentSchedule = 'LastPaymentSchedule',
}

export enum NachaFilesSortFields {
  id = 'id',
  fileName = 'FileName',
  createdOn = 'CreatedOn',
  status = 'Status',
}

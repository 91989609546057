import React, { useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { Box } from '@material-ui/core'
import TableComponent, { TableComponentType } from 'UI/Table'
import { convertDate, convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { ISyndicatedDealListItem, ISyndicatedDealStatsItem } from 'typescript/interfaces/syndicators'

export const TableSyndicatedDeals = TableComponent as TableComponentType<ISyndicatedDealListItem>

export const useSyndicatedDeals = () => {
  const classes = useTableComponentStyle()

  const columns = useRef<Array<IColumn<ISyndicatedDealListItem>>>([
    {
      label: '#',
      field: 'id',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.dealId),
    },
    {
      label: 'Business Name',
      field: 'businessName',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box whiteSpace="nowrap" maxWidth={220} textOverflow="ellipsis" overflow="hidden">
          {data.businessName}
        </Box>
      ),
    },
    {
      label: 'Participation',
      field: 'participation',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => 'a',
    },
    {
      label: 'Shared in Deal',
      field: 'sharedInDeal',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => `${convertEmptyField(data.sharedInDeal)} %`,
    },
    {
      label: 'Purchased',
      field: 'Purchased',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.purchased),
    },

    {
      label: 'Collected',
      field: 'collected',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.collected),
    },
    {
      label: 'Remaining Balance',
      field: 'remainingBalance',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => (data.remainingBalance < 0 ? convertToPriceFormat(0) : convertToPriceFormat(data.remainingBalance)),
    },
    {
      label: 'Start Date',
      field: 'startDate',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.startDate),
    },
    {
      label: 'Status',
      field: 'status',
      render: (data) => convertEmptyField(data.status),
    },
  ])

  return {
    columns: columns.current,
  }
}

export const TableSyndicatedDealStats = TableComponent as TableComponentType<ISyndicatedDealStatsItem>

export const useSyndicatedDealStats = () => {
  const classes = useTableComponentStyle()

  const columns = useRef<Array<IColumn<ISyndicatedDealStatsItem>>>([
    {
      label: 'Month',
      field: 'month',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.month),
    },
    {
      label: 'Collected',
      field: 'collected',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.amount),
    },
  ])

  return {
    columns: columns.current,
  }
}

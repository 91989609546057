import { Box } from '@material-ui/core'
import { EquifaxReportForm } from 'components/IntegrationReportForms/EquifaxReportForm'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import React from 'react'

export const EquifaxReportContainer = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  return (
    <Box className={additionalStyle.wrapper} mb="2rem">
      <EquifaxReportForm />
    </Box>
  )
}

import { Box, Paper } from '@material-ui/core'
import NewBankForm from 'components/NewBankForm'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import React from 'react'

const AddNewBankContainer = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  return (
    <Box className={additionalStyle.wrapper} mb="2rem">
      <Paper elevation={0}>
        <NewBankForm />
      </Paper>
    </Box>
  )
}

export default AddNewBankContainer

import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import { NEW_CLIENT_URL, VIEW_CLIENT_URL, CLIENTS_LIST_URL, EDIT_CLIENT_URL, NEW_OWNER_URL, EDIT_OWNER_URL, CLIENT_DOCUMENTS } from 'constants/routes'
import NewClientPage from 'pages/NewClient'
import ViewClientPage from 'pages/ClientPage'
import ClientsListPage from 'pages/ClientsLlist'
import EditClientPage from 'pages/EditClient'
import NewOwnerPage from 'pages/NewOwner'
import EditOwnerPage from 'pages/EditOwner'
import ClientDocumentsPage from 'pages/ClientsDocuments'

const ClientsContainer: FC = () => {
  return (
    <Switch>
      <Route path={CLIENT_DOCUMENTS} component={ClientDocumentsPage} />
      <Route path={CLIENTS_LIST_URL} component={ClientsListPage} />
      <Route path={NEW_CLIENT_URL} component={NewClientPage} />
      <Route path={VIEW_CLIENT_URL} component={ViewClientPage} />
      <Route path={EDIT_CLIENT_URL} component={EditClientPage} />
      <Route path={NEW_OWNER_URL} component={NewOwnerPage} />
      <Route path={EDIT_OWNER_URL} component={EditOwnerPage} />
    </Switch>
  )
}

export default ClientsContainer

import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { INotification } from 'typescript/interfaces/notifications'
import { convertNotifBodyDate } from 'utils/formatters'

interface INotificationBodyProps {
  notification?: INotification | null
}

const useStyles = makeStyles(() => ({
  messageContent: {
    '& *': {
      wordBreak: 'break-word',
    },
  },
  time: {
    width: 'max-content',
    whiteSpace: 'pre',
  },
}))
const NotificationBody = ({ notification }: INotificationBodyProps) => {
  const s = useStyles()
  return (
    <Box p="3rem" width="100%">
      <Box mb="2rem" display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography color="textSecondary" variant="h2">
          {notification?.subject}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box ml="2rem">
            <Typography className={s.time}>{convertNotifBodyDate(notification?.creationDate)}</Typography>
          </Box>
        </Box>
      </Box>
      {/* eslint-disable-next-line */}
      <Box>{notification?.message && <div className={s.messageContent} dangerouslySetInnerHTML={{ __html: notification.message }} />}</Box>
    </Box>
  )
}

export default NotificationBody

import { makeStyles } from '@material-ui/core'

export const useCommentsLogStyles = makeStyles((theme) => ({
  even: {
    background: theme.palette.background.default,
  },
  base: {
    padding: '1.5rem',
  },
}))

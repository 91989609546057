import {
  COMPANIES_URL,
  COMPANY_NEW_URL,
  DASHBOARD_URL,
  OUTHOUSE_COMPANIES_NEW_URL,
  OUTHOUSE_COMPANIES_URL,
  SYNDICATORS_NEW_URL,
  SYNDICATORS_URL,
  ISO_ADD_NEW_URL,
  ISO_LIST_URL,
  REP_ADD_NEW_URL,
  REP_LIST_URL,
  VENDOR_ADD_NEW_URL,
  VENDOR_LIST_URL,
  NEW_CLIENT_URL,
  CLIENTS_LIST_URL,
  FUNDING_QUE_URL,
  ACH_DEBIT_URL,
  ACH_CREDIT_URL,
  NACHA_LIST_URL,
  WITHDRAWAL_QUE_URL,
  TODAYS_PAYMENT_REPORT_URL,
  USER_ROLES_URL,
  USER_MANAGEMENT_URL,
  REP_PERFORMANCE_URL,
  REQUEST_FUNDER_INFO_URL,
  APPROVE_SYNDICATION_LIMIT_URL,
  PORTFOLIO_SUMMARY_URL,
  SYNDICATOR_OWN_URL,
  REP_OVERALL_DEALS_URL,
  DEALS_LIST_URL,
  IMPORT_LEGAL_FEES,
  ACH_FEES_URL,
  BANKS_URL,
  VENDOR_OWN_URL,
  REP_OWN_URL,
  ISO_OWN_URL,
  LIEN_SOLUTIONS_FILING_URL,
  HOLIDAYS_URL,
  EQUIFAX_REPORT_URL,
  EXPERIAN_REPORT_URL,
  PRBC_REPORT_URL,
  ENHANCED_REPORT_URL,
} from 'constants/routes'
import { ReactComponent as DashBoardIcon } from 'assets/svg/DashboardIcon.svg'
import { ReactComponent as UsersIcon } from 'assets/svg/UsersIcon.svg'
import { ReactComponent as PaymentsIcon } from 'assets/svg/PaymentsIcon.svg'
import { ReactComponent as CompaniesIcon } from 'assets/svg/CompaniesIcon.svg'
import { ReactComponent as PartnersIcon } from 'assets/svg/PartnersIcon.svg'
import { ReactComponent as VendorsIcon } from 'assets/svg/VendorsIcon.svg'
import { ReactComponent as SyndicatorsIcon } from 'assets/svg/SyndicatorsIcon.svg'
import { ReactComponent as QueuesIcon } from 'assets/svg/QueuesIcon.svg'
import { ReactComponent as OverviewIcon } from 'assets/svg/OverviewIcon.svg'
import { ReactComponent as ListIcon } from 'assets/svg/ListViewIcon.svg'
import { ReactComponent as IntegrationsIcon } from 'assets/svg/IntegrationsIcon.svg'
import { ReactComponent as UsersModuleIcon } from 'assets/svg/UsersModuleIcon.svg'
import { FC, SVGProps, useContext, useMemo } from 'react'
import { UserContext } from 'contexts/userContext'
import { usePermission } from 'hooks/usePermission'
import { generatePath } from 'react-router-dom'
import {
  ACH_MODULE_PERM,
  APPROVE_FUNDERS_LIMIT_PERM,
  CLIENTS_MODULE_PERM,
  CLIENT_CREATE_UPDATE_VIEW,
  DASHBOARD_MODULE_PERM,
  ISO_MODULE_PERM,
  NACHA_LIST_PERM,
  PORTFOLIO_SUMMARY_PERM,
  FUNDING_QUES_PERM,
  WITHDRAWAL_QUES_PERM,
  REP_MODULE_PERM,
  REP_PERFORMANCE_PERM,
  REQUEST_FUNDER_INFO_PERM,
  SYNDICATORS_MODULE_PERM,
  SYNDICATORS_VIEW_PERM,
  SYNDICATOR_OWN_VIEW_PERM,
  TODAY_PAYMENT_REPORT_PERM,
  USER_MODULE_PERM,
  USER_ROLES_MODULE_PERM,
  VENDORS_MODULE_PERM,
  OVERALL_DEALS_MODULE_PERM,
  FUNDED_DEALS_PERM,
  SUBMITTED_DEALS_PERM,
  IMPORT_MASS_LEGAL_FEES_PERM,
  CREATE_REP_PERM,
  SYNDICATOR_CREATE_PERM,
  MY_DEALS_PERM,
  VENDORS_ADD_PERM,
  INHOUSE_COMPANY_MODULE_PERM,
  OUTHOUSE_COMPANY_MODULE_PERM,
  CREATE_INHOUSE_COMPANY_PERM,
  CREATE_OUTHOUSE_COMPANY_PERM,
  BANK_MODULE_PERM,
  ADD_ISO_PERM,
  VENDOR_OWN_VIEW_PERM,
  REP_OWN_VIEW_PERM,
  ISO_OWN_VIEW_PERM,
  HOLIDAYS_PERM,
  INTEGRATIONS_PERM,
} from 'constants/permissions'

export interface IMenuItemChildren {
  url: string
  label: string
  redirectUrl?: string
}

export interface IMenuItem {
  url?: string
  label?: string
  icon: FC<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  exact: boolean
  children?: IMenuItemChildren[]
}

export const useMenuItems = () => {
  const { hasPermission } = usePermission()
  const userContext = useContext(UserContext)

  const menuItems: IMenuItem[] = useMemo(() => {
    return [
      ...(hasPermission(DASHBOARD_MODULE_PERM)
        ? [
            {
              url: DASHBOARD_URL,
              label: 'Dashboard',
              icon: DashBoardIcon,
              exact: false,
            },
          ]
        : []),
      ...(hasPermission(MY_DEALS_PERM)
        ? [
            {
              icon: ListIcon,
              label: 'My Deals',
              exact: false,
              url: generatePath(DEALS_LIST_URL, {
                type: 'my-deals',
              }),
            },
          ]
        : []),
      ...(hasPermission(FUNDED_DEALS_PERM) || hasPermission(SUBMITTED_DEALS_PERM)
        ? [
            {
              icon: ListIcon,
              label: 'Deals',
              exact: false,
              children: [
                ...(hasPermission(FUNDED_DEALS_PERM)
                  ? [
                      {
                        url: generatePath(DEALS_LIST_URL, {
                          type: 'funded',
                        }),
                        label: 'View Funded Deals',
                      },
                    ]
                  : []),
                ...(hasPermission(SUBMITTED_DEALS_PERM)
                  ? [
                      {
                        url: generatePath(DEALS_LIST_URL, {
                          type: 'submitted',
                        }),
                        label: 'View Submitted Deals',
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(() => {
        if (hasPermission(CLIENTS_MODULE_PERM) && hasPermission(CLIENT_CREATE_UPDATE_VIEW)) {
          return [
            {
              icon: UsersIcon,
              label: 'Clients',
              exact: false,
              children: [
                { url: CLIENTS_LIST_URL, label: 'View clients' },
                { url: NEW_CLIENT_URL, label: 'Add client' },
              ],
            },
          ]
        } else if (hasPermission(CLIENTS_MODULE_PERM)) {
          return [
            {
              icon: UsersIcon,
              label: 'Clients',
              exact: false,
              url: CLIENTS_LIST_URL,
            },
          ]
        } else {
          return []
        }
      })(),
      ...(hasPermission(NACHA_LIST_PERM) ||
      hasPermission(ACH_MODULE_PERM) ||
      hasPermission(TODAY_PAYMENT_REPORT_PERM) ||
      hasPermission(IMPORT_MASS_LEGAL_FEES_PERM) ||
      hasPermission(HOLIDAYS_PERM)
        ? [
            {
              icon: PaymentsIcon,
              label: 'Payments',
              exact: false,
              children: [
                ...(hasPermission(NACHA_LIST_PERM) ? [{ url: NACHA_LIST_URL, label: 'NACHA Exports' }] : []),
                ...(hasPermission(ACH_MODULE_PERM) ? [{ url: ACH_DEBIT_URL, label: 'ACH Debit' }] : []),
                ...(hasPermission(ACH_MODULE_PERM) ? [{ url: ACH_CREDIT_URL, label: 'ACH Credit' }] : []),
                ...(hasPermission(ACH_MODULE_PERM) ? [{ url: ACH_FEES_URL, label: 'Manage CRM Fees' }] : []),
                ...(hasPermission(HOLIDAYS_PERM) ? [{ url: HOLIDAYS_URL, label: 'National Bank Holiday Schedule' }] : []),
                ...(hasPermission(IMPORT_MASS_LEGAL_FEES_PERM)
                  ? [
                      {
                        url: IMPORT_LEGAL_FEES,
                        label: 'Import Mass Legal Fees',
                      },
                    ]
                  : []),
                ...(hasPermission(TODAY_PAYMENT_REPORT_PERM)
                  ? [
                      {
                        url: TODAYS_PAYMENT_REPORT_URL,
                        label: "Today's Payment Report",
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(hasPermission(INHOUSE_COMPANY_MODULE_PERM) || hasPermission(OUTHOUSE_COMPANY_MODULE_PERM) || hasPermission(BANK_MODULE_PERM)
        ? [
            {
              icon: CompaniesIcon,
              label: 'Companies',
              exact: false,
              children: [
                ...(hasPermission(INHOUSE_COMPANY_MODULE_PERM)
                  ? [
                      {
                        url: COMPANIES_URL,
                        label: 'View inhouse companies',
                      },
                    ]
                  : []),
                ...(hasPermission(CREATE_INHOUSE_COMPANY_PERM)
                  ? [
                      {
                        url: COMPANY_NEW_URL,
                        label: 'Add inhouse company',
                      },
                    ]
                  : []),
                ...(hasPermission(OUTHOUSE_COMPANY_MODULE_PERM)
                  ? [
                      {
                        url: OUTHOUSE_COMPANIES_URL,
                        label: 'View outhouse companies',
                      },
                    ]
                  : []),
                ...(hasPermission(CREATE_OUTHOUSE_COMPANY_PERM)
                  ? [
                      {
                        url: OUTHOUSE_COMPANIES_NEW_URL,
                        label: 'Add outhouse company',
                      },
                    ]
                  : []),
                ...(hasPermission(BANK_MODULE_PERM) ? [{ url: BANKS_URL, label: 'Banks' }] : []),
              ],
            },
          ]
        : []),
      ...(hasPermission(ISO_MODULE_PERM) || hasPermission(REP_MODULE_PERM)
        ? [
            {
              icon: PartnersIcon,
              label: 'Partners',
              exact: false,
              children: [
                ...(hasPermission(ISO_MODULE_PERM) ? [{ url: ISO_LIST_URL, label: 'View ISO' }] : []),
                ...(hasPermission(ADD_ISO_PERM) ? [{ url: ISO_ADD_NEW_URL, label: 'Add ISO' }] : []),
                ...(hasPermission(REP_MODULE_PERM) ? [{ url: REP_LIST_URL, label: 'View REP' }] : []),
                ...(hasPermission(CREATE_REP_PERM) ? [{ url: REP_ADD_NEW_URL, label: 'Add REP' }] : []),
              ],
            },
          ]
        : []),
      ...(hasPermission(VENDORS_MODULE_PERM)
        ? [
            {
              icon: VendorsIcon,
              label: 'Vendors',
              exact: false,
              children: [
                { url: VENDOR_LIST_URL, label: 'View Vendors' },
                ...(hasPermission(VENDORS_ADD_PERM) ? [{ url: VENDOR_ADD_NEW_URL, label: 'Add Vendor' }] : []),
              ],
            },
          ]
        : []),
      ...(hasPermission(SYNDICATORS_MODULE_PERM) || hasPermission(SYNDICATORS_VIEW_PERM)
        ? [
            {
              icon: SyndicatorsIcon,
              label: 'Syndicators',
              exact: false,
              children: [
                ...(hasPermission(SYNDICATORS_VIEW_PERM) ? [{ url: SYNDICATORS_URL, label: 'View Syndicators' }] : []),
                ...(hasPermission(SYNDICATOR_CREATE_PERM)
                  ? [
                      {
                        url: SYNDICATORS_NEW_URL,
                        label: 'Add Syndicator',
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(hasPermission(FUNDING_QUES_PERM) || hasPermission(WITHDRAWAL_QUES_PERM)
        ? [
            {
              icon: QueuesIcon,
              label: 'Queues',
              exact: false,
              children: [
                ...(hasPermission(FUNDING_QUES_PERM) ? [{ url: FUNDING_QUE_URL, label: 'Funding' }] : []),
                ...(hasPermission(WITHDRAWAL_QUES_PERM) ? [{ url: WITHDRAWAL_QUE_URL, label: 'Withdrawal' }] : []),
              ],
            },
          ]
        : []),
      ...(hasPermission(REP_PERFORMANCE_PERM)
        ? [
            {
              label: 'Overview',
              icon: OverviewIcon,
              exact: false,
              children: [{ url: REP_PERFORMANCE_URL, label: 'Rep performance' }],
            },
          ]
        : []),
      ...(hasPermission(INTEGRATIONS_PERM)
        ? [
            {
              label: 'Integrations',
              icon: IntegrationsIcon,
              exact: false,
              children: [
                { url: EQUIFAX_REPORT_URL, label: 'Equifax Credit Report' },
                { url: EXPERIAN_REPORT_URL, label: 'Experian Credit Report' },
                { url: ENHANCED_REPORT_URL, label: 'Enhanced People Search Report' },
                { url: PRBC_REPORT_URL, label: 'PRBC Consumer Report' },
                { url: LIEN_SOLUTIONS_FILING_URL, redirectUrl: LIEN_SOLUTIONS_FILING_URL, label: 'Lien Solutions Filing' },
              ],
            },
          ]
        : []),
      ...(hasPermission(USER_MODULE_PERM) || hasPermission(USER_ROLES_MODULE_PERM)
        ? [
            {
              label: 'Users',
              icon: UsersModuleIcon,
              exact: false,
              children: [
                ...(hasPermission(USER_ROLES_MODULE_PERM) ? [{ url: USER_ROLES_URL, label: 'Roles' }] : []),
                ...(hasPermission(USER_MODULE_PERM) ? [{ url: USER_MANAGEMENT_URL, label: 'Users Management' }] : []),
              ],
            },
          ]
        : []),

      ...(hasPermission(SYNDICATOR_OWN_VIEW_PERM)
        ? [
            {
              label: 'Syndicator profile',
              icon: OverviewIcon,
              exact: false,
              url: SYNDICATOR_OWN_URL,
            },
          ]
        : []),
      ...(hasPermission(VENDOR_OWN_VIEW_PERM)
        ? [
            {
              label: 'Vendor profile',
              icon: OverviewIcon,
              exact: false,
              url: VENDOR_OWN_URL,
            },
          ]
        : []),
      ...(hasPermission(REP_OWN_VIEW_PERM)
        ? [
            {
              label: 'REP profile',
              icon: OverviewIcon,
              exact: false,
              url: REP_OWN_URL,
            },
          ]
        : []),
      ...(hasPermission(ISO_OWN_VIEW_PERM)
        ? [
            {
              label: 'ISO profile',
              icon: OverviewIcon,
              exact: false,
              url: ISO_OWN_URL,
            },
          ]
        : []),
      ...(hasPermission(REQUEST_FUNDER_INFO_PERM) || hasPermission(APPROVE_FUNDERS_LIMIT_PERM)
        ? [
            {
              label: 'Funders',
              icon: OverviewIcon,
              exact: false,
              children: [
                ...(hasPermission(REQUEST_FUNDER_INFO_PERM)
                  ? [
                      {
                        url: REQUEST_FUNDER_INFO_URL,
                        label: 'Request Funder Info',
                      },
                    ]
                  : []),
                ...(hasPermission(APPROVE_FUNDERS_LIMIT_PERM)
                  ? [
                      {
                        url: APPROVE_SYNDICATION_LIMIT_URL,
                        label: 'Approve Funders Limit',
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(hasPermission(PORTFOLIO_SUMMARY_PERM) && !userContext.state.roles.isFunder
        ? [
            {
              label: 'Portfolio Summary',
              icon: ListIcon,
              exact: false,
              url: PORTFOLIO_SUMMARY_URL,
            },
          ]
        : []),
      ...(hasPermission(OVERALL_DEALS_MODULE_PERM) && !userContext.state.roles.isFunder
        ? [
            {
              label: 'Portfolio Summary',
              icon: ListIcon,
              exact: false,
              url: REP_OVERALL_DEALS_URL,
            },
          ]
        : []),
      ...(userContext.state.roles.isFunder
        ? [
            {
              label: 'Portfolio Summary',
              icon: ListIcon,
              exact: false,
              children: [
                {
                  url: REP_OVERALL_DEALS_URL,
                  label: 'REP Portfolio Summary',
                },
                {
                  url: PORTFOLIO_SUMMARY_URL,
                  label: 'Syndicator Portfolio Summary',
                },
              ],
            },
          ]
        : []),
    ]
  }, [userContext.state.user, userContext.state.permissions])

  return {
    menuItems,
  }
}

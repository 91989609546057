import React, { FC, useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import DatePicker from 'UI/DatePicker/DatePicker'
import { usePaginationList } from 'hooks/usePaginationList'
import { endOfMonth } from 'date-fns'
import { IHolidayListItem } from 'typescript/interfaces/holidays'
import { TableHolidays, useHolidays } from 'hooks/useHolidays'
import { useMutation } from 'react-query'
import { createHoliday, deleteHoliday, getHoliday } from 'services/holidays'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import Button from 'UI/Button/ButtonWithPreloader'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import HolidayForm from 'components/HolidayForm'
import isValidDate from 'utils/isValidDate'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const HolidaysContainer: FC = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  const [openCreateHoliday, setOpenCreateHoliday] = useState(false)
  const [deleteHolidayModal, setDeleteHolidayModal] = useState({
    id: 0,
    open: false,
  })
  const { enqueueSnackbar } = useSnackbar()
  const { handleSort, handleFetchMore, pagination, setPagination } = usePaginationList<IHolidayListItem>({
    initState: {
      data: [],
      search: '',
      order: '',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
      Date: endOfMonth(new Date()).toISOString(),
    },
  })

  const { columns } = useHolidays({
    handleDelete: (id: number) =>
      setDeleteHolidayModal({
        open: true,
        id: id,
      }),
  })

  const [createHolidayMut, { isLoading: isLoadingCreateHoliday }] = useMutation(createHoliday, {
    onSuccess: () => {
      getHolidaysMut({
        Date: endOfMonth(new Date(pagination.Date)).toISOString(),
        isYear: true,
      })
      setOpenCreateHoliday(false)
      enqueueSnackbar('Holiday successfully created')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [deleteHolidayMut, { isLoading: isLoadingDeleteHoliday }] = useMutation(deleteHoliday, {
    onSuccess: (_, variables) => {
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.filter((holiday) => holiday.id !== variables),
      }))
      setDeleteHolidayModal({
        open: false,
        id: 0,
      })
      enqueueSnackbar('Holiday successfully deleted')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [getHolidaysMut, { isLoading }] = useMutation(getHoliday, {
    onSuccess: (data) => {
      setDeleteHolidayModal({
        open: false,
        id: 0,
      })
      setPagination((prevState) => ({
        ...prevState,
        data: [...data.data],
        hasMore: data.data.length === 20,
      }))
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    getHolidaysMut({
      Date: endOfMonth(new Date(pagination.Date)).toISOString(),
      isYear: true,
    })
  }, [pagination.Date])

  return (
    <Box className={additionalStyle.wrapper} component={Paper} mb="2rem">
      <Box p="3rem" pb="2rem" display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h2" color="textSecondary">
            National Bank Holiday Schedule
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Box maxWidth="274px" ml="1rem">
            <DatePicker
              views={['year']}
              format="yyyy"
              fullWidth
              label="Date"
              name="to"
              type="keyboard"
              value={pagination.Date || null}
              onChange={(date) => {
                if (isValidDate(date)) {
                  setPagination((prevState) => ({
                    ...prevState,
                    Date: date,
                  }))
                }
              }}
            />
          </Box>
          <Box maxWidth="274px" ml="1rem">
            <Button color="primary" variant="contained" onClick={() => setOpenCreateHoliday(true)}>
              Add Holiday
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableHolidays
          loading={isLoading}
          data={pagination.data}
          columns={columns}
          hasMore={pagination.hasMore}
          handleSortChange={handleSort}
          handleGetMore={handleFetchMore}
        />
      </Box>
      <ModalComponent open={deleteHolidayModal.open}>
        <ConfirmationContent
          isLoading={isLoadingDeleteHoliday}
          text="Are you sure you want to delete holiday?"
          handleCancel={() =>
            setDeleteHolidayModal({
              open: false,
              id: 0,
            })
          }
          handleConfirm={() => deleteHolidayMut(deleteHolidayModal.id)}
        />
      </ModalComponent>
      <ModalComponent open={openCreateHoliday}>
        <HolidayForm loading={isLoadingCreateHoliday} onSubmit={createHolidayMut} handleCancel={() => setOpenCreateHoliday(false)} />
      </ModalComponent>
    </Box>
  )
}

export default HolidaysContainer

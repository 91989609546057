import React, { useRef } from 'react'
import { IVendorListItem, SortVendorFields } from 'typescript/interfaces/vendor'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { generatePath, Link, useHistory } from 'react-router-dom'
import { TRANSACTIONS_URL, VENDOR_INFO_URL, WITHDRAWAL_URL } from 'constants/routes'
import { Box } from '@material-ui/core'
import Button from 'UI/Button/ButtonWithPreloader'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertToPriceFormat, convertEmptyField, convertDate } from 'utils/formatters'
import { TRANSACTION_PERM, WITHDRAWAL_PERM } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'

export const TableVendors = TableComponent as TableComponentType<IVendorListItem>

export const useVendors = () => {
  const { hasPermission, isOwnVendor } = usePermission()
  const classes = useTableComponentStyle()
  const { push } = useHistory()
  const columns = useRef<Array<IColumn<IVendorListItem>>>([
    {
      field: SortVendorFields.id,
      label: 'Vendor #',
      sorting: true,
      render: (data) => (
        <Link
          to={generatePath(VENDOR_INFO_URL, {
            id: data.id.toString(),
          })}
        >
          {data.id}
        </Link>
      ),
      bodyCellClassName: classes.accentCell,
    },
    {
      field: SortVendorFields.name,
      label: 'Name',
      sorting: true,
      render: (data) => (
        <Box whiteSpace="nowrap" maxWidth={200} textOverflow="ellipsis" overflow="hidden">
          {data.businessName}
        </Box>
      ),
      bodyCellClassName: classes.accentCell,
    },
    {
      field: SortVendorFields.ein,
      label: 'EIN',
      sorting: true,
      render: (data) => convertEmptyField(data.ein),
      bodyCellClassName: classes.accentCell,
    },
    {
      field: SortVendorFields.phone,
      label: 'Phone',
      sorting: true,
      render: (data) => convertEmptyField(data.phone),
      bodyCellClassName: classes.accentCell,
    },
    {
      field: SortVendorFields.address,
      label: 'Address',
      sorting: true,
      render: (data) => data.street,
      bodyCellClassName: classes.accentCell,
    },
    {
      field: SortVendorFields.vendorType,
      label: 'Vendor Type',
      sorting: true,
      render: (data) => data.vendorType,
      bodyCellClassName: classes.accentCell,
    },

    {
      field: SortVendorFields.availableBalance,
      label: 'Current Balance',
      sorting: true,
      render: (data) => convertToPriceFormat(data.userPage.availableBalance),
      bodyCellClassName: classes.accentCell,
    },
    {
      field: SortVendorFields.lastTransactions,
      label: 'Last Transactions',
      sorting: true,
      render: (data) => convertDate(data.lastTransactions),
      bodyCellClassName: classes.accentCell,
    },
    {
      field: 'actions',
      label: '',
      render: (data) => {
        return (
          <Box display="flex" alignItems="center">
            {(hasPermission(TRANSACTION_PERM) || isOwnVendor(data.id)) && (
              <Box mr="2rem">
                <Button
                  onClick={() =>
                    push(
                      generatePath(TRANSACTIONS_URL, {
                        id: data.id,
                        type: 'vendor',
                        businessName: data.businessName,
                      }),
                    )
                  }
                  color="secondary"
                  variant="outlined"
                  size="small"
                >
                  View Trs
                </Button>
              </Box>
            )}
            {(hasPermission(WITHDRAWAL_PERM) || isOwnVendor(data.id)) && (
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() =>
                  push(
                    generatePath(WITHDRAWAL_URL, {
                      id: data.id,
                      type: 'vendor',
                      businessName: data.businessName,
                    }),
                  )
                }
              >
                Withdrawals
              </Button>
            )}
          </Box>
        )
      },
    },
  ])
  return {
    columns: columns.current,
  }
}

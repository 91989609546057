import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import {
  camelCaseWrap,
  converToTwoSignsAfterDot,
  convertDate,
  convertEmptyField,
  convertToPriceFormat,
  convertToPriceFormatWithSign,
  convertToPriceFormatWithoutCents,
  formatNegativePrice,
} from 'utils/formatters'
import { ISyndicatorReportListItem, SyndicatorDealsSortingFields } from 'typescript/interfaces/syndicators'
import { Box, IconButton, SvgIcon, Tooltip, Typography } from '@material-ui/core'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import { ReactComponent as InfoIcon } from 'assets/svg/InfoIcon.svg'

export const TableSyndicatorReport = TableComponent as TableComponentType<ISyndicatorReportListItem>

export const arrayData = Array(20)
  .fill({
    merchant: 'MERCHANT',
    deal: 4900,
    dealEndDate: '08.30.2020',
    participation: 50,
    dealAdvance: 500,
    syndicated: 281800,
    projPayback: 479130,
    toDatePayback: 100,
    paidOff: 100,
  })
  .map((c, i) => ({
    ...c,
    id: i,
    merchant: `${c.merchant} ${i}`,
  }))

interface IUseSyndicatedDealsParams {
  handleExpand: (dealId: number) => void
}

export const useSyndicatorReport = (params?: IUseSyndicatedDealsParams) => {
  const classes = useTableComponentStyle()
  const columns = useRef<Array<IColumn<ISyndicatorReportListItem>>>([
    {
      label: 'Merchant',
      field: SyndicatorDealsSortingFields.merchant,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.merchant),
    },
    {
      label: 'Deal',
      field: SyndicatorDealsSortingFields.deal,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.deal),
    },
    {
      label: 'Type',
      tooltip: 'Deal Type',
      field: 'extendType',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertEmptyField(data.extendType ?? 'Regular'),
    },
    {
      label: (
        <>
          Start
          <br />
          Date
        </>
      ),
      tooltip: 'Deal Start Date',
      field: SyndicatorDealsSortingFields.dealStartDate,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.startDate),
    },
    {
      label: (
        <>
          End
          <br />
          Date
        </>
      ),
      tooltip: 'Deal End Date',
      field: SyndicatorDealsSortingFields.dealEndDate,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertDate(data.dealEndDate),
    },
    {
      label: 'Status',
      field: 'status',
      render: (data) => convertEmptyField(camelCaseWrap(data.status)),
    },
    {
      label: (
        <>
          Ttl
          <br />
          Advance
        </>
      ),
      tooltip: 'Total Deal Advance',
      field: SyndicatorDealsSortingFields.dealAdvance,
      bodyCellClassName: classes.accentCell,
      headerCellClassName: classes.allowTextWraping,
      sorting: true,
      render: (data) => convertToPriceFormat(data.dealAdvance),
    },
    {
      label: (
        <>
          Ttl
          <br />
          Payback
        </>
      ),
      tooltip: 'Total Deal Payback',
      field: SyndicatorDealsSortingFields.projPayback,
      bodyCellClassName: classes.secondaryBodyCell,
      headerCellClassName: classes.allowTextWraping,
      render: (data) => convertToPriceFormat(data.toDatePayback),
    },
    {
      label: 'Term / F',
      tooltip: 'Term/Factor Rate',
      field: SyndicatorDealsSortingFields.term,
      bodyCellClassName: classes.accentCell,
      render: (data) => `${converToTwoSignsAfterDot(data.term)}/${converToTwoSignsAfterDot(data.factor)}`,
    },
    {
      label: (
        <>
          Synd
          <br />
          Amount
        </>
      ),
      tooltip: 'Syndicated Amount',
      field: SyndicatorDealsSortingFields.toDatePayback,
      bodyCellClassName: classes.secondaryBodyCell,
      headerCellClassName: classes.allowTextWraping,
      sorting: true,
      render: (data) => convertToPriceFormat(data.syndicated),
    },
    {
      label: (
        <>
          Synd&nbsp;Ttl
          <br />
          Payback
        </>
      ),
      tooltip: 'Syndicator Total Payback',
      field: SyndicatorDealsSortingFields.syndicated,
      bodyCellClassName: classes.accentCell,
      headerCellClassName: classes.allowTextWraping,
      render: (data) => convertToPriceFormat(data.projPayback),
    },
    {
      label: 'Synd %',
      tooltip: 'Syndication %',
      field: SyndicatorDealsSortingFields.participation,
      bodyCellClassName: classes.accentCell,
      render: (data) => <>{convertEmptyField(data.participation)}&nbsp;%</>,
    },
    {
      label: 'Daily',
      tooltip: 'Daily Amount',
      field: 'Daily Amount',
      sorting: false,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.paymentFrequency === 'Daily' ? data.frequentPayment : 0),
    },
    {
      label: 'Weekly',
      tooltip: 'Weekly Amount',
      field: 'Weekly Amount',
      sorting: false,
      bodyCellClassName: classes.accentCell,
      render: (data) => {
        const displayPayment =
          data.paymentFrequency === 'Daily' ? 0 : data.paymentFrequency === 'Weekly' ? data.frequentPayment : data.frequentPayment / 2

        return (
          <Box display="flex" alignItems="center">
            {convertToPriceFormat(displayPayment)}
            {data.paymentFrequency === 'Biweekly' && (
              <Box mt={0.5} ml={1}>
                <Tooltip placement="top" title={`Biweekly: ${convertToPriceFormat(data.frequentPayment)}`}>
                  <InfoIcon color="orange" fill="orange" width={16} height={16} />
                </Tooltip>
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      label: (
        <>
          Ttl
          <br />
          Collected
        </>
      ),
      tooltip: 'Total Collected',
      field: 'totalCollected',
      sorting: false,
      bodyCellClassName: classes.accentCell,
      headerCellClassName: classes.allowTextWraping,
      render: (data) => convertToPriceFormat(data.dealCollected),
    },
    {
      label: (
        <>
          Synd
          <br />
          Collected
        </>
      ),
      tooltip: 'Syndicator Collected',
      field: 'collected',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      headerCellClassName: classes.allowTextWraping,
      render: (data) => convertToPriceFormat(data.collected),
    },
    {
      label: (
        <>
          Rem
          <br />
          Bal&nbsp;Ttl
        </>
      ),
      tooltip: 'Remaining Balance Total',
      field: 'remainingBalanceTotal',
      sorting: false,
      bodyCellClassName: classes.accentCell,
      headerCellClassName: classes.allowTextWraping,
      render: (data) => convertToPriceFormat(data.dealRemaining),
    },
    {
      label: (
        <>
          Rem
          <br />
          Bal&nbsp;Synd
        </>
      ),
      tooltip: 'Remaining Balance Syndicator',
      field: SyndicatorDealsSortingFields.syndicatorRemaining,
      sorting: true,
      bodyCellClassName: classes.accentCell,
      headerCellClassName: classes.allowTextWraping,
      render: (data) => convertToPriceFormat(data.remainingBalance),
    },
    {
      label: 'PNL',
      field: 'pnl',
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Tooltip
          placement="bottom-end"
          title={
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Funding</Typography>
                <Box ml="1rem">{convertToPriceFormatWithSign(-data.syndicated)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Commission</Typography>
                <Box ml="1rem">{convertToPriceFormatWithSign(-data.totalCommission)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Legal Fee</Typography>
                <Box ml="1rem">{convertToPriceFormatWithSign(-data.legalFee)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Mgm Fee</Typography>
                <Box ml="1rem">{convertToPriceFormatWithSign(-data.mgmFee)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Default Fee</Typography>
                <Box ml="1rem">{convertToPriceFormatWithSign(-data.defaultFee)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Bank Fee</Typography>
                <Box ml="1rem">{convertToPriceFormatWithSign(data.bankFee)}</Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Collected</Typography>
                <Box ml="1rem">{convertToPriceFormatWithSign(data.collected)}</Box>
              </Box>
            </Box>
          }
        >
          <Box>{formatNegativePrice(data.pnl, convertToPriceFormatWithoutCents)}</Box>
        </Tooltip>
      ),
    },
    ...(params != null
      ? [
          {
            label: '',
            field: 'status',
            render: (data: ISyndicatorReportListItem) => (
              <IconButton onClick={() => params.handleExpand(data.deal)}>
                <SvgIcon
                  style={{ transform: `rotate(${data.tableOnlyRowExpanded ? '180deg' : '0'})` }}
                  fontSize="inherit"
                  viewBox="0 0 20 20"
                  component={CircleArrowIcon}
                />
              </IconButton>
            ),
          },
        ]
      : []),
  ])

  return {
    columns: columns.current,
  }
}

import makeStyles from '@material-ui/core/styles/makeStyles'

export const useAssignLendersStyle = makeStyles((theme) => ({
  navButton: {
    '& svg': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  icon: {
    '& path': {
      fill: theme.palette.info.contrastText,
    },
  },
  button: {
    marginRight: '1.25rem',
    background: theme.palette.info.light,
  },
}))

import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { DASHBOARD_URL, INHOUSE_DEAL, OUTHOUSE_DEAL, CLIENT_DEAL } from 'constants/routes'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { usePermission } from 'hooks/usePermission'
import { CREATE_CLIENT_DEAL, CREATE_INHOUSE_OUTHOUSE_PERM } from 'constants/permissions'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import InhouseDeal from './InhouseDeal'
import OuthouseDeal from './OuthouseDeal'
import ClientDealContainer from './ClientDeal'

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '3rem',
    fontWeight: 500,
    lineHeight: '3rem',
    paddingTop: '0.75rem',
  },
}))

const NewDealContainer = () => {
  const s = useStyles()
  const additionalStyle = useAdditionalMaterialStyle()
  const { push } = useHistory()
  const { view } = useParams<{ view: string }>()
  const { state } = useLocation<{ prevLink: string }>()
  const prevLink = useRef('')
  const { hasPermission } = usePermission()

  const handleGoBack = () => {
    push(prevLink.current || DASHBOARD_URL)
  }

  useEffect(() => {
    prevLink.current = state?.prevLink
  }, [])

  return (
    <Box className={additionalStyle.wrapper}>
      <Box ml="3rem" display="flex" mb="2rem" alignItems="center">
        <Box mr="1.5rem">
          <IconButton onClick={handleGoBack} color="inherit">
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography className={s.pageTitle} color="textSecondary">
          {view === 'edit' ? 'Edit' : 'New'} Deal
        </Typography>
      </Box>
      <Switch>
        {hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM) && <Route path={INHOUSE_DEAL} component={InhouseDeal} />}
        {hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM) && <Route path={OUTHOUSE_DEAL} component={OuthouseDeal} />}
        {hasPermission(CREATE_CLIENT_DEAL) && <Route path={CLIENT_DEAL} component={ClientDealContainer} />}
      </Switch>
    </Box>
  )
}

export default NewDealContainer

import React from 'react'
import cn from 'classnames'

import { Box, Button, FormControlLabel, makeStyles, TextField, Typography } from '@material-ui/core'
import Checkbox from 'UI/Checkbox'
import { CHOOSE_ROLE, RESET_PASSWORD_URL } from 'constants/routes'
import { FormikHelpers, useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import { JWT_TOKEN, REFRESH_TOKEN } from 'constants/localStorageKeys'

import { useMutation } from 'react-query'
import { API_LOG_IN } from 'constants/apiUrl'
import { ACCOUNT_NOT_ACTIVATED, INVALID_LOGIN_ATTEMPT } from 'constants/errors'
import { AxiosError, AxiosResponse } from 'axios'

import { FailResponse } from 'typescript/interfaces/axios'
import { publicInstance } from 'services/axios/publicInstance'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import { ISignInSuccessResponse } from 'typescript/interfaces/auth'

import { schemaSignIn } from './schema'

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: 24,
  },
  input2: {
    marginBottom: 16,
  },
  button: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 48,
    color: '#4C5878',
  },
  smallText: {
    fontSize: 19,
    marginBottom: 36,
  },
  checkboxLabel: {
    color: '#4C5878',
    fontSize: 17,
    marginBottom: -3,
  },
  link: {
    color: '#249AF3',
    textDecoration: 'none',
    fontSize: 19,
  },
  smallLink: {
    fontSize: 17,
  },
}))

interface Values {
  email: string
  password: string
  remember: boolean
}

const SignInForm = () => {
  const s = useStyles()
  const { push } = useHistory()

  const signIn = (values: Values) => {
    return publicInstance.post(API_LOG_IN, values, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig)
  }

  const [mutate, { isLoading }] = useMutation(signIn, {
    onSuccess: ({ data: { token, refreshToken } }: AxiosResponse<ISignInSuccessResponse>) => {
      localStorage.setItem(JWT_TOKEN, token)
      localStorage.setItem(REFRESH_TOKEN, refreshToken)
      push(`${CHOOSE_ROLE}${window.location.search}`)
    },
    onError: (err: AxiosError<FailResponse>) => {
      if (err.response?.data.description === INVALID_LOGIN_ATTEMPT) {
        setFieldError('email', 'The email or the password is incorrect')
        setFieldError('password', ' ')
      } else if (err.response?.data.description === ACCOUNT_NOT_ACTIVATED) {
        setFieldError('email', 'Account deactivated')
      } else {
        setFieldError('email', err.response?.data.description)
      }
    },
  })

  const onSubmit = (vs: Values, formContext: FormikHelpers<Values>) => {
    return mutate(vs)
  }

  const { values, errors, touched, handleChange, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema: schemaSignIn,
    onSubmit: onSubmit,
  })

  return (
    <Box
      width="520px"
      maxWidth="80%"
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Typography className={s.text} align="center">
        Sign in to portal
      </Typography>
      {/*<Typography className={s.smallText} align="center">*/}
      {/*  Need an account?{' '}*/}
      {/*  <Link className={s.link} to={SIGN_UP_URL}>*/}
      {/*    Sign up*/}
      {/*  </Link>*/}
      {/*</Typography>*/}
      <TextField
        className={s.input}
        variant="outlined"
        fullWidth
        label="Email"
        name="email"
        onChange={handleChange}
        value={values.email}
        error={!!errors.email && !!touched.email}
        helperText={!!touched.email && errors.email}
      />
      <TextField
        variant="outlined"
        fullWidth
        className={s.input2}
        label="Password"
        type="password"
        name="password"
        error={!!errors.password && !!touched.password}
        onChange={handleChange}
        value={values.password}
        helperText={!!touched.password && errors.password?.trim()}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          classes={{
            label: s.checkboxLabel,
          }}
          label="Keep me signed in"
          onChange={handleChange}
          control={<Checkbox color="primary" checked={values.remember} name="remember" />}
        />
        <Link className={cn(s.link, s.smallLink)} to={RESET_PASSWORD_URL}>
          Forgot password
        </Link>
      </Box>

      <Button disabled={isLoading} type="submit" className={s.button} color="primary" variant="contained" fullWidth>
        Sign in
      </Button>
    </Box>
  )
}

export default SignInForm

import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ReactComponent as NotificationChatIcon } from 'assets/svg/NotificationChatIcon.svg'
import { INotification } from 'typescript/interfaces/notifications'
import { convertNotifDate } from 'utils/formatters'

interface Props {
  selected: boolean
  notification: INotification
  onClick: () => void
}

const useStyles = makeStyles((theme) => ({
  chatListItem: {
    padding: 24,
    paddingLeft: (props: any) => (props.selected ? 20 : 24),
    borderLeft: (props: any) =>
      props.selected ? `4px solid ${theme.palette.primary.main}` : props.unRead ? `4px solid ${theme.palette.secondary.main}` : 'none',
    background: (props: any) => (props.selected ? '#F5F6F8' : theme.palette.background.paper),
    borderBottom: `1px solid ${theme.palette.info.light}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    cursor: 'pointer',
    '&:hover': {
      background: '#F5F6F8',
    },
  },
  icon: {
    '& path': {
      fill: (props: any) => (props.selected ? theme.palette.primary.main : props.unRead ? theme.palette.secondary.main : ''),
    },
  },
  name: {
    color: theme.palette.text.secondary,
    fontSize: 19,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '11rem',
  },
  position: {
    color: theme.palette.text.primary,
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '11rem',
  },
  time: {
    whiteSpace: 'nowrap',
  },
}))

const NotificationsChatListItem = ({ selected, notification, onClick }: Props) => {
  const s = useStyles({ selected, unRead: !notification.isRead })
  return (
    <Box onClick={onClick} display="flex" width="100%" className={s.chatListItem}>
      <NotificationChatIcon className={s.icon} />

      <Box flex="1" ml="19px">
        <Typography className={s.name}>{notification.subject}</Typography>
        <Typography className={s.position}>{notification.shortMessage}</Typography>
      </Box>
      <Box>
        <Typography className={s.time}>{convertNotifDate(notification.creationDate)}</Typography>
      </Box>
    </Box>
  )
}

export default NotificationsChatListItem

import {
  API_GET_ACH,
  API_EDIT_ACH,
  API_STOP_ACH,
  API_GET_NACHA_FILES,
  API_PROCESS_CREDIT,
  API_PROCESS_DEBITS,
  API_PROCESS_FEES,
  API_CANCEL_PAYMENT,
  API_DUPLICATE_PAYMENT,
  API_DUPLICATE_ALL_PAYMENTS,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { AxiosResponse } from 'axios'
import { IAchGetListParams, IAchList, IAchEditValues, INachaFilesGetParams, INachaFile, IAchStopValues } from 'typescript/interfaces/achDebit'
import { generatePath } from 'react-router-dom'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

export const getAchList = (params: IAchGetListParams) =>
  privateInstance.get<IAchGetListParams, AxiosResponse<IAchList>>(API_GET_ACH, {
    params: params,
  })

export const editAch = (values: IAchEditValues) => privateInstance.put<IAchEditValues, AxiosResponse<boolean>>(API_EDIT_ACH, values)

export const stopAch = (values: IAchStopValues) => privateInstance.put<number, AxiosResponse<boolean>>(API_STOP_ACH, values)

export const getNachaFiles = (params: INachaFilesGetParams) =>
  privateInstance.get<INachaFilesGetParams, AxiosResponse<INachaFile[]>>(API_GET_NACHA_FILES, {
    params: params,
  })

export const processDebits = (scheduledDate: string) =>
  privateInstance.post<unknown, AxiosResponse<boolean>>(
    API_PROCESS_DEBITS,
    {},
    {
      params: {
        scheduledDate,
      },
    },
  )

export const processCredits = (scheduledDate: string) =>
  privateInstance.post<unknown, AxiosResponse<boolean>>(
    API_PROCESS_CREDIT,
    {},
    {
      params: {
        scheduledDate,
      },
    },
  )

export const processFees = (scheduledDate: string) =>
  privateInstance.post<unknown, AxiosResponse<boolean>>(
    API_PROCESS_FEES,
    {},
    {
      params: {
        scheduledDate,
      },
    },
  )

export const cancelPayment = (id: string | number) =>
  privateInstance.post<typeof id, AxiosResponse<boolean>>(
    generatePath(API_CANCEL_PAYMENT, {
      id: id,
    }),
  )

export const duplicatePayment = (id: string | number) =>
  privateInstance.post<typeof id, AxiosResponse<boolean>>(
    generatePath(API_DUPLICATE_PAYMENT, {
      id: id,
    }),
  )

export const duplicateAllPayments = (date: MaterialUiPickersDate | string) => {
  return privateInstance.post<unknown, AxiosResponse<boolean>>(API_DUPLICATE_ALL_PAYMENTS, null, {
    params: {
      date,
    },
  })
}

import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { useInfoBLockStyle } from 'components/RepPerformance/style'
import cn from 'classnames'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

interface IInfoBlockProps {
  title?: string
  value?: string | number
  inhouse?: string | number
  outhouse?: string | number
  isLoading?: boolean
}

const InfoBlock: FC<IInfoBlockProps> = ({ title = 'asdasd', value = 628000, inhouse, outhouse, isLoading }) => {
  const classes = useInfoBLockStyle()
  return (
    <Box p={4} className={cn(classes.infoWrapper)}>
      {!isLoading ? (
        <>
          <Typography>{title}</Typography>
          <Box mt={1} mb={1}>
            <Typography variant="h3" color="textSecondary">
              {value}
            </Typography>
          </Box>
          <Typography color="textSecondary">
            IH: {inhouse} / OH: {outhouse}
          </Typography>
        </>
      ) : (
        <Skeleton height="4rem" width="100%" />
      )}
    </Box>
  )
}

export default InfoBlock

import { Box, Grid, Paper, SvgIcon, Typography } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { ReactComponent as AuditLogIcon } from 'assets/svg/AuditLogIcon.svg'
import { ReactComponent as TimeIcon } from 'assets/svg/TimeIcon.svg'
import { convertDate, convertDateWithTime, convertEmptyField } from 'utils/formatters'
import { IAuditLogListItem } from 'typescript/interfaces/deals'
import { useAuditLogItemStyles } from './style'

interface IAuditLogItemProps {
  data: IAuditLogListItem
}

const AuditLogItemDealSchedule: FC<IAuditLogItemProps> = ({ data }) => {
  const classes = useAuditLogItemStyles()

  const isNew = !!JSON.parse(data.historicalDatas[0].newValue)

  const schedule: any = useMemo(() => {
    if (isNew) {
      return JSON.parse(data.historicalDatas[0].newValue)
    } else {
      return JSON.parse(data.historicalDatas[0].oldValue)
    }
  }, [data.historicalDatas[0], isNew])

  return (
    <Paper elevation={0}>
      <Box p="2rem" display="flex">
        <Box mr="1rem">
          <SvgIcon className={classes.auditIcon} viewBox="0 0 18 24" component={AuditLogIcon} />
        </Box>

        <Box width="100%">
          {isNew ? (
            <Typography variant="h5" color="textSecondary">
              Schedule #{schedule?.Id} was added by: {data?.createdBy}
            </Typography>
          ) : (
            <Typography variant="h5" color="textSecondary">
              Schedule #{schedule?.Id} was removed by: {data?.createdBy}
            </Typography>
          )}
          <Box display="flex" mb="1.5rem" mt="0.75rem">
            <SvgIcon component={TimeIcon} />
            <Typography>{convertDateWithTime(data?.date)}</Typography>
          </Box>
          {isNew && (
            <Grid container item xs={12} lg={8} spacing={5}>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={6}>
                  <Typography>Start Date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(schedule.StartDate)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>End Date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(schedule.EndDate)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Frequent Payment</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(schedule.FrequentPayment)}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={6}>
                  <Typography>Term</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(schedule.Term)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Payment Frequency</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(schedule.PaymentFrequency === 0 ? 'Daily' : 'Weekly')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default AuditLogItemDealSchedule

import { Box } from '@material-ui/core'
import AddEditSyndicatorForm from 'components/AddEditSyndicatorForm'
import React from 'react'

const AddNewSyndicator = () => {
  return (
    <Box mb="2rem">
      <AddEditSyndicatorForm />
    </Box>
  )
}

export default AddNewSyndicator

import { Box } from '@material-ui/core'
import CreateNewPasswordForm from 'components/CreateNewPasswordForm'
import React from 'react'

const CreateNewPasswordPage = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <CreateNewPasswordForm />
    </Box>
  )
}

export default CreateNewPasswordPage

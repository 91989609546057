import makeStyles from '@material-ui/core/styles/makeStyles'
import { bg3 } from 'containers/MaterialUiContainer/mainTheme'

export const useTransactionsStyle = makeStyles((theme) => ({
  secondaryDarkTypography: {
    color: theme.palette.secondary.dark,
  },
  closeTrsModalButton: {
    padding: theme.spacing(0),
    borderRadius: '50%!important',
    border: '1px solid #D0DDE8!important',
    backgroundColor: `${bg3}!important`,
    '&:hover': {
      backgroundColor: '#D0DDE8!important',
    },
    '& svg': {
      '& path': {
        fill: '#738BA5',
      },
    },
  },
  linkBreadCrumb: {
    color: theme.palette.text.primary,
  },
  separator: {
    color: theme.palette.text.primary,
  },
}))

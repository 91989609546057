import React, { useRef } from 'react'
import { useTableComponentStyle } from 'UI/Table/style'
import { IColumn } from 'typescript/interfaces/tableUi'
import { IHolidayListItem } from 'typescript/interfaces/holidays'
import TableComponent, { TableComponentType } from 'UI/Table'
import { convertDate } from 'utils/formatters'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteTrashIcon.svg'

export const TableHolidays = TableComponent as TableComponentType<IHolidayListItem>

interface IUseHolidaysProps {
  handleDelete?: (id: number) => void
}

export const useHolidays: (props?: IUseHolidaysProps) => any = (props = {}) => {
  const { handleDelete } = props
  const classes = useTableComponentStyle()
  const columns = useRef<Array<IColumn<IHolidayListItem>>>([
    {
      label: 'Date',
      field: 'date',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertDate(data.date),
    },
    {
      label: 'Description',
      field: 'description',
      bodyCellClassName: classes.secondaryBodyCell,
    },
    {
      label: 'Repeat',
      field: 'isRepeated',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => (data.isRepeated ? 'Yes' : 'No'),
    },
    {
      field: 'actions',
      label: '',
      cellStyle: {
        width: 60,
      },
      render: (data) => {
        return (
          <IconButton size="small" onClick={() => handleDelete?.(data.id)}>
            <DeleteIcon />
          </IconButton>
        )
      },
    },
  ])

  return {
    columns: columns.current,
  }
}

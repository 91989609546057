import React, { FC } from 'react'
import { useFormik } from 'formik'
import { ICreateEditBankAccountClientValues } from 'typescript/interfaces/clients'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField/TextField'
import RequiredOption from 'UI/Select/RequiredOption'
import SelectComponent from 'UI/Select'
import { bankAccountTypes } from 'constants/bankAccountType'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Button from 'UI/Button/ButtonWithPreloader'
import Typography from '@material-ui/core/Typography'
import { schemaClientBankAccount } from 'components/AddClientBankAccount/schema'
import { useSnackbar } from 'notistack'

type InitValues = Omit<ICreateEditBankAccountClientValues, 'clientId'>

export interface IAddClientBankAccountFormProps {
  clientId: string | number
  submit: (values: ICreateEditBankAccountClientValues) => void
  initValues?: InitValues
  cancel: () => void
  isLoading?: boolean
  disableBalance?: boolean
}

const constInitValues: InitValues = {
  isMain: false,
  bankAccount: {
    bankAccount: '',
    bankRouting: '',
    availableBalance: '',
    bankAccountType: '',
    bankName: '',
  },
}

const AddClientBankAccountForm: FC<IAddClientBankAccountFormProps> = ({
  clientId,
  submit,
  initValues = constInitValues,
  cancel,
  isLoading,
  disableBalance,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, values, touched, errors, handleChange } = useFormik<ICreateEditBankAccountClientValues>({
    initialValues: {
      clientId: clientId,
      ...initValues,
    },
    validationSchema: schemaClientBankAccount,
    onSubmit: (vals) => {
      if (initValues.isMain && !vals.isMain)
        enqueueSnackbar('At least one bank account should be main. Select another bank account as main if you want to change the main bank account')
      else submit(vals)
    },
  })
  return (
    <form onSubmit={handleSubmit}>
      <Box maxWidth="100%" width={400}>
        <Box my="1rem">
          <Typography variant="h2" color="textSecondary">
            Bank Account
          </Typography>
        </Box>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <SelectComponent
              name="bankAccount.bankAccountType"
              onChange={handleChange}
              value={values.bankAccount.bankAccountType}
              error={Boolean(touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType)}
              helperText={touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType}
              fullWidth
              label={<RequiredOption label="Bank Account Type" />}
            >
              {bankAccountTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="bankAccount.bankRouting"
              onChange={handleChange}
              value={values.bankAccount.bankRouting}
              error={Boolean(touched.bankAccount?.bankRouting && errors.bankAccount?.bankRouting)}
              helperText={touched.bankAccount?.bankRouting && errors.bankAccount?.bankRouting}
              label={<RequiredOption label="Bank Routing #" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="bankAccount.bankAccount"
              onChange={handleChange}
              value={values.bankAccount.bankAccount}
              error={Boolean(touched.bankAccount?.bankAccount && errors.bankAccount?.bankAccount)}
              helperText={touched.bankAccount?.bankAccount && errors.bankAccount?.bankAccount}
              label={<RequiredOption label="Bank Account #" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="bankAccount.bankName"
              onChange={handleChange}
              value={values.bankAccount.bankName}
              error={Boolean(touched.bankAccount?.bankName && errors.bankAccount?.bankName)}
              helperText={touched.bankAccount?.bankName && errors.bankAccount?.bankName}
              label={<RequiredOption label="Bank Name" />}
            />
          </Grid>
        </Grid>
        <Box mt="1rem" display="flex" alignItems="center" justifyContent="flex-end">
          <Button loading={isLoading} color="secondary" variant="contained" onClick={() => cancel()}>
            Cancel
          </Button>
          <Box ml="1rem">
            <Button loading={isLoading} color="primary" variant="contained" type="submit">
              Complete
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default AddClientBankAccountForm

import {
  BANK_COMPANY_MUST_BE_AT_MOST_NINE_SYMBOLS,
  INVALID_FEDERAL_TAX_ID,
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
  ZIP_CODE_FORMAT_ERROR,
} from 'constants/errors'
import { zipCodeReg } from 'constants/regExp'
import * as yup from 'yup'

export const schemaAddEditCompany = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD),
  description: yup.string().required(REQUIRED_FIELD),
  city: yup.string().required(REQUIRED_FIELD),
  street: yup.string().required(REQUIRED_FIELD),
  stateId: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  federalTaxId: yup
    .string()
    .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val))
    .required(REQUIRED_FIELD),
  phone: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
  zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  bankCompanyId: yup.string().max(9, BANK_COMPANY_MUST_BE_AT_MOST_NINE_SYMBOLS),
  bankAccountName: yup.string().nullable(),
})

import React, { FC, useMemo } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { ResponsiveContainer, BarChart, Bar, YAxis, CartesianGrid, Tooltip, LabelList, XAxis } from 'recharts'
import { convertToPriceFormat, convertDate } from 'utils/formatters'
import useTheme from '@material-ui/core/styles/useTheme'
import { IFundedPerMonthData } from 'typescript/interfaces/rep'

interface IInhouseFundedPerMonthProps {
  data: IFundedPerMonthData[]
}

const InhouseFundedPerMonth: FC<IInhouseFundedPerMonthProps> = ({ data }) => {
  const theme = useTheme()

  const convertedData = data.map((item) => ({
    date: convertDate(item.reportDate),
    amount: item.fundedPerMonthIH,
  }))

  const maxLength = useMemo(() => {
    return (
      convertedData
        .reduce((acc, val) => {
          return acc < val.amount ? val.amount : acc
        }, 0)
        .toString().length *
        10 +
      10
    )
  }, [convertedData])

  return (
    <Box p={5}>
      <Typography>Inhouse Funded per Month</Typography>
      <Box mt="1rem" height="15rem" width="100%" mb="1rem">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={convertedData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <YAxis
              tick={{
                fill: theme.palette.text.primary,
                strokeWidth: 1,
              }}
              tickFormatter={(val) => convertToPriceFormat(val)}
              minTickGap={0}
              tickCount={5}
              allowDecimals={false}
              width={maxLength}
              axisLine={false}
              tickLine={false}
              interval="preserveStartEnd"
              tickMargin={0}
              padding={{
                top: 0,
                bottom: 0,
              }}
            />
            <XAxis dataKey="date" hide />
            <Tooltip key="date" />
            <Bar fillOpacity={0.3} fill={theme.palette.primary.main} dataKey="amount">
              <LabelList
                dataKey="date"
                content={(props) => {
                  const { x, y, width } = props
                  return (
                    <g>
                      <rect x={x} y={y} height={4} width={width} fill={theme.palette.primary.main} />
                    </g>
                  )
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default InhouseFundedPerMonth

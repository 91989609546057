import React from 'react'
import { Box } from '@material-ui/core'
import NotificationReceivedContainer from 'containers/NotificationReceivedContainer'

const NotificationReceivedPage = () => {
  return (
    <Box>
      <NotificationReceivedContainer />
    </Box>
  )
}

export default NotificationReceivedPage

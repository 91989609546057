import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import DatePicker from 'UI/DatePicker/DatePicker'
import cn from 'classnames'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { TableNacha, useNacha } from 'hooks/useNacha'
import { usePaginationList } from 'hooks/usePaginationList'
import { useMutation } from 'react-query'
import { getNachaFiles } from 'services/ach'
import { INachaFile } from 'typescript/interfaces/achDebit'
import { NachaFilesSortFields } from 'typescript/enums/ach'
import { getFile } from 'services/storage'
import { createLinkToDownloadTextFile } from 'utils/downloadFile'
import { deleteNachaFile } from 'services/iso'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import ModalComponent from 'UI/Modal'
import { differenceInCalendarDays, startOfDay, endOfDay } from 'date-fns'
import axios from 'axios'
import convertDateForPicker from 'utils/convertDateForPicker'

const NachaContainer: FC = () => {
  const classes = useAdditionalMaterialStyle()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteFileState, setDeleteFileState] = useState({
    id: 0,
    open: false,
  })

  const handleDownLoadFile = useCallback((url, name) => {
    getFile(url).then((res) => {
      axios.get(res.data).then((resText) => {
        createLinkToDownloadTextFile(resText.data, name)
      })
    })
  }, [])

  const [removeDoc, { isLoading: isLoadingRemove }] = useMutation(deleteNachaFile, {
    onSuccess: (_, variables) => {
      const { id } = deleteFileState
      setDeleteFileState({
        open: false,
        id: 0,
      })
      setPagination((prevState) => ({
        ...prevState,
        data: pagination.data.filter((d) => d.id !== id),
      }))
      enqueueSnackbar('File successfully deleted')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const { pagination, setPagination, handleSort, handleFetchMore } = usePaginationList<INachaFile>({
    initState: {
      data: [],
      search: '',
      order: NachaFilesSortFields.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
      startDate: new Date(),
      endDate: new Date(),
    },
  })

  const [getNachaFilesMut, { isLoading }] = useMutation(getNachaFiles, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  useEffect(() => {
    if (differenceInCalendarDays(new Date(pagination.endDate), new Date(pagination.startDate)) >= 0)
      getNachaFilesMut({
        PageSize: 20,
        PageIndex: pagination.page,
        Query: pagination.search,
        SortField: pagination.order,
        SortOrder: pagination.sortOrder,
        DateFrom: startOfDay(new Date(pagination.startDate || '')).toISOString(),
        DateTo: endOfDay(new Date(pagination.endDate || '')).toISOString(),
        // DateFrom: pagination.startDate,
        // DateTo: pagination.endDate,
      })
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder, pagination.startDate, pagination.endDate])

  const { columns } = useNacha({
    handleDelete: (idFile) => {
      setDeleteFileState({
        open: true,
        id: idFile,
      })
    },
    handleDownload: handleDownLoadFile,
  })

  return (
    <Paper className={classes.wrapper} elevation={0}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          NACHA Management
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography className={cn(classes.boldText)} color="textSecondary">
            Date:{' '}
          </Typography>
          <Box ml={4}>
            <DatePicker
              label="From"
              value={pagination.startDate}
              maxDate={pagination.endDate}
              maxDateMessage="From date must be less or equal than To date"
              type="keyboard"
              onChange={(date) =>
                setPagination((prevState) => ({
                  ...prevState,
                  data: [],
                  page: 0,
                  hasMore: true,
                  startDate: convertDateForPicker(date),
                }))
              }
            />
          </Box>
          <Box ml={4} alignSelf="flex-start">
            <DatePicker
              label="To"
              value={pagination.endDate}
              type="keyboard"
              onChange={(date) =>
                setPagination((prevState) => ({
                  ...prevState,
                  data: [],
                  page: 0,
                  hasMore: true,
                  endDate: convertDateForPicker(date),
                }))
              }
            />
          </Box>
        </Box>
      </Box>
      <Box mt="2rem">
        <TableNacha
          loading={isLoading}
          hasMore={pagination.hasMore}
          handleGetMore={handleFetchMore}
          handleSortChange={handleSort}
          columns={columns}
          data={pagination.data}
        />
      </Box>
      <ModalComponent open={deleteFileState.open}>
        <ConfirmationContent
          text="Are you sure you want delete file?"
          isLoading={isLoadingRemove}
          handleCancel={() =>
            setDeleteFileState({
              open: false,
              id: 0,
            })
          }
          handleConfirm={() => removeDoc(deleteFileState.id)}
        />
      </ModalComponent>
    </Paper>
  )
}

export default NachaContainer

import React, { FC, useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { fade } from '@material-ui/core'
import { IPerformanceDealIsos } from 'typescript/interfaces/rep'
import { getRandomColor } from 'utils/randomColor'

interface IAllIsoUsageChartProps {
  data: IPerformanceDealIsos[]
}

const AllIsoUsageChart: FC<IAllIsoUsageChartProps> = ({ data }) => {
  const fontSizeHtml = parseFloat(getComputedStyle(document.documentElement).fontSize) / 4

  const convertedData = useMemo(() => {
    return data.map((item) => ({
      count: item.dealsCount,
      name: item.iso.businessName,
    }))
  }, [data])

  return (
    <Box p={5}>
      <Typography>All ISO Usage</Typography>
      <Box display="flex" alignItems="center">
        <Box mt="1rem" height="15rem" flex={1} mb="1rem">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Tooltip key="name" />
              {/*<Pie*/}
              {/*  dataKey="count"*/}
              {/*  startAngle={90}*/}
              {/*  endAngle={450}*/}
              {/*  data={convertedData}*/}
              {/*  innerRadius={fontSizeHtml * 10.43}*/}
              {/*  outerRadius={fontSizeHtml * 11.06}*/}
              {/*>*/}
              {/*  <Tooltip labelFormatter={(index) => convertedData[index as number].name} />*/}
              {/*  <Cell strokeWidth={1} fill="#D0DDE8" />*/}
              {/*  <Cell strokeWidth={1} fill={theme.palette.primary.main} />*/}
              {/*</Pie>*/}
              <Pie
                dataKey="count"
                startAngle={90}
                endAngle={450}
                data={convertedData}
                innerRadius={fontSizeHtml * 10.43}
                outerRadius={fontSizeHtml * 16.19}
              >
                <Tooltip labelFormatter={(index) => convertedData[index as number].name} />
                {/*<Cell strokeWidth={1} fill="transparent" />*/}
                {convertedData.map((item) => {
                  const color = getRandomColor()
                  return <Cell strokeWidth={5} fill={fade(color, 0.5)} />
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  )
}

export default AllIsoUsageChart

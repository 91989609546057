import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import Button from 'UI/Button/ButtonWithPreloader'
import { SyndicatorListItem } from 'typescript/interfaces/syndicators'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustSquareIcon.svg'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as DocumentOutlined } from 'assets/svg/DocumentOutlined.svg'
import TableComponent, { TableComponentType } from 'UI/Table'
import { SYNDICATORS_NEW_URL } from 'constants/routes'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { getSyndicators } from 'services/syndicators'
import { PAGE_SIZE } from 'constants/pagination'
import { useSnackbar } from 'notistack'
import SearchField from 'UI/SearchField'
import { exportSyndicator } from 'services/export'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { ExportType } from 'typescript/enums/export'
import { downloadFile } from 'utils/downloadFile'
import { usePermission } from 'hooks/usePermission'
import { SYNDICATOR_CREATE_PERM } from 'constants/permissions'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import useSyndicatorTable from './useSyndicatorTable'

const TableClients = TableComponent as TableComponentType<SyndicatorListItem>

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2.25rem',
    fontWeight: 500,
  },
  companyNameLink: {
    fontSize: '1.1875rem',
    color: theme.palette.text.secondary,
    textDecoration: 'none',
  },
  tableIdHeaderCell: {
    paddingLeft: '3rem',
  },
  tableIdBodyCell: {
    paddingLeft: '3rem',
  },
  button: {
    fontSize: '1.25rem',
  },
}))

const SyndicatorsListContainer = () => {
  const s = useStyles()
  const additionalStyle = useAdditionalMaterialStyle()
  const { push } = useHistory()
  const { columns } = useSyndicatorTable()
  const snack = useSnackbar()
  const { hasPermission } = usePermission()
  const [pagination, setPagination] = useState<{
    data: Array<SyndicatorListItem>
    search: string
    order: any
    sortOrder: 'ASC' | 'DESC'
    page: number
    hasMore: boolean
  }>({
    data: [],
    search: '',
    order: 'Id',
    sortOrder: 'DESC',
    page: 0,
    hasMore: true,
  })

  const [loadSyndicators, { isLoading }] = useMutation(getSyndicators, {
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
    onSuccess(syndicatorsData) {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...syndicatorsData.data],
        hasMore: syndicatorsData.data.length === 20,
      }))
    },
  })

  const [exportSyndicatorExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportSyndicator, {
    onSuccess: (data, variables) => {
      downloadFile(data)
    },
    onError: () => {
      snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSearch = useCallback((search) => {
    setPagination((prevState) => ({
      ...prevState,
      data: prevState.search !== search ? [] : prevState.data,
      page: 0,
      search: search,
    }))
  }, [])

  const handleSort = useCallback((order, orderSor) => {
    setPagination((prevState) => ({
      ...prevState,
      data: [],
      order: order,
      sortOrder: orderSor,
    }))
  }, [])

  const handleFetchMore = useCallback(() => {
    setPagination((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }))
  }, [])

  useEffect(() => {
    loadSyndicators({
      PageSize: PAGE_SIZE,
      PageIndex: pagination.page,
      Query: pagination.search,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder])

  return (
    <Paper className={additionalStyle.wrapper}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box p="3rem" pb="2rem">
          <Typography className={s.title} variant="h2" color="textSecondary">
            Syndication summary report
          </Typography>
        </Box>
        <Box pr="3rem" display="flex">
          <Box width={240} mr={5}>
            <SearchField handleSearch={handleSearch} fullWidth label="Search Syndicator" />
          </Box>
          {hasPermission(SYNDICATOR_CREATE_PERM) && (
            <Button variant="text" onClick={() => push(SYNDICATORS_NEW_URL)} className={s.button} startIcon={<PlusIcon />} color="secondary">
              Add New Syndicator
            </Button>
          )}
          <Button
            loading={isLoadingExportExcel}
            onClick={() =>
              exportSyndicatorExcelMut({
                ExportType: ExportType.excel,
              })
            }
            variant="text"
            className={s.button}
            color="secondary"
            startIcon={<ExcelIcon />}
          >
            Export to Excel
          </Button>
          <Button
            loading={isLoadingExportExcel}
            onClick={() =>
              exportSyndicatorExcelMut({
                ExportType: ExportType.pdf,
              })
            }
            variant="text"
            className={s.button}
            color="secondary"
            startIcon={<DocumentOutlined />}
          >
            Export to PDF
          </Button>
        </Box>
      </Box>

      <TableClients
        handleGetMore={handleFetchMore}
        loading={isLoading}
        columns={columns}
        handleSortChange={handleSort}
        data={pagination.data || []}
        hasMore={pagination.hasMore}
      />
    </Paper>
  )
}

export default SyndicatorsListContainer

import { Box, CircularProgress, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { useQuery } from 'react-query'
import { BANKS_EDIT_URL } from 'constants/routes'
import { getBank } from 'services/banks'
import { ReactComponent as EditPenIcon } from 'assets/svg/EditPenIcon.svg'
import { convertEmptyField } from 'utils/formatters'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '3rem',
    fontWeight: 500,
    lineHeight: '3rem',
    paddingTop: '0.75rem',
  },
}))
const ViewBank = () => {
  const { goBack, push } = useHistory()
  const additionalStyle = useAdditionalMaterialStyle()
  const s = useStyles()
  const { id } = useParams<{ id: string }>()
  const { isLoading, data: resData } = useQuery(['bank', +id!], () => getBank(id || ''), {
    retry: false,
  })

  const handleEditClick = () => {
    push(BANKS_EDIT_URL.replace(':id', id))
  }

  const data = resData?.data

  return (
    <Box className={additionalStyle.wrapper} mb="2rem">
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="calc(100vh - 98px - 2rem)">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" mb="2rem" alignItems="center">
            <Box mr="1.5rem">
              <IconButton onClick={goBack} color="inherit">
                <ArrowLeftIcon />
              </IconButton>
            </Box>
            <Typography className={s.pageTitle} color="textSecondary">
              {data?.userPage.bankName}
            </Typography>
          </Box>

          <Paper elevation={0}>
            <Box position="relative" p="3rem" pb="2rem" display="flex" justifyContent="space-between">
              <Typography variant="h3" color="textSecondary">
                General Information
              </Typography>
              <Box position="absolute" top="1rem" right="1rem">
                <IconButton size="small" onClick={handleEditClick}>
                  <EditPenIcon />
                </IconButton>
              </Box>
            </Box>
            <Box p="3rem" pt="0">
              <Grid container>
                <Grid item container spacing={2} xs={6}>
                  <Grid item xs={6}>
                    <Typography>Bank Account</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">{convertEmptyField(data?.userPage.bankAccount)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Bank Routing</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">{convertEmptyField(data?.userPage.bankRouting)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Zip Code</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">{convertEmptyField(data?.zipCode)}</Typography>
                  </Grid>
                </Grid>
                <Grid item container spacing={2} xs={6}>
                  <Grid item xs={6}>
                    <Typography>Street</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">{convertEmptyField(data?.street)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>City</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">{convertEmptyField(data?.city)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>State</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">{convertEmptyField(data?.state.name)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </>
      )}
    </Box>
  )
}

export default ViewBank

import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { ISelectRefiDealsFormInitValues } from 'typescript/interfaces/deals'
import Button from 'UI/Button/ButtonWithPreloader'

interface ISelectRefiDealsFormProps {
  initValues?: ISelectRefiDealsFormInitValues
  submit: (values: ISelectRefiDealsFormInitValues) => void
  isLoading?: boolean
  cancel: () => void
}

const defaultInitValues: ISelectRefiDealsFormInitValues = {
  dealsIds: {},
  selectedDeals: [],
}

export const SelectRefiDealsForm = ({ initValues = defaultInitValues, submit, isLoading, cancel }: ISelectRefiDealsFormProps) => {
  const { handleSubmit, handleChange, values } = useFormik<ISelectRefiDealsFormInitValues>({
    initialValues: initValues,
    enableReinitialize: false,
    onSubmit: submit,
  })

  return (
    <Box width={240} maxWidth="100%">
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h2" color="textSecondary">
            Select Deals To Refi
          </Typography>
          {initValues.selectedDeals.map((element) => (
            <FormControlLabel
              name="dealsIds"
              onChange={(event, checked) => {
                handleChange({ target: { name: 'dealsIds', value: { ...values.dealsIds, [element.id]: checked } } })
              }}
              label={element.name}
              control={<Checkbox color="primary" name="dealsIds" />}
            />
          ))}
          <Box mt="2rem" display="flex" justifyContent="flex-end">
            <Button color="secondary" variant="contained" onClick={() => cancel()}>
              Cancel
            </Button>
            <Box ml="2rem">
              <Button loading={isLoading} type="submit" color="primary" variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

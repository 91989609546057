import React, { FC } from 'react'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { IEarlyDiscount, earlyDiscountDescription } from 'typescript/interfaces/deals'
import { MAX_VALUE_IS, MIN_VALUE_IS, NUMBERS_ONLY } from 'constants/errors'
import * as yup from 'yup'

const getOptionSchema = () =>
  yup
    .number()
    .nullable()
    .notRequired()
    .typeError(NUMBERS_ONLY)
    .min(1, MIN_VALUE_IS.replace(':value', '1'))
    .max(2, MAX_VALUE_IS.replace(':value', '2'))

const validationSchema = yup.object().shape({
  thirtyDays: getOptionSchema(),
  sixtyDays: getOptionSchema(),
  ninetyDays: getOptionSchema(),
  oneHundredTwentyDays: getOptionSchema(),
})

const defaultValues: IEarlyDiscount = {
  thirtyDays: undefined,
  sixtyDays: undefined,
  ninetyDays: undefined,
  oneHundredTwentyDays: undefined,
}

interface EarlyDiscountFormProps {
  initialValues?: IEarlyDiscount
  onSubmit: (values?: IEarlyDiscount) => void
  classes: any
}

const EarlyDiscountForm: FC<EarlyDiscountFormProps> = ({ initialValues, onSubmit, ...props }) => {
  const { values, errors, touched, handleChange, handleSubmit } = useFormik<IEarlyDiscount>({
    validationSchema,
    initialValues: initialValues ?? defaultValues,
    enableReinitialize: false,
    onSubmit: (formValues) => {
      let transformedValues: any = Object.fromEntries(Object.entries(formValues).map(([key, value]) => [key, value === '' ? null : value]))
      if (Object.values(transformedValues).every((x) => !x)) transformedValues = null

      onSubmit(transformedValues)
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={3}>
        <Typography variant="body1">Early discounts</Typography>
      </Box>
      {Object.entries(values)
        .map((entry) => entry as [keyof IEarlyDiscount, number?])
        .map(([key, factor]) => (
          <TextField
            {...props}
            fullWidth
            variant="outlined"
            placeholder="Factor rate"
            InputLabelProps={{ shrink: true }}
            name={key}
            value={factor ?? ''}
            onChange={handleChange}
            label={earlyDiscountDescription[key]}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
          />
        ))}
      <Button type="submit" color="primary" variant="contained">
        Save
      </Button>
    </form>
  )
}

export default EarlyDiscountForm

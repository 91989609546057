import { isValid } from 'date-fns'

const isValidDate = (date: any) => {
  const newDate = new Date(date)
  if (isValid(newDate)) {
    return true
  } else {
    return date === '' || date === null
  }
}

export default isValidDate

import { AxiosResponse } from 'axios'
import {
  API_CREATE_NOTIFICATION,
  API_GET_ALL_NEW_NOTIFICATION,
  API_GET_ALL_RECEIVED_NOTIFICATION,
  API_GET_NEW_NOTIFICATION_COUNT,
  API_GET_NOTIFICATION,
  API_GET_SENT_NOTIFICATION,
  API_MARK_NOTIFS_AS_READ,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { INewNotificationCreate, INewNotificationInitialValues, INotification } from 'typescript/interfaces/notifications'
import { IPagination } from 'typescript/interfaces/pagination'
import removeEmptyAttributes from 'utils/removeEmptyAttributes'

export const createNotification = (values: INewNotificationInitialValues) =>
  privateInstance.post<INewNotificationCreate, AxiosResponse<INewNotificationInitialValues>>(API_CREATE_NOTIFICATION, {
    ...values,
    usersId: values.usersId.map((user) => user.id),
    rolesId: values.rolesId.map((role) => role.id),
  })

export const getSentNotifications = (params: IPagination) =>
  privateInstance.get<INotification, AxiosResponse<Array<INotification>>>(API_GET_SENT_NOTIFICATION, {
    params: removeEmptyAttributes(params),
  })

export const getAllReceivedNotifications = (params: IPagination) =>
  privateInstance.get<INotification, AxiosResponse<Array<INotification>>>(API_GET_ALL_RECEIVED_NOTIFICATION, {
    params: removeEmptyAttributes(params),
  })

export const getNewNotificationsCount = () => privateInstance.get<INotification, AxiosResponse<number>>(API_GET_NEW_NOTIFICATION_COUNT)

export const getAllNewNotifications = (params: IPagination) =>
  privateInstance.get<INotification, AxiosResponse<Array<INotification>>>(API_GET_ALL_NEW_NOTIFICATION, {
    params: removeEmptyAttributes(params),
  })

export const getNotification = (id: string) =>
  privateInstance.get<INotification, AxiosResponse<INotification>>(API_GET_NOTIFICATION.replace(':id', id))

export const markAllAsRead = () => privateInstance.get(API_MARK_NOTIFS_AS_READ)

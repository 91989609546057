import { storageInstance } from 'services/axios/storageInstance'
import { API_DOWNLOAD_URL, API_GET_UPLOAD_URL } from 'constants/apiUrl'
import { IDocumentUploadLinkParams } from 'typescript/interfaces/storage'
import Axios, { AxiosResponse } from 'axios'

export const getFile = (url: string) =>
  storageInstance.get(API_DOWNLOAD_URL, {
    params: {
      url: url,
    },
  })

export const getDownloadUrl = (params: IDocumentUploadLinkParams) =>
  storageInstance.get<IDocumentUploadLinkParams, AxiosResponse<string>>(API_GET_UPLOAD_URL, {
    params: params,
  })

export const uploadToS3 = async (file: File, link: string) => {
  const buffer = await file.arrayBuffer()
  const response = await Axios.put<File, AxiosResponse<string>>(link, buffer, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}

import { Box, Grid, Paper, SvgIcon, Typography } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { ReactComponent as AuditLogIcon } from 'assets/svg/AuditLogIcon.svg'
import { ReactComponent as TimeIcon } from 'assets/svg/TimeIcon.svg'
import { convertDateWithTime } from 'utils/formatters'
import { IAuditLogListItem } from 'typescript/interfaces/deals'
import { useAuditLogItemStyles } from './style'

interface IAuditLogItemProps {
  data: IAuditLogListItem
}

const AuditLogItemNewDocument: FC<IAuditLogItemProps> = ({ data }) => {
  const classes = useAuditLogItemStyles()

  const isNew = !!JSON.parse(data.historicalDatas[0].newValue)

  const document: any = useMemo(() => {
    if (isNew) {
      return JSON.parse(data.historicalDatas[0].newValue)
    } else {
      return JSON.parse(data.historicalDatas[0].oldValue)
    }
  }, [data.historicalDatas[0], isNew])

  return (
    <Paper elevation={0}>
      <Box p="2rem" display="flex">
        <Box mr="1rem">
          <SvgIcon className={classes.auditIcon} viewBox="0 0 18 24" component={AuditLogIcon} />
        </Box>

        <Box width="100%">
          {isNew ? (
            <Typography variant="h5" color="textSecondary">
              New document {document?.Description} was added by: {data?.createdBy}
            </Typography>
          ) : (
            <Typography variant="h5" color="textSecondary">
              Document {document?.Description} was deleted by: {data?.createdBy}
            </Typography>
          )}
          <Box display="flex" mb="1.5rem" mt="0.75rem">
            <SvgIcon component={TimeIcon} />
            <Typography>{convertDateWithTime(data?.date)}</Typography>
          </Box>
          <Grid container item xs={12} lg={8} spacing={5}>
            <Grid item container xs={12} md={6} spacing={2}>
              <Grid item xs={6}>
                <Typography>Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">{document?.Description}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Type</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">{document?.DocumentType}</Typography>
              </Grid>
            </Grid>
            {isNew ? (
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={6}>
                  <Typography>Created At</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary"> {convertDateWithTime(document?.CreatedDate)}</Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={6}>
                  <Typography>Deleted At</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary"> {convertDateWithTime(data?.date)}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Paper>
  )
}

export default AuditLogItemNewDocument

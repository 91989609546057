import React, { FC } from 'react'
import MuiButton, { ButtonProps } from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface IButtonWithPreloaderProps extends ButtonProps {
  loading?: boolean
}

const useButtonStyle = makeStyles((theme) => ({
  circular: {
    '& svg': {
      '& *': {
        fill: 'transparent',
      },
    },
  },
}))

const Button: FC<IButtonWithPreloaderProps> = ({ disabled, loading, children, style, ...rest }) => {
  const classes = useButtonStyle()
  return (
    <MuiButton
      disabled={disabled || loading}
      style={{
        position: 'relative',
        ...style,
      }}
      {...rest}
    >
      {loading && (
        <Box position="absolute" left="calc(50% - 12.5px)" top="calc(50% - 12.5px)" width={25} height={25}>
          <CircularProgress
            color="primary"
            size="small"
            className={classes.circular}
            style={{
              width: 25,
              height: 25,
            }}
          />
        </Box>
      )}
      {children}
    </MuiButton>
  )
}

export default Button

import React, { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { DASHBOARD_VIEW_FIRST_WITH_CHART, DASHBOARD_VIEW_SECOND } from 'constants/routes'
import FirstViewDashboard from 'containers/Dashboard/FirstView'
import SecondDashboardView from 'containers/Dashboard/SecondView'

const DashboardContainer: FC = () => {
  return (
    <Switch>
      <Route path={DASHBOARD_VIEW_FIRST_WITH_CHART} component={FirstViewDashboard} />
      <Route path={DASHBOARD_VIEW_SECOND} component={SecondDashboardView} />
      <Redirect to={DASHBOARD_VIEW_SECOND} />
    </Switch>
  )
}

export default DashboardContainer

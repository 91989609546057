import { Box, Paper, Typography } from '@material-ui/core'
import { usePaginationList } from 'hooks/usePaginationList'
import { TableApproveSyndicationLimit, useApproveSyndicationLimit } from 'hooks/useApproveSyndicationLimit'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { getApprovedFunders } from 'services/rep'
import { IApprovedFunderListItem, ISetLimitModalData } from 'typescript/interfaces/rep'
import ModalComponent from 'UI/Modal'
import SetLimitForm from 'components/SetLimit'
import { setLimit } from 'services/syndicators'
import { useSnackbar } from 'notistack'
import { INVALID_LIMIT_VALUE, SOMETHING_WENT_WRONG, YOU_DONT_HAVE_ENOUGHT } from 'constants/errors'
import { AxiosError } from 'axios'
import { FailResponse } from 'typescript/interfaces/axios'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

interface ISetLimitModal {
  data: ISetLimitModalData
  open: boolean
}

const ApproveSyndicationLimitContainer = () => {
  const additionalStyles = useAdditionalMaterialStyle()

  const { enqueueSnackbar } = useSnackbar()
  const { pagination, handleFetchMore, handleSort, setPagination } = usePaginationList<IApprovedFunderListItem>({
    initState: {
      data: [],
      search: '',
      order: 'id',
      sortOrder: 'DESC',
      page: 0,
      hasMore: false,
    },
  })

  const [limitModal, setLimitModal] = useState<ISetLimitModal>({
    data: { representativeId: null, limit: null },
    open: false,
  })

  const handleModalOpen = (data: ISetLimitModalData) => {
    setLimitModal((prevState) => ({ data, open: true }))
  }

  const handleModalClose = () => {
    setLimitModal({ open: false, data: { representativeId: null, limit: null } })
  }

  const { columns } = useApproveSyndicationLimit({ handleModalOpen })

  const [getApprovedFundersMut, { isLoading }] = useMutation(getApprovedFunders, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [setLimitMut, { isLoading: setLimitLoading }] = useMutation(setLimit, {
    onSuccess: (data) => {
      if (pagination.page === 0) {
        getApprovedFundersMut(
          {
            PageSize: 20,
            PageIndex: pagination.page,
            SortField: pagination.order,
            SortOrder: pagination.sortOrder,
          },
          {
            onSuccess: (syndData) => {
              setPagination((prevState) => ({
                ...prevState,
                data: [...syndData.data],
                hasMore: syndData.data.length === 20,
              }))
            },
          },
        )
      } else {
        setPagination((prevState) => ({
          ...prevState,
          data: [],
          page: 0,
        }))
      }
      setLimitModal({ open: false, data: { representativeId: null, limit: null } })
    },
    onError(err: AxiosError<FailResponse>) {
      if (err.response?.data.description === INVALID_LIMIT_VALUE) {
        enqueueSnackbar(YOU_DONT_HAVE_ENOUGHT)
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const handleSubmit = (data: ISetLimitModalData) => {
    setLimitMut(data)
  }

  useEffect(() => {
    getApprovedFundersMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder])

  return (
    <Paper className={additionalStyles.wrapper} elevation={0}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          Approve Funders Limit
        </Typography>
      </Box>
      <Box>
        <TableApproveSyndicationLimit
          loading={isLoading}
          columns={columns}
          data={pagination.data}
          handleSortChange={handleSort}
          hasMore={pagination.hasMore}
          handleGetMore={handleFetchMore}
        />
      </Box>

      <ModalComponent open={limitModal.open} onClose={handleModalClose}>
        <Box>
          <Box mb="3rem">
            <Typography variant="h3" color="textSecondary">
              Approve limit
            </Typography>
          </Box>
          <SetLimitForm isLoading={setLimitLoading} initialValues={limitModal.data} handleSubmit={handleSubmit} handleCancel={handleModalClose} />
        </Box>
      </ModalComponent>
    </Paper>
  )
}

export default ApproveSyndicationLimitContainer

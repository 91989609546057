import { Box, FormControlLabel, Grid, TextField } from '@material-ui/core'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { useFormik } from 'formik'
import React, { FC } from 'react'
import { ICreateRoleForm } from 'typescript/interfaces/users'
import Button from 'UI/Button/ButtonWithPreloader'
import Checkbox from 'UI/Checkbox'
import { schemaAddNewRole } from './schema'

interface IAddNewRoleFormProps {
  cancel: () => void
  submit: (values: ICreateRoleForm) => void
  loading?: boolean
}

const AddNewRoleForm: FC<IAddNewRoleFormProps> = ({ cancel, submit, loading }) => {
  const classes = useAdditionalMaterialStyle()
  const { handleSubmit, values, errors, touched, handleChange } = useFormik<ICreateRoleForm>({
    initialValues: {
      name: '',
      isUndeletable: true,
    },
    validationSchema: schemaAddNewRole,
    onSubmit: submit,
  })
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={9}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            classes={{
              label: classes.checkboxLabel,
            }}
            name="isUndeletable"
            onChange={handleChange}
            label="Is Undeletable"
            control={<Checkbox checked={values.isUndeletable} color="primary" name="isUndeletable" />}
          />
        </Grid>
      </Grid>
      <Box mt={10}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button onClick={cancel} fullWidth color="secondary" variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button loading={loading} fullWidth color="primary" variant="contained" type="submit">
              Add New Role
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default AddNewRoleForm

import {
  API_GET_VENDOR_LIST,
  API_GET_VENDOR_BY_ID,
  API_CREATE_VENDOR,
  API_EDIT_VENDOR,
  API_GET_VENDOR_LIGHT_LIST,
  API_CONNECT_VENDOR_DOCUMENTS_TO_VENDOR,
  API_DELETE_VENDOR_DOCUMENT,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { AxiosResponse } from 'axios'
import { IPagination } from 'typescript/interfaces/pagination'
import {
  SortVendorFields,
  IVendorListItem,
  IVendorEntity,
  IVendorInitValues,
  IVendorListLightItem,
  IVendorDocumentConnectParams,
  IVendorDocumentConnectParamsResponse,
  IUploadVendorDocWithDocType,
} from 'typescript/interfaces/vendor'
import { getDownloadUrl, uploadToS3 } from 'services/storage'
import { storageInstance } from 'services/axios/storageInstance'

export const getVendorsList = (params: IPagination<SortVendorFields>) =>
  privateInstance.get<IPagination<SortVendorFields>, AxiosResponse<Array<IVendorListItem>>>(API_GET_VENDOR_LIST, {
    params: params,
  })

export const getVendorById = (id: string) =>
  privateInstance.get<{ id: string }, AxiosResponse<IVendorEntity>>(API_GET_VENDOR_BY_ID.replace(':id', id))

export const createVendor = (values: IVendorInitValues) =>
  privateInstance.post<IVendorInitValues, AxiosResponse<IVendorEntity>>(API_CREATE_VENDOR, values)

export const editVendor = (values: IVendorInitValues & { id: string | number }) =>
  privateInstance.put<IVendorInitValues, AxiosResponse<IVendorEntity>>(API_EDIT_VENDOR, values)

export const getVendorLightList = (params: IPagination<SortVendorFields>) =>
  privateInstance.get<IPagination<SortVendorFields>, AxiosResponse<Array<IVendorListLightItem>>>(API_GET_VENDOR_LIGHT_LIST, {
    params: params,
  })

export const deleteFileFromVendor = (id: number) => storageInstance.delete(API_DELETE_VENDOR_DOCUMENT.replace(':id', id.toString()))

export const connectUploadedUrlToVendor = async (body: IVendorDocumentConnectParams) =>
  storageInstance.post<IVendorDocumentConnectParams, AxiosResponse<IVendorDocumentConnectParamsResponse>>(
    API_CONNECT_VENDOR_DOCUMENTS_TO_VENDOR,
    body,
  )

export const uploadVendorDocuments = async (documents: IUploadVendorDocWithDocType[], vendorId: number) => {
  const links = await Promise.all(
    documents.map((doc) => {
      return getDownloadUrl({
        DocumentName: doc.file.name,
        DocumentDestination: 'Vendor',
        DestinationObjectId: vendorId,
      })
    }),
  )

  const uploadedFilesLinks = await Promise.all(links.map((link, i) => uploadToS3(documents[i].file, link.data)))

  const connectedUploadedUrls = await Promise.all(
    uploadedFilesLinks.map((uploadedLink, i) => {
      return connectUploadedUrlToVendor({
        documentUrl: links[i].data.split('?')[0],
        description: documents[i].file.name,
        documentType: documents[i].documentType,
        vendorId,
      })
    }),
  )

  return connectedUploadedUrls
}

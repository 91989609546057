import { AxiosResponse } from 'axios'

export const downloadFile = (response: AxiosResponse<any>) => {
  let fileName = 'untitled.xlsx'
  try {
    fileName = response.headers['content-disposition']
      .split(';')
      .find((n: any) => n.includes('filename='))
      .replace('filename=', '')
      .trim()
    fileName = fileName.replace(/"/g, '').replace(/\//g, '_')
  } catch {
    throw new Error("can't parse content-disposition header")
  }
  const url = window.URL.createObjectURL(new Blob([response.data]))
  createLinkToDownLoad(url, fileName)
}

export const createLinkToDownLoad = (url: string, fileName: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName) //or any other extension
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const createLinkToDownloadTextFile = (text: string, fileName: string) => {
  const link = document.createElement('a')
  link.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

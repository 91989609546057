import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import Paper from '@material-ui/core/Paper/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import VendorAutoComplete from 'components/VendorAutoComplete'
import cn from 'classnames'
import Button from 'UI/Button/ButtonWithPreloader'
import { useTableComponentStyle } from 'UI/Table/style'
import { useImportMassLegalFeesStyle } from 'containers/ImportMassLegalFees/style'
import { ReactComponent as FileIcon } from 'assets/svg/FileIcon.svg'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import { IFeeItemWithSelectedProps, IImportMassLegalFeeValues } from 'typescript/interfaces/massLegalfee'
import FormHelperText from '@material-ui/core/FormHelperText'
import { schemaImportMassLegalFees } from 'containers/ImportMassLegalFees/schema'
import { useMutation } from 'react-query'
import { getPreviewLegalFee, saveImportLegalFee } from 'services/legalFee'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { AxiosError } from 'axios'
import { TableImportMassLegalFees, useImportMassLegalFee } from 'hooks/useImportMassLegalFee'
import { convertToPriceFormat } from 'utils/formatters'
import { getFile } from 'services/storage'
import { createLinkToDownLoad } from 'utils/downloadFile'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const supportedTypes = ['xlsx', 'xls']

const ImportMassLegalFeesContainer: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const classes = useTableComponentStyle()
  const massLegalFeesClasses = useImportMassLegalFeesStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const [collectedData, setCollectedData] = useState<IFeeItemWithSelectedProps[] | undefined>(undefined)
  const [costsData, setCostsData] = useState<IFeeItemWithSelectedProps[] | undefined>(undefined)

  const countCosts = useMemo(() => {
    return costsData
      ? costsData.reduce((acc, val) => {
          return val.selected ? acc + 1 : acc
        }, 0)
      : 0
  }, [costsData])

  const countCollected = useMemo(() => {
    return collectedData
      ? collectedData.reduce((acc, val) => {
          return val.selected ? acc + 1 : acc
        }, 0)
      : 0
  }, [collectedData])

  const handleSelectAll = useCallback(
    (type: 'costs' | 'collected') => {
      if (type === 'costs') {
        setCostsData((prevState) => {
          return prevState
            ? prevState.map((item) => ({
                ...item,
                selected: !(prevState.length === countCosts),
              }))
            : prevState
        })
      } else if (type === 'collected') {
        setCollectedData((prevState) => {
          return prevState
            ? prevState.map((item) => ({
                ...item,
                selected: !(prevState.length === countCollected),
              }))
            : prevState
        })
      }
    },
    [countCosts, countCollected],
  )

  const handleDownLoadFile = useCallback((name) => {
    // eslint-disable-next-line max-len
    getFile('https://fl-staging-storage-api.s3.amazonaws.com/permanent/Example/LegalMassImport_gav33WkxSkOlQKAgyS6X6w%3D%3D.xlsx').then((res) => {
      createLinkToDownLoad(res.data, name)
    })
  }, [])

  const [saveImportMassLegalFeeMut, { isLoading: isLoadingImport }] = useMutation(saveImportLegalFee, {
    onSuccess: (data) => {
      resetForm()
      setCollectedData(undefined)
      setCostsData(undefined)
      enqueueSnackbar('Data successfully imported')
    },
  })

  const [getPreviewLegalFeeMut, { isLoading }] = useMutation(getPreviewLegalFee, {
    onSuccess: (data) => {
      // resetForm()
      setCollectedData(
        data.data.feeList
          .filter((fee) => fee.type === 'Collections')
          .map((item) => ({
            ...item,
            selected: true,
          })),
      )
      setCostsData(
        data.data.feeList
          .filter((fee) => fee.type === 'Costs')
          .map((item) => ({
            ...item,
            selected: true,
          })),
      )
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'BAD_DATA_PROVIDED') enqueueSnackbar('Invalid Data Provided')
      else if (err.response) {
        enqueueSnackbar(<pre style={{ fontSize: '1rem' }}>{err.response.data.errors.join('\n')}</pre>)
      } else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const { handleSubmit, errors, touched, setFieldValue, values, handleChange, resetForm } = useFormik<IImportMassLegalFeeValues>({
    initialValues: {
      VendorId: '',
      RefNumber: '',
      Document: '',
    },
    validationSchema: schemaImportMassLegalFees,
    onSubmit: (vals) => {
      const fileFormData = new FormData()
      fileFormData.append('Document', vals.Document)
      fileFormData.append('VendorId', vals.VendorId.toString())
      fileFormData.append('RefNumber', vals.RefNumber)
      getPreviewLegalFeeMut(fileFormData)
    },
  })

  const { columns: columnsCollected } = useImportMassLegalFee({
    countSelected: countCollected,
    handleSelectAll: handleSelectAll,
    type: 'collected',
    handleChange: (id, checked) => {
      setCollectedData((prevState) => {
        return prevState
          ? prevState.map((item) => ({
              ...item,
              selected: item.id === id ? checked : item.selected,
            }))
          : prevState
      })
    },
  })
  const { columns: columnsCosts } = useImportMassLegalFee({
    countSelected: countCosts,
    handleSelectAll: handleSelectAll,
    type: 'costs',
    handleChange: (id, checked) => {
      setCostsData((prevState) => {
        return prevState
          ? prevState.map((item) => ({
              ...item,
              selected: item.id === id ? checked : item.selected,
            }))
          : prevState
      })
    },
  })

  const totalCollected = useMemo(() => {
    if (collectedData)
      return collectedData.reduce((acc, val) => {
        if (val.selected) return val.amount + acc
        return acc
      }, 0)
    return 0
  }, [collectedData])

  const totalCost = useMemo(() => {
    if (costsData)
      return costsData.reduce((acc, val) => {
        if (val.selected) return val.amount + acc
        return acc
      }, 0)
    return 0
  }, [costsData])

  return (
    <Box className={additionalStyle.wrapper}>
      <Box mb={5}>
        <form onSubmit={handleSubmit}>
          <Paper elevation={0}>
            <Box p="3rem">
              <Typography variant="h3" color="textSecondary">
                Import Mass Legal Fees
              </Typography>
              <Box mt="2rem">
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <VendorAutoComplete
                      error={Boolean(touched.VendorId && errors.VendorId)}
                      helperText={touched.VendorId && errors.VendorId}
                      vendorId={values.VendorId}
                      onChange={(e, value) => {
                        if (value) setFieldValue('VendorId', value.id)
                        else setFieldValue('VendorId', '')
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Box display="flex" alignItems="center">
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={massLegalFeesClasses.uploadButton}
                        onClick={() => fileInputRef.current && fileInputRef.current.click()}
                      >
                        Upload file
                      </Button>
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ position: 'fixed', zIndex: -10 }}
                        onChange={(e) => {
                          if (e.target.files) {
                            const type = e.target.files[0].name.split('.')[1]
                            if (supportedTypes.includes(type)) {
                              setFieldValue('Document', e.target.files[0])
                            } else enqueueSnackbar("Files with inappropriate format can't be added")
                          }
                          if (fileInputRef.current) fileInputRef.current.value = ''
                        }}
                      />
                      <Box className={massLegalFeesClasses.boxFileName}>
                        <FileIcon style={{ width: 15, height: 15 }} />
                        <Typography>{typeof values.Document === 'object' ? values.Document.name : 'File name'}</Typography>
                      </Box>
                      {errors.Document && touched.Document && (
                        <Box ml={2}>
                          <FormHelperText error>{errors.Document}</FormHelperText>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mt="2rem">
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <TextField
                      name="RefNumber"
                      value={values.RefNumber}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      label="Invoice/Ref Number"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt="2rem" display="flex" alignItems="center" justifyContent="space-between">
                <Button loading={isLoading} color="primary" variant="contained" type="submit">
                  Preview Data
                </Button>
                <Box>
                  <Button startIcon={<FileIcon />} color="secondary" variant="text" onClick={() => handleDownLoadFile('sample.xls')}>
                    Download Sample File
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </form>
      </Box>
      {collectedData && costsData && (
        <Paper elevation={0}>
          <Box p="2rem">
            <Box display="flex" alignItems="center">
              <Box mr="6rem">
                <Typography className={cn(classes.colorSecondaryDark)} gutterBottom>
                  Total Collected:
                </Typography>
                <Typography variant="h3" color="textSecondary">
                  {convertToPriceFormat(totalCollected)}
                </Typography>
              </Box>
              <Box mr="6rem">
                <Typography className={cn(classes.colorSecondaryDark)} gutterBottom>
                  Total Costs:
                </Typography>
                <Typography variant="h3" color="textSecondary">
                  {convertToPriceFormat(totalCost)}
                </Typography>
              </Box>
              <Box>
                <Typography className={cn(classes.colorSecondaryDark)} gutterBottom>
                  Total:
                </Typography>
                <Typography variant="h3" color="textSecondary">
                  {convertToPriceFormat(totalCollected - totalCost)}
                </Typography>
              </Box>
            </Box>
            <Box mt="2rem" mx="-2rem">
              <Box ml="2rem">
                <Typography variant="h3" color="textSecondary">
                  Payments Collected
                </Typography>
              </Box>
              <TableImportMassLegalFees tableClassName={classes.table} columns={columnsCollected} data={collectedData || []} />
            </Box>
            <Box mt="2rem" mx="-2rem">
              <Box ml="2rem">
                <Typography variant="h3" color="textSecondary">
                  Legal Costs
                </Typography>
              </Box>
              <TableImportMassLegalFees tableClassName={classes.table} columns={columnsCosts} data={costsData || []} />
            </Box>
          </Box>
          <Box mt="4rem" p="2rem">
            <Button
              color="primary"
              variant="contained"
              loading={isLoadingImport}
              onClick={() => {
                if (collectedData && costsData)
                  saveImportMassLegalFeeMut([
                    ...collectedData.filter((item) => item.selected).map((item) => item.id),
                    ...costsData.filter((item) => item.selected).map((item) => item.id),
                  ])
              }}
            >
              Import Data
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export default ImportMassLegalFeesContainer

import React, { useMemo, useRef, useContext } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import { IDealsListItem } from 'typescript/interfaces/deals'
import { Box, fade } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { ReactComponent as ArrowUpIcon } from 'assets/svg/ArrowRightTopIcon.svg'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as InfoIcon } from 'assets/svg/InfoIcon.svg'
import { useLastDealsSecondViewStyle } from 'components/Dashboard/style'
import TableComponent, { TableComponentType } from 'UI/Table'
import {
  convertDate,
  convertEmptyField,
  converToTwoSignsAfterDot,
  convertToPriceFormat,
  formatBigNumber,
  camelCaseWrap,
  convertToPriceFormatWithSign,
} from 'utils/formatters'
import Button from 'UI/Button/ButtonWithPreloader'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DealsStatus, DealsType, SortFieldsDeal } from 'typescript/enums/deals'
import { generatePath, Link, useHistory } from 'react-router-dom'
import { DEAL_INFO_URL, NEW_DEAL_URL } from 'constants/routes'
import { insert } from 'utils/arrayHelpers'
import { ReactComponent as DeleteCircleIcon } from 'assets/svg/DeleteCircleIcon.svg'
import IconButton from '@material-ui/core/IconButton'
import { usePermission } from 'hooks/usePermission'
import { CREATE_CLIENT_DEAL, CREATE_INHOUSE_OUTHOUSE_PERM, DEAL_DELETE_PERM, DEAL_EDIT_PERM } from 'constants/permissions'
import { CompanyType } from 'typescript/interfaces/companies'
import { dealPaidStatusToColor } from 'constants/deals'
import { UserContext } from 'contexts/userContext'
import Typography from '@material-ui/core/Typography'
import { PaymentFrequencyEnum } from 'typescript/interfaces/paymentFrequency'

export const TableLastDealsSecondView = TableComponent as TableComponentType<IDealsListItem>

const useDealsTableStyle = makeStyles((theme) => ({
  errorButton: {
    border: `1px solid ${theme.palette.error.main}`,
    backgroundColor: fade(theme.palette.error.main, 0.05),
    color: theme.palette.error.main,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: fade(theme.palette.error.main, 0.1),
    },
  },
}))

interface IUseFullDealsProps {
  handleClickStatusButton?: (id: number, status: string) => void
  hideQuesDealAction?: boolean
  isQuesDeals?: boolean
  deleteDeal?: (id: number) => void
  view?: 'funded' | 'submitted' | 'dashboard' | 'my-deals'
}

export const useFullDeals = ({ isQuesDeals, hideQuesDealAction, handleClickStatusButton, deleteDeal, view }: IUseFullDealsProps | undefined = {}) => {
  const {
    state: { roles },
  } = useContext(UserContext)
  const { push } = useHistory()
  const classes = useLastDealsSecondViewStyle()
  const dealsTableClasses = useDealsTableStyle()
  const handleClickChangeStatus = handleClickStatusButton || (() => {})
  const { hasPermission } = usePermission()

  const columns = useMemo<Array<IColumn<IDealsListItem>>>(
    () => [
      {
        label: '#',
        field: 'id',
        sorting: false,
        headerCellClassName: classes.headerCell,
        render: (data) => <Link to={generatePath(DEAL_INFO_URL, { id: data.id })}>{data.id}</Link>,
      },
      {
        label: 'Date',
        sorting: false,
        field: 'date',
        headerCellClassName: classes.headerCell,
        render: (data: IDealsListItem) => {
          return convertDate(data.createdDate)
        },
      },
      ...(view === 'submitted'
        ? [
            {
              label: 'Status',
              field: SortFieldsDeal.status,
              sorting: true,
              headerCellClassName: classes.headerCell,
              render: (data: IDealsListItem) => camelCaseWrap(data.status !== DealsStatus.approved ? data.status : data.paidStatus),
            },
          ]
        : []),
      ...(view === 'funded' || view === 'dashboard' || view === 'my-deals'
        ? [
            {
              label: 'Paid status',
              field: SortFieldsDeal.paidStatus,
              sorting: true,
              headerCellClassName: classes.headerCell,
              render: (data: IDealsListItem) => camelCaseWrap(data.type === DealsType.outhouse ? data.status : data.paidStatus),
            },
          ]
        : []),
      {
        label: 'Business',
        field: SortFieldsDeal.businessName,
        sorting: true,
        headerCellClassName: classes.headerCell,
        render: (data) => {
          return (
            <Box display="flex" alignItems="center" color="text.secondary" maxWidth={220}>
              {!isQuesDeals && (
                <Tooltip title={data.paidStatus}>
                  <Box
                    mt={-0.5}
                    mr={1}
                    borderRadius="50%"
                    width={6}
                    height={6}
                    style={{
                      backgroundColor: data.status !== 'Approved' ? '#D0DDE8' : dealPaidStatusToColor[data.paidStatus],
                    }}
                  />
                </Tooltip>
              )}
              <Box whiteSpace="nowrap" maxWidth={210} textOverflow="ellipsis" overflow="hidden">
                {convertEmptyField(data.businessName)}
              </Box>
            </Box>
          )
        },
      },
      {
        label: 'Advanced',
        field: SortFieldsDeal.advanced,
        sorting: true,
        headerCellClassName: classes.headerCell,
        render: (data) => formatBigNumber(data.advanceAmount),
      },
      {
        label: 'Payback',
        field: SortFieldsDeal.payback,
        sorting: true,
        headerCellClassName: classes.headerCell,
        bodyCellClassName: classes.accentCell,
        render: (data) => formatBigNumber(data.payback),
      },
      {
        label: 'Weekly',
        field: SortFieldsDeal.weekly,
        sorting: true,
        headerCellClassName: classes.headerCell,
        bodyCellClassName: classes.blueCell,
        render: (data) =>
          formatBigNumber(data.paymentFrequency !== PaymentFrequencyEnum.Daily && data.paidStatus !== 'FullyPaid' ? data.frequentPayment : 0),
      },
      {
        label: 'Daily',
        field: SortFieldsDeal.daily,
        sorting: true,
        headerCellClassName: classes.headerCell,
        bodyCellClassName: classes.blueCell,
        render: (data) =>
          formatBigNumber(data.paymentFrequency === PaymentFrequencyEnum.Daily && data.paidStatus !== 'FullyPaid' ? data.frequentPayment : 0),
      },
      {
        label: 'Term / F',
        field: SortFieldsDeal.term,
        sorting: true,
        headerCellClassName: classes.headerCell,
        render: (data) => `${converToTwoSignsAfterDot(data.term)}/${converToTwoSignsAfterDot(data.factor)}`,
      },
      {
        label: 'Net',
        field: SortFieldsDeal.net,
        sorting: true,
        headerCellClassName: classes.headerCell,
        render: (data) => formatBigNumber(data.net),
      },
      {
        label: 'Collected',
        field: SortFieldsDeal.collected,
        sorting: true,
        headerCellClassName: classes.headerCell,
        render: (data) => {
          return (
            <Box fontSize="1rem" display="flex" alignItems="center">
              <Box mr={1} mb="-3px">
                <SvgIcon fontSize="inherit" viewBox="0 0 16 16" component={ArrowUpIcon} />
              </Box>
              {formatBigNumber(data.collected)}
              {data.unallocated > 0 && (
                <Box mt={0.5} ml={1}>
                  <Tooltip placement="top" title={`Unallocated: ${convertToPriceFormat(data.unallocated)}`}>
                    <InfoIcon color="orange" fill="orange" width={16} height={16} />
                  </Tooltip>
                </Box>
              )}
            </Box>
          )
        },
      },
      {
        label: 'Paid Off',
        field: SortFieldsDeal.paidOff,
        headerCellClassName: classes.headerCell,
        render: (data) => `${converToTwoSignsAfterDot(data.paidOff)}%`,
      },
      {
        label: 'Remaining',
        field: SortFieldsDeal.remaining,
        sorting: true,
        headerCellClassName: classes.headerCell,
        bodyCellClassName: classes.accentCell,
        render: (data) => formatBigNumber(data.remaining > 0 ? data.remaining : 0),
      },
      {
        label: 'Rep',
        field: SortFieldsDeal.rep,
        sorting: true,
        headerCellClassName: classes.headerCell,
        render: (data) => {
          return data.representatives?.map((rep) => (
            <Tooltip title={rep.name || ''}>
              <Box whiteSpace="nowrap" maxWidth={100} textOverflow="ellipsis" overflow="hidden">
                {convertEmptyField(rep.name)}
              </Box>
            </Tooltip>
          ))
        },
      },
      {
        label: 'ISO',
        field: 'iso',
        headerCellClassName: classes.headerCell,
        render: (data) => {
          return data.isos?.map((iso) => (
            <Tooltip title={iso.businessName || ''}>
              <Box whiteSpace="nowrap" maxWidth={100} textOverflow="ellipsis" overflow="hidden">
                {convertEmptyField(iso.businessName)}
              </Box>
            </Tooltip>
          ))
        },
      },
      {
        label: 'Inhouse company',
        field: SortFieldsDeal.inhouseCompany,
        sorting: true,
        headerCellClassName: classes.headerCell,
        render: (data) => convertEmptyField(data.company?.type === CompanyType.InHouse ? data.company?.name : null),
      },
      {
        label: 'PNL',
        field: SortFieldsDeal.pnl,
        sorting: true,
        headerCellClassName: classes.headerCell,
        bodyCellClassName: classes.accentCell,
        render: (data) => (
          <Tooltip
            placement="bottom-end"
            title={
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Funding</Typography>
                  <Box ml="1rem">{convertToPriceFormatWithSign(-data.funding)}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Commission</Typography>
                  <Box ml="1rem">{convertToPriceFormatWithSign(-data.totalCommission)}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Legal Fee</Typography>
                  <Box ml="1rem">{convertToPriceFormatWithSign(-data.legalFee)}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>PSF Fee</Typography>
                  <Box ml="1rem">{convertToPriceFormatWithSign(data.psfFee)}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Bank Fee</Typography>
                  <Box ml="1rem">{convertToPriceFormatWithSign(data.syndicationFee)}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Default fee</Typography>
                  <Box ml="1rem">{convertToPriceFormatWithSign(data.remaining <= 0 ? data.defaultFee : 0)}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Collected</Typography>
                  <Box ml="1rem">{convertToPriceFormatWithSign(data.remaining <= 0 ? data.collected - data.defaultFee : data.collected)}</Box>
                </Box>
              </Box>
            }
          >
            <Box>{data.pnl >= 0 ? formatBigNumber(data.pnl) : `(${formatBigNumber(Math.abs(data.pnl))})`}</Box>
          </Tooltip>
        ),
      },
    ],
    [view],
  )

  const columnsForClient = useMemo(() => {
    const dealTypeColumn: IColumn<IDealsListItem> = {
      label: 'Type',
      field: 'type',
      headerCellClassName: classes.headerCell,
      render: (data) => convertEmptyField(data.type),
    }

    const dealStatusColumn: IColumn<IDealsListItem> = {
      label: 'Status',
      field: 'status',
      headerCellClassName: classes.headerCell,
      render: (data) => {
        if (data.status !== DealsStatus.approved) return data.status
        return data.paidStatus
      },
    }

    const columnsToRender = insert(columns, 3, dealTypeColumn, dealStatusColumn)

    return columnsToRender
      .map((column) => {
        return {
          ...column,
          sorting: false,
        }
      })
      .concat([
        {
          label: 'Edit',
          field: 'edit',
          sorting: false,
          render: (data) => {
            return (
              <IconButton
                size="small"
                disabled={
                  !hasPermission(DEAL_EDIT_PERM) ||
                  data.status === DealsStatus.approved ||
                  data.status === DealsStatus.declined ||
                  (roles.isClient && data.type === DealsType.outhouse)
                }
                onClick={() => {
                  if (hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM)) {
                    push(
                      generatePath(NEW_DEAL_URL, {
                        id: data.id,
                        view: 'edit',
                        type: data.type === CompanyType.InHouse ? 'inhouse' : 'outhouse',
                        step: 1,
                      }),
                    )
                  } else if (hasPermission(CREATE_CLIENT_DEAL)) {
                    push(
                      generatePath(NEW_DEAL_URL, {
                        id: data.id,
                        view: 'edit',
                        type: 'client-deal',
                        step: 1,
                      }),
                    )
                  }
                }}
              >
                <EditIcon />
              </IconButton>
            )
          },
        },
        {
          label: 'Delete',
          field: 'delete',
          sorting: false,
          render: (data) => {
            return (
              <IconButton
                disabled={!hasPermission(DEAL_DELETE_PERM) || (roles.isClient && data.type === DealsType.outhouse)}
                size="small"
                onClick={() => {
                  if (deleteDeal) {
                    deleteDeal(data.id)
                  }
                }}
              >
                <DeleteCircleIcon />
              </IconButton>
            )
          },
        },
      ])
  }, [columns, roles])

  const columnsForDashboard = useMemo(() => {
    return [columns[0] || {}, columns[2] || {}, columns[6] || {}, columns[14] || {}]
  }, [columns])

  const columnsForTopByRemaining = useRef<Array<IColumn<IDealsListItem>>>([
    {
      label: '#',
      field: 'id',
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => <Link to={generatePath(DEAL_INFO_URL, { id: data.id })}>{data.id}</Link>,
    },
    {
      label: 'Business',
      field: SortFieldsDeal.businessName,
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => {
        return (
          <Box display="flex" alignItems="center" color="text.secondary" maxWidth={220}>
            {!isQuesDeals && (
              <Box
                mt={-0.5}
                mr={1}
                borderRadius="50%"
                width={6}
                height={6}
                style={{
                  backgroundColor: data.status !== 'Approved' ? '#D0DDE8' : dealPaidStatusToColor[data.paidStatus],
                }}
              />
            )}
            <Box whiteSpace="nowrap" maxWidth={210} textOverflow="ellipsis" overflow="hidden">
              {convertEmptyField(data.businessName)}
            </Box>
          </Box>
        )
      },
    },
    {
      label: 'Payback',
      field: SortFieldsDeal.payback,
      sorting: false,
      headerCellClassName: classes.headerCell,
      bodyCellClassName: classes.accentCell,
      render: (data) => formatBigNumber(data.payback),
    },
    {
      label: 'Remaining',
      field: SortFieldsDeal.remaining,
      sorting: false,
      headerCellClassName: classes.headerCell,
      bodyCellClassName: classes.accentCell,
      render: (data) => formatBigNumber(data.remaining),
    },
  ])

  const columnsForTopByCollected = useRef<Array<IColumn<IDealsListItem>>>([
    {
      label: '#',
      field: 'id',
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => <Link to={generatePath(DEAL_INFO_URL, { id: data.id })}>{data.id}</Link>,
    },
    {
      label: 'Business',
      field: SortFieldsDeal.businessName,
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => {
        return (
          <Box display="flex" alignItems="center" color="text.secondary" maxWidth={220}>
            {!isQuesDeals && (
              <Box
                mt={-0.5}
                mr={1}
                borderRadius="50%"
                width={6}
                height={6}
                style={{
                  backgroundColor: data.status !== 'Approved' ? '#D0DDE8' : dealPaidStatusToColor[data.paidStatus],
                }}
              />
            )}
            <Box whiteSpace="nowrap" maxWidth={210} textOverflow="ellipsis" overflow="hidden">
              {convertEmptyField(data.businessName)}
            </Box>
          </Box>
        )
      },
    },
    {
      label: 'Daily',
      field: SortFieldsDeal.daily,
      sorting: false,
      headerCellClassName: classes.headerCell,
      bodyCellClassName: classes.blueCell,
      render: (data) => formatBigNumber(data.frequentPayment),
    },
    {
      label: 'Collected',
      field: SortFieldsDeal.collected,
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => {
        return (
          <Box fontSize="1rem" display="flex" alignItems="center">
            <Box mr={1} mb="-3px">
              <SvgIcon fontSize="inherit" viewBox="0 0 16 16" component={ArrowUpIcon} />
            </Box>
            {formatBigNumber(data.collected)}
            {data.unallocated > 0 && (
              <Box mt={0.5} ml={1}>
                <Tooltip placement="top" title={`Unallocated: ${convertToPriceFormat(data.unallocated)}`}>
                  <InfoIcon color="orange" fill="orange" width={16} height={16} />
                </Tooltip>
              </Box>
            )}
          </Box>
        )
      },
    },
  ])

  const columnsForTopByAdvanced = useRef<Array<IColumn<IDealsListItem>>>([
    {
      label: '#',
      field: 'id',
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => <Link to={generatePath(DEAL_INFO_URL, { id: data.id })}>{data.id}</Link>,
    },
    {
      label: 'Business',
      field: SortFieldsDeal.businessName,
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => {
        return (
          <Box display="flex" alignItems="center" color="text.secondary" maxWidth={220}>
            {!isQuesDeals && (
              <Box
                mt={-0.5}
                mr={1}
                borderRadius="50%"
                width={6}
                height={6}
                style={{
                  backgroundColor: data.status !== 'Approved' ? '#D0DDE8' : dealPaidStatusToColor[data.paidStatus],
                }}
              />
            )}
            <Box whiteSpace="nowrap" maxWidth={210} textOverflow="ellipsis" overflow="hidden">
              {convertEmptyField(data.businessName)}
            </Box>
          </Box>
        )
      },
    },
    {
      label: 'Rep',
      field: SortFieldsDeal.rep,
      sorting: false,
      headerCellClassName: classes.headerCell,
      render: (data) => {
        return data.representatives?.map((rep) => (
          <Tooltip title={rep.name || ''}>
            <Box whiteSpace="nowrap" maxWidth={100} textOverflow="ellipsis" overflow="hidden">
              {convertEmptyField(rep.name)}
            </Box>
          </Tooltip>
        ))
      },
    },
    {
      label: 'Advanced',
      field: SortFieldsDeal.advanced,
      sorting: true,
      headerCellClassName: classes.headerCell,
      render: (data) => formatBigNumber(data.advanceAmount),
    },
  ])

  const columnsForFundingQueDeals = useMemo(() => {
    const filteredColumns = columns.filter(
      (c) =>
        c.field !== SortFieldsDeal.collected &&
        c.field !== SortFieldsDeal.paidOff &&
        c.field !== SortFieldsDeal.remaining &&
        c.field !== SortFieldsDeal.label &&
        c.field !== SortFieldsDeal.pnl,
    )

    return hideQuesDealAction
      ? filteredColumns
      : filteredColumns.concat({
          field: 'actions',
          label: '',
          render: (data) => {
            return (
              <Box display="flex" alignItems="center">
                <Button color="secondary" variant="outlined" size="small" onClick={() => handleClickChangeStatus(data.id, DealsStatus.approved)}>
                  Approve
                </Button>
                <Box ml={2}>
                  <Button
                    onClick={() => handleClickChangeStatus(data.id, 'Declined')}
                    className={dealsTableClasses.errorButton}
                    color="secondary"
                    variant="outlined"
                    size="small"
                  >
                    Decline
                  </Button>
                </Box>
              </Box>
            )
          },
        })
  }, [columns, hideQuesDealAction])

  return {
    columns: columns,
    columnsQues: columnsForFundingQueDeals,
    columnsForClient,
    columnsForDashboard,
    columnsForTopByRemaining: columnsForTopByRemaining.current,
    columnsForTopByCollected: columnsForTopByCollected.current,
    columnsForTopByAdvanced: columnsForTopByAdvanced.current,
  }
}

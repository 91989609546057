import { makeStyles } from '@material-ui/core'

export const useSyndicatedReportStatsStyles = makeStyles((theme) => ({
  tableCellContainer: {
    padding: 0,
    borderBottom: 'none',
  },
  rowBg: {
    background: theme.palette.background.default,
  },
  tableContainer: {
    width: '30%',
    '& .MuiTableCell-root': {
      border: 'none',
    },
  },
}))

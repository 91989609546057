import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import ClientInfoForm from 'components/ClientForms/ClientInfoForm'
import { useNewClientStyle } from 'containers/NewClient/style'
import { useMutation } from 'react-query'
import { addExistingClient, createClient } from 'services/clients'
import { NEW_OWNER_URL, VIEW_CLIENT_URL } from 'constants/routes'
import { IClientContactInfo, IClientInitValue } from 'typescript/interfaces/clients'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useHistory, generatePath } from 'react-router-dom'
import { AxiosError } from 'axios'
import {
  CLIENT_WITH_BUSINESS_NAME_EXISTS,
  CLIENT_WITH_BUSINESS_PHONE_EXISTS,
  CLIENT_WITH_EMAIL_EXISTS,
  SOMETHING_WENT_WRONG,
  USER_EXISTS,
} from 'constants/errors'
import { UserContext } from 'contexts/userContext'
import { SET_CLIENTS_BY_USER } from 'constants/actionTypes'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { FormikErrors } from 'formik'
import { Button } from '@material-ui/core'

interface IClientSnackbarActionProps {
  snackbarKey: SnackbarKey
  contactInfo: IClientContactInfo
  push: (path: string) => void
}

const ClientSnackbarAction: FC<IClientSnackbarActionProps> = ({ snackbarKey, contactInfo, push }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [addExistingClientMut] = useMutation(addExistingClient, {
    onSuccess: ({ data: clientId }) => {
      closeSnackbar(snackbarKey)
      push(VIEW_CLIENT_URL.replace(':id(\\d+)', clientId.toString()))
    },
    onError: () => {
      closeSnackbar(snackbarKey)
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  return (
    <>
      <Button type="button" color="primary" onClick={() => addExistingClientMut(contactInfo)}>
        Yes
      </Button>
      <Button type="button" color="primary" onClick={() => closeSnackbar(snackbarKey)}>
        No
      </Button>
    </>
  )
}

const ClientInfoContainer: FC = () => {
  const { dispatch, state } = useContext(UserContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { goBack, push } = useHistory()
  const classes = useNewClientStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const snackbarKeyRef = useRef<SnackbarKey>()

  const [formErrors, setFormErrors] = useState<FormikErrors<IClientInitValue>>({})

  const [createClientMut, { isLoading }] = useMutation(createClient, {
    onSuccess: (data) => {
      push(
        generatePath(NEW_OWNER_URL, {
          id: data.data.id.toString(),
          type: 'create',
        }),
      )
      dispatch({
        type: SET_CLIENTS_BY_USER,
        payload: data.data,
      })
      enqueueSnackbar('Client successfully created')
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'CLIENT_WITH_EMAIL_EXISTS') {
        enqueueSnackbar(USER_EXISTS)
      } else if (err.response?.data.description === 'EMAIL_USED_BY_ANOTHER_USER') {
        enqueueSnackbar(USER_EXISTS)
      } else if (err.response?.data.description === 'BANK_ACCOUNT_USED') {
        enqueueSnackbar('Current Bank Account already exists')
      } else if (err.response?.data.description === 'CLIENT_EXISTS') {
        const { errors } = err.response.data as { errors?: string[] }
        setFormErrors({
          businessName: errors?.includes('CLIENT_WITH_BUSINESS_NAME_EXISTS') ? CLIENT_WITH_BUSINESS_NAME_EXISTS : '',
          email: errors?.includes('CLIENT_WITH_EMAIL_EXISTS') ? CLIENT_WITH_EMAIL_EXISTS : '',
          businessPhone: errors?.includes('CLIENT_WITH_BUSINESS_PHONE_EXISTS') ? CLIENT_WITH_BUSINESS_PHONE_EXISTS : '',
        })
        if (state.roles.isRep) {
          const contactInfo = (({ businessName, email, businessPhone }) => ({ businessName, email, businessPhone }))(JSON.parse(err.config.data))
          enqueueSnackbar('The client already exists in the system. Would you like to add him to your list of clients?', {
            action: (snackbarKey) => <ClientSnackbarAction {...{ snackbarKey, contactInfo, push }} />,
            persist: true,
          })
        } else {
          enqueueSnackbar('The client already exists in the system.')
        }
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const handleSubmitClientInfo = useCallback(
    (values: IClientInitValue) => {
      const copyValues = {
        ...values,
        grossAnnualIncome: values.grossAnnualIncome || null,
      }
      createClientMut(copyValues)
    },
    [createClientMut],
  )

  useEffect(() => {
    return () => {
      if (snackbarKeyRef.current) {
        closeSnackbar(snackbarKeyRef.current)
      }
    }
  }, [])

  return (
    <Box className={additionalStyle.wrapper}>
      <Box display="flex" alignItems="center">
        {!state.roles.isClient && (
          <Box ml="3rem" mr="1rem">
            <IconButton color="inherit" onClick={goBack}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
        )}
        <Typography variant="h1" color="textSecondary">
          New Client
        </Typography>
      </Box>
      <Box mt="2rem">
        <Paper elevation={0} className={classes.paper}>
          <ClientInfoForm
            loading={isLoading}
            back={{
              handler: goBack,
              label: 'Back',
            }}
            submit={{
              handler: handleSubmitClientInfo,
              label: 'Save And Continue',
            }}
            formErrors={formErrors}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default ClientInfoContainer

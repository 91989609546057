import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import { INotification } from 'typescript/interfaces/notifications'
import { convertNotifSentBodyDate } from 'utils/formatters'
import { IconButton, makeStyles } from '@material-ui/core'
import { ReactComponent as CircleCloseIcon } from 'assets/svg/CircleCloseIcon.svg'

interface ISentNotificationModal {
  handleCancel: () => void
  notification: INotification | null
}

const useStyles = makeStyles((theme) => ({
  messageContent: {
    '& *': {
      wordBreak: 'break-word',
    },
    padding: '0 3rem',
    maxHeight: 234,
    overflow: 'auto',
  },
  date: {
    color: theme.palette.secondary.dark,
  },
  time: {
    display: 'inline-block',
    marginLeft: '1rem',
    color: theme.palette.info.contrastText,
  },
  border: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  to: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
  },
}))

const SentNotificationModalContent: FC<ISentNotificationModal> = ({ handleCancel, notification }) => {
  const s = useStyles()
  if (!notification) return null

  const [notifCreationDay, notifCreationTime] = convertNotifSentBodyDate(notification.creationDate).split(' ')

  return (
    <Box py="3rem" position="relative">
      <Box top="-1.25rem" right="-1.25rem" position="absolute">
        <IconButton style={{ width: 'auto' }} onClick={handleCancel}>
          <CircleCloseIcon />
        </IconButton>
      </Box>
      <Box width="680px">
        <Box px="3rem">
          <Typography variant="h4" className={s.date}>
            {notifCreationDay}
            <Typography component="span" variant="h4" className={s.time}>
              {notifCreationTime}
            </Typography>
          </Typography>
          <Box className={s.border} mt="2.5rem" mb="2.5rem" pb="0.5rem">
            <Typography variant="h4" color="textSecondary">
              {notification?.to}
            </Typography>
          </Box>
        </Box>

        {/* eslint-disable-next-line */}
        <div className={s.messageContent} dangerouslySetInnerHTML={{ __html: notification?.message! }}></div>
      </Box>

      <Box px="3rem" mt="2rem" display="flex" alignItems="center" justifyContent="flex-end">
        <Button color="secondary" onClick={handleCancel} variant="contained">
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

export default SentNotificationModalContent

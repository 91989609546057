import React, { FC, useEffect, useCallback, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TotalBlockDeal from 'components/FundingQues/TotalBlockDeal'
import { useFullDeals, TableLastDealsSecondView } from 'hooks/useFullDeals'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { getDealList, getFundingQues, changeDealStatus } from 'services/deals'
import { usePaginationList } from 'hooks/usePaginationList'
import { IDealsListItem } from 'typescript/interfaces/deals'
import { DealsStatus, SortFieldsDeal } from 'typescript/enums/deals'
import Skeleton from '@material-ui/lab/Skeleton'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const initState = {
  openModal: false,
  status: '',
  id: 0,
}

const FundingQueContainer: FC = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryCache()
  const [stateChangeStatus, setStateChangeStatus] = useState<{
    openModal: boolean
    status: string
    id: number
  }>(initState)

  const handleOpenModal = useCallback((id: number, status: string) => {
    setStateChangeStatus({
      openModal: true,
      id: id,
      status: status,
    })
  }, [])

  const [changeStatus, { isLoading: isLoadingStatusChange }] = useMutation(changeDealStatus, {
    onSuccess: (data) => {
      enqueueSnackbar(`Status successfully changed to ${stateChangeStatus.status}`)
      const { id } = stateChangeStatus
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.filter((d) => d.id !== id),
      }))
      setStateChangeStatus(initState)
      queryClient.fetchQuery(['ques'], getFundingQues, {
        retry: false,
        cacheTime: 0,
      })
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const { setPagination, handleFetchMore, handleSort, pagination } = usePaginationList<IDealsListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsDeal.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
      status: DealsStatus.readyToFund,
    },
  })

  const { data: dataQues, isLoading: isLoadingQues } = useQuery(['ques'], getFundingQues, {
    retry: false,
    cacheTime: 0,
  })

  const { columnsQues } = useFullDeals({
    handleClickStatusButton: handleOpenModal,
    hideQuesDealAction: pagination.status !== DealsStatus.readyToFund,
    isQuesDeals: true,
  })

  const readyToFundTotal = useMemo(() => {
    const que = dataQues ? dataQues.data.find((q) => q.statusName === DealsStatus.readyToFund) : undefined
    return {
      label: DealsStatus.readyToFund,
      count: que ? que.dealsCount : 0,
      total: que ? que.dealsSum : 0,
    }
  }, [dataQues])

  const approvedTotal = useMemo(() => {
    const que = dataQues ? dataQues.data.find((q) => q.statusName === DealsStatus.approved) : undefined
    return {
      label: DealsStatus.approved,
      count: que ? que.dealsCount : 0,
      total: que ? que.dealsSum : 0,
    }
  }, [dataQues])

  const declinedTotal = useMemo(() => {
    const que = dataQues ? dataQues.data.find((q) => q.statusName === 'Declined') : undefined
    return {
      label: 'Declined',
      count: que ? que.dealsCount : 0,
      total: que ? que.dealsSum : 0,
    }
  }, [dataQues])

  const [getDealListMut, { isLoading }] = useMutation(getDealList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const handleChangeActiveStatus = useCallback((status: string) => {
    setPagination((prevState) => ({
      ...prevState,
      data: status !== prevState.status ? [] : prevState.data,
      page: 0,
      status: status,
    }))
  }, [])

  const handleCancel = useCallback(() => {
    setStateChangeStatus((prevState) => ({
      ...prevState,
      openModal: false,
    }))
  }, [])

  const handleConfirmDealStatus = useCallback(() => {
    changeStatus({
      id: stateChangeStatus.id,
      status: stateChangeStatus.status,
    })
  }, [changeStatus, stateChangeStatus.id, stateChangeStatus.status])

  useEffect(() => {
    getDealListMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      Statuses: [pagination.status],
    })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.status])

  return (
    <>
      <Paper className={additionalStyle.wrapper} elevation={0}>
        <Box p="3rem">
          <Typography variant="h3" color="textSecondary">
            Funding Que
          </Typography>
          <Box mt={8}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                {isLoadingQues ? (
                  <Skeleton height={150} />
                ) : (
                  <TotalBlockDeal
                    setActiveStatus={handleChangeActiveStatus}
                    label={readyToFundTotal.label}
                    count={readyToFundTotal.count}
                    total={readyToFundTotal.total}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {isLoadingQues ? (
                  <Skeleton height={150} />
                ) : (
                  <TotalBlockDeal
                    setActiveStatus={handleChangeActiveStatus}
                    label={approvedTotal.label}
                    count={approvedTotal.count}
                    total={approvedTotal.total}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {isLoadingQues ? (
                  <Skeleton height={150} />
                ) : (
                  <TotalBlockDeal
                    setActiveStatus={handleChangeActiveStatus}
                    label={declinedTotal.label}
                    count={declinedTotal.count}
                    total={declinedTotal.total}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <Box className={additionalStyle.wrapper} mt="1rem">
        <Paper elevation={0}>
          <Box p="2rem">
            <Typography variant="h3" color="textSecondary">
              Deals
            </Typography>
          </Box>
          <TableLastDealsSecondView
            loading={isLoading}
            handleSortChange={handleSort}
            handleGetMore={handleFetchMore}
            columns={columnsQues}
            data={pagination.data}
            hasMore={pagination.hasMore}
          />
        </Paper>
      </Box>
      <ModalComponent open={stateChangeStatus.openModal} onClose={handleCancel}>
        <ConfirmationContent
          isLoading={isLoadingStatusChange}
          handleCancel={handleCancel}
          handleConfirm={handleConfirmDealStatus}
          text={`Are you sure you want to change status to ${stateChangeStatus.status}?`}
        />
      </ModalComponent>
    </>
  )
}

export default FundingQueContainer

import { OUTHOUSE_COMPANIES_NEW_URL, OUTHOUSE_COMPANIES_URL, OUTHOUSE_COMPANY_EDIT_URL } from 'constants/routes'
import NewOuthouseCompanyPage from 'pages/NewOuthouseCompany'
import OuthouseCompaniesListPage from 'pages/OuthouseCompaniesList'
import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'

const OuthouseCompaniesContainer: FC = () => {
  return (
    <Switch>
      <Route path={OUTHOUSE_COMPANIES_URL} exact component={OuthouseCompaniesListPage} />
      <Route path={[OUTHOUSE_COMPANY_EDIT_URL, OUTHOUSE_COMPANIES_NEW_URL]} exact component={NewOuthouseCompanyPage} />
    </Switch>
  )
}

export default OuthouseCompaniesContainer

import React from 'react'

import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'

import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { FailResponse } from 'typescript/interfaces/axios'
import { useHistory } from 'react-router-dom'
import { RESET_EMAIL_SENT_URL } from 'constants/routes'
import { INVALID_RESET_PASSWORD_ATTEMPT } from 'constants/errors'
import { forgotPassword } from 'services/auth/auth'

import { schemaReset } from './schema'

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 24,
  },
  button: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 48,
    color: '#4C5878',
  },
  smallText: {
    fontSize: 19,
    marginBottom: 36,
  },
}))

const ResetPasswordForm = () => {
  const s = useStyles()
  const { push } = useHistory()

  const [mutate, { isLoading }] = useMutation(forgotPassword, {
    onSuccess: () => {
      push(RESET_EMAIL_SENT_URL)
    },
    onError: (err: AxiosError<FailResponse>) => {
      if (err.response?.data.description === INVALID_RESET_PASSWORD_ATTEMPT) {
        setFieldError('email', 'User with entered email does not exist')
      } else {
        setFieldError('email', err.response?.data.description)
      }
    },
  })

  const { values, errors, touched, handleChange, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schemaReset,
    onSubmit: (formValues) => mutate(formValues),
  })
  return (
    <Box
      width="520px"
      maxWidth="80%"
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Typography className={s.text} align="center">
        Reset password
      </Typography>
      <Typography className={s.smallText} align="center">
        Please enter the email address you used for creating your account
      </Typography>
      <TextField
        className={s.input}
        variant="outlined"
        fullWidth
        label="Email"
        name="email"
        onChange={handleChange}
        value={values.email}
        error={!!errors.email && !!touched.email}
        helperText={!!touched.email && errors.email}
      />

      <Button disabled={isLoading} type="submit" className={s.button} color="primary" variant="contained" fullWidth>
        Send reset email
      </Button>
    </Box>
  )
}

export default ResetPasswordForm

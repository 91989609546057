import * as yup from 'yup'
import { BANK_ROUTING_MUST_BE_NINE_SYMBOLS, REQUIRED_FIELD, ZIP_CODE_FORMAT_ERROR } from 'constants/errors'
import { zipCodeReg } from 'constants/regExp'

export const schemaBankForm = yup.object().shape({
  city: yup.string().nullable(),
  street: yup.string().nullable(),
  zipCode: yup.string().nullable().matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  stateId: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  bankName: yup.string().nullable(),
  userPage: yup.object().shape({
    bankName: yup.string().required(REQUIRED_FIELD),
    bankRouting: yup
      .string()
      .test('isFullbankRoutingId', BANK_ROUTING_MUST_BE_NINE_SYMBOLS, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val))
      .required(REQUIRED_FIELD),
    bankAccount: yup.string().required(REQUIRED_FIELD),
  }),
})

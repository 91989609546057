import { Box, IconButton, Paper, Typography } from '@material-ui/core'
import { SOMETHING_WENT_WRONG, USER_EXISTS } from 'constants/errors'
import { usePaginationList } from 'hooks/usePaginationList'
import { useUserManagementTable, TableUserManagement } from 'hooks/useUserManagement'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { getUsers, setUserRoles, updateUser } from 'services/users'
import { IUpdateUserRolesForm, IUser } from 'typescript/interfaces/users'
import { ReactComponent as CancelIcon } from 'assets/svg/CloseIcon.svg'
import ModalComponent from 'UI/Modal'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import ChangeUserRolesForm from 'components/ChangeUserRolesForm'
import { forgotPassword, impersonateUser } from 'services/auth/auth'
import { JWT_TOKEN, REFRESH_TOKEN, USER_ROLE } from 'constants/localStorageKeys'
import SearchField from 'UI/SearchField'
import CalculateRestHeightBlock from 'components/CalculateRestHeightBlock'
import UserForm from 'components/UserForm'
import { AxiosError } from 'axios'
import { FailResponse } from 'typescript/interfaces/axios'

interface IChangeRolesModal {
  user: IUser | null
  open: boolean
}

const UserManagementContainer = () => {
  const classes = useAdditionalMaterialStyle()
  const [userModalState, setUserModalState] = useState<{
    open: boolean
    data: Partial<IUser> | undefined
  }>({
    open: false,
    data: undefined,
  })
  const { setPagination, handleSort, handleFetchMore, pagination, handleSearch } = usePaginationList<IUser>()
  const { enqueueSnackbar } = useSnackbar()

  const [forgotPasswordMut] = useMutation(forgotPassword, {
    onSuccess: () => {
      enqueueSnackbar('Letter successfully sent to email')
    },
    onError: (err: AxiosError<FailResponse>) => {
      enqueueSnackbar(err.response?.data.description)
    },
  })

  const [updateUserMut, { isLoading: isLoadingUpdateUser }] = useMutation(updateUser, {
    onSuccess: (data, variables) => {
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.map((user) => {
          if (user.id === variables.id)
            return {
              ...user,
              ...variables,
            }
          return user
        }),
      }))
      setUserModalState({
        open: false,
        data: undefined,
      })
      enqueueSnackbar('User successfully edited')
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'EMAIL_USED_BY_ANOTHER_USER') {
        enqueueSnackbar(USER_EXISTS)
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [getUsersMut, { isLoading }] = useMutation(getUsers, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [changeRolesModal, setChangeRolesModal] = useState<IChangeRolesModal>({
    user: null,
    open: false,
  })

  const handleChangeRolesClick = (user: IUser) => {
    setChangeRolesModal({ open: true, user })
  }

  const [setUserRolesMut, { isLoading: isSetUserRolesLoading }] = useMutation(setUserRoles, {
    onSuccess: (data, variables) => {
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.map((user) => {
          return user.id === variables.userId
            ? {
                ...user,
                userRoles: variables.roles,
              }
            : user
        }),
      }))
      setChangeRolesModal({ open: false, user: null })
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [impersonateMut, { isLoading: isImpersonating }] = useMutation(impersonateUser, {
    onSuccess: (data) => {
      localStorage.removeItem(USER_ROLE)
      localStorage.setItem(JWT_TOKEN, data.data.token)
      localStorage.setItem(REFRESH_TOKEN, data.data.refreshToken)
      window.location.pathname = '/'
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleImpersonateClick = (user: IUser) => {
    if (user.userRoles.length === 0) {
      enqueueSnackbar(`User ${user.email} must have at least one role for impersonate!`)
      return
    }
    impersonateMut(user.email)
  }

  const { columns } = useUserManagementTable({
    handleChangeRolesClick,
    handleImpersonateClick,
    impersonateLoading: isImpersonating,
    handleEdit: (data) =>
      setUserModalState({
        open: true,
        data: data,
      }),
    resetPassword: (data) =>
      forgotPasswordMut({
        email: data.email,
      }),
  })

  useEffect(() => {
    getUsersMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      Query: pagination.search,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.search])

  const handleSubmit = ({ roles }: IUpdateUserRolesForm) => {
    setUserRolesMut({ userId: changeRolesModal.user!.id, roles })
  }

  return (
    <Paper className={classes.wrapper} elevation={0}>
      <Box p="3rem" pb="1rem" display="flex" alignItems="flex-start" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          Users Management
        </Typography>
        <Box width="320px">
          <SearchField label="Search by email" handleSearch={(search) => handleSearch(search)} />
        </Box>
      </Box>
      <CalculateRestHeightBlock>
        <TableUserManagement
          loading={isLoading}
          data={pagination.data}
          hasMore={pagination.hasMore}
          handleGetMore={handleFetchMore}
          handleSortChange={handleSort}
          columns={columns}
        />
      </CalculateRestHeightBlock>
      <ModalComponent open={changeRolesModal.open}>
        <Box minWidth={300} maxWidth={500}>
          <Box display="flex" justifyContent="flex-end" mt="-1rem" mr="-1rem">
            <IconButton
              color="secondary"
              size="small"
              onClick={() => setChangeRolesModal({ open: false, user: null })}
              classes={{
                root: classes.closeTrsModalButton,
              }}
            >
              <CancelIcon />
            </IconButton>
          </Box>
          <Typography variant="h2" color="textSecondary">
            Change Roles
          </Typography>

          <Box mt="2rem">
            <ChangeUserRolesForm
              loading={isSetUserRolesLoading}
              cancel={() => setChangeRolesModal({ open: false, user: null })}
              submit={handleSubmit}
              user={changeRolesModal.user}
            />
          </Box>
        </Box>
      </ModalComponent>
      <ModalComponent open={userModalState.open}>
        <UserForm
          initValues={userModalState.data}
          isLoading={isLoadingUpdateUser}
          close={() =>
            setUserModalState({
              open: false,
              data: undefined,
            })
          }
          submit={(values) => updateUserMut(values)}
        />
      </ModalComponent>
    </Paper>
  )
}

export default UserManagementContainer

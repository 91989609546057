import * as yup from 'yup'
import { REQUIRED_FIELD } from 'constants/errors'

export const schemaNewNotificationForm = yup.object().shape({
  usersId: yup
    .array()
    .of(yup.object().shape({ id: yup.number(), email: yup.string() }))
    .test('oneOfFieldsRequired', 'You need to select specific users or roles', function () {
      return this.parent.usersId.length || this.parent.rolesId.length
    }),
  rolesId: yup
    .array()
    .of(yup.object().shape({ id: yup.number(), name: yup.string() }))
    .test('oneOfFieldsRequired', 'You need to select specific users or roles', function () {
      return this.parent.usersId.length || this.parent.rolesId.length
    }),
  subject: yup.string().required(REQUIRED_FIELD),
  message: yup.string().required(REQUIRED_FIELD),
  shortMessage: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('only empty characters', REQUIRED_FIELD, function () {
      const message = this.parent.shortMessage ?? ''
      return message.replace(/\n/g, '').replace(/ /g, '')
    }),
})

import React from 'react'
import { CheckboxProps, Checkbox as MuiCheckbox } from '@material-ui/core'
import { ReactComponent as CheckboxIcon } from 'assets/svg/Checkbox.svg'
import { ReactComponent as CheckboxCheckedIcon } from 'assets/svg/CheckboxChecked.svg'
import { ReactComponent as CheckboxCheckedDisabledIcon } from 'assets/svg/CheckboxCheckedDisabled.svg'

const Checkbox = (props: CheckboxProps) => {
  const { disabled } = props
  return <MuiCheckbox icon={<CheckboxIcon />} checkedIcon={disabled ? <CheckboxCheckedDisabledIcon /> : <CheckboxCheckedIcon />} {...props} />
}

export default Checkbox

import * as yup from 'yup'
import {
  BANK_ROUTING_MUST_BE_NINE_SYMBOLS,
  INVALID_EMAIL,
  INVALID_FEDERAL_TAX_ID,
  INVALID_PHONE_NUMBER,
  INVALID_PRICE_FORMAT,
  REQUIRED_FIELD,
  ZIP_CODE_FORMAT_ERROR,
} from 'constants/errors'
import { integerReg, priceReg, zipCodeReg } from 'constants/regExp'

export const userPageSchema = (isEdit: boolean) =>
  yup.object().shape({
    bankName: yup.string().required(REQUIRED_FIELD),
    availableBalance: yup.string().concat(!isEdit ? yup.string().required(REQUIRED_FIELD).matches(priceReg, INVALID_PRICE_FORMAT) : yup.string()),
    bankRouting: yup
      .string()
      .test('isFullbankRoutingId', BANK_ROUTING_MUST_BE_NINE_SYMBOLS, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val))
      .required(REQUIRED_FIELD),
    bankAccount: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(integerReg, 'Bank Account must be numeric')
      .test('isCorrectLength', 'Must be at least 5 characters and no more than 15', function (val) {
        return Boolean(val && val.length >= 5 && val.length <= 15)
      }),
  })

export const schemaVendorForm = (isEdit: boolean) =>
  yup.object().shape({
    vendorType: yup.string().required(REQUIRED_FIELD),
    ein: yup.string(),
    email: yup.string().required(REQUIRED_FIELD).email(INVALID_EMAIL),
    nameOnCheck: yup.string(),
    nameOnBankAccount: yup.string(),
    phone: yup
      .string()
      .required(REQUIRED_FIELD)
      .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
    userPage: userPageSchema(isEdit),
    businessName: yup.string().required(REQUIRED_FIELD),
    city: yup.string().required(REQUIRED_FIELD),
    street: yup.string().required(REQUIRED_FIELD),
    stateId: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    federalTaxId: yup
      .string()
      .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val))
      .required(REQUIRED_FIELD),
    zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  })

import React, { FC, useState, useRef, useCallback } from 'react'
import Paper from '@material-ui/core/Paper'
import { TableOwners, useOwners } from 'hooks/useOwners'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import { IOwnerEntity } from 'typescript/interfaces/owners'
import ModalComponent from 'UI/Modal'
import { deleteOwner } from 'services/clients'
import { useMutation, useQueryCache } from 'react-query'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { AxiosError, AxiosResponse } from 'axios'
import { IClientEntity } from 'typescript/interfaces/clients'

interface IOwnerInfoProps {
  owners: Array<IOwnerEntity>
  handleGoToOwner: () => void
  idClient: string
  readOnly: boolean
}

const OwnerInformation: FC<IOwnerInfoProps> = ({ owners, handleGoToOwner, idClient, readOnly }) => {
  const [openDeleteOwner, setOpenDeleteOwner] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const queryCache = useQueryCache()
  const idOwnerRef = useRef<null | string | number>(null)
  const { columns } = useOwners({
    idClient: idClient,
    handleOpenDeleteModal: (id) => {
      idOwnerRef.current = id
      setOpenDeleteOwner(true)
    },
    readOnly,
  })
  const [deleteOwnerMut, { isLoading }] = useMutation(deleteOwner, {
    onSuccess: () => {
      enqueueSnackbar('Owner successfully deleted')
      const prevClient = queryCache.getQueryData<AxiosResponse<IClientEntity>>(['clientById', idClient])
      queryCache.setQueryData(['clientById', idClient], {
        ...prevClient,
        data: {
          ...prevClient!.data,
          owners: prevClient!.data.owners.filter((o) => o.id !== idOwnerRef.current),
        },
      })
      setOpenDeleteOwner(false)
    },
    onError: (error: AxiosError) => {
      if (error.response && error.response.data.description === 'LAST_CLIENT_OWNER_DELETE_ATTEMPT') {
        enqueueSnackbar('Must be at least 1 owner')
      } else enqueueSnackbar(SOMETHING_WENT_WRONG)
      setOpenDeleteOwner(false)
    },
  })

  const handleDelete = useCallback(() => {
    if (idOwnerRef.current) deleteOwnerMut(idOwnerRef.current.toString())
  }, [])

  return (
    <Paper elevation={0}>
      <Box px={12} py={8} pt={13}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" color="textSecondary">
            Owner Information
          </Typography>
          {!readOnly && (
            <Box>
              <Button onClick={handleGoToOwner} startIcon={<PlusIcon />} variant="contained" color="primary">
                Add Owner
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <TableOwners columns={columns} data={owners} />
      </Box>
      <ModalComponent open={openDeleteOwner} onClose={() => setOpenDeleteOwner(false)}>
        <Box>
          <Box>
            <Typography variant="h3" color="textSecondary">
              Are you sure you want to delete owner?
            </Typography>
          </Box>
          <Box mt="2rem" display="flex" alignItems="center" justifyContent="flex-end">
            <Button onClick={() => setOpenDeleteOwner(false)} loading={isLoading} color="primary" variant="text">
              Cancel
            </Button>
            <Box ml="2rem">
              <Button onClick={handleDelete} loading={isLoading} color="primary" variant="contained">
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalComponent>
    </Paper>
  )
}

export default OwnerInformation

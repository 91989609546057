import React, { FC, useCallback } from 'react'
import RepForm from 'components/RepForm'
import { useMutation } from 'react-query'
import { createRep } from 'services/rep'
import { useSnackbar } from 'notistack'
import { BANK_ACCOUNT_ALREADY_USED, SOMETHING_WENT_WRONG } from 'constants/errors'
import { generatePath, useHistory } from 'react-router-dom'
import { UPLOAD_USER_PAGE_DOCS } from 'constants/routes'
import { AxiosError } from 'axios'

const NewRepContainer: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { push } = useHistory()
  const [createRepMut, { isLoading }] = useMutation(createRep, {
    onSuccess: (data) => {
      enqueueSnackbar('Representative successfully created')
      push(
        `${generatePath(UPLOAD_USER_PAGE_DOCS, {
          type: 'rep',
          id: data.data.id,
        })}?type=create`,
      )
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'REP_FOR_COMPANY_EXISTS') enqueueSnackbar('REP with the current company already exists')
      else if (err.response && err.response.data.description === 'REP_FOR_USER_EXISTS') enqueueSnackbar('REP with the current email already exists')
      else if (err.response && err.response.data.descripiton === 'ANOTHER_COMPANY_ATTACHED_TO_USER')
        enqueueSnackbar('User with the current email already exists')
      else if (err.response?.data.description === 'BANK_ACCOUNT_USED') enqueueSnackbar(BANK_ACCOUNT_ALREADY_USED)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })
  const handleSubmit = useCallback(
    (values) => {
      createRepMut(values)
    },
    [createRepMut],
  )

  return <RepForm label="Add New REP" submit={handleSubmit} loading={isLoading} />
}

export default NewRepContainer

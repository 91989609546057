import React, { FC, useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { editVendor, getVendorById } from 'services/vendor'
import { VENDOR_INFO_URL } from 'constants/routes'
import { BANK_ACCOUNT_ALREADY_USED, SOMETHING_WENT_WRONG } from 'constants/errors'
import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import VendorForm from 'components/VendorForm'
import { AxiosError } from 'axios'

const EditVendorContainer: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useQuery(['vendorById', id], () => getVendorById(id), {
    retry: false,
    cacheTime: 0,
  })

  const [editVendorMut, { isLoading: isLoadingEditRep }] = useMutation(editVendor, {
    onSuccess: () => {
      enqueueSnackbar('Vendor successfully edited')
      push(
        generatePath(VENDOR_INFO_URL, {
          id,
        }),
      )
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'VENDOR_FOR_COMPANY_EXISTS')
        enqueueSnackbar('Vendor with the current company already exists')
      else if (err.response?.data.description === 'ANOTHER_COMPANY_ATTACHED_TO_USER') enqueueSnackbar('Vendor with the current email already exists')
      else if (err.response?.data.description === 'BANK_ACCOUNT_USED') enqueueSnackbar(BANK_ACCOUNT_ALREADY_USED)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSubmit = useCallback(
    (values) => {
      editVendorMut({
        ...values,
        id: id,
      })
    },
    [id],
  )

  return isLoading || !data ? (
    <Box width="100%" display="flex" alignItems="center">
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <VendorForm
      label={`Edit Vendor ${data.data.businessName}`}
      submit={handleSubmit}
      loading={isLoadingEditRep}
      initValues={{
        nameOnCheck: data.data.nameOnCheck,
        userPage: data.data.userPage,
        email: data.data.email,
        phone: data.data.phone,
        ein: data.data.ein,
        vendorType: data.data.vendorType,
        comments: data.data.comments,
        businessName: data.data.businessName,
        city: data.data.city,
        street: data.data.street,
        zipCode: data.data.zipCode,
        federalTaxId: data.data.federalTaxId,
        stateId: data.data.state.id,
      }}
    />
  )
}

export default EditVendorContainer

import React, { FC } from 'react'
import { ThemeProvider, CssBaseline, StylesProvider, jssPreset } from '@material-ui/core'
import { create } from 'jss'
import { mainTheme } from 'containers/MaterialUiContainer/mainTheme'
import jssExpand from 'jss-plugin-expand'

const jss = create({
  plugins: [...jssPreset().plugins, jssExpand()],
  insertionPoint: 'jss-insertion-point',
})

const MaterialUiContainer: FC = ({ children }) => {
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesProvider>
  )
}

export default MaterialUiContainer

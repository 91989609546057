import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  authContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    background: '#F5F6F8',
  },
}))

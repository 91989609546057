import React, { FC, ReactElement, useMemo } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete/Autocomplete'
import { Company, CompanyLight, CompanyType, ICompaniesParamsLight } from 'typescript/interfaces/companies'
import { useQuery } from 'react-query'
import { getCompaniesLight } from 'services/companies'
import { Value } from '@material-ui/lab'
import { Chip } from '@material-ui/core'
import { useMultipleAutocompleteStyles } from 'components/RolesAutoComplete'
import cn from 'classnames'

interface IAutoCompleteProps {
  companyId: any | any[]
  companyEntity?: Company
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void
  error?: boolean
  helperText?: string | false
  label?: string | ReactElement
  companyType?: CompanyType
  multiple?: boolean
  disabled?: boolean
  paramsRequest?: Partial<ICompaniesParamsLight>
  allTypes?: boolean
}

const CompanyAutoComplete: FC<IAutoCompleteProps> = ({
  companyId,
  onChange,
  helperText,
  error,
  label = 'Company',
  companyType = CompanyType.InHouse,
  multiple,
  disabled,
  paramsRequest = {},
  companyEntity,
  allTypes,
}) => {
  const classes = useMultipleAutocompleteStyles()
  const { data, isLoading } = useQuery(['companies'], () => {
    return getCompaniesLight({
      PageSize: 4000,
      ...(allTypes ? {} : { CompanyType: companyType }),
      ...paramsRequest,
    })
  })
  const arrayCompanies = useMemo(() => {
    const dataCompanies: any[] = data ? data.data : []
    return companyEntity
      ? dataCompanies.concat({
          id: companyEntity.id,
          name: companyEntity.name,
        })
      : dataCompanies
  }, [data, companyEntity])

  const selectedOptionCompany = useMemo(() => {
    if (data) {
      const findCompany = arrayCompanies.find((c) => c.id === companyId) || null
      return findCompany
    }
    return null
  }, [arrayCompanies, companyId])

  const arraySelectedOptions = useMemo(() => {
    if (Array.isArray(companyId) && data) {
      return arrayCompanies.filter((c) => companyId.includes(c.id))
    }
    return []
  }, [arrayCompanies, companyId])

  return (
    <Autocomplete
      disabled={disabled}
      multiple={Boolean(multiple)}
      value={multiple ? arraySelectedOptions : selectedOptionCompany}
      loading={isLoading}
      options={arrayCompanies}
      classes={{ inputRoot: cn(classes.inputRoot, multiple) }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option: CompanyLight, index) => <Chip label={option.name} {...getTagProps({ index })} />)
      }}
      getOptionLabel={(option: CompanyLight) => {
        return option.name
      }}
      getOptionSelected={(option: CompanyLight, value) => {
        if (value && value.id) return option.id === value.id
        return false
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          helperText={helperText}
          error={error}
          label={label}
          InputProps={{
            ...params.InputProps,
            ref: params.InputProps.ref,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} />
              </>
            ),
          }}
          variant="outlined"
        />
      )}
    />
  )
}

export default CompanyAutoComplete

import React, { FC, useMemo } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import { ReactComponent as CircleCloseIcon } from 'assets/svg/CircleCloseIcon.svg'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete/Autocomplete'
import { useQuery } from 'react-query'
import { Value } from '@material-ui/lab'
import { getRepLight } from 'services/rep'
import { IRepListItemLight } from 'typescript/interfaces/rep'
import { Box, Chip } from '@material-ui/core'
import { useSelectComponentStyle } from 'UI/Select'
import cn from 'classnames'
import { useMultipleAutocompleteStyles } from 'components/RolesAutoComplete'

interface IAutoCompleteProps {
  repId: any
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void
  error?: boolean
  helperText?: string | false
  label?: string
  disabled?: boolean
  smoothLeft?: boolean
  smoothRight?: boolean
  multiple?: boolean
  className?: string
  isHouseHidden?: boolean
}

const RepAutoComplete: FC<IAutoCompleteProps> = ({
  repId,
  onChange,
  helperText,
  error,
  label = 'Rep',
  disabled,
  smoothLeft,
  smoothRight,
  className,
  isHouseHidden,
  multiple,
}) => {
  const { data, isLoading } = useQuery(['rep'], () => getRepLight({ PageSize: 4000 }))
  const arrayCompanies = useMemo(() => {
    return data ? (isHouseHidden ? data.data.filter((el) => el.id !== 1) : data.data) : []
  }, [data])
  const selectedOptionCompany = useMemo(() => {
    if (data) {
      const findCompany = data.data.find((c) => c.id === repId) || null
      return findCompany
    }
    return null
  }, [data, repId])

  const classes = useSelectComponentStyle()
  const mClasses = useMultipleAutocompleteStyles()

  const arraySelectedOptions = useMemo(() => {
    if (Array.isArray(repId) && data) {
      return arrayCompanies.filter((c) => repId.includes(c.id))
    }
    return []
  }, [arrayCompanies, repId])

  return (
    <Autocomplete
      multiple={Boolean(multiple)}
      disabled={disabled}
      value={multiple ? arraySelectedOptions : selectedOptionCompany}
      loading={isLoading}
      options={arrayCompanies}
      getOptionLabel={(option: IRepListItemLight) => {
        return option.name
      }}
      getOptionSelected={(option: IRepListItemLight, value) => {
        if (value && value.id) return option.id === value.id
        return false
      }}
      classes={{ inputRoot: cn(mClasses.inputRoot, multiple), root: className }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option: IRepListItemLight, index) => <Chip label={option.name} {...getTagProps({ index })} />)
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={helperText}
          error={error}
          label={label}
          className={cn({ [classes.disabledFormControlLeftBorder]: smoothLeft }, { [classes.disabledFormControlRightBorder]: smoothRight })}
          InputProps={{
            ...params.InputProps,
            ref: params.InputProps.ref,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                {selectedOptionCompany && (
                  <Box mr="0.25rem" display="flex" alignItems="center" justifyContent="center" style={{ cursor: 'pointer' }}>
                    <SvgIcon
                      fontSize="inherit"
                      viewBox="0 0 20 20"
                      component={CircleCloseIcon}
                      onClick={(e: any) => {
                        onChange!(e, undefined, 'clear')
                      }}
                    />
                  </Box>
                )}
                <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} />
              </>
            ),
          }}
          variant="outlined"
        />
      )}
    />
  )
}

export default RepAutoComplete

import React, { FC } from 'react'
import MainRouter from 'containers/Router'
import MaterialUiContainer from 'containers/MaterialUiContainer'
import { SnackbarProvider } from 'notistack'
// import { ReactQueryDevtools } from 'react-query-devtools'
import { ReactQueryCacheProvider } from 'react-query'
import { queryCache } from 'services/queryCache'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { CloseAction } from 'UI/SnackBar'

const App: FC = () => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <MaterialUiContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            autoHideDuration={6000}
            maxSnack={4}
            action={CloseAction}
            classes={{
              root: 'root-snackbar',
              containerAnchorOriginBottomLeft: 'root-snackbar-provider important',
            }}
          >
            <MainRouter />
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </MaterialUiContainer>
      {/*<ReactQueryDevtools initialIsOpen />*/}
    </ReactQueryCacheProvider>
  )
}

export default App

import React, { useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { Box } from '@material-ui/core'
import Button from 'UI/Button/ButtonWithPreloader'
import TableComponent, { TableComponentType } from 'UI/Table'
import { convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { IApprovedFunderListItem, ISetLimitModalData } from 'typescript/interfaces/rep'

export const TableApproveSyndicationLimit = TableComponent as TableComponentType<IApprovedFunderListItem>

interface IUseApproveSyndicatorProps {
  handleModalOpen: (data: ISetLimitModalData) => void
}

export const useApproveSyndicationLimit = ({ handleModalOpen }: IUseApproveSyndicatorProps) => {
  const classes = useTableComponentStyle()

  const columns = useRef<Array<IColumn<IApprovedFunderListItem>>>([
    {
      label: '#',
      field: 'id',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.id),
    },
    {
      label: 'Business Name',
      field: 'businessName',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box whiteSpace="nowrap" maxWidth={220} textOverflow="ellipsis" overflow="hidden">
          {data.businessName}
        </Box>
      ),
    },
    {
      label: 'First Name',
      field: 'firstName',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.firstName),
    },
    {
      label: 'Last Name',
      field: 'lastName',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.lastName),
    },
    {
      label: 'Limit',
      field: 'limit',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.limit),
    },

    {
      label: '',
      field: 'actions',
      render: (data) => (
        <Button onClick={() => handleModalOpen({ representativeId: data.id, limit: data.limit })} color="secondary" variant="outlined" size="small">
          Set Limit Amount
        </Button>
      ),
    },
  ])

  return {
    columns: columns.current,
  }
}

import React from 'react'
import { Box } from '@material-ui/core'
import EmailSent from 'components/EmailSent'

const EmailSentPage = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <EmailSent />
    </Box>
  )
}

export default EmailSentPage

import React, { FC, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { requestAnonymous } from 'services/rep'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Box } from '@material-ui/core'
import { FunderRequestStatusEnum } from 'typescript/enums/funder'
import { BASE_APP_URL, SIGN_IN_URL } from 'constants/routes'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { AxiosError } from 'axios'

const ApproveRequestInfoCooperationContainer: FC = () => {
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const infoToken = (params.get('infoToken') || '').split(' ').join('+')
  const coopToken = (params.get('coopToken') || '').split(' ').join('+')

  const [requestAnonymousMut, { isLoading }] = useMutation(requestAnonymous, {
    onSuccess: () => {
      if (infoToken) {
        enqueueSnackbar('Info Request Approved')
      } else if (coopToken) {
        enqueueSnackbar('Cooperation Request Approved')
      }
      push(BASE_APP_URL)
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'REQUEST_NOT_FOUND') {
        enqueueSnackbar('Request is already granted')
        push(BASE_APP_URL)
      } else if (err.response?.data.description === 'INVALID_ROLE') {
        enqueueSnackbar('Invalid Role')
        push(BASE_APP_URL)
      } else if (err.response?.status === 500) {
        enqueueSnackbar('Please login to continue')
        push(`${SIGN_IN_URL}?redirectTo=${location.pathname}${location.search}`)
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
        push(BASE_APP_URL)
      }
    },
  })

  useEffect(() => {
    if (infoToken || coopToken)
      requestAnonymousMut({
        token: infoToken || coopToken || '',
        ...(infoToken ? { infoRequestStatus: FunderRequestStatusEnum.Granted } : {}),
        ...(coopToken ? { cooperationRequestStatus: FunderRequestStatusEnum.Granted } : {}),
      })
  }, [infoToken, coopToken])

  return isLoading ? (
    <Box height="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  ) : (
    <></>
  )
}

export default ApproveRequestInfoCooperationContainer

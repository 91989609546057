import { Box, Button, IconButton, Paper, Typography } from '@material-ui/core'
import { ReactComponent as AddSquareIcon } from 'assets/svg/PlustSquareIcon.svg'
import { SOMETHING_WENT_WRONG, USER_ROLE_ALREADY_EXIST } from 'constants/errors'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { usePaginationList } from 'hooks/usePaginationList'
import { TableRoles, useRolesTable } from 'hooks/useRoles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { addNewRole, deleteRole, getRoles } from 'services/users'
import { ICreateRoleForm, IRole } from 'typescript/interfaces/users'
import ModalComponent from 'UI/Modal'
import { ReactComponent as CancelIcon } from 'assets/svg/CloseIcon.svg'
import AddNewRoleForm from 'components/AddNewRoleForm'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { AxiosError } from 'axios'

interface IDeleteRoleModal {
  id: string | null
  open: boolean
}

const UserRolesContainer = () => {
  const classes = useAdditionalMaterialStyle()
  const [openNewRoleModal, setOpenNewRoleModal] = useState(false)

  const [deleteRoleModal, setDeleteRoleModal] = useState<IDeleteRoleModal>({ open: false, id: null })

  const handleDeleteClick = (id: string) => {
    setDeleteRoleModal({ open: true, id })
  }

  const { columns } = useRolesTable({ handleDeleteClick })

  const { setPagination, handleSort, handleFetchMore, pagination } = usePaginationList<IRole>()
  const { enqueueSnackbar } = useSnackbar()

  const [getRolesMut, { isLoading }] = useMutation(getRoles, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [addNewRoleMut, { isLoading: newRoleLoading }] = useMutation(addNewRole, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, data.data],
      }))
      setOpenNewRoleModal(false)
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description?.includes('USER_ROLE_ALREADY_EXIST')) {
        enqueueSnackbar(USER_ROLE_ALREADY_EXIST)
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [deleteRoleMut, { isLoading: deleteRoleLoading }] = useMutation(deleteRole, {
    onSuccess: (_, id) => {
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.filter((role) => role.id !== +id),
      }))
      setDeleteRoleModal({ open: false, id: null })
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    getRolesMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder])

  const handleSubmit = (values: ICreateRoleForm) => {
    addNewRoleMut(values)
  }

  return (
    <Paper className={classes.wrapper} elevation={0}>
      <Box p="3rem" pb="1rem" display="flex" alignItems="flex-start" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          Roles
        </Typography>
        <Button variant="text" color="secondary" onClick={() => setOpenNewRoleModal(true)} startIcon={<AddSquareIcon />}>
          Add New Role
        </Button>
      </Box>
      <TableRoles
        loading={isLoading}
        data={pagination.data}
        hasMore={pagination.hasMore}
        handleGetMore={handleFetchMore}
        handleSortChange={handleSort}
        columns={columns}
      />
      <ModalComponent open={openNewRoleModal}>
        <Box minWidth={300} maxWidth={500}>
          <Box display="flex" justifyContent="flex-end" mt="-1rem" mr="-1rem">
            <IconButton
              color="secondary"
              size="small"
              onClick={() => setOpenNewRoleModal(false)}
              classes={{
                root: classes.closeTrsModalButton,
              }}
            >
              <CancelIcon />
            </IconButton>
          </Box>
          <Typography variant="h2" color="textSecondary">
            Add new role
          </Typography>

          <Box mt="2rem">
            <AddNewRoleForm cancel={() => setOpenNewRoleModal(false)} submit={handleSubmit} loading={newRoleLoading} />
          </Box>
        </Box>
      </ModalComponent>

      <ModalComponent open={deleteRoleModal.open}>
        <ConfirmationContent
          isLoading={deleteRoleLoading}
          handleCancel={() =>
            setDeleteRoleModal((prevState) => ({
              id: null,
              open: false,
            }))
          }
          handleConfirm={() => deleteRoleMut(deleteRoleModal.id!)}
          text="Are you sure you want to delete role?"
        />
      </ModalComponent>
    </Paper>
  )
}

export default UserRolesContainer

import React from 'react'

import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core'
import { SIGN_IN_URL } from 'constants/routes'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'

import { schemaSignUp } from './schema'

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: 24,
  },
  input2: {
    marginBottom: 16,
  },
  button: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 48,
    marginBottom: 36,
    color: '#4C5878',
  },
  smallText: {
    marginTop: 32,
    fontSize: 19,
  },
  link: {
    color: '#249AF3',
    textDecoration: 'none',
    fontSize: 19,
  },
}))

const SignUpForm = () => {
  const s = useStyles()
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      fullName: '',
      email: '',
    },
    validationSchema: schemaSignUp,
    onSubmit: () => {},
  })
  return (
    <Box
      width="520px"
      maxWidth="80%"
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Typography className={s.text} align="center">
        Create your account
      </Typography>

      <TextField
        size="small"
        variant="outlined"
        fullWidth
        className={s.input}
        label="Full name"
        name="fullName"
        error={!!errors.fullName && !!touched.email}
        onChange={handleChange}
        value={values.fullName}
        helperText={!!touched.fullName && errors.fullName}
      />
      <TextField
        size="small"
        className={s.input2}
        variant="outlined"
        fullWidth
        label="Email"
        name="email"
        onChange={handleChange}
        value={values.email}
        error={!!errors.email && !!touched.email}
        helperText={!!touched.email && errors.email}
      />

      <Button type="submit" className={s.button} color="primary" variant="contained" fullWidth>
        Next
      </Button>

      <Typography className={s.smallText} align="center">
        Already have account?{' '}
        <Link className={s.link} to={SIGN_IN_URL}>
          Sign in
        </Link>
      </Typography>
    </Box>
  )
}

export default SignUpForm

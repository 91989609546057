import React, { FC, useRef } from 'react'
import Select, { SelectProps } from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import cn from 'classnames'
import { FormHelperText } from '@material-ui/core'

interface ISelectComponent extends SelectProps {
  label?: string | React.ReactElement
  labelProps?: InputLabelProps
  smoothLeft?: boolean
  smoothRight?: boolean
  error?: boolean
  helperText?: string | false | undefined
}

export const useSelectComponentStyle = makeStyles((theme) => ({
  labelRoot: {
    color: theme.palette.secondary.dark,
  },
  labelShrink: {},
  marginDenseLabel: {},
  disabledFormControlRightBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      // borderRight: 'none',
      borderRightWidth: 0.5,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  disabledFormControlLeftBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderLeftWidth: 0.5,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  // outlinedLabel: {
  //   transform: 'translate(0.875rem, 0.95rem) scale(1)',
  //   '&$marginDenseLabel': {
  //     transform: 'translate(0.875rem, 0.95rem) scale(1)',
  //   },
  //   '&$labelShrink': {
  //     transform: 'translate(10px, -18px) scale(1)',
  //   },
  // },
  rootFormControl: {
    // paddingTop: 20,
    '&:not(:last-of-the-type)': {
      color: 'red',
    },
  },
}))

const SelectComponent: FC<ISelectComponent> = ({
  label,
  labelProps,
  children,
  inputProps,
  smoothLeft,
  smoothRight,
  fullWidth = true,
  error,
  helperText,
  ...rest
}) => {
  const classes = useSelectComponentStyle()
  const refSelect = useRef() as React.MutableRefObject<HTMLInputElement>
  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      size="small"
      error={error}
      className={cn({ [classes.disabledFormControlLeftBorder]: smoothLeft }, { [classes.disabledFormControlRightBorder]: smoothRight })}
    >
      {label && (
        <InputLabel
          error={error}
          shrink={!!rest.value}
          classes={{
            root: classes.labelRoot,
            // outlined: classes.outlinedLabel,
            shrink: classes.labelShrink,
            marginDense: classes.marginDenseLabel,
          }}
          {...labelProps}
        >
          {label}
        </InputLabel>
      )}
      <Select
        IconComponent={(props) => <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} {...props} />}
        error={error}
        inputProps={{
          ...inputProps,
        }}
        MenuProps={{
          // disableScrollLock: true,
          onExited: () => refSelect && refSelect.current && refSelect.current.focus(),
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...rest}
      >
        {React.Children.map(children, (child) => child)}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
      <input
        ref={refSelect}
        style={{
          left: 0,
          top: 0,
          position: 'fixed',
          zIndex: -100,
          border: 'none',
          height: 0.1,
        }}
      />
    </FormControl>
  )
}

export default SelectComponent

import { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertDate, convertEmptyField, converToTwoSignsAfterDot, convertToPriceFormat } from 'utils/formatters'
import { IPortfolioSummaryItem } from 'typescript/interfaces/syndicators'
import { IPortfolioSummarySortFields } from 'typescript/enums/syndicators'
import { DealsStatus } from 'typescript/enums/deals'

export const TablePortfolioSummary = TableComponent as TableComponentType<IPortfolioSummaryItem>

export const usePortfolioSummaryTable = () => {
  const classes = useTableComponentStyle()
  const columns = useRef<Array<IColumn<IPortfolioSummaryItem>>>([
    {
      label: 'Merchant',
      sorting: true,
      field: IPortfolioSummarySortFields.businessName,
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertEmptyField(data.merchant),
    },
    {
      label: 'Company',
      field: 'company',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.company),
    },
    {
      label: 'Funded',
      sorting: true,
      field: 'funded',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.funded),
    },
    {
      label: 'Deal',
      sorting: true,
      field: 'id',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.deal),
    },
    {
      label: 'Status',
      sorting: true,
      field: 'status',
      bodyCellClassName: classes.accentCell,
      render: (data) => {
        if (data.status !== DealsStatus.approved) return convertEmptyField(data.status)
        return convertEmptyField(data.paidStatus)
      },
    },
    {
      label: 'Start date',
      // sorting: true,
      field: 'startDate',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.createdDate),
    },
    {
      label: 'Payment freq',
      // sorting: true,
      field: 'paymentFrequency',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.paymentFrequency),
    },
    {
      label: 'Term',
      sorting: true,
      field: IPortfolioSummarySortFields.term,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.term),
    },
    {
      label: 'Shares',
      // sorting: true,
      field: 'shares',
      bodyCellClassName: classes.accentCell,
      render: (data) => `${convertEmptyField(data.shares)} %`,
    },
    {
      label: 'Deal End',
      // sorting: true,
      field: 'endDate',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.dealEndDate),
    },
    {
      label: 'Factor',
      // sorting: true,
      field: 'factor',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.factorRate),
    },
    {
      label: 'ACH Pmnt',
      sorting: true,
      field: IPortfolioSummarySortFields.frequent,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.frequentPayment),
    },
    {
      label: 'Advance',
      sorting: true,
      field: IPortfolioSummarySortFields.advanced,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.advanceAmount),
    },
    {
      label: 'Payback',
      sorting: true,
      field: IPortfolioSummarySortFields.payback,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.paybackAmount),
    },
    {
      label: 'Bank fee',
      // sorting: true,
      field: 'syndicationComission',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.syndicationFee),
    },
    {
      label: 'Paid Off',
      field: IPortfolioSummarySortFields.paidOff,
      bodyCellClassName: classes.accentCell,
      render: (data) => `${converToTwoSignsAfterDot(data.paidOff > 100 ? 100 : data.paidOff)}%`,
    },
    {
      label: 'Total Cost',
      sorting: true,
      field: 'totalCost',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.payOffAmount),
    },
    {
      label: 'Proj RTR',
      field: 'projRTR',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.projectedRtr),
    },
  ])

  return {
    columns: columns.current,
  }
}

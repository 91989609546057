import { Box } from '@material-ui/core'
import { EnhancedReportForm } from 'components/IntegrationReportForms/EnhanceReportForm'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import React from 'react'

export const EnhancedReportContainer = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  return (
    <Box className={additionalStyle.wrapper} mb="2rem">
      <EnhancedReportForm />
    </Box>
  )
}

import React, { FC } from 'react'
import { useFormik } from 'formik'
import { ILenderParams } from 'typescript/interfaces/deals'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from 'UI/Checkbox'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Box from '@material-ui/core/Box'
import Button from 'UI/Button/ButtonWithPreloader'
import { assignLenderSchema } from 'components/AssignLenderForm/schema'
import SyndicatorAutoComplete from 'components/SyndicatorAutoComplete'
import { getPercentage } from 'utils/getPercentage'

interface IAssignLenderForm {
  submit: (values: ILenderParams) => void
  initValue?: ILenderParams
  dealId: string
  cancel: () => void
  loading?: boolean
  mode?: string
  advanceAmount: number
}

interface IAssignLenderFormInitValues extends ILenderParams {
  syndicatorName?: string
}

const AssignLenderForm: FC<IAssignLenderForm> = ({ submit, dealId, cancel, initValue, loading, mode, advanceAmount }) => {
  const { values, setFieldValue, errors, touched, handleSubmit, setValues } = useFormik<IAssignLenderFormInitValues>({
    initialValues: initValue || {
      syndicatorId: '',
      syndicatorName: '',
      lenderId: 0,
      dealId: dealId,
      lendingPercentage: '',
      isLead: false,
      lendingAmount: '',
    },
    validationSchema: assignLenderSchema,
    onSubmit: submit,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <SyndicatorAutoComplete
            disabled={mode === 'edit'}
            error={Boolean(touched.syndicatorId && errors.syndicatorId)}
            helperText={touched.syndicatorId && errors.syndicatorId}
            syndicatorId={values.syndicatorId}
            onChange={(e, value) => {
              if (!value) return
              setFieldValue('syndicatorId', value.id)
              setFieldValue('syndicatorName', value.name)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt="1rem">
            <TextField
              fullWidth
              label="Percentage"
              name="lendingPercentage"
              value={values.lendingPercentage}
              onChange={(e) => {
                e.persist()
                setValues({
                  ...values,
                  lendingPercentage: e.target.value,
                  lendingAmount: Number.isNaN(e.target.value) || Number(e.target.value) < 0 ? '' : +e.target.value * advanceAmount * 0.01,
                })
              }}
              variant="outlined"
              error={Boolean(touched.lendingPercentage && errors.lendingPercentage)}
              helperText={touched.lendingPercentage && errors.lendingPercentage}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt="1rem">
            <TextField
              fullWidth
              label="Amount"
              name="lendingAmount"
              value={values.lendingAmount}
              onChange={(e) => {
                e.persist()
                setValues({
                  ...values,
                  lendingPercentage: Number.isNaN(e.target.value) || Number(e.target.value) < 0 ? '' : getPercentage(advanceAmount, +e.target.value),
                  lendingAmount: e.target.value,
                })
              }}
              variant="outlined"
              error={Boolean(touched.lendingAmount && errors.lendingAmount)}
              helperText={touched.lendingAmount && errors.lendingAmount}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox name="isLead" checked={values.isLead} color="primary" onChange={(e) => setFieldValue('isLead', e.target.checked)} />}
            label={<Typography color="textSecondary">Main Lender</Typography>}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button variant="contained" color="secondary" onClick={cancel} loading={loading}>
              Cancel
            </Button>
            <Box ml={3}>
              <Button loading={loading} variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default AssignLenderForm

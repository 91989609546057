import React, { useEffect, useState } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IFeeItemWithSelectedProps } from 'typescript/interfaces/massLegalfee'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import Checkbox from 'UI/Checkbox'
import { convertDate, convertToPriceFormat } from 'utils/formatters'

export const TableImportMassLegalFees = TableComponent as TableComponentType<IFeeItemWithSelectedProps>

interface IUseImportMassLegalFee {
  type: 'collected' | 'costs'
  handleChange: (id: number, checked: boolean) => void
  handleSelectAll: (type: 'collected' | 'costs') => void
  countSelected: number
}

export const useImportMassLegalFee = ({ type, handleChange, countSelected, handleSelectAll }: IUseImportMassLegalFee) => {
  const classes = useTableComponentStyle()
  const [columns, setColumns] = useState<IColumn<IFeeItemWithSelectedProps>[]>([])

  useEffect(() => {
    setColumns([
      {
        label: (data) => <Checkbox checked={data.length === countSelected} name="isActive" color="primary" onChange={(e) => handleSelectAll(type)} />,
        field: 'select',
        cellStyle: {
          width: 70,
        },
        render: (data) => (
          <Checkbox checked={data.selected} name="isActive" color="primary" onChange={(e) => handleChange(data.id, e.target.checked)} />
        ),
      },
      {
        label: 'Debtor',
        field: 'debtor',
        cellStyle: {
          width: '20%',
        },
        bodyCellClassName: classes.secondaryDarkCell,
      },
      {
        label: 'Client Claim #',
        field: 'clientClaim',
        cellStyle: {
          width: '10%',
        },
        bodyCellClassName: classes.secondaryDarkCell,
      },
      {
        label: 'Date',
        field: 'date',
        cellStyle: {
          width: '10%',
        },
        bodyCellClassName: classes.secondaryDarkCell,
        render: (data) => convertDate(data.date),
      },
      {
        label: type === 'collected' ? 'Collected amount' : 'Cost amount',
        field: 'type',
        cellStyle: {
          width: '10%',
        },
        bodyCellClassName: classes.secondaryDarkCell,
        render: (data) => convertToPriceFormat(data.amount),
      },
      {
        label: 'Comments',
        field: 'comments',
        cellStyle: {
          width: '25%',
        },
        bodyCellClassName: classes.secondaryDarkCell,
      },
      {
        label: 'Status',
        field: 'status',
        cellStyle: {
          width: '15%',
        },
        bodyCellClassName: classes.secondaryDarkCell,
      },
    ])
  }, [countSelected, type])

  return {
    columns: columns,
  }
}

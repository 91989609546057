import React, { FC, useEffect } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as AddSquareIcon } from 'assets/svg/PlustSquareIcon.svg'
import { VENDOR_ADD_NEW_URL } from 'constants/routes'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import Paper from '@material-ui/core/Paper/Paper'
import { useHistory } from 'react-router-dom'
import { TableVendors, useVendors } from 'hooks/useVendors'
import { usePaginationList } from 'hooks/usePaginationList'
import { useMutation } from 'react-query'
import { getVendorsList } from 'services/vendor'
import { IVendorListItem, SortVendorFields } from 'typescript/interfaces/vendor'
import { exportVendor } from 'services/export'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { ExportType } from 'typescript/enums/export'
import { downloadFile } from 'utils/downloadFile'
import { usePermission } from 'hooks/usePermission'
import { VENDORS_ADD_PERM } from 'constants/permissions'
import SearchField from 'UI/SearchField'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const VendorsListContainer: FC = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  const { enqueueSnackbar } = useSnackbar()
  const { hasPermission } = usePermission()
  const { push } = useHistory()
  const { columns } = useVendors()
  const { setPagination, handleSort, handleFetchMore, pagination, handleSearch } = usePaginationList<IVendorListItem>({
    initState: {
      data: [],
      search: '',
      order: SortVendorFields.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getVendors, { isLoading: isLoadingVendor }] = useMutation(getVendorsList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [exportVendorExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportVendor, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    getVendors({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      Query: pagination.search,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.search])

  return (
    <Paper className={additionalStyle.wrapper} elevation={0}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          Vendors
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width={240} mr={5}>
            <SearchField handleSearch={handleSearch} fullWidth label="Search Vendor" />
          </Box>
          {hasPermission(VENDORS_ADD_PERM) && (
            <Button variant="text" color="secondary" startIcon={<AddSquareIcon />} onClick={() => push(VENDOR_ADD_NEW_URL)}>
              Add New Vendor
            </Button>
          )}
          <Box ml={5}>
            <Button
              loading={isLoadingExportExcel}
              onClick={() =>
                exportVendorExcelMut({
                  ExportType: ExportType.excel,
                })
              }
              variant="text"
              color="secondary"
              startIcon={<ExcelIcon />}
            >
              Export to Excel
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableVendors
          loading={isLoadingVendor}
          columns={columns}
          data={pagination.data}
          hasMore={pagination.hasMore}
          handleGetMore={handleFetchMore}
          handleSortChange={handleSort}
        />
      </Box>
    </Paper>
  )
}

export default VendorsListContainer

import React, { FC } from 'react'
import { useFormik } from 'formik'
import { IHolidayCreateForm } from 'typescript/interfaces/holidays'
import { Box, Typography, TextField } from '@material-ui/core'
import DatePicker from 'UI/DatePicker/DatePicker'
import Button from 'UI/Button/ButtonWithPreloader'
import { schemaHoliday } from 'components/HolidayForm/schema'
import RequiredOption from 'UI/Select/RequiredOption'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from 'UI/Checkbox'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'

interface IHolidayFormProps {
  onSubmit: (value: IHolidayCreateForm) => void
  handleCancel: () => void
  loading?: boolean
}

const HolidayForm: FC<IHolidayFormProps> = ({ onSubmit, handleCancel, loading }) => {
  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik<IHolidayCreateForm>({
    initialValues: {
      date: null,
      description: '',
      isRepeated: false,
    },
    validationSchema: schemaHoliday,
    onSubmit: onSubmit,
  })
  return (
    <Box width={400}>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h2" color="textSecondary">
            Add Holiday
          </Typography>
        </Box>
        <Box mt="2rem">
          <DatePicker
            fullWidth
            label={<RequiredOption label="Date" />}
            name="date"
            value={values.date}
            error={Boolean(touched.date && errors.date) || !isValidDate(values.date)}
            helperText={(touched.date && errors.date) || (!isValidDate(values.date) && 'MM/DD/YYYY')}
            type="keyboard"
            onChange={(date) => setFieldValue('date', convertDateForPicker(date))}
          />
        </Box>
        <Box mt="2rem">
          <TextField
            variant="outlined"
            fullWidth
            label="Description"
            name="description"
            value={values.description}
            helperText={touched.description && errors.description}
            error={Boolean(touched.description && errors.description)}
            onChange={handleChange}
          />
        </Box>
        <Box mt="2rem">
          <FormControlLabel
            control={
              <Checkbox
                name="isRepeated"
                checked={values.isRepeated}
                color="primary"
                onChange={(e) => setFieldValue('isRepeated', e.target.checked)}
              />
            }
            label={<Typography color="textSecondary">Repeat</Typography>}
          />
        </Box>
        <Box mt="1rem" display="flex" alignItems="center" justifyContent="flex-end">
          <Button onClick={handleCancel} color="secondary" variant="contained">
            Cancel
          </Button>
          <Box ml="1rem">
            <Button loading={loading} type="submit" color="primary" variant="contained">
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default HolidayForm

import React, { FC } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import { ReactComponent as CircleCloseIcon } from 'assets/svg/CircleCloseIcon.svg'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete/Autocomplete'
import { Value } from '@material-ui/lab'
import { Box, Chip } from '@material-ui/core'
import { useSelectComponentStyle } from 'UI/Select'
import cn from 'classnames'
import { useMultipleAutocompleteStyles } from 'components/RolesAutoComplete'
import { DealPaidStatus } from 'typescript/interfaces/deals'

interface IAutoCompleteProps {
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void
  error?: boolean
  helperText?: string | false
  label?: string
  disabled?: boolean
  smoothLeft?: boolean
  smoothRight?: boolean
  multiple?: boolean
  className?: string
  paidStatuses: Array<DealPaidStatus>
}

const PaidStatusAutoComplete: FC<IAutoCompleteProps> = ({
  paidStatuses,
  onChange,
  helperText,
  error,
  label = 'Paid Status',
  disabled,
  smoothLeft,
  smoothRight,
  className,
  multiple,
}) => {
  const classes = useSelectComponentStyle()
  const mClasses = useMultipleAutocompleteStyles()

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      value={paidStatuses}
      options={['Current', 'FullyPaid', 'InCollection', 'Default', 'ProcessingRenewal']}
      classes={{ inputRoot: cn(mClasses.inputRoot, multiple), root: className }}
      renderTags={(tagValue, getTagProps) => {
        return (
          <Box>
            {tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))}
          </Box>
        )
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={helperText}
          error={error}
          label={label}
          className={cn({ [classes.disabledFormControlLeftBorder]: smoothLeft }, { [classes.disabledFormControlRightBorder]: smoothRight })}
          InputProps={{
            ...params.InputProps,
            ref: params.InputProps.ref,
            endAdornment: (
              <Box display="flex">
                {paidStatuses.length > 0 && (
                  <Box mr="0.25rem" display="flex" alignItems="center" justifyContent="center" style={{ cursor: 'pointer' }}>
                    <SvgIcon
                      fontSize="inherit"
                      viewBox="0 0 20 20"
                      component={CircleCloseIcon}
                      onClick={(e: any) => {
                        onChange!(e, undefined, 'clear')
                      }}
                    />
                  </Box>
                )}
                <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} />
              </Box>
            ),
          }}
          variant="outlined"
        />
      )}
    />
  )
}

export default PaidStatusAutoComplete

import React, { FC, useCallback, useState, useEffect } from 'react'
import { useNewClientStyle } from 'containers/NewClient/style'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import OwnerForm from 'components/ClientForms/OwnerForm'
import { useHistory, useParams, Redirect, generatePath } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { createOwner, getClientById } from 'services/clients'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { VIEW_CLIENT_URL, CLIENT_DOCUMENTS, EDIT_CLIENT_URL, EDIT_OWNER_URL, NEW_OWNER_URL } from 'constants/routes'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const OwnersContainer: FC = () => {
  const classes = useNewClientStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const { push, replace } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { id, type } = useParams<{ id: string; type: 'new' | 'create' }>()
  const { data, isLoading: isLoadingClient } = useQuery(['clientById', id], () => getClientById(id), {
    retry: false,
    cacheTime: 0,
  })
  const [countOw, setCountOw] = useState(0)
  const [createOwnerMut, { isLoading }] = useMutation(createOwner)

  useEffect(() => {
    if (data) setCountOw(data.data.owners.length + 1)
  }, [data])

  const handleSubmit = useCallback(
    async (values, variant: number) => {
      const copyValues = {
        ...values,
        ownershipDate: values.ownershipDate || null,
      }
      await createOwnerMut(
        {
          ...copyValues,
          clientId: id,
        },
        {
          onSuccess: (ownerData) => {
            if (variant === 1 || countOw === 3) {
              if (type === 'create')
                push(
                  `${generatePath(CLIENT_DOCUMENTS, {
                    id: id,
                  })}?backUrl=${generatePath(EDIT_OWNER_URL, {
                    id: id,
                    idOwner: ownerData.data.id,
                  })}?nextUrl=${generatePath(CLIENT_DOCUMENTS, {
                    id: id,
                  })}`,
                )
              else
                push(
                  generatePath(VIEW_CLIENT_URL, {
                    id: id,
                  }),
                )
            } else if (variant === 2) setCountOw((prevState) => prevState + 1)
            enqueueSnackbar('Owner successfully created')
          },
          onError: () => {
            enqueueSnackbar(SOMETHING_WENT_WRONG)
          },
        },
      )
    },
    [enqueueSnackbar, id],
  )

  const handleClickBack = useCallback(() => {
    if (type === 'create')
      replace(
        `${generatePath(EDIT_CLIENT_URL, {
          id: id,
        })}?nextUrl=${generatePath(NEW_OWNER_URL, {
          id: id.toString(),
          type: 'create',
        })}`,
      )
    else if (type === 'new')
      replace(
        generatePath(VIEW_CLIENT_URL, {
          id: id,
        }),
      )
  }, [push, id, type])

  if (countOw > 3) {
    enqueueSnackbar('Max 3 owners')
    return <Redirect to={VIEW_CLIENT_URL.replace(':id(\\d+)', id)} />
  }

  return isLoadingClient ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Box className={additionalStyle.wrapper}>
      <Box display="flex" alignItems="center">
        <Box ml="3rem" mr="1rem">
          <IconButton color="inherit" onClick={handleClickBack}>
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography variant="h1" color="textSecondary">
          Owner #{countOw}
        </Typography>
      </Box>
      <Box mt="2rem">
        <Paper elevation={0} className={classes.paper}>
          <OwnerForm
            handleClickBack={handleClickBack}
            countOwners={countOw}
            loading={isLoading}
            submit={{
              handler: handleSubmit,
              label: 'Save',
            }}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default OwnersContainer

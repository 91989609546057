import { AxiosResponse } from 'axios'
import {
  API_CREATE_INHOUSE_COMPANY,
  API_CREATE_OUTHOUSE_COMPANY,
  API_GET_COMPANIES,
  API_GET_COMPANY,
  API_UPDATE_INHOUSE_COMPANY,
  API_UPDATE_OUTHOUSE_COMPANY,
  API_GET_COMPANIES_LIGHT,
  API_UPLOAD_COMPANY_LOGO,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { storageInstance } from 'services/axios/storageInstance'
import {
  Company,
  CompanyForm,
  CompanyListItem,
  CompanySortFields,
  CompanyType,
  OuthouseCompany,
  OuthouseCompanyForm,
  ICompaniesParamsLight,
  ICompanyLogoUploadParams,
} from 'typescript/interfaces/companies'
import { IPagination } from 'typescript/interfaces/pagination'
import removeEmptyAttributes from 'utils/removeEmptyAttributes'
import { getDownloadUrl, uploadToS3 } from 'services/storage'

export const createInhouseCompany = (values: CompanyForm) =>
  privateInstance.post<CompanyForm, AxiosResponse<Company>>(API_CREATE_INHOUSE_COMPANY, values)

export const editInhouseCompany = (values: CompanyForm) =>
  privateInstance.put<CompanyForm, AxiosResponse<Company>>(API_UPDATE_INHOUSE_COMPANY, values)

export const getInhouseCompany = (id: string) => privateInstance.get<CompanyForm, AxiosResponse<Company>>(API_GET_COMPANY.replace(':id', id))

export const createOuthouseCompany = (values: OuthouseCompanyForm) =>
  privateInstance.post<CompanyForm, AxiosResponse<OuthouseCompany>>(API_CREATE_OUTHOUSE_COMPANY, values)

export const editOuthouseCompany = (values: OuthouseCompanyForm) =>
  privateInstance.put<CompanyForm, AxiosResponse<OuthouseCompany>>(API_UPDATE_OUTHOUSE_COMPANY, values)

export const getOuthouseCompany = (id: string) => privateInstance.get<CompanyForm, AxiosResponse<OuthouseCompany>>(API_GET_COMPANY.replace(':id', id))

export const getCompanies = (params: IPagination<CompanySortFields> & { CompanyType?: CompanyType }) => {
  const sParams = new URLSearchParams(removeEmptyAttributes(params) as Record<string, string>)
  return privateInstance.get<CompanyForm, AxiosResponse<CompanyListItem[]>>(`${API_GET_COMPANIES}?${sParams.toString()}`)
}

export const getCompaniesLight = (params: ICompaniesParamsLight) => {
  const sParams = new URLSearchParams(removeEmptyAttributes(params) as Record<string, string>)
  return privateInstance.get<CompanyForm, AxiosResponse<CompanyListItem[]>>(`${API_GET_COMPANIES_LIGHT}?${sParams.toString()}`)
}

export const getOuthouseCompanies = (params: IPagination<CompanySortFields> & { CompanyType?: CompanyType }) => {
  const sParams = new URLSearchParams(removeEmptyAttributes(params) as Record<string, string>)
  return privateInstance.get<CompanyForm, AxiosResponse<CompanyListItem[]>>(`${API_GET_COMPANIES}?${sParams.toString()}`)
}

export const attachLogoToCompany = (params: ICompanyLogoUploadParams) =>
  storageInstance.post<ICompanyLogoUploadParams, AxiosResponse<boolean>>(API_UPLOAD_COMPANY_LOGO, params)

export const uploadLogoCompany = async (values: { file: File | string | undefined; companyId: number | string }) => {
  if (typeof values.file === 'object') {
    const link = await getDownloadUrl({
      DocumentDestination: 'Logo',
      DocumentName: values.file.name,
      DestinationObjectId: values.companyId,
    })
    await uploadToS3(values.file, link.data)
    const connectedUploadedLink = attachLogoToCompany({
      companyId: values.companyId,
      fileUrl: link.data.split('?')[0],
    })
    return connectedUploadedLink
  } else if (typeof values.file === 'string') {
    const connectedUploadedLink = attachLogoToCompany({
      companyId: values.companyId,
      fileUrl: values.file,
    })
    return connectedUploadedLink
  }
  return Promise.resolve(true)
}

// export const getCompaniesLight = (params: IPagination & { CompanyType?: CompanyType }) => {
//   const sParams = new URLSearchParams(removeEmptyAttributes(params) as Record<string, string>)
//   return privateInstance.get<CompanyForm, AxiosResponse<CompanyLight[]>>(`${API_GET_COMPANIES_LIGHT}?${sParams.toString()}`)
// }

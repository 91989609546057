export enum EnumStatusWithdrawal {
  approved = 'Approved',
  pending = 'Pending',
  scheduled = 'Scheduled',
  processingBatch = 'ProcessingBatch',
  successful = 'Successful',
  rejected = 'Rejected',
  stopped = 'Stopped',
}
export type StatusWithdrawal =
  | EnumStatusWithdrawal.approved
  | EnumStatusWithdrawal.pending
  | EnumStatusWithdrawal.scheduled
  | EnumStatusWithdrawal.rejected

export enum SortFieldsTransactionsList {
  id = 'Id',
  date = 'Date',
  amount = 'Amount',
  dealNumber = 'DealNumber',
  dealName = 'DealName',
  type = 'Type',
  category = 'Category',
  comments = 'Comments',
  creationDate = 'CreationDate',
}

export enum SortFieldsWithdrawal {
  id = 'id',
  amount = 'amount',
  date = 'date',
  status = 'status',
  dealName = 'dealName',
  type = 'type',
}

export const DealTransactionTypes = {
  // PayoffCredit: 'PayOff Credit',
  PaymentRefund: 'Payment Refund',
  PaymentAch: 'Payment ACH',
  IncomingWire: 'Incoming Wire',
  LegalCost: 'Legal Cost',
  LegalCostVendor: 'Legal Cost Vendor',
}

export const TransactionsTypes = {
  // PageToPage: 'Page To Page',
  PageToDeal: 'Page To Deal',
  PageToBank: 'Page To Bank',
  BankToPage: 'Bank To Page',
  DealToPage: 'Deal To Page',
  PageToFun: 'Page To Fun',
  DealToFun: 'Deal To Fun',
}

export const CategoriesTransaction = {
  Transfer: 'Deposit',
  LegalFee: 'Legal Fee',
  Withdrawal: 'Withdrawal',
  SyndicationPayment: 'Syndication Payment',
  ClientProgramFee: 'Client Program Fee',
  RepProgramFee: 'Rep Program Fee',
  SyndicatorsProgramFee: 'Syndication Management Fee',
  UccFee: 'UCC Fee',
  WireFee: 'Wire Fee',
  ClientToRepCommission: 'CRM REP FEE',
  ClientToIsoCommission: 'Client To Iso Commission',
  ClientToSyndicatorFee: 'Syndication Commission Fee',
  PsfFee: 'Psf Fee',
  Commission: 'Commission',
  BounceFee: 'Bounce Fee',
  PayoffCredit: 'Payoff Credit',
  PaymentRefund: 'Payment Refund',
  PaymentAch: 'Payment ACH',
  DefaultFee: 'Default Fee',
  DiscountCredit: 'Discount Credit',
  CrmFee: 'CRM Fee',
  Wire: 'Wire',
  SyndicationCost: 'Syndication Cost',
  DailySplit: 'Daily Splits',
  Leverage: 'Leverage',
  SyndicationManagementFee: 'Syndication Management Fee',
}

export const CategoriesAccordingToType: {
  [K in keyof typeof TransactionsTypes]: {
    [T in keyof Partial<typeof CategoriesTransaction>]: typeof CategoriesTransaction[T]
  }
} = {
  // PageToPage: {
  //   Transfer: 'Transfer',
  //   LegalFee: 'Legal Fee',
  // },
  PageToDeal: {
    BounceFee: 'Bounce Fee',
    Commission: 'Commission',
    SyndicationManagementFee: 'Syndication Management Fee',
  },
  PageToBank: {
    Withdrawal: 'Withdrawal',
  },
  BankToPage: {
    Transfer: 'Deposit',
    Leverage: 'Leverage',
  },
  DealToPage: {
    DailySplit: 'Daily Splits',
    PsfFee: 'Psf Fee',
  },
  DealToFun: {
    WireFee: 'Wire Fee',
  },
  PageToFun: {
    RepProgramFee: 'Rep Program Fee',
  },
}

export enum SortFieldDealPayments {
  id = 'id',
  date = 'date',
  amount = 'amount',
  dealNumber = 'dealNumber',
  dealName = 'dealName',
  type = 'type',
  category = 'category',
  comments = 'comments',
  creationDate = 'creationDate',
}

export enum StatusPayment {
  scheduled = 'Scheduled',
  pending = 'Pending',
  processingBatch = 'Processing Batch',
  successful = 'Successful',
  rejected = 'Rejected',
  cancelled = 'Cancelled',
  stopped = 'Stopped',
}

import React, { FC } from 'react'
import { Box, MenuItem } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useFormik } from 'formik'
import { IExtendDealFormInitValues } from 'typescript/interfaces/deals'
import TextField from '@material-ui/core/TextField'
import Button from 'UI/Button/ButtonWithPreloader'
import SelectComponent from 'UI/Select'
import RequiredOption from 'UI/Select/RequiredOption'
import { ExtendDealType } from 'typescript/enums/deals'
import { schemaExtendDeal } from './schema'

interface IExtendDealFormProps {
  initValues?: IExtendDealFormInitValues
  submit: (values: IExtendDealFormInitValues) => void
  isLoading?: boolean
  cancel: () => void
}

const defaultInitValues: IExtendDealFormInitValues = {
  status: '',
  paybackAmount: undefined,
  remaining: undefined,
  net: undefined,
  type: '',
  amount: '',
}

export const ExtendDealForm: FC<IExtendDealFormProps> = ({ initValues = defaultInitValues, submit, isLoading, cancel }) => {
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik<IExtendDealFormInitValues>({
    initialValues: initValues,
    validationSchema: schemaExtendDeal,
    enableReinitialize: false,
    onSubmit: submit,
  })

  return (
    <Box width={240} maxWidth="100%">
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h2" color="textSecondary">
            Extend Deal
          </Typography>
        </Box>
        <Box mt="2rem">
          <SelectComponent
            value={values.type}
            name="type"
            label={<RequiredOption label="Extend Type" />}
            onChange={(e) => {
              if (e.target.value === ExtendDealType.reverse) {
                setFieldValue('term', 1)
              }
              handleChange(e)
            }}
            error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}
          >
            <MenuItem value="Flex">{ExtendDealType.flex}</MenuItem>
            <MenuItem value="Reverse">{ExtendDealType.reverse}</MenuItem>
          </SelectComponent>
        </Box>
        <Box mt="2rem">
          <TextField
            label={<RequiredOption label="Amount" />}
            variant="outlined"
            fullWidth
            name="amount"
            onChange={handleChange}
            value={values.amount}
            error={Boolean(touched.amount && errors.amount)}
            helperText={touched.amount && errors.amount}
          />
        </Box>
        <Box mt="2rem" display="flex" justifyContent="flex-end">
          <Button color="secondary" variant="contained" onClick={() => cancel()}>
            Cancel
          </Button>
          <Box ml="2rem">
            <Button loading={isLoading} type="submit" color="primary" variant="contained">
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

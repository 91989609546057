import React, { FC } from 'react'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useTableFooter } from 'components/Dashboard/style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { IDealsStats } from 'typescript/interfaces/deals'
import { converToTwoSignsAfterDot, convertToPriceFormat } from 'utils/formatters'
import cn from 'classnames'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

interface FooterTableLatestDealsProps {
  stats?: IDealsStats
}

const FooterTableLatestDeals: FC<FooterTableLatestDealsProps> = ({ stats }) => {
  const classes = useTableFooter()
  const additionalClasses = useAdditionalMaterialStyle()
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={3} className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
          {stats?.dealsCount} Records
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)} />
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              Total advanced
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {convertToPriceFormat(stats?.advanced)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              Total payback
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {convertToPriceFormat(stats?.payback)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              Total weekly
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {convertToPriceFormat(stats?.weekly)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              Total daily
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {convertToPriceFormat(stats?.daily)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              AVG Term/F
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {converToTwoSignsAfterDot(stats?.termAvg)}/{converToTwoSignsAfterDot(stats?.factorAvg)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              Total NET
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {convertToPriceFormat(stats?.net)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              Total collected
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {convertToPriceFormat(stats?.collected)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              AVG Paid&nbsp;Off
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {converToTwoSignsAfterDot(stats?.paidOffAvg)}%
            </Typography>
          </Box>
        </TableCell>
        <TableCell colSpan={4} className={cn(classes.cellStyle, classes.footerTableCell)}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textPrimary">
              Total Remaining
            </Typography>
            <Typography className={cn(additionalClasses.boldText)} color="textSecondary">
              {convertToPriceFormat(stats?.remaining)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)}>
          {stats && stats.pnl !== undefined && (
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" color="textPrimary">
                Total Pnl
              </Typography>
              <Typography noWrap className={cn(additionalClasses.boldText)} color="textSecondary">
                {stats.pnl >= 0 ? convertToPriceFormat(stats.pnl) : `(${convertToPriceFormat(Math.abs(stats.pnl))})`}
              </Typography>
            </Box>
          )}
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}

export default FooterTableLatestDeals

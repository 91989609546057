import React, { FC } from 'react'
import { useFormik } from 'formik'
import { setManagementFeeSchema } from 'components/SetManagementRepFee/schema'
import { ISetManagementFee } from 'typescript/interfaces/rep'
import { Box, Grid, TextField, Typography } from '@material-ui/core'
import Button from 'UI/Button/ButtonWithPreloader'
import RequiredOption from 'UI/Select/RequiredOption'

interface ISetManagementRepFeeFormProps {
  loading?: boolean
  onSubmit: (values: ISetManagementFee) => void
  handleCancel: () => void
  initValues: ISetManagementFee
}

const SetManagementRepFeeForm: FC<ISetManagementRepFeeFormProps> = ({ onSubmit, handleCancel, loading, initValues }) => {
  const { values, errors, handleChange, handleSubmit, touched } = useFormik<ISetManagementFee>({
    validationSchema: setManagementFeeSchema,
    initialValues: initValues,
    onSubmit: onSubmit,
  })
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h2" color="textSecondary">
        Set Management Fee
      </Typography>
      <Box mt="2rem">
        <TextField
          fullWidth
          variant="outlined"
          label={<RequiredOption label="Management Fee %" />}
          name="feeValue"
          value={values.feeValue}
          onChange={handleChange}
          error={Boolean(touched.feeValue && errors.feeValue)}
          helperText={touched.feeValue && errors.feeValue}
        />
      </Box>
      <Box mt={10}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button onClick={handleCancel} fullWidth color="secondary" variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button loading={loading} fullWidth color="primary" variant="contained" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default SetManagementRepFeeForm

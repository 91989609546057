import makeStyles from '@material-ui/core/styles/makeStyles'

export const useLatestDealsStyle = makeStyles((theme) => ({
  wrapper: {
    marginLeft: '-3.25rem',
    marginRight: '-3.25rem',
    marginTop: '1.5rem',
    '@media screen and (min-width: 1280px) and (max-width: 1600px)': {
      marginLeft: '-1.5rem',
      marginRight: '-1.5rem',
    },
  },
  navButton: {
    '& svg': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  roundBorder: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '6px',
  },
  autocomplete: {
    marginTop: -4,
    width: '100%',
    maxWidth: '180px',
  },
  paidStatusAutoComplete: {
    marginTop: -4,
    width: '100%',
  },
  table: {
    height: 'calc(100vh - 16rem)',
    overflow: 'auto',
  },
}))

import { Box, Button, CircularProgress, IconButton, makeStyles, Popover, SvgIcon, Typography } from '@material-ui/core'
import NotificationsChatListItem from 'containers/NotificationReceivedContainer/NotificationsChatListItem'
import React, { useEffect } from 'react'
import { INotification } from 'typescript/interfaces/notifications'
import { ReactComponent as NotificationIcon } from 'assets/svg/NotificationIcon.svg'
import { usePaginationList } from 'hooks/usePaginationList'
import { getAllReceivedNotifications, getNewNotificationsCount, markAllAsRead } from 'services/notifications'
import { useMutation, useQuery } from 'react-query'
import { ReactComponent as ExpandIcon } from 'assets/svg/ExpandIcon.svg'
import { ReactComponent as NewNotificationIcon } from 'assets/svg/NewNotificationIcon.svg'
import { NOTIFICATIONS_NEW_URL, NOTIFICATIONS_RECEIVED_URL } from 'constants/routes'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { usePermission } from 'hooks/usePermission'
import { NOTIFICATIONS_CREATE_PERM } from 'constants/permissions'

const useStyles = makeStyles((theme) => ({
  popover: {
    overflow: 'inherit',
    borderRadius: '0.5rem',
    '&:after': {
      width: 0,
      height: 0,
      borderLeft: '0.8rem solid transparent',
      borderRight: '0.8rem solid transparent',
      borderBottom: `0.8rem solid ${theme.palette.common.white}`,
      top: '-0.7rem',
      right: '0.65rem',
      display: 'table',
      content: '""',
      position: 'absolute',
    },
  },
  button: {
    paddingBottom: '1.5rem',
    paddingTop: '1.5rem',
  },
}))

const NotificationPopover = () => {
  const { hasPermission } = usePermission()
  const s = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { push } = useHistory()
  const snack = useSnackbar()
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const { data: countData, refetch } = useQuery('notifs-count', getNewNotificationsCount)

  const { pagination, setPagination } = usePaginationList<INotification>({
    initState: {
      data: [],
      search: '',
      order: 'Date',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getNotifications, { isLoading }] = useMutation(getAllReceivedNotifications, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: data.data,
      }))
    },
  })

  const [markAsRead] = useMutation(markAllAsRead, {
    onSuccess: () => {
      snack.enqueueSnackbar(<Typography>Successfully marked all messages as read</Typography>)
      refetch()
      getNotifications({
        PageSize: 8,
        PageIndex: pagination.page,
        Query: pagination.search,
        SortField: pagination.order,
        SortOrder: pagination.sortOrder,
      })
    },
  })

  useEffect(() => {
    if (open) {
      getNotifications({
        PageSize: 8,
        PageIndex: pagination.page,
        Query: pagination.search,
        SortField: pagination.order,
        SortOrder: pagination.sortOrder,
      })
    }
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder, open])

  const handleClickOnLink = (str: string) => {
    push(NOTIFICATIONS_RECEIVED_URL.replace(':id?', str))
    handleClose()
  }

  return (
    <>
      <Box py="1.5rem" onClick={handleClick}>
        <IconButton>{countData && countData.data > 0 ? <NewNotificationIcon /> : <SvgIcon component={NotificationIcon} />}</IconButton>
      </Box>

      <Popover
        classes={{ paper: s.popover }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isLoading && (
          <Box width="100%" height="100px" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
        {!isLoading && pagination.data.length === 0 ? (
          <Box p="3rem" textAlign="center">
            <Typography variant="h3">There are no notifications</Typography>
          </Box>
        ) : (
          <>
            <Box display="flex" p="1.5rem" justifyContent="space-between">
              <IconButton size="small">
                <SvgIcon fontSize="small" viewBox="0 0 16 16" component={ExpandIcon} />
              </IconButton>
              <Button onClick={() => markAsRead()} disabled={countData && countData.data === 0} color="secondary" size="medium">
                Mark all as read
              </Button>
            </Box>
            <Box maxHeight="550px" overflow="auto">
              {pagination.data.map((notif) => (
                <NotificationsChatListItem onClick={() => handleClickOnLink(`${notif.id}`)} selected={false} notification={notif} key={notif.id} />
              ))}
            </Box>
          </>
        )}
        <Box p="2rem" pt="1rem">
          {hasPermission(NOTIFICATIONS_CREATE_PERM) && (
            <Box mb="0.75rem">
              <Button
                onClick={() => {
                  push(NOTIFICATIONS_NEW_URL)
                  handleClose()
                }}
                color="primary"
                variant="contained"
                fullWidth
              >
                Create Notification
              </Button>
            </Box>
          )}
          <Button onClick={() => handleClickOnLink('')} fullWidth variant="contained">
            View All
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default NotificationPopover

import React, { FC } from 'react'
import { SnackbarKey, useSnackbar } from 'notistack'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as CloseIcon } from 'assets/svg/CloseIcon.svg'
import cn from 'classnames'

const useCustomSnackBarContent = makeStyles((theme) => ({
  closeButton: {
    padding: 0,
    '& svg': {
      width: 24,
      height: 24,
      '& path': {
        fill: theme.palette.common.white,
      },
    },
  },
}))

export const CloseAction: FC<SnackbarKey> = (key) => {
  const { closeSnackbar } = useSnackbar()
  const classes = useCustomSnackBarContent()
  return (
    <Box ml={1}>
      <IconButton onClick={() => closeSnackbar(key)} className={cn(classes.closeButton)}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

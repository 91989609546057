import React, { FC, useCallback, useContext, useMemo } from 'react'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as UploadIcon } from 'assets/svg/UploadIcon.svg'
import GeneralInfo from 'components/ViewClient/GeneralInfo'
import DealsTable from 'components/ViewClient/DealsTable'
import OwnerInformation from 'components/ViewClient/OwnerInformation'
import Documents from 'components/ViewClient/Documents'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getClientById } from 'services/clients'
import { EDIT_CLIENT_URL, NEW_OWNER_URL, CLIENT_DOCUMENTS, NEW_DEAL_URL } from 'constants/routes'
import { useSnackbar } from 'notistack'
import { CREATE_CLIENT_DEAL, CREATE_INHOUSE_OUTHOUSE_PERM, CLIENT_CREATE_UPDATE_VIEW } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'
import ClientBankAccounts from 'components/ViewClient/BankAccounts'
import { UserContext } from 'contexts/userContext'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { AxiosError } from 'axios'
import { SOMETHING_WENT_WRONG } from 'constants/errors'

const ViewClientContainer: FC = () => {
  const { state } = useContext(UserContext)
  const { hasPermission } = usePermission()
  const { id } = useParams<{ id: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const additionalStyle = useAdditionalMaterialStyle()
  const { push, goBack } = useHistory()
  const { data, isLoading } = useQuery(['clientById', id], () => getClientById(id), {
    retry: 0,
    cacheTime: 0,
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'CLIENT_NOT_FOUND' && state.roles.isRep) {
        enqueueSnackbar('This client is not on your list of clients')
        goBack()
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const readOnlyMode = !hasPermission(CLIENT_CREATE_UPDATE_VIEW)

  const handleGoToOwner = useCallback(() => {
    if (data && data.data.owners.length < 3)
      push(
        generatePath(NEW_OWNER_URL, {
          id: id,
          type: 'new',
        }),
      )
    else enqueueSnackbar('Max 3 owners')
  }, [data, id])

  const urlNewDeal = useMemo(() => {
    if (hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM))
      return generatePath(NEW_DEAL_URL, {
        view: 'new',
        type: 'inhouse',
        step: '1',
      })
    else if (hasPermission(CREATE_CLIENT_DEAL))
      return generatePath(NEW_DEAL_URL, {
        view: 'new',
        type: 'client-deal',
        step: '1',
      })
    return ''
  }, [hasPermission])

  return (
    <Box>
      <Box color="common.white" display="flex" alignItems="center">
        {!state.roles.isClient && (
          <Box mr="1.5rem">
            <IconButton color="inherit" onClick={goBack}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
        )}
        <Box>
          <Typography variant="h1" color="textSecondary">
            Client #{data?.data.id}
          </Typography>
        </Box>
      </Box>
      {!readOnlyMode && (
        <Box mt="2rem" display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box mr="1rem">
              <Button
                onClick={() =>
                  push({
                    pathname: urlNewDeal,
                    search: `?clientId=${data?.data.id}`,
                  })
                }
                color="primary"
                variant="contained"
                startIcon={<PlusIcon fill="white" />}
              >
                Add Deal
              </Button>
            </Box>
            <Box mr="1rem">
              <Button color="inherit" variant="contained" startIcon={<PlusIcon />} onClick={handleGoToOwner}>
                Add Owner
              </Button>
            </Box>
            <Box mr="1rem">
              <Button
                color="inherit"
                variant="contained"
                startIcon={<UploadIcon />}
                onClick={() => {
                  push(
                    generatePath(CLIENT_DOCUMENTS, {
                      id: id,
                    }),
                  )
                }}
              >
                Upload Documents
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Button variant="contained" color="inherit" startIcon={<EditIcon />} onClick={() => push(EDIT_CLIENT_URL.replace(':id(\\d+)', id))}>
              Edit
            </Button>
          </Box>
        </Box>
      )}
      <Box className={additionalStyle.wrapper}>
        <Box mt="1rem">
          <GeneralInfo data={data?.data} loading={isLoading} />
        </Box>
        <Box mt="1rem">
          <ClientBankAccounts readOnly={readOnlyMode} />
        </Box>
        {data && (
          <Box mt="1rem">
            <DealsTable idClient={data.data.id} deals={data.data.deals} />
          </Box>
        )}
        {data && (
          <Box mt="1rem">
            <OwnerInformation idClient={id} handleGoToOwner={handleGoToOwner} owners={data.data.owners} readOnly={readOnlyMode} />
          </Box>
        )}
        {data && (
          <Box mt="1rem" mb="2rem">
            <Documents documents={data.data.documents} idClient={id} readOnly={readOnlyMode} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ViewClientContainer

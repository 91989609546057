import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useQuery } from 'react-query'
import { getDealList, getDealStats } from 'services/deals'
import { DealsStatus, SortFieldsDeal } from 'typescript/enums/deals'
import { useFullDeals } from 'hooks/useFullDeals'
import { IDealsListItem } from 'typescript/interfaces/deals'
import { convertToPriceFormat } from 'utils/formatters'

const TableTopByAdvanced = TableComponent as TableComponentType<IDealsListItem>

const TopByAdvancedAmount: FC = () => {
  const { data, isLoading } = useQuery(
    ['top-by-advanced'],
    () =>
      getDealList({
        SortField: SortFieldsDeal.advanced,
        SortOrder: 'DESC',
        PageSize: 5,
        PageIndex: 0,
        Statuses: [DealsStatus.approved],
      }),
    // eslint-disable-next-line function-paren-newline
  )
  const { data: dealStats } = useQuery('deal-stats', () => getDealStats({ Statuses: [DealsStatus.approved] }))

  const { columnsForTopByAdvanced } = useFullDeals()

  return (
    <Paper elevation={0}>
      <Box py={4} px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">Top by Advanced Amount</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h2" color="textSecondary">
            {convertToPriceFormat(dealStats?.data.advanced)}
          </Typography>
        </Box>
        <Box mx={-4}>
          <TableTopByAdvanced loading={isLoading} columns={columnsForTopByAdvanced} data={data?.data || []} />
        </Box>
      </Box>
    </Paper>
  )
}

export default TopByAdvancedAmount

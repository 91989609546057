import { COMPANIES_URL, COMPANY_EDIT_URL, COMPANY_NEW_URL, COMPANY_URL } from 'constants/routes'
import CompaniesListPage from 'pages/CompaniesList'
import CompanyPage from 'pages/Company'
import NewCompanyPage from 'pages/NewCompany'
import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import { usePermission } from 'hooks/usePermission'
import { CREATE_INHOUSE_COMPANY_PERM } from 'constants/permissions'

const CompaniesContainer: FC = () => {
  const { hasPermission } = usePermission()
  return (
    <Switch>
      <Route path={COMPANIES_URL} exact component={CompaniesListPage} />
      {hasPermission(CREATE_INHOUSE_COMPANY_PERM) && <Route path={[COMPANY_NEW_URL, COMPANY_EDIT_URL]} exact component={NewCompanyPage} />}
      <Route path={COMPANY_URL} exact component={CompanyPage} />
    </Switch>
  )
}

export default CompaniesContainer

import React, { FC, useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useTableComponentStyle } from 'UI/Table/style'
import { ILenderEntity } from 'typescript/interfaces/deals'
import { convertToPriceFormat, formatBigNumber } from 'utils/formatters'

const TableSyndicatorsLight = TableComponent as TableComponentType<ILenderEntity>

interface ISyndicatorsTableProps {
  data: ILenderEntity[]
}

const SyndicatorsLightTable: FC<ISyndicatorsTableProps> = ({ data }) => {
  const classes = useTableComponentStyle()
  const columnsRef = useRef<IColumn<ILenderEntity>[]>([
    {
      label: '#',
      field: 'id',
      bodyCellClassName: classes.accentCell,
    },
    {
      label: 'Name',
      field: 'name',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => rowData.businessName,
    },
    {
      label: 'Main Lender',
      field: 'isMain',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => (rowData.isLead ? 'Yes' : 'No'),
    },
    {
      label: 'Amt. Participated',
      field: 'amount',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertToPriceFormat(rowData.lendingAmount),
    },
    {
      label: 'Percentage',
      field: 'percentage',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => `${rowData.lendingPercentage} %`,
    },
    {
      label: 'RTR',
      field: 'rtr',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => formatBigNumber(rowData.paybackAmount),
    },
  ])
  return <TableSyndicatorsLight columns={columnsRef.current} data={data} />
}

export default SyndicatorsLightTable

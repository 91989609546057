import React, { FC, useMemo } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import { ReactComponent as CircleCloseIcon } from 'assets/svg/CircleCloseIcon.svg'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete/Autocomplete'
import { useQuery } from 'react-query'
import { Value } from '@material-ui/lab'
import { getVendorLightList } from 'services/vendor'
import { Box, Chip } from '@material-ui/core'
import { IVendorListLightItem } from 'typescript/interfaces/vendor'
import cn from 'classnames'
import { useMultipleAutocompleteStyles } from 'components/RolesAutoComplete'

interface IAutoCompleteProps {
  vendorId: any
  dealId?: string
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void
  error?: boolean
  helperText?: string | false
  label?: string
  multiple?: boolean
  disabled?: boolean
}

const VendorAutoComplete: FC<IAutoCompleteProps> = ({ vendorId, onChange, helperText, error, label = 'Select Vendor', multiple, disabled }) => {
  const mClasses = useMultipleAutocompleteStyles()
  const { data, isLoading } = useQuery(['vendorsLight'], () => getVendorLightList({ PageSize: 4000 }), {
    retry: false,
    cacheTime: 0,
  })
  const arrayVendors = useMemo(() => {
    return data ? data.data : []
  }, [data])

  const selectedOptionVendor = useMemo(() => {
    if (data) {
      const findVendor = data.data.find((c) => c.id === vendorId) || null
      return findVendor
    }
    return null
  }, [data, vendorId])

  const arraySelectedOptions = useMemo(() => {
    if (Array.isArray(vendorId) && data) {
      return arrayVendors.filter((c) => vendorId.includes(c.id))
    }
    return []
  }, [arrayVendors, vendorId])

  return (
    <Autocomplete
      disabled={Boolean(disabled)}
      multiple={Boolean(multiple)}
      value={multiple ? arraySelectedOptions : selectedOptionVendor}
      loading={isLoading}
      options={arrayVendors}
      getOptionLabel={(option: IVendorListLightItem) => {
        return option.name
      }}
      getOptionSelected={(option: IVendorListLightItem, value) => {
        if (value && value.id) return option.id === value.id
        return false
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option: IVendorListLightItem, index) => <Chip label={option.name} {...getTagProps({ index })} />)
      }}
      classes={{ inputRoot: cn(mClasses.inputRoot, multiple) }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={helperText}
          error={error}
          label={label}
          InputProps={{
            // autoComplete: "off",
            ...params.InputProps,
            ref: params.InputProps.ref,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                {selectedOptionVendor && (
                  <Box mr="0.25rem" display="flex" alignItems="center" justifyContent="center" style={{ cursor: 'pointer' }}>
                    <SvgIcon
                      fontSize="inherit"
                      viewBox="0 0 20 20"
                      component={CircleCloseIcon}
                      onClick={(e: any) => {
                        onChange!(e, undefined, 'clear')
                      }}
                    />
                  </Box>
                )}
                <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} />
              </>
            ),
          }}
          variant="outlined"
        />
      )}
    />
  )
}

export default VendorAutoComplete

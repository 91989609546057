import * as yup from 'yup'
import {
  INVALID_FEDERAL_TAX_ID,
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
  INVALID_EMAIL,
  ZIP_CODE_FORMAT_ERROR,
  INVALID_PRICE_FORMAT,
  NUMBERS_ONLY,
  BANK_ROUTING_MUST_BE_NINE_SYMBOLS,
  INVALID_SYMBOLS,
  ONLY_DIGITS,
} from 'constants/errors'
import { integerReg, priceReg, zipCodeReg } from 'constants/regExp'
import { bankAccountTypes } from 'constants/bankAccountType'

export const schemaClientInfo = yup.object().shape({
  clientType: yup.string().required(REQUIRED_FIELD),
  businessName: yup.string().required(REQUIRED_FIELD),
  doingBusinessAs: yup.string().required(REQUIRED_FIELD),
  businessStartDate: yup.string().nullable().required(REQUIRED_FIELD),
  entityType: yup.string().required(REQUIRED_FIELD),
  isMailingAddressDifferent: yup.boolean(),
  grossAnnualIncome: yup.string().matches(integerReg, ONLY_DIGITS),
  federalTaxId: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val)),
  expectedGrade: yup.mixed(),
  phone: yup
    .string()
    .nullable()
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => (val ? val.replace(/\s/g, '').indexOf('_') < 0 : true)),
  homePhone: yup
    .string()
    .nullable()
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => (val ? val.replace(/\s/g, '').indexOf('_') < 0 : true)),
  businessPhone: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
  email: yup.string().required(REQUIRED_FIELD).email(INVALID_EMAIL),
  webSite: yup.string(),
  industryId: yup.string().required(REQUIRED_FIELD),
  phisicalStateId: yup.string().required(REQUIRED_FIELD),
  city: yup.string().required(REQUIRED_FIELD),
  street: yup.string().required(REQUIRED_FIELD),
  zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  mailingStateId: yup.mixed().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().nullable().required(REQUIRED_FIELD),
    otherwise: yup.mixed(),
  }),
  mailingCity: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
    otherwise: yup.string(),
  }),
  mailingStreet: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required().required(REQUIRED_FIELD),
    otherwise: yup.string(),
  }),
  mailingZipCode: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
    otherwise: yup.string(),
  }),
  landlordOrMortgageCo: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
    otherwise: yup.string(),
  }),
  landlordContactName: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
    otherwise: yup.string(),
  }),
  landlordPhone: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup
      .string()
      .required(REQUIRED_FIELD)
      .test('isFullLandLordPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
    otherwise: yup.string(),
  }),
  landlordFax: yup.number().when('isMailingAddressDifferent', {
    is: true,
    then: yup.number().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY),
    otherwise: yup.number(),
  }),
  rentOrMortgagePayment: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD).matches(priceReg, INVALID_PRICE_FORMAT),
    otherwise: yup.string(),
  }),
  miCampId: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^\d{16}$/, 'Only 16 digits'),
    otherwise: yup.string(),
  }),
  leaseExpiration: yup.mixed().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().nullable().required(REQUIRED_FIELD),
    otherwise: yup.mixed(),
  }),
  bankAccount: yup.object().shape({
    bankRouting: yup
      .string()
      .min(9, BANK_ROUTING_MUST_BE_NINE_SYMBOLS)
      .max(9, BANK_ROUTING_MUST_BE_NINE_SYMBOLS)
      .test('integer', INVALID_SYMBOLS, function () {
        return Number.isInteger(+this.parent.bankRouting)
      })
      .required(REQUIRED_FIELD),
    bankName: yup.string().required(REQUIRED_FIELD),
    bankAccount: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(integerReg, 'Bank Account must be numeric')
      .test('isCorrectLength', 'Must be at least 5 characters and no more than 15', function (val) {
        return Boolean(val && val.length >= 5 && val.length <= 15)
      }),
    bankAccountType: yup.string().oneOf(bankAccountTypes).required(REQUIRED_FIELD),
  }),
})

export const schemaEditClient = yup.object().shape({
  additionalGuarantors: yup.array().of(
    yup.object().shape({
      ein: yup
        .string()
        .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val)),
    }),
  ),
  clientType: yup.string().required(REQUIRED_FIELD),
  businessName: yup.string().required(REQUIRED_FIELD),
  doingBusinessAs: yup.string().required(REQUIRED_FIELD),
  businessStartDate: yup.string().nullable().required(REQUIRED_FIELD),
  grossAnnualIncome: yup.string().matches(priceReg, INVALID_PRICE_FORMAT),
  entityType: yup.string().required(REQUIRED_FIELD),
  isMailingAddressDifferent: yup.boolean(),
  federalTaxId: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val)),
  expectedGrade: yup.mixed(),
  phone: yup
    .string()
    .nullable()
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => (val ? val.replace(/\s/g, '').indexOf('_') < 0 : true)),
  homePhone: yup
    .string()
    .nullable()
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => (val ? val.replace(/\s/g, '').indexOf('_') < 0 : true)),
  businessPhone: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
  fax: yup.number().typeError(NUMBERS_ONLY),
  email: yup.string().required(REQUIRED_FIELD).email(INVALID_EMAIL),
  webSite: yup.string(),
  industryId: yup.string().required(REQUIRED_FIELD),
  phisicalStateId: yup.string().required(REQUIRED_FIELD),
  city: yup.string().required(REQUIRED_FIELD),
  street: yup.string().required(REQUIRED_FIELD),
  zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  mailingStateId: yup.mixed().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().nullable().required(REQUIRED_FIELD),
    otherwise: yup.mixed(),
  }),
  mailingCity: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
    otherwise: yup.string(),
  }),
  mailingStreet: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required().required(REQUIRED_FIELD),
    otherwise: yup.string(),
  }),
  mailingZipCode: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
    otherwise: yup.string(),
  }),
  landlordOrMortgageCo: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
    otherwise: yup.string(),
  }),
  landlordContactName: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
    otherwise: yup.string(),
  }),
  landlordPhone: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup
      .string()
      .required(REQUIRED_FIELD)
      .test('isFullLandLordPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
    otherwise: yup.string(),
  }),
  landlordFax: yup.number().when('isMailingAddressDifferent', {
    is: true,
    then: yup.number().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY),
    otherwise: yup.number(),
  }),
  rentOrMortgagePayment: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD).matches(priceReg, INVALID_PRICE_FORMAT),
    otherwise: yup.string(),
  }),
  miCampId: yup.string().when('isMailingAddressDifferent', {
    is: true,
    then: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^\d{16}$/, 'Only 16 digits'),
    otherwise: yup.string(),
  }),
  leaseExpiration: yup.mixed().when('isMailingAddressDifferent', {
    is: true,
    then: yup.string().nullable().required(REQUIRED_FIELD),
    otherwise: yup.mixed(),
  }),
})

export const schemaAddGuarantor = yup.object().shape({
  ein: yup.string().test('isFullFederalTaxId', INVALID_FEDERAL_TAX_ID, (val) => Boolean((val && val.replace(/\s/g, '').indexOf('_') < 0) || !val)),
})

import React, { useEffect, useRef } from 'react'

import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { AxiosError } from 'axios'
import { FailResponse } from 'typescript/interfaces/axios'
import { API_RESET_PASSWORD } from 'constants/apiUrl'
import { publicInstance } from 'services/axios/publicInstance'
import { useMutation } from 'react-query'
import { SIGN_IN_URL } from 'constants/routes'
import { ACCOUNT_NOT_ACTIVATED, INVALID_VALIDATION_TOKEN } from 'constants/errors'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import { schemaCreateNewPassword } from './schema'

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 24,
  },
  input2: {
    marginBottom: 16,
  },
  button: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 48,
    color: '#4C5878',
  },
  smallText: {
    fontSize: 19,
  },
  link: {
    color: '#249AF3',
    textDecoration: 'none',
    fontSize: 17,
  },
}))

const VALIDATION_TOKEN = 'validationToken'

interface Values {
  newPassword: string
  confirmPassword: string
}

const CreateNewPasswordForm = () => {
  const s = useStyles()
  const validationToken = useRef('')
  const location = useLocation()
  const { push } = useHistory()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const vToken = searchParams.get(VALIDATION_TOKEN) || ''
    validationToken.current = vToken
  }, [])

  const restorePassword = (values: Values) => {
    return publicInstance.post(API_RESET_PASSWORD, { ...values, resetPasswordToken: validationToken.current }, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig)
  }

  const [mutate, { isLoading }] = useMutation(restorePassword, {
    onSuccess: () => {
      push(SIGN_IN_URL)
    },
    onError: (err: AxiosError<FailResponse>) => {
      if (err.response?.data.description === INVALID_VALIDATION_TOKEN) {
        setFieldError('newPassword', 'Your validation token is expired')
      } else if (err.response?.data.description === ACCOUNT_NOT_ACTIVATED) {
        setFieldError('newPassword', 'This user is not activated. Please activate the user using the link from the email')
      } else {
        setFieldError('newPassword', err.response?.data.description)
      }

      // if(err.response?.data.detail === )
    },
  })

  const { values, errors, touched, handleChange, handleSubmit, setFieldError } = useFormik<Values>({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: schemaCreateNewPassword,
    onSubmit: (formValues) => mutate(formValues),
  })
  return (
    <Box
      width="520px"
      maxWidth="80%"
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Typography className={s.text} align="center">
        Create new password
      </Typography>
      <Typography className={s.smallText} align="center">
        Your new password must be different from previously used passwords
      </Typography>
      <Box mb="2rem" mt="0.5rem">
        <Typography className={s.smallText} align="center">
          Back to{' '}
          <Link className={s.link} to={SIGN_IN_URL}>
            Sign In
          </Link>
        </Typography>
      </Box>
      <TextField
        size="small"
        className={s.input}
        variant="outlined"
        fullWidth
        type="password"
        label="Password"
        name="newPassword"
        onChange={handleChange}
        value={values.newPassword}
        error={!!errors.newPassword && !!touched.newPassword}
        helperText={!!touched.newPassword && errors.newPassword}
      />
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        type="password"
        className={s.input2}
        label="Confirm password"
        name="confirmPassword"
        error={!!errors.confirmPassword && !!touched.confirmPassword}
        onChange={handleChange}
        value={values.confirmPassword}
        helperText={!!touched.confirmPassword && errors.confirmPassword}
      />

      <Button disabled={isLoading} type="submit" className={s.button} color="primary" variant="contained" fullWidth>
        Confirm
      </Button>
    </Box>
  )
}

export default CreateNewPasswordForm

import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'

export interface Step {
  title: string
  [key: string]: any
}

interface Props {
  steps: Step[]
  current: number
  available?: number[]
  onChange?: (step: Step, index: number) => void
  maxSteps?: number
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
  },
  container: {
    justifyContent: 'space-between',
    display: 'flex',
    cursor: 'pointer',
    marginLeft: '3rem',
    marginRight: '3rem',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  step: {
    width: (props: any) => `calc(${100 / props.quantity}% - 2rem)`,
    marginRight: '2rem',
    '&:last-child': {
      marginRight: 0,
    },
  },
  stepDivider: {
    width: '100%',
    height: '0.1875rem',
    background: theme.palette.text.primary,
    marginLeft: '0.75rem',
  },
  stepCurrent: {
    background: theme.palette.secondary.main,
  },
  textCurrent: {
    color: theme.palette.secondary.main,
  },
  textSmall: {
    marginBottom: -1,
  },
}))

const CustomStepper = ({ steps, current, available, onChange, maxSteps }: Props) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const uploadDocs = params.get('uploadDocs')
  const s = useStyles({ quantity: maxSteps ?? steps.length })

  const isNotIncluded = useCallback(
    (i) => {
      return available && !available.includes(i)
    },
    [available, current],
  )

  const handleChange = (step: Step, i: number) => {
    if (!isNotIncluded(i) && !uploadDocs) {
      onChange?.(step, i)
    }
  }
  return (
    <Box className={s.container}>
      {steps.map((step, i) => (
        <Box onClick={() => handleChange(step, i)} className={cn(s.step, (isNotIncluded(i) || uploadDocs) && s.disabled)} key={step.title}>
          <Typography className={cn(s.title, current >= i && s.textCurrent)}>{step.title}</Typography>
          <Box display="flex" alignItems="center">
            <Typography className={cn(s.textSmall, current >= i && s.textCurrent)}>{i + 1}</Typography>
            <Box className={cn(s.stepDivider, current >= i && s.stepCurrent)} />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default CustomStepper

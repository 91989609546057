import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'

export const useDrawerMenuStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '6rem',
    overflowX: 'hidden',
  },
  activeLink: {
    color: theme.palette.primary.dark,
    '& svg': {
      '& path': {
        fill: theme.palette.primary.dark,
      },
    },
  },
  link: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.8rem 1.5rem',

    '&:hover': {
      // backgroundColor: theme.palette.divider,
      '& svg': {
        '& path': {
          fill: theme.palette.primary.dark,
        },
      },
    },
  },
  btnLabel: {
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '0.85rem',
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    textAlign: 'center',
    '& svg': {
      marginBottom: '0.25rem',
    },
  },
  logOutLink: {
    padding: 0,
    height: '4rem',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: '0',
    },
  },
  popover: {
    // minWidth: '17.5rem',
    // padding: '0',
    overflow: 'inherit',
    // position: 'relative',
    '&:after': {
      width: 0,
      height: 0,
      borderTop: '0.8rem solid transparent',
      borderBottom: '0.8rem solid transparent',
      borderRight: `0.8rem solid ${theme.palette.common.white}`,
      top: '1.8rem',
      left: '-0.8rem',
      display: 'table',
      content: '""',
      position: 'absolute',
    },
  },
  menuItemChild: {
    fontSize: '1rem',
    fontWeight: 500,
    padding: '0.75rem',

    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

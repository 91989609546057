import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import { Box, Divider, Paper, useMediaQuery } from '@material-ui/core'
import DailyChart from 'components/Dashboard/DailyChart'
import TermFactorChart from 'components/Dashboard/TermFactor Avg'
import PaidOffAvg from 'components/Dashboard/PaidOffAvg'
import PNLChart from 'components/Dashboard/PNL'
import AmountBlock from 'components/Dashboard/AmountBlock'
import useTheme from '@material-ui/core/styles/useTheme'
import LatestDealsSecondView from 'components/Dashboard/LatestDealsSecondView'
import { generatePath, useHistory } from 'react-router-dom'
import { DASHBOARD_VIEW_FIRST_WITH_CHART } from 'constants/routes'
import RTRSummary from 'components/Dashboard/RTRSummary'
import { getDealStats, getDealStatsForChart } from 'services/deals'
import { useQuery } from 'react-query'
import { endOfDay, startOfDay, subYears } from 'date-fns'
import { convertToPriceFormat } from 'utils/formatters'
import { DealsStatus } from 'typescript/enums/deals'

const SecondDashboardView: FC = () => {
  const theme = useTheme()
  const { push } = useHistory()
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))

  const { data: dealStats } = useQuery('deal-stats', () => {
    return getDealStats({ Statuses: [DealsStatus.approved] })
  })

  const { data: chartsData } = useQuery(
    ['charts-data'],
    () =>
      getDealStatsForChart({
        From: subYears(startOfDay(new Date()), 1).toISOString(),
        To: endOfDay(new Date()).toISOString(),
      }),
    // eslint-disable-next-line function-paren-newline
  )

  return (
    <Box>
      <Grid container>
        <Grid item container xs={6}>
          <Grid item lg={3} xs={6}>
            <Box mb={2} pr={2} height="100%">
              <DailyChart
                header={dealStats?.data}
                stats={chartsData?.data}
                additionalRem={isXl ? 6 : 3}
                onCollapse={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { chart: 'daily', from: '2' }))}
              />
            </Box>
          </Grid>
          <Grid item lg={3} xs={6}>
            <Box mb={2} pr={2} height="100%">
              <TermFactorChart
                header={dealStats?.data}
                stats={chartsData?.data}
                additionalRem={isXl ? 6 : 3}
                onCollapse={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { chart: 'termFactor', from: '2' }))}
              />
            </Box>
          </Grid>
          <Grid item lg={3} xs={6}>
            <Box pr={2} height="100%">
              <PaidOffAvg
                header={dealStats?.data}
                stats={chartsData?.data}
                additionalRem={isXl ? 6 : 3}
                onCollapse={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { chart: 'paidOffAvg', from: '2' }))}
              />
            </Box>
          </Grid>
          <Grid item lg={3} xs={6}>
            <Box pr={2} height="100%">
              <PNLChart
                header={dealStats?.data}
                stats={chartsData?.data}
                additionalRem={isXl ? 6 : 3}
                onCollapse={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { chart: 'pnl', from: '2' }))}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={6}>
            <Paper elevation={0}>
              <Box display="flex" flexDirection="column" height="100%">
                <AmountBlock label="Advanced Amount" value={convertToPriceFormat(dealStats?.data.advanced)} />
                <Divider />
                <AmountBlock label="Payback Amount" value={convertToPriceFormat(dealStats?.data.payback)} />
                <Divider />
                <AmountBlock label="Net To Merchant" value={convertToPriceFormat(dealStats?.data.net)} />
                <Divider />
                <AmountBlock label="Collected Amount" value={convertToPriceFormat(dealStats?.data.collected)} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Box pl={4} display="flex" height="100%" flexDirection="column">
              <RTRSummary />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box mt="3rem">
        <LatestDealsSecondView />
      </Box>
    </Box>
  )
}

export default SecondDashboardView

import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { convertToPriceFormat } from 'utils/formatters'
import Typography from '@material-ui/core/Typography'
import { useFundingQuesStyle } from 'components/FundingQues/style'

interface ITotalBlockDeal {
  label: string
  count: number
  total: number
  setActiveStatus: (status: string) => void
}

const TotalBlockDeal: FC<ITotalBlockDeal> = ({ label, count, total, setActiveStatus }) => {
  const classes = useFundingQuesStyle()
  return (
    <Box className={classes.wrapper}>
      <Typography variant="h4" onClick={() => setActiveStatus(label)} color="textSecondary" className={classes.link} component="a">
        {label}
      </Typography>
      <Box mt="1.5rem">
        <Typography variant="h1" color="textSecondary" className={classes.secondaryColor}>
          {count}
        </Typography>
        <Typography variant="h3" color="textSecondary" className={classes.secondaryColor}>
          Total: {convertToPriceFormat(total)}
        </Typography>
      </Box>
    </Box>
  )
}

export default TotalBlockDeal

import React from 'react'
import { Box } from '@material-ui/core'
import { Route, Switch } from 'react-router-dom'
import {
  CREATE_PASSWORD_INVITATION_URL,
  RESET_EMAIL_SENT_URL,
  RESET_PASSWORD_URL,
  SET_PASSWORD_URL,
  SIGN_IN_URL,
  SIGN_UP_URL,
  CREATE_PASSWORD_URL,
  CHOOSE_ROLE,
} from 'constants/routes'

import AuthSideBar from 'components/AuthSideBar'

import ResetPasswordPage from 'pages/ResetPassword'
import SignInPage from 'pages/SignIn'
import SignUpPageInvitation from 'pages/SignUpInvitation'
import ConfirmSignUpPage from 'pages/ConfirmSignUp'
import SignUpPage from 'pages/SignUp'
import EmailSentPage from 'pages/EmailSent'
import CreateNewPasswordPage from 'pages/CreateNewPassword'
import ChooseRole from 'pages/ChooseRole'

import { useStyles } from './styles'

const Auth = () => {
  const s = useStyles({})
  return (
    <Box className={s.authContainer}>
      <AuthSideBar />
      <Box className={s.content}>
        <Switch>
          <Route exact path={CREATE_PASSWORD_URL} component={ConfirmSignUpPage} />
          <Route exact path={SIGN_IN_URL} component={SignInPage} />
          <Route exact path={SIGN_UP_URL} component={SignUpPage} />
          <Route exact path={RESET_PASSWORD_URL} component={ResetPasswordPage} />
          <Route exact path={CREATE_PASSWORD_INVITATION_URL} component={SignUpPageInvitation} />
          <Route exact path={SET_PASSWORD_URL} component={CreateNewPasswordPage} />
          <Route exact path={RESET_EMAIL_SENT_URL} component={EmailSentPage} />
          <Route exact path={CHOOSE_ROLE} component={ChooseRole} />
        </Switch>
      </Box>
    </Box>
  )
}

export default Auth

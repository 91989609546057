import { QueryCache } from 'react-query'

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchInterval: 100000,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      retryDelay: 100000,
    },
  },
})

export { queryCache }

import { Box, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory, useParams, useLocation, Link } from 'react-router-dom'
import { BASE_APP_URL, DEAL_ASSIGN_LENDERS, DEALS_LIST_URL, NEW_DEAL_URL, VIEW_CLIENT_URL } from 'constants/routes'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { ReactComponent as UploadIcon } from 'assets/svg/UploadIcon.svg'
import { ReactComponent as NewPaymentIcon } from 'assets/svg/NewPaymentIcon.svg'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as DeleteCircleIcon } from 'assets/svg/DeleteCircleIcon.svg'
import { ReactComponent as HorizDotsIcon } from 'assets/svg/HorizDotsIcon.svg'
import { ReactComponent as PhoneIcon } from 'assets/svg/PhoneIcon.svg'
import { ReactComponent as HomeIcon } from 'assets/svg/HomeIcon.svg'
import { ReactComponent as EmailAtIcon } from 'assets/svg/EmailAtIcon.svg'
import { ReactComponent as LocationIcon } from 'assets/svg/LocationIcon.svg'
import { ReactComponent as SuitcaseIcon } from 'assets/svg/SuitcaseIcon.svg'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { useMutation, useQuery } from 'react-query'
import { deleteDeal, getDeal, refundDeal, setToDefault, startClientFees, stopClientFees } from 'services/deals'
import cn from 'classnames'
import { ReactComponent as CancelIcon } from 'assets/svg/CloseIcon.svg'
import ModalComponent from 'UI/Modal'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { convertDate, convertEmptyField, convertToPriceFormat, roundNumber } from 'utils/formatters'
import { FeeStatusEnum, IDealPaymentFormValues, IEarlyDiscount, ISetToDefaultParams, earlyDiscountDescription } from 'typescript/interfaces/deals'
import { useSnackbar } from 'notistack'
import { DealsStatus, DealsType } from 'typescript/enums/deals'
import { SOMETHING_WENT_WRONG, NO_PERMISSIONS_FOR_THIS_DEAL } from 'constants/errors'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { IDealPayment } from 'typescript/interfaces/transactions'
import { TableDealPayments, useTransactions } from 'hooks/useTransactions'
import { usePaginationList } from 'hooks/usePaginationList'
import { SortFieldDealPayments } from 'typescript/enums/transactions'
import { getDealPayments, createDealPayment, editTransaction } from 'services/transactions'
import {
  ASSIGN_LENDERS_APPROVED_PERM,
  ASSIGN_LENDERS_PERM,
  CREATE_CLIENT_DEAL,
  CREATE_INHOUSE_OUTHOUSE_PERM,
  DEAL_DEACTIVATE_CRM_FEES_PERM,
  DEAL_DELETE_PERM,
  DEAL_EDIT_PERM,
  DEAL_NEW_PAYMENT_PERM,
  DEAL_REFUND_PERM,
  DEAL_REVERSE_SCHEDULE_PERM,
  DEAL_SCHEDULE_PAYMENT_PERM,
  DEAL_SET_TO_DEFAULT_PERM,
  DEAL_UPLOAD_DOCUMENTS_PERM,
} from 'constants/permissions'
import { CompanyType } from 'typescript/interfaces/companies'
import { usePermission } from 'hooks/usePermission'
import { createLinkToDownLoad, downloadFile } from 'utils/downloadFile'
import Button from 'UI/Button/ButtonWithPreloader'
import SetDefaultForm from 'components/SetDefaultForm'
import RefundDealForm from 'components/RefundDealForm'
import { getClientBankAccounts } from 'services/clients'
import { AxiosError } from 'axios'
import SchedulesLightTable from 'containers/Deal/DealInfo/Schedules'
import { UserContext } from 'contexts/userContext'
import DealPaymentForm from 'components/DealPaymentForm'
import { getFile } from 'services/storage'
import VendorsLightTable from 'containers/Deal/DealInfo/Vendors'
import SchedulePopover from 'containers/Deal/DealInfo/SchedulePopover'
import { exportDealPayments } from 'services/export'
import { useStates } from 'hooks/useStates'
import SyndicatorsLightTable from './SyndicatorsLightTable'
import RelatedDocumentsTable from './RelatedDocumentsTable'
import DetailsPopover from './DetailsPopover'
import { useStyles } from './style'
import MapStreetView from './MapStreetView'
import ReverseSchedulesTable from './ReverseSchedules'
import ReverseSchedulePopover from './ReverseSchedulePopover'
import FlexScheduleTable from './FlexSchedule'

const DealInfoContainer = () => {
  const location = useLocation()
  const { hasPermission } = usePermission()
  const { goBack, push } = useHistory()
  const s = useStyles()
  const { id } = useParams<{ id: string }>()
  const classes = useAdditionalMaterialStyle()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorScheduleEl, setAnchorScheduleEl] = React.useState(null)
  const [anchorReverseScheduleEl, setAnchorReverseScheduleEl] = React.useState(null)
  const [openPayment, setOpenPayment] = useState(false)
  const [editPaymentState, setEditPaymentState] = useState({
    id: 0,
    open: false,
  })
  const { enqueueSnackbar } = useSnackbar()
  const [deleteDealModal, setDeleteDealModal] = useState(false)
  const [openSetDefaultDeal, setOpenSetDefaultDeal] = useState(false)
  const [openActivateDeactivateFees, setOpenActivateDeactivateFees] = useState(false)
  const [openRefundModal, setOpenRefundModal] = useState(false)
  const { state } = useContext(UserContext)
  const params = new URLSearchParams(location.search)
  const docUrl = params.get('docUrl')

  useEffect(() => {
    if (docUrl)
      getFile(docUrl).then((res) => {
        createLinkToDownLoad(res.data, '')
        push(location.pathname)
      })
  }, [docUrl, location.pathname])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickSchedule = (event: any) => {
    setAnchorScheduleEl(event.currentTarget)
  }

  const handleClickReverseSchedule = (event: any) => {
    setAnchorReverseScheduleEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseSchedule = () => {
    setAnchorScheduleEl(null)
  }

  const handleCloseReverseSchedule = () => {
    setAnchorReverseScheduleEl(null)
  }

  const { data: statesData } = useStates()

  const { data, refetch } = useQuery(['inhouse-deal', id], () => getDeal(id), {
    onError(err: AxiosError) {
      if (err.response?.data.description === 'NO_PERMISSIONS_FOR_THIS_DEAL') {
        enqueueSnackbar(NO_PERMISSIONS_FOR_THIS_DEAL)
        goBack()
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
        push(DEALS_LIST_URL)
      }
    },
  })

  const deal = data?.data

  const showErrorAfterRefund = useCallback(() => {
    enqueueSnackbar(
      `Refund amount more than the unallocated value (${convertToPriceFormat(
        deal && deal.unallocated && deal.unallocated > 0 ? deal?.unallocated : 0,
      )})`,
    )
  }, [enqueueSnackbar, deal])

  const { data: dataBankAccounts } = useQuery(
    ['bankAccounts', id],
    () =>
      getClientBankAccounts({
        clientId: data && data.data.client ? data.data.client.id : '',
      }),
    {
      enabled: data && data.data.client?.id,
    },
  )

  const [refundDealMut, { isLoading: isLoadingRefund }] = useMutation(refundDeal, {
    onSuccess: () => {
      refetch()
      setOpenRefundModal(false)
      enqueueSnackbar('Refunded')
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'INVALID_REFUND_AMOUNT') showErrorAfterRefund()
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [stopClientFeesMut, { isLoading: isLoadingStopClientFees }] = useMutation(stopClientFees, {
    onSuccess: () => {
      refetch()
      enqueueSnackbar('CRM Fees deactivated')
      setOpenActivateDeactivateFees(false)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [startClientFeesMut, { isLoading: isLoadingStartClientFees }] = useMutation(startClientFees, {
    onSuccess: () => {
      refetch()
      enqueueSnackbar('CRM Fees activated')
      setOpenActivateDeactivateFees(false)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [setToDefaultMut, { isLoading: isLoadingSetToDefault }] = useMutation(setToDefault, {
    onSuccess: () => {
      refetch()
      setOpenSetDefaultDeal(false)
      if (deal && deal.paidStatus !== 'Default') enqueueSnackbar('Deal is set to Default')
      else enqueueSnackbar('Default updated')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [handleDelete, { isLoading: deleteIsLoading }] = useMutation(() => deleteDeal(id), {
    onSuccess(res) {
      push(BASE_APP_URL)
      enqueueSnackbar(<Typography>Successfully deleted deal</Typography>)
    },
    onError(err: AxiosError) {
      if (err.response?.data.description) enqueueSnackbar(err.response?.data.description)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const open = Boolean(anchorEl)
  const openSchedule = Boolean(anchorScheduleEl)
  const openReverseSchedule = Boolean(anchorReverseScheduleEl)

  const { handleSort, handleFetchMore, pagination, setPagination } = usePaginationList<IDealPayment>({
    initState: {
      data: [],
      search: '',
      order: SortFieldDealPayments.creationDate,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })
  const [getTransactions, { isLoading: transactionsLoading }] = useMutation(getDealPayments)

  const handleUpdateTransactions = useCallback(() => {
    if (pagination.page === 0)
      getTransactions(
        {
          PageSize: 20,
          PageIndex: pagination.page,
          Query: pagination.search,
          SortField: pagination.order,
          SortOrder: pagination.sortOrder,
          DealId: +id,
        },
        {
          onSuccess: (returnData) => {
            setPagination((prevState) => ({
              ...prevState,
              page: 0,
              data: [...returnData.data],
              hasMore: returnData.data.length === 20,
            }))
          },
        },
      )
    else
      setPagination((prevState) => ({
        ...prevState,
        page: 0,
        data: [],
        hasMore: true,
      }))
  }, [pagination, getTransactions, id])

  const [createDealPaymentMut, { isLoading: isNewPaymentLoading }] = useMutation(createDealPayment, {
    onSuccess: () => {
      enqueueSnackbar('Payment successfully created')
      setOpenPayment(false)
      handleUpdateTransactions()
      refetch()
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'INVALID_REFUND_AMOUNT') showErrorAfterRefund()
      else if (err.response?.data.description === 'TRANSACTION_AMOUNT_LESS_OR_EQUAL_ZERO') enqueueSnackbar(`Amount can't be less or equal 0`)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [editTransactionMut, { isLoading: isEditingTransaction }] = useMutation(editTransaction, {
    onSuccess: () => {
      enqueueSnackbar('Payment successfully edited')
      setEditPaymentState({
        open: false,
        id: 0,
      })
      handleUpdateTransactions()
      refetch()
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'INVALID_FLEX_AMOUNT') {
        enqueueSnackbar('Extend amount is more than remain to extend')
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const handleCreateDealPayment = useCallback(
    (values: IDealPaymentFormValues) => {
      const paymentDate = values.status === 'Cancelled' || values.status === 'Rejected' ? null : values.paymentDate
      createDealPaymentMut({
        ...values,
        paymentDate: paymentDate || null,
        dealId: +id,
        category: values.category === 'LegalCostVendor' ? 'LegalCost' : values.category,
        vendorId: !values.vendorId ? null : values.vendorId,
      })
    },
    [createDealPaymentMut, id],
  )

  const handleEditTransaction = useCallback(
    (values: IDealPaymentFormValues) => {
      editTransactionMut({
        id: editPaymentState.id,
        scheduledDate: values.scheduledFor || '',
        paymentDate: values.paymentDate || null,
        status: values.status || '',
        comment: values.comments || '',
      })
    },
    [editTransactionMut, editPaymentState],
  )

  // const handleSubmitNewPayment = useCallback((values: ITransactionParamsInitValue) => {}, [])

  const { columnsDealTransactions } = useTransactions({
    handleEditPaymentDeal: (idPaymentDeal) => {
      setEditPaymentState({
        id: idPaymentDeal,
        open: true,
      })
    },
  })

  useEffect(() => {
    if (id)
      getTransactions(
        {
          PageSize: 20,
          PageIndex: pagination.page,
          Query: pagination.search,
          SortField: pagination.order,
          SortOrder: pagination.sortOrder,
          DealId: +id,
        },
        {
          onSuccess: (returnData) => {
            setPagination((prevState) => ({
              ...prevState,
              data: [...prevState.data, ...returnData.data],
              hasMore: returnData.data.length === 20,
            }))
          },
        },
      )
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder])

  const handleCancelModal = () => {
    setDeleteDealModal(false)
  }

  const dealIsNotEditable = useMemo(() => {
    return (
      ((deal?.status === DealsStatus.approved || deal?.status === DealsStatus.declined || deal?.type === DealsType.outhouse) &&
        !state.roles.isAdmin) ||
      !hasPermission(DEAL_EDIT_PERM) ||
      (state.roles.isClient && deal?.type === DealsType.outhouse)
    )
  }, [deal?.status, state.roles.isAdmin, state.roles.isClient, deal?.type])

  const [exportDealPaymentsExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportDealPayments, {
    onSuccess: (dataFile) => {
      downloadFile(dataFile)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const disableUploadDocuments = useMemo(() => {
    return (
      (deal?.paidStatus === 'Current' && !state.roles.isAdmin && !state.roles.isISO && !state.roles.isRep) ||
      !hasPermission(DEAL_UPLOAD_DOCUMENTS_PERM) ||
      deal?.type === DealsType.outhouse
    )
  }, [deal])

  const disableNewPayment = useMemo(() => {
    return deal?.status !== DealsStatus.approved || deal?.type === DealsType.outhouse || !hasPermission(DEAL_NEW_PAYMENT_PERM)
  }, [deal])

  const disableSchedule = useMemo(() => {
    return deal?.status !== DealsStatus.approved || deal?.type === DealsType.outhouse || !hasPermission(DEAL_SCHEDULE_PAYMENT_PERM)
  }, [deal])

  const disableReverseSchedule = useMemo(() => {
    return deal?.status !== DealsStatus.approved || deal?.type === DealsType.outhouse || !hasPermission(DEAL_REVERSE_SCHEDULE_PERM)
  }, [deal])

  const disableRefund = useMemo(() => {
    return (
      deal?.status !== DealsStatus.approved ||
      deal?.type === DealsType.outhouse ||
      !hasPermission(DEAL_REFUND_PERM) ||
      !deal?.unallocated ||
      deal?.unallocated <= 0
    )
  }, [deal])

  const disableSetToDefault = useMemo(() => {
    return deal?.status !== DealsStatus.approved || deal?.type === DealsType.outhouse || !hasPermission(DEAL_SET_TO_DEFAULT_PERM)
  }, [deal])

  const isActiveCrmFee = deal?.paymentInfo.clientProgramFeePaymentsStatus === FeeStatusEnum.active

  const disableDeactivateCrmFees = useMemo(() => {
    return (
      deal?.status !== DealsStatus.approved ||
      deal?.type === DealsType.outhouse ||
      (deal?.paidStatus === 'FullyPaid' && isActiveCrmFee) ||
      !hasPermission(DEAL_DEACTIVATE_CRM_FEES_PERM)
    )
  }, [deal, isActiveCrmFee])

  const disableAssignLenders = useMemo(() => {
    return (
      (!hasPermission(ASSIGN_LENDERS_APPROVED_PERM) || deal?.type === DealsType.outhouse) &&
      ((deal?.status !== DealsStatus.new && deal?.status !== DealsStatus.inUnderwriting && deal?.status !== DealsStatus.readyToFund) ||
        !hasPermission(ASSIGN_LENDERS_PERM))
    )
  }, [deal])

  const disableDelete = useMemo(() => {
    return (
      (deal?.status !== DealsStatus.new &&
        deal?.status !== DealsStatus.approved &&
        deal?.status !== DealsStatus.inUnderwriting &&
        deal?.status !== DealsStatus.readyToFund &&
        deal?.status !== DealsStatus.outhouseApproved) ||
      !hasPermission(DEAL_DELETE_PERM)
    )
  }, [deal])

  const dealPaymentDefaultValues: IDealPaymentFormValues | undefined = useMemo(() => {
    if (editPaymentState.id && pagination.data) {
      const foundedPayment = pagination.data.find((p) => +p.id === editPaymentState.id)
      return foundedPayment
        ? {
            scheduledFor: foundedPayment.scheduledDate,
            amount: foundedPayment.payment,
            achProvider: foundedPayment.provider,
            bankAccountId: foundedPayment.bankAccount,
            status: foundedPayment.status,
            category: foundedPayment.category,
            paymentDate: foundedPayment.paymentDate,
            comments: foundedPayment.comments,
          }
        : undefined
    }
    return undefined
  }, [editPaymentState.id, pagination.data])

  const paidOffVal = useMemo(() => {
    return deal && deal.paidOff !== undefined && deal.paidOff <= 100 ? deal.paidOff : 100
  }, [deal])

  const fullAddress = useMemo(() => {
    if (!deal?.client || !statesData?.data) {
      return null
    }

    const { street, city, phisicalStateId, zipCode } = deal.client
    const stateAbbreviation = statesData.data.find((x) => x.id === phisicalStateId)?.abbreviation

    return `${street}, ${city}, ${stateAbbreviation} ${zipCode}`
  }, [deal, statesData?.data])

  return (
    <Box className={classes.wrapper}>
      <Box display="flex" ml="3rem" mb="2rem" alignItems="center">
        <Box mr="1.5rem">
          <IconButton onClick={goBack} color="inherit">
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography variant="h1" color="textSecondary">
          Deal #{convertEmptyField(deal?.id)}
        </Typography>
      </Box>
      {/* Buttons */}
      <Box ml="3rem" mr="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Button
          onClick={() => {
            if (hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM)) {
              push(
                `${generatePath(NEW_DEAL_URL, {
                  id: deal?.id,
                  view: 'edit',
                  type: deal?.type === 'InHouse' ? 'inhouse' : 'outhouse',
                  step: 2,
                })}?uploadDocs=true`,
              )
            } else if (hasPermission(CREATE_CLIENT_DEAL)) {
              push(
                `${generatePath(NEW_DEAL_URL, {
                  id: deal?.id,
                  view: 'edit',
                  type: 'client-deal',
                  step: 2,
                })}?uploadDocs=true`,
              )
            }
          }}
          disabled={disableUploadDocuments}
          color="primary"
          variant="contained"
          startIcon={<UploadIcon />}
        >
          Upload Documents
        </Button>
        <Button variant="contained" onClick={() => setOpenPayment(true)} startIcon={<NewPaymentIcon />} disabled={disableNewPayment}>
          New Payment
        </Button>
        <Button onClick={handleClickSchedule} disabled={disableSchedule} variant="contained" startIcon={<NewPaymentIcon />}>
          Schedule Payments
        </Button>
        <SchedulePopover deal={data?.data} open={openSchedule} onClose={handleCloseSchedule} anchorEl={anchorScheduleEl} />
        {deal?.extendType === 'Reverse' && (
          <Button onClick={handleClickReverseSchedule} disabled={disableReverseSchedule} variant="contained" startIcon={<NewPaymentIcon />}>
            Schedule Disbursement
          </Button>
        )}
        <ReverseSchedulePopover
          deal={data?.data}
          open={openReverseSchedule}
          onClose={handleCloseReverseSchedule}
          anchorEl={anchorReverseScheduleEl}
        />
        <Button variant="contained" startIcon={<NewPaymentIcon />} disabled={disableRefund} onClick={() => setOpenRefundModal(true)}>
          Refund
        </Button>
        <Button variant="contained" startIcon={<NewPaymentIcon />} disabled={disableSetToDefault} onClick={() => setOpenSetDefaultDeal(true)}>
          {deal?.paymentInfo?.defaultFee !== 0 ? 'Update default' : 'Set to Default'}
        </Button>
        <Button
          variant="contained"
          startIcon={<NewPaymentIcon />}
          disabled={disableDeactivateCrmFees}
          onClick={() => setOpenActivateDeactivateFees(true)}
        >
          {isActiveCrmFee ? 'Deactivate CRM Fees' : 'Activate CRM Fees'}
        </Button>
        <Button
          variant="contained"
          startIcon={<NewPaymentIcon />}
          disabled={disableAssignLenders}
          onClick={() =>
            push(
              generatePath(DEAL_ASSIGN_LENDERS, {
                id: deal?.id,
                type: deal?.type === 'InHouse' ? 'inhouse' : 'outhouse',
              }),
            )
          }
        >
          Assign Lenders
        </Button>
        <Button
          onClick={() => {
            if (hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM)) {
              push(
                generatePath(NEW_DEAL_URL, {
                  id: deal?.id,
                  view: 'edit',
                  type: deal?.type === CompanyType.InHouse ? 'inhouse' : 'outhouse',
                  step: 1,
                }),
              )
            } else if (hasPermission(CREATE_CLIENT_DEAL)) {
              push(
                generatePath(NEW_DEAL_URL, {
                  id: deal?.id,
                  view: 'edit',
                  type: 'client-deal',
                  step: 1,
                }),
              )
            }
          }}
          variant="contained"
          disabled={dealIsNotEditable}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
        <Button disabled={disableDelete} onClick={() => setDeleteDealModal(true)} variant="contained" startIcon={<DeleteCircleIcon />}>
          Delete
        </Button>
        <Box pb="2rem" pr="1rem" mr="-1rem" mb="-2rem" onClick={handleClick}>
          <IconButton className={s.iconButton}>
            <HorizDotsIcon />
          </IconButton>
        </Box>

        <DetailsPopover refetch={refetch} deal={data?.data} open={open} onClose={handleClose} anchorEl={anchorEl} />
      </Box>
      {/* Client info */}
      <Box padding="3rem" mt="1rem" component={Paper} display="flex" justifyContent="space-between" alignItems="center">
        <Box padding="1.5rem" flex="1" mr="1rem">
          <Typography color="textPrimary">Business Name:</Typography>
          <Typography
            className={s.clientName}
            variant="h2"
            color="textSecondary"
            component={Link}
            to={generatePath(VIEW_CLIENT_URL, { id: deal?.client?.id ?? 0 })}
          >
            {convertEmptyField(deal?.client?.businessName)}
          </Typography>
        </Box>
        <Box padding="1.5rem" flex="1" mr="1rem" className={s.blueRoundedBlock}>
          <Typography color="textPrimary">Remaining Balance:</Typography>
          <Typography variant="h3" color="textSecondary">
            {convertToPriceFormat(deal && deal.remaining && deal.remaining > 0 ? deal.remaining : 0)}
          </Typography>
        </Box>
        <Box padding="1.5rem" flex="1" mr="1rem" className={s.blueRoundedBlock}>
          <Typography color="textPrimary">Unallocated:</Typography>
          <Typography variant="h3" color="textSecondary">
            {convertToPriceFormat(deal && deal.unallocated && deal.unallocated > 0 ? deal.unallocated : 0)}
          </Typography>
        </Box>
        <Box padding="1.5rem" flex="1" mr="1rem" className={s.blueRoundedBlock}>
          <Typography color="textPrimary">Default fee:</Typography>
          <Typography variant="h3" color="textSecondary">
            {convertToPriceFormat(deal?.paymentInfo.defaultFee || 0)}
          </Typography>
        </Box>
        <Box padding="1.5rem" flex="1" className={s.blueRoundedBlock}>
          <Typography color="textPrimary">Collected:</Typography>
          <Typography variant="h3" color="textSecondary">
            {convertToPriceFormat(deal?.collected)}
            <span
              style={{
                whiteSpace: 'nowrap',
                marginLeft: '5px',
              }}
            >
              [{roundNumber(paidOffVal)}]%
            </span>
          </Typography>
        </Box>
      </Box>
      {/* GeneralInfo + ContactInfo */}
      <Box mt="1rem" display="flex" justifyContent="space-between">
        <Box flex="1" component={Paper}>
          <Box p="3rem">
            <Typography className={s.sectionLabel} color="textSecondary">
              General Information
            </Typography>
            <Grid container component={Box} mt="2rem">
              <Grid item container xs={6} spacing={1}>
                <Grid item xs={6}>
                  <Typography>Unique Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{deal?.dealName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Company</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{deal?.type === 'InHouse' ? convertEmptyField(data?.data.company?.name) : 'n/a'}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>OutHouse Lenders</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">
                    {deal?.type === 'InHouse' ? 'n/a' : convertEmptyField(deal?.outhouseLenders?.map((l) => l.businessName).join(','))}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>ISO</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.isos.map((x) => x.businessName).join(', '))}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Created On</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(deal?.createdDate)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Updated On</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(deal?.updatedDate)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Client Id</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.client?.id)}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={6} spacing={1}>
                <Grid item xs={6}>
                  <Typography>Advance Amount</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.advanceAmount)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>UCC Uploaded</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{deal?.uccUploaded ? 'Yes' : 'No'}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Term</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.term)}</Typography>
                </Grid>
                {deal?.extendType === 'Reverse' && (
                  <>
                    <Grid item xs={6}>
                      <Typography>Reverse Term</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="textSecondary">{convertEmptyField(deal?.reverseTerm)}</Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Typography>Start Date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(deal?.startDate)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Planned End Date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertDate(deal?.plannedEndDate)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Status</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">
                    {deal?.status !== DealsStatus.approved ? convertEmptyField(deal?.status) : convertEmptyField(deal?.paidStatus)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box p="3rem">
            <Typography className={s.sectionLabel} color="textSecondary">
              Payment Information
            </Typography>
            <Grid container component={Box} mt="2rem">
              <Grid item container xs={6} spacing={1}>
                <Grid item xs={6}>
                  <Typography>Frequency</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.paymentFrequency)}</Typography>
                </Grid>
                {deal?.extendType === 'Reverse' && (
                  <>
                    <Grid item xs={6}>
                      <Typography>Disbursement Frequency</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="textSecondary">{convertEmptyField(deal.paymentInfo?.disbursementFrequency)}</Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Typography>Factor Rate</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.factor)}</Typography>
                </Grid>
                {deal?.paymentInfo.earlyDiscount &&
                  Object.entries(deal.paymentInfo.earlyDiscount)
                    .map((entry) => entry as [keyof IEarlyDiscount, number?])
                    .filter(([_, factor]) => !!factor)
                    .map(([key, factor]) => (
                      <>
                        <Grid item xs={6}>
                          <Typography>{earlyDiscountDescription[key]}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(factor)}</Typography>
                        </Grid>
                      </>
                    ))}
                <Grid item xs={6}>
                  <Typography>Payback Amount</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.payback)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Early PayOff Amount</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.earlyPayOffAmount)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Payments</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.frequentPayment)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Collected</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.collected)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Remaining Balance</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">
                    {deal && deal.remaining && deal.remaining >= 0 ? convertEmptyField(deal?.remaining) : 0}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Default Fee</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.defaultFee)}</Typography>
                </Grid>
                {deal?.type === 'InHouse' && (
                  <>
                    <Grid item xs={6}>
                      <Typography>Specified Percentage</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.specifiedPercentage)}</Typography>
                    </Grid>
                    {['NY', 'CA'].includes(statesData?.data.find((x) => x.id === deal?.client?.phisicalStateId)?.abbreviation ?? '') && (
                      <>
                        <Grid item xs={6}>
                          <Typography>APR</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.apr)}</Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6}>
                      <Typography>Monthly Revenue</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.monthlyRevenue)}</Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Typography>Bank Account Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.bankAccount?.bankAccountType)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Bank Account #</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.bankAccount?.bankAccount)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Bank Routing #</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.bankAccount?.bankRouting)}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={6} spacing={1}>
                <Grid item xs={6}>
                  <Typography>Name on Volded Check</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.nameOnCheck)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Bank Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.nameOnBankAccount)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Commission To Rep</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.commissionToRep)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>ISO Fee</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.isoFee)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Total Comission</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.totalCommission)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Bank Fee</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.syndicationFee)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>PSF Fee</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.psfFee)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Ref/Payoff</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo.payOffAmount)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Monthly CRM Fee</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.paymentInfo?.monthlyCrmFee)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Net To Merchant</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{convertEmptyField(deal?.net)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Send As Wire</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{deal?.sendAsWire ? 'Yes' : 'No'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box p="3rem" width="33%" ml="1rem" component={Paper}>
          <Typography className={s.sectionLabel} color="textSecondary">
            Contact Information
          </Typography>
          <Box mt="2rem">
            <Box mb="1.5rem" display="flex">
              <Box>
                <SuitcaseIcon className={s.contactIcon} width={22} height={22} viewBox="1.5 1 22 22" />
              </Box>
              <Typography className={s.contactText} color="textSecondary">
                {convertEmptyField(deal?.client?.businessPhone)}
              </Typography>
            </Box>
            <Box mb="1.5rem" display="flex">
              <Box>
                <PhoneIcon className={s.contactIcon} width={22} height={22} viewBox="-3.5 -0.5 22 22" />
              </Box>
              <Typography className={s.contactText} color="textSecondary">
                {convertEmptyField(deal?.client?.phone)}
              </Typography>
            </Box>
            <Box mb="1.5rem" display="flex">
              <Box>
                <HomeIcon className={s.contactIcon} width={22} height={22} viewBox="1.5 1 22 22" />
              </Box>
              <Typography className={s.contactText} color="textSecondary">
                {convertEmptyField(deal?.client?.homePhone)}
              </Typography>
            </Box>
            <Box mb="1.5rem" display="flex">
              <Box>
                <EmailAtIcon className={s.contactIcon} width={22} height={22} viewBox="-0.5 -1 26 26" />
              </Box>
              <Typography className={s.contactText} color="textSecondary">
                {convertEmptyField(deal?.client?.email)}
              </Typography>
            </Box>
            <Box mb="1.5rem" display="flex">
              <Box>
                <LocationIcon className={s.contactIcon} width={22} height={22} viewBox="-1 -1 34 34" />
              </Box>
              <Typography className={s.contactText} color="textSecondary">
                {convertEmptyField(fullAddress)}
              </Typography>
            </Box>

            <MapStreetView client={deal?.client} />
          </Box>
        </Box>
      </Box>
      {/* Syndicators and RelatedDocs Table */}
      <Box display="flex" flexDirection="column" mt="1rem">
        <Box component={Paper}>
          <Box p="3rem" pb="1rem">
            <Typography className={s.sectionLabel} color="textSecondary">
              Syndicators
            </Typography>
          </Box>
          <SyndicatorsLightTable data={deal && deal.lenders ? deal.lenders : []} />
        </Box>
        <Box component={Paper} mt="1rem">
          <Box p="3rem" pb="1rem">
            <Typography className={s.sectionLabel} color="textSecondary">
              Schedule Payments
            </Typography>
          </Box>
          <SchedulesLightTable />
        </Box>
        {deal?.extendType === 'Reverse' && (
          <Box component={Paper} mt="1rem">
            <Box p="3rem" pb="1rem">
              <Typography className={s.sectionLabel} color="textSecondary">
                Disbursement Schedules
              </Typography>
            </Box>
            <ReverseSchedulesTable disbursementFrequency={deal?.paymentInfo?.disbursementFrequency} />
          </Box>
        )}
        {deal?.extendType === 'Flex' && (
          <Box component={Paper} mt="1rem">
            <Box p="3rem" pb="1rem">
              <Typography className={s.sectionLabel} color="textSecondary">
                Flex Disbursements
              </Typography>
            </Box>
            <FlexScheduleTable firstDisbursement={{ id: -1, date: deal.startDate, amount: deal.flexAmount }} />
          </Box>
        )}
        <Box mt="1rem" display="flex">
          <Box component={Paper} width="calc(50% - 1rem)" mr="1rem">
            <Box p="3rem" pb="1rem" display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={s.sectionLabel} color="textSecondary">
                Vendors
              </Typography>
            </Box>
            <VendorsLightTable data={deal?.vendors || []} />
          </Box>
          <Box component={Paper} width="calc(50%)">
            <Box p="3rem" pb="1rem" display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={s.sectionLabel} color="textSecondary">
                Related Documents
              </Typography>
            </Box>
            <RelatedDocumentsTable documents={deal?.documents || []} />
          </Box>
        </Box>
      </Box>
      <Box component={Paper} mt="1rem">
        <Box px="3rem" py="2rem" pb="0" display="flex" justifyContent="flex-end">
          {deal && (
            <Button
              variant="text"
              color="secondary"
              startIcon={<ExcelIcon />}
              loading={isLoadingExportExcel}
              onClick={() => {
                exportDealPaymentsExcelMut({
                  dealId: deal.id || 0,
                })
              }}
            >
              Export to Excel
            </Button>
          )}
        </Box>
        <TableDealPayments
          hasMore={pagination.hasMore}
          handleSortChange={handleSort}
          handleGetMore={handleFetchMore}
          columns={columnsDealTransactions}
          data={pagination.data}
          loading={transactionsLoading}
        />
      </Box>
      <ModalComponent open={openPayment || editPaymentState.open}>
        <Box maxWidth={500} maxHeight={800}>
          <Box display="flex" justifyContent="flex-end" mt="-1rem" mr="-1rem">
            <IconButton
              color="secondary"
              size="small"
              onClick={() => {
                openPayment && setOpenPayment(false)
                editPaymentState.open &&
                  setEditPaymentState({
                    open: false,
                    id: 0,
                  })
              }}
              classes={{
                root: cn(classes.closeTrsModalButton),
              }}
            >
              <CancelIcon />
            </IconButton>
          </Box>
          <Typography variant="h2" color="textSecondary">
            {openPayment && `Add New Payment - Deal #${id}`}
            {editPaymentState.open && `Edit Payment - Deal #${id}`}
          </Typography>
          {deal && (
            <Box mt="2rem">
              <DealPaymentForm
                initialValues={dealPaymentDefaultValues}
                editMode={editPaymentState.open}
                bankAccounts={dataBankAccounts?.data || []}
                cancel={() => {
                  openPayment && setOpenPayment(false)
                  editPaymentState.open &&
                    setEditPaymentState({
                      open: false,
                      id: 0,
                    })
                }}
                submit={editPaymentState.open ? handleEditTransaction : handleCreateDealPayment}
                loading={isNewPaymentLoading || isEditingTransaction}
              />
            </Box>
          )}
        </Box>
      </ModalComponent>

      <ModalComponent open={deleteDealModal} onClose={handleCancelModal}>
        <ConfirmationContent
          isLoading={deleteIsLoading}
          handleCancel={handleCancelModal}
          handleConfirm={() => handleDelete()}
          text={`Are you sure you want to delete this deal?`}
        />
      </ModalComponent>
      <ModalComponent open={openSetDefaultDeal}>
        <SetDefaultForm
          initValues={{
            vendorIds: deal?.vendors ? deal.vendors.map((item) => item.id) : undefined,
            defaultFee: deal?.paymentInfo.defaultFee ? deal.paymentInfo.defaultFee : '',
          }}
          isDefault={deal?.paidStatus === 'Default'}
          submit={(values) =>
            setToDefaultMut(
              Object.entries(values).reduce((acc, [key, value]) => {
                return {
                  ...acc,
                  dealId: id,
                  [key]: value || (key === 'defaultFee' && '0'),
                }
              }, {}) as ISetToDefaultParams,
            )
          }
          cancel={() => setOpenSetDefaultDeal(false)}
          isLoading={isLoadingSetToDefault}
        />
      </ModalComponent>
      <ModalComponent open={openActivateDeactivateFees}>
        <ConfirmationContent
          isLoading={isLoadingStartClientFees || isLoadingStopClientFees}
          text={`Are you sure you want to ${isActiveCrmFee ? 'deactivate' : 'activate'} CRM Fees?`}
          handleCancel={() => setOpenActivateDeactivateFees(false)}
          handleConfirm={() => {
            if (deal) {
              if (deal.paymentInfo.clientProgramFeePaymentsStatus === FeeStatusEnum.active) stopClientFeesMut(deal.id)
              else startClientFeesMut(deal.id)
            }
          }}
        />
      </ModalComponent>
      {deal && dataBankAccounts && (
        <ModalComponent open={openRefundModal}>
          <RefundDealForm
            cancel={() => setOpenRefundModal(false)}
            submit={(values) =>
              refundDealMut({
                id: id,
                ...values,
              })
            }
            isLoading={isLoadingRefund}
            initValues={{
              amount: deal.unallocated || 0,
            }}
          />
        </ModalComponent>
      )}
    </Box>
  )
}

export default DealInfoContainer

import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper/Paper'
import { Box, Checkbox, FormControlLabel, MenuItem } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { IInitialValuesRepresentative } from 'typescript/interfaces/rep'
import { useFormik } from 'formik'
import TextField from '@material-ui/core/TextField'
import Button from 'UI/Button/ButtonWithPreloader'
import { useHistory, useParams } from 'react-router-dom'
import ReactInputMask from 'react-input-mask'
import { schemaRepForm } from 'components/RepForm/schema'
import RequiredOption from 'UI/Select/RequiredOption'
import { userPageInitValues } from 'typescript/interfaces/users'
import SelectComponent from 'UI/Select'
import { useStates } from 'hooks/useStates'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

interface IRepFormProps {
  label: string
  initValues?: IInitialValuesRepresentative
  submit: (values: IInitialValuesRepresentative) => void
  loading?: boolean
}

const defaultInitValues: IInitialValuesRepresentative = {
  firstName: '',
  lastName: '',
  phone: '',
  mobile: '',
  email: '',
  fax: '',
  userPage: userPageInitValues,
  nameOnCheck: '',
  businessName: '',
  city: '',
  street: '',
  zipCode: '',
  federalTaxId: '',
  stateId: '',
  isCrmFeeActive: true,
}

const RepForm: FC<IRepFormProps> = ({ initValues = defaultInitValues, label, submit, loading }) => {
  const additionalStyle = useAdditionalMaterialStyle()
  const { id } = useParams<{ id: string }>()
  const { goBack } = useHistory()
  const { data: statesData } = useStates()
  const { handleSubmit, values, errors, touched, handleChange } = useFormik({
    initialValues: initValues,
    onSubmit: submit,
    validationSchema: schemaRepForm(Boolean(id)),
  })
  return (
    <form onSubmit={handleSubmit}>
      <Grid className={additionalStyle.wrapper} container spacing={4}>
        <Grid item xs={6}>
          <Paper elevation={0}>
            <Box p="3rem">
              <Typography variant="h2" color="textSecondary">
                {label}
              </Typography>
              <Box mt="3rem">
                <TextField
                  value={values.businessName}
                  onChange={handleChange}
                  error={Boolean(errors.businessName && touched.businessName)}
                  helperText={touched.businessName && errors.businessName}
                  name="businessName"
                  label={<RequiredOption label="Business Name" />}
                  variant="outlined"
                  fullWidth
                />
              </Box>
              <Box mt="3rem">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      fullWidth
                      variant="outlined"
                      value={values.firstName}
                      onChange={handleChange}
                      label={<RequiredOption label="First Name" />}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      fullWidth
                      variant="outlined"
                      value={values.lastName}
                      onChange={handleChange}
                      label={<RequiredOption label="Last Name" />}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                </Grid>
                <Box mt="2rem">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <ReactInputMask name="phone" mask="(999)-999-9999" value={values.phone} onChange={handleChange}>
                        {(props: any) => (
                          <TextField
                            {...props}
                            label={<RequiredOption label="Phone" />}
                            error={Boolean(errors.phone && touched.phone)}
                            helperText={touched.phone && errors.phone}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <ReactInputMask name="mobile" mask="(999)-999-9999" value={values.mobile} onChange={handleChange}>
                        {(props: any) => (
                          <TextField
                            {...props}
                            error={Boolean(errors.mobile && touched.mobile)}
                            helperText={touched.mobile && errors.mobile}
                            label="Mobile"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="2rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        value={values.fax}
                        name="fax"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label="Fax"
                        helperText={touched.fax && errors.fax}
                        error={Boolean(touched.fax && errors.fax)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled={Boolean(id)}
                        value={values.email}
                        name="email"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label={<RequiredOption label="Email" />}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="2rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        error={!!touched.street && !!errors.street}
                        helperText={touched.street && errors.street}
                        value={values.street}
                        name="street"
                        onChange={handleChange}
                        label={<RequiredOption label="Street" />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        error={!!touched.city && !!errors.city}
                        helperText={touched.city && errors.city}
                        value={values.city}
                        name="city"
                        onChange={handleChange}
                        label={<RequiredOption label="City" />}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="2rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <SelectComponent
                        error={!!touched.stateId && !!errors.stateId}
                        helperText={touched.stateId && errors.stateId}
                        value={values.stateId}
                        name="stateId"
                        onChange={handleChange}
                        label={<RequiredOption label="State" />}
                        fullWidth
                      >
                        {statesData?.data.map((state) => (
                          <MenuItem value={state.id}>{state.name}</MenuItem>
                        ))}
                      </SelectComponent>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        error={!!touched.zipCode && !!errors.zipCode}
                        helperText={touched.zipCode && errors.zipCode}
                        value={values.zipCode}
                        name="zipCode"
                        onChange={handleChange}
                        label={<RequiredOption label="Zip Code" />}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt="2rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <ReactInputMask name="federalTaxId" mask="99-9999999" value={values.federalTaxId} onChange={handleChange}>
                        {(props: any) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            error={!!touched.federalTaxId && !!errors.federalTaxId}
                            helperText={touched.federalTaxId && errors.federalTaxId}
                            label={<RequiredOption label="Federal Tax ID" />}
                            fullWidth
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        name="CRM fee active"
                        onChange={handleChange}
                        label="CRM fee active"
                        labelPlacement="start"
                        control={<Checkbox checked={values.isCrmFeeActive} color="primary" name="isCrmFeeActive" />}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            elevation={0}
            style={{
              height: '100%',
            }}
          >
            <Box p="3rem" flexDirection="column" display="flex" height="inherit">
              <Box>
                <Typography variant="h2" color="textSecondary">
                  Bank Information
                </Typography>
                <Box mt="3rem">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        value={values.userPage.bankName}
                        name="userPage.bankName"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label={<RequiredOption label="Bank Name" />}
                        helperText={touched.userPage?.bankName && errors.userPage?.bankName}
                        error={Boolean(touched.userPage?.bankName && errors.userPage?.bankName)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReactInputMask
                        name="userPage.bankRouting"
                        mask="999999999"
                        maskPlaceholder={null}
                        onChange={handleChange}
                        value={values.userPage.bankRouting}
                      >
                        {(props: any) => (
                          <TextField
                            variant="outlined"
                            error={!!touched.userPage?.bankRouting && !!errors.userPage?.bankRouting}
                            helperText={touched.userPage?.bankRouting && errors.userPage?.bankRouting}
                            label={<RequiredOption label="Bank Routing #" />}
                            fullWidth
                            {...props}
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box mt="3rem">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      value={values.userPage.bankAccount}
                      name="userPage.bankAccount"
                      fullWidth
                      onChange={handleChange}
                      variant="outlined"
                      label={<RequiredOption label="Bank Account #" />}
                      error={Boolean(touched.userPage?.bankAccount && errors.userPage?.bankAccount)}
                      helperText={touched.userPage?.bankAccount && errors.userPage?.bankAccount}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={Boolean(id)}
                      value={values.userPage.availableBalance}
                      fullWidth
                      name="userPage.availableBalance"
                      variant="outlined"
                      onChange={handleChange}
                      label={<RequiredOption label="Starting Balance" />}
                      error={Boolean(touched.userPage?.availableBalance && errors.userPage?.availableBalance)}
                      helperText={touched.userPage?.availableBalance && errors.userPage?.availableBalance}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt="3rem">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      value={values.nameOnCheck}
                      name="nameOnCheck"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      label="Name on Voided Check"
                      error={Boolean(touched.nameOnCheck && errors.nameOnCheck)}
                      helperText={touched.nameOnCheck && errors.nameOnCheck}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" alignItems="flex-end" flex={1}>
                <Box mr={5}>
                  <Button variant="contained" color="secondary" onClick={() => goBack()} loading={loading}>
                    Cancel
                  </Button>
                </Box>
                <Button variant="contained" color="primary" type="submit" loading={loading}>
                  Complete
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  )
}

export default RepForm

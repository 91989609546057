import React, { FC, useContext } from 'react'
import AssignLendersContainer, { AssignLendersProps } from 'containers/Deal/AssignLenders/AssignLenders'
import AssignLendersFunderContainer from 'containers/Deal/AssignLenders/AssignLendersFunder'
import { UserContext } from 'contexts/userContext'

const AssignLendersPage: FC<AssignLendersProps> = (props) => {
  const { state } = useContext(UserContext)
  return state.roles.isFunder ? <AssignLendersFunderContainer {...props} /> : <AssignLendersContainer {...props} />
}

export default AssignLendersPage

import { Box, Paper } from '@material-ui/core'
import AddEditCompanyForm from 'components/AddEditCompanyForm'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import React from 'react'

const AddNewCompany = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  return (
    <Box className={additionalStyle.wrapper} mb="2rem">
      <Paper>
        <AddEditCompanyForm />
      </Paper>
    </Box>
  )
}

export default AddNewCompany

import { useContext, useCallback } from 'react'
import { UserContext } from 'contexts/userContext'

export const usePermission = () => {
  const userContext = useContext(UserContext)

  const hasPermission = useCallback(
    (perm: string) => {
      return userContext.state.permissions.includes(perm)
    },
    [userContext.state.permissions],
  )

  const isOwnVendor = useCallback(
    (id: number) => {
      return Boolean(userContext.state.vendor ? userContext.state.vendor.id === id : userContext.state.roles.isCollector)
    },
    [userContext.state.vendor],
  )

  const isOwnRep = useCallback(
    (id: number) => {
      return Boolean(userContext.state.rep ? userContext.state.rep.id === id : userContext.state.roles.isRep)
    },
    [userContext.state.rep],
  )

  const isOwnIso = useCallback(
    (id: number) => {
      return Boolean(userContext.state.iso ? +userContext.state.iso.id === id : userContext.state.roles.isISO)
    },
    [userContext.state.iso],
  )

  const isOwnSyndicator = useCallback(
    (id: number) => {
      return Boolean(userContext.state.syndicator ? +userContext.state.syndicator.id === id : userContext.state.roles.isSyndicator)
    },
    [userContext.state.syndicator],
  )

  return {
    hasPermission,
    isOwnVendor,
    isOwnRep,
    isOwnIso,
    isOwnSyndicator,
  }
}

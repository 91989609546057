import { Box, Grid, IconButton, Paper, TextField, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import cn from 'classnames'
import { DEAL_INFO_URL } from 'constants/routes'

import Button from 'UI/Button/ButtonWithPreloader'
import { convertDateWithTime } from 'utils/formatters'
import { usePaginationList } from 'hooks/usePaginationList'
import { ReactComponent as LoadMoreIcon } from 'assets/svg/LoadMoreIcon.svg'
import { getCommentsLog, newCommentLog } from 'services/deals'
import { ICommentLogItem } from 'typescript/interfaces/deals'
import { useMutation } from 'react-query'
import { useTableComponentStyle } from 'UI/Table/style'
import { useFormik } from 'formik'
import { useCommentsLogStyles } from './style'
import { schemaNewCommentLogForm } from './schema'

const DealCommentsLogContainer = () => {
  const { id } = useParams<{ id: string }>()
  const classes = useCommentsLogStyles()
  const tClasses = useTableComponentStyle()

  const { values, handleSubmit, handleChange, touched, errors, resetForm } = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: schemaNewCommentLogForm,
    onSubmit: (formValues) => {
      addNewComment({ dealId: +id, text: formValues.text })
    },
  })
  const { setPagination, handleFetchMore, pagination } = usePaginationList<ICommentLogItem>()

  const [getCommentsLogMut, { isLoading }] = useMutation(getCommentsLog, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [addNewComment, { isLoading: newCommentLoading }] = useMutation(newCommentLog, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [data.data, ...prevState.data],
      }))
      resetForm()
      const editorNode = document.querySelector('.ql-editor')
      if (editorNode) {
        editorNode.textContent = ''
      }
    },
  })

  useEffect(() => {
    getCommentsLogMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      DealId: id,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder, id])

  const { push } = useHistory()
  return (
    <Box>
      <Box display="flex" mb="2rem" alignItems="center">
        <Box mr="1.5rem">
          <IconButton onClick={() => push(generatePath(DEAL_INFO_URL, { id }))} color="inherit">
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography variant="h1" color="textSecondary">
          Audit Trail Deal #{id}
        </Typography>
      </Box>
      <Paper elevation={0}>
        <Box component="form" onSubmit={(e: any) => handleSubmit(e)} p="3rem">
          <Box mb="1.5rem">
            <Button loading={newCommentLoading} startIcon={<PlusIcon />} type="submit" variant="contained" color="primary">
              Add Comment
            </Button>
          </Box>
          <Box mt="2.5rem">
            <TextField
              variant="outlined"
              fullWidth
              name="text"
              onChange={handleChange}
              value={values.text}
              error={Boolean(touched.text && errors.text)}
              helperText={touched.text && errors.text}
              label="Comment"
              placeholder="Type your message"
              multiline
              rows={5}
            />
          </Box>

          <Box mt="2.5rem">
            {pagination.data.map((item, i) => (
              <Grid className={cn({ [classes.even]: i % 2 === 0, [classes.base]: true })} key={item.dateTime} container>
                <Grid item xs={3} md={2}>
                  {item.createdBy}
                </Grid>
                <Grid item xs={3} md={2}>
                  {convertDateWithTime(item.dateTime)}
                </Grid>
                <Grid item xs={6} md={8}>
                  {item.text}
                </Grid>
              </Grid>
            ))}
            {pagination.hasMore && Boolean(pagination.data.length) && (
              <Box width="100%" py={3}>
                <Button
                  loading={isLoading}
                  onClick={handleFetchMore}
                  variant="outlined"
                  color="secondary"
                  startIcon={<LoadMoreIcon />}
                  fullWidth
                  className={tClasses.loadMoreButton}
                >
                  Show more
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default DealCommentsLogContainer

import React, { FC, useState, useMemo, useContext } from 'react'
import Box from '@material-ui/core/Box'
import { useHeaderStyle } from 'components/Header/style'
import { ReactComponent as LogoHeader } from 'assets/svg/Logo.svg'
import { ReactComponent as DealIcon } from 'assets/svg/DealIcon.svg'
import { ReactComponent as NewClientIcon } from 'assets/svg/NewClientIcon.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import ChangeViewDashboardAction from 'components/ChangeViewDashboard'
import { generatePath, Route, useHistory, useLocation } from 'react-router-dom'
import { DASHBOARD_URL, NEW_DEAL_URL, NEW_CLIENT_URL, SIGN_IN_URL, BASE_APP_URL } from 'constants/routes'
import { ReactComponent as ProfileBlueIcon } from 'assets/svg/ProfileBlueIcon.svg'
import { MenuItem, Popover, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import useTheme from '@material-ui/core/styles/useTheme'
import Avatar from '@material-ui/core/Avatar'
import NotificationPopover from 'components/NotificationPopover'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import ModalComponent from 'UI/Modal'
import { useMutation, useQuery } from 'react-query'
import { privateInstance } from 'services/axios/privateInstance'
import { API_LOG_OUT } from 'constants/apiUrl'
import { JWT_TOKEN, REFRESH_TOKEN, USER_ROLE } from 'constants/localStorageKeys'
import { usePermission } from 'hooks/usePermission'
import {
  CLIENT_CREATE_UPDATE_VIEW,
  CREATE_CLIENT_DEAL,
  DASHBOARD_MODULE_PERM,
  NOTIFICATIONS_VIEW_PERM,
  NOTIFICATIONS_CREATE_PERM,
  CREATE_INHOUSE_OUTHOUSE_PERM,
} from 'constants/permissions'
import { RESET_USER_STORE } from 'constants/actionTypes'
import { UserContext } from 'contexts/userContext'
import { getCurrentUser } from 'services/users'
import { IRole } from 'typescript/interfaces/users'

const Header: FC = () => {
  const { hasPermission } = usePermission()
  const { dispatch, state } = useContext(UserContext)
  const [openLogout, setOpenLogout] = useState(false)
  const classes = useHeaderStyle({
    trigger: false,
  })
  const theme = useTheme()
  const { push, replace } = useHistory()
  const { pathname } = useLocation()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const [logOut] = useMutation(
    () => {
      replace(SIGN_IN_URL)
      dispatch({
        type: RESET_USER_STORE,
      })
      return privateInstance.post(API_LOG_OUT)
    },
    {
      onSettled() {
        localStorage.removeItem(USER_ROLE)
        localStorage.removeItem(JWT_TOKEN)
        localStorage.removeItem(REFRESH_TOKEN)
      },
    },
  )

  const urlNewDeal = useMemo(() => {
    if (hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM))
      return generatePath(NEW_DEAL_URL, {
        view: 'new',
        type: 'inhouse',
        step: '1',
      })
    else if (hasPermission(CREATE_CLIENT_DEAL))
      return generatePath(NEW_DEAL_URL, {
        view: 'new',
        type: 'client-deal',
        step: '1',
      })
    return ''
  }, [hasPermission])

  const { data } = useQuery(['currentUser', localStorage.getItem(JWT_TOKEN)], getCurrentUser, {
    refetchOnWindowFocus: false,
    retry: false,
  })

  const handleRoleChange = (role: IRole) => {
    localStorage.setItem(USER_ROLE, role.name)
    window.location.pathname = '/'
  }

  return (
    <Box className={classes.wrapper}>
      <Box
        className={classes.wrapperLogo}
        onClick={() => {
          if (!state.roles.isClient || (state.roles.isClient && state.client)) push(BASE_APP_URL)
        }}
      >
        <LogoHeader />
      </Box>
      {hasPermission(DASHBOARD_MODULE_PERM) && (
        <Route
          path={DASHBOARD_URL}
          render={(props) => (
            <Box ml={6}>
              <ChangeViewDashboardAction />
            </Box>
          )}
        />
      )}
      <Box ml={4} flex={1} display="flex" alignItems="center" justifyContent="flex-end">
        {(hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM) || (hasPermission(CREATE_CLIENT_DEAL) && state.roles.isClient && state.client)) && (
          <Box mr={3}>
            <Button
              onClick={() => push(urlNewDeal, { prevLink: pathname })}
              variant="contained"
              startIcon={<SvgIcon viewBox="0 0 24 24" htmlColor={theme.palette.text.primary} component={DealIcon} />}
            >
              New Deal
            </Button>
          </Box>
        )}
        {hasPermission(CLIENT_CREATE_UPDATE_VIEW) && (
          <Box mr={5}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SvgIcon viewBox="0 0 18 18" htmlColor={theme.palette.common.white} component={NewClientIcon} />}
              onClick={() => push(NEW_CLIENT_URL)}
            >
              New Client
            </Button>
          </Box>
        )}
        <Box display="flex" alignItems="center">
          {(hasPermission(NOTIFICATIONS_CREATE_PERM) || hasPermission(NOTIFICATIONS_VIEW_PERM)) && <NotificationPopover />}
          <Box ml={4}>
            <Box onClick={handleClick} style={{ cursor: 'pointer', paddingBottom: '1.5rem', paddingTop: '1.5rem' }}>
              <Avatar
                style={{
                  width: 36,
                  height: 36,
                }}
              />
            </Box>
            {(data?.data.userRoles.length || 0) > 1 && (
              <Popover
                classes={{ paper: classes.popover }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {data?.data.userRoles.map((role) => (
                  <MenuItem onClick={() => handleRoleChange(role)} key={role.id} className={classes.menuItem}>
                    <Box className={classes.iconContainer}>
                      <ProfileBlueIcon />
                    </Box>
                    <Typography variant="h4" color="textSecondary">
                      {role.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Popover>
            )}
          </Box>
        </Box>
      </Box>
      <ModalComponent open={openLogout} onClose={() => setOpenLogout(false)}>
        <ConfirmationContent handleCancel={() => setOpenLogout(false)} handleConfirm={logOut} text="Are you sure you want to log out?" />
      </ModalComponent>
    </Box>
  )
}

export default Header

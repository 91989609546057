import React, { FC } from 'react'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'

interface IIconButtonProps extends IconButtonProps {
  width?: string | number
  height?: string | number
}

const CustomIconButton: FC<IIconButtonProps> = ({ children, width, height, ...rest }) => {
  return (
    <IconButton
      {...rest}
      style={{
        width: width,
        minHeight: '1.1876em',
        paddingTop: 'calc(0.783rem - 1px)',
        paddingBottom: 'calc(0.783rem - 1px)',
      }}
    >
      {children}
    </IconButton>
  )
}

export default CustomIconButton

import React, { useRef } from 'react'
import { INotification } from 'typescript/interfaces/notifications'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { makeStyles, Typography } from '@material-ui/core'
import cn from 'classnames'
import TableComponent, { TableComponentType } from 'UI/Table'
import { format } from 'date-fns'

export const TableNotificationsSent = TableComponent as TableComponentType<INotification>

const useStyles = makeStyles((theme) => ({
  to: {
    width: '25%',
  },
  message: {
    width: '60%',
  },
  time: {
    width: '15%',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export const useNotificationSent = ({ onNotificationClick }: { onNotificationClick: (data: INotification) => void }) => {
  const classes = useTableComponentStyle()
  const s = useStyles()
  const columns = useRef<Array<IColumn<INotification>>>([
    {
      label: 'Date',
      field: 'date',
      sorting: true,
      bodyCellClassName: cn(classes.accentCell, s.time),
      headerCellClassName: s.time,
      render: (data) => (
        <Typography noWrap className={cn(classes.accentCell)}>
          {data.creationDate && format(new Date(data.creationDate), 'MM.dd.yyyy HH:mm')}
        </Typography>
      ),
    },
    {
      label: 'To',
      field: 'to',
      headerCellClassName: s.to,
      bodyCellClassName: cn(classes.accentCell),
      render: (data) => <Typography className={cn(classes.accentCell, s.ellipsis)}>{data.to}</Typography>,
    },
    {
      label: 'Message',
      field: 'Message',
      headerCellClassName: s.message,
      bodyCellClassName: cn(classes.accentCell),
      render: (data) => (
        <Typography onClick={() => onNotificationClick(data)} className={cn(classes.accentCell, s.ellipsis, s.hover)}>
          {data.shortMessage}
        </Typography>
      ),
    },
  ])

  return {
    columns: columns.current,
  }
}

import { Box, CircularProgress } from '@material-ui/core'
import { GoogleMap, StreetViewPanorama, useLoadScript } from '@react-google-maps/api'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import React, { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { getCoordsFromAddress } from 'services/maps'
import { IClientEntity } from 'typescript/interfaces/clients'

const containerStyle = {
  width: '100%',
  height: '418px',
}

interface IMapStreetViewProps {
  client?: IClientEntity
}

const MapStreetView = ({ client }: IMapStreetViewProps) => {
  const { isLoading, data } = useQuery(
    ['gmaps-address', client?.id],
    () =>
      getCoordsFromAddress({
        address: `${client?.mailingState?.name || ''}, ${client?.city || client?.mailingCity}, ${client?.street || client?.mailingStreet}, ${
          client?.zipCode || client?.mailingZipCode
        }`,
      }),
    { enabled: Boolean(client?.id) },
    // eslint-disable-next-line function-paren-newline
  )

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY!,
  })

  const { enqueueSnackbar } = useSnackbar()

  const center = useMemo(() => {
    return data?.data.results?.[0]?.geometry?.location
  }, [data])

  useEffect(() => {
    if (loadError) {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    }
  }, [loadError])

  return !isLoading && isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
      <StreetViewPanorama options={{ visible: true, position: center }} />
    </GoogleMap>
  ) : (
    <Box height="418px" mt="3rem" width="100%" bgcolor="#eee" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  )
}

export default MapStreetView

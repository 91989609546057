import { Box, CircularProgress, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { useStates } from 'hooks/useStates'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SelectComponent from 'UI/Select'
import RequiredOption from 'UI/Select/RequiredOption'
import { IBankCreateEdit } from 'typescript/interfaces/banks'
import { useMutation, useQuery } from 'react-query'
import { createBank, getBank, updateBank } from 'services/banks'
import Button from 'UI/Button/ButtonWithPreloader'
import { BANKS_URL } from 'constants/routes'
import { useSnackbar } from 'notistack'
import ReactInputMask from 'react-input-mask'
import { schemaBankForm } from './schema'

const NewBankForm = () => {
  const { id } = useParams<{ id: string }>()
  const { goBack, push } = useHistory()
  const { data: statesData } = useStates()

  const { enqueueSnackbar } = useSnackbar()

  const [createBankMut, { isLoading: isCreateLoading }] = useMutation(createBank, {
    onSuccess(data) {
      push(BANKS_URL)
      enqueueSnackbar('Successfully created bank')
    },
  })
  const [updateBankMut, { isLoading: isUpdateLoading }] = useMutation(updateBank, {
    onSuccess(data) {
      push(BANKS_URL)
      enqueueSnackbar('Successfully updated bank')
    },
  })

  const { isLoading } = useQuery(['bank', id], () => getBank(id), {
    onSuccess(data) {
      const { data: bankData } = data
      setValues({
        street: bankData.street,
        city: bankData.city,
        zipCode: bankData.zipCode,
        userPage: {
          id: bankData.userPage.id,
          bankName: bankData.userPage.bankName,
          bankAccount: bankData.userPage.bankAccount,
          bankRouting: bankData.userPage.bankRouting,
        },
        stateId: bankData.state.id,
      })
    },
    enabled: id !== 'new',
  })

  const { values, handleChange, setValues, handleSubmit, errors, touched, resetForm } = useFormik<IBankCreateEdit>({
    initialValues: {
      street: '',
      city: '',
      zipCode: '',
      userPage: {
        bankName: '',
        bankAccount: '',
        bankRouting: '',
      },
      stateId: null,
    },
    onSubmit: ({ ...formValues }) => {
      if (id) {
        updateBankMut({
          ...formValues,
          id: Number(id),
        })
      } else {
        createBankMut(formValues)
      }
    },
    validationSchema: schemaBankForm,
  })

  useEffect(() => {
    resetForm()
  }, [id])

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="calc(100vh - 98px - 2rem)">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box p="3rem">
            <Box mb="2rem">
              <Typography variant="h2" color="textSecondary">
                {id ? 'Edit Bank' : 'Add New Bank'}
              </Typography>
            </Box>
            <Grid spacing={8} container lg={8} xs={12}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.userPage?.bankName && !!errors.userPage?.bankName}
                  helperText={touched.userPage?.bankName && errors.userPage?.bankName}
                  value={values.userPage.bankName}
                  name="userPage.bankName"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="Bank Name" />}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.street && !!errors.street}
                  helperText={touched.street && errors.street}
                  value={values.street}
                  name="street"
                  onChange={handleChange}
                  fullWidth
                  label="Street"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                  value={values.city}
                  name="city"
                  onChange={handleChange}
                  fullWidth
                  label="City"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.zipCode && !!errors.zipCode}
                  helperText={touched.zipCode && errors.zipCode}
                  name="zipCode"
                  fullWidth
                  label="Zip Code"
                  value={values.zipCode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.userPage?.bankAccount && !!errors.userPage?.bankAccount}
                  helperText={touched.userPage?.bankAccount && errors.userPage?.bankAccount}
                  name="userPage.bankAccount"
                  fullWidth
                  label={<RequiredOption label="Bank Account #" />}
                  value={values.userPage?.bankAccount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <ReactInputMask
                  name="userPage.bankRouting"
                  mask="999999999"
                  maskPlaceholder={null}
                  value={values.userPage.bankRouting}
                  onChange={handleChange}
                >
                  {(props: any) => (
                    <TextField
                      variant="outlined"
                      error={!!touched.userPage?.bankRouting && !!errors.userPage?.bankRouting}
                      helperText={touched.userPage?.bankRouting && errors.userPage?.bankRouting}
                      fullWidth
                      label={<RequiredOption label="Bank Routing #" />}
                      {...props}
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={6}>
                <SelectComponent
                  error={!!touched.stateId && !!errors.stateId}
                  helperText={touched.stateId && errors.stateId}
                  value={values.stateId}
                  name="stateId"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="State" />}
                >
                  {statesData?.data.map((state) => (
                    <MenuItem value={state.id}>{state.name}</MenuItem>
                  ))}
                </SelectComponent>
              </Grid>
            </Grid>
            <Box mt="2rem" display="flex">
              <Box mr="1rem">
                <Button color="secondary" variant="contained" onClick={() => goBack()}>
                  Cancel
                </Button>
              </Box>
              <Button loading={isCreateLoading || isUpdateLoading} color="primary" variant="contained" type="submit">
                Complete
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default NewBankForm

import * as yup from 'yup'
import { ONLY_DIGITS, REQUIRED_FIELD } from 'constants/errors'
import { integerWithNegativeReg } from 'constants/regExp'

export const schemaDealPayment = yup.object().shape({
  scheduledFor: yup.string().nullable().required(REQUIRED_FIELD),
  amount: yup.string().required(REQUIRED_FIELD).matches(integerWithNegativeReg, ONLY_DIGITS),
  // achProvider: yup.string().required(REQUIRED_FIELD),
  bankAccountId: yup.string().required(REQUIRED_FIELD),
  status: yup.string().required(REQUIRED_FIELD),
  category: yup.string().required(REQUIRED_FIELD),
  paymentDate: yup.string().nullable(),
  comments: yup.string().nullable(),
  vendorId: yup.string().test('is-req', REQUIRED_FIELD, function (value) {
    const { category } = this.parent
    return category !== 'LegalCostVendor' || !!value
  }),
})

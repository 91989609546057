import React from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'
import { ReactComponent as EmailSentIcon } from 'assets/svg/EmailSent.svg'

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 24,
  },
  button: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 48,
    color: '#4C5878',
  },
  smallText: {
    fontSize: 19,
  },
}))

const EmailSent = () => {
  const s = useStyles()
  return (
    <Box width="520px" maxWidth="80%" display="flex" flexDirection="column" alignItems="center">
      <Typography className={s.text} align="center">
        Email has been sent!
      </Typography>
      <Typography className={s.smallText} align="center">
        The link for restoring your password has been sent to your email
      </Typography>
      <EmailSentIcon />
    </Box>
  )
}

export default EmailSent

import { differenceInCalendarDays, differenceInWeeks, differenceInYears, format } from 'date-fns'
import Numeral from 'numeral'

export const zeroInsteadNull = (value: any) => {
  if (value === null) return 0
  return value
}

export const formatterToUsPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

export const formatterToUsPriceWithoutCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const convertToPriceFormat = (value: any) => {
  if (value || value === 0) return formatterToUsPrice.format(value)
  return '$0'
}

export const convertToPriceFormatWithSign = (value: number) => {
  const isNegative = value < 0 || (value === 0 && 1 / value === -Infinity)
  if (value || value === 0) return `${isNegative ? '' : '+'}${formatterToUsPrice.format(value)}`
  return '$0'
}

export const convertToPriceFormatWithoutCents = (value: any) => {
  if (value || value === 0) return formatterToUsPriceWithoutCents.format(value)
  return '$0'
}

export const checkForNaN = (value: any) => {
  if (Number.isNaN(value)) return 'N/A'
  if (value || value === 0) return formatterToUsPrice.format(value)
  return '$0'
}

export const convertEmptyField = (value: any) => {
  if (!value && value !== 0) return 'n/a'
  return value
}

export const convertDate = (date?: any) => {
  if (date && date !== '0001-01-01T00:00:00+00:00') return format(new Date(date), 'MM.dd.yyyy')
  return 'n/a'
}

export const convertDateWithTime = (date?: any) => {
  if (date) return format(new Date(date), 'MM.dd.yyyy hh:mm aaa')
  return 'n/a'
}

export const converToTwoSignsAfterDot = (value?: number): number => {
  if (!value) return 0
  return Math.round(value * 100 + Number.EPSILON) / 100
}

export const convertNotifDate = (date?: any): string => {
  if (date) {
    if (differenceInCalendarDays(new Date(), new Date(date)) < 1) {
      return format(new Date(date), 'HH:mm')
    }

    if (differenceInCalendarDays(new Date(), new Date(date)) === 1) {
      return `Yesterday ${format(new Date(date), 'HH:mm')}`
    }

    if (differenceInWeeks(new Date(), new Date(date)) < 1) {
      return format(new Date(date), 'EEE HH:mm')
    }

    if (differenceInYears(new Date(), new Date(date)) < 1) {
      return format(new Date(date), 'MMM d HH:mm')
    }

    return format(new Date(date), 'dd.MM.yyyy HH:mm')
  }

  return 'n/a'
}

export const convertNotifBodyDate = (date?: any): string => {
  if (date) {
    if (differenceInYears(new Date(), new Date(date)) < 1) {
      return `${format(new Date(date), 'MMMM d')}      ${format(new Date(date), 'HH:mm')}`
    }

    return `${format(new Date(date), 'dd.MM.yyyy')}      ${format(new Date(date), 'HH:mm')}`
  }

  return 'n/a'
}

export const convertNotifSentBodyDate = (date?: any): string => {
  if (date) {
    return `${format(new Date(date), 'dd.MM.yyyy')} ${format(new Date(date), 'HH:mm')}`
  }

  return 'n/a '
}

export const formatBigNumber = (value?: number): string => {
  if (value) {
    return Numeral(value).format('$0,0').toUpperCase()
  }

  return convertToPriceFormat(value)
}

export const formatNegativePrice = (value: number, formatter: (val: any) => any | undefined = convertToPriceFormat): string => {
  return value >= 0 ? formatter(value) : `(${formatter(Math.abs(value))})`
}

export const roundNumber = (value?: number) => {
  if (value) return Math.round((value + Number.EPSILON) * 100) / 100
  return 0
}

export const camelCaseWrap = (text: string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1\u200B$2')
}

import React, { FC, useMemo } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete/Autocomplete'
import { useQuery } from 'react-query'
import { Value } from '@material-ui/lab'
import { Chip } from '@material-ui/core'
import { getUsersLight } from 'services/users'
import { IUserLight } from 'typescript/interfaces/users'
import { useMultipleAutocompleteStyles } from 'components/RolesAutoComplete'

interface IAutoCompleteProps {
  users: any[]
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void
  error?: boolean
  helperText?: string | false
  label?: string
}

const UsersAutoComplete: FC<IAutoCompleteProps> = ({ users, onChange, helperText, error, label = 'Users' }) => {
  const { data, isLoading } = useQuery(['users'], () => getUsersLight({ PageSize: 4000 }))
  const s = useMultipleAutocompleteStyles()

  const arrayUsers = useMemo(() => {
    return data ? data.data : []
  }, [data])

  return (
    <Autocomplete
      multiple
      value={users}
      onChange={onChange}
      options={arrayUsers}
      getOptionLabel={(option: IUserLight) => option.email}
      renderTags={(tagValue, getTagProps) => tagValue.map((option: IUserLight, index) => <Chip label={option.email} {...getTagProps({ index })} />)}
      classes={{ inputRoot: s.inputRoot }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            ref: params.InputProps.ref,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} />
              </>
            ),
          }}
          helperText={helperText}
          error={error}
          label={label}
          variant="outlined"
        />
      )}
    />
  )
}

export default UsersAutoComplete

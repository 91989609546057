import React from 'react'
import AuthContainer from 'containers/Auth'

interface Props {}

const Auth = (props: Props) => {
  return <AuthContainer />
}

export default Auth

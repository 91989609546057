import { SIGN_IN_URL } from 'constants/routes'
import React, { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'

import isAuthenticated from 'services/auth/isAuthenticated'

type Props = {
  component: FC<any>
  componentProps?: any
  exact?: boolean
  path?: string
  history?: any
}

export const PrivateRoute: FC<Props> = ({ component: Component, componentProps, exact, path }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (isAuthenticated() ? <Component {...props} {...componentProps} /> : <Redirect to={SIGN_IN_URL} />)}
    />
  )
}

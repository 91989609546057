import React from 'react'
import { useFormik } from 'formik'
import Button from 'UI/Button/ButtonWithPreloader'
import { TextField } from '@material-ui/core'
import RequiredOption from 'UI/Select/RequiredOption'
import ReactInputMask from 'react-input-mask'
import { schemaAddGuarantor } from './schema'

export interface Guarantor {
  id: number
  businessName: string
  ownerName: string
  ein: string
}

interface Props {
  initialValues: Guarantor
  guarantors: Guarantor[]
  name: string
  propsFormValues: any
  disabled: boolean | undefined
  setGuarantors: React.Dispatch<React.SetStateAction<Guarantor[]>>
  handleClosePopover: () => void
  classes: any
}

export const AddGuarantorForm = ({
  initialValues,
  propsFormValues,
  guarantors,
  setGuarantors,
  handleClosePopover,
  name,
  disabled,
  ...props
}: Props) => {
  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues,
    enableReinitialize: false,
    validationSchema: schemaAddGuarantor,
    onSubmit: (formValues) => {
      guarantors.push({
        businessName: formValues.businessName,
        ownerName: formValues.ownerName,
        ein: formValues.ein,
        id: Number(guarantors.length + 1),
      })
      if (guarantors) {
        setGuarantors(guarantors)
        handleClosePopover()
      }
    },
  })

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <form className={props.classes.container} onSubmit={handleSubmit}>
      <TextField
        {...props}
        variant="outlined"
        placeholder="Business name"
        InputLabelProps={{ shrink: true }}
        name="businessName"
        value={values.businessName}
        onChange={handleChange}
        label={<RequiredOption label="Business Name" />}
      />
      <TextField
        {...props}
        variant="outlined"
        placeholder="Owner Name"
        InputLabelProps={{ shrink: true }}
        name="ownerName"
        value={values.ownerName}
        onChange={handleChange}
        label={<RequiredOption label="Owner Name" />}
      />
      <ReactInputMask name="ein" mask="99-9999999" value={values.ein} onChange={handleChange}>
        {(propsEin: any) => (
          <TextField
            {...props}
            variant="outlined"
            placeholder="EIN"
            error={!!touched.ein && !!errors.ein}
            helperText={touched.ein && errors.ein}
            InputLabelProps={{ shrink: true }}
            name="ein"
            value={values.ein}
            onChange={handleChange}
            label={<RequiredOption label="EIN" />}
          />
        )}
      </ReactInputMask>
      <Button type="submit" color="primary" variant="contained">
        Add
      </Button>
    </form>
  )
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Box, Button, CircularProgress, FormControlLabel, Grid, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import ButtonWithPreloader from 'UI/Button/ButtonWithPreloader'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import SwitchExtended from 'UI/Switch/SwitchExtended'
import SelectComponent from 'UI/Select'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { ReactComponent as SplitIcon } from 'assets/svg/SplitIcon.svg'
import Checkbox from 'UI/Checkbox'
import { useMutation, useQuery } from 'react-query'
import { DEAL_INFO_URL, NEW_DEAL_URL } from 'constants/routes'
import { useSnackbar } from 'notistack'
import { createInhouseDeal, editInhouseDeal, getDeal, getFlexScheduleByDeal } from 'services/deals'
import { extendTypes, ICreateUpdateInhouseDeal, IExtendType } from 'typescript/interfaces/deals'
import { useFormik } from 'formik'
import ClientAutoComplete from 'components/ClientAutoComplete'
import ISOAutoComplete from 'components/ISOAutoComplete'
import { paymentFrequency } from 'constants/paymentFrequency'
import { CompanyType } from 'typescript/interfaces/companies'
import { getPercentage } from 'utils/getPercentage'
import { checkForNaN } from 'utils/formatters'
import { LENDER_DONT_HAVE_ENOUGHT_MONEY, SOMETHING_WENT_WRONG, SPLIT_COMMISSION_ERROR } from 'constants/errors'
import RepAutoComplete from 'components/RepAutoComplete'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'

import RequiredOption from 'UI/Select/RequiredOption'
import CompanyAutoComplete from 'components/CompanyAutoComplete'
import { getClientBankAccounts } from 'services/clients'
import { UserContext } from 'contexts/userContext'
import { AxiosError } from 'axios'
import { IRepListItemLight } from 'typescript/interfaces/rep'
import VendorAutoComplete from 'components/VendorAutoComplete'
import { DealsStatus } from 'typescript/enums/deals'
import { IVendorListLightItem } from 'typescript/interfaces/vendor'
import { usePermission } from 'hooks/usePermission'
import { ADD_VENDOR_TO_DEAL } from 'constants/permissions'
import { getRepLight } from 'services/rep'
import { IClientListItemLight } from 'typescript/interfaces/clients'
import { getStates } from 'services/states'
import { IIsoListItemLight } from 'typescript/interfaces/iso'
import SplitCommissionForm from './SplitCommissionForm'
import { schemaInHouseDeal } from './schema'
import EarlyDiscountForm from './EarlyDiscountForm'
import FlexDisbursementForm, { IFlexDisbursementForm } from './FlexDisbursementForm'
import SplitIsoCommissionForm from './SplitIsoCommissionForm'

interface Props {
  changeStepsAvailable: (steps: number[]) => void
}

interface InhouseDealForm extends ICreateUpdateInhouseDeal {
  clientState?: string
}

export const useStyles = makeStyles((theme) => ({
  paper: {
    flex: 1,
    marginRight: '1rem',
    padding: '3rem 3rem',
    '&:last-child': {
      marginRight: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    marginBottom: '2rem',
  },
  formContainer: {
    marginTop: '1.625rem',
  },
  heading: {
    fontSize: '2.25rem',
    color: theme.palette.text.secondary,
    marginBottom: '1rem',
  },
  switch: {
    marginBottom: '2.75rem',
  },
  selectLabel: {
    color: theme.palette.secondary.dark,
  },
  button: {
    marginRight: '1.25rem',
    background: theme.palette.info.light,
  },
  icon: {
    '& path': {
      fill: theme.palette.info.contrastText,
    },
  },
  checkboxLabel: {
    color: theme.palette.secondary.dark,
    fontSize: '1.0625rem',
    marginBottom: -3,
  },
  checkboxGrid: { paddingTop: '0 !important', paddingBottom: '0 !important', display: 'flex' },
  comission: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '1.0625rem',
    marginRight: '2rem',
  },
  root: {
    width: 300,
    padding: 20,
  },
  popoverFlex: {
    width: 550,
    padding: 20,
  },
  popoverTextField: {
    marginTop: 20,
    marginBottom: 20,
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const DealInformationForm = ({ changeStepsAvailable }: Props) => {
  const { hasPermission } = usePermission()
  const s = useStyles()
  const { push, goBack, location } = useHistory()
  const snack = useSnackbar()
  const { id, view } = useParams<{ id: string; view: 'new' | 'edit' }>()
  const params = new URLSearchParams(location.search)
  const clientId = params.get('clientId')
  const { state } = useContext(UserContext)

  const [isChangingView, setIsChangingView] = useState(false)
  const [isEnabledQuery, setIsEnabledQuery] = useState(true)

  const [anchorElForFlex, setAnchorElForFlex] = useState<HTMLButtonElement | null>(null)
  const [anchorElForFactor, setAnchorElForFactor] = useState<HTMLButtonElement | null>(null)
  const [anchorElForRep, setAnchorElForRep] = useState<HTMLButtonElement | null>(null)
  const [anchorElForIso, setAnchorElForIso] = useState<HTMLButtonElement | null>(null)
  const [anchorElForPSF, setAnchorElForPSF] = useState<HTMLButtonElement | null>(null)

  const [selectedIsos, setSelectedIsos] = useState<Array<{ id: number; name: string }>>([])
  const [selectedReps, setSelectedReps] = useState<any[]>([])

  const [representativeState, setRepresentativeState] = useState<any>(null)

  const [totalAdvance, setTotalAdvance] = useState<number | undefined>()

  const { isLoading, data } = useQuery(['inhouse-deal'], () => getDeal(id), {
    onSuccess(res) {
      const {
        data: {
          id: dealId,
          client,
          paymentInfo,
          advanceAmount,
          term,
          reverseTerm,
          uccUploaded,
          factor,
          frequentPayment,
          isos,
          representatives,
          comments,
          bankAccount,
          collected,
          sendAsWire,
          company,
          vendors,
          flexAmount,
          repSplitCommission,
          psfFeeSplitCommission,
          extendType,
        },
      } = res

      if (isos?.length) {
        setSelectedIsos([...selectedIsos.map((x) => ({ id: x.id, name: x.name })), ...isos.map((x) => ({ id: x.id, name: x.businessName ?? '' }))])
      }

      if (representatives?.length) {
        setSelectedReps([...selectedReps, ...representatives.filter((el) => el.id !== 1)])
      }

      setValues({
        id: dealId,
        clientId: client?.id,
        advanceAmount,
        term,
        reverseTerm,
        factor,
        uccUploaded: Boolean(uccUploaded),
        frequentPayment,
        isoIds: isos.length > 0 ? isos.map((item) => item.id) : undefined,
        representativeIds: representatives && representatives.length > 0 ? representatives.map((item) => item.id) : undefined,
        paymentFrequency: paymentInfo.paymentFrequency,
        disbursementFrequency: paymentInfo.disbursementFrequency,
        paybackAmount: paymentInfo.payback,
        commissionToRep: paymentInfo.commissionToRep,
        repSplitCommission: repSplitCommission,
        isoSplitCommission: isos.map((x) => ({ id: x.id, value: x.percentageValue })),
        psfFeeSplitCommission: psfFeeSplitCommission,
        isoFee: paymentInfo.isoFee,
        psfFee: paymentInfo.psfFee,
        collected: collected,
        syndicationFee: paymentInfo.syndicationFee,
        payOffAmount: paymentInfo.payOffAmount,
        comments: comments ?? '',
        commissionSplit: paymentInfo.commissionSplit,
        sendAsWire,
        bankAccountId: bankAccount.id,
        companyId: company?.id,
        vendorIds: vendors?.map((v) => v.id),
        flexAmount: flexAmount,
        isFlexDeal: flexAmount != null,
        earlyPayOffAmount: paymentInfo.earlyPayOffAmount,
        earlyDiscount: paymentInfo.earlyDiscount,
        extendType: extendType,
        specifiedPercentage: paymentInfo.specifiedPercentage ?? undefined,
        apr: paymentInfo.apr ?? undefined,
        monthlyRevenue: paymentInfo.monthlyRevenue ?? undefined,
        clientState: undefined,
        flexSchedule: values.flexSchedule ?? undefined,
      })
      setIsEnabledQuery(false)
    },
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
    cacheTime: 0,
    enabled: id && isEnabledQuery,
  })

  useQuery(['rep'], () => getRepLight({ PageSize: 4000 }), {
    onSuccess(response) {
      const houseRep = response.data.find((rep) => rep.id === 1)

      if (selectedReps.find((el) => el?.id === houseRep?.id)) return

      setSelectedReps([...selectedReps, houseRep])
      setRepresentativeState(houseRep)
    },
  })

  const handleClickPopoverFlex = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElForFlex(event.currentTarget)
  }

  const repositionPopoverFlex = () => {
    const anchor = anchorElForFlex
    setAnchorElForFlex(null)
    setTimeout(() => {
      setAnchorElForFlex(anchor)
    }, 0)
  }

  const handleClickPopoverFactor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElForFactor(event.currentTarget)
  }

  const handleClickPopoverRep = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElForRep(event.currentTarget)
  }

  const handleClickPopoverIso = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElForIso(event.currentTarget)
  }

  const handleClickPopoverPSF = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElForPSF(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorElForFlex(null)
    setAnchorElForFactor(null)
    setAnchorElForRep(null)
    setAnchorElForIso(null)
    setAnchorElForPSF(null)
  }

  const isOpenPopoverFlex = Boolean(anchorElForFlex)
  const isOpenPopoverFactor = Boolean(anchorElForFactor)
  const isOpenPopoverRep = Boolean(anchorElForRep)
  const isOpenPopoverIso = Boolean(anchorElForIso)
  const isOpenPopoverPSF = Boolean(anchorElForPSF)

  const isApprovedDeal = useMemo(() => {
    return Boolean(data?.data.status === DealsStatus.approved)
  }, [data])

  const isDisabledToEdit = (data?.data.status === 'Declined' || data?.data.status === 'Approved') && !state.roles.isAdmin

  const isDisabledSplitCommission = !(state.roles.isFunder || state.roles.isAdmin)

  const isDisabledEarlyPayoff = !isApprovedDeal || isDisabledSplitCommission

  const [mutateCreateDeal, { isLoading: isCreateLoading }] = useMutation(createInhouseDeal, {
    onSuccess(res) {
      changeStepsAvailable([0, 1, 2])
      push(
        generatePath(NEW_DEAL_URL, {
          id: res.data.id,
          view: 'edit',
          type: 'inhouse',
          step: 2,
        }),
      )
      snack.enqueueSnackbar(<Typography>Successfully created deal</Typography>)
    },
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
  })

  const [mutateUpdateDeal, { isLoading: isUpdateLoading }] = useMutation(editInhouseDeal, {
    onSuccess(res) {
      if (isApprovedDeal) {
        push(
          generatePath(DEAL_INFO_URL, {
            id: id,
          }),
        )
      } else
        push(
          generatePath(NEW_DEAL_URL, {
            id: id,
            view: 'edit',
            type: 'inhouse',
            step: 2,
          }),
        )
      snack.enqueueSnackbar('Successfully updated deal')
    },
    onError(err: AxiosError) {
      if (err.response && err.response.data.description === 'INVALID_ADVANCE_AMOUNT_VALUE' && data?.data.mainDealId)
        snack.enqueueSnackbar(`Advance amount can't be less than the remaining balance in deal #${data.data.mainDealId}`)
      else if (err.response?.data.description === 'NET_AMOUNT_IS_NEGATIVE') snack.enqueueSnackbar('Net amount is negative')
      else if (err.response?.data.description === 'INVALID_FUND_AMOUNT') snack.enqueueSnackbar(LENDER_DONT_HAVE_ENOUGHT_MONEY)
      else if (err.response?.data.description === 'INVALID_SPLIT_COMMISSION_VALUE') snack.enqueueSnackbar(SPLIT_COMMISSION_ERROR)
      else snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldTouched, setValues, resetForm } = useFormik<InhouseDealForm>({
    validationSchema: schemaInHouseDeal,
    initialValues: {
      companyId: undefined,
      id: undefined,
      clientId: parseInt(clientId || '') || undefined,
      paymentFrequency: '',
      disbursementFrequency: undefined,
      advanceAmount: undefined,
      term: undefined,
      reverseTerm: undefined,
      uccUploaded: true,
      factor: undefined,
      frequentPayment: undefined,
      paybackAmount: undefined,
      isoIds: undefined,
      commissionToRep: undefined,
      isoFee: undefined,
      commissionSplit: undefined,
      repSplitCommission: undefined,
      isoSplitCommission: [],
      psfFeeSplitCommission: undefined,
      representativeIds: undefined,
      psfFee: undefined,
      syndicationFee: undefined,
      payOffAmount: undefined,
      sendAsWire: undefined,
      comments: '',
      bankAccountId: undefined,
      vendorIds: undefined,
      flexAmount: undefined,
      flexSchedule: undefined,
      isFlexDeal: false,
      earlyPayOffAmount: undefined,
      earlyDiscount: undefined,
      specifiedPercentage: undefined,
      apr: undefined,
      monthlyRevenue: undefined,
      clientState: undefined,
    },
    enableReinitialize: false,
    onSubmit: (formValues) => {
      const transformedValues = Object.entries(formValues).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            value ||
            ((key === 'syndicationFee' ||
              key === 'payOffAmount' ||
              key === 'earlyPayOffAmount' ||
              key === 'psfFee' ||
              key === 'isoFee' ||
              key === 'commissionToRep') &&
              '0') ||
            (key === 'comments' && '') ||
            null,
          uccUploaded: formValues.uccUploaded,
          sendAsWire: formValues.sendAsWire,
          flexAmount: formValues.isFlexDeal ? formValues.flexAmount : null,
        }),
        {},
      ) as ICreateUpdateInhouseDeal

      if (id) {
        mutateUpdateDeal(transformedValues)
      } else {
        mutateCreateDeal(transformedValues)
      }
    },
  })

  const getInitialValuesForIsoCommission = useCallback((): { [key: number]: number } => {
    const initialValues: { [key: number]: number } = {}

    if (values.isoSplitCommission.length === 0) {
      const totalIsos = selectedIsos.length
      if (totalIsos > 0) {
        const baseValue = Math.floor(100 / totalIsos)
        let remaining = 100 - baseValue * totalIsos

        selectedIsos.forEach((iso, _index) => {
          initialValues[iso.id] = baseValue + (remaining > 0 ? 1 : 0)
          remaining -= 1
        })
      }
    } else {
      selectedIsos.forEach((iso) => {
        const match = values.isoSplitCommission.find((x) => x.id === iso.id)
        initialValues[iso.id] = match ? match.value : 0
      })
    }

    return initialValues
  }, [selectedIsos, values.isoSplitCommission])

  const getInitialValuesForCommission = useCallback(
    (name: 'repSplitCommission' | 'psfFeeSplitCommission') => {
      if (!selectedReps.length) return

      const submittedCommissionIds = values?.[name]?.map((el) => el?.id)

      const initialValues = selectedReps.reduce<any>((acc, value, index, array) => {
        const accKey = `${value?.id}`
        const newIndex = values?.[name]?.findIndex((el) => el.id === value?.id)

        const splitCommissionValue =
          submittedCommissionIds?.length && !submittedCommissionIds.includes(value?.id)
            ? 0
            : array.length === 1
            ? 100
            : (newIndex === 0 || newIndex) && (values?.[name]?.[newIndex]?.value || values?.[name]?.[newIndex]?.value === 0)
            ? values?.[name]?.[newIndex]?.value
            : value?.id === 1
            ? 50
            : Math.floor((100 - 50) / (array.length - 1))

        return { ...acc, [accKey]: splitCommissionValue }
      }, {})

      const restCommission = 100 - Object.values(initialValues).reduce((acc: number, el) => acc + Number(el), 0)

      if (restCommission > 0 && selectedReps.length > 1) {
        initialValues[selectedReps[1]?.id] = Number(initialValues[selectedReps[1]?.id]) + restCommission
      }

      return initialValues
    },
    [selectedReps, values],
  )

  useQuery(['flexSchedule', id], () => getFlexScheduleByDeal(id), {
    onSuccess({ data: flexSchedule }) {
      setFieldValue('flexSchedule', [...flexSchedule])
    },
    retry: false,
    cacheTime: 0,
    enabled: id,
  })

  const flexDisbursementFormValues: IFlexDisbursementForm = useMemo(() => {
    return {
      schedule: values.flexSchedule ?? [],
      advanceAmount: values.advanceAmount || 0,
      flexAmount: values.flexAmount || 0,
    }
  }, [values.flexSchedule, values.advanceAmount, values.flexAmount])

  const { data: statesData } = useQuery('states', getStates, {
    onSuccess(res) {
      if (data?.data?.client?.phisicalStateId) {
        setFieldValue('clientState', res.data?.find((x) => x.id === data?.data?.client?.phisicalStateId)?.abbreviation)
      }
    },
    retry: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    enabled: view === 'new' || Boolean(data?.data?.client?.phisicalStateId),
  })

  const { data: dataBankAccounts } = useQuery(
    ['client-bank-accounts', values.clientId],
    () =>
      getClientBankAccounts({
        clientId: values.clientId ? values.clientId : 0,
      }),
    {
      cacheTime: 0,
      retry: false,
      enabled: values.clientId,
    },
  )

  const generateCommissionData = (name: 'repSplitCommission' | 'isoSplitCommission' | 'psfFeeSplitCommission') => {
    const commissionData = []
    const initialValuesForCommission = name === 'isoSplitCommission' ? getInitialValuesForIsoCommission() : getInitialValuesForCommission(name)

    if (!initialValuesForCommission) return

    for (const [key, value] of Object.entries(initialValuesForCommission)) {
      const numberKey = Number(key)
      const numberValue = Number(value)

      commissionData.push({ id: numberKey, value: numberValue })
    }

    return commissionData
  }

  const getTotalAdvance = (advanceAmount?: number, extendType?: IExtendType, syndicationFee?: number) => {
    if (extendType === 'Reverse' && advanceAmount !== undefined) {
      return Number(advanceAmount) + Number(syndicationFee || 0)
    } else if (advanceAmount !== undefined) {
      return Number(advanceAmount)
    } else {
      return advanceAmount
    }
  }

  useEffect(() => {
    if (!values.isFlexDeal) {
      setFieldValue('flexAmount', '')
      setFieldValue('extendType', '')
    }
    if (totalAdvance !== undefined && values.factor !== undefined) {
      setFieldValue('paybackAmount', Math.ceil(totalAdvance * Number(values.factor || 0)))
    }
    if (totalAdvance !== undefined && values.term !== undefined) {
      setFieldValue('frequentPayment', Math.ceil((totalAdvance * Number(values.factor || 0)) / Number(values.term || 1)))
    }
  }, [values.isFlexDeal, values.flexAmount, totalAdvance, values.factor, values.term])

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { advanceAmount, reverseTerm, extendType } = values
      if (extendType === 'Reverse') {
        setFieldValue('flexAmount', advanceAmount && reverseTerm ? Math.ceil(Number(advanceAmount) / Number(reverseTerm)) : '')
      }
    }, 400)
    return () => clearTimeout(timeout)
  }, [values.reverseTerm, values.advanceAmount, values.extendType])

  useEffect(() => {
    const timeout = setTimeout(() => setTotalAdvance(getTotalAdvance(values.advanceAmount, values.extendType, values.syndicationFee)), 400)
    return () => clearTimeout(timeout)
  }, [values.advanceAmount, values.extendType, values.syndicationFee])

  useEffect(() => {
    if (touched.advanceAmount || (touched.syndicationFee && values.extendType === 'Reverse')) {
      setFieldValue('uccUploaded', Number(totalAdvance || 0) >= 20000)
    }
  }, [totalAdvance])

  useEffect(() => {
    setIsChangingView(true)
    resetForm()
    setTimeout(() => {
      setIsChangingView(false)
    }, 10)
  }, [view])

  const totalCommission = useMemo(() => {
    return Number(values.isoFee || 0) + Number(values.commissionToRep || 0)
  }, [values.isoFee, values.commissionToRep])

  if (isChangingView) {
    return null
  }

  if (isLoading) {
    return (
      <Box display="flex" height="400px" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  if (data && data.data.type === CompanyType.OutHouse)
    push(
      generatePath(DEAL_INFO_URL, {
        id: id,
      }),
    )

  return (
    <Box
      display="flex"
      component="form"
      onSubmit={(e: any) => {
        setFieldValue('repSplitCommission', generateCommissionData('repSplitCommission'))
        setFieldValue('psfFeeSplitCommission', generateCommissionData('psfFeeSplitCommission'))
        setFieldValue('isoSplitCommission', generateCommissionData('isoSplitCommission'))

        handleSubmit(e)
      }}
      className={s.formContainer}
    >
      <Paper className={s.paper}>
        <Typography className={s.heading}>{id ? 'Edit deal' : 'Add New Deal'}</Typography>
        {view === 'new' && (
          <SwitchExtended
            variants={[
              { title: 'Inhouse Deal', value: 'inhouse' },
              { title: 'Outhouse Deal', value: 'outhouse' },
            ]}
            value="inhouse"
            className={s.switch}
            onClick={() =>
              push({
                pathname: '/deal/new/outhouse/1',
                search: location.search,
              })
            }
          />
        )}

        <Grid container spacing={8}>
          <Grid item xs={6}>
            <ClientAutoComplete
              disabled={isDisabledToEdit}
              label={<RequiredOption label="Choose the client" />}
              error={Boolean(touched.clientId && errors.clientId)}
              helperText={touched.clientId && errors.clientId}
              clientId={values.clientId}
              onChange={(e, value: IClientListItemLight) => {
                if (value) handleChange({ target: { name: 'clientId', value: value.id } })
                else handleChange({ target: { name: 'clientId', value: '' } })
                setFieldValue('bankAccountId', '')
                setFieldValue('clientState', statesData?.data?.find((x) => x.id === value?.physicalStateId)?.abbreviation)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CompanyAutoComplete
              disabled={isDisabledToEdit}
              label={<RequiredOption label="Company" />}
              error={Boolean(touched.companyId && errors.companyId)}
              helperText={touched.companyId && errors.companyId}
              onChange={(e, value) => {
                if (value) setFieldValue('companyId', value.id)
                else {
                  setFieldValue('companyId', undefined)
                }
              }}
              companyId={values.companyId}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectComponent
              disabled={isDisabledToEdit || isApprovedDeal}
              name="paymentFrequency"
              label={<RequiredOption label="Payment Frequency" />}
              onChange={handleChange}
              labelProps={{ className: s.selectLabel }}
              fullWidth
              error={Boolean(touched.paymentFrequency && errors.paymentFrequency)}
              helperText={touched.paymentFrequency && errors.paymentFrequency}
              value={values.paymentFrequency}
            >
              {paymentFrequency.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={isDisabledToEdit || isApprovedDeal}
              variant="outlined"
              fullWidth
              name="advanceAmount"
              onChange={(e) => {
                handleChange(e)
                if (!touched.advanceAmount) setFieldTouched('advanceAmount')
              }}
              value={values.advanceAmount}
              error={Boolean(touched.advanceAmount && errors.advanceAmount)}
              helperText={
                (touched.advanceAmount && errors.advanceAmount) || values.extendType !== 'Reverse' || !values.syndicationFee || !totalAdvance
                  ? touched.advanceAmount && errors.advanceAmount
                  : `Total Advance Amount: ${totalAdvance}`
              }
              label={<RequiredOption label="Advance Amount" />}
            />
          </Grid>
          <Grid item xs={3} className={s.checkboxGrid}>
            <FormControlLabel
              disabled={isDisabledToEdit}
              classes={{
                label: s.checkboxLabel,
              }}
              name="uccUploaded"
              onChange={handleChange}
              label="UCC uploaded"
              control={<Checkbox disabled={isDisabledToEdit} checked={values.uccUploaded} color="primary" name="uccUploaded" />}
            />
          </Grid>
          <Grid item xs={3} className={s.checkboxGrid}>
            <FormControlLabel
              disabled={isDisabledToEdit || isApprovedDeal}
              classes={{
                label: s.checkboxLabel,
              }}
              name="isFlexDeal"
              onChange={(e) => {
                handleChange(e)
                if (!e) {
                  setFieldValue('extendType', '')
                }
              }}
              label="Flex/Reverse Deal"
              control={<Checkbox checked={values.isFlexDeal} color="primary" name="isFlexDeal" />}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectComponent
              disabled={!values.isFlexDeal || isDisabledToEdit || isApprovedDeal}
              name="extendType"
              label={<RequiredOption label="Flex Type" />}
              onChange={handleChange}
              labelProps={{ className: s.selectLabel }}
              fullWidth
              error={Boolean(touched.extendType && errors.extendType)}
              helperText={touched.extendType && errors.extendType}
              value={values.extendType}
            >
              {extendTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
          {values.extendType === 'Reverse' && (
            <>
              <Grid item xs={6}>
                <SelectComponent
                  disabled={isDisabledToEdit || isApprovedDeal}
                  name="disbursementFrequency"
                  label={<RequiredOption label="Disbursement Frequency" />}
                  onChange={handleChange}
                  labelProps={{ className: s.selectLabel }}
                  fullWidth
                  error={Boolean(touched.disbursementFrequency && errors.disbursementFrequency)}
                  helperText={touched.disbursementFrequency && errors.disbursementFrequency}
                  value={values.disbursementFrequency ?? ''}
                >
                  {paymentFrequency.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </SelectComponent>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={isDisabledToEdit || isApprovedDeal}
                  variant="outlined"
                  fullWidth
                  name="reverseTerm"
                  onChange={handleChange}
                  value={values.reverseTerm ?? ''}
                  error={Boolean(touched.reverseTerm && errors.reverseTerm)}
                  helperText={touched.reverseTerm && errors.reverseTerm}
                  label={<RequiredOption label="Reverse Term" />}
                />
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              disabled={!values.isFlexDeal || isApprovedDeal || values.extendType === 'Reverse'}
              name="flexAmount"
              onChange={handleChange}
              value={values.flexAmount ?? ''}
              error={Boolean(touched.flexAmount && errors.flexAmount)}
              helperText={touched.flexAmount && errors.flexAmount}
              label={<RequiredOption label="Flex/Reverse Amount" />}
              InputProps={{
                endAdornment:
                  values.extendType === 'Flex' ? (
                    <InputAdornment position="end">
                      <IconButton disabled={!values.flexAmount} onClick={handleClickPopoverFlex}>
                        <SplitIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
            />
            <Popover
              open={isOpenPopoverFlex}
              anchorEl={anchorElForFlex}
              onClose={handleClosePopover}
              PaperProps={{ classes: { root: s.popoverFlex } }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={s.popoverContainer}>
                <FlexDisbursementForm
                  classes={{ root: s.popoverTextField }}
                  initialValues={flexDisbursementFormValues}
                  firstDisbursement={{ id: -1, date: data?.data.startDate, amount: values.flexAmount }}
                  onResize={() => repositionPopoverFlex()}
                  onSubmit={({ schedule }) => {
                    handleClosePopover()
                    setFieldValue('flexSchedule', schedule)
                  }}
                />
              </div>
            </Popover>
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled={isDisabledToEdit}
              variant="outlined"
              fullWidth
              name="term"
              onChange={handleChange}
              value={values.term}
              error={Boolean(touched.term && errors.term)}
              helperText={touched.term && errors.term}
              label={<RequiredOption label="Term" />}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled={isDisabledToEdit}
              variant="outlined"
              fullWidth
              name="factor"
              onChange={handleChange}
              value={values.factor}
              error={Boolean(touched.factor && errors.factor)}
              helperText={touched.factor && errors.factor}
              label={<RequiredOption label="Factor rate" />}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled={!values.factor} onClick={handleClickPopoverFactor}>
                      <SplitIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Popover
              open={isOpenPopoverFactor}
              anchorEl={anchorElForFactor}
              onClose={handleClosePopover}
              PaperProps={{ classes: { root: s.root } }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={s.popoverContainer}>
                <EarlyDiscountForm
                  classes={{ root: s.popoverTextField }}
                  initialValues={values.earlyDiscount}
                  onSubmit={(earlyDiscount) => {
                    handleClosePopover()
                    setFieldValue('earlyDiscount', earlyDiscount)
                  }}
                />
              </div>
            </Popover>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              disabled
              InputLabelProps={{ shrink: values.frequentPayment !== undefined }}
              name="frequentPayment"
              onChange={handleChange}
              value={values.frequentPayment}
              error={Boolean(touched.frequentPayment && errors.frequentPayment)}
              helperText={touched.frequentPayment && errors.frequentPayment}
              label="Daily Payment"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              disabled
              name="paybackAmount"
              InputLabelProps={{ shrink: values.paybackAmount !== undefined }}
              onChange={handleChange}
              value={values.paybackAmount}
              error={Boolean(touched.paybackAmount && errors.paybackAmount)}
              helperText={
                (touched.paybackAmount && errors.paybackAmount) || Boolean(!values.earlyPayOffAmount)
                  ? touched.paybackAmount && errors.paybackAmount
                  : `Total Payback Amount: ${Number(values?.paybackAmount) - Number(values?.earlyPayOffAmount)}`
              }
              label="Payback Amount"
            />
          </Grid>
          <Grid item xs={6}>
            <ISOAutoComplete
              multiple
              disabled={isDisabledToEdit}
              label="ISO"
              error={Boolean(touched.isoIds && errors.isoIds)}
              helperText={touched.isoIds && errors.isoIds}
              isoId={values.isoIds}
              onChange={(_e, value: IIsoListItemLight[]) => {
                if (value.length) {
                  handleChange({ target: { name: 'isoIds', value: value?.map((v: any) => v.id) } })
                  setSelectedIsos(value.map((x) => ({ id: x.id, name: x.businessName })))
                  if (value.length < 2) {
                    setFieldValue('isoSplitCommission', [])
                  }
                } else {
                  handleChange({ target: { name: 'isoIds', value: undefined } })
                  setFieldValue('isoFee', '')
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              disabled={isDisabledEarlyPayoff}
              name="earlyPayOffAmount"
              onChange={handleChange}
              value={values.earlyPayOffAmount}
              error={Boolean(touched.earlyPayOffAmount && errors.earlyPayOffAmount)}
              helperText={touched.earlyPayOffAmount && errors.earlyPayOffAmount}
              label="Early PayOff Amount"
            />
          </Grid>
          <Grid item xs={6}>
            {!state.roles.isISO && (
              <>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="commissionToRep"
                  onChange={handleChange}
                  value={values.commissionToRep ?? ''}
                  disabled={!values.representativeIds || isDisabledToEdit}
                  error={Boolean(touched.commissionToRep && errors.commissionToRep)}
                  helperText={
                    touched.commissionToRep && errors.commissionToRep
                      ? touched.commissionToRep && errors.commissionToRep
                      : `${getPercentage(values?.advanceAmount, values?.commissionToRep)}% of 
                      advance amount`
                  }
                  label="Commission To Rep"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!values.commissionToRep || isDisabledSplitCommission}
                          aria-label="Split commission to Rep's"
                          onClick={handleClickPopoverRep}
                        >
                          <SplitIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Popover
                  open={isOpenPopoverRep}
                  anchorEl={anchorElForRep}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  PaperProps={{ classes: { root: s.root } }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className={s.popoverContainer}>
                    <SplitCommissionForm
                      key="repSplitCommission"
                      handleClosePopover={handleClosePopover}
                      propsSetFieldValue={setFieldValue}
                      propsFormValues={values}
                      initialValues={getInitialValuesForCommission('repSplitCommission')}
                      selectedReps={selectedReps}
                      disabled={isDisabledToEdit}
                      classes={{ root: s.popoverTextField }}
                      name="repSplitCommission"
                    />
                  </div>
                </Popover>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="isoFee"
              onChange={handleChange}
              // @ts-ignore
              value={values.isoFee ?? ''}
              error={Boolean(touched.isoFee && errors.isoFee) || Boolean(errors.isoSplitCommission)}
              helperText={
                touched.isoFee && errors.isoFee
                  ? touched.isoFee && errors.isoFee
                  : errors.isoSplitCommission
                  ? errors.isoSplitCommission
                  : `${getPercentage(values?.advanceAmount, values?.isoFee)}% of advance amount`
              }
              label="ISO Fee"
              disabled={!values.isoIds || isDisabledToEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!values.isoFee || !values.isoIds || values.isoIds.length < 2}
                      aria-label="Split commission to Iso's"
                      onClick={handleClickPopoverIso}
                    >
                      <SplitIcon height={60} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Popover
              open={isOpenPopoverIso}
              anchorEl={anchorElForIso}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              PaperProps={{ classes: { root: s.root } }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={s.popoverContainer}>
                <SplitIsoCommissionForm
                  key="isoSplitCommission"
                  handleClosePopover={handleClosePopover}
                  propsSetFieldValue={setFieldValue}
                  propsFormValues={values}
                  initialValues={getInitialValuesForIsoCommission()}
                  selectedIsos={selectedIsos}
                  disabled={isDisabledToEdit}
                  classes={{ root: s.popoverTextField }}
                  name="isoSplitCommission"
                />
              </div>
            </Popover>
          </Grid>
          <Grid item container justify="flex-start" xs={6}>
            <Typography className={s.comission}>Total Commission:</Typography>
            <Typography className={s.comission}>{checkForNaN(totalCommission)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {getPercentage(values?.advanceAmount, totalCommission)}
              {`% of advance amount`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {!state.roles.isISO && (
              <RepAutoComplete
                multiple
                disabled={isDisabledToEdit}
                isHouseHidden
                label="REP"
                error={Boolean(touched.representativeIds && errors.representativeIds)}
                helperText={touched.representativeIds && errors.representativeIds}
                repId={values.representativeIds}
                onChange={(e, value) => {
                  if (value && value.length > 0) {
                    handleChange({
                      target: {
                        name: 'representativeIds',
                        value: value.map((v: IRepListItemLight) => v.id),
                      },
                    })
                    if (representativeState) {
                      setSelectedReps([representativeState, ...value.filter((v: IRepListItemLight) => v.id !== 1)])
                    }
                  } else {
                    handleChange({ target: { name: 'representativeIds', value: undefined } })
                    setFieldValue('commissionToRep', 0)
                    setSelectedReps([{ id: 1, value: 100 }])
                  }
                }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <VendorAutoComplete
              multiple
              vendorId={values.vendorIds}
              disabled={data?.data.status !== DealsStatus.approved || !hasPermission(ADD_VENDOR_TO_DEAL)}
              label="Vendors"
              error={Boolean(touched.vendorIds && errors.vendorIds)}
              helperText={touched.vendorIds && errors.vendorIds}
              onChange={(e, value) => {
                if (value)
                  handleChange({
                    target: {
                      name: 'vendorIds',
                      value: value.map((v: IVendorListLightItem) => v.id),
                    },
                  })
                else {
                  handleChange({ target: { name: 'vendorIds', value: undefined } })
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={isDisabledToEdit}
              variant="outlined"
              fullWidth
              name="psfFee"
              onChange={handleChange}
              value={values.psfFee}
              error={Boolean(touched.psfFee && errors.psfFee)}
              helperText={touched.psfFee && errors.psfFee}
              label="PSF Fee"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!values.psfFee || !selectedReps.length || isDisabledSplitCommission}
                      aria-label="Split PSF Fee to Rep's"
                      onClick={handleClickPopoverPSF}
                    >
                      <SplitIcon height={60} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Popover
              open={isOpenPopoverPSF}
              anchorEl={anchorElForPSF}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              PaperProps={{ classes: { root: s.root } }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={s.popoverContainer}>
                <SplitCommissionForm
                  key="psfFeeSplitCommission"
                  handleClosePopover={handleClosePopover}
                  propsSetFieldValue={setFieldValue}
                  propsFormValues={values}
                  initialValues={getInitialValuesForCommission('psfFeeSplitCommission')}
                  selectedReps={selectedReps}
                  disabled={isDisabledToEdit}
                  classes={{ root: s.popoverTextField }}
                  name="psfFeeSplitCommission"
                />
              </div>
            </Popover>
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={isDisabledToEdit || (isApprovedDeal && values.extendType === 'Reverse')}
              variant="outlined"
              fullWidth
              name="syndicationFee"
              onChange={(e) => {
                handleChange(e)
                if (!touched.syndicationFee) setFieldTouched('syndicationFee')
              }}
              value={values.syndicationFee ?? ''}
              error={Boolean(touched.syndicationFee && errors.syndicationFee)}
              helperText={touched.syndicationFee && errors.syndicationFee}
              label="Bank Fee"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              name="payOffAmount"
              onChange={handleChange}
              value={values.payOffAmount}
              error={Boolean(touched.payOffAmount && errors.payOffAmount)}
              helperText={touched.payOffAmount && errors.payOffAmount}
              label="Ref/PayOff Amount"
            />
          </Grid>

          <Grid item xs>
            <TextField
              variant="outlined"
              fullWidth
              name="specifiedPercentage"
              onChange={handleChange}
              value={values.specifiedPercentage ?? ''}
              error={Boolean(touched.specifiedPercentage && errors.specifiedPercentage)}
              helperText={touched.specifiedPercentage && errors.specifiedPercentage}
              label={<RequiredOption label="Specified Percentage" />}
            />
          </Grid>
          {['NY', 'CA'].includes(values.clientState ?? '') && (
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                fullWidth
                name="apr"
                onChange={handleChange}
                value={values.apr ?? ''}
                error={Boolean(touched.apr && errors.apr)}
                helperText={touched.apr && errors.apr}
                label={<RequiredOption label="APR" />}
              />
            </Grid>
          )}
          <Grid item xs>
            <TextField
              variant="outlined"
              fullWidth
              name="monthlyRevenue"
              onChange={handleChange}
              value={values.monthlyRevenue ?? ''}
              error={Boolean(touched.monthlyRevenue && errors.monthlyRevenue)}
              helperText={touched.monthlyRevenue && errors.monthlyRevenue}
              label={<RequiredOption label="Monthly Revenue" />}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={s.paper}>
        <Box>
          <Typography className={s.heading}>Business Banking Info</Typography>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <SelectComponent
                disabled={isDisabledToEdit}
                name="bankAccountId"
                label={<RequiredOption label="Bank Account" />}
                onChange={handleChange}
                labelProps={{ className: s.selectLabel }}
                fullWidth
                error={Boolean(touched.bankAccountId && errors.bankAccountId)}
                helperText={touched.bankAccountId && errors.bankAccountId}
                value={values.bankAccountId}
              >
                {dataBankAccounts && dataBankAccounts.data.length > 0 ? (
                  dataBankAccounts.data.map((ba) => (
                    <MenuItem key={ba.bankAccount.id} value={ba.bankAccount.id}>
                      {ba.bankAccount.bankName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value="">
                    {dataBankAccounts ? 'No bank accounts' : 'Choose Client'}
                  </MenuItem>
                )}
              </SelectComponent>
            </Grid>
            <Grid item xs={12} className={s.checkboxGrid}>
              <FormControlLabel
                disabled={isDisabledToEdit}
                classes={{
                  label: s.checkboxLabel,
                }}
                name="sendAsWire"
                onChange={handleChange}
                label="Send As Wire"
                control={<Checkbox checked={values.sendAsWire} color="primary" name="sendAsWire" />}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={isDisabledToEdit}
                variant="outlined"
                fullWidth
                name="comments"
                onChange={handleChange}
                value={values.comments}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
                label="Comments"
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginTop="auto">
          {!isApprovedDeal && (
            <Button variant="contained" onClick={goBack} startIcon={<ArrowLeftIcon className={s.icon} />} className={s.button}>
              Back
            </Button>
          )}
          <ButtonWithPreloader
            loading={isCreateLoading || isUpdateLoading}
            disabled={isCreateLoading || isUpdateLoading || isDisabledToEdit}
            type="submit"
            color="primary"
            variant="contained"
          >
            {isApprovedDeal ? 'Save' : 'Complete'}
          </ButtonWithPreloader>
        </Box>
      </Paper>
    </Box>
  )
}

export default DealInformationForm

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { AxiosError } from 'axios'
import FileDragNDrop from 'components/FileDragNDrop'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { deleteFileFromVendor, getVendorById } from 'services/vendor'
import {
  IInitVendorDocsValues,
  IUploadedVendorCategoriesKeys,
  IUploadedVendorDocuments,
  IVendorDocumentConnectParamsResponse,
} from 'typescript/interfaces/vendor'
import { removeAtIndex } from 'utils/arrayHelpers'

interface IDocumentForm {
  submit: {
    label: string
    handler: (values: IInitVendorDocsValues) => any
  }
  loading?: boolean
  back: {
    label: string
    handler: () => any
  }
  initialValues?: IInitVendorDocsValues
}

interface IRemoveVendorFileParams {
  id: number
  name: IUploadedVendorCategoriesKeys
}

const initValues: IInitVendorDocsValues = {
  documents: {
    W9: [],
    Invoice: [],
  },
  uploadedDocuments: {
    W9: [],
    Invoice: [],
  },
}

const DocumentsForm: FC<IDocumentForm> = ({ submit, back, initialValues = initValues, loading }) => {
  const [deleteFile, setDeleteFile] = useState<{
    file: IVendorDocumentConnectParamsResponse | undefined
    open: boolean
  }>({
    file: undefined,
    open: false,
  })

  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<{ id: string }>()

  useQuery(['vendor', id], () => getVendorById(id), {
    onSuccess(res) {
      const {
        data: { documents },
      } = res

      const documentsToSet: IUploadedVendorDocuments = {
        W9: [],
        Invoice: [],
      }

      documents.forEach((doc) => {
        documentsToSet[doc.documentType].push(doc)
      })

      setFieldValue('uploadedDocuments', documentsToSet)
    },
    onError() {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
    enabled: id,
  })

  const { handleSubmit, setFieldValue, values, touched, errors } = useFormik({
    initialValues: initialValues,
    onSubmit: submit.handler,
  })

  const handleDrop = useCallback(
    (files: File[], name: IUploadedVendorCategoriesKeys) => {
      let filesToAdd: File[] = []
      if (files.length > 0 && files.some((f) => f.size > 50000000)) {
        enqueueSnackbar('Files must be no more than 50MB.')
        filesToAdd = files.filter((f) => f.size <= 50000000)
      } else {
        filesToAdd = files
      }
      setFieldValue(`documents.${name}`, [...values.documents[name], ...filesToAdd])
    },
    [values],
  )

  /** Remove local file **/
  const handleRemove = useCallback(
    (index: number, name: IUploadedVendorCategoriesKeys) => {
      setFieldValue(`documents.${name}`, removeAtIndex(values.documents[name], index))
    },
    [values],
  )

  /** Remove file from S3 storage **/
  const [mutateRemove, { isLoading: isLoadingDeleteFile }] = useMutation(
    ({ id: docId, name }: IRemoveVendorFileParams) => deleteFileFromVendor(docId),
    {
      onSuccess(_, variables) {
        enqueueSnackbar(<Typography>Successfully deleted document</Typography>)
        setFieldValue(
          `uploadedDocuments.${variables.name}`,
          values.uploadedDocuments[variables.name].filter((doc) => doc.id !== variables.id),
        )
        setDeleteFile({
          file: undefined,
          open: false,
        })
      },
      onError: (err: AxiosError) => {
        setDeleteFile({
          file: undefined,
          open: false,
        })
        if (err.response && err.response.data.description === 'CANT_DELETE_LAST_DOCUMENT')
          enqueueSnackbar('The last file of this type cannot be deleted')
      },
    },
  )

  const handleRemoveUploaded = useCallback(
    (documentId: number, name: IUploadedVendorCategoriesKeys) => {
      const deletedFile = values.uploadedDocuments[name].find((doc) => doc.id === documentId)
      setDeleteFile({
        file: deletedFile,
        open: true,
      })
    },
    [setFieldValue, mutateRemove, values],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Typography variant="h3" color="textSecondary">
          Optional Documents
        </Typography>
      </Box>
      <Box mt="1.5rem">
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <FileDragNDrop
              title="Invoice"
              name="Invoice"
              onDrop={handleDrop}
              uploadedFiles={values.uploadedDocuments.Invoice}
              uploaded={Boolean(values.uploadedDocuments.Invoice.length)}
              files={values.documents.Invoice}
              onRemove={handleRemove}
              onRemoveUploaded={handleRemoveUploaded}
            />
          </Grid>
          <Grid item xs={3}>
            <FileDragNDrop
              title="W9"
              name="W9"
              onDrop={handleDrop}
              uploadedFiles={values.uploadedDocuments.W9}
              uploaded={Boolean(values.uploadedDocuments.W9.length)}
              files={values.documents.W9}
              onRemove={handleRemove}
              onRemoveUploaded={handleRemoveUploaded}
              error={Boolean(touched.documents?.W9 && errors.documents?.W9)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="3rem" display="flex" alignItems="center">
        <Box mr={5}>
          <Button color="secondary" onClick={() => back.handler()} variant="contained" startIcon={<ArrowLeftIcon />}>
            {back.label}
          </Button>
        </Box>
        <Button loading={loading} color="primary" variant="contained" type="submit">
          {submit.label}
        </Button>
      </Box>
      <ModalComponent open={deleteFile.open}>
        <ConfirmationContent
          isLoading={isLoadingDeleteFile}
          text="Are you sure you want to delete file?"
          handleCancel={() =>
            setDeleteFile({
              file: undefined,
              open: false,
            })
          }
          handleConfirm={() => {
            mutateRemove({
              id: deleteFile.file?.id || 0,
              name: deleteFile.file?.documentType || 'Invoice',
            })
          }}
        />
      </ModalComponent>
    </form>
  )
}

export default DocumentsForm

import { IUserPage } from 'typescript/interfaces/users'
import { IState } from 'typescript/interfaces/companies'

export interface IBankCreateEdit {
  id?: number
  street: string | null
  city: string | null
  zipCode: string | null
  userPage: Partial<IUserPage>
  stateId: number | null
}

export interface IBankResponse {
  id?: number
  street: string | null
  city: string | null
  zipCode: string | null
  bankRouting?: number | null
  accountNumber?: string
  userPage: IUserPage
  state: IState
}

export enum SortFieldsBanks {
  bankName = 'BankName',
  street = 'Street',
  city = 'City',
  zipCode = 'ZipCode',
  bankRouting = 'BankRouting',
  accountNumber = 'AccountNumber',
  state = 'State',
}

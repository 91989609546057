import makeStyles from '@material-ui/core/styles/makeStyles'

export const useFundingQuesStyle = makeStyles((theme) => ({
  secondaryColor: {
    color: theme.palette.text.hint,
  },
  link: {
    cursor: 'pointer',
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    padding: '2rem',
  },
}))

import React, { FC, useCallback, useMemo } from 'react'
import { generatePath, useParams, useHistory, useLocation } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useMutation, useQuery } from 'react-query'
import { deleteFileFromRep, getRepById, uploadRepDocuments } from 'services/rep'
import { deleteFileFromIso, getIsoById, uploadIsoDocuments } from 'services/iso'
import { getSyndicator, deleteFileFromSyndicator, uploadSyndicatorDocuments } from 'services/syndicators'
import { useFormik } from 'formik'
import FileDragNDrop from 'components/FileDragNDrop'
import { removeAtIndex } from 'utils/arrayHelpers'
import { useSnackbar } from 'notistack'
import { IIsoDocumentsForm } from 'typescript/interfaces/iso'
import Button from 'UI/Button/ButtonWithPreloader'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ISO_INFO_URL, REP_INFO_URL, SYNDICATOR_INFO_URL } from 'constants/routes'
import { usePermission } from 'hooks/usePermission'
import { DELETE_USER_UPLOADED_DOCUMENTS, UPLOAD_USER_PAGE_DOCS_PERM } from 'constants/permissions'
import { uploadDocsSchema } from 'containers/UploadDocs/schema'

interface IUploadDocumentQueryParams {
  type: 'iso' | 'syndicator' | 'rep'
  id: string
}

const UploadDocumentContainer: FC = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isAfterCreation = params.get('type')
  const { hasPermission, isOwnSyndicator, isOwnIso, isOwnRep } = usePermission()
  const { type, id } = useParams<IUploadDocumentQueryParams>()
  const { enqueueSnackbar } = useSnackbar()
  const { goBack, push } = useHistory()
  const additionalStyle = useAdditionalMaterialStyle()

  const { isLoading: isLoadingIso } = useQuery(['isoById', id], () => getIsoById(id), {
    retry: false,
    cacheTime: 0,
    enabled: type === 'iso',
    onSuccess: (res) => {
      setFieldValue('uploadedDocuments', res.data.documents)
    },
  })

  const { isLoading: isLoadingSyndicator } = useQuery(['syndicatorById', id], () => getSyndicator(id), {
    retry: false,
    cacheTime: 0,
    enabled: type === 'syndicator',
    onSuccess: (res) => {
      setFieldValue('uploadedDocuments', res.data.documents)
    },
  })

  const { isLoading: isLoadingRep } = useQuery(['repById', id], () => getRepById(id), {
    retry: false,
    cacheTime: 0,
    enabled: type === 'rep',
    onSuccess: (res) => {
      setFieldValue('uploadedDocuments', res.data.documents)
    },
  })

  const [mutate, { isLoading }] = useMutation(
    (docs: File[]) => {
      switch (type) {
        case 'rep': {
          return uploadRepDocuments(docs, +id)
        }
        case 'syndicator': {
          return uploadSyndicatorDocuments(docs, +id)
        }
        case 'iso': {
          return uploadIsoDocuments(docs, +id)
        }
        default: {
          return uploadIsoDocuments(docs, +id)
        }
      }
    },
    {
      onSuccess(res) {
        switch (type) {
          case 'syndicator': {
            push(
              generatePath(SYNDICATOR_INFO_URL, {
                id: id,
              }),
            )
            break
          }
          case 'rep': {
            push(
              generatePath(REP_INFO_URL, {
                id: id,
              }),
            )
            break
          }
          case 'iso': {
            push(
              generatePath(ISO_INFO_URL, {
                id: id,
              }),
            )
            break
          }
          default: {
            push(
              generatePath(ISO_INFO_URL, {
                id: id,
              }),
            )
            break
          }
        }
      },
    },
  )

  const handleDrop = (files: File[]) => {
    let filesToAdd: File[] = []
    if (files.length > 0 && files.some((f) => f.size > 50000000)) {
      enqueueSnackbar('Files must be no more than 50MB.')
      filesToAdd = files.filter((f) => f.size <= 50000000)
    } else {
      filesToAdd = files
    }
    setFieldValue(`documents`, [...values.documents, ...filesToAdd])
  }
  const handleRemove = (index: number) => {
    setFieldValue(`documents`, removeAtIndex(values.documents, index))
  }

  const [mutateRemove] = useMutation(
    ({ id: docId }: { id: string }) => {
      switch (type) {
        case 'iso': {
          return deleteFileFromIso(+docId)
        }
        case 'rep': {
          return deleteFileFromRep(+docId)
        }
        case 'syndicator': {
          return deleteFileFromSyndicator(+docId)
        }
        default: {
          return deleteFileFromIso(+docId)
        }
      }
    },
    {
      onSuccess(_, variables) {
        enqueueSnackbar('Successfully deleted document')
      },
    },
  )

  const { values, setFieldValue, handleSubmit, touched, errors } = useFormik<IIsoDocumentsForm>({
    initialValues: {
      documents: [],
      uploadedDocuments: [],
    },
    validationSchema: uploadDocsSchema,
    onSubmit: (formValues) => {
      mutate(formValues.documents)
    },
  })

  const handleRemoveUploaded = useCallback(
    (documentId: number) => {
      setFieldValue(
        `uploadedDocuments`,
        values.uploadedDocuments.filter((doc) => doc.id !== documentId),
      )
      mutateRemove({ id: `${documentId}` })
    },
    [values],
  )

  const labelDocs = useMemo(() => {
    switch (type) {
      case 'rep': {
        return 'REP Documents'
      }
      case 'iso': {
        return 'ISO Documents'
      }
      case 'syndicator': {
        return 'SYNDICATOR Documents'
      }
      default: {
        return ''
      }
    }
  }, [type])

  if (
    !hasPermission(UPLOAD_USER_PAGE_DOCS_PERM) &&
    !(type === 'iso' && isOwnIso(+id)) &&
    !(type === 'rep' && isOwnRep(+id)) &&
    !(type === 'syndicator' && isOwnSyndicator(+id))
  )
    goBack()

  return (
    <Box className={additionalStyle.wrapper}>
      <Box mb="1rem">
        <Typography variant="h1" color="textSecondary">
          {labelDocs}
        </Typography>
      </Box>
      <Paper elevation={0}>
        {isLoadingIso || isLoadingSyndicator || isLoadingRep ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box p="3rem">
              <Grid container>
                <Grid item xs={6}>
                  <FileDragNDrop
                    disabledDelete={!hasPermission(DELETE_USER_UPLOADED_DOCUMENTS)}
                    title="Documents"
                    name="VoidedCheck"
                    onDrop={handleDrop}
                    uploadedFiles={values.uploadedDocuments}
                    uploaded={Boolean(values.uploadedDocuments.length)}
                    files={values.documents}
                    onRemove={handleRemove}
                    onRemoveUploaded={handleRemoveUploaded}
                    error={Boolean(touched.documents && errors.documents)}
                  />
                </Grid>
              </Grid>
              <Box mt="3rem" display="flex" alignItems="center">
                {!isAfterCreation && (
                  <Box mr="1rem">
                    <Button color="inherit" variant="contained" onClick={goBack} className={additionalStyle.greyDarkButton}>
                      Cancel
                    </Button>
                  </Box>
                )}
                <Button loading={isLoading} color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Paper>
    </Box>
  )
}

export default UploadDocumentContainer

import React, { useCallback, useMemo, useState } from 'react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useMutation } from 'react-query'
import { cancelPayment, duplicatePayment, editAch, stopAch } from 'services/ach'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import ModalComponent from 'UI/Modal'
import DatePicker from 'UI/DatePicker/DatePicker'
import { Box } from '@material-ui/core'

interface IUseAch {
  callBackEdit: (id: number, date: MaterialUiPickersDate | string) => void
  callBackStop?: (id: number) => void
  callBackCancel?: (id: number) => void
  callBackDuplicate?: () => void
}

export const useAch = (props: IUseAch) => {
  const { callBackEdit, callBackStop, callBackCancel, callBackDuplicate } = props
  const { enqueueSnackbar } = useSnackbar()
  const [duplicatePaymentState, setDuplicatePaymentState] = useState({
    open: false,
    id: 0,
  })
  const [cancelFutureDebits, setCancelFutureDebits] = useState({
    open: false,
    id: 0,
  })
  const [stopState, setStopState] = useState<{
    open: boolean
    category: string | null
    id: number
  }>({
    open: false,
    category: '',
    id: 0,
  })
  const [editAchState, setEditAchState] = useState<{
    open: boolean
    id: number
    category: null | string
    date: MaterialUiPickersDate | string
  }>({
    open: false,
    id: 0,
    date: null,
    category: null,
  })

  const [stopAchMut, { isLoading: isLoadingStop }] = useMutation(stopAch, {
    onSuccess: (data) => {
      callBackStop && callBackStop(stopState.id)
      setStopState({
        open: false,
        category: '',
        id: 0,
      })
      enqueueSnackbar('Debit successfully stopped')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [editAchMut, { isLoading: isLoadingEdit }] = useMutation(editAch, {
    onSuccess: (data) => {
      callBackEdit(editAchState.id, editAchState.date)
      setEditAchState({
        id: 0,
        date: null,
        open: false,
        category: null,
      })
      enqueueSnackbar('Debit successfully edited')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [cancelAchMut, { isLoading: isLoadingCancel }] = useMutation(cancelPayment, {
    onSuccess: (data, variables) => {
      callBackCancel && callBackCancel(+variables)
      setCancelFutureDebits({
        id: 0,
        open: false,
      })
      enqueueSnackbar('Debit successfully canceled')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [duplicateMut, { isLoading: isLoadingDuplicate }] = useMutation(duplicatePayment, {
    onSuccess: (data) => {
      callBackDuplicate && callBackDuplicate()
      setDuplicatePaymentState({
        id: 0,
        open: false,
      })
      enqueueSnackbar('Debit successfully duplicated')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleDuplicate = useCallback(() => {
    duplicateMut(duplicatePaymentState.id)
  }, [duplicateMut, duplicatePaymentState.id])

  const handleStop = useCallback(() => {
    stopAchMut({
      id: stopState.id,
      category: stopState.category !== 'Withdrawal' ? null : 'Withdrawal',
    })
  }, [stopAchMut, stopState.id, stopState.category])

  const handleCancel = useCallback(() => {
    cancelAchMut(cancelFutureDebits.id)
  }, [cancelAchMut, cancelFutureDebits.id])

  const ModalStop = useMemo(() => {
    return (
      <ModalComponent open={stopState.open}>
        <ConfirmationContent
          handleCancel={() =>
            setStopState({
              open: false,
              id: 0,
              category: '',
            })
          }
          isLoading={isLoadingStop}
          handleConfirm={handleStop}
          text="Are you sure you want to stop debit?"
        />
      </ModalComponent>
    )
  }, [stopState.open, isLoadingStop, handleStop])

  const ModalCancel = useMemo(() => {
    return (
      <ModalComponent open={cancelFutureDebits.open}>
        <ConfirmationContent
          handleCancel={() =>
            setCancelFutureDebits({
              open: false,
              id: 0,
            })
          }
          isLoading={isLoadingCancel}
          handleConfirm={handleCancel}
          text="Are you sure you want to cancel debit?"
        />
      </ModalComponent>
    )
  }, [cancelFutureDebits.open, isLoadingCancel, handleCancel])

  const ModalDuplicate = useMemo(() => {
    return (
      <ModalComponent open={duplicatePaymentState.open}>
        <ConfirmationContent
          handleCancel={() =>
            setDuplicatePaymentState({
              open: false,
              id: 0,
            })
          }
          isLoading={isLoadingDuplicate}
          handleConfirm={handleDuplicate}
          text="Are you sure you want to duplicate debit?"
        />
      </ModalComponent>
    )
  }, [duplicatePaymentState.open, isLoadingDuplicate, handleDuplicate])

  const ModalEdit = useMemo(() => {
    return (
      <ModalComponent open={editAchState.open}>
        <Box pt="1rem" width={250}>
          <ConfirmationContent
            handleCancel={() =>
              setEditAchState({
                id: 0,
                date: null,
                open: false,
                category: null,
              })
            }
            isLoading={isLoadingEdit}
            handleConfirm={() =>
              editAchMut({
                id: editAchState.id,
                scheduledDate: editAchState.date,
                category: editAchState.category !== 'Withdrawal' ? null : 'Withdrawal',
              })
            }
            renderedElement={
              <DatePicker
                fullWidth
                disablePast
                label="Date"
                value={editAchState.date}
                type="keyboard"
                onChange={(date) =>
                  setEditAchState((prevState) => ({
                    ...prevState,
                    date: date,
                  }))
                }
              />
            }
          />
        </Box>
      </ModalComponent>
    )
  }, [editAchState.open, editAchState.date, editAchState.id, isLoadingEdit, editAchMut])

  return {
    setCancelFutureDebits,
    setDuplicatePaymentState,
    cancelFutureDebits,
    stopState,
    setStopState,
    editAchState,
    setEditAchState,
    ModalStop,
    ModalEdit,
    ModalCancel,
    ModalDuplicate,
  }
}

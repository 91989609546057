export enum IPortfolioSummarySortFields {
  id = 'id',
  businessName = 'businessName',
  advanced = 'advanced',
  payback = 'payback',
  frequent = 'frequent',
  term = 'term',
  net = 'net',
  collected = 'collected',
  paidOff = 'paidOff',
  remaining = 'remaining',
  rep = 'rep',
  funder = 'funder',
  label = 'label',
  pnl = 'pnl',
}

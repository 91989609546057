import React, { useState, useEffect } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { IFunderLenderItem, ILenderEntity } from 'typescript/interfaces/deals'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteCircleIcon.svg'
import Button from 'UI/Button/ButtonWithPreloader'

export const TableLenders = TableComponent as TableComponentType<ILenderEntity>
export const TableFunderLenders = TableComponent as TableComponentType<IFunderLenderItem>

interface IUseAssignLenders {
  handleEdit?: (data: ILenderEntity) => void
  handleEditFunder?: (data: IFunderLenderItem) => void
  handleDelete?: (id: number, idCompany: number) => void
  disableActions: boolean
}

export const useAssignLenders = (props?: IUseAssignLenders) => {
  const classes = useTableComponentStyle()
  const { disableActions } = props || {}
  const [columns, setColumns] = useState<IColumn<ILenderEntity>[]>([])
  const [columnsFunder, setColumnsFunder] = useState<IColumn<IFunderLenderItem>[]>([])

  useEffect(() => {
    setColumns([
      {
        label: 'Lender #',
        field: 'id',
        bodyCellClassName: classes.secondaryBodyCell,
      },
      {
        label: 'Biz Name',
        field: 'businessName',
        bodyCellClassName: classes.accentCell,
      },
      {
        label: 'Main Lender',
        field: 'isLead',
        bodyCellClassName: classes.accentCell,
        render: (data) => (data.isLead ? 'Yes' : 'No'),
      },
      {
        label: 'Amt Participated',
        field: 'amount',
        bodyCellClassName: classes.accentCell,
        render: (data) => convertToPriceFormat(data.lendingAmount),
      },
      {
        label: 'Percentage',
        field: 'percentage',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) => `${data.lendingPercentage} %`,
      },
      {
        label: 'Account Balance',
        field: 'balance',
        bodyCellClassName: classes.accentCell,
        render: (data) => convertToPriceFormat(data.availableBalance),
      },
      {
        label: 'Edit',
        field: 'edit',
        render: (data) => {
          return (
            <IconButton disabled={disableActions} onClick={() => props?.handleEdit && props?.handleEdit(data)}>
              <EditIcon />
            </IconButton>
          )
        },
      },
      {
        label: 'Delete',
        field: 'delete',
        render: (data) => {
          return (
            <IconButton
              disabled={disableActions}
              onClick={() => props?.handleDelete && props?.handleDelete(Number(data.id), Number(data.syndicatorId))}
            >
              <DeleteIcon />
            </IconButton>
          )
        },
      },
    ])

    setColumnsFunder([
      {
        label: 'Syndicator #',
        field: 'syndicator',
        bodyCellClassName: classes.secondaryBodyCell,
        render: (data) => data.syndicatorId,
      },
      {
        label: 'Biz Name',
        field: 'bussName',
        bodyCellClassName: classes.secondaryBodyCell,
        render: (data) => convertEmptyField(data.businessName),
      },
      {
        label: 'First Name',
        field: 'firstName',
        bodyCellClassName: classes.secondaryBodyCell,
        render: (data) => convertEmptyField(data.firstName),
      },
      {
        label: 'Last Name',
        field: 'lastName',
        bodyCellClassName: classes.secondaryBodyCell,
        render: (data) => convertEmptyField(data.lastName),
      },
      {
        label: 'Main Lender',
        field: 'isMain',
        bodyCellClassName: classes.secondaryBodyCell,
        render: (data) => (data.isLead ? 'Yes' : 'No'),
      },
      {
        label: 'Amt Participated',
        field: 'amount',
        bodyCellClassName: classes.secondaryBodyCell,
        render: (data) => convertToPriceFormat(data.lendingAmount),
      },
      {
        label: 'Amt Allowed',
        field: 'amountAllowed',
        bodyCellClassName: classes.secondaryBodyCell,
        render: (data) => convertToPriceFormat(data.limit),
      },
      {
        label: 'Percentage',
        field: 'percentage',
        bodyCellClassName: classes.secondaryBodyCell,
        render: (data) => `${data.lendingPercentage}%`,
      },
      {
        label: '',
        field: 'actions',
        render: (data) => (
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            disabled={disableActions}
            onClick={() => props?.handleEditFunder && props?.handleEditFunder(data)}
          >
            {data.isAttachedToDeal ? 'Edit' : 'Assign'}
          </Button>
        ),
      },
    ])
  }, [disableActions])

  return {
    columns: columns,
    columnsFunder,
  }
}

import { Box, CircularProgress, IconButton, Paper, Typography } from '@material-ui/core'
import { DEAL_INFO_URL } from 'constants/routes'
import React, { useEffect, useMemo } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { getAuditLog } from 'services/deals'
import { useMutation } from 'react-query'
import { usePaginationList } from 'hooks/usePaginationList'
import { IAuditLogListItem } from 'typescript/interfaces/deals'
import { ReactComponent as LoadMoreIcon } from 'assets/svg/LoadMoreIcon.svg'
import Button from 'UI/Button/ButtonWithPreloader'
import { useTableComponentStyle } from 'UI/Table/style'
import AuditLogItem from './AuditLogItem'

const DealAuditLogContainer = () => {
  const { id } = useParams<{ id: string }>()
  const classes = useTableComponentStyle()

  const { push } = useHistory()

  const { setPagination, handleFetchMore, pagination } = usePaginationList<IAuditLogListItem>({
    initState: {
      data: [],
      search: '',
      order: '',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getAuditLogMut, { isLoading }] = useMutation(getAuditLog, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  useEffect(() => {
    getAuditLogMut({
      PageSize: 20,
      PageIndex: pagination.page,
      DealId: id,
    })
  }, [pagination.page, id])

  const isEmpty = useMemo(() => !pagination.data.length, [pagination.data])

  return (
    <Box>
      <Box display="flex" mb="2rem" alignItems="center">
        <Box mr="1.5rem">
          <IconButton onClick={() => push(generatePath(DEAL_INFO_URL, { id }))} color="inherit">
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography variant="h1" color="textSecondary">
          Audit History For Deal #{id}
        </Typography>
      </Box>
      <Box>
        {!isLoading && isEmpty && (
          <Box mb="2rem">
            <Paper elevation={0}>
              <Box p="2rem">
                <Typography variant="h5" color="textSecondary">
                  Deal #{id} has no updates.
                </Typography>
              </Box>
            </Paper>
          </Box>
        )}
        {pagination.data.map((logItem) => (
          <Box key={logItem.date} mb="0.75rem">
            <AuditLogItem data={logItem} />
          </Box>
        ))}
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" minHeight="320px">
            <CircularProgress />
          </Box>
        )}
      </Box>
      {pagination.hasMore && !isEmpty && (
        <Box width="100%" py={3}>
          <Button
            loading={isLoading}
            onClick={handleFetchMore}
            variant="outlined"
            color="secondary"
            startIcon={<LoadMoreIcon />}
            fullWidth
            className={classes.loadMoreButton}
          >
            Show more
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default DealAuditLogContainer

import React from 'react'

import { Box, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { useSignUpStyles } from 'components/SignUpInvitationForm/style'
import { activateUserNoPassword } from 'services/auth/auth'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Button from 'UI/Button/ButtonWithPreloader'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { SIGN_IN_URL } from 'constants/routes'
import { AxiosError } from 'axios'
import { getEmailByToken } from 'services/users'

import { schemaSignUp } from './schema'

const SignUpInvitationForm = () => {
  const s = useSignUpStyles()
  const location = useLocation()
  const { push } = useHistory()
  const params = new URLSearchParams(location.search)
  const token = params.get('validationToken')
  const { enqueueSnackbar } = useSnackbar()

  const { data } = useQuery(['getUserToken', token], () => getEmailByToken(token || ''))

  const [activateUserNoPasswordMut, { isLoading }] = useMutation(activateUserNoPassword, {
    onSuccess: () => {
      push(SIGN_IN_URL)
      enqueueSnackbar('Account successfully created')
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'INVALID_ACTIVATE_ATTEMPT') enqueueSnackbar('Token is not valid')
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      confirmPassword: '',
      password: '',
      email: data ? data.data.email : '',
    },
    enableReinitialize: true,
    validationSchema: schemaSignUp,
    onSubmit: (vals) => {
      activateUserNoPasswordMut({
        validationToken: token || '',
        password: vals.password,
      })
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box width="520px" maxWidth="80%">
        <Typography className={s.text} align="center">
          Create your account
        </Typography>
        <Box mt="4rem">
          <TextField
            disabled
            size="small"
            variant="outlined"
            fullWidth
            label="Email"
            name="email"
            onChange={handleChange}
            value={values.email}
            error={!!errors.email && !!touched.email}
            helperText={!!touched.email && errors.email}
          />
        </Box>
        <Box mt="2rem">
          <TextField
            type="password"
            size="small"
            variant="outlined"
            fullWidth
            label="Password"
            name="password"
            onChange={handleChange}
            value={values.password}
            error={!!errors.password && !!touched.password}
            helperText={!!touched.password && errors.password}
          />
        </Box>
        <Box mt="2rem">
          <TextField
            type="password"
            size="small"
            variant="outlined"
            fullWidth
            label="Confirm password"
            name="confirmPassword"
            onChange={handleChange}
            value={values.confirmPassword}
            error={!!errors.confirmPassword && !!touched.confirmPassword}
            helperText={!!touched.confirmPassword && errors.confirmPassword}
          />
        </Box>
        <Box mt="2rem">
          <Button loading={isLoading} type="submit" color="primary" variant="contained" fullWidth>
            Confirm
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default SignUpInvitationForm

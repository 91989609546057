import React, { FC, useMemo } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as ExpandIcon } from 'assets/svg/ExpandIcon.svg'
import Paper from '@material-ui/core/Paper/Paper'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import useTheme from '@material-ui/core/styles/useTheme'
import IconButton from '@material-ui/core/IconButton'
import { IChartProps } from 'typescript/interfaces/dashboard'
import { convertDate, convertToPriceFormatWithoutCents, formatBigNumber } from 'utils/formatters'
import { addDays } from 'date-fns'

const DailyChart: FC<IChartProps> = ({ additionalRem = 0, onCollapse, stats, header }) => {
  const theme = useTheme()
  const fontSizeHtml = parseFloat(getComputedStyle(document.documentElement).fontSize)

  const data = stats?.data
    ?.map(({ date, frequent }) => ({ name: convertDate(new Date(date)), frequent }))
    .concat([
      {
        name: convertDate(new Date()),
        frequent: stats ? stats.todayFrequent : 0,
      },
      {
        name: convertDate(addDays(new Date(), 1)),
        frequent: stats ? stats.tomorrowFrequent : 0,
      },
    ])

  const maxLength = useMemo(() => {
    return data
      ? data
          .reduce((acc, val) => {
            return acc < val.frequent ? val.frequent : acc
          }, 0)
          .toString().length *
          (fontSizeHtml / 2) +
          fontSizeHtml
      : 0
  }, [data])

  return (
    <Paper elevation={0}>
      <Box height="100%" py="1rem" px="1rem" display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Daily</Typography>
            <IconButton onClick={onCollapse} size="small">
              <SvgIcon fontSize="small" viewBox="0 0 16 16" component={ExpandIcon} />
            </IconButton>
          </Box>
          <Box>
            <Typography style={{ fontWeight: 600 }} noWrap variant="h4" color="textSecondary">
              {convertToPriceFormatWithoutCents(header?.daily)}
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <ResponsiveContainer width="100%" height={fontSizeHtml * (6.45 + additionalRem)}>
            <AreaChart
              data={data}
              margin={{
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <YAxis
                tick={{
                  fill: theme.palette.text.primary,
                  strokeWidth: 1,
                }}
                minTickGap={0}
                tickCount={4}
                allowDecimals={false}
                width={maxLength}
                axisLine={false}
                tickLine={false}
                interval="preserveStartEnd"
                tickMargin={0}
                tickFormatter={(item) => formatBigNumber(item)}
                padding={{
                  top: 0,
                  bottom: 0,
                }}
              />
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <Tooltip labelFormatter={(index) => data?.[index as number].name} />
              <Area type="monotone" dataKey="frequent" stroke={theme.palette.primary.main} fillOpacity={0.3} fill={theme.palette.primary.main} />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Paper>
  )
}

export default DailyChart

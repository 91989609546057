import React, { useRef } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import { SyndicatorListItem } from 'typescript/interfaces/syndicators'
import { IColumn } from 'typescript/interfaces/tableUi'
import { format } from 'date-fns'
import { generatePath, Link, useHistory } from 'react-router-dom'
import { SYNDICATOR_INFO_URL, TRANSACTIONS_URL, WITHDRAWAL_URL } from 'constants/routes'
import { convertToPriceFormat, convertEmptyField } from 'utils/formatters'

const useTableColumnStyles = makeStyles((theme) => ({
  button: {
    fontSize: '1.0625rem',
  },
  accentCell: {
    fontSize: '1.1875rem',
    color: theme.palette.text.secondary,
  },
}))

const useSyndicatorTable = () => {
  const tableStyles = useTableColumnStyles()
  const { push } = useHistory()
  const columnsRef = useRef<IColumn<SyndicatorListItem>[]>([
    {
      label: 'Organization',
      field: 'companyName',
      bodyCellClassName: tableStyles.accentCell,
      render: (rowData) => (
        <Link to={SYNDICATOR_INFO_URL.replace(':id', `${rowData.id}`)}>
          <Typography className={tableStyles.accentCell}>{rowData.businessName}</Typography>
        </Link>
      ),
    },
    {
      label: 'Name',
      field: 'name',
      bodyCellClassName: tableStyles.accentCell,
      sorting: true,
      render: (rowData) => (
        <Typography className={tableStyles.accentCell}>
          {rowData.firstName} {rowData.lastName}
        </Typography>
      ),
    },
    {
      label: 'Data Joined',
      field: 'dataJoined',
      sorting: true,
      render: (rowData) => <Typography className={tableStyles.accentCell}>{format(new Date(rowData.dataJoined), 'MM.dd.yyyy')}</Typography>,
    },
    {
      label: 'Deals',
      field: 'deals',
      bodyCellClassName: tableStyles.accentCell,
      sorting: true,
    },
    {
      label: 'Amount Syndicated',
      field: 'amountSyndicated',
      bodyCellClassName: tableStyles.accentCell,
      render: (data) => convertToPriceFormat(data.amountSyndicated),
    },

    {
      label: 'Current Balance',
      field: 'availableBalance',
      bodyCellClassName: tableStyles.accentCell,
      sorting: true,
      render: (data) => convertToPriceFormat(data.availableBalance),
    },
    {
      label: 'Last Transactions',
      field: 'LastTransactions',
      sorting: true,
      render: (rowData) => (
        <Typography className={tableStyles.accentCell}>
          {rowData.lastTransaction ? format(new Date(rowData.lastTransaction), 'MM.dd.yyyy') : convertEmptyField(undefined)}
        </Typography>
      ),
    },
    {
      label: '',
      field: 'viewTrs',
      render: (rowData) => (
        <Button
          size="small"
          className={tableStyles.button}
          color="secondary"
          variant="outlined"
          onClick={() =>
            push(
              generatePath(TRANSACTIONS_URL, {
                id: rowData.id,
                type: 'syndicator',
                businessName: rowData.businessName,
              }),
            )
          }
        >
          View Trs
        </Button>
      ),
    },
    {
      label: '',
      field: 'withdrawals',
      render: (rowData) => (
        <Button
          size="small"
          className={tableStyles.button}
          color="secondary"
          variant="outlined"
          onClick={() =>
            push(
              generatePath(WITHDRAWAL_URL, {
                id: rowData.id,
                type: 'syndicator',
                businessName: rowData.businessName,
              }),
            )
          }
        >
          Withdrawals
        </Button>
      ),
    },
  ])

  return { columns: columnsRef.current }
}

export default useSyndicatorTable

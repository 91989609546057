import React from 'react'
import { ReactComponent as DocumentIcon } from 'assets/svg/Document.svg'
import { ReactComponent as RemoveIcon } from 'assets/svg/RemoveIcon.svg'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import cn from 'classnames'
import { useSnackbar } from 'notistack'

interface Props {
  disabledDelete?: boolean
  files?: Array<File>
  title?: string
  uploaded?: boolean
  onDrop?: (acceptedFiles: File[], name: any, fileRejections?: FileRejection[], event?: DropEvent) => void
  onRemove?: (index: number, name: any) => void
  onRemoveUploaded?: (index: number, name: any) => void
  name?: string
  uploadedFiles?: any[]
  error?: boolean
  maxFiles?: number
  disabled?: boolean
}

interface DocumentItemProps {
  onRemove: () => void
  title: string
  disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    borderRadius: 6,
    background: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  header: {
    padding: '1rem 1.25rem',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  icon: {
    marginRight: '0.75rem',
  },
  title: {
    fontSize: '1.1875rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  status: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.dark,
  },
  emptyBigText: {
    color: theme.palette.text.primary,
    fontSize: '1.5rem',
  },
  emptySmallText: {
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
  contentContainer: {
    padding: '3rem 1rem',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  inputDiv: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
}))

const useDocumentStyles = makeStyles((theme) => ({
  iconAndDeleteConainer: {
    position: 'relative',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '5rem',
  },
  delete: {
    position: 'absolute',
    width: 'fit-content',
    minWidth: 0,
    padding: '0.25rem',
    top: -5,
    right: -7,
  },
  text: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

const DocumentItem = ({ title, onRemove, disabled }: DocumentItemProps) => {
  const s = useDocumentStyles()
  return (
    <Box className={s.container}>
      <Box className={s.iconAndDeleteConainer}>
        <DocumentIcon height="45" width="34" />
        {!disabled && (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              onRemove()
            }}
            className={s.delete}
          >
            <RemoveIcon />
          </Button>
        )}
      </Box>
      <Typography className={s.text}>{title}</Typography>
    </Box>
  )
}

const FileDragNDrop = ({
  files,
  title = 'Title',
  uploaded = false,
  uploadedFiles,
  onDrop,
  onRemove,
  onRemoveUploaded,
  name = '',
  error,
  maxFiles,
  disabled,
  disabledDelete,
}: Props) => {
  const s = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles,
    disabled,
    // accept: '.pdf',
    onDrop: (acceptedFiles, rejections, event) => {
      if (rejections.length > 0) enqueueSnackbar("Files with inappropriate format can't be added")
      // if((!files || files.length === 0) && (!uploadedFiles || uploadedFiles.length === 0))
      onDrop?.(acceptedFiles, name, rejections, event)
      // else
      //   enqueueSnackbar("Only one document can be uploaded")
    },
  })

  return (
    <Box className={cn(s.container, error && s.error)}>
      <Box className={s.header}>
        <DocumentIcon className={s.icon} />
        <Box>
          <Typography className={s.title}>{title}</Typography>
          <Typography className={s.status}>{uploaded || (files && files.length > 0) ? 'Uploaded' : 'Not uploaded'}</Typography>
        </Box>
      </Box>
      <div className={s.inputDiv} {...getRootProps()}>
        <input {...getInputProps()} multiple={maxFiles !== 1} />
        <Box className={s.contentContainer}>
          {uploadedFiles?.length ? (
            uploadedFiles.map((file, index) => (
              <DocumentItem
                disabled={disabledDelete}
                key={file.id}
                onRemove={() => {
                  if (onRemoveUploaded) {
                    onRemoveUploaded(file.id, name)
                  }
                }}
                title={file.description}
              />
            ))
          ) : (
            <></>
          )}
          {files?.length ? (
            files.map((file, index) => (
              <DocumentItem
                key={file.name}
                onRemove={() => {
                  if (onRemove) {
                    onRemove(index, name)
                  }
                }}
                title={file.name}
              />
            ))
          ) : (
            <></>
          )}
          {files?.length === 0 && uploadedFiles?.length === 0 && (
            <Box>
              <Typography className={s.emptyBigText}>Please, upload {title}</Typography>
              <Typography className={s.emptySmallText}>Drag + Drop</Typography>
            </Box>
          )}
        </Box>
      </div>
    </Box>
  )
}

export default FileDragNDrop

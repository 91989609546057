import * as yup from 'yup'
import { ONLY_DIGITS, REQUIRED_FIELD } from 'constants/errors'
import { integerReg } from 'constants/regExp'

export const transactionSchema = yup.object().shape({
  userPageId: yup.string().required(REQUIRED_FIELD),
  creationDate: yup.string().required(REQUIRED_FIELD),
  amount: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(integerReg, ONLY_DIGITS)
    .test('is-more-than', 'Negative value and zero are forbidden', (val) => {
      if (!parseFloat(val || '0')) return false
      return parseFloat(val || '0') > 0
    }),
  dealId: yup.string().test('is-req', REQUIRED_FIELD, function (value) {
    const { type } = this.parent
    if (!this.parent.type) return true
    else if ((type === 'DealToFun' || type === 'DealToPage' || type === 'PageToDeal') && !value) return false
    return true
  }),
  type: yup.string().required(REQUIRED_FIELD),
  category: yup.string().required(REQUIRED_FIELD),
  comments: yup.string(),
})

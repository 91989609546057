import { API_GET_VENDOR_FEE, API_CREATE_VENDOR_FEE, API_SAVE_VENDOR_FEE, API_GET_PREVIEW_LEGAL_FEE } from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import {
  IMassLegalFeeParams,
  IMassLegalFeeListItem,
  IMassLegalFeeList,
  IImportMassLegalFeeValues,
  IImportMassLegalFeeResponse,
} from 'typescript/interfaces/massLegalfee'
import { AxiosResponse } from 'axios'

export const getLegalFee = (params: IMassLegalFeeParams) =>
  privateInstance.get<typeof params, AxiosResponse<IMassLegalFeeList>>(API_GET_VENDOR_FEE, {
    params: params,
  })

export const createLegalFee = (values: IMassLegalFeeListItem) =>
  privateInstance.post<typeof values, AxiosResponse<IMassLegalFeeListItem>>(API_CREATE_VENDOR_FEE, values)

export const saveLegalFee = (values: IMassLegalFeeParams) =>
  privateInstance.post<IMassLegalFeeParams, AxiosResponse<boolean>>(API_SAVE_VENDOR_FEE, undefined, {
    params: values,
  })

export const saveImportLegalFee = (values: Array<number>) => privateInstance.post<Array<number>, AxiosResponse<boolean>>(API_SAVE_VENDOR_FEE, values)

export const getPreviewLegalFee = (values: FormData) =>
  privateInstance.post<IImportMassLegalFeeValues, AxiosResponse<IImportMassLegalFeeResponse>>(API_GET_PREVIEW_LEGAL_FEE, values)

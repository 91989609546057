import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NewNotificationPage from 'pages/NewNotification'
import SentNotificationsListPage from 'pages/SentNotificationsList'
import NotificationReceivedPage from 'pages/NotificationReceived'

import { NOTIFICATIONS_NEW_URL, NOTIFICATIONS_RECEIVED_URL, NOTIFICATIONS_SENT_URL } from 'constants/routes'
import { NOTIFICATIONS_CREATE_PERM, NOTIFICATIONS_VIEW_PERM, SENT_NOTIFICATIONS_PERM } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'

const NotificationsContainer = () => {
  const { hasPermission } = usePermission()
  return (
    <Switch>
      {hasPermission(NOTIFICATIONS_CREATE_PERM) && <Route path={NOTIFICATIONS_NEW_URL} exact component={NewNotificationPage} />}
      {hasPermission(SENT_NOTIFICATIONS_PERM) && <Route path={NOTIFICATIONS_SENT_URL} exact component={SentNotificationsListPage} />}
      {hasPermission(NOTIFICATIONS_VIEW_PERM) && <Route path={NOTIFICATIONS_RECEIVED_URL} exact component={NotificationReceivedPage} />}
    </Switch>
  )
}

export default NotificationsContainer

import { REQUIRED_FIELD, ZIP_CODE_FORMAT_ERROR } from 'constants/errors'
import * as yup from 'yup'
import { zipCodeReg } from 'constants/regExp'

export const schemaAddEquifaxReport = () =>
  yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    middleName: yup.string(),
    suffix: yup.string(),
    streetNum: yup.string().required(REQUIRED_FIELD),
    streetName: yup.string().required(REQUIRED_FIELD),
    streetType: yup.string().nullable(),
    preDirection: yup.string().nullable(),
    postDirection: yup.string().nullable(),
    apt: yup.string(),
    city: yup.string().required(REQUIRED_FIELD),
    state: yup.string().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    postalCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
    tinType: yup.string().required(REQUIRED_FIELD),
    taxId: yup.string().required(REQUIRED_FIELD),
    birthDate: yup.string().nullable(),
  })

export const schemaAddPrbcReport = () =>
  yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    address: yup.string().required(REQUIRED_FIELD),
    city: yup.string().required(REQUIRED_FIELD),
    state: yup.string().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    postalCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
    tinType: yup.string().required(REQUIRED_FIELD),
    taxId: yup.string().required(REQUIRED_FIELD),
    birthDate: yup.string().nullable(),
  })

export const schemaAddEnhancedSearchReport = () =>
  yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    middleName: yup.string(),
    streetNum: yup.string(),
    streetName: yup.string(),
    streetType: yup.string().nullable(),
    preDirection: yup.string().nullable(),
    postDirection: yup.string().nullable(),
    apt: yup.string(),
    city: yup.string(),
    state: yup.string().nullable(),
    postalCode: yup.string(),
    tinType: yup.string().nullable().required(REQUIRED_FIELD),
    taxId: yup.string().when('tinType', {
      is: (tin) => Boolean(tin),
      then: yup.string().required(REQUIRED_FIELD),
      otherwise: yup.string().nullable(),
    }),
    birthDate: yup.string().nullable(),
  })

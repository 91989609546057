import React, { FC, useCallback } from 'react'
import VendorForm from 'components/VendorForm'
import { useMutation } from 'react-query'
import { createVendor } from 'services/vendor'
import { VENDOR_DOCUMENTS, VENDOR_LIST_URL } from 'constants/routes'
import { generatePath, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { BANK_ACCOUNT_ALREADY_USED, SOMETHING_WENT_WRONG } from 'constants/errors'
import { AxiosError } from 'axios'

const CreateVendorContainer: FC = () => {
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [createVendorMut, { isLoading }] = useMutation(createVendor, {
    onSuccess: (data) => {
      push(`${generatePath(VENDOR_DOCUMENTS, { id: data.data.id })}?backUrl=${VENDOR_LIST_URL}`)
      enqueueSnackbar('Vendor successfully created')
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'VENDOR_FOR_COMPANY_EXISTS')
        enqueueSnackbar('Vendor with the current company already exists')
      else if (err.response?.data.description === 'ANOTHER_COMPANY_ATTACHED_TO_USER') enqueueSnackbar('Vendor with the current email already exists')
      else if (err.response?.data.description === 'BANK_ACCOUNT_USED') enqueueSnackbar(BANK_ACCOUNT_ALREADY_USED)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSubmit = useCallback((values) => {
    createVendorMut(values)
  }, [])

  return <VendorForm submit={handleSubmit} label="Add New Vendor" loading={isLoading} />
}

export default CreateVendorContainer

import { useCallback, useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import TableComponent, { TableComponentType } from 'UI/Table'
import { convertDateWithTime, convertEmptyField } from 'utils/formatters'
import { IHistoricalDataItem } from 'typescript/interfaces/deals'
import { arrayField, dateFields, fieldToTitleMap, percentFields } from 'containers/Deal/DealAuditLog/constants'

export const TableAuditLog = TableComponent as TableComponentType<IHistoricalDataItem>

export const useAuditLog = () => {
  const classes = useTableComponentStyle()

  const getValue = useCallback((item: IHistoricalDataItem, isNew: boolean) => {
    if (dateFields.includes(item.fieldName)) {
      return convertDateWithTime(isNew ? item.newValue : item.oldValue)
    }

    if (percentFields.includes(item.fieldName)) {
      return `${convertEmptyField(isNew ? item.newValue : item.oldValue)} %`
    }

    if (arrayField.includes(item.fieldName)) {
      return convertEmptyField(JSON.parse(isNew ? item.newValue : item.oldValue).join(','))
    }

    return isNew ? convertEmptyField(item.newValue) : convertEmptyField(item.oldValue)
  }, [])

  const columns = useRef<Array<IColumn<IHistoricalDataItem>>>([
    {
      label: 'Field',
      field: 'month',
      bodyCellClassName: classes.accentCell,
      render: (data) => fieldToTitleMap[data.fieldName] ?? data.fieldName,
    },
    {
      label: 'Before change',
      field: 'collected',
      bodyCellClassName: classes.accentCell,
      render: (data) => getValue(data, false),
    },
    {
      label: 'After change',
      field: 'collected',
      bodyCellClassName: classes.accentCell,
      render: (data) => getValue(data, true),
    },
  ])

  return {
    columns: columns.current,
  }
}

import React from 'react'
import ResetPasswordForm from 'components/ResetPasswordForm'
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { useHistory } from 'react-router-dom'
import { SIGN_IN_URL } from 'constants/routes'
import { bg2 } from 'containers/MaterialUiContainer/mainTheme'

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.secondary.main,
    '& path': {
      fill: theme.palette.secondary.main,
    },
  },
  iconButton: {
    background: bg2,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  text: {
    color: theme.palette.secondary.dark,
    fontSize: '1.0625rem',
  },
}))

const ResetPasswordPage = () => {
  const s = useStyles()
  const { push } = useHistory()
  const handleGoBack = () => push(SIGN_IN_URL)
  return (
    <Box position="relative" display="flex" alignItems="center" justifyContent="center" height="100vh">
      <Box position="absolute" top="3rem" left="3rem" display="flex" alignItems="center">
        <IconButton className={s.iconButton} onClick={handleGoBack} color="inherit">
          <ArrowLeftIcon className={s.icon} />
        </IconButton>
        <Box ml="1rem">
          <Typography className={s.text}>Back to Sign in</Typography>
        </Box>
      </Box>
      <ResetPasswordForm />
    </Box>
  )
}

export default ResetPasswordPage

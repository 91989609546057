import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { BASE_APP_URL } from 'constants/routes'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useMutation } from 'react-query'
import { generatePath, useHistory } from 'react-router-dom'
import RequiredOption from 'UI/Select/RequiredOption'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import SelectComponent from 'UI/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useStates } from 'hooks/useStates'
import { getPrbcReport } from 'services/clients'
import { IPrbcReportForm } from 'typescript/interfaces/reports'
import { downloadFile } from 'utils/downloadFile'
import { TinType } from 'typescript/enums/integrations'
import DatePicker from 'UI/DatePicker/DatePicker'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'
import { getEnumElement } from './utils'
import { schemaAddPrbcReport } from './schema'
import { useStyles } from './styles'

const tinTypes = getEnumElement(TinType)

export const PrbcReportForm = () => {
  const { push } = useHistory()
  const snack = useSnackbar()
  const s = useStyles()
  const { data: statesData } = useStates()

  const [mutateCreatePrbcReport, { isLoading: isCreateLoading }] = useMutation(getPrbcReport, {
    onSuccess(data) {
      downloadFile(data)
      push(`${generatePath(BASE_APP_URL)}`)
      snack.enqueueSnackbar(<Typography>Successfully created PRBC Report</Typography>)
    },
    onError() {
      snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  // const [mutateValidatePrbcReport, { isLoading: isValidateLoading }] = useMutation(getCreditReportValidation, {
  //   onError(error: AxiosError) {
  //     if (error.response?.data.errors) {
  //       error.response?.data.errors.forEach((err: string) => {
  //         snack.enqueueSnackbar(err)
  //       })
  //     } else {
  //       snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
  //     }
  //   },
  // })

  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik<IPrbcReportForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      address: '',
      state: '',
      postalCode: '',
      city: '',
      tinType: '',
      taxId: '',
      birthDate: null,
    },
    validationSchema: schemaAddPrbcReport(),
    onSubmit: async (formValues) => {
      // const validationRequest = await mutateValidatePrbcReport(formValues)
      // if (validationRequest?.status === 200) {
      //   mutateCreatePrbcReport(formValues)
      // }
      mutateCreatePrbcReport(formValues)
    },
  })

  return (
    <Grid
      container
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSubmit()
      }}
      spacing={8}
    >
      <Grid item xs={12}>
        <Paper className={s.paper}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography className={s.heading}>{'Create PRBC Report'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" color="textSecondary">
                Personal Info
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                value={values.firstName}
                name="firstName"
                onChange={handleChange}
                fullWidth
                label={<RequiredOption label="Consumer's First Name" />}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                value={values.lastName}
                name="lastName"
                onChange={handleChange}
                fullWidth
                label={<RequiredOption label="Consumer's Last Name" />}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                disableFuture
                name="birthDate"
                value={values.birthDate ? values.birthDate : null}
                onChange={(date) => setFieldValue('birthDate', convertDateForPicker(date))}
                error={Boolean(touched.birthDate && errors.birthDate) || !isValidDate(values.birthDate)}
                helperText={(touched.birthDate && errors.birthDate) || (!isValidDate(values.birthDate) && 'MM/DD/YYYY')}
                inputVariant="outlined"
                variant="dialog"
                type="keyboard"
                label={'Birth Date'}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" color="textSecondary">
                Postal Address
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
                value={values.address}
                name="address"
                onChange={handleChange}
                label={<RequiredOption label="Address" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(touched.city && errors.city)}
                variant="outlined"
                helperText={touched.city && errors.city}
                value={values.city}
                name="city"
                onChange={handleChange}
                label={<RequiredOption label="City" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                helperText={touched.state && errors.state}
                error={Boolean(touched.state && errors.state)}
                value={values.state}
                name="state"
                onChange={handleChange}
                label={<RequiredOption label="State" />}
                fullWidth
              >
                {statesData?.data.map((state) => (
                  <MenuItem key={state.id} value={state.abbreviation}>
                    {state.name}
                  </MenuItem>
                ))}
              </SelectComponent>
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(touched.postalCode && errors.postalCode)}
                variant="outlined"
                helperText={touched.postalCode && errors.postalCode}
                value={values.postalCode}
                name="postalCode"
                onChange={handleChange}
                label={<RequiredOption label="Postal Code" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" color="textSecondary">
                TIN Info
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                helperText={touched.tinType && errors.tinType}
                error={Boolean(touched.tinType && errors.tinType)}
                value={values.tinType}
                name="tinType"
                onChange={handleChange}
                label={<RequiredOption label="TIN type" />}
                fullWidth
              >
                {tinTypes}
              </SelectComponent>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={<RequiredOption label="Tax Identification Number" />}
                name="taxId"
                value={values.taxId}
                onChange={handleChange}
                error={Boolean(touched.taxId && errors.taxId)}
                helperText={touched.taxId && errors.taxId}
              />
            </Grid>
          </Grid>
          <Box mt="auto" pt="2rem">
            <Button disabled={isCreateLoading} color="primary" variant="contained" type="submit">
              Complete
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { NOTIFICATIONS_SENT_URL } from 'constants/routes'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { useLatestDealsStyle } from 'containers/Deal/LatestDeals/style'
import { usePermission } from 'hooks/usePermission'
import { SENT_NOTIFICATIONS_PERM } from 'constants/permissions'

interface Props {
  tab: 'new' | 'all'
  onTabChange: (tab: 'new' | 'all') => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

const NotificationsHeader = ({ tab, onTabChange }: Props) => {
  const { hasPermission } = usePermission()
  const { push, goBack } = useHistory()
  const s = useStyles()
  const sDeals = useLatestDealsStyle()
  return (
    <Box className={s.container} p="3rem" display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box mr="1.5rem">
          <IconButton color="secondary" onClick={goBack} className={sDeals.navButton}>
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography style={{ marginTop: '0.5rem' }} variant="h2" color="textSecondary">
          All Notifications
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Button onClick={() => onTabChange('all')} color={tab === 'all' ? 'primary' : 'secondary'} variant="contained">
          All
        </Button>
        <Box ml="1rem">
          <Button onClick={() => onTabChange('new')} color={tab === 'new' ? 'primary' : 'secondary'} variant="contained">
            New
          </Button>
        </Box>
        {hasPermission(SENT_NOTIFICATIONS_PERM) && (
          <Box ml="1rem">
            <Button onClick={() => push(NOTIFICATIONS_SENT_URL)} color="secondary" variant="outlined">
              Sent notifications
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default NotificationsHeader

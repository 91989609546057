import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { fade } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'

interface IInhouseVsOuthouseProps {
  inhouse: number
  outhouse: number
}

const InhouseVsOuthouseChart: FC<IInhouseVsOuthouseProps> = ({ inhouse, outhouse }) => {
  const theme = useTheme()
  const fontSizeHtml = parseFloat(getComputedStyle(document.documentElement).fontSize) / 4
  const data = [
    { name: 'Outhouse', value: outhouse },
    { name: 'Inhouse', value: inhouse },
  ]
  return (
    <Box p={5}>
      <Typography>Inhouse vs Outhouse</Typography>
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          <Typography>OUTHOUSE: {outhouse}</Typography>
        </Box>
        <Box mt="1rem" height="15rem" flex={1} mb="1rem">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie dataKey="value" startAngle={90} endAngle={450} data={data} innerRadius={fontSizeHtml * 10.43} outerRadius={fontSizeHtml * 11.06}>
                <Tooltip labelFormatter={(index) => data[index as number].name} />
                <Cell strokeWidth={0} fill="#D0DDE8" />
                <Cell strokeWidth={0} fill={theme.palette.primary.main} />
              </Pie>
              <Pie dataKey="value" startAngle={90} endAngle={450} data={data} innerRadius={fontSizeHtml * 10.43} outerRadius={fontSizeHtml * 16.19}>
                <Tooltip labelFormatter={(index) => data[index as number].name} />
                <Cell strokeWidth={0} fill="transparent" />
                <Cell strokeWidth={0} fill={fade(theme.palette.primary.light, 0.15)} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Box flex={1}>
          <Typography align="right">INHOUSE: {inhouse}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default InhouseVsOuthouseChart

import { privateInstance } from 'services/axios/privateInstance'
import { API_CREATE_HOLIDAY, API_GET_HOLIDAYS, API_DELETE_HOLIDAY } from 'constants/apiUrl'
import { IHolidayCreateForm, IHolidayListItem, IHolidayListParams } from 'typescript/interfaces/holidays'
import { AxiosResponse } from 'axios'
import { generatePath } from 'react-router-dom'

export const createHoliday = (values: IHolidayCreateForm) =>
  privateInstance.post<IHolidayCreateForm, AxiosResponse<IHolidayListItem>>(API_CREATE_HOLIDAY, values)

export const deleteHoliday = (id: number) =>
  privateInstance.delete<typeof id, AxiosResponse<boolean>>(
    generatePath(API_DELETE_HOLIDAY, {
      id,
    }),
  )

export const getHoliday = (params: IHolidayListParams) =>
  privateInstance.get<IHolidayListParams, AxiosResponse<Array<IHolidayListItem>>>(API_GET_HOLIDAYS, {
    params: params,
  })

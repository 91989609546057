import React, { FC } from 'react'
import SignUpForm from 'components/SignUpForm'
import { Box } from '@material-ui/core'

const SignUpPage: FC = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <SignUpForm />
    </Box>
  )
}

export default SignUpPage

import React, { ChangeEvent, FC, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Button from 'UI/Button/ButtonWithPreloader'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactComponent as CloseIcon } from 'assets/svg/CloseIcon.svg'
import { IconButton } from '@material-ui/core'
import { useLatestDealsStyle } from 'containers/Deal/LatestDeals/style'

interface IUploadImageProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  label: string
  urlImage?: string
  handleDelete?: () => void
}

const useUploadImage = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))

const UploadImage: FC<IUploadImageProps> = ({ handleChange, label, urlImage, handleDelete }) => {
  const s = useLatestDealsStyle()
  const classes = useUploadImage()
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <Box position="relative">
      {urlImage && (
        <Box maxWidth={250} maxHeight={250} position="relative">
          <Box position="absolute" right={0} top={0} zIndex={10}>
            <IconButton size="small" color="secondary" onClick={handleDelete} className={s.navButton}>
              <CloseIcon />
            </IconButton>
          </Box>
          <img src={urlImage} alt="company" className={classes.image} />
        </Box>
      )}
      {!urlImage && (
        <Box display="inline-flex" alignItems="center" justifyContent="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              inputRef.current && inputRef.current.click()
            }}
          >
            {label}
          </Button>
        </Box>
      )}
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => {
          handleChange(e)
          if (inputRef.current) inputRef.current.value = ''
        }}
        style={{
          position: 'absolute',
          zIndex: -1,
          opacity: 0,
        }}
      />
    </Box>
  )
}

export default UploadImage

import { IState } from 'typescript/interfaces/companies'
import { ExportType } from 'typescript/enums/export'
import { IIsoDocuments, IIsoLightItem } from 'typescript/interfaces/iso'
import { FunderRequestStatusEnum } from 'typescript/enums/funder'
import { IUserPage } from 'typescript/interfaces/users'

export interface IRepEntity {
  id: number
  firstName: string
  lastName: string
  phone: string
  mobile: string
  email: string
  fax: string
  dealsCount: number
  documents: IIsoDocuments[]
  userPage: IUserPage
  nameOnCheck: string
  nameOnBankAccount: string
  managementFee: number
  businessName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  state: IState
  isCrmFeeActive: boolean
}

export interface IRepListItem {
  businessName: string
  id: number
  firstName: string
  lastName: string
  phone: string
  mobile: string
  startingBalance: number
  dealsCount: number
  lastTransactionDate: Date
  state: IState
  userPage: IUserPage
}

export interface IRepListItemLight {
  id: number
  name: string
}

export enum SortFieldRep {
  id = 'Id',
  businessName = 'BusinessName',
  firstName = 'FirstName',
  lastName = 'LastName',
  phone = 'Phone',
  state = 'State',
  ofDeals = 'OfDeals',
  startingBalance = 'StartingBalance',
  availableBalance = 'AvailableBalance',
  lastTransactions = 'LastTransactions',
}

export interface IInitialValuesRepresentative {
  firstName: string
  lastName: string
  phone: string
  mobile: string
  email: string
  fax: string
  userPage: IUserPage
  nameOnCheck: string
  businessName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  stateId: number | ''
  isCrmFeeActive: boolean
}

export interface IRepLight {
  id: number
  name: string | null
}

export interface IRepExportParams {
  ExportType: ExportType
}

export interface IRepPortfolioSummaryExportParams {
  ExportType: ExportType
  Date: string
}

export interface IConnectRepFileLinksToRepParams {
  documentUrl: string
  representativeId: number | string
  description: string
}

export interface IRepPerformanceParams {
  RepId: string
  FromDate: string | null
  ToDate: string | null
}

export interface IRepPerformanceParamsFormik extends IRepPerformanceParams {
  ShowDeals: boolean
}

export interface IPerformanceDealIsos {
  id: number
  iso: IIsoLightItem
  dealsCount: number
}

export interface IPerformanceDealStatus {
  id: number
  paidStatus: string
  dealsCount: number
}

export interface IRepPerformanceEntity {
  id: number
  repId: number
  reportDate: string
  totalAmount: number
  avgAmount: number
  totalPsf: number
  avgPsf: number
  totalCommission: number
  avgCommission: number
  totalPnl: number
  avgPnl: number
  dealsCount: number
  defaultRatio: number
  totalAmountIH: number
  avgAmountIH: number
  totalPsfIH: number
  avgPsfIH: number
  totalCommissionIH: number
  avgCommissionIH: number
  totalPnlIH: number
  avgPnlIH: number
  defaultRatioIH: number
  dealsCountIH: number
  totalAmountOH: number
  avgAmountOH: number
  totalPsfOH: number
  avgPsfOH: number
  totalCommissionOH: number
  avgCommissionOH: number
  totalPnlOH: number
  avgPnlOH: number
  defaultRatioOH: number
  dealsCountOH: number
  fundedPerMonthIH: number
  repPerformanceDealIsos: IPerformanceDealIsos[]
  repPerformanceDealPaidStatuses: IPerformanceDealStatus[]
}

export interface IFundedPerMonthData {
  reportDate: string
  fundedPerMonthIH: number
}

export interface IEquityData {
  reportDate: string
  pnlTotal: number
}

export interface IRequestFunderListItem {
  id: number
  businessName: string
  firstName: string | null
  lastName: string | null
  phone: string | null
  state: IState | null
  deals: number | null
  defaultRate: number | null
  commission: number | null
  infoRequest: FunderRequestStatusEnum | null
  cooperationRequest: FunderRequestStatusEnum | null
  managementFee: string | null | number
}

export interface IApprovedFunderListItem {
  id: number
  businessName: string
  firstName: string
  lastName: string
  limit: number
}

export interface ISetLimitModalData {
  representativeId: number | null
  syndicatorId?: number
  limit: number | null
}

export interface IRequestAnonymouse {
  token: string
  cooperationRequestStatus?: FunderRequestStatusEnum
  infoRequestStatus?: FunderRequestStatusEnum
}

export interface ISetManagementFee {
  funderId: string | number
  feeValue: string | number
}

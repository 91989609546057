import React, { FC, useCallback, useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useTableComponentStyle } from 'UI/Table/style'
import { IUploadedCategoriesKeys, IDealDocumentConnectParamsResponse } from 'typescript/interfaces/deals'
import { Link } from 'react-router-dom'
import { getFile } from 'services/storage'
import { createLinkToDownLoad } from 'utils/downloadFile'

const TableRelatedDocuments = TableComponent as TableComponentType<IDealDocumentConnectParamsResponse>

interface IDealDocumentProps {
  documents: Array<IDealDocumentConnectParamsResponse>
}

const RelatedDocumentsTable: FC<IDealDocumentProps> = (props) => {
  const classes = useTableComponentStyle()
  const { documents } = props

  const handleDownLoadFile = useCallback((url, name) => {
    getFile(url).then((res) => {
      createLinkToDownLoad(res.data, name)
    })
  }, [])

  const getDescription = useCallback((type: IUploadedCategoriesKeys) => {
    switch (type) {
      case 'Application': {
        return 'Application'
      }
      case 'BankStatements': {
        return 'Bank Statements'
      }
      case 'CreditProcessingStatements': {
        return 'Credit Processing Statements'
      }
      case 'DriverLicense': {
        return 'Driver License'
      }
      case 'Optional': {
        return 'Optional Document'
      }
      case 'VoidedCheck': {
        return 'Voided Check'
      }
      case 'W9': {
        return 'W9'
      }
      default: {
        return type
      }
    }
  }, [])

  const columnsRef = useRef<IColumn<IDealDocumentConnectParamsResponse>[]>([
    {
      label: 'Name',
      field: 'name',
      bodyCellClassName: classes.accentCell,
      render: (data) => {
        return (
          <Link
            to={data.documentUrl}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              handleDownLoadFile(data.documentUrl, data.description)
            }}
          >
            {data.description}
          </Link>
        )
      },
    },
    {
      label: 'Description',
      field: 'description',
      bodyCellClassName: classes.accentCell,
      render: (data) => getDescription(data.documentType),
    },
  ])
  return <TableRelatedDocuments columns={columnsRef.current} data={documents} />
}

export default RelatedDocumentsTable

import React, { FC } from 'react'
import Modal, { ModalProps } from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useModalStyle = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: '2rem',
    maxHeight: '97vh',
    overflow: 'auto',
  },
}))

const ModalComponent: FC<ModalProps> = ({ open, children, ...rest }) => {
  const classes = useModalStyle()
  return (
    <Modal open={open} {...rest}>
      <div className={classes.wrapper}>
        <Box display="flex">
          <Paper elevation={0} className={classes.paper}>
            {children}
          </Paper>
        </Box>
      </div>
    </Modal>
  )
}

export default ModalComponent

import { Box, Button, CircularProgress, Grid, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { OUTHOUSE_COMPANIES_URL, COMPANY_URL } from 'constants/routes'
import { useFormik } from 'formik'
import { useStates } from 'hooks/useStates'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { createOuthouseCompany, editOuthouseCompany, getOuthouseCompany } from 'services/companies'
import { OuthouseCompanyForm, OuthouseCompany } from 'typescript/interfaces/companies'
import SelectComponent from 'UI/Select'
import ReactInputMask from 'react-input-mask'
import RequiredOption from 'UI/Select/RequiredOption'
import { schemaAddEditCompany } from './schema'

const useStyles = makeStyles((theme) => ({
  selectLabel: {
    color: theme.palette.secondary.dark,
  },
  backButton: {
    marginRight: '1.25rem',
  },
  heading: {
    fontSize: '2.25rem',
    color: theme.palette.text.secondary,
    marginBottom: '2rem',
    fontWeight: 500,
  },
  subHeading: {
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
    marginBottom: '2rem',
    fontWeight: 500,
  },
}))

const AddEditOuthouseCompanyForm = () => {
  const { id } = useParams<{ id: string }>()
  const { push, goBack } = useHistory()
  const snack = useSnackbar()
  const s = useStyles()
  const { data: statesData } = useStates()

  const { isLoading } = useQuery(['company', +id!], () => getOuthouseCompany(id || ''), {
    enabled: !!id,
    retry: false,
    onSuccess(res) {
      const { state, ...bufferFieldsToBeSet }: OuthouseCompany = res.data
      const fieldsToBeSet: OuthouseCompanyForm = { ...bufferFieldsToBeSet, stateId: state?.id }
      setValues(fieldsToBeSet)
    },
  })

  const [mutateCreateOuthouseCompany, { isLoading: isCreateLoading }] = useMutation(createOuthouseCompany, {
    onSuccess(res) {
      push(OUTHOUSE_COMPANIES_URL)
      snack.enqueueSnackbar(<Typography>Successfully created company</Typography>)
    },
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
  })
  const [mutateUpdateOuthouseCompany, { isLoading: isUpdateLoading }] = useMutation(editOuthouseCompany, {
    onSuccess(res) {
      push(COMPANY_URL.replace(':id', id))
      snack.enqueueSnackbar(<Typography>Successfully updated company</Typography>)
    },
    onError() {
      snack.enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
  })

  const { values, setValues, handleChange, handleSubmit, errors, touched, resetForm } = useFormik<OuthouseCompanyForm>({
    initialValues: {
      name: '',
      firstName: '',
      lastName: '',
      phone: '',
      mobile: '',
      email: '',
      city: '',
      street: '',
      zipCode: '',
      federalTaxId: '',
      stateId: null,
    },
    validationSchema: schemaAddEditCompany,
    onSubmit: (formValues) => {
      if (id) {
        mutateUpdateOuthouseCompany(formValues)
      } else {
        mutateCreateOuthouseCompany(formValues)
      }
    },
  })

  useEffect(() => {
    resetForm()
  }, [id])

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="calc(100vh - 98px - 2rem)">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box p="3rem">
            <Typography className={s.heading}>{id ? 'Edit Company' : 'Add New Outhouse Company'}</Typography>
            <Grid spacing={8} container lg={8} xs={12}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="Organization Name" />}
                />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                  value={values.firstName}
                  name="firstName"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="First name" />}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                  value={values.lastName}
                  name="lastName"
                  onChange={handleChange}
                  fullWidth
                  label={<RequiredOption label="Last name" />}
                />
              </Grid>
              <Grid item xs={6}>
                <ReactInputMask name="mobile" mask="(999)-999-9999" value={values.mobile} onChange={handleChange}>
                  {(props: any) => (
                    <TextField
                      {...props}
                      error={Boolean(errors.mobile && touched.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      label="Mobile"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={6}>
                <ReactInputMask name="phone" mask="(999)-999-9999" value={values.phone} onChange={handleChange}>
                  {(props: any) => (
                    <TextField
                      {...props}
                      error={Boolean(errors.phone && touched.phone)}
                      helperText={touched.phone && errors.phone}
                      label={<RequiredOption label="Phone" />}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  fullWidth
                  label="Email"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.street && !!errors.street}
                  helperText={touched.street && errors.street}
                  value={values.street}
                  name="street"
                  onChange={handleChange}
                  fullWidth
                  label="Street"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                  value={values.city}
                  name="city"
                  onChange={handleChange}
                  fullWidth
                  label="City"
                />
              </Grid>

              <Grid item xs={6}>
                <SelectComponent
                  labelProps={{ className: s.selectLabel }}
                  error={!!touched.stateId && !!errors.stateId}
                  helperText={touched.stateId && errors.stateId}
                  value={values.stateId}
                  name="stateId"
                  onChange={handleChange}
                  fullWidth
                  label="State"
                >
                  {statesData?.data.map((state) => (
                    <MenuItem value={state.id}>{state.name}</MenuItem>
                  ))}
                </SelectComponent>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  error={!!touched.zipCode && !!errors.zipCode}
                  helperText={touched.zipCode && errors.zipCode}
                  name="zipCode"
                  fullWidth
                  label="Zip Code"
                  value={values.zipCode}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box mt="2rem">
              <Button color="secondary" variant="contained" onClick={() => goBack()} className={s.backButton}>
                Cancel
              </Button>
              <Button disabled={isCreateLoading || isUpdateLoading} color="primary" variant="contained" type="submit">
                Complete
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default AddEditOuthouseCompanyForm

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 448,
    maxWidth: '40%',
    background: '#3D4967',
    opacity: 0.85,
    height: '100%',
    padding: '36px 56px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textContainer: {
    marginBottom: 'calc(50% + 36px)',
  },
  text: {
    color: '#fff',
    fontWeight: 500,
    fontSize: 36,
  },
  smallText: {
    color: '#fff',
    fontSize: 19,
  },
}))

import React, { FC } from 'react'
import { useFormik } from 'formik'
import { IUser } from 'typescript/interfaces/users'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from 'UI/Button/ButtonWithPreloader'
import RequiredOption from 'UI/Select/RequiredOption'
import ReactInputMask from 'react-input-mask'
import Checkbox from 'UI/Checkbox'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { schemaUserForm } from 'components/UserForm/schema'

interface IUserFormProps {
  initValues?: Partial<IUser>
  submit: (values: Partial<IUser>) => void
  close: () => void
  isLoading?: boolean
}

const initValuesDefault: Partial<IUser> = {
  id: 0,
  firstName: '',
  lastName: '',
  isActive: false,
  phone: '',
}

const UserForm: FC<IUserFormProps> = ({ initValues = initValuesDefault, submit, close, isLoading }) => {
  const { handleSubmit, values, errors, touched, handleChange, setFieldValue } = useFormik<Partial<IUser>>({
    initialValues: initValues,
    onSubmit: submit,
    validationSchema: schemaUserForm,
  })
  return (
    <Box width={300} maxWidth="100%">
      <Box mb="2rem">
        <Typography color="textSecondary" variant="h2">
          User Form
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <TextField
            label={<RequiredOption label="Email" />}
            name="email"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box mt="2rem">
          <TextField
            label={<RequiredOption label="First Name" />}
            name="firstName"
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box mt="2rem">
          <TextField
            label={<RequiredOption label="Last Name" />}
            name="lastName"
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box mt="2rem">
          <ReactInputMask name="phone" mask="(999)-999-9999" value={values.phone} onChange={handleChange}>
            {(props: any) => (
              <TextField
                {...props}
                error={Boolean(errors.phone && touched.phone)}
                helperText={touched.phone && errors.phone}
                label={<RequiredOption label="Phone" />}
                variant="outlined"
                fullWidth
              />
            )}
          </ReactInputMask>
        </Box>
        <Box mt="2rem">
          <FormControlLabel
            control={
              <Checkbox name="isActive" checked={values.isActive} color="primary" onChange={(e) => setFieldValue('isActive', e.target.checked)} />
            }
            label={<Typography color="textSecondary">Active</Typography>}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt="2rem">
          <Button color="secondary" variant="contained" onClick={close}>
            Cancel
          </Button>
          <Box ml="1rem">
            <Button loading={isLoading} color="primary" variant="contained" type="submit">
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default UserForm

import { MenuItem } from '@material-ui/core'
import React from 'react'

export const getEnumElement = (e: Record<string, string>) => {
  return Object.entries(e).map(([key, value], i) => {
    const uniqueKey = `${key}-${i}`
    return (
      <MenuItem key={uniqueKey} value={key}>
        {value}
      </MenuItem>
    )
  })
}

import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
import DailyChart from 'components/Dashboard/DailyChart'
import TermFactorChart from 'components/Dashboard/TermFactor Avg'
import PaidOffAvg from 'components/Dashboard/PaidOffAvg'
import PNLChart from 'components/Dashboard/PNL'
import TopByRemaining from 'components/Dashboard/TopByRemaining'
import TopByAdvancedAmount from 'components/Dashboard/TopByAdvancedAmount'
import TopByCollectedAmount from 'components/Dashboard/TopByCollectedAmount'
import LatestDeals from 'components/Dashboard/LatestDeals'
import DetailedChart from 'components/Dashboard/DetailedChart'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ChartEnumKey } from 'typescript/enums/charts'
import { DASHBOARD_VIEW_FIRST_WITH_CHART } from 'constants/routes'
import { useQuery } from 'react-query'
import { getDealStatsForChart } from 'services/deals'
import { endOfDay, startOfDay, subYears } from 'date-fns'

const FirstViewDashboard: FC = () => {
  const { chart } = useParams<{ chart: ChartEnumKey }>()
  const { push } = useHistory()

  const { data: chartsData } = useQuery(
    ['charts-data'],
    () =>
      getDealStatsForChart({
        From: subYears(startOfDay(new Date()), 1).toISOString(),
        To: endOfDay(new Date()).toISOString(),
      }),
    // eslint-disable-next-line function-paren-newline
  )

  return (
    <Grid container>
      {chart ? (
        <Grid container item md={8}>
          <DetailedChart stats={chartsData?.data} />
        </Grid>
      ) : (
        <Grid container item md={8}>
          <Grid item container md={6}>
            <Grid item md={6}>
              <Box pr={3} pb={3}>
                <DailyChart
                  stats={chartsData?.data}
                  onCollapse={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { chart: 'daily', from: '1' }))}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box pr={3} pb={3}>
                <TermFactorChart
                  stats={chartsData?.data}
                  onCollapse={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { chart: 'termFactor', from: '1' }))}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box pr={3}>
                <PaidOffAvg
                  stats={chartsData?.data}
                  onCollapse={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { chart: 'paidOffAvg', from: '1' }))}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box pr={3}>
                <PNLChart
                  stats={chartsData?.data}
                  onCollapse={() => push(generatePath(DASHBOARD_VIEW_FIRST_WITH_CHART, { chart: 'pnl', from: '1' }))}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Box pl={3} pr={6}>
              <TopByRemaining />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box pt={6} pr={3}>
              <TopByAdvancedAmount />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box pl={3} pt={6} pr={6}>
              <TopByCollectedAmount />
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container item md={4}>
        <Box width="100%">
          <LatestDeals />
        </Box>
      </Grid>
    </Grid>
  )
}

export default FirstViewDashboard

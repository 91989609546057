import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  rowTitle: {
    color: theme.palette.info.contrastText,
  },
  blockTitle: { marginBottom: '2rem' },
}))

import React, { FC, useCallback, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useACHDebitStyle } from 'containers/ACHDebit/style'
import cn from 'classnames'
import { convertToPriceFormat } from 'utils/formatters'
import SearchField from 'UI/SearchField'
import DatePicker from 'UI/DatePicker/DatePicker'
import Button from 'UI/Button/ButtonWithPreloader'
import { TableAchDebit, useAchDebit } from 'hooks/useAchDebit'
import { useMutation } from 'react-query'
import { duplicateAllPayments, getAchList, processDebits } from 'services/ach'
import { usePaginationList } from 'hooks/usePaginationList'
import { AchEntity } from 'typescript/interfaces/achDebit'
import { useAch } from 'hooks/useAch'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const AchDebitContainer: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useACHDebitStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const { setPagination, pagination, handleFetchMore, handleSort, handleSearch } = usePaginationList<AchEntity>({
    initState: {
      date: new Date().toISOString(),
      data: [],
      search: '',
      order: '',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })
  const [getAch, { isLoading }] = useMutation(getAchList)

  const [processDebitsMut, { isLoading: isLoadingProcessing }] = useMutation(processDebits, {
    onSuccess: () => {
      setPagination((prevState) => ({
        ...prevState,
        data: [],
      }))
      enqueueSnackbar('Debits successfully processed')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [duplicatePaymentsMut, { isLoading: isLoadingDuplicate }] = useMutation(duplicateAllPayments, {
    onSuccess: () => {
      refetchDebits()
      enqueueSnackbar('Payments Duplicated')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    if (pagination.date) {
      getAch(
        {
          PageSize: 20,
          PageIndex: pagination.page,
          Query: pagination.search,
          SortField: pagination.order,
          SortOrder: pagination.sortOrder,
          Date: pagination.date,
          Type: 'Debit',
        },
        {
          onSuccess: (data) => {
            setPagination((prevState) => ({
              ...prevState,
              data: [...prevState.data, ...data.data.transactions],
              hasMore: data.data.transactions.length === 20,
              totalPayments: data.data.totalPayment,
            }))
          },
        },
      )
    }
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder, pagination.date])

  const refetchDebits = useCallback(() => {
    if (pagination.page === 0)
      getAch(
        {
          PageSize: 20,
          PageIndex: pagination.page,
          Query: pagination.search,
          SortField: pagination.order,
          SortOrder: pagination.sortOrder,
          Date: pagination.date,
          Type: 'Debit',
        },
        {
          onSuccess: (data) => {
            setPagination((prevState) => ({
              ...prevState,
              page: 0,
              data: [...data.data.transactions],
              hasMore: data.data.transactions.length === 20,
            }))
          },
        },
      )
    else
      setPagination((prevState) => ({
        ...prevState,
        page: 0,
        data: [],
        hasMore: true,
      }))
  }, [pagination])

  const {
    setStopState,
    setEditAchState,
    setCancelFutureDebits,
    ModalEdit,
    ModalStop,
    ModalCancel,
    ModalDuplicate,
    setDuplicatePaymentState,
  } = useAch({
    callBackEdit: (id, date) => {
      refetchDebits()
    },
    callBackStop: (id) => {
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.map((ach) => ({
          ...ach,
          status: ach.paymentId === id ? 'Stopped' : ach.status,
        })),
      }))
    },
    callBackCancel: (id) => {
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.filter((ach) => ach.paymentId !== id),
      }))
    },
    callBackDuplicate: () => {
      refetchDebits()
    },
  })
  const { columns } = useAchDebit({
    handleStop: (id, category) => {
      setStopState({
        open: true,
        id: id,
        category: category,
      })
    },
    handleEdit: (id, date, category) => {
      setEditAchState({
        open: true,
        id: id,
        date: date,
        category: category,
      })
    },
    handleCancel: (id) => {
      setCancelFutureDebits({
        open: true,
        id: id,
      })
    },
    handleDuplicate: (id) => {
      setDuplicatePaymentState({
        open: true,
        id: id,
      })
    },
  })

  return (
    <Paper className={additionalStyle.wrapper} elevation={0}>
      <Box py="3rem" px="3rem">
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="h3" color="textSecondary">
              ACH Debit
            </Typography>
          </Box>
          <Box m="0 auto">
            {pagination.totalPayments !== undefined && (
              <>
                <Typography gutterBottom className={cn(classes.darkTypography)}>
                  Total Payments
                </Typography>
                <Typography color="textSecondary" variant="h4">
                  {convertToPriceFormat(pagination.totalPayments || 0)}
                </Typography>
              </>
            )}
          </Box>
          <Box>
            <SearchField label="Search the deal" handleSearch={(search) => handleSearch(search)} />
          </Box>
          <Box ml="1rem">
            <DatePicker
              disablePast
              type="keyboard"
              label="Sort by Date"
              value={pagination.date}
              onChange={(date) => {
                setPagination((prevState) => ({
                  ...prevState,
                  hasMore: true,
                  data: [],
                  date: date,
                  page: 0,
                }))
              }}
            />
          </Box>
          <Box ml="1rem" display="flex" alignItems="center">
            <Button variant="contained" color="primary" loading={isLoadingProcessing} onClick={() => processDebitsMut(pagination.date)}>
              Process Payments
            </Button>
            <Box ml="1rem">
              <Button variant="outlined" color="secondary" onClick={() => duplicatePaymentsMut(pagination.date)} loading={isLoadingDuplicate}>
                Duplicate All Payments
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt="2rem">
        <TableAchDebit
          hasMore={pagination.hasMore}
          loading={isLoading}
          columns={columns}
          data={pagination.data}
          handleSortChange={handleSort}
          handleGetMore={handleFetchMore}
        />
      </Box>
      {ModalEdit}
      {ModalStop}
      {ModalCancel}
      {ModalDuplicate}
    </Paper>
  )
}

export default AchDebitContainer

import { Box, IconButton, Paper, Typography } from '@material-ui/core'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import React, { useEffect } from 'react'
import { ReactComponent as ArrowLeft } from 'assets/svg/ArrowLeftIcon.svg'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as DocumentIcon } from 'assets/svg/DocumentOutlined.svg'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { TableOverallDealsSummary, useOverallDealsSummary } from 'hooks/useOverallDealsSummary'
import { usePaginationList } from 'hooks/usePaginationList'
import { IRepPortfolioSummaryRecord } from 'typescript/interfaces/deals'
import { overAllDealsSummarySchema } from 'containers/RepOverallDealsSummary/schema'
import { useMutation } from 'react-query'
import { getRepPortfolioSummary } from 'services/deals'
import { exportRepPortfolioSummary } from 'services/export'
import { downloadFile } from 'utils/downloadFile'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { ExportType } from 'typescript/enums/export'
import Button from 'UI/Button/ButtonWithPreloader'
import CalculateRestHeightBlock from 'components/CalculateRestHeightBlock'
import SelectComponent from 'UI/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getYear } from 'date-fns'

const currYear = getYear(new Date())

const RepOverallDealsSummaryContainer = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useAdditionalMaterialStyle()
  const { goBack } = useHistory()
  const { pagination, setPagination, handleSort, handleFetchMore } = usePaginationList<IRepPortfolioSummaryRecord>()

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik<{ year: Array<number> | undefined }>({
    initialValues: {
      year: undefined,
    },
    validationSchema: overAllDealsSummarySchema,
    onSubmit: (formValues) => {
      const years = formValues.year as Array<number>
      setPagination((prevState) => ({
        ...prevState,
        data: [],
        page: 0,
        hasMore: true,
        year: years.map((item) => new Date(item, 1).toISOString()),
      }))
    },
  })

  const [getRepPortfolioSummaryMut, { isLoading }] = useMutation(getRepPortfolioSummary, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data.records],
        hasMore: data.data.records.length === 20,
      }))
    },
  })

  const [exportRepPortfolioSummaryMut, { isLoading: isLoadingExportExcel }] = useMutation(exportRepPortfolioSummary, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    if (pagination.year)
      getRepPortfolioSummaryMut({
        Year: pagination.year,
        PageSize: 20,
        PageIndex: pagination.page,
        SortField: pagination.order,
        SortOrder: pagination.sortOrder,
      })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.year])

  const { columns } = useOverallDealsSummary()

  return (
    <Paper className={classes.wrapper} elevation={0}>
      <Box p="2rem">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box mr="1.5rem">
              <IconButton color="secondary" onClick={goBack} className={classes.navButton}>
                <ArrowLeft />
              </IconButton>
            </Box>
            <Box mb="-0.5rem">
              <Typography variant="h2" color="textSecondary">
                Overall Deals Summary
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Box ml="0.75rem">
              <Button
                disabled={!pagination.year}
                loading={isLoadingExportExcel}
                variant="text"
                color="secondary"
                startIcon={<ExcelIcon />}
                onClick={() => {
                  exportRepPortfolioSummaryMut({
                    Date: pagination.year,
                    ExportType: ExportType.excel,
                  })
                }}
              >
                Export To Excel
              </Button>
            </Box>
            <Box ml="0.75rem">
              <Button
                disabled={!pagination.year}
                loading={isLoadingExportExcel}
                variant="text"
                color="secondary"
                startIcon={<DocumentIcon />}
                onClick={() => {
                  exportRepPortfolioSummaryMut({
                    Date: pagination.year,
                    ExportType: ExportType.pdf,
                  })
                }}
              >
                Download as PDF
              </Button>
            </Box>
          </Box>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mt="2.5rem" display="flex" alignItems="flex-start">
            <Box mt="0.75rem">
              <Typography color="textSecondary" noWrap>
                Select Year:
              </Typography>
            </Box>
            <Box mr="0.75rem" ml="2rem" width={400} display="flex">
              {/*<DatePicker*/}
              {/*  error={Boolean(touched.year && errors.year)}*/}
              {/*  helperText={touched.year && errors.year}*/}
              {/*  format="yyyy"*/}
              {/*  label="Select the Year"*/}
              {/*  fullWidth*/}
              {/*  views={['year']}*/}
              {/*  onChange={(date) => setFieldValue('year', date)}*/}
              {/*  value={values.year}*/}
              {/*/>*/}
              <SelectComponent
                error={Boolean(touched.year && errors.year)}
                helperText={touched.year && errors.year}
                multiple
                onChange={(e) => setFieldValue('year', e.target.value)}
                value={values.year ? values.year : []}
              >
                {Array(15)
                  .fill(1)
                  .map((_, index) => (
                    <MenuItem value={currYear - index}>{currYear - index}</MenuItem>
                  ))}
              </SelectComponent>
            </Box>
            <Button type="submit" color="primary" variant="contained">
              Vew Report
            </Button>
          </Box>
        </form>
      </Box>
      <CalculateRestHeightBlock>
        {pagination.year && (
          <TableOverallDealsSummary
            loading={isLoading}
            data={pagination.data}
            handleSortChange={handleSort}
            handleGetMore={handleFetchMore}
            columns={columns}
            hasMore={pagination.hasMore}
          />
        )}
      </CalculateRestHeightBlock>
    </Paper>
  )
}

export default RepOverallDealsSummaryContainer

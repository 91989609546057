import * as yup from 'yup'
import {
  INVALID_SYMBOLS,
  MIN_VALUE_IS_ONE,
  MUST_BE_INTEGER,
  NUMBER_CANNOT_BE_NEGATIVE,
  NUMBERS_ONLY,
  ONLY_DIGITS,
  REQUIRED_FIELD,
} from 'constants/errors'
import { valiadateFloatWithTwoDigits } from 'utils/validateFloatWithTwoDigits'
import { integerReg } from 'constants/regExp'

export const schemaClientDeal = yup.object().shape({
  advanceAmount: yup.string().typeError(NUMBERS_ONLY).required(REQUIRED_FIELD).matches(integerReg, ONLY_DIGITS),
  // .test('float', INVALID_SYMBOLS, function () {
  //   return valiadateFloatWithTwoDigits(+this.parent.advanceAmount)
  // })
  term: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(integerReg, MUST_BE_INTEGER)
    .test('integer', MIN_VALUE_IS_ONE, function (val) {
      return parseInt(val || '') >= 1
    }),
  bankAccountId: yup.string().required(REQUIRED_FIELD),
})

export const schemaOuthouseDeal = yup.object().shape({
  advanceAmount: yup
    .number()
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE)
    .test('float', INVALID_SYMBOLS, function () {
      return valiadateFloatWithTwoDigits(+this.parent.advanceAmount)
    })
    .required(REQUIRED_FIELD),
  term: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(integerReg, MUST_BE_INTEGER)
    .test('integer', MIN_VALUE_IS_ONE, function (val) {
      return parseInt(val || '') >= 1
    }),
})

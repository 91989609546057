import { AxiosResponse } from 'axios'
import { API_CREATE_BANK, API_GET_BANK, API_GET_BANKS, API_UPDATE_BANK } from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { IBankCreateEdit, IBankResponse, SortFieldsBanks } from 'typescript/interfaces/banks'
import { IPagination } from 'typescript/interfaces/pagination'

export const createBank = (values: IBankCreateEdit) => privateInstance.post<IBankCreateEdit, AxiosResponse<IBankCreateEdit>>(API_CREATE_BANK, values)

export const updateBank = (values: IBankCreateEdit) => privateInstance.put<IBankCreateEdit, AxiosResponse<IBankCreateEdit>>(API_UPDATE_BANK, values)

export const getBank = (id: string) => privateInstance.get<IBankCreateEdit, AxiosResponse<IBankResponse>>(API_GET_BANK.replace(':id', id))

export const getBanks = (params: IPagination<SortFieldsBanks>) =>
  privateInstance.get<IBankCreateEdit, AxiosResponse<Array<IBankResponse>>>(API_GET_BANKS, { params })

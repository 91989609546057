import React, { FC } from 'react'
import { useFormik } from 'formik'
import { IInviteClientInitValues } from 'typescript/interfaces/clients'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from 'UI/Button/ButtonWithPreloader'
import { schemaInviteClient } from 'components/InviteClient/schema'

interface IInviteClientFormProps {
  handleSubmit: (values: IInviteClientInitValues) => void
  handleCancel: () => void
  isLoading?: boolean
}

const InviteClientForm: FC<IInviteClientFormProps> = ({ handleSubmit, handleCancel, isLoading }) => {
  const { handleSubmit: submit, errors, touched, values, handleChange } = useFormik<IInviteClientInitValues>({
    validationSchema: schemaInviteClient,
    initialValues: {
      email: '',
    },
    onSubmit: handleSubmit,
  })
  return (
    <Box width={320} maxWidth="100%">
      <form onSubmit={submit}>
        <Box pt="1rem">
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            name="email"
            onChange={handleChange}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Box>
        <Box mt="2rem" display="flex" alignItems="center" justifyContent="flex-end">
          <Button disabled={isLoading} color="primary" variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Box ml="1rem">
            <Button loading={isLoading} color="primary" variant="contained" type="submit">
              Invite
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default InviteClientForm

import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertDate, convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { Box } from '@material-ui/core'
import { IWithdrawalListItem } from 'typescript/interfaces/transactions'
import { EnumStatusWithdrawal, SortFieldsWithdrawal } from 'typescript/enums/transactions'
import Button from 'UI/Button/ButtonWithPreloader'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

export const TableWithdrawalQueues = TableComponent as TableComponentType<IWithdrawalListItem>

interface IUseWithdrawalProps {
  handleClickChangeStatus?: (id: number, status: EnumStatusWithdrawal) => void
  isQueue?: boolean
}

export const useWithdrawalQueuesTable = ({ handleClickChangeStatus, isQueue }: IUseWithdrawalProps) => {
  const classes = useTableComponentStyle()
  const addMatStyle = useAdditionalMaterialStyle()
  const columns = useRef<Array<IColumn<IWithdrawalListItem>>>([
    {
      label: 'Payment #',
      field: SortFieldsWithdrawal.id,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.id),
    },
    ...(isQueue
      ? [
          {
            label: 'User Page',
            field: 'userPageId',
            bodyCellClassName: classes.secondaryBodyCell,
            sorting: false,
            render: (data: IWithdrawalListItem) => data.userPageName,
          },
        ]
      : []),
    {
      label: 'Payment',
      field: SortFieldsWithdrawal.amount,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => (data.type.startsWith('PageTo') ? `(${convertToPriceFormat(data.amount)})` : convertToPriceFormat(data.amount)),
    },
    {
      label: isQueue ? 'Schedule date' : 'Payment Date',
      field: SortFieldsWithdrawal.date,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertDate(data.date),
    },
    {
      label: 'Status',
      field: SortFieldsWithdrawal.status,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.status),
      sorting: true,
    },
    {
      label: 'Type',
      field: SortFieldsWithdrawal.type,
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.type),
    },
    {
      label: '',
      field: 'actions',
      cellStyle: {
        width: 140,
      },
      render: (data) =>
        data.status === EnumStatusWithdrawal.pending && (
          <Box display="flex" alignItems="center">
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => handleClickChangeStatus && handleClickChangeStatus(data.id, EnumStatusWithdrawal.scheduled)}
            >
              Schedule
            </Button>
            <Box ml={2}>
              <Button
                onClick={() => handleClickChangeStatus && handleClickChangeStatus(data.id, EnumStatusWithdrawal.rejected)}
                className={addMatStyle.errorButton}
                color="secondary"
                variant="outlined"
                size="small"
              >
                Reject
              </Button>
            </Box>
          </Box>
        ),
    },
  ])

  return {
    columns: columns.current,
  }
}

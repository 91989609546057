import * as yup from 'yup'
import { REQUIRED_FIELD } from 'constants/errors'

export const documentsClientValidationSchema = yup.object().shape({
  documents: yup.object().shape({
    VoidedCheck: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
      const opts = this.options as any
      const { value: grandparent } = opts.from[1]
      return this.parent?.VoidedCheck.length > 0 || grandparent?.uploadedDocuments.VoidedCheck.length > 0
    }),
    Application: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
      const opts = this.options as any
      const { value: grandparent } = opts.from[1]
      return this.parent.Application.length > 0 || grandparent?.uploadedDocuments?.Application.length > 0
    }),
    DriverLicense: yup.array().test('notEmpty', REQUIRED_FIELD, function () {
      const opts = this.options as any
      const { value: grandparent } = opts.from[1]
      return this.parent.DriverLicense.length > 0 || grandparent?.uploadedDocuments?.DriverLicense.length > 0
    }),
  }),
  uploadedDocuments: yup.object().shape({}),
})

import * as yup from 'yup'
import { REQUIRED_FIELD } from 'constants/errors'
import { differenceInCalendarDays } from 'date-fns'

export const schemaRepPerformance = yup.object().shape({
  RepId: yup.string().required(REQUIRED_FIELD),
  FromDate: yup
    .string()
    .typeError(REQUIRED_FIELD)
    .required(REQUIRED_FIELD)
    .test('diffDays', 'From date must be less or equal than To date', function (val) {
      if (this.parent.ToDate && val) return differenceInCalendarDays(new Date(this.parent.ToDate), new Date(val)) >= 0
      else return true
    }),
  ToDate: yup.string().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  ShowDeals: yup.boolean(),
})

import { Box, Paper, SvgIcon, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { ReactComponent as AuditLogIcon } from 'assets/svg/AuditLogIcon.svg'
import { ReactComponent as TimeIcon } from 'assets/svg/TimeIcon.svg'
import { convertDateWithTime } from 'utils/formatters'
import { IAuditLogListItem } from 'typescript/interfaces/deals'
import { TableAuditLog, useAuditLog } from 'hooks/useAuditLog'
import { useAuditLogItemStyles } from './style'
import AuditLogItemNewDocument from './AuditLogItemNewDocument'
import AuditLogItemDealLender from './AuditLogItemDealLender'
import AuditLogItemDealSchedule from './AuditLogItemDealSchedule'

interface IAuditLogItemProps {
  data: IAuditLogListItem
}

const AuditLogItem: FC<IAuditLogItemProps> = ({ data }) => {
  const classes = useAuditLogItemStyles()

  const { columns } = useAuditLog()

  if (data.historicalDatas.length === 0) return null

  if (data.historicalDatas[0]?.fieldName === 'DealDocument' && data.historicalDatas[0]?.fieldType === 'Json') {
    return <AuditLogItemNewDocument data={data} />
  }

  if (data.historicalDatas[0]?.fieldName === 'DealLender' && data.historicalDatas[0]?.fieldType === 'Json') {
    return <AuditLogItemDealLender data={data} />
  }

  if (data.historicalDatas[0]?.fieldName === 'PaymentSchedule' && data.historicalDatas[0]?.fieldType === 'Json') {
    return <AuditLogItemDealSchedule data={data} />
  }

  return (
    <Paper elevation={0}>
      <Box p="2rem" display="flex">
        <Box mr="1rem">
          <SvgIcon className={classes.auditIcon} viewBox="0 0 18 24" component={AuditLogIcon} />
        </Box>

        <Box width="100%">
          <Typography variant="h5" color="textSecondary">
            Deal #{data.dealId} was updated by: {data.createdBy}
          </Typography>
          <Box display="flex" mb="1.5rem" mt="0.75rem">
            <SvgIcon component={TimeIcon} />
            <Typography>{convertDateWithTime(data.date)}</Typography>
          </Box>

          <TableAuditLog columns={columns} data={data.historicalDatas} />
        </Box>
      </Box>
    </Paper>
  )
}

export default AuditLogItem

import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertEmptyField } from 'utils/formatters'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteCircleIcon.svg'
import { IRole } from 'typescript/interfaces/users'
import { Box, Button, useTheme } from '@material-ui/core'

export const TableRoles = TableComponent as TableComponentType<IRole>

interface IUseRolesTableProps {
  handleDeleteClick: (id: string) => void
}

export const useRolesTable = ({ handleDeleteClick }: IUseRolesTableProps) => {
  const classes = useTableComponentStyle()
  const theme = useTheme()
  const columns = useRef<Array<IColumn<IRole>>>([
    {
      label: 'Role #',
      field: 'id',
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
    },
    {
      label: 'Name',
      sorting: true,
      field: 'name',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.name),
    },
    {
      label: 'Operations',
      field: 'type',
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box display="flex" color={theme.palette.text.secondary} alignItems="center">
          <Button disabled={data.isUndeletable} onClick={() => handleDeleteClick(`${data.id}`)} color="inherit" startIcon={<DeleteIcon />}>
            Delete
          </Button>
        </Box>
      ),
    },
  ])

  return {
    columns: columns.current,
  }
}

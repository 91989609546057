import { publicInstance } from 'services/axios/publicInstance'
import { API_ACTIVATE_USER_NO_PASSWORD, API_FORGOT_PASSWORD, API_IMPERSONATE_USER } from 'constants/apiUrl'
import { IActivateUserNoPasswordValues, IActivateUserNoPasswordResponse, ISignInSuccessResponse, IResetPassword } from 'typescript/interfaces/auth'
import { AxiosResponse } from 'axios'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'

export const activateUserNoPassword = (values: IActivateUserNoPasswordValues) =>
  publicInstance.post<IActivateUserNoPasswordValues, AxiosResponse<IActivateUserNoPasswordResponse>>(API_ACTIVATE_USER_NO_PASSWORD, values)

export const impersonateUser = (email: string) =>
  publicInstance.post<ISignInSuccessResponse, AxiosResponse<ISignInSuccessResponse>>(API_IMPERSONATE_USER, { email }, {
    skipAuthRefresh: true,
  } as AxiosAuthRefreshRequestConfig)

export const forgotPassword = (values: IResetPassword) => {
  return publicInstance.post(API_FORGOT_PASSWORD, values, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig)
}

import React, { FC } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as BlockViewIcon } from 'assets/svg/BlockViewIcon.svg'
import Box from '@material-ui/core/Box'
import { ReactComponent as ListViewIcon } from 'assets/svg/ListViewIcon.svg'
import useTheme from '@material-ui/core/styles/useTheme'
import { useLocation, useHistory } from 'react-router-dom'
import { DASHBOARD_VIEW_FIRST, DASHBOARD_VIEW_SECOND } from 'constants/routes'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'

const ChangeViewDashboardAction: FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const { pathname } = useLocation()

  const isSecondView = pathname === DASHBOARD_VIEW_SECOND

  return (
    <Box display="flex" alignItems="center">
      <ToggleButtonGroup
        exclusive
        value={pathname}
        onChange={(e, newValue) => {
          if (newValue) history.replace(newValue)
        }}
      >
        <ToggleButton value={DASHBOARD_VIEW_FIRST}>
          <SvgIcon
            htmlColor={!isSecondView ? theme.palette.secondary.main : theme.palette.text.primary}
            fontSize="small"
            component={BlockViewIcon}
            viewBox="0 0 14 14"
          />
        </ToggleButton>
        <ToggleButton value={DASHBOARD_VIEW_SECOND}>
          <SvgIcon
            fontSize="small"
            htmlColor={isSecondView ? theme.palette.secondary.main : 'transparent'}
            // stroke={isSecondView ? theme.palette.secondary.main : 'transparent'}
            component={ListViewIcon}
            viewBox="0 0 16 16"
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default ChangeViewDashboardAction

import { createMuiTheme } from '@material-ui/core'
// eslint-disable-next-line
import * as type from '@material-ui/lab/themeAugmentation'

export const bg2 = '#F6FBFF'
export const bg3 = '#ECF2F8'

const secondary = {
  main: '#249AF3',
  light: '#5B8BEA',
  dark: '#4C5878',
}

const primary = {
  light: '#72BA71',
  main: '#5CAA5B',
  dark: '#318430',
}

const background = {
  default: '#F8FAFE',
  paper: '#FFFFFF',
}

const text = {
  primary: '#738AA5',
  hint: '#3D4967',
  secondary: '#172342',
  disabled: '#A2B3C1',
}

const common = {
  black: 'black',
  white: '#FFFFFF',
}

const divider = '#D0DDE8'

const info = {
  main: '#D0DDE8',
  light: '#E4EDF4',
  dark: '#C0CFDC',
  contrastText: '#93A6BB',
}

const error = {
  main: '#EE0056',
}

export const mainTheme = createMuiTheme({
  palette: {
    secondary: secondary,
    primary: primary,
    text: text,
    background: background,
    common: common,
    divider: divider, // also for tabs
    info: info,
    error: error,
  },
  spacing: 4,
  typography: {
    fontFamily: [
      'TT Commons',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    caption: {
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.3,
    },
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.625rem',
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.125rem',
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          '@media screen and (max-width: 1365px)': {
            fontSize: 13,
          },
          '@media screen and (min-width: 1366px) and (max-width: 1599px)': {
            fontSize: 14,
          },
          '@media screen and (min-width: 1600px) and (max-width: 1919px)': {
            fontSize: 15,
          },
          '@media screen and (min-width: 1920px)': {
            fontSize: 16,
          },
          '& *:focus': {
            outline: 'none',
          },
        },
        a: {
          textDecoration: 'none',
          color: text.secondary,
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        '.root-snackbar-provider.important': {
          '& *': {
            fontSize: '1.25rem',
          },
        },
        '.MuiPickersBasePicker-container': {
          '& .MuiPickersToolbarText-toolbarTxt': {
            color: common.white,
          },
          '& .MuiIconButton-root:not(.MuiCheckbox-root):not(.MuiSwitch-switchBase)': {
            width: 36,
            height: 36,
          },
          '& .MuiPickersToolbar-toolbar': {
            height: 75,
          },
          '& .MuiPickersDay-daySelected': {
            color: common.white,
          },
        },
        '.MuiFormHelperText-root.Mui-error': {
          marginLeft: 10,
        },
        '#root': {
          overflowX: 'visible',
        },
        body: {
          fontSize: 14,
          overflowX: 'hidden',
        },
        '::-webkit-scrollbar': {
          backgroundColor: common.white,
          width: 8,
          height: 8,
        },
        '::-webkit-scrollbar-button': {
          display: 'none',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: info.light,
          borderRadius: 10,
          width: 8,
          height: 8,
        },
      },
    },
    MuiTextField: {},
    MuiOutlinedInput: {
      root: {
        backgroundColor: common.white,
        borderRadius: 6,
        '&$disabled': {
          backgroundColor: info.light,
          color: info.contrastText,
        },
      },
      input: {
        padding: `1rem 0.875rem`,
        paddingTop: '0.783rem',
        paddingBottom: '0.783rem',
        color: secondary.dark,
      },
      adornedStart: {},
      inputAdornedStart: {
        marginLeft: 8,
      },
      inputMarginDense: {
        padding: `0.531rem 0.875rem`,
        paddingTop: '0.783rem',
        paddingBottom: '0.783rem',
      },
      // input: {
      //   padding: `${16}px ${14}px`,
      // },
      notchedOutline: {
        borderColor: divider,
        '& legend': {
          width: '0.01px !important',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:not(.MuiCheckbox-root):not(.MuiSwitch-switchBase)': {
          width: '2.75rem',
          height: '2.75rem',
          borderRadius: 6,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&$sizeSmall': {
            width: 'auto',
            height: 'auto',
          },
          '&$disabled': {
            filter: 'opacity(0.5)',
          },
          '&$colorInherit': {
            backgroundColor: common.white,
            boxShadow: '0px 1px 3px #DBDFE7',
          },
          '&$colorPrimary': {
            backgroundColor: primary.main,
            '&:hover': {
              backgroundColor: primary.dark,
            },
            '& svg': {
              '& path, & rect': {
                fill: common.white,
              },
            },
          },
          '&$colorSecondary': {
            border: `1px solid ${secondary.main}`,
            backgroundColor: bg2,
          },
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        width: '0.875rem',
        height: '0.875rem',
      },
    },
    MuiButton: {
      root: {
        fontSize: '1rem',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        padding: '0.5rem',
        paddingRight: '1.5rem',
        paddingLeft: '1.5rem',
        whiteSpace: 'nowrap',
        '&$disabled': {
          '& $startIcon': {
            '& *': {
              fill: text.disabled,
            },
          },
        },
      },
      textSecondary: {
        '& $startIcon': {
          '& *': {
            fill: secondary.main,
          },
        },
      },
      outlined: {
        padding: '0.5rem',
        paddingRight: '1rem',
        paddingLeft: '1rem',
      },
      outlinedSizeSmall: {
        fontSize: '1rem',
        lineHeight: '1.5',
      },
      contained: {
        color: text.primary,
        backgroundColor: common.white,
        boxShadow: '0px 1px 3px #DBDFE7',
        '&$containedSecondary': {
          backgroundColor: info.light,
          color: info.contrastText,
          '& svg': {
            '& path': {
              fill: info.contrastText,
            },
          },
        },
      },
      outlinedSecondary: {
        backgroundColor: bg2,
        '& $startIcon': {},
      },
      colorInherit: {
        color: text.secondary,
        '& svg': {
          '& *': {
            fill: text.secondary,
          },
        },
      },
      containedPrimary: {
        color: common.white,
        boxShadow: 'none',
        '& $startIcon': {
          '& svg': {
            '& *': {
              fill: common.white,
            },
          },
        },
      },
      endIcon: {
        '& svg': {
          width: '1rem',
          height: '1rem',
        },
      },
      startIcon: {
        borderRadius: 4,
        marginLeft: 0,
        marginRight: 9,
        marginTop: -3,
        '& svg': {
          width: '1rem',
          height: '1rem',
        },
      },
    },
    MuiPaper: {
      elevation0: {
        boxShadow: '0px 1px 3px #DBDFE7',
        height: '100%',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${divider}`,
        borderRight: `1px solid ${divider}`,
        fontSize: '1rem',
        padding: '0.575rem',
      },
      head: {
        color: text.primary,
        whiteSpace: 'nowrap',
        borderTop: `1px solid ${divider}`,
      },
      stickyHeader: {
        backgroundColor: 'inherit',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiToggleButton: {
      root: {
        width: '2.75rem',
        height: '2.75rem',
        borderRadius: 6,
        backgroundColor: common.white,
        border: 'none',
        '&$selected': {
          backgroundColor: common.white,
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        boxShadow: '0px 1px 3px #DBDFE7',
        borderRadius: 6,
      },
      groupedHorizontal: {
        '&:not(:first-child)': {
          borderLeft: `1px solid ${divider}`,
        },
      },
    },
    MuiChip: {
      root: {
        border: `1px solid ${divider}`,
        borderRadius: 6,
        fontSize: 16,
        color: secondary.dark,
      },
    },
    MuiSelect: {
      root: {
        '&$outlined': {
          '&$outlined': {
            color: secondary.dark,
            paddingRight: 40,
          },
        },
      },
      iconOutlined: {
        top: '0.75rem',
        marginRight: '0.5rem',
        width: '1.25rem',
        height: '1.25rem',
      },
    },
    MuiTypography: {
      root: {
        wordBreak: 'break-word',
      },
      noWrap: {
        overflow: 'visible',
        whiteSpace: 'nowrap',
      },
    },
    MuiInputLabel: {
      root: {
        color: text.disabled,
      },
      outlined: {
        color: secondary.dark,
        transform: 'translate(0.875rem, 0.95rem) scale(1)',
        '&$marginDense': {
          transform: 'translate(0.875rem, 0.95rem) scale(1)',
        },
        '&$shrink': {
          transform: 'translate(10px, -18px) scale(0.875)',
        },
      },
      shrink: {
        color: secondary.dark,
      },
      marginDense: {},
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        color: secondary.dark,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: `${error.main} !important`,
      },
    },
  },
})

import * as yup from 'yup'
import { BANK_ROUTING_MUST_BE_NINE_SYMBOLS, INVALID_SYMBOLS, REQUIRED_FIELD } from 'constants/errors'
import { bankAccountTypes } from 'constants/bankAccountType'
import { integerReg } from 'constants/regExp'

export const schemaClientBankAccount = yup.object().shape({
  bankAccount: yup.object().shape({
    bankRouting: yup
      .string()
      .min(9, BANK_ROUTING_MUST_BE_NINE_SYMBOLS)
      .max(9, BANK_ROUTING_MUST_BE_NINE_SYMBOLS)
      .test('integer', INVALID_SYMBOLS, function () {
        return Number.isInteger(+this.parent.bankRouting)
      })
      .required(REQUIRED_FIELD),
    bankName: yup.string().required(REQUIRED_FIELD),
    bankAccount: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(integerReg, 'Bank Account must be numeric')
      .test('isCorrectLength', 'Must be at least 5 characters and no more than 15', function (val) {
        return Boolean(val && val.length >= 5 && val.length <= 15)
      }),
    bankAccountType: yup.string().oneOf(bankAccountTypes).required(REQUIRED_FIELD),
  }),
})

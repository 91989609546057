import {
  API_GET_REP_LIST,
  API_CREATE_REP,
  API_EDIT_REP,
  API_GET_REP_BY_ID,
  API_GET_REP_LIGHT,
  API_DELETE_REP_DOCUMENT,
  API_CONNECT_REP_DOCUMENTS_TO_REP,
  API_GET_REP_PERFORMANCE,
  API_GET_REP_FUNDERS,
  API_GET_REP_APPROVED_FUNDERS,
  API_PUT_REQUEST_ANONYMOUS,
  API_SET_MANAGEMENT_FEE,
} from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import {
  IRepListItem,
  SortFieldRep,
  IInitialValuesRepresentative,
  IRepEntity,
  IRepListItemLight,
  IConnectRepFileLinksToRepParams,
  IRepPerformanceParams,
  IRepPerformanceEntity,
  IRequestFunderListItem,
  IApprovedFunderListItem,
  IRequestAnonymouse,
  ISetManagementFee,
} from 'typescript/interfaces/rep'
import { IPagination } from 'typescript/interfaces/pagination'
import { AxiosResponse } from 'axios'
import { storageInstance } from 'services/axios/storageInstance'
import { IDealDocumentConnectParams, IDealDocumentConnectParamsResponse } from 'typescript/interfaces/deals'
import { getDownloadUrl, uploadToS3 } from 'services/storage'

export const getRepList = (params: IPagination) =>
  privateInstance.get<IPagination<SortFieldRep>, AxiosResponse<IRepListItem[]>>(API_GET_REP_LIST, {
    params: params,
  })

export const getRepLight = (params: IPagination) =>
  privateInstance.get<IPagination<SortFieldRep>, AxiosResponse<IRepListItemLight[]>>(API_GET_REP_LIGHT, {
    params: params,
  })

export const createRep = (values: IInitialValuesRepresentative) =>
  privateInstance.post<IInitialValuesRepresentative, AxiosResponse<IRepEntity>>(API_CREATE_REP, values)

export const editRep = (values: IInitialValuesRepresentative & { id: string }) =>
  privateInstance.put<IInitialValuesRepresentative, AxiosResponse<IRepEntity>>(API_EDIT_REP, values)

export const getRepById = (id: string) => privateInstance.get<{ id: string }, AxiosResponse<IRepEntity>>(API_GET_REP_BY_ID.replace(':id', id))

export const deleteFileFromRep = (id: number) => storageInstance.delete(API_DELETE_REP_DOCUMENT.replace(':id', id.toString()))

export const connectUploadedUrlToRep = async (body: IConnectRepFileLinksToRepParams) =>
  storageInstance.post<IDealDocumentConnectParams, AxiosResponse<IDealDocumentConnectParamsResponse>>(API_CONNECT_REP_DOCUMENTS_TO_REP, body)

export const uploadRepDocuments = async (documents: File[], representativeId: number | string) => {
  const links = await Promise.all(
    documents.map((doc) => {
      return getDownloadUrl({
        DocumentName: doc.name,
        DocumentDestination: 'Representative',
        DestinationObjectId: representativeId,
      })
    }),
  )

  const uploadedFilesLinks = await Promise.all(links.map((link, i) => uploadToS3(documents[i], link.data)))

  const connectedUploadedUrls = await Promise.all(
    uploadedFilesLinks.map((uploadedLink, i) => {
      return connectUploadedUrlToRep({
        documentUrl: links[i].data.split('?')[0],
        description: documents[i].name,
        representativeId,
      })
    }),
  )

  return connectedUploadedUrls
}

export const getRepPerformance = (params: IRepPerformanceParams) => {
  return privateInstance.get<IRepPerformanceParams, AxiosResponse<IRepPerformanceEntity[]>>(API_GET_REP_PERFORMANCE, {
    params: params,
  })
}

export const getFunders = (params: IPagination) => {
  return privateInstance.get<IPagination, AxiosResponse<IRequestFunderListItem[]>>(API_GET_REP_FUNDERS, {
    params,
  })
}

export const getApprovedFunders = (params: IPagination) => {
  return privateInstance.get<IPagination, AxiosResponse<IApprovedFunderListItem[]>>(API_GET_REP_APPROVED_FUNDERS, {
    params,
  })
}

export const requestAnonymous = (params: IRequestAnonymouse) =>
  privateInstance.put<IRequestAnonymouse, AxiosResponse<boolean>>(API_PUT_REQUEST_ANONYMOUS, params)

export const setManagementFee = (params: ISetManagementFee) =>
  privateInstance.post<ISetManagementFee, AxiosResponse<boolean>>(API_SET_MANAGEMENT_FEE, params)

import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { useFormik } from 'formik'
import { IRefundFormInitValues } from 'typescript/interfaces/deals'
import Button from 'UI/Button/ButtonWithPreloader'
import TextField from '@material-ui/core/TextField/TextField'
import Typography from '@material-ui/core/Typography'
import { schemaRefund } from 'components/RefundDealForm/schema'

const defaultInitValues: IRefundFormInitValues = {
  amount: '',
}

interface IRefundDealFormProps {
  initValues?: IRefundFormInitValues
  submit: (values: IRefundFormInitValues) => void
  cancel: () => void
  isLoading?: boolean
}

const RefundDealForm: FC<IRefundDealFormProps> = ({ initValues = defaultInitValues, submit, isLoading, cancel }) => {
  const { handleSubmit, handleChange, touched, errors, values } = useFormik<IRefundFormInitValues>({
    initialValues: initValues,
    onSubmit: submit,
    validationSchema: schemaRefund,
  })
  return (
    <Box width={225}>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h2" color="textSecondary">
            Refund
          </Typography>
        </Box>
        <Box mt="2rem">
          <TextField
            label="Amount"
            variant="outlined"
            fullWidth
            name="amount"
            onChange={handleChange}
            value={values.amount}
            error={Boolean(touched.amount && errors.amount)}
            helperText={touched.amount && errors.amount}
          />
        </Box>
        <Box mt="2rem" display="flex" justifyContent="flex-end">
          <Button color="secondary" variant="contained" onClick={() => cancel()}>
            Cancel
          </Button>
          <Box ml="2rem">
            <Button loading={isLoading} type="submit" color="primary" variant="contained">
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default RefundDealForm

import * as yup from 'yup'
import { INVALID_PHONE_NUMBER, INVALID_MOBILE_NUMBER, ZIP_CODE_FORMAT_ERROR, REQUIRED_FIELD } from 'constants/errors'
import { zipCodeReg } from 'constants/regExp'

export const schemaAddEditCompany = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD),
  firstName: yup.string().required(REQUIRED_FIELD),
  lastName: yup.string().required(REQUIRED_FIELD),
  phone: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
  mobile: yup.string().test('isFullMobileNumber', INVALID_MOBILE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0) || !val),
  city: yup.string(),
  street: yup.string(),
  zipCode: yup.string().matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  stateId: yup.mixed(),
})

import React, { FC, useCallback, useMemo } from 'react'
import { useNewClientStyle } from 'containers/NewClient/style'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import OwnerForm from 'components/ClientForms/OwnerForm'
import { useHistory, useLocation, useParams, generatePath } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { editOwner, getClientById } from 'services/clients'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { EDIT_CLIENT_URL, VIEW_CLIENT_URL } from 'constants/routes'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const EditOwnerContainer: FC = () => {
  const classes = useNewClientStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const { goBack, push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { id, idOwner } = useParams<{ id: string; idOwner: string }>()
  const { data, isLoading: isLoadingClient } = useQuery(['clientById', id], () => getClientById(id), {
    retry: false,
    cacheTime: 0,
  })
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const nextUrl = params.get('nextUrl')
  const fOwner = useMemo(() => {
    if (data) return data.data.owners.find((o) => o.id.toString() === idOwner.toString())
    return undefined
  }, [data, idOwner])
  const [editOwnerMut, { isLoading }] = useMutation(editOwner)

  const handleSubmit = useCallback(
    async (values, variant?: number) => {
      await editOwnerMut(
        {
          ...values,
          clientId: id,
          id: idOwner,
          ownershipDate: values.ownershipDate || null,
        },
        {
          onSuccess: () => {
            if (nextUrl) push(nextUrl)
            else push(VIEW_CLIENT_URL.replace(':id(\\d+)', id))
            enqueueSnackbar('Owner successfully edited')
          },
          onError: () => {
            enqueueSnackbar(SOMETHING_WENT_WRONG)
          },
        },
      )
    },
    [enqueueSnackbar, id, idOwner],
  )

  const handleClickBack = useCallback(() => {
    if (nextUrl)
      push(
        `${generatePath(EDIT_CLIENT_URL, {
          id: id,
        })}?nextUrl=${window.location.pathname}${window.location.search}`,
      )
    else {
      goBack()
    }
  }, [nextUrl, id])

  return isLoadingClient || !fOwner ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Box className={additionalStyle.wrapper}>
      <Box display="flex" alignItems="center">
        <Box ml="3rem" mr="1rem">
          <IconButton color="inherit" onClick={handleClickBack}>
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography variant="h1" color="textSecondary">
          Edit {fOwner.firstName} {fOwner.lastName}
        </Typography>
      </Box>
      <Box mt="2rem">
        <Paper elevation={0} className={classes.paper}>
          <OwnerForm
            handleClickBack={handleClickBack}
            createMode={false}
            loading={isLoading}
            initialValues={{
              clientId: id,
              firstName: fOwner.firstName,
              lastName: fOwner.lastName,
              birthDate: fOwner.birthDate.toString(),
              title: fOwner.title,
              ownership: fOwner.ownership.toString(),
              ssn: fOwner.ssn,
              ownershipDate: fOwner.ownershipDate ? fOwner.ownershipDate.toString() : '',
              driverLicense: fOwner.driverLicense,
              dlExpiredDate: fOwner.dlExpiredDate.toString(),
              stateIssuedDL: fOwner.stateIssuedDL,
              stateId: fOwner.state.id.toString(),
              city: fOwner.city,
              street: fOwner.street,
              zipCode: fOwner.zipCode,
              email: fOwner.email,
              mobile: fOwner.mobile,
              fax: fOwner.fax,
            }}
            submit={{
              handler: handleSubmit,
              label: 'Save',
            }}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default EditOwnerContainer

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const convertDateForPicker = (date: MaterialUiPickersDate | Date) => {
  let newDate = date

  if (date) {
    newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0)
  }

  return newDate
}

export default convertDateForPicker

import * as yup from 'yup'
import { INVALID_EMAIL, INVALID_PHONE_NUMBER, REQUIRED_FIELD } from 'constants/errors'

export const schemaUserForm = yup.object().shape({
  firstName: yup.string().required(REQUIRED_FIELD).nullable(),
  lastName: yup.string().required(REQUIRED_FIELD).nullable(),
  phone: yup
    .string()
    .required(REQUIRED_FIELD)
    .nullable()
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
  email: yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
})

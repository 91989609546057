import React, { FC, useCallback, useContext } from 'react'
import ISOForm, { IIsoInitialValues } from 'components/ISOForm'
import { useHistory, generatePath } from 'react-router-dom'
import { useMutation } from 'react-query'
import { createIso } from 'services/iso'
import { useSnackbar } from 'notistack'
import { ISO_INFO_URL, UPLOAD_USER_PAGE_DOCS } from 'constants/routes'
import { AxiosError } from 'axios'
import { BANK_ACCOUNT_ALREADY_USED, SOMETHING_WENT_WRONG } from 'constants/errors'
import { UserContext } from 'contexts/userContext'

const AddNewIsoContainer: FC = () => {
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const {
    state: { roles },
  } = useContext(UserContext)

  const [createIsoMutation, { isLoading }] = useMutation(createIso, {
    onSuccess: (data) => {
      !roles.isRep
        ? push(
            `${generatePath(UPLOAD_USER_PAGE_DOCS, {
              type: 'iso',
              id: data.data.id,
            })}?type=create`,
          )
        : push(
            generatePath(ISO_INFO_URL, {
              id: data.data.id,
            }),
          )
      enqueueSnackbar('ISO successfully created')
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'ISO_FOR_COMPANY_EXISTS') enqueueSnackbar('ISO with the current company already exists')
      else if (err.response?.data.description === 'ISO_FOR_USER_EXISTS' || err.response?.data.description === 'EMAIL_USED_BY_ANOTHER_USER')
        enqueueSnackbar('ISO with the current email already exists')
      else if (err.response?.data.description === 'BANK_ACCOUNT_USED') enqueueSnackbar(BANK_ACCOUNT_ALREADY_USED)
      else if (err.response && err.response.data.descripiton === 'ANOTHER_COMPANY_ATTACHED_TO_USER')
        enqueueSnackbar('User with the current email already exists')
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSubmit = useCallback(
    (values: IIsoInitialValues) => {
      createIsoMutation(values)
    },
    [createIsoMutation],
  )

  return <ISOForm label="Add New ISO" loading={isLoading} submitHandler={handleSubmit} />
}

export default AddNewIsoContainer

import { Box, Button, CircularProgress, Divider, Grid, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import { NEW_DEAL_URL } from 'constants/routes'
import { IMerchantClient, IMerchantClientsUpdate } from 'typescript/interfaces/deals'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { getMerchantClients, updateMerchantClients } from 'services/deals'
import ReactInputMask from 'react-input-mask'
import SelectComponent from 'UI/Select'
import ButtonWithPreloader from 'UI/Button/ButtonWithPreloader'
import { merchantClientsValidationSchema } from './schema'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  backButton: {
    marginRight: '1.25rem',
    background: theme.palette.info.light,
  },
  icon: {
    '& path': {
      fill: theme.palette.info.contrastText,
    },
  },
  iconRemove: {
    transform: 'rotate(45deg)',
  },
}))

const maxClients = 5

const TopMerchantClients = () => {
  const s = useStyles()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { id: dealId } = useParams<{ id: string }>()
  const [initialValues, setInitialValues] = useState<{ clients: IMerchantClient[] }>({ clients: [] })

  const { data, isLoading } = useQuery(['merchant-clients', dealId], () => getMerchantClients(dealId), {
    onSuccess(res) {
      for (const item of res.data.merchantClients) {
        item.amountOwing = item.amountOwing ?? undefined
        item.businessName = item.businessName ?? undefined
        item.contactName = item.contactName ?? undefined
        item.phone = item.phone ?? undefined
      }

      setInitialValues({ clients: res.data.merchantClients })
    },
    onError() {
      enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
    enabled: dealId,
  })

  const formik = useFormik<{ clients: IMerchantClient[] }>({
    initialValues: initialValues,
    validationSchema: merchantClientsValidationSchema,
    enableReinitialize: true,
    onSubmit: (formFields) => {
      if (dirty) {
        mutate({
          dealId: Number(dealId),
          merchantClients: formFields.clients,
        })
      } else {
        handleGoNext()
      }
    },
  })

  const {
    values: { clients },
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    dirty,
  } = formik

  const [mutate, { isLoading: isLoadingUpdate }] = useMutation((request: IMerchantClientsUpdate) => updateMerchantClients(request), {
    onSuccess() {
      handleGoNext()
    },
    onError() {
      enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
  })

  const handleGoBack = useCallback(() => {
    push(
      generatePath(NEW_DEAL_URL, {
        id: dealId,
        view: 'edit',
        type: 'inhouse',
        step: 3,
      }),
    )
  }, [dealId, push])

  const handleGoNext = () => {
    push(
      generatePath(NEW_DEAL_URL, {
        id: dealId,
        view: 'edit',
        type: 'inhouse',
        step: 5,
      }),
    )
  }

  const createClientWithId = (): IMerchantClient => {
    const maxId = clients.reduce((max, obj) => Math.max(max, obj.id), 0)
    return { id: maxId + 1 }
  }

  if (isLoading) {
    return (
      <Box display="flex" height="400px" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Paper>
      <Box p="3rem" mt="1.625rem" mb="2rem">
        <Box mb="3rem">
          <Typography className={s.sectionTitle}>
            Please list the top 5 clients that &quot;{data?.data.client.businessName}&quot; are currently providing services for / have provided
            services for in the past 30 days
          </Typography>
        </Box>
        <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <FieldArray
              name="clients"
              render={({ push: pushClient, remove }) => (
                <>
                  {clients.map((client, index) => (
                    <Box key={client.id}>
                      <Grid container spacing={8}>
                        <Grid item xs={4}>
                          <TextField
                            name={`clients[${index}].businessName`}
                            value={client.businessName}
                            onChange={handleChange}
                            label="Business Name"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            name={`clients[${index}].contactName`}
                            value={client.contactName}
                            onChange={handleChange}
                            label="Contact Name"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ReactInputMask name={`clients[${index}].phone`} mask="(999)-999-9999" value={client.phone} onChange={handleChange}>
                            {(props: any) => <TextField {...props} label="Phone number" variant="outlined" fullWidth />}
                          </ReactInputMask>
                        </Grid>
                        <Grid item xs={4}>
                          <SelectComponent
                            fullWidth
                            label="Does the client owe you money?"
                            value={client.amountOwing != null ? 'Yes' : 'No'}
                            onChange={(e) => {
                              setFieldValue(`clients[${index}].amountOwing`, e.target.value === 'Yes' ? client.amountOwing ?? 0 : undefined)
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </SelectComponent>
                        </Grid>
                        {client.amountOwing != null && (
                          <Grid item xs={4}>
                            <TextField
                              name={`clients[${index}].amountOwing`}
                              value={client.amountOwing}
                              onChange={handleChange}
                              label="Amount"
                              variant="outlined"
                              fullWidth
                              error={touched.clients?.[index]?.amountOwing && Boolean((errors.clients?.[index] as any)?.amountOwing)}
                              helperText={touched.clients?.[index]?.amountOwing && (errors.clients?.[index] as any)?.amountOwing}
                            />
                          </Grid>
                        )}
                        <Grid item xs={4}>
                          <Button
                            onClick={() => remove(index)}
                            startIcon={<PlusIcon className={`${s.icon} ${s.iconRemove}`} />}
                            variant="contained"
                            color="inherit"
                          >
                            Remove client
                          </Button>
                        </Grid>
                      </Grid>
                      <Box my="3rem" mx="-3rem">
                        <Divider />
                      </Box>
                    </Box>
                  ))}
                  {clients.length < maxClients && (
                    <>
                      <Button
                        onClick={() => pushClient(createClientWithId())}
                        startIcon={<PlusIcon className={s.icon} />}
                        variant="contained"
                        color="inherit"
                      >
                        Add client
                      </Button>
                      <Box my="3rem" mx="-3rem">
                        <Divider />
                      </Box>
                    </>
                  )}
                </>
              )}
            />
            <Box mt="auto">
              <Button variant="contained" onClick={handleGoBack} startIcon={<ArrowLeftIcon className={s.icon} />} className={s.backButton}>
                Back
              </Button>
              <ButtonWithPreloader disabled={isLoadingUpdate} loading={isLoadingUpdate} type="submit" color="primary" variant="contained">
                {dirty ? 'Save' : 'Next'}
              </ButtonWithPreloader>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </Paper>
  )
}

export default TopMerchantClients

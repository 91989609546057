import { Box, Grid } from '@material-ui/core'
import RolesAutoComplete from 'components/RolesAutoComplete'
import { useFormik } from 'formik'
import React, { FC, useEffect } from 'react'
import { IUpdateUserRolesForm, IUser } from 'typescript/interfaces/users'
import Button from 'UI/Button/ButtonWithPreloader'
import { schemaChangeUserRoles } from './schema'

interface IChangeUserRolesFormProps {
  cancel: () => void
  submit: (values: IUpdateUserRolesForm) => void
  loading?: boolean
  user?: IUser | null
}

const ChangeUserRolesForm: FC<IChangeUserRolesFormProps> = ({ cancel, submit, loading, user }) => {
  const { handleSubmit, values, errors, touched, handleChange, setFieldValue } = useFormik<IUpdateUserRolesForm>({
    initialValues: {
      roles: [],
    },
    validationSchema: schemaChangeUserRoles,
    onSubmit: submit,
  })

  useEffect(() => {
    setFieldValue('roles', user?.userRoles)
  }, [user?.userRoles])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={9}>
        <Grid item xs={12}>
          <RolesAutoComplete
            roles={values.roles.map((role) => ({ name: role.name, id: role.id }))}
            label="Roles"
            error={Boolean(touched.roles && errors.roles)}
            helperText={touched.roles && (errors.roles as string)}
            onChange={(e, value) => {
              if (value) handleChange({ target: { name: 'roles', value } })
            }}
          />
        </Grid>
      </Grid>
      <Box mt={10}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button onClick={cancel} fullWidth color="secondary" variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button loading={loading} fullWidth color="primary" variant="contained" type="submit">
              Update roles
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default ChangeUserRolesForm

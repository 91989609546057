import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IIsoListItem, IIsoDeals, IIsoDocuments } from 'typescript/interfaces/iso'
import { IColumn } from 'typescript/interfaces/tableUi'
import Button from 'UI/Button/ButtonWithPreloader'
import { useTableComponentStyle } from 'UI/Table/style'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteTrashIcon.svg'
import { DEAL_INFO_URL, ISO_INFO_URL, TRANSACTIONS_URL } from 'constants/routes'
import { Link, useHistory, generatePath } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { convertDate, convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { usePermission } from 'hooks/usePermission'
import { TRANSACTION_PERM } from 'constants/permissions'

export const TableIso = TableComponent as TableComponentType<IIsoListItem>
export const TableIsoDeals = TableComponent as TableComponentType<IIsoDeals>
export const TableIsoDocuments = TableComponent as TableComponentType<IIsoDocuments>

interface IUseIsoProps {
  handleDeleteFile?: (id: number) => void
  handleDownloadFile?: (url: string, name: string) => void
  canDelete?: boolean
}

export const useIso = (props: IUseIsoProps) => {
  const { hasPermission } = usePermission()
  const { handleDeleteFile, handleDownloadFile, canDelete = true } = props
  const { push } = useHistory()
  const classes = useTableComponentStyle()
  const columns = useRef<IColumn<IIsoListItem>[]>([
    {
      field: 'Id',
      label: 'ISO #',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Link
          to={generatePath(ISO_INFO_URL, {
            id: data.id.toString(),
          })}
        >
          {data.id}
        </Link>
      ),
    },
    {
      field: 'BusinessName',
      label: 'Business Name',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Box whiteSpace="nowrap" maxWidth={270} textOverflow="ellipsis" overflow="hidden">
          {data.businessName}
        </Box>
      ),
    },
    {
      field: 'FirstName',
      label: 'First Name',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => data.firstName,
    },
    {
      field: 'LastName',
      label: 'Last Name',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => data.lastName,
    },
    {
      field: 'Phone',
      label: 'Phone',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => <Box whiteSpace="nowrap">{data.phone}</Box>,
    },
    {
      field: 'State',
      label: 'State',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => data.state.name,
    },
    ...(hasPermission(TRANSACTION_PERM)
      ? [
          {
            field: 'DealsCount',
            label: 'Of Deals',
            sorting: true,
            bodyCellClassName: classes.accentCell,
            render: (data: IIsoListItem) => data.dealsCount,
          },
          {
            label: 'Current Balance',
            field: 'AvailableBalance',
            sorting: true,
            bodyCellClassName: classes.accentCell,
            render: (data: IIsoListItem) => convertToPriceFormat(data.availableBalance),
          },
          {
            field: 'LastTransactionDate',
            label: 'Last Transactions',
            sorting: true,
            bodyCellClassName: classes.accentCell,
            render: (data: IIsoListItem) => {
              return convertDate(data.lastTransactionDate)
            },
          },
        ]
      : []),
    ...(hasPermission(TRANSACTION_PERM)
      ? [
          {
            field: 'actions',
            label: '',
            render: (data: IIsoListItem) => {
              return (
                <Box display="flex" alignItems="center">
                  {hasPermission(TRANSACTION_PERM) && (
                    <Box mr="1rem">
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          push(
                            generatePath(TRANSACTIONS_URL, {
                              type: 'iso',
                              id: data.id.toString(),
                              businessName: data.businessName,
                            }),
                          )
                        }
                      >
                        View Trs
                      </Button>
                    </Box>
                  )}
                </Box>
              )
            },
          },
        ]
      : []),
  ])
  const columnsIsoDeals = useRef<IColumn<IIsoDeals>[]>([
    {
      field: 'id',
      label: '#',
      bodyCellClassName: classes.accentCell,
      render: (data) => <Link to={generatePath(DEAL_INFO_URL, { id: data.id })}>{data.id}</Link>,
    },
    {
      field: 'advancedAmount',
      label: 'Advanced Amount',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.advanceAmount),
    },
    {
      field: 'status',
      label: 'Status',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.paidStatus),
    },
    {
      field: 'projectReturn',
      label: 'Projected Return',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.payback),
    },
    {
      field: 'paymentAmount',
      label: 'Payment Amount',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertToPriceFormat(data.frequentPayment),
    },
    {
      field: 'statusDate',
      label: 'Start Date',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.startDate),
    },
    {
      field: 'term',
      label: 'Term',
      bodyCellClassName: classes.accentCell,
    },
    {
      field: 'endDate',
      label: 'End Date',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.endDate),
    },
  ])

  const columnsIsoDocuments = useRef<IColumn<IIsoDocuments>[]>([
    {
      field: 'name',
      label: 'Name',
      bodyCellClassName: classes.accentCell,
      render: (data) => (
        <Link
          to={data.documentUrl}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            handleDownloadFile && handleDownloadFile(data.documentUrl, data.description)
          }}
        >
          {data.description}
        </Link>
      ),
    },
    {
      field: 'date',
      label: 'Date',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.createdDate),
    },
    ...(canDelete
      ? [
          {
            field: 'actions',
            label: '',
            cellStyle: {
              width: 60,
            },
            render: (data: IIsoDocuments) => {
              return (
                <IconButton size="small" onClick={() => handleDeleteFile?.(data.id)}>
                  <DeleteIcon />
                </IconButton>
              )
            },
          },
        ]
      : []),
  ])

  return {
    columns: columns.current,
    columnsIsoDeals: columnsIsoDeals.current,
    columnsIsoDocuments: columnsIsoDocuments.current,
  }
}

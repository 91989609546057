import React, { useEffect, useState, useContext } from 'react'
import { generatePath, Route, Switch, useHistory, useParams, Redirect } from 'react-router-dom'
import DocumentsForm from 'components/InhouseDealForm/Documents'
import { CLIENT_DEAL, NEW_CLIENT_URL, NEW_DEAL_URL } from 'constants/routes'
import CustomStepper, { Step } from 'UI/Stepper/CustomStepper'
import ClientDealInformationForm from 'components/ClientDealForm/DealInformation'
import { UserContext } from 'contexts/userContext'
import { useSnackbar } from 'notistack'
import { CompanyType } from 'typescript/interfaces/companies'

const ClientDealContainer = () => {
  const [steps, setSteps] = useState([{ title: 'Deal Information' }, { title: 'Documents' }])
  const { state } = useContext(UserContext)
  const { step = '0', view, id } = useParams<{ step: string; view: string; id: string }>()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [availableSteps, setAvailableSteps] = useState(view === 'edit' ? [0, 1, 2] : [0])

  useEffect(() => {
    if (view === 'new') {
      setAvailableSteps([0])
      setSteps([{ title: 'Deal Information' }, { title: 'Documents' }])
    }
  }, [view])

  const handleStepChange = (_: Step, s: number) => {
    push(
      generatePath(NEW_DEAL_URL, {
        id: id,
        view: view,
        type: 'client-deal',
        step: s + 1,
      }),
    )
  }

  // const steps = [{ title: 'Deal Information' }, { title: 'Documents' }]

  if (!state.client) {
    enqueueSnackbar('Client need to be added')
    return <Redirect to={NEW_CLIENT_URL} />
  }

  return (
    <div>
      <CustomStepper steps={steps} current={+step - 1} available={availableSteps} onChange={handleStepChange} />

      <Switch>
        <Route
          path={CLIENT_DEAL.replace(':step?', '1')}
          render={(params: any) => (
            <ClientDealInformationForm
              {...params}
              changeStepsAvailable={setAvailableSteps}
              changeTabsCallBack={(data) => {
                if (data.type === CompanyType.OutHouse) setSteps([{ title: 'Deal Information' }])
              }}
            />
          )}
        />
        <Route path={CLIENT_DEAL.replace(':step?', '2')} component={DocumentsForm} />
      </Switch>
    </div>
  )
}

export default ClientDealContainer

import { DEAL_INFO_URL, NEW_DEAL_URL, DEAL_ASSIGN_LENDERS, DEALS_LIST_URL, DEAL_AUDIT_LOG_URL, DEAL_COMMENTS_LOG_URL } from 'constants/routes'
import DealInfoPage from 'pages/DealInfo'
import NewDealPage from 'pages/NewDeal'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AssignLendersPage from 'containers/Deal/AssignLenders'
import LatestDealsPage from 'pages/LatestDeals'
import DealAuditLogPage from 'pages/DealAuditLog'
import DealCommentsLogPage from 'pages/DealCommentsLog'
import { usePermission } from 'hooks/usePermission'
import {
  ASSIGN_LENDERS_PERM,
  CLIENT_CREATE_UPDATE_VIEW,
  LATEST_DEALS_PERM,
  PERMISSION_CREATE_PERSONAL_CLIENT,
  FUNDED_DEALS_PERM,
  SUBMITTED_DEALS_PERM,
  DEAL_AUDIT_LOG_PERM,
  DEAL_COMMENTS_LOG_PERM,
  MY_DEALS_PERM,
} from 'constants/permissions'

const DealContainer = () => {
  const { hasPermission } = usePermission()
  return (
    <Switch>
      {(hasPermission(LATEST_DEALS_PERM) ||
        hasPermission(FUNDED_DEALS_PERM) ||
        hasPermission(SUBMITTED_DEALS_PERM) ||
        hasPermission(MY_DEALS_PERM)) && <Route path={DEALS_LIST_URL} component={LatestDealsPage} />}
      <Route path={NEW_DEAL_URL} component={NewDealPage} />
      {hasPermission(DEAL_ASSIGN_LENDERS) && <Route path={DEAL_ASSIGN_LENDERS} component={AssignLendersPage} />}
      <Route path={DEAL_INFO_URL} exact component={DealInfoPage} />
      {hasPermission(DEAL_AUDIT_LOG_PERM) && <Route path={DEAL_AUDIT_LOG_URL} exact component={DealAuditLogPage} />}
      {hasPermission(DEAL_COMMENTS_LOG_PERM) && <Route path={DEAL_COMMENTS_LOG_URL} exact component={DealCommentsLogPage} />}
      {hasPermission(ASSIGN_LENDERS_PERM) && <Route path={DEAL_ASSIGN_LENDERS} component={AssignLendersPage} />}
      {(hasPermission(CLIENT_CREATE_UPDATE_VIEW) || hasPermission(PERMISSION_CREATE_PERSONAL_CLIENT)) && (
        <Route path={DEAL_INFO_URL} exact component={DealInfoPage} />
      )}
    </Switch>
  )
}

export default DealContainer

import React, { useMemo } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { Box, makeStyles, Typography } from '@material-ui/core'
import Button from 'UI/Button/ButtonWithPreloader'
import TableComponent, { TableComponentType } from 'UI/Table'
import { convertEmptyField } from 'utils/formatters'
import { IRequestFunderListItem } from 'typescript/interfaces/rep'
import { FunderRequestStatusEnum } from 'typescript/enums/funder'

export const TableRequestFundersInfo = TableComponent as TableComponentType<IRequestFunderListItem>

const useBlurredTextStyles = makeStyles((theme) => ({
  blur: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    userSelect: 'none',
  },
}))

interface IUseRequestFundersInfoParams {
  handleRequestInfo: (body: { representativeId: number }) => void
  handleCooperationInfo: (body: { representativeId: number }) => void
  isCooperationLoading: boolean
  isInfoLoading: boolean
}

export const useRequestFundersInfo = ({
  handleRequestInfo,
  handleCooperationInfo,
  isCooperationLoading,
  isInfoLoading,
}: IUseRequestFundersInfoParams) => {
  const classes = useTableComponentStyle()
  const blurred = useBlurredTextStyles()

  const blurTextIfNotApproved = (approved: boolean, text: any, placeholder?: string) => {
    if (approved) {
      return text
    }
    return <Typography className={blurred.blur}>{placeholder || 'Random word'}</Typography>
  }

  const columns = useMemo<Array<IColumn<IRequestFunderListItem>>>(
    () => [
      {
        label: '#',
        field: 'id',
        bodyCellClassName: classes.accentCell,
        render: (data) => convertEmptyField(data.id),
      },
      {
        label: 'Business Name',
        field: 'businessName',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) => (
          <Box whiteSpace="nowrap" maxWidth={220} textOverflow="ellipsis" overflow="hidden">
            {data.businessName}
          </Box>
        ),
      },
      {
        label: 'First Name',
        field: 'firstName',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) => convertEmptyField(data.firstName),
      },
      {
        label: 'Last Name',
        field: 'lastName',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) => convertEmptyField(data.lastName),
      },
      {
        label: 'Phone',
        field: 'phone',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) =>
          blurTextIfNotApproved(
            data.infoRequest === FunderRequestStatusEnum.Granted,
            <Box whiteSpace="nowrap">{convertEmptyField(data.phone)}</Box>,
            '999-999-999',
          ),
      },
      {
        label: 'State',
        field: 'state',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) => blurTextIfNotApproved(data.infoRequest === FunderRequestStatusEnum.Granted, convertEmptyField(data.state?.name), 'Ohio'),
      },
      // {
      //   label: 'Number Of Deals',
      //   field: 'ofDeals',
      //   sorting: true,
      //   bodyCellClassName: classes.accentCell,
      //   render: (data) => blurTextIfNotApproved(data.infoRequest === FunderRequestStatusEnum.Granted, convertEmptyField(data.deals), '3'),
      // },
      {
        label: 'Default Rate',
        field: 'defaultRate',
        sorting: true,
        bodyCellClassName: classes.accentCell,
        render: (data) => blurTextIfNotApproved(data.infoRequest === FunderRequestStatusEnum.Granted, convertEmptyField(data.defaultRate), '100'),
      },
      {
        label: 'Management fee',
        field: 'comission',
        bodyCellClassName: classes.accentCell,
        render: (data) => blurTextIfNotApproved(data.infoRequest === FunderRequestStatusEnum.Granted, convertEmptyField(data.managementFee), '250'),
      },
      {
        label: '',
        field: 'actions',
        render: (data) =>
          data.infoRequest === FunderRequestStatusEnum.Granted ? (
            <Button
              disabled={data.cooperationRequest === FunderRequestStatusEnum.Requested || data.cooperationRequest === FunderRequestStatusEnum.Granted}
              loading={isCooperationLoading}
              onClick={() => handleCooperationInfo({ representativeId: data.id })}
              color="secondary"
              variant="outlined"
              size="small"
            >
              {data.cooperationRequest === FunderRequestStatusEnum.Granted ? 'Granted' : 'Request Cooperation'}
            </Button>
          ) : (
            <Button
              disabled={data.infoRequest === FunderRequestStatusEnum.Requested}
              loading={isInfoLoading}
              onClick={() => handleRequestInfo({ representativeId: data.id })}
              color="secondary"
              variant="outlined"
              size="small"
            >
              {data.infoRequest === FunderRequestStatusEnum.Requested ? 'Requested' : 'Request Info'}
            </Button>
          ),
      },
    ],
    [isInfoLoading, isCooperationLoading, handleRequestInfo, handleCooperationInfo],
  )

  return {
    columns,
  }
}

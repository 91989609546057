import React, { FC, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustSquareIcon.svg'
import Paper from '@material-ui/core/Paper/Paper'
import { TableClients, useClients } from 'hooks/useClients'
import { useMutation } from 'react-query'
import { usePaginationList } from 'hooks/usePaginationList'
import { IClientListItem, SortFieldsClients } from 'typescript/interfaces/clients'
import { getClients, inviteClient } from 'services/clients'
import { exportClients } from 'services/export'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG, USER_EXISTS } from 'constants/errors'
import { ExportType } from 'typescript/enums/export'
import { downloadFile } from 'utils/downloadFile'
import ModalComponent from 'UI/Modal'
import InviteClientForm from 'components/InviteClient'
import { AxiosError } from 'axios'
import SearchField from 'UI/SearchField'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { usePermission } from 'hooks/usePermission'
import { CLIENT_INVITE_PERM } from 'constants/permissions'

const ClientsListContainer: FC = () => {
  const { hasPermission } = usePermission()
  const { columns } = useClients()
  const additionalStyle = useAdditionalMaterialStyle()
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const { handleSort, handleFetchMore, pagination, setPagination, handleSearch } = usePaginationList<IClientListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsClients.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })
  const { enqueueSnackbar } = useSnackbar()

  const [getClientsMut, { isLoading }] = useMutation(getClients, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [exportClientsExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportClients, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [inviteClientMut, { isLoading: isLoadingInviteClient }] = useMutation(inviteClient, {
    onSuccess: (data) => {
      setOpenInviteModal(false)
      enqueueSnackbar('Letter successfully sent to email')
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'EMAIL_USED_BY_ANOTHER_USER') enqueueSnackbar(USER_EXISTS)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    getClientsMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      Query: pagination.search,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.search])

  return (
    <Paper className={additionalStyle.wrapper}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          Clients
        </Typography>
        <Box display="flex" alignItems="center">
          <Box mr="3rem">
            <SearchField label="Search client" handleSearch={(search) => handleSearch(search)} />
          </Box>
          {hasPermission(CLIENT_INVITE_PERM) && (
            <Button onClick={() => setOpenInviteModal(true)} variant="text" color="secondary" startIcon={<PlusIcon />}>
              Invite Client
            </Button>
          )}
          <Box ml="1rem">
            <Button
              loading={isLoadingExportExcel}
              onClick={() =>
                exportClientsExcelMut({
                  ExportType: ExportType.excel,
                })
              }
              variant="text"
              color="secondary"
              startIcon={<ExcelIcon />}
            >
              Export to Excel
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableClients
          loading={isLoading}
          columns={columns}
          data={pagination.data}
          hasMore={pagination.hasMore}
          handleSortChange={handleSort}
          handleGetMore={handleFetchMore}
        />
      </Box>
      <ModalComponent open={openInviteModal}>
        <InviteClientForm
          isLoading={isLoadingInviteClient}
          handleSubmit={(values) => {
            inviteClientMut(values)
          }}
          handleCancel={() => setOpenInviteModal(false)}
        />
      </ModalComponent>
    </Paper>
  )
}

export default ClientsListContainer

import { ExportType } from 'typescript/enums/export'
import { IPagination } from 'typescript/interfaces/pagination'
import { BankAccountTypeEnum } from 'typescript/interfaces/bankAccountType'

export enum CompanyType {
  InHouse = 'InHouse',
  OutHouse = 'OutHouse',
}

export enum CompanySortFields {
  id = 'Id',
  name = 'Name',
  federalTaxId = 'FederalTaxId',
  firstName = 'FirstName',
  lastName = 'LastName',
  email = 'Email',
  phone = 'Phone',
}

export interface CompanyLight {
  id: number
  name: string
  type: CompanyType
}

export interface IState {
  abbreviation: string
  name: string
  id: number
}

export interface CompanyListItem {
  id: number
  name: string
  federalTaxId: string
  firstName: string
  lastName: string
  phone: string
  mobile: string
  email: string
}

export interface Company {
  id: number
  name: string
  description: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  firstName?: string
  lastName?: string
  phone?: string
  mobile?: string
  email?: string
  logo: string
  companyType: CompanyType
  state: IState
  bankCompanyId: string
  bankAccountName: string
}

export interface IBankAccount {
  id?: string | number
  bankAccount: string
  bankRouting: string
  availableBalance?: number | string | null
  bankAccountType?: BankAccountTypeEnum | ''
  bankName?: string
}

export interface CompanyForm {
  id?: number
  name: string
  description: string
  bankCompanyId: string
  bankAccountName: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  phone?: string
  stateId: number | ''
  companyType: CompanyType
  logo?: File
  logoUrl?: string
}

export interface OuthouseCompanyForm {
  id?: number
  name: string
  firstName: string
  lastName: string
  phone: string
  mobile: string
  email: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  stateId: number | null
}

export interface OuthouseCompany {
  id?: number
  name: string
  firstName: string
  lastName: string
  phone: string
  mobile: string
  email: string
  label: string
  city: string
  street: string
  zipCode: string
  federalTaxId: string
  state: {
    id: number
    name: string
  }
}

export interface ICompaniesExportParams {
  ExportType: ExportType
  CompanyType: CompanyType
}

export interface ICompaniesParamsLight extends IPagination<CompanySortFields> {
  CompanyType?: CompanyType
  HasSyndicator?: boolean
  ExcludeWithSyndicator?: boolean
  ExcludeWithRep?: boolean
  ExcludeWithIso?: boolean
}

export interface ICompanyLogoUploadParams {
  companyId: number | string
  fileUrl: string
}

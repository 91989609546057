import React, { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { REP_LIST_URL, REP_ADD_NEW_URL, REP_EDIT_URL, REP_INFO_URL } from 'constants/routes'
import RepListPage from 'pages/RepListPage'
import NewRepPage from 'pages/NewRep'
import EditRepPage from 'pages/EditRep'
import RepPage from 'pages/RepInfo'
import { usePermission } from 'hooks/usePermission'
import { CREATE_REP_PERM, REP_MODULE_PERM, REP_OWN_VIEW_PERM } from 'constants/permissions'

const RepContainer: FC = () => {
  const { hasPermission } = usePermission()
  return (
    <Switch>
      {hasPermission(REP_MODULE_PERM) && <Route path={REP_LIST_URL} component={RepListPage} />}
      {hasPermission(CREATE_REP_PERM) && <Route path={REP_ADD_NEW_URL} component={NewRepPage} />}
      <Route path={REP_EDIT_URL} component={EditRepPage} />
      {(hasPermission(REP_MODULE_PERM) || hasPermission(REP_OWN_VIEW_PERM)) && <Route path={REP_INFO_URL} component={RepPage} />}
      <Redirect to={REP_LIST_URL} />
    </Switch>
  )
}

export default RepContainer

import { Box, Grid, MenuItem, TextField } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import DatePicker from 'UI/DatePicker/DatePicker'
import { DealTransactionTypes, StatusPayment } from 'typescript/enums/transactions'
import { IDealPaymentFormValues } from 'typescript/interfaces/deals'
import Button from 'UI/Button/ButtonWithPreloader'
import SelectComponent from 'UI/Select'
import { IBankAccountClient } from 'typescript/interfaces/clients'
import { schemaDealPayment } from 'components/DealPaymentForm/schema'
import RequiredOption from 'UI/Select/RequiredOption'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'
import VendorAutoComplete from 'components/VendorAutoComplete'

interface IDealPaymentFormProps {
  cancel: () => void
  submit: (values: IDealPaymentFormValues) => void
  loading?: boolean
  bankAccounts: IBankAccountClient[]
  editMode?: boolean
  initialValues?: IDealPaymentFormValues
}

const defaultInitValues: IDealPaymentFormValues = {
  scheduledFor: '',
  amount: undefined,
  achProvider: '',
  bankAccountId: '',
  status: '',
  category: '',
  paymentDate: '',
  comments: '',
  vendorId: '',
}

const DealPaymentForm = ({ submit, cancel, loading, bankAccounts, editMode, initialValues = defaultInitValues }: IDealPaymentFormProps) => {
  const { handleSubmit, values, errors, touched, handleChange, setFieldValue } = useFormik<IDealPaymentFormValues>({
    initialValues: {
      ...initialValues,
      bankAccountId: bankAccounts.find((bankAcc) => bankAcc.isMain)?.bankAccount.id || undefined,
    },
    enableReinitialize: true,
    validationSchema: schemaDealPayment,
    onSubmit: submit,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DatePicker
            fullWidth
            label={<RequiredOption label="Scheduled For" />}
            name="scheduledFor"
            value={values.scheduledFor || null}
            error={Boolean(touched.scheduledFor && errors.scheduledFor) || !isValidDate(values.scheduledFor)}
            helperText={(touched.scheduledFor && errors.scheduledFor) || (!isValidDate(values.scheduledFor) && 'MM/DD/YYYY')}
            type="keyboard"
            onChange={(date) => setFieldValue('scheduledFor', convertDateForPicker(date))}
          />
        </Grid>
        {!editMode && (
          <Grid item xs={12}>
            <TextField
              disabled={editMode}
              fullWidth
              variant="outlined"
              label={<RequiredOption label="Amount" />}
              name="amount"
              value={values.amount}
              onChange={handleChange}
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />
          </Grid>
        )}
        {/*<Grid item xs={12}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    variant="outlined"*/}
        {/*    label={<RequiredOption label="Ach Provider"/>}*/}
        {/*    name="achProvider"*/}
        {/*    value={values.achProvider}*/}
        {/*    onChange={handleChange}*/}
        {/*    error={Boolean(touched.achProvider && errors.achProvider)}*/}
        {/*    helperText={touched.achProvider && errors.achProvider}*/}
        {/*  />*/}
        {/*</Grid>*/}
        {!editMode && (
          <Grid item xs={12}>
            <SelectComponent
              disabled={editMode}
              value={values.bankAccountId}
              name="bankAccountId"
              label={<RequiredOption label="Bank Account" />}
              onChange={handleChange}
              error={Boolean(touched.bankAccountId && errors.bankAccountId)}
              helperText={touched.bankAccountId && errors.bankAccountId}
            >
              {bankAccounts.map((bankAcc) => (
                <MenuItem value={bankAcc.bankAccount.id}>{bankAcc.bankAccount.bankName}</MenuItem>
              ))}
            </SelectComponent>
          </Grid>
        )}
        <Grid item xs={12}>
          {editMode ? (
            <SelectComponent
              value={values.status}
              name="status"
              label={<RequiredOption label="Payment Status" />}
              onChange={handleChange}
              error={Boolean(touched.status && errors.status)}
              helperText={touched.status && errors.status}
            >
              <MenuItem value="ProcessingBatch">{StatusPayment.processingBatch}</MenuItem>
              <MenuItem value="Scheduled">{StatusPayment.scheduled}</MenuItem>
              <MenuItem value="Successful">{StatusPayment.successful}</MenuItem>
              <MenuItem value="Cancelled">{StatusPayment.cancelled}</MenuItem>
              <MenuItem value="Rejected">{StatusPayment.rejected}</MenuItem>
            </SelectComponent>
          ) : (
            <SelectComponent
              value={values.status}
              name="status"
              label={<RequiredOption label="Payment Status" />}
              onChange={handleChange}
              error={Boolean(touched.status && errors.status)}
              helperText={touched.status && errors.status}
            >
              <MenuItem value="Scheduled">{StatusPayment.scheduled}</MenuItem>
              <MenuItem value="Successful">{StatusPayment.successful}</MenuItem>
            </SelectComponent>
          )}
        </Grid>

        {!editMode && (
          <Grid item xs={12}>
            <SelectComponent
              disabled={editMode}
              value={values.category}
              name="category"
              label={<RequiredOption label="Transaction Type" />}
              onChange={handleChange}
              error={Boolean(touched.category && errors.category)}
              helperText={touched.category && errors.category}
            >
              {Object.entries(DealTransactionTypes).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </SelectComponent>
          </Grid>
        )}
        {!editMode && values.category === 'LegalCostVendor' && (
          <Grid item xs={12}>
            <VendorAutoComplete
              vendorId={values.vendorId}
              error={Boolean(touched.vendorId && errors.vendorId)}
              helperText={touched.vendorId && errors.vendorId}
              onChange={(e, value) => {
                setFieldValue('vendorId', value?.id ?? '')
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DatePicker
            fullWidth
            label="Payment Date"
            name="paymentDate"
            value={values.paymentDate || null}
            error={Boolean(touched.paymentDate && errors.paymentDate) || !isValidDate(values.paymentDate)}
            helperText={(touched.paymentDate && errors.paymentDate) || (!isValidDate(values.paymentDate) && 'MM/DD/YYYY')}
            type="keyboard"
            onChange={(date) => setFieldValue('paymentDate', convertDateForPicker(date))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Comment"
            name="comments"
            multiline
            rows={3}
            value={values.comments}
            onChange={handleChange}
            error={Boolean(touched.comments && errors.comments)}
            helperText={touched.comments && errors.comments}
          />
        </Grid>
      </Grid>
      <Box mt={10}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button onClick={cancel} fullWidth color="secondary" variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button loading={loading} fullWidth color="primary" variant="contained" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default DealPaymentForm

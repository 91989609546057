import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ISO_ADD_NEW_URL, ISO_LIST_URL, ISO_INFO_URL, ISO_EDIT_URL } from 'constants/routes'
import ISOListPage from 'pages/ISOList'
import AddNewIsoPage from 'pages/AddNewIso'
import IsoInfoPage from 'pages/IsoInfo'
import EditIsoPage from 'pages/EditIsoPage'
import { usePermission } from 'hooks/usePermission'
import { ADD_ISO_PERM, EDIT_ISO_PERM, ISO_MODULE_PERM, ISO_OWN_VIEW_PERM } from 'constants/permissions'

const IsoContainer: FC = () => {
  const { hasPermission } = usePermission()
  return (
    <Switch>
      {hasPermission(ISO_MODULE_PERM) && <Route exact path={ISO_LIST_URL} component={ISOListPage} />}
      {hasPermission(ADD_ISO_PERM) && <Route exact path={ISO_ADD_NEW_URL} component={AddNewIsoPage} />}
      {(hasPermission(ISO_MODULE_PERM) || hasPermission(ISO_OWN_VIEW_PERM)) && <Route exact path={ISO_INFO_URL} component={IsoInfoPage} />}
      {hasPermission(EDIT_ISO_PERM) && <Route exact path={ISO_EDIT_URL} component={EditIsoPage} />}
    </Switch>
  )
}

export default IsoContainer

import React, { FC, useCallback, useEffect } from 'react'
import Paper from '@material-ui/core/Paper/Paper'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as ExpandIcon } from 'assets/svg/ExpandIcon.svg'
import { ReactComponent as FilterIcon } from 'assets/svg/FilterIcon.svg'
import IconButton from '@material-ui/core/IconButton'
import TableComponent, { TableComponentType } from 'UI/Table'
import SelectGroup from 'UI/Select/SelectGroup'
import CustomIconButton from 'UI/Button/IconButton'
import { useFormik } from 'formik'
import { IDealsFilters, IDealsListItem } from 'typescript/interfaces/deals'
import { usePaginationList } from 'hooks/usePaginationList'
import { DealsStatus, SortFieldsDeal } from 'typescript/enums/deals'
import { useFullDeals } from 'hooks/useFullDeals'
import { useMutation } from 'react-query'
import { getDealList } from 'services/deals'
import RepAutoComplete from 'components/RepAutoComplete'
import ISOAutoComplete from 'components/ISOAutoComplete'
import { useLatestDealsStyle } from 'containers/Deal/LatestDeals/style'
import { useHistory, generatePath } from 'react-router-dom'
import { DEALS_LIST_URL } from 'constants/routes'

const TableLatestDeals = TableComponent as TableComponentType<IDealsListItem>

const LatestDeals: FC = () => {
  const s = useLatestDealsStyle()
  const { push } = useHistory()
  const { setPagination, handleFetchMore, handleSort, pagination } = usePaginationList<IDealsListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsDeal.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getDealListMut, { isLoading }] = useMutation(getDealList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  useEffect(() => {
    getDealListMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      RepresentativeId: pagination.repId,
      IsoId: pagination.isoId,
      Statuses: [DealsStatus.approved],
    })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.repId, pagination.isoId])

  const handleFiltersSubmit = (filtersToSet: IDealsFilters) => {
    if (filtersToSet.repId === pagination.repId && filtersToSet.isoId === pagination.isoId) return
    setPagination((prevState) => ({ ...prevState, ...filtersToSet, data: [] }))
  }

  const { values: filters, setValues, handleSubmit } = useFormik<IDealsFilters>({
    initialValues: {
      repId: undefined,
      isoId: undefined,
    },
    onSubmit: handleFiltersSubmit,
  })

  const handleRepChange = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    setValues((prevState) => ({
      ...prevState,
      repId: value?.map((v: any) => v.id),
    }))
  }, [])

  const handleIsoChange = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    setValues((prevState) => ({
      ...prevState,
      isoId: value?.map((v: any) => v.id),
    }))
  }, [])

  const { columnsForDashboard } = useFullDeals()

  return (
    <Paper elevation={0}>
      <Box py={4} px={4} pb={0}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" color="textSecondary">
            Latest Deals
          </Typography>
          <IconButton onClick={() => push(generatePath(DEALS_LIST_URL))} size="small">
            <SvgIcon fontSize="small" viewBox="0 0 16 16" component={ExpandIcon} />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" mt={4} mb={2}>
          <Box flex={1}>
            <SelectGroup>
              <RepAutoComplete multiple onChange={handleRepChange} className={s.autocomplete} repId={filters.repId} />
              <ISOAutoComplete multiple onChange={handleIsoChange} className={s.autocomplete} isoId={filters.isoId} />
            </SelectGroup>
          </Box>
          <Box flexShrink={1} ml={3} minWidth={36} width={88}>
            <CustomIconButton onClick={() => handleSubmit()} color="secondary" size="small" height="2rem" width="100%">
              <SvgIcon fontSize="inherit" viewBox="0 0 16 13" color="secondary" component={FilterIcon} />
            </CustomIconButton>
          </Box>
        </Box>
        <Box mx={-4}>
          <TableLatestDeals
            stickyHeader
            tableContainerClassName={s.table}
            handleSortChange={handleSort}
            columns={columnsForDashboard}
            loading={isLoading}
            handleGetMore={handleFetchMore}
            data={pagination.data}
            hasMore={pagination.hasMore}
            lazy
          />
        </Box>
      </Box>
    </Paper>
  )
}

export default LatestDeals

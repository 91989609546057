import React, { FC } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { history } from 'services/history'
import { BASE_APP_URL, AUTH_URLS, REQUEST_ANONYMOUS } from 'constants/routes'
import Auth from 'pages/Auth'
import AppContainer from 'containers/App'
import { PrivateRoute } from 'components/PrivateRoute'
import UserProvider from 'contextProviders/UserInfo'
import ApproveRequestInfoCooperationPage from 'pages/ApproveRequestInfoCooperationPage'

const MainRouter: FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={REQUEST_ANONYMOUS} component={ApproveRequestInfoCooperationPage} />
        <Route path={AUTH_URLS} component={Auth} />
        <UserProvider>
          <PrivateRoute path={BASE_APP_URL} component={AppContainer} />
        </UserProvider>
      </Switch>
    </Router>
  )
}

export default MainRouter

import React, { FC, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from 'UI/Button/ButtonWithPreloader'
import { ReactComponent as AddSquareIcon } from 'assets/svg/PlustSquareIcon.svg'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { TableIso, useIso } from 'hooks/useIso'
import { useHistory } from 'react-router-dom'
import { ISO_ADD_NEW_URL } from 'constants/routes'
import { useMutation } from 'react-query'
import { getIsoList } from 'services/iso'
import { IIsoListItem } from 'typescript/interfaces/iso'
import SearchField from 'UI/SearchField'
import { usePaginationList } from 'hooks/usePaginationList'
import { exportIso } from 'services/export'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { ExportType } from 'typescript/enums/export'
import { downloadFile } from 'utils/downloadFile'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const IsoListContainer: FC = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  const { columns } = useIso({})
  const { push } = useHistory()
  const { pagination, setPagination, handleSearch, handleSort, handleFetchMore } = usePaginationList<IIsoListItem>({
    initState: {
      data: [],
      search: '',
      order: 'Id',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })
  const { enqueueSnackbar } = useSnackbar()

  const [getIso, { isLoading }] = useMutation(getIsoList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const [exportIsosExcelMut, { isLoading: isLoadingExportExcel }] = useMutation(exportIso, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    getIso({
      PageSize: 20,
      PageIndex: pagination.page,
      Query: pagination.search,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder])

  return (
    <Paper className={additionalStyle.wrapper} elevation={0}>
      <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" color="textSecondary">
          ISO List
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width={240} mr={5}>
            <SearchField handleSearch={handleSearch} fullWidth label="Search ISO" />
          </Box>
          <Button variant="text" color="secondary" startIcon={<AddSquareIcon />} onClick={() => push(ISO_ADD_NEW_URL)}>
            Add New ISO
          </Button>
          <Box ml={5}>
            <Button
              loading={isLoadingExportExcel}
              onClick={() =>
                exportIsosExcelMut({
                  ExportType: ExportType.excel,
                })
              }
              variant="text"
              color="secondary"
              startIcon={<ExcelIcon />}
            >
              Export to Excel
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableIso
          handleSortChange={handleSort}
          loading={isLoading}
          columns={columns}
          data={pagination.data}
          handleGetMore={handleFetchMore}
          hasMore={pagination.hasMore}
        />
      </Box>
    </Paper>
  )
}

export default IsoListContainer

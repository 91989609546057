import React, { FC, useCallback } from 'react'
import { useNewClientStyle } from 'containers/NewClient/style'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import DocumentsForm from 'components/ClientForms/DocumentsForm'
import { useMutation } from 'react-query'
import { IUploadDocWithDocType, IUploadedClientCategoriesKeys } from 'typescript/interfaces/deals'
import { generatePath, useHistory, useParams, useLocation } from 'react-router-dom'
import { VIEW_CLIENT_URL } from 'constants/routes'
import { uploadClientDocuments } from 'services/clients'
import { IDocumentToSend, IInitClientDocsValues } from 'typescript/interfaces/clients'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const DocumentsContainer: FC = () => {
  const classes = useNewClientStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const { push, goBack, replace } = useHistory()
  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const backUrl = params.get('backUrl')

  const [mutate, { isLoading }] = useMutation((docs: IUploadDocWithDocType[]) => uploadClientDocuments(docs, +id), {
    onSuccess() {
      push(
        generatePath(VIEW_CLIENT_URL, {
          id,
        }),
      )
    },
  })

  const handleSubmit = useCallback(
    async (formValues: IInitClientDocsValues) => {
      const documentsToSend: IDocumentToSend[] = []

      // eslint-disable-next-line guard-for-in
      for (const category in formValues.documents) {
        formValues.documents[category as IUploadedClientCategoriesKeys].forEach((f) => {
          documentsToSend.push({ file: f, documentType: category as IUploadedClientCategoriesKeys })
        })
      }
      await mutate(documentsToSend)
    },
    [mutate],
  )

  return (
    <Box className={additionalStyle.wrapper}>
      <Box display="flex" alignItems="center">
        <Box ml="3rem" mr="1rem">
          <IconButton onClick={backUrl ? () => replace(backUrl) : goBack} color="inherit">
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography variant="h1" color="textSecondary">
          Documents
        </Typography>
      </Box>
      <Box mt="2rem">
        <Paper elevation={0} className={classes.paper}>
          <DocumentsForm
            loading={isLoading}
            back={{
              label: 'Back',
              handler: backUrl ? () => replace(backUrl) : goBack,
            }}
            submit={{
              label: 'Complete',
              handler: handleSubmit,
            }}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default DocumentsContainer

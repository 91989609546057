import React, { FC, useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertEmptyField } from 'utils/formatters'
import { IVendorListLightItem } from 'typescript/interfaces/vendor'

const TableVendorsLight = TableComponent as TableComponentType<IVendorListLightItem>

interface IVendorLightTableProps {
  data: IVendorListLightItem[]
}

const VendorsLightTable: FC<IVendorLightTableProps> = ({ data }) => {
  const classes = useTableComponentStyle()
  const columnsRef = useRef<IColumn<IVendorListLightItem>[]>([
    {
      label: '#',
      field: 'id',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertEmptyField(rowData.id),
    },
    {
      label: 'Name',
      field: 'name',
      bodyCellClassName: classes.accentCell,
      render: (rowData) => convertEmptyField(rowData.name),
    },
  ])
  return <TableVendorsLight columns={columnsRef.current} data={data} />
}

export default VendorsLightTable

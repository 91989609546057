import {
  COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT,
  MIN_VALUE_IS_ONE,
  NUMBERS_ONLY,
  NUMBER_CANNOT_BE_NEGATIVE,
  REQUIRED_FIELD,
  MUST_BE_INTEGER,
  MIN_VALUE_IS,
  ONLY_DIGITS,
} from 'constants/errors'
import { paymentFrequency } from 'constants/paymentFrequency'
import { integerReg } from 'constants/regExp'
import * as yup from 'yup'

export const schemaOutHouseDeal = yup.object().shape({
  clientId: yup.number().typeError(NUMBERS_ONLY).required(REQUIRED_FIELD),
  outhouseLenders: yup
    .array()
    .typeError(REQUIRED_FIELD)
    .test('no-undefined', REQUIRED_FIELD, (val) => val !== undefined)
    .min(1, REQUIRED_FIELD),
  isoId: yup.number().typeError(NUMBERS_ONLY),
  paymentFrequency: yup.string().oneOf(paymentFrequency),
  advanceAmount: yup.string().required(REQUIRED_FIELD).matches(integerReg, ONLY_DIGITS),
  paybackAmount: yup.number().typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  earlyPayOffAmount: yup.number().typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  frequentPayment: yup.number().typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  commissionToRep: yup
    .string()
    .matches(integerReg, ONLY_DIGITS)
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function (value) {
      if (!this.parent.representativeIds) return true
      const rFee = value || 0
      const aAmount = this.parent.advanceAmount || 0

      return aAmount * 0.15 >= rFee
    }),
  isoFee: yup
    .string()
    .matches(integerReg, ONLY_DIGITS)
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function () {
      if (!this.parent.isoId) return true

      const іFee = this.parent.isoFee || 0
      const aAmount = this.parent.advanceAmount || 0

      return aAmount * 0.15 >= іFee
    }),
  commissionSplit: yup.number().typeError(NUMBERS_ONLY).min(0, NUMBER_CANNOT_BE_NEGATIVE),
  psfFee: yup
    .string()
    .matches(integerReg, ONLY_DIGITS)
    .test('comission', COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT, function () {
      const pFee = this.parent.psfFee || 0
      const aAmount = this.parent.advanceAmount || 0

      return aAmount * 0.15 >= pFee
    })
    .typeError(NUMBERS_ONLY)
    .min(0, NUMBER_CANNOT_BE_NEGATIVE),
  psfOuthouseProcess: yup.boolean(),
  comments: yup.string(),
  term: yup.number().integer(MUST_BE_INTEGER).min(1, MIN_VALUE_IS_ONE),
  factor: yup.number().typeError(NUMBERS_ONLY).min(1, MIN_VALUE_IS.replace(':value', '1')).max(2, MIN_VALUE_IS.replace(':value', '2')),
  representativeId: yup.number().typeError(NUMBERS_ONLY),
})

import { useCallback, useState } from 'react'

interface IInitState<T> {
  data: Array<T>
  search: string
  order: any
  sortOrder: 'ASC' | 'DESC'
  page: number
  hasMore: boolean
  [key: string]: any
}

interface IProps<T> {
  initState?: IInitState<T>
}

const usePaginationList = <T extends unknown>(props: IProps<T> = {}) => {
  const { initState } = props
  const [pagination, setPagination] = useState<IInitState<T>>(
    initState || {
      data: [],
      search: '',
      order: '',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  )

  const handleSearch = useCallback((search) => {
    setPagination((prevState) => ({
      ...prevState,
      data: prevState.search !== search ? [] : prevState.data,
      page: 0,
      search: search,
    }))
  }, [])

  const handleSort = useCallback((order, orderSor) => {
    setPagination((prevState) => ({
      ...prevState,
      page: 0,
      data: [],
      order: order,
      sortOrder: orderSor,
    }))
  }, [])

  const handleFetchMore = useCallback(() => {
    setPagination((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }))
  }, [])

  return {
    pagination,
    handleFetchMore,
    handleSort,
    handleSearch,
    setPagination,
  }
}

export { usePaginationList }

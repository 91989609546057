import { Box, makeStyles, Paper, SvgIcon, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { usePaginationList } from 'hooks/usePaginationList'
import { TableTodaysPaymentReport, useTodaysPaymentReport } from 'hooks/useTodaysPaymentReport'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { getTodaysPaymentReportList } from 'services/todaysPaymentReports'
import { ITodaysPaymentReportListItem } from 'typescript/interfaces/todaysPaymentReport'
import DatePicker from 'UI/DatePicker/DatePicker'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as FilterIcon } from 'assets/svg/FilterIcon.svg'
import { startOfDay, endOfDay } from 'date-fns'
import CustomIconButton from 'UI/Button/IconButton'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { downloadFile } from 'utils/downloadFile'
import { exportPaymentReports } from 'services/export'
import Button from 'UI/Button/ButtonWithPreloader'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { schemaTodaysPaymentReportFilters } from './schema'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
}))

const TodaysPaymentReportContainer = () => {
  const s = useStyles()
  const additionalStyle = useAdditionalMaterialStyle()
  const { columns } = useTodaysPaymentReport()
  const { enqueueSnackbar } = useSnackbar()

  const { values, touched, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      from: startOfDay(new Date()).toISOString(),
      to: endOfDay(new Date()).toISOString(),
    },
    validationSchema: schemaTodaysPaymentReportFilters,
    onSubmit: (formValues) => {
      if (formValues.from === pagination.from && formValues.to === pagination.to) {
        return
      }
      setPagination((prevState) => ({ ...prevState, ...formValues, data: [] }))
    },
  })

  const { handleSort, handleFetchMore, pagination, setPagination } = usePaginationList<ITodaysPaymentReportListItem>({
    initState: {
      data: [],
      search: '',
      order: 'Date',
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
      from: startOfDay(new Date()).toISOString(),
      to: endOfDay(new Date()).toISOString(),
    },
  })

  const [getTodaysPaymentReportMut, { isLoading }] = useMutation(getTodaysPaymentReportList, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
    onError: () => {
      enqueueSnackbar(<Typography>Something went wrong</Typography>)
    },
  })

  const [exportPaymentReportsMut, { isLoading: isLoadingExportExcel }] = useMutation(exportPaymentReports, {
    onSuccess: (data) => {
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  useEffect(() => {
    getTodaysPaymentReportMut({
      PageSize: 20,
      PageIndex: pagination.page,
      Query: pagination.search,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      StartDate: startOfDay(new Date(pagination.from)).toISOString(),
      EndDate: endOfDay(new Date(pagination.to)).toISOString(),
    })
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder, pagination.from, pagination.to])

  return (
    <Box className={additionalStyle.wrapper} component={Paper} mb="2rem">
      <Box p="3rem" pb="2rem" display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography className={s.title} variant="h2" color="textSecondary">
            Today’s Payment Report
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Box maxWidth="274px" ml="1rem">
            <DatePicker
              fullWidth
              label="From"
              maxDate={values.to}
              name="from"
              value={values.from || null}
              error={Boolean(touched.from && errors.from) || !isValidDate(values.from)}
              helperText={(touched.from && errors.from) || (!isValidDate(values.from) && 'MM/DD/YYYY')}
              onChange={(date) => setFieldValue('from', convertDateForPicker(date))}
              type="keyboard"
            />
          </Box>
          <Box maxWidth="274px" ml="1rem">
            <DatePicker
              fullWidth
              label="To"
              name="to"
              minDate={values.from}
              value={values.to || null}
              error={Boolean(touched.to && errors.to) || !isValidDate(values.to)}
              helperText={(touched.to && errors.to) || (!isValidDate(values.to) && 'MM/DD/YYYY')}
              onChange={(date) => setFieldValue('to', convertDateForPicker(date))}
              type="keyboard"
            />
          </Box>
          <Box minWidth={36} width={138} ml={3}>
            <CustomIconButton onClick={() => handleSubmit()} size="small" color="secondary" width="100%" height={36}>
              <SvgIcon fontSize="inherit" viewBox="0 0 16 13" color="secondary" component={FilterIcon} />
            </CustomIconButton>
          </Box>
          <Box ml="4rem">
            <Button
              variant="text"
              color="secondary"
              loading={isLoadingExportExcel}
              disabled={isLoadingExportExcel}
              onClick={() =>
                exportPaymentReportsMut({
                  StartDate: startOfDay(new Date(pagination.from)).toISOString(),
                  EndDate: endOfDay(new Date(pagination.to)).toISOString(),
                })
              }
              startIcon={<SvgIcon viewBox="0 0 13 17" color="inherit" fontSize="inherit" component={ExcelIcon} />}
            >
              Export to Excel
            </Button>
          </Box>
        </Box>
      </Box>
      <TableTodaysPaymentReport
        loading={isLoading}
        columns={columns}
        data={pagination.data}
        handleSortChange={handleSort}
        hasMore={pagination.hasMore}
        handleGetMore={handleFetchMore}
      />
    </Box>
  )
}

export default TodaysPaymentReportContainer

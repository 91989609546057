import React, { FC, useMemo } from 'react'
import useTheme from '@material-ui/core/styles/useTheme'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import { IChartProps } from 'typescript/interfaces/dashboard'
import { convertDate, formatBigNumber } from 'utils/formatters'

const PNLChartDetailed: FC<IChartProps> = ({ stats }) => {
  const theme = useTheme()
  const fontSizeHtml = parseFloat(getComputedStyle(document.documentElement).fontSize)
  const data = stats?.data?.map(({ date, pnl }) => ({ name: convertDate(new Date(date)), pnl }))

  const maxLength = useMemo(() => {
    return data
      ? formatBigNumber(
          data.reduce((acc, val) => {
            return String(acc).length < String(val.pnl).length ? val.pnl : acc
          }, 0),
        ).toString().length *
          (fontSizeHtml / 2)
      : 0
  }, [data])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <YAxis
          allowDecimals={false}
          tick={{
            fill: theme.palette.text.primary,
            strokeWidth: 1,
          }}
          minTickGap={0}
          tickCount={4}
          width={maxLength}
          axisLine={false}
          tickLine={false}
          interval="preserveStartEnd"
          tickMargin={0}
          tickFormatter={(item) => formatBigNumber(item)}
          padding={{
            top: 0,
            bottom: 0,
          }}
        />

        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip labelFormatter={(index) => data?.[index as number]?.name} />
        <Area type="monotone" dataKey="pnl" stroke={theme.palette.primary.main} fillOpacity={0.3} fill={theme.palette.primary.main} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default PNLChartDetailed

import {
  API_EXPORT_CLIENTS,
  API_EXPORT_ISOS,
  API_EXPORT_VENDORS,
  API_EXPORT_REP,
  API_EXPORT_SYNDICATORS,
  API_EXPORT_DEALS,
  API_EXPORT_COMPANIES,
  API_EXPORT_PAYMENT_REPORTS,
  API_EXPORT_TRANSACTIONS,
  API_EXPORT_SYNDICATOR_REPORT,
  API_EXPORT_SYNDICATOR_SUMMARY,
  API_EXPORT_REP_PORTFOLIO_SUMMARY,
  API_EXPORT_DEAL_PAY_HISTORY,
  API_EXPORT_DEAL_PAY_HISTORY_CONDENSED,
  API_EXPORT_DEAL_PAYMENTS,
} from 'constants/apiUrl'
import { storageInstance } from 'services/axios/storageInstance'
import { IClientExportParams } from 'typescript/interfaces/clients'
import { IIsoExportParams } from 'typescript/interfaces/iso'
import { IVendorExportParams } from 'typescript/interfaces/vendor'
import { IRepExportParams, IRepPortfolioSummaryExportParams } from 'typescript/interfaces/rep'
import { ISyndicatorExportParams, ISyndicatorExportSummaryParams, ISyndicatorReportExportParams } from 'typescript/interfaces/syndicators'
import { IDealPayHistoryParams, IDealsExportParams, IExportDealPaymentsParams } from 'typescript/interfaces/deals'
import { ICompaniesExportParams } from 'typescript/interfaces/companies'
import { AxiosResponse } from 'axios'
import { IPagination } from 'typescript/interfaces/pagination'
import { AdditionalPaginationProps } from 'typescript/interfaces/todaysPaymentReport'
import { ITransactionsExportParams } from 'typescript/interfaces/transactions'
import { transformRequestOptions } from 'services/deals'

export const exportClients = (params: IClientExportParams) =>
  storageInstance.get<IClientExportParams, AxiosResponse<boolean>>(API_EXPORT_CLIENTS, {
    responseType: 'blob',
    params: params,
  })

export const exportIso = (params: IIsoExportParams) =>
  storageInstance.get<IIsoExportParams, AxiosResponse<boolean>>(API_EXPORT_ISOS, {
    responseType: 'blob',
    params: params,
  })

export const exportVendor = (params: IVendorExportParams) =>
  storageInstance.get<IVendorExportParams, AxiosResponse<boolean>>(API_EXPORT_VENDORS, {
    responseType: 'blob',
    params: params,
  })

export const exportRep = (params: IRepExportParams) =>
  storageInstance.get<IRepExportParams, AxiosResponse<boolean>>(API_EXPORT_REP, {
    responseType: 'blob',
    params: params,
  })

export const exportSyndicator = (params: ISyndicatorExportParams) =>
  storageInstance.get<ISyndicatorExportParams, AxiosResponse<boolean>>(API_EXPORT_SYNDICATORS, {
    responseType: 'blob',
    params: params,
  })

export const exportDeals = (params: IDealsExportParams) =>
  storageInstance.get<IDealsExportParams, AxiosResponse<boolean>>(API_EXPORT_DEALS, {
    responseType: 'blob',
    params: params,
    paramsSerializer: transformRequestOptions,
  })

export const exportCompanies = (params: ICompaniesExportParams) =>
  storageInstance.get<ICompaniesExportParams, AxiosResponse<BlobPart>>(API_EXPORT_COMPANIES, {
    responseType: 'blob',
    params: params,
  })

export const exportPaymentReports = (params: IPagination & AdditionalPaginationProps) =>
  storageInstance.get<IPagination & AdditionalPaginationProps, AxiosResponse<BlobPart>>(API_EXPORT_PAYMENT_REPORTS, {
    responseType: 'blob',
    params: params,
  })

export const exportTransactions = (params: ITransactionsExportParams) =>
  storageInstance.get<IPagination & AdditionalPaginationProps, AxiosResponse<BlobPart>>(API_EXPORT_TRANSACTIONS, {
    responseType: 'blob',
    params: params,
  })

export const exportSyndicatorReport = (params: ISyndicatorReportExportParams) =>
  storageInstance.get<ISyndicatorExportParams, AxiosResponse<boolean>>(API_EXPORT_SYNDICATOR_REPORT, {
    responseType: 'blob',
    params,
  })

export const exportSyndicatorSummary = (params: ISyndicatorExportSummaryParams) =>
  storageInstance.get<ISyndicatorExportParams, AxiosResponse<BlobPart>>(API_EXPORT_SYNDICATOR_SUMMARY, {
    responseType: 'blob',
    params,
  })

export const exportRepPortfolioSummary = (params: IRepPortfolioSummaryExportParams) =>
  storageInstance.get<IRepPortfolioSummaryExportParams, AxiosResponse<BlobPart>>(API_EXPORT_REP_PORTFOLIO_SUMMARY, {
    responseType: 'blob',
    params,
    paramsSerializer: transformRequestOptions,
  })

export const exportDealPayHistory = (params: IDealPayHistoryParams) =>
  storageInstance.get<IDealPayHistoryParams, AxiosResponse<BlobPart>>(API_EXPORT_DEAL_PAY_HISTORY, {
    params,
    responseType: 'blob',
  })

export const exportDealPayHistoryCondensed = (params: IDealPayHistoryParams) =>
  storageInstance.get<IDealPayHistoryParams, AxiosResponse<BlobPart>>(API_EXPORT_DEAL_PAY_HISTORY_CONDENSED, {
    params,
    responseType: 'blob',
  })

export const exportDealPayments = (params: IExportDealPaymentsParams) =>
  storageInstance.get<IExportDealPaymentsParams, AxiosResponse<BlobPart>>(API_EXPORT_DEAL_PAYMENTS, {
    params,
    responseType: 'blob',
  })

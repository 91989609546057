import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import { generatePath, Link, useParams, useHistory } from 'react-router-dom'
import { ITransactionParamsInitValue, ITransactionsQueryParams, IWithdrawalListItem } from 'typescript/interfaces/transactions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import { ReactComponent as CancelIcon } from 'assets/svg/CloseIcon.svg'
import Paper from '@material-ui/core/Paper'
import { useTransactionsStyle } from 'containers/Transactions/style'
import cn from 'classnames'
import ModalComponent from 'UI/Modal'
import AddTransactionForm from 'components/AddTransactionForm'
import IconButton from '@material-ui/core/IconButton'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import {
  ISO_INFO_URL,
  ISO_LIST_URL,
  REP_INFO_URL,
  REP_LIST_URL,
  SYNDICATOR_INFO_URL,
  SYNDICATORS_URL,
  VENDOR_INFO_URL,
  VENDOR_LIST_URL,
} from 'constants/routes'
import { useMutation, useQuery } from 'react-query'
import { createTransaction, getWithdrawal } from 'services/transactions'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { getIsoById } from 'services/iso'
import { usePaginationList } from 'hooks/usePaginationList'
import { SortFieldsWithdrawal } from 'typescript/enums/transactions'
import { getRepById } from 'services/rep'
import { getVendorById } from 'services/vendor'
import { getSyndicator } from 'services/syndicators'
import { TableWithdrawalQueues, useWithdrawalQueuesTable } from 'hooks/useWithdrawalQueuesTable'
import { usePermission } from 'hooks/usePermission'
import { ADD_WITHDRAWAL_PERM, WITHDRAWAL_PERM } from 'constants/permissions'
import { UserContext } from 'contexts/userContext'
import { AxiosError } from 'axios'

const WithdrawalActivityContainer: FC = () => {
  const {
    state: { roles },
  } = useContext(UserContext)
  const { goBack } = useHistory()
  const { hasPermission, isOwnVendor, isOwnRep, isOwnSyndicator } = usePermission()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useTransactionsStyle()
  const { columns } = useWithdrawalQueuesTable({})
  const [openTrsModal, setOpenTrsModal] = useState(false)
  const { type, id, businessName } = useParams<ITransactionsQueryParams>()
  const { handleSort, handleFetchMore, pagination, setPagination } = usePaginationList<IWithdrawalListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsWithdrawal.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const { data: dataRep, isLoading: isLoadingGetReps } = useQuery(['repById', id], () => getRepById(id), {
    retry: false,
    enabled: type === 'rep',
  })

  const { data: dataIso, isLoading: isLoadingGetIso } = useQuery(['isoById', id], () => getIsoById(id), {
    retry: false,
    enabled: type === 'iso',
  })

  const { data: dataSyndicator, isLoading: isLoadingGetSyn } = useQuery(['syndicatorById', id], () => getSyndicator(id), {
    retry: false,
    enabled: type === 'syndicator',
  })

  const { data: dataVendor, isLoading: isLoadingGetVendors } = useQuery(['vendorById', id], () => getVendorById(id), {
    retry: false,
    enabled: type === 'vendor',
  })

  const userPageId = useMemo(() => {
    switch (type) {
      case 'iso': {
        return dataIso ? dataIso.data.userPage.id?.toString() : '0'
      }
      case 'rep': {
        return dataRep ? dataRep.data.userPage.id?.toString() : '0'
      }
      case 'vendor': {
        return dataVendor ? dataVendor.data.userPage.id.toString() : '0'
      }
      case 'syndicator': {
        return dataSyndicator ? dataSyndicator.data.userPage.id?.toString() : ''
      }
      default: {
        return ''
      }
    }
  }, [dataIso, type, dataRep, dataVendor, dataSyndicator])

  const [createTransMut, { isLoading }] = useMutation(createTransaction, {
    onSuccess: () => {
      enqueueSnackbar('Transaction successfully created')
      setOpenTrsModal(false)
      if (pagination.page === 0)
        getWithdrawalMut(
          {
            PageSize: 20,
            PageIndex: pagination.page,
            Query: pagination.search,
            SortField: pagination.order,
            SortOrder: pagination.sortOrder,
            UserPageId: userPageId,
          },
          {
            onSuccess: (data) => {
              setPagination((prevState) => ({
                ...prevState,
                page: 0,
                data: [...data.data],
                hasMore: data.data.length === 20,
              }))
            },
          },
        )
      else
        setPagination((prevState) => ({
          ...prevState,
          page: 0,
          data: [],
          hasMore: true,
        }))
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'WITHDRAWAL_LIMIT_EXCEEDED') enqueueSnackbar(`Can't be more than 4 withdrawals per month`)
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleCreateTrans = useCallback(
    (values: Partial<ITransactionParamsInitValue>) => {
      const copyValues = { ...values }
      delete copyValues.userId
      if (!copyValues.dealId) copyValues.dealId = null
      createTransMut(copyValues)
    },
    [createTransMut],
  )

  const getUrls = useCallback(() => {
    let listUrl = ''
    let infoUrl = ''
    let listLabel = ''
    switch (type) {
      case 'iso': {
        listLabel = 'ISO list'
        listUrl = ISO_LIST_URL
        infoUrl = generatePath(ISO_INFO_URL, {
          id: id,
        })
        break
      }
      case 'rep': {
        listLabel = 'REP list'
        listUrl = REP_LIST_URL
        infoUrl = generatePath(REP_INFO_URL, {
          id: id,
        })
        break
      }
      case 'vendor': {
        listLabel = 'Vendor list'
        listUrl = VENDOR_LIST_URL
        infoUrl = generatePath(VENDOR_INFO_URL, {
          id: id,
        })
        break
      }
      case 'syndicator': {
        listLabel = 'Syndicator list'
        listUrl = SYNDICATORS_URL
        infoUrl = generatePath(SYNDICATOR_INFO_URL, {
          id: id,
        })
        break
      }
      default: {
        break
      }
    }
    return {
      listUrl,
      infoUrl,
      listLabel,
    }
  }, [type, id])

  const [getWithdrawalMut, { isLoading: isLoadingListTransactions }] = useMutation(getWithdrawal)

  useEffect(() => {
    if (userPageId && userPageId !== '0')
      getWithdrawalMut(
        {
          PageSize: 20,
          PageIndex: pagination.page,
          Query: pagination.search,
          SortField: pagination.order,
          SortOrder: pagination.sortOrder,
          UserPageId: userPageId,
        },
        {
          onSuccess: (data) => {
            setPagination((prevState) => ({
              ...prevState,
              data: [...prevState.data, ...data.data],
              hasMore: data.data.length === 20,
            }))
          },
        },
      )
  }, [pagination.page, pagination.search, pagination.order, pagination.sortOrder, userPageId])

  const filteredColumns = useMemo(() => {
    return columns.filter((c) => c.field !== 'actions')
  }, [columns])

  const isOwnWithdrawal = useMemo(() => {
    return (type === 'vendor' && isOwnVendor(+id)) || (type === 'rep' && isOwnRep(+id)) || (type === 'syndicator' && isOwnSyndicator(+id))
  }, [id, isOwnSyndicator, isOwnRep, isOwnVendor, type])

  if (!hasPermission(WITHDRAWAL_PERM) && !isOwnWithdrawal) goBack()

  return (
    <Box>
      <Box>
        <Breadcrumbs
          separator=">"
          classes={{
            separator: classes.separator,
          }}
        >
          {roles.isAdmin && (
            <Link className={cn(classes.linkBreadCrumb)} to={getUrls().listUrl}>
              {getUrls().listLabel}
            </Link>
          )}
          <Link className={cn(classes.linkBreadCrumb)} to={getUrls().infoUrl}>
            # {id}
          </Link>
          <Typography color="textSecondary">Withdrawal</Typography>
        </Breadcrumbs>
      </Box>
      <Box display="flex" alignItems="center" mt="1rem">
        <Box flex={1}>
          <Typography variant="h2" color="textSecondary">
            Withdrawal
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            color="inherit"
            variant="contained"
            startIcon={<PlusIcon />}
            onClick={() => setOpenTrsModal(true)}
            disabled={!hasPermission(ADD_WITHDRAWAL_PERM) && !isOwnWithdrawal}
          >
            Add New Record
          </Button>
        </Box>
      </Box>
      <Box mt="0.75rem">
        <Paper elevation={0}>
          <Box mt="1rem">
            <TableWithdrawalQueues
              hasMore={pagination.hasMore}
              handleSortChange={handleSort}
              handleGetMore={handleFetchMore}
              columns={filteredColumns}
              data={pagination.data}
              loading={isLoadingGetIso || isLoadingListTransactions || isLoadingGetReps || isLoadingGetVendors || isLoadingGetSyn}
            />
          </Box>
        </Paper>
      </Box>
      {userPageId && (
        <ModalComponent open={openTrsModal}>
          <Box maxWidth={500}>
            <Box display="flex" justifyContent="flex-end" mt="-1rem" mr="-1rem">
              <IconButton
                color="secondary"
                size="small"
                onClick={() => setOpenTrsModal(false)}
                classes={{
                  root: cn(classes.closeTrsModalButton),
                }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
            <Typography variant="h2" color="textSecondary">
              {businessName}
            </Typography>
            <Box mt="2rem">
              <AddTransactionForm
                userPageId={userPageId || ''}
                withdrawal
                loading={isLoading}
                submit={handleCreateTrans}
                handleCancel={() => setOpenTrsModal(false)}
              />
            </Box>
          </Box>
        </ModalComponent>
      )}
    </Box>
  )
}

export default WithdrawalActivityContainer

import * as yup from 'yup'

export const schemaCreateNewPassword = yup.object().shape({
  newPassword: yup
    .string()
    .test(
      'password',
      //eslint-disable-next-line
      'Incorrect format. Password must contain at least 8 characters, 1 Upper case letter, 1 Lower case letter, 1 number and 1 special character.',
      function (password) {
        // eslint-disable-next-line
        const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_.,@#\$%\^&\*])(?=.{8,})/
        return passwordRegEx.test(password || '')
      },
    )
    .required('Enter a password'),
  confirmPassword: yup.mixed().test('match', 'Passwords do not match', function () {
    return this.parent.newPassword === this.parent.confirmPassword
  }),
})

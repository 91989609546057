import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import { SYNDICATORS_URL, SYNDICATORS_NEW_URL, SYNDICATORS_EDIT_URL, SYNDICATOR_INFO_URL, SYNDICATOR_REPORT_URL } from 'constants/routes'
import NewSyndicatorPage from 'pages/NewSyndicator'
import SyndicatorListPage from 'pages/SyndicatorList'
import SyndicatorInfoPage from 'pages/SyndicatorInfo'
import SyndicatorReportPage from 'pages/SyndicatorReport'
import { usePermission } from 'hooks/usePermission'
import { SYNDICATORS_MODULE_PERM, SYNDICATORS_VIEW_PERM, SYNDICATOR_OWN_VIEW_PERM, SYNDICATOR_CREATE_PERM } from 'constants/permissions'

const SyndicatorsContainer: FC = () => {
  const { hasPermission } = usePermission()
  return (
    <Switch>
      {(hasPermission(SYNDICATORS_MODULE_PERM) || hasPermission(SYNDICATORS_VIEW_PERM)) && (
        <Route exact path={SYNDICATORS_URL} component={SyndicatorListPage} />
      )}
      {hasPermission(SYNDICATOR_CREATE_PERM) && <Route exact path={[SYNDICATORS_NEW_URL, SYNDICATORS_EDIT_URL]} component={NewSyndicatorPage} />}
      {(hasPermission(SYNDICATORS_MODULE_PERM) || hasPermission(SYNDICATOR_OWN_VIEW_PERM)) && (
        <Route exact path={SYNDICATOR_INFO_URL} component={SyndicatorInfoPage} />
      )}
      {(hasPermission(SYNDICATORS_MODULE_PERM) || hasPermission(SYNDICATOR_OWN_VIEW_PERM)) && (
        <Route exact path={SYNDICATOR_REPORT_URL} component={SyndicatorReportPage} />
      )}
    </Switch>
  )
}

export default SyndicatorsContainer

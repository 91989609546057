import React, { FC, useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Button from 'UI/Button/ButtonWithPreloader'
import Paper from '@material-ui/core/Paper'
import { ReactComponent as EditPenIcon } from 'assets/svg/EditPenIcon.svg'
import { ReactComponent as UploadIcon } from 'assets/svg/UploadIcon.svg'
import Grid from '@material-ui/core/Grid'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { TableIsoDeals, TableIsoDocuments, useIso } from 'hooks/useIso'
import { getIsoById, deleteFileFromIso } from 'services/iso'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ISO_EDIT_URL, TRANSACTIONS_URL, UPLOAD_USER_PAGE_DOCS } from 'constants/routes'
import BusinessInfo from 'components/CompanyInfo/BusinessInfo'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { getFile } from 'services/storage'
import { createLinkToDownLoad } from 'utils/downloadFile'
import { EDIT_ISO_PERM, ISO_MODULE_PERM, SEE_ISO_DEALS_PERM, TRANSACTION_PERM, UPLOAD_USER_PAGE_DOCS_PERM } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'
import UserPageInfo from 'components/UserPageInfo'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const IsoInfoContainer: FC = () => {
  const { goBack, push } = useHistory()
  const { hasPermission, isOwnIso } = usePermission()
  const { id } = useParams<{ id: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const queryCache = useQueryCache()
  const aStyle = useAdditionalMaterialStyle()
  const [deleteFileState, setDeleteFileState] = useState({
    id: 0,
    open: false,
  })

  const handleDownLoadFile = useCallback((url, name) => {
    getFile(url).then((res) => {
      createLinkToDownLoad(res.data, name)
    })
  }, [])

  const { columnsIsoDocuments, columnsIsoDeals } = useIso({
    handleDeleteFile: (idFile) => {
      setDeleteFileState({
        open: true,
        id: idFile,
      })
    },
    handleDownloadFile: handleDownLoadFile,
    canDelete: hasPermission(ISO_MODULE_PERM),
  })

  const { data, isLoading } = useQuery(['isoById', id], () => getIsoById(id), {
    retry: false,
    cacheTime: 0,
  })

  const [removeDoc, { isLoading: isLoadingRemove }] = useMutation(deleteFileFromIso, {
    onSuccess: (_, variables) => {
      setDeleteFileState({
        open: false,
        id: 0,
      })
      queryCache.setQueryData(['isoById', id], {
        ...data,
        data: {
          ...data?.data,
          documents: data?.data.documents.filter((d) => d.id !== variables),
        },
      })
      enqueueSnackbar('Successfully deleted document')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  return (
    <Box className={aStyle.wrapper}>
      {isLoading || !data ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center">
            <Box ml="3rem" mr="1.5rem">
              <IconButton color="inherit" onClick={() => goBack()}>
                <ArrowLeftIcon />
              </IconButton>
            </Box>
            <Typography color="textSecondary" variant="h1">
              {data.data.businessName}
            </Typography>
            <Box mr="3rem" flex="1" display="flex" justifyContent="flex-end">
              {(hasPermission(TRANSACTION_PERM) || isOwnIso(+data?.data.id)) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    push(
                      generatePath(TRANSACTIONS_URL, {
                        type: 'iso',
                        id: data.data.id.toString(),
                        businessName: data.data.businessName,
                      }),
                    )
                  }}
                >
                  Activity
                </Button>
              )}
            </Box>
          </Box>
          <Box mt="2rem">
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Box width="100%" display="flex" justifyContent="flex-end" p="1rem" position="relative">
                    {hasPermission(EDIT_ISO_PERM) && (
                      <IconButton
                        className={aStyle.absoluteEditButton}
                        size="small"
                        onClick={() =>
                          push(
                            generatePath(ISO_EDIT_URL, {
                              id: data.data.id.toString(),
                            }),
                          )
                        }
                      >
                        <EditPenIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Box pb="2rem" px="3rem">
                    <Box mb="2rem">
                      <Typography variant="h3" color="textSecondary">
                        General Information
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        container
                        xs={6}
                        spacing={3}
                        style={{
                          alignContent: 'flex-start',
                        }}
                      >
                        <Grid item xs={6}>
                          <Typography>First Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{data.data.firstName}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Last Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{data.data.lastName}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Phone</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{data.data.phone}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Mobile</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{data.data.mobile}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Email</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{data.data.email}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Fax</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{data.data.fax}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item container xs={6} spacing={3}>
                        <UserPageInfo
                          disableCurrBalance={!hasPermission(TRANSACTION_PERM) && !isOwnIso(+data.data.id)}
                          userPage={data.data.userPage}
                          nameOnCheck={data.data.nameOnCheck}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <BusinessInfo
                    street={data.data.street}
                    businessName={data.data.businessName}
                    city={data.data.city}
                    state={data.data.state}
                    federalTaxId={data.data.federalTaxId}
                    zipCode={data.data.zipCode}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
          {hasPermission(SEE_ISO_DEALS_PERM) && (
            <Box mt={5}>
              <Paper elevation={0}>
                <Box p="3rem">
                  <Typography variant="h2" color="textSecondary">
                    ISO Deals
                  </Typography>
                </Box>
                <Box>
                  <TableIsoDeals columns={columnsIsoDeals} data={data.data.deals} />
                </Box>
              </Paper>
            </Box>
          )}
          <Box mt={5}>
            <Paper elevation={0}>
              <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" color="textSecondary">
                  Documents
                </Typography>
                <Box>
                  {(hasPermission(UPLOAD_USER_PAGE_DOCS_PERM) || isOwnIso(+data?.data.id)) && (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<UploadIcon />}
                      onClick={() =>
                        push(
                          generatePath(UPLOAD_USER_PAGE_DOCS, {
                            type: 'iso',
                            id: data.data.id,
                          }),
                        )
                      }
                    >
                      Upload documents
                    </Button>
                  )}
                </Box>
              </Box>
              <Box>
                <TableIsoDocuments columns={columnsIsoDocuments} data={data.data.documents} />
              </Box>
            </Paper>
          </Box>
        </>
      )}
      <ModalComponent open={deleteFileState.open}>
        <ConfirmationContent
          text="Are you sure you want delete file?"
          isLoading={isLoadingRemove}
          handleCancel={() =>
            setDeleteFileState({
              open: false,
              id: 0,
            })
          }
          handleConfirm={() => removeDoc(deleteFileState.id)}
        />
      </ModalComponent>
    </Box>
  )
}

export default IsoInfoContainer

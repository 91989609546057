import React, { FC } from 'react'
import { KeyboardDatePicker as KeyboardMuiDatePicker, DatePicker as MuiDatePicker, DatePickerProps } from '@material-ui/pickers'
import { ReactComponent as CalendarIcon } from 'assets/svg/CalendarIcon.svg'
import TextField from '@material-ui/core/TextField'

interface IDatePickerProps extends DatePickerProps {}

const DatePicker: FC<IDatePickerProps> = ({ format = 'MM/dd/yyyy', invalidDateMessage = 'MM/DD/YYYY', type, ...props }) => {
  return (
    <>
      {type === 'keyboard' ? (
        <KeyboardMuiDatePicker
          format={format}
          invalidDateMessage={invalidDateMessage}
          inputVariant="outlined"
          keyboardIcon={<CalendarIcon />}
          {...props}
        />
      ) : (
        <MuiDatePicker
          format={format}
          inputVariant="outlined"
          TextFieldComponent={(propsTextField) => (
            <TextField
              {...propsTextField}
              InputProps={{
                autoFocus: false,
                endAdornment: <CalendarIcon />,
                readOnly: true,
              }}
            />
          )}
          {...props}
        />
      )}
    </>
  )
}

export default DatePicker

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomStepper, { Step } from 'UI/Stepper/CustomStepper'
import DealInformationForm from 'components/OuthouseDealForm/DealInformation'

const InhouseDeal = () => {
  const [step, setStep] = useState(1)

  // eslint-disable-next-line
  const { push } = useHistory()

  const handleStepChange = (_: Step, s: number) => {
    setStep(s)
  }

  const steps = [{ title: 'Deal Information', link: '/deal/new/inhouse/1' }]

  return (
    <div>
      <CustomStepper maxSteps={3} steps={steps} current={step} onChange={handleStepChange} />
      <DealInformationForm />
    </div>
  )
}

export default InhouseDeal

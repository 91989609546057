import makeStyles from '@material-ui/core/styles/makeStyles'
import { bg3 } from 'containers/MaterialUiContainer/mainTheme'

export const useInfoBLockStyle = makeStyles((theme) => ({
  infoWrapper: {
    height: '100%',
    backgroundColor: bg3,
    borderRadius: theme.shape.borderRadius * 2,
  },
}))

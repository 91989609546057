import { createContext, Dispatch } from 'react'
import { CHANGE_CURRENT_USER, RESET_USER_STORE, SET_CLIENTS_BY_USER, SET_ISO, SET_REP, SET_SYNDICATOR, SET_VENDOR } from 'constants/actionTypes'
import { IActionUser, IRoleLight, IUserContextStore } from 'typescript/interfaces/users'
import {
  clientPermissions,
  repPermissions,
  adminPermissions,
  assistantPermissions,
  collectorPermissions,
  funderPermissions,
  isoPermissions,
  syndicatorPermissions,
} from 'constants/permissions'
import { ADMIN, ASSISTANT, CLIENT, COLLECTOR, FUNDER, ISO, REPRESENTATIVE, SYNDICATOR } from 'constants/roles'

const initState: IUserContextStore = {
  user: undefined,
  permissions: [],
  roles: {
    isAdmin: false,
    isISO: false,
    isRep: false,
    isSyndicator: false,
    isCollector: false,
    isFunder: false,
    isAssistant: false,
    isClient: false,
  },
  client: undefined,
  syndicator: undefined,
  vendor: undefined,
  iso: undefined,
  rep: undefined,
}

const reducer = (state: IUserContextStore, action: IActionUser) => {
  const { type } = action
  switch (type) {
    case SET_VENDOR: {
      return {
        ...state,
        vendor: action.payload,
      }
    }
    case SET_ISO: {
      return {
        ...state,
        iso: action.payload,
      }
    }
    case SET_SYNDICATOR: {
      return {
        ...state,
        syndicator: action.payload,
      }
    }
    case SET_REP: {
      return {
        ...state,
        rep: action.payload,
      }
    }
    case SET_CLIENTS_BY_USER: {
      return {
        ...state,
        client: action.payload,
      }
    }
    case RESET_USER_STORE: {
      return {
        ...initState,
      }
    }
    case CHANGE_CURRENT_USER: {
      const { userRoles } = action.payload
      const roles = {
        isAdmin: Boolean(userRoles.find((r: IRoleLight) => r.name === ADMIN)),
        isISO: Boolean(userRoles.find((r: IRoleLight) => r.name === ISO)),
        isRep: Boolean(userRoles.find((r: IRoleLight) => r.name === REPRESENTATIVE)),
        isSyndicator: Boolean(userRoles.find((r: IRoleLight) => r.name === SYNDICATOR)),
        isCollector: Boolean(userRoles.find((r: IRoleLight) => r.name === COLLECTOR)),
        isFunder: Boolean(userRoles.find((r: IRoleLight) => r.name === FUNDER)),
        isAssistant: Boolean(userRoles.find((r: IRoleLight) => r.name === ASSISTANT)),
        isClient: Boolean(userRoles.find((r: IRoleLight) => r.name === CLIENT)),
      }

      const permissions = [
        ...(roles.isAdmin ? adminPermissions : []),
        ...(roles.isISO ? isoPermissions : []),
        ...(roles.isRep ? repPermissions : []),
        ...(roles.isSyndicator ? syndicatorPermissions : []),
        ...(roles.isCollector ? collectorPermissions : []),
        ...(roles.isFunder ? funderPermissions : []),
        ...(roles.isAssistant ? assistantPermissions : []),
        ...(roles.isClient ? clientPermissions : []),
      ]

      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        permissions: permissions,
        roles: roles,
      }
    }
    default: {
      return state
    }
  }
}

const UserContext = createContext<{
  state: IUserContextStore
  dispatch: Dispatch<any>
}>({
  state: initState,
  dispatch: () => null,
})

export { UserContext, initState, reducer }

import { fade, makeStyles } from '@material-ui/core'
import { bg2 } from 'containers/MaterialUiContainer/mainTheme'

export const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 48,
    color: '#4C5878',
  },
  iconContainer: {
    border: `1px solid ${fade(theme.palette.secondary.main, 0.2)}`,
    background: bg2,
    padding: '0.4rem',
    marginRight: '0.75rem',
    borderRadius: '0.375rem',
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItem: {
    height: '3.25rem',
  },
}))

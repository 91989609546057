import makeStyles from '@material-ui/core/styles/makeStyles'

export const useSyndicatorFormStyles = makeStyles((theme) => ({
  selectLabel: {
    color: theme.palette.secondary.dark,
  },
  backButton: {
    marginRight: '1.25rem',
  },
  heading: {
    fontSize: '2.25rem',
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  subHeading: {
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
    marginBottom: '2rem',
    fontWeight: 500,
  },
  paper: {
    height: '100%',
    padding: '3rem',
  },
  fullHeight: {
    height: '100%',
  },
  checkboxLabel: {
    color: theme.palette.secondary.dark,
    fontSize: '1.0625rem',
    marginBottom: -3,
  },
  checkboxGrid: { paddingTop: '0 !important', paddingBottom: '0 !important', display: 'flex' },
}))

import axios, { AxiosError } from 'axios'
import { API_REFRESH_TOKEN, API_STORAGE_URL } from 'constants/apiUrl'
import { JWT_TOKEN, REFRESH_TOKEN } from 'constants/localStorageKeys'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { requestInterceptorCallback } from 'services/axios/publicInstance'
import { publicInstance } from './publicInstance'

const storageInstance = axios.create({ baseURL: API_STORAGE_URL })

export const refreshAccessToken = async () => {
  const response = await publicInstance.post(API_REFRESH_TOKEN, { refreshToken: localStorage.getItem(REFRESH_TOKEN) })
  localStorage.setItem(JWT_TOKEN, response.data.token)
  localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken)
  return response
}

// eslint-disable-next-line
const requestStorageInterceptor = storageInstance.interceptors.request.use(requestInterceptorCallback, function (error: AxiosError) {
  return Promise.reject(error)
})

createAuthRefreshInterceptor(storageInstance, refreshAccessToken, {})

export { storageInstance }

import { Box, Paper } from '@material-ui/core'
import NewNotificationForm from 'components/NewNotificationForm'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { NOTIFICATIONS_SENT_URL } from 'constants/routes'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { createNotification } from 'services/notifications'
import { INewNotificationInitialValues } from 'typescript/interfaces/notifications'

const NewNotificationContainer = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { push } = useHistory()
  const [createNewNotifMut, { isLoading }] = useMutation(createNotification, {
    onSuccess: () => {
      enqueueSnackbar('Notification successfully sent')
      push(NOTIFICATIONS_SENT_URL)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })
  const handleSubmit = useCallback(
    (values: INewNotificationInitialValues) => {
      createNewNotifMut(values)
    },
    [createNewNotifMut],
  )

  return (
    <Box component={Paper} p="3rem">
      <NewNotificationForm submit={handleSubmit} loading={isLoading} />
    </Box>
  )
}

export default NewNotificationContainer

import { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { useTableComponentStyle } from 'UI/Table/style'
import { convertDate, convertEmptyField, convertToPriceFormat } from 'utils/formatters'
import { ITodaysPaymentReportListItem } from 'typescript/interfaces/todaysPaymentReport'

export const TableTodaysPaymentReport = TableComponent as TableComponentType<ITodaysPaymentReportListItem>

export const useTodaysPaymentReport = () => {
  const classes = useTableComponentStyle({})
  const columns = useRef<Array<IColumn<ITodaysPaymentReportListItem>>>([
    {
      label: 'ID #',
      field: 'id',
      bodyCellClassName: classes.secondaryBodyCell,
      sorting: true,
      render: (data) => convertEmptyField(data.id),
    },
    {
      label: 'Deal Name',
      sorting: true,
      field: 'dealName',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.dealName),
    },
    {
      label: 'Trs Type',
      sorting: true,
      field: 'category',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.category),
    },
    {
      label: 'Status',
      sorting: true,
      field: 'status',
      bodyCellClassName: classes.accentCell,
      render: (data) => convertEmptyField(data.status),
    },
    {
      label: 'Payment Scheduled',
      field: 'scheduledDate',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertDate(data.scheduledDate),
    },
    {
      label: 'Payment Date',
      field: 'paymentDate',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertDate(data.paymentDate),
    },
    {
      label: 'Transaction Value',
      sorting: true,
      field: 'amount',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => (data.category.startsWith('PageTo') ? `(${convertToPriceFormat(data.amount)})` : convertToPriceFormat(data.amount)),
    },
  ])

  return {
    columns: columns.current,
  }
}

import { Box, IconButton, Paper, Typography } from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from 'assets/svg/ArrowLeftIcon.svg'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import { ReactComponent as ExcelIcon } from 'assets/svg/ExcelIcon.svg'
import { ReactComponent as DocumentOutlined } from 'assets/svg/DocumentOutlined.svg'
import Button from 'UI/Button/ButtonWithPreloader'
import { TablePortfolioSummary, usePortfolioSummaryTable } from 'hooks/usePortfolioSummary'
import { usePaginationList } from 'hooks/usePaginationList'
import PortfolioSummaryTableFooter from 'components/PortfolioSummaryTableFooter'
import { useMutation, useQuery } from 'react-query'
import { getPortfolioSummary, getPortfolioSummaryTotal } from 'services/syndicators'
import { IPortfolioSummaryItem } from 'typescript/interfaces/syndicators'
import { IPortfolioSummarySortFields } from 'typescript/enums/syndicators'
import { exportSyndicatorSummary } from 'services/export'
import { downloadFile } from 'utils/downloadFile'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { ExportType } from 'typescript/enums/export'
import CalculateRestHeightBlock from 'components/CalculateRestHeightBlock'

const PortfolioSummaryContainer: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { goBack } = useHistory()
  const additionalStyles = useAdditionalMaterialStyle()
  const { columns } = usePortfolioSummaryTable()
  const { handleFetchMore, handleSort, pagination, setPagination } = usePaginationList<IPortfolioSummaryItem>({
    initState: {
      data: [],
      search: '',
      order: IPortfolioSummarySortFields.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const { data } = useQuery(['totalPortfolioSummary'], getPortfolioSummaryTotal, {
    cacheTime: 0,
    retry: false,
  })

  const [getPortfolioSummaryMut, { isLoading }] = useMutation(getPortfolioSummary, {
    onSuccess: (resData) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...resData.data],
        hasMore: resData.data.length === 20,
      }))
    },
  })

  useEffect(() => {
    getPortfolioSummaryMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder])

  const [exportPortfolioMut, { isLoading: isLoadingExportExcel }] = useMutation(exportSyndicatorSummary, {
    onSuccess: (resData) => {
      downloadFile(resData)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  return (
    <Paper className={additionalStyles.wrapper}>
      <Box p="3rem" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box mr="1.5rem">
            <IconButton color="secondary" onClick={goBack} className={additionalStyles.navButton}>
              <ArrowLeft />
            </IconButton>
          </Box>
          <Box mb="-0.5rem">
            <Typography variant="h2" color="textSecondary">
              Portfolio Summary
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Button
            variant="text"
            color="secondary"
            startIcon={<ExcelIcon />}
            onClick={() =>
              exportPortfolioMut({
                ExportType: ExportType.excel,
              })
            }
            loading={isLoadingExportExcel}
          >
            Export to Excel
          </Button>
          <Button
            variant="text"
            color="secondary"
            startIcon={<DocumentOutlined />}
            onClick={() =>
              exportPortfolioMut({
                ExportType: ExportType.pdf,
              })
            }
            loading={isLoadingExportExcel}
          >
            Export to PDF
          </Button>
        </Box>
      </Box>
      <CalculateRestHeightBlock>
        <TablePortfolioSummary
          stickyHeader
          loading={isLoading}
          columns={columns}
          handleSortChange={handleSort}
          handleGetMore={handleFetchMore}
          data={pagination.data}
          hasMore={pagination.hasMore}
          Footer={() => <PortfolioSummaryTableFooter stats={data?.data} />}
        />
      </CalculateRestHeightBlock>
    </Paper>
  )
}

export default PortfolioSummaryContainer

import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import { NOTIFICATIONS_RECEIVED_URL } from 'constants/routes'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { INotification } from 'typescript/interfaces/notifications'
import NotificationsChatListItem from './NotificationsChatListItem'

interface Props {
  notifications: INotification[]
  selectedNotification: string
  hasMore: boolean
  onFetchMore: () => void
  loading: boolean
}

const useStyles = makeStyles((theme) => ({
  chatListContainer: {
    minWidth: 385,
    background: '#fff',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 250px)',
    minHeight: 'calc(100vh - 250px)',
    maxWidth: '30%',
    borderRight: `1px solid ${theme.palette.divider}`,
    // boxShadow: `0px 1px 3px #DBDFE7`
  },
}))

const NotificationsChatList = ({ notifications, selectedNotification, hasMore, onFetchMore, loading }: Props) => {
  const s = useStyles({})
  const { push } = useHistory()
  return (
    <Box className={s.chatListContainer}>
      {!notifications.length && loading && (
        <Box width="100%" height="200px" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {notifications.length === 0 && !loading ? (
        <Box p="3rem" textAlign="center">
          <Typography variant="h3">There are no notifications</Typography>
        </Box>
      ) : (
        <>
          {notifications.map((notif) => (
            <NotificationsChatListItem
              onClick={() => push(NOTIFICATIONS_RECEIVED_URL.replace(':id', `${notif.id}`))}
              key={notif.id}
              selected={selectedNotification === `${notif.id}`}
              notification={notif}
            />
          ))}
          {hasMore && (
            <Box p="3rem">
              <Button disabled={loading} onClick={() => onFetchMore()} variant="contained" color="primary" fullWidth>
                Load more
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default NotificationsChatList

import React, { FC, useMemo } from 'react'
import { useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import DatePicker from 'UI/DatePicker/DatePicker'
import SelectComponent from 'UI/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from 'UI/Button/ButtonWithPreloader'
import { TransactionsTypes, CategoriesAccordingToType } from 'typescript/enums/transactions'
import DealAutoComplete from 'components/DealAutoComplete'
import { ITransactionParamsInitValue, ITransactionsQueryParams } from 'typescript/interfaces/transactions'
import { transactionSchema } from 'components/AddTransactionForm/schema'
import { useParams } from 'react-router-dom'
import { IGetDealsLight } from 'typescript/interfaces/deals'
import { DealsStatus } from 'typescript/enums/deals'
import convertDateForPicker from 'utils/convertDateForPicker'

interface ITransactionForm {
  submit: (values: any) => void
  handleCancel: () => void
  dealId?: string
  loading?: boolean
  withdrawal?: boolean
  userPageId: string | number
}

const AddTransactionForm: FC<ITransactionForm> = ({ submit, handleCancel, loading, withdrawal, dealId, userPageId }) => {
  const { type, id } = useParams<ITransactionsQueryParams>()
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setValues } = useFormik<ITransactionParamsInitValue>({
    initialValues: {
      userPageId: userPageId || '',
      creationDate: '',
      amount: '',
      dealId: dealId || '',
      type: withdrawal ? 'PageToBank' : '',
      category: withdrawal ? 'Withdrawal' : '',
      comments: '',
      userId: '',
    },
    enableReinitialize: true,
    validationSchema: transactionSchema,
    onSubmit: ({ deal: _, ...vals }) => {
      // if (parseInt(vals.sourceBankAccount || '') === parseInt(vals.destinationBankAccount || ''))
      //   enqueueSnackbar('The selected user page has the same bank account')
      // else
      submit(vals)
    },
  })

  const paramsRequest: Partial<IGetDealsLight> = useMemo(() => {
    switch (type) {
      case 'syndicator': {
        return {
          SyndicatorId: id,
          Statuses: [DealsStatus.approved],
        }
      }
      case 'iso': {
        return {
          IsoId: id,
          Statuses: [DealsStatus.approved],
        }
      }
      case 'rep': {
        return {
          RepresentativeId: id,
          Statuses: [DealsStatus.approved],
        }
      }
      default: {
        return {
          Statuses: [DealsStatus.approved],
        }
      }
    }
  }, [type, id])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={9}>
        <Grid item xs={12}>
          <DatePicker
            disablePast={withdrawal}
            fullWidth
            name="creationDate"
            value={values.creationDate || null}
            label="Transaction Date"
            type="keyboard"
            onChange={(date) => {
              setFieldValue('creationDate', convertDateForPicker(date))
            }}
          />
        </Grid>
        {/* {!deal && ( */}
        <Grid item xs={12}>
          <SelectComponent
            disabled={withdrawal}
            value={values.type}
            name="type"
            error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}
            label="Transaction Type"
            onChange={(e) => {
              e.persist()
              setValues({
                ...values,
                category: '',
                type: e.target.value as keyof typeof TransactionsTypes | '',
                userPageId: userPageId,
              })
            }}
          >
            {Object.entries(TransactionsTypes).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </SelectComponent>
        </Grid>
        {/* )} */}
        <Grid item xs={12}>
          <SelectComponent
            disabled={withdrawal}
            label="Category"
            onChange={handleChange}
            value={values.category}
            name="category"
            error={Boolean(touched.category && errors.category)}
            helperText={touched.category && errors.category}
          >
            {values.type ? (
              Object.entries(CategoriesAccordingToType[values.type]).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))
            ) : (
              <MenuItem key="no-options" value="no" disabled>
                Select Transaction Type to see categories....
              </MenuItem>
            )}
          </SelectComponent>
        </Grid>
        {(values.type === 'DealToPage' || values.type === 'PageToDeal' || values.type === 'DealToFun') && (
          <Grid item xs={12}>
            <DealAutoComplete
              error={Boolean(touched.dealId && errors.dealId)}
              helperText={touched.dealId && errors.dealId}
              dealId={values.dealId}
              paramsRequest={{
                ...paramsRequest,
                Statuses: [
                  ...(paramsRequest.Statuses || []),
                  ...((values.type === 'DealToPage' && values.category === 'PsfFee' && [DealsStatus.outhouseApproved]) || []),
                ],
              }}
              onChange={(e, value) => {
                setFieldValue('dealId', value.id)
              }}
            />
          </Grid>
        )}
        {/*{values.type === 'DealToPage' && (*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <UserPageAutoComplete*/}
        {/*      userId={values.userId}*/}
        {/*      onChange={(e, value) => {*/}
        {/*        if (value) {*/}
        {/*          setFieldValue('userId', value.id)*/}
        {/*          setLoadingBankInfo(true)*/}
        {/*          switch (value.type) {*/}
        {/*            case 'iso': {*/}
        {/*              getIsoById(value.id).then((res) => {*/}
        {/*                setLoadingBankInfo(false)*/}
        {/*                setFieldValue('userPageId', res.data.userPage.id)*/}
        {/*              })*/}
        {/*              break*/}
        {/*            }*/}
        {/*            case 'vendor': {*/}
        {/*              getVendorById(value.id).then((res) => {*/}
        {/*                setLoadingBankInfo(false)*/}
        {/*                setFieldValue('userPageId', res.data.userPage.id)*/}
        {/*              })*/}
        {/*              break*/}
        {/*            }*/}
        {/*            case 'rep': {*/}
        {/*              getRepById(value.id).then((res) => {*/}
        {/*                setLoadingBankInfo(false)*/}
        {/*                setFieldValue('userPageId', res.data.userPage.id)*/}
        {/*              })*/}
        {/*              break*/}
        {/*            }*/}
        {/*            case 'syndicator': {*/}
        {/*              getSyndicator(value.id).then((res) => {*/}
        {/*                setLoadingBankInfo(false)*/}
        {/*                setFieldValue('userPageId', res.data.userPage.id)*/}
        {/*              })*/}
        {/*              break*/}
        {/*            }*/}
        {/*            default: {*/}
        {/*              return ''*/}
        {/*            }*/}
        {/*          }*/}
        {/*        }*/}
        {/*      }}*/}
        {/*      error={Boolean(touched.userPageId && errors.userPageId)}*/}
        {/*      helperText={touched.userPageId && (loadingBankInfo ? 'Loading bank info...' : errors.userPageId)}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*)}*/}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Amount"
            value={values.amount}
            name="amount"
            onChange={handleChange}
            error={Boolean(touched.amount && errors.amount)}
            helperText={touched.amount && errors.amount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            label="Comment"
            value={values.comments}
            name="comments"
            onChange={handleChange}
            error={Boolean(touched.comments && errors.comments)}
            helperText={touched.comments && errors.comments}
          />
        </Grid>
      </Grid>
      <Box mt="2rem" display="flex" justifyContent="flex-end" alignItems="center">
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
        <Box ml={5}>
          <Button loading={loading} type="submit" color="primary" variant="contained">
            Create
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default AddTransactionForm

import React, { useRef, useCallback } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import TableComponent, { TableComponentType } from 'UI/Table'
import { ClientDocument } from 'typescript/interfaces/clients'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as DeleteCircleIcon } from 'assets/svg/DeleteCircleIcon.svg'
import { useTableComponentStyle } from 'UI/Table/style'
import { IUploadedCategoriesKeys } from 'typescript/interfaces/deals'
import { convertDate } from 'utils/formatters'
import { Link } from 'react-router-dom'

export const TableClientDocuments = TableComponent as TableComponentType<ClientDocument>

interface IClientDocumentHookProps {
  deleteFile?: (id: number, name: any) => void
  downloadFile?: (url: string, name: string) => void
  readOnly: boolean
}

export const useClientDocuments = (props: IClientDocumentHookProps) => {
  const classes = useTableComponentStyle()

  const getDescription = useCallback((type: IUploadedCategoriesKeys) => {
    switch (type) {
      case 'Application': {
        return 'Application'
      }
      case 'BankStatements': {
        return 'Bank Statements'
      }
      case 'CreditProcessingStatements': {
        return 'Credit Processing Statements'
      }
      case 'DriverLicense': {
        return 'Driver License'
      }
      case 'Optional': {
        return 'Optional Document'
      }
      case 'VoidedCheck': {
        return 'Voided Check'
      }
      case 'W9': {
        return 'W9'
      }
      default: {
        return ''
      }
    }
  }, [])

  const columns = useRef<Array<IColumn<ClientDocument>>>([
    {
      field: 'name',
      label: 'Name',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => {
        return (
          <Link
            to={data.documentUrl}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              props && props.downloadFile && props.downloadFile(data.documentUrl, data.description)
            }}
          >
            {data.description}
          </Link>
        )
      },
    },
    {
      field: 'description',
      label: 'Description',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => getDescription(data.documentType),
    },
    {
      field: 'uploadDate',
      label: 'Upload Date',
      sorting: true,
      bodyCellClassName: classes.accentCell,
      render: (data) => convertDate(data.createdDate),
    },
    ...(!props.readOnly
      ? [
          {
            field: 'actions',
            label: 'Delete',
            render: (data: ClientDocument) => {
              return (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (props && props.deleteFile) props.deleteFile(data.id, data.documentType)
                  }}
                >
                  <DeleteCircleIcon />
                </IconButton>
              )
            },
          },
        ]
      : []),
  ])
  return {
    columns: columns.current,
  }
}

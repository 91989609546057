import React, { FC, useCallback, useMemo, useState } from 'react'
import { usePopoverStyles } from 'containers/Deal/DealInfo/style'
import { Box, IconButton, MenuItem, Popover, PopoverProps, Typography } from '@material-ui/core'
import { DealsStatus, DealsType } from 'typescript/enums/deals'
import { DEAL_REVERSE_SCHEDULE_PERM } from 'constants/permissions'
import { IInhouseDealResponse, ICreateReverseSchedule } from 'typescript/interfaces/deals'
import { usePermission } from 'hooks/usePermission'
import cn from 'classnames'
import { ReactComponent as CancelIcon } from 'assets/svg/CloseIcon.svg'
import ModalComponent from 'UI/Modal'
import { useMutation, useQueryCache } from 'react-query'
import { createReverseSchedule, deleteAllReverseSchedules, getReverseSchedulesByDeal } from 'services/deals'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import ReverseScheduleForm from 'components/ReverseScheduleForm'
import { AxiosError } from 'axios'

interface Props extends PopoverProps {
  deal?: IInhouseDealResponse
}

const ReverseSchedulePopover: FC<Props> = ({ anchorEl, open, onClose, deal, ...rest }) => {
  const classes = useAdditionalMaterialStyle()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<{ id: string }>()
  const queryCache = useQueryCache()
  const { hasPermission } = usePermission()
  const s = usePopoverStyles()
  const [openSchedule, setOpenSchedule] = useState(false)
  const [openDeleteReverseSchedule, setOpenDeleteReverseSchedule] = useState(false)

  const handleClosePopover = useCallback(() => {
    // @ts-ignore
    onClose(new Event('click'), 'backdropClick')
  }, [])

  const disableReverseSchedule = useMemo(() => {
    return deal?.status !== DealsStatus.approved || deal?.type === DealsType.outhouse || !hasPermission(DEAL_REVERSE_SCHEDULE_PERM)
  }, [deal])

  const [createReverseScheduleMut, { isLoading }] = useMutation(createReverseSchedule, {
    onSuccess: () => {
      queryCache.fetchQuery(['reverseSchedulesDeal', id], () => getReverseSchedulesByDeal(id), {
        retry: false,
        cacheTime: 0,
        enabled: id,
      })
      enqueueSnackbar('Disbursement schedule successfully created')
      setOpenSchedule(false)
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'INVALID_REVERSE_AMOUNT') {
        enqueueSnackbar('Reverse amount is more than remain to extend')
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [deleteAllReverseSchedulesMut, { isLoading: isLoadingDeleteAllSchedules }] = useMutation(deleteAllReverseSchedules, {
    onSuccess: () => {
      queryCache.fetchQuery(['reverseSchedulesDeal', id], () => getReverseSchedulesByDeal(id), {
        retry: false,
        cacheTime: 0,
        enabled: id,
      })
      setOpenDeleteReverseSchedule(false)
      enqueueSnackbar('Disbursement schedules successfully deleted')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleSubmit = useCallback(
    (values: ICreateReverseSchedule) => {
      createReverseScheduleMut(values)
    },
    [createReverseScheduleMut],
  )

  return (
    <>
      <Popover
        classes={{ paper: s.popover }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...rest}
      >
        <MenuItem
          className={s.menuItem}
          disabled={disableReverseSchedule}
          onClick={() => {
            setOpenSchedule(true)
            handleClosePopover()
          }}
        >
          <Typography variant="h4" color="textSecondary">
            New Schedule
          </Typography>
        </MenuItem>
        <MenuItem
          className={s.menuItem}
          disabled={disableReverseSchedule}
          onClick={() => {
            setOpenDeleteReverseSchedule(true)
            handleClosePopover()
          }}
        >
          <Typography variant="h4" color="textSecondary">
            Delete all Schedules
          </Typography>
        </MenuItem>
      </Popover>
      <ModalComponent open={openSchedule}>
        <Box maxWidth={500}>
          <Box display="flex" justifyContent="flex-end" mt="-1rem" mr="-1rem">
            <IconButton
              color="secondary"
              size="small"
              onClick={() => setOpenSchedule(false)}
              classes={{
                root: cn(classes.closeTrsModalButton),
              }}
            >
              <CancelIcon />
            </IconButton>
          </Box>
          <Typography variant="h2" color="textSecondary">
            Schedule Disbursement - Deal #{id}
          </Typography>
          {deal?.paymentInfo?.disbursementFrequency && (
            <Box mt="2rem">
              <ReverseScheduleForm
                dealId={id}
                loading={isLoading}
                disbursementFrequency={deal.paymentInfo.disbursementFrequency}
                cancel={() => setOpenSchedule(false)}
                submit={handleSubmit}
              />
            </Box>
          )}
        </Box>
      </ModalComponent>
      <ModalComponent open={openDeleteReverseSchedule}>
        <ConfirmationContent
          isLoading={isLoadingDeleteAllSchedules}
          handleCancel={() => setOpenDeleteReverseSchedule(false)}
          handleConfirm={() => deleteAllReverseSchedulesMut(id)}
          text="Are you sure you want to delete all disbursement schedules?"
        />
      </ModalComponent>
    </>
  )
}

export default ReverseSchedulePopover

import React, { FC, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  getClientBankAccounts,
  createClientBankAccount,
  editClientBankAccount,
  deleteClientBankAccount,
  setMainClientBankAccount,
} from 'services/clients'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import Paper from '@material-ui/core/Paper/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useClientBankAccounts, TableClientBankAccounts } from 'hooks/useClientBankAccounts'
import ModalComponent from 'UI/Modal'
import AddClientBankAccountForm from 'components/AddClientBankAccount'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { AxiosError } from 'axios'

interface IClientBankAccountsProps {
  readOnly: boolean
}

const ClientBankAccounts: FC<IClientBankAccountsProps> = ({ readOnly }) => {
  const queryCache = useQueryCache()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteBankAccModal, setDeleteBankAccModal] = useState({
    id: 0,
    open: false,
  })
  const [bankAccountModal, setBankAccountModal] = useState<{
    id: number | undefined
    open: boolean
  }>({
    id: undefined,
    open: false,
  })

  const { id } = useParams<{ id: string }>()
  const { data, isLoading, refetch } = useQuery(
    ['client-bank-accounts', id],
    () =>
      getClientBankAccounts({
        clientId: id,
      }),
    {
      retry: false,
      cacheTime: 0,
    },
  )

  const [createClientBankAccountMut, { isLoading: isLoadingCreateClientBankAccount }] = useMutation(createClientBankAccount, {
    onSuccess: () => {
      setBankAccountModal({
        id: undefined,
        open: false,
      })
      enqueueSnackbar('Bank account successfully added')
      refetch()
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'BANK_ACCOUNT_USED') {
        enqueueSnackbar('Current Bank Account already exists')
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [editClientBankAccountMut, { isLoading: isLoadingEditClientBankAccount }] = useMutation(editClientBankAccount, {
    onSuccess: () => {
      setBankAccountModal({
        id: undefined,
        open: false,
      })
      enqueueSnackbar('Bank account successfully edited')
      refetch()
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'BANK_ACCOUNT_USED_IN_DEALS') {
        enqueueSnackbar('The bank account is already used in the deal')
      } else if (err.response && err.response.data.description === 'BANK_ACCOUNT_HAS_TRANSACTIONS') {
        enqueueSnackbar('The bank account has created transactions')
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [deleteClientBankAccountMut, { isLoading: isLoadingDeleteClientBankAccount }] = useMutation(deleteClientBankAccount, {
    onSuccess: () => {
      setDeleteBankAccModal({
        open: false,
        id: 0,
      })
      enqueueSnackbar('Bank account successfully edited')
      refetch()
    },
    onError: (err: AxiosError) => {
      if (err.response && err.response.data.description === 'BANK_ACCOUNT_USED') {
        enqueueSnackbar('Current Bank Account already exists')
        // enqueueSnackbar('The bank account is already used in the deal')
      } else if (err.response && err.response.data.description === 'BANK_ACCOUNT_USED_IN_DEALS') {
        enqueueSnackbar('The bank account is already used in the deal')
      } else if (err.response && err.response.data.description === 'BANK_ACCOUNT_HAS_TRANSACTIONS') {
        enqueueSnackbar('The bank account has created transactions')
      } else if (err.response && err.response.data.description === 'BANK_ACCOUNT_IS_MAIN') {
        enqueueSnackbar("Main bank account can't be deleted")
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [setMainBankAccountMut] = useMutation(setMainClientBankAccount, {
    onSuccess: (_, variables) => {
      queryCache.setQueryData(['client-bank-accounts', id], {
        ...data,
        data: data?.data.map((bankAcc) => ({
          ...bankAcc,
          isMain: bankAcc.bankAccount.id === +variables.bankAccountId,
        })),
      })
    },
    onError: () => {
      refetch()
    },
  })

  const { columns } = useClientBankAccounts({
    handleDelete: (idBankAcc) =>
      setDeleteBankAccModal({
        id: idBankAcc,
        open: true,
      }),
    handleEdit: (idBankAcc) =>
      setBankAccountModal({
        id: idBankAcc,
        open: true,
      }),
    handleSetMain: (idBankAcc = 0) => {
      setMainBankAccountMut({
        clientId: id,
        bankAccountId: idBankAcc,
      })
    },
    readOnly,
  })

  const initValues = useMemo(() => {
    if (data && bankAccountModal.id) return data.data.find((ba) => ba.bankAccount.id === bankAccountModal.id)
    return undefined
  }, [data, bankAccountModal.id])

  return (
    <Paper elevation={0}>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box px={12} py={8} pt={13}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h3" color="textSecondary">
                Bank Accounts
              </Typography>
              {!readOnly && (
                <Box>
                  <Button
                    onClick={() => {
                      setBankAccountModal({
                        open: true,
                        id: undefined,
                      })
                    }}
                    startIcon={<PlusIcon />}
                    variant="contained"
                    color="primary"
                  >
                    Add Bank Account
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          {columns && columns.length > 0 && (
            <Box>
              <TableClientBankAccounts columns={columns} data={data ? data.data : []} />
            </Box>
          )}
          <ModalComponent open={bankAccountModal.open}>
            <AddClientBankAccountForm
              disableBalance={Boolean(bankAccountModal.id)}
              initValues={initValues}
              isLoading={isLoadingCreateClientBankAccount || isLoadingEditClientBankAccount}
              clientId={id}
              submit={(values) => {
                if (bankAccountModal.id) editClientBankAccountMut(values)
                else createClientBankAccountMut(values)
              }}
              cancel={() =>
                setBankAccountModal({
                  id: undefined,
                  open: false,
                })
              }
            />
          </ModalComponent>
          <ModalComponent open={deleteBankAccModal.open}>
            <ConfirmationContent
              isLoading={isLoadingDeleteClientBankAccount}
              text="Are you sure you want to delete bank account?"
              handleCancel={() =>
                setDeleteBankAccModal({
                  open: false,
                  id: 0,
                })
              }
              handleConfirm={() =>
                deleteClientBankAccountMut({
                  clientId: id,
                  bankAccountId: deleteBankAccModal.id,
                })
              }
            />
          </ModalComponent>
        </>
      )}
    </Paper>
  )
}

export default ClientBankAccounts

export enum TinType {
  SSN = 'SSN',
  Passport = 'Passport',
  Feein = 'FEIN / EIN',
  NraBusiness = 'NRA Business',
  NraPersonal = 'NRA Personal',
  LeNumber = 'LE Number',
  Rfc = 'RFC',
  FederalId = 'Federal ID',
  RegistrationId = 'Registration ID',
  Curp = 'C.U.R.P',
  PublicSecurityImmigrationNumber = 'Public Security & Immigration Number',
  Nie = 'N.I.E.',
  ElectoralNumber = 'Electoral Number',
  IdentificationNumber = 'Identification Number',
  RucNumber = 'R.U.C. Number',
  CedulaNumber = 'Cedula Number',
  ForeignIdNumber = 'Foreign ID Number',
  SerialNumber = 'Serial Number',
  NitNumber = 'N.I.T. Number',
  DiplomaticPassport = 'Diplomatic Passport',
  TravelDocumentNumber = 'Travel Document Number',
  NationalIdNumber = 'National ID Number',
  Dni = 'D.N.I',
  Cin = 'C.I.N.',
  Sin = 'SIN',
  StateTaxId = 'State Tax ID',
  IndividualTaxIdentificationNumber = 'Individual Tax Identification Number',
  NationalInsuranceNumber = 'National Insurance Number (NIN)',
}

export enum Directions {
  E = 'East',
  N = 'North',
  S = 'South',
  W = 'West',
  NE = 'Northeast',
  NW = 'Northwest',
  SE = 'Southeast',
  SW = 'Southwest',
}

export enum ContactBy {
  Both = 'BOTH (sends via SMS & EMAIL)',
  Sms = 'SMS',
  Email = 'EMAIL',
  Neither = 'NEITHER',
}

export enum PayCycle {
  EveryWeek = 'Every week',
  EveryOtherWeek = 'Every other week',
  TwoTimesAMonth = 'Two times a month',
  OnceAMonth = 'Once a month',
}

export enum AccountTypes {
  Checking = 'Checking',
  Savings = 'Savings',
  Credit_Card = 'Credit Card',
  Mortgage = 'Mortgage',
}

export enum StreetType {
  FG = 'Forge',
  FK = 'Fork',
  FT = 'Fort',
  FY = 'Freeway',
  GA = 'Garden',
  GW = 'Gateway',
  GL = 'Glen',
  GN = 'Green',
  GR = 'Grove',
  HB = 'Harbor',
  HV = 'Haven',
  HT = 'Heights',
  HY = 'Highway',
  HL = 'Hill',
  HW = 'Hollow',
  IN = 'Inlet',
  IS = 'Island',
  IL = 'Isle',
  JC = 'Junction',
  KY = 'Key',
  KN = 'Knoll',
  LK = 'Lake',
  LD = 'Land or Lodge',
  LG = 'Landing',
  LN = 'Lane',
  LT = 'Light',
  LF = 'Loaf',
  LO = 'Lock',
  LP = 'Loop',
  MA = 'Mall',
  MR = 'Manor',
  MD = 'Meadow',
  ME = 'Mews',
  ML = 'Mill',
  MS = 'Mission',
  MN = 'Montee or Mountain',
  MW = 'Motorway',
  MT = 'Mount',
  NK = 'Neck',
  OR = 'Orchard',
  OV = 'Oval',
  OP = 'Overpass',
  PK = 'Park',
  PY = 'Parkway',
  PS = 'Pass',
  PG = 'Passage',
  PA = 'Path',
  PI = 'Pike',
  PE = 'Pine',
  PL = 'Place',
  PN = 'Plain',
  PZ = 'Plaza',
  PT = 'Point',
  PO = 'Port',
  PR = 'Prairie',
  RA = 'Radial',
  RM = 'Ramp',
  RC = 'Ranch',
  RG = 'Range',
  RP = 'Rapids',
  RS = 'Rest',
  RI = 'Ridge',
  RV = 'River',
  RD = 'Road',
  RT = 'Route',
  RO = 'Row',
  RU = 'Rue',
  RL = 'Ruelle',
  RN = 'Run',
  SH = 'Shoal',
  SR = 'Shore',
  SK = 'Skyway',
  SP = 'Spring',
  SG = 'Spur',
  SQ = 'Square',
  SA = 'Station',
  SV = 'Stravenue',
  SM = 'Stream',
  ST = 'Street',
  SU = 'Summit',
  TE = 'Terrace',
  TW = 'Throughway',
  TC = 'Trace',
  TK = 'Track',
  TF = 'Trafficway',
  TR = 'Trail',
  TL = 'Trailer',
  TU = 'Tunnel',
  TP = 'Turnpike',
  UP = 'Underpass',
  UN = 'Union',
  VA = 'Valley',
  VI = 'Viaduct',
  VW = 'View',
  VG = 'Village',
  VL = 'Ville',
  VS = 'Vista',
  WK = 'Walk',
  WA = 'Wall',
  WY = 'Way',
  WL = 'Well',
}

import { IIsoLightItem, IIsoListItemLight } from 'typescript/interfaces/iso'
import { IRepLight } from 'typescript/interfaces/rep'
import { ExportType } from 'typescript/enums/export'
import { IPagination } from 'typescript/interfaces/pagination'
import { SortFieldsDeal, DealsType, DealsStatus, RepPortfolioSummarySortFields } from 'typescript/enums/deals'
import { IClientEntity, IClientListItemLight } from 'typescript/interfaces/clients'
import { IVendorListLightItem } from 'typescript/interfaces/vendor'
import { PaymentFrequencyEnum, IPaymentFrequency } from './paymentFrequency'
import { CompanyType, CompanyLight, IBankAccount } from './companies'

export interface ICreateUpdateOuthouseDeal {
  id?: number
  clientId?: number
  lendersIds?: number[]
  outhouseLenders?: number[]
  isoId?: number
  paymentFrequency?: IPaymentFrequency
  advanceAmount?: number
  earlyPayOffAmount?: number
  paybackAmount?: number
  frequentPayment?: number
  commissionToRep?: number
  isoFee?: number
  commissionSplit?: number
  psfFee?: number
  psfOuthouseProcess?: boolean
  comments?: string
  companyType?: CompanyType
  term?: number | null
  factor?: number | null
  representativeIds?: number[]
}

export interface ICreateUpdateOuthouseDealResponse {
  id?: number
  clientId?: number
  companyId?: number
  isoId?: number
  paymentFrequency?: IPaymentFrequency
  advanceAmount?: number
  paybackAmount?: number
  frequentPayment?: number
  commissionToRep?: number
  isoFee?: number
  commissionSplit?: number
  psfFee?: number
  psfOuthouseProcess?: boolean
  comments?: string
  earlyPayOffAmount?: number
  representatives: IRepLight[]
}

interface State {
  id?: number
  name?: string
}

export interface BankInfo {
  id: number
  name?: string
  bankAccount?: string
  bankRouting?: string
  bankCompanyId?: string
  bankAccountType?: 'Checking'
  nameOnCheck?: string
  sendAsWire?: boolean
  maxSyndicatedPercentage?: number
}
interface Company {
  id?: number
  name?: string
  description?: string
  city?: string
  street?: string
  zipCode?: string
  federalTaxId?: string
  firstName?: string
  lastName?: string
  phone?: string
  mobile?: string
  email?: string
  companyType?: 'InHouse' | 'OutHouse'
  state?: State
  bankInformation?: BankInfo
}

export enum FeeStatusEnum {
  active = 'Active',
  stopped = 'Stopped',
}

export interface IEarlyDiscount {
  thirtyDays?: number
  sixtyDays?: number
  ninetyDays?: number
  oneHundredTwentyDays?: number
}

export const earlyDiscountDescription = {
  thirtyDays: 'Thirty (30) Days Option',
  sixtyDays: 'Sixty (60) Days Option',
  ninetyDays: 'Ninety (90) Days Option',
  oneHundredTwentyDays: 'One Hundred Twenty (120) Days',
}

export interface IFlexDisbursement {
  id: number
  date?: string
  amount?: number
}

interface PaymentInfo {
  paymentFrequency?: 'Daily' | 'Biweekly' | 'Weekly' | 'Monthly'
  disbursementFrequency?: PaymentFrequencyEnum
  syndicationFee?: number
  psfFee?: number
  commissionSplit?: number
  defaultFee?: number
  legalFee?: number
  commissionToRep?: number
  monthlyCrmFee?: number
  totalCommission?: number
  isoFee?: number
  frequentPayment?: number
  payOffAmount?: number
  payback?: number
  earlyPayOffAmount?: number
  earlyDiscount?: IEarlyDiscount
  specifiedPercentage?: number
  apr?: number
  monthlyRevenue?: number
  clientProgramFeePaymentsStatus?: FeeStatusEnum
  repProgramFeePaymentsStatus?: FeeStatusEnum
  syndicatorsProgramFeePaymentsStatus?: FeeStatusEnum
}

export interface IInhouseDealResponse {
  mainDealId?: number
  id?: number
  dealName?: string
  businessName?: string
  advanceAmount?: number
  payback?: number
  frequentPayment?: number
  factor?: number
  term?: number
  reverseTerm?: number
  net?: number
  collected?: number
  paidOff?: number
  remaining?: number
  company?: Company
  pnl?: number
  funder?: string
  label?: string
  representative?: { id?: number; name?: string }
  representatives?: IRepLight[]
  repSplitCommission?: [{ id: number; value: number }]
  psfFeeSplitCommission?: [{ id: number; value: number }]
  isos: IIsoLightItem[]
  client?: IClientEntity
  lenders?: Array<ILenderEntity>
  outhouseLenders?: Array<IOuthouseLender>
  documents: IDealDocumentConnectParamsResponse[]
  type?: 'InHouse' | 'OutHouse'
  bankAccount: IBankAccount
  nameOnBankAccount: string
  nameOnCheck: string
  sendAsWire: boolean
  paymentInfo: PaymentInfo
  createdDate?: string
  updatedDate?: string
  clientId?: number
  uccUploaded?: boolean
  startDate?: string
  plannedEndDate?: string
  status: DealsStatus
  repClawbackSent: boolean
  isoClawbackSent: boolean
  paidStatus: DealPaidStatus
  comments?: string
  vendors?: IVendorListLightItem[]
  unallocated?: number
  isSubmitted?: boolean
  flexAmount?: number
  extendType?: IExtendType
}

export interface ICreateUpdateInhouseDeal {
  id?: number
  clientId?: number
  companyId?: number
  paymentFrequency?: IPaymentFrequency | ''
  disbursementFrequency?: PaymentFrequencyEnum
  advanceAmount?: number
  term?: number
  reverseTerm?: number
  uccUploaded?: boolean
  factor?: number
  frequentPayment?: number
  paybackAmount?: number
  isoIds?: number[]

  commissionSplit?: number
  repSplitCommission?: [{ id: number; value: number }]
  isoSplitCommission: Array<{ id: number; value: number }>
  psfFeeSplitCommission?: [{ id: number; value: number }]
  representativeIds?: number[]

  psfFee?: number
  syndicationFee?: number
  payOffAmount?: number
  earlyPayOffAmount?: number
  earlyDiscount?: IEarlyDiscount
  isoFee?: number
  commissionToRep?: number

  psfOuthouseProcess?: boolean
  comments?: string
  companyType?: CompanyType
  sendAsWire?: boolean
  bankAccountId?: string | number
  vendorIds?: number[]

  flexAmount?: number
  flexSchedule?: IFlexDisbursement[]
  isFlexDeal: boolean
  collected?: number
  extendType?: IExtendType

  specifiedPercentage?: number
  apr?: number
  monthlyRevenue?: number
}

export type IExtendType = 'Flex' | 'Reverse'

export const extendTypes: IExtendType[] = ['Flex', 'Reverse']

export type DealPaidStatus = 'Current' | 'FullyPaid' | 'InCollection' | 'Default' | 'ProcessingRenewal'

export interface IDealsListItem {
  id: number
  dealName: string
  businessName: string
  advanceAmount: number
  funding: number
  payback: number
  frequentPayment: number
  paymentFrequency: PaymentFrequencyEnum
  factor: number
  term: number
  net: number
  commission: number
  legalFee: number
  syndicationFee: number
  defaultFee: number
  psfFee: number
  collected: number
  paidOff: number
  remaining: number
  pnl: number
  funder: string
  label: string
  representatives: IRepLight[]
  isos: IIsoListItemLight[]
  company: CompanyLight
  status: DealsStatus
  type?: 'InHouse' | 'OutHouse'
  paidStatus: DealPaidStatus
  createdDate: string
  approvedDate: string
  unallocated: number
  totalCommission: number
  isFlexDeal: boolean
}

export interface IDealsStats {
  dealsCount: number
  advanced: number
  payback: number
  daily: number
  weekly: number
  termAvg: number
  factorAvg: number
  net: number
  collected: number
  paidOffAvg: number
  remaining: number
  pnl: number
}

export interface IAdditionalDealsStatistic {
  Status?: DealsStatus
  RepresentativeId?: string
  IsoId?: string
  From?: number | string
  To?: number | string
  Label?: string
  ClientId?: string
  SyndicatorId?: string
}

export interface AdditionalPaginationProps {
  Statuses?: Array<DealsStatus>
  PaidStatuses?: Array<DealPaidStatus>
  RepresentativeId?: string
  IsoId?: string
  From?: number | string
  To?: number | string
  Label?: string
  ClientId?: string
  SyndicatorId?: string
  FromDate?: string
  ToDate?: string
}

export interface IQueItem {
  statusName: string
  dealsSum: number
  dealsCount: number
}

export interface IEditStatusParam {
  id: number
  status: string
}

export interface IDealsExportParams {
  ExportType: ExportType
  DealStatuses: Array<DealsStatus>
}

export interface ILenderEntity {
  id: number
  lenderId: number
  syndicatorId: number
  businessName: string
  firstName: string
  lastName: string
  lendingAmount: number
  lendingPercentage: number
  paybackAmount: number
  isLead: boolean
  assigningDate: string
  availableBalance: number
}

export interface IOuthouseLender {
  id: number
  businessName: string
}

export interface ILenderParams {
  lenderId: number | string
  dealId: number | string
  syndicatorId: number | string
  lendingPercentage: number | string
  lendingAmount: number | string
  isLead: boolean
}

export interface IGetDealsLight extends IPagination<SortFieldsDeal> {
  Statuses?: Array<DealsStatus>
  PaidStatuses?: Array<DealPaidStatus>
  ClientId?: string | number
  Type?: DealsType
  RepresentativeId?: number | string
  IsoId?: number | string
  SyndicatorId?: number | string
}

export interface ILightDeal {
  id: number
  name: string
  date: string
}

export interface IDealDocumentUploadLinkParams {
  DocumentName: string
  DocumentDestination: 'Deal'
  DestinationObjectId: number
}
// export type IDocumentType = 'VoidedCheck' | 'Application' | 'DriverLicense' | 'BankStatements' | 'CreditProcessingStatements'

export interface IDealDocumentConnectParams {
  documentUrl: string
  description: string
  dealId: number
  documentType: IUploadedCategoriesKeys
}
export interface IDealDocumentConnectParamsResponse {
  id: number
  documentUrl: string
  description: string
  createdDate: string
  documentType: IUploadedCategoriesKeys
}

export interface IClientDocumentConnectParamsResponse {
  id: number
  documentUrl: string
  description: string
  createdDate: string
  documentType: IUploadedClientCategoriesKeys
}

export interface IUploadDocWithDocType {
  file: File
  documentType: IUploadedCategoriesKeys
}

export interface IDocuments {
  VoidedCheck: File[]
  Application: File[]
  DriverLicense: File[]
  BankStatements: File[]
  CreditProcessingStatements: File[]
  W9: File[]
  Optional: File[]
  Contract: File[]
}

export interface IClientDocuments {
  VoidedCheck: File[]
  Application: File[]
  DriverLicense: File[]
  BankStatements: File[]
  CreditProcessingStatements: File[]
  W9: File[]
  Optional: File[]
}

export interface IUploadedDocuments {
  VoidedCheck: IDealDocumentConnectParamsResponse[]
  Application: IDealDocumentConnectParamsResponse[]
  DriverLicense: IDealDocumentConnectParamsResponse[]
  BankStatements: IDealDocumentConnectParamsResponse[]
  CreditProcessingStatements: IDealDocumentConnectParamsResponse[]
  W9: IDealDocumentConnectParamsResponse[]
  Optional: IDealDocumentConnectParamsResponse[]
  Contract: IDealDocumentConnectParamsResponse[]
}

export interface IUploadedClientDocuments {
  VoidedCheck: IClientDocumentConnectParamsResponse[]
  Application: IClientDocumentConnectParamsResponse[]
  DriverLicense: IClientDocumentConnectParamsResponse[]
  BankStatements: IClientDocumentConnectParamsResponse[]
  CreditProcessingStatements: IClientDocumentConnectParamsResponse[]
  W9: IClientDocumentConnectParamsResponse[]
  Optional: IClientDocumentConnectParamsResponse[]
}

export type IUploadedCategoriesKeys = keyof IUploadedDocuments
export type IUploadedClientCategoriesKeys = keyof IUploadedClientDocuments

export interface IMerchantClient {
  id: number
  businessName?: string
  contactName?: string
  phone?: string
  amountOwing?: number
}

export interface IMerchantClientsResponse {
  client: IClientListItemLight
  merchantClients: IMerchantClient[]
}

export interface IMerchantClientsUpdate {
  dealId: number
  merchantClients: IMerchantClient[]
}

export interface IDealsFilters {
  repId?: number | number[]
  isoId?: number | number[]
  labelId?: number
  clientId?: number | number[]
  from?: number | string
  to?: number | string
  paidStatuses?: Array<DealPaidStatus>
  Statuses?: Array<DealsStatus>
  search?: undefined
}

export interface ISchedulePaymentInitValues {
  dealId: string
  startDate: string
  endDate: string
  paymentFrequency: PaymentFrequencyEnum | ''
  frequentPayment: string
  bankAccount?: string
  paymentsCount: string
}

export interface ISchedule {
  dealId: number
  endDate: string
  frequentPayment: 111
  id: 6
  isMain: false
  nextPaymentDate: string
  paymentFrequency: PaymentFrequencyEnum
  startDate: string
  status: 'Active' | 'Draft'
  term: 1
}

export interface IStatisticWithChartsResponse {
  frequent: number
  factor: number
  paidOff: number
  pnl: number
  data: IStatisticForCharts[]
  tomorrowFrequent: number
  todayFrequent: number
}

export interface IStatisticForCharts {
  date: string
  frequent: number
  factor: number
  paidOff: number
  pnl: number
}

export interface IAuditLogListItem {
  date: string
  createdBy: string
  dealId: number
  historicalDatas: IHistoricalDataItem[]
}

export interface IHistoricalDataItem {
  fieldName: string
  oldValue: string
  newValue: string
  fieldType: string
}

export interface IDealBaseValues {
  id?: number
  advanceAmount?: number
  term?: number
  type: 'InHouse'
  bankAccountId?: string | number
}
export interface INewPaymentFormValues {
  scheduledFor?: string
  amount?: number
  bankAccount?: number
  paymentStatus?: string
  transactionRef?: number
  transactionType?: string
  paymentDate?: string
  userPages?: string
  comment?: string
  externalWire?: boolean
}

export interface ICommentLogItem {
  createdBy: string
  dateTime: string
  text: string
}

export interface ICreateCommentLog {
  text: string
  dealId: number
}

export interface IRepPortfolioSummaryParams extends IPagination<RepPortfolioSummarySortFields> {
  Year: string
}

export interface IRepPortfolioSummaryRecord {
  merchant: string
  dealId: number
  company: string
  startDate: string
  endDate: string
  iso: string
  status: DealPaidStatus
  factor: number
  frequentPayment: number
  advanceAmount: number
  isoFee: number
  paybackAmount: number
  paidOff: number
  commissionToRep: number
  psf: number
  remainPayback: number
  totalPaid: number
}

export interface IRepPortfolioSummaryResponse {
  totalAdvanceAmount: number
  totalIsoFee: number
  totalPayback: number
  totalPaid: number
  totalRemainPayback: number
  totalCommission: number
  totalPsf: number
  records: IRepPortfolioSummaryRecord[]
}

export interface ISetToDefaultFormInitValues {
  vendorIds?: number[] | string[]
  defaultFee: string | number
}

export interface IExtendDealFormInitValues {
  status?: string
  paybackAmount?: number
  remaining?: number
  net?: number
  type: string
  amount: number | string
}

export interface ISelectRefiDealsFormInitValues {
  selectedDeals: ILightDeal[]
  dealsIds: Record<string, boolean>
}

export interface ISetToDefaultParams extends ISetToDefaultFormInitValues {
  dealId: string | number
}

export interface IRefundFormInitValues {
  amount: string | number
}

export interface IRefundDealParams extends IRefundFormInitValues {
  id: string | number
}

export interface IExtendDealParams extends IExtendDealFormInitValues {
  id: string | number
}

export interface IDealPayHistoryParams {
  dealId: string | number
}

export interface IExportDealPaymentsParams {
  dealId: string | number
}

export enum StatusSchedule {
  active = 'Active',
  finished = 'Finished',
}

export interface IScheduleListItem {
  id: number
  startDate: string
  nextPaymentDate: string
  endDate: string
  term: number
  paymentFrequency: PaymentFrequencyEnum
  frequentPayment: number
  clientToSyndicatorsPaymentsStatus: FeeStatusEnum
  isMain: boolean
  status: StatusSchedule
}

export interface IReverseScheduleListItem {
  id: number
  reverseAmount: number
  startDate: string
  nextPaymentDate: string
  endDate: string
  status: StatusSchedule
  term: number
  dealId: string
}

export interface ICreateReverseSchedule {
  dealId: string
  startDate?: string
  term?: number
  reverseAmount?: number
}

export interface IPaymentScheduleCalculationParams {
  startDate?: string
  frequency: PaymentFrequencyEnum
  term?: number
}

export interface IDealPaymentFormValues {
  scheduledFor?: string
  paymentDate?: string | null
  amount?: number
  bankAccountId?: number | string
  category?: string
  comments?: string
  status?: string
  achProvider?: string
  vendorId?: string | null
}

export interface IDealPaymentParams extends IDealPaymentFormValues {
  dealId: number | string
}

export interface IFunderLenderItem {
  id: number
  syndicatorId: number
  businessName: string
  assigningDate: string
  firstName: string
  lastName: string
  lendingAmount: number
  lendingPercentage: number
  paybackAmount: number
  availableBalance: number
  isLead: boolean
  limit: number
  isConnectedToFunder: boolean
  isFunderSyndicator: boolean
  isAttachedToDeal: boolean
  isHouseSyndicator: boolean
}

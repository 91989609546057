export const BASE_APP_URL: string = '/'
export const DASHBOARD_URL: string = '/dashboard'
export const USERS_URL: string = '/users'
export const PAYMENTS_URL: string = '/payments'
export const DASHBOARD_VIEW_URL: string = '/dashboard/:view'
export const DASHBOARD_VIEW_FIRST: string = DASHBOARD_VIEW_URL.replace(':view', '1')
export const DASHBOARD_VIEW_SECOND: string = DASHBOARD_VIEW_URL.replace(':view', '2')
export const DASHBOARD_VIEW_FIRST_WITH_CHART: string = `${DASHBOARD_VIEW_FIRST}/:chart?/:from?`
export const HOLIDAYS_URL: string = '/holidays'

export const CLIENTS_BASE_URL: string = '/clients'
export const VIEW_CLIENT_URL: string = '/clients/view/:id(\\d+)'
export const NEW_CLIENT_URL: string = '/clients/new'
export const CLIENTS_LIST_URL = '/clients/list'
export const EDIT_CLIENT_URL = '/clients/edit/:id(\\d+)'
export const NEW_OWNER_URL = '/clients/:id(\\d+)/owner/:type(new|create)'
export const EDIT_OWNER_URL = '/clients/:id(\\d+)/owner/edit/:idOwner(\\d+)'
export const CLIENT_DOCUMENTS = '/clients/:id(\\d+)/documents'

export const ISO_BASE_URL: string = '/iso'
export const ISO_LIST_URL: string = '/iso/list'
export const ISO_ADD_NEW_URL: string = '/iso/new'
export const ISO_INFO_URL: string = '/iso/info/:id'
export const ISO_OWN_URL: string = '/iso/info/me'
export const ISO_EDIT_URL: string = '/iso/edit/:id(\\d+)'

export const COMPANIES_URL = '/companies'
export const COMPANY_NEW_URL = '/companies/new'
export const COMPANY_EDIT_URL = '/companies/:id/edit'
export const COMPANY_URL = '/companies/:id'
export const COMPANY_LIST_URL = '/companies/list'

export const OUTHOUSE_COMPANIES_URL = '/outhouse-companies'
export const OUTHOUSE_COMPANIES_NEW_URL = '/outhouse-companies/new'
export const OUTHOUSE_COMPANY_URL = '/outhouse-companies/:id'
export const OUTHOUSE_COMPANY_EDIT_URL = '/outhouse-companies/:id/edit'

export const BANKS_URL = '/banks'
export const BANK_URL = '/banks/:id'
export const BANKS_NEW_URL = '/banks/new'
export const BANKS_EDIT_URL = '/banks/:id/edit'

export const SIGN_IN_URL = '/sign-in'
export const SIGN_UP_URL = '/sign-up'
export const RESET_PASSWORD_URL = '/reset-password'
export const CREATE_PASSWORD_INVITATION_URL = '/create-password'
export const CREATE_PASSWORD_URL = '/create-password-register'
export const SET_PASSWORD_URL = '/set-password'
export const RESET_EMAIL_SENT_URL = '/reset-email-sent'
export const CHOOSE_ROLE = '/choose-role'

export const AUTH_URLS = [
  SIGN_IN_URL,
  SIGN_UP_URL,
  RESET_PASSWORD_URL,
  CREATE_PASSWORD_INVITATION_URL,
  SET_PASSWORD_URL,
  RESET_EMAIL_SENT_URL,
  CHOOSE_ROLE,
]

export const MESSENGER_URL = '/messenger/:user?'

export const NEW_DEAL_URL = '/deal/:id?/:view(new|edit)/:type(inhouse|outhouse|client-deal)/:step?'
export const CLIENT_DEAL = '/deal/:id?/:view(new|edit)/:type(client-deal)/:step?'
export const INHOUSE_DEAL = '/deal/:id?/:view(new|edit)/:type(inhouse)/:step?'
export const OUTHOUSE_DEAL = '/deal/:id?/:view(new|edit)/:type(outhouse)'

export const DEAL_BASE_URL = '/deal'
export const DEAL_INFO_URL = '/deal/:id(\\d+)'
export const DEAL_AUDIT_LOG_URL = '/deal/:id(\\d+)/audit-log'
export const DEAL_COMMENTS_LOG_URL = '/deal/:id(\\d+)/comments-log'
export const DEAL_ASSIGN_LENDERS = '/deal/:id(\\d+)/:type(inhouse|outhouse)/assign-lenders'
export const DEALS_LIST_URL = '/deal/list/:type(submitted|funded|my-deals)?'

export const SYNDICATORS_URL = '/syndicators'
export const SYNDICATOR_INFO_URL = '/syndicators/:id/info'
export const SYNDICATOR_OWN_URL = '/syndicators/me/info'
export const SYNDICATOR_REPORT_URL = '/syndicators/:id/report'
export const SYNDICATORS_NEW_URL = '/syndicators/new'
export const SYNDICATORS_EDIT_URL = '/syndicators/:id/edit'

export const REP_BASE_URL = '/rep'
export const REP_LIST_URL = '/rep/list'
export const REP_ADD_NEW_URL = '/rep/new'
export const REP_INFO_URL = '/rep/info/:id'
export const REP_OWN_URL = '/rep/info/me'
export const REP_EDIT_URL = '/rep/edit/:id(\\d+)'

export const VENDOR_BASE_URL = '/vendor'
export const VENDOR_LIST_URL = '/vendor/list'
export const VENDOR_ADD_NEW_URL = '/vendor/new'
export const VENDOR_INFO_URL = '/vendor/info/:id'
export const VENDOR_OWN_URL = '/vendor/info/me'
export const VENDOR_EDIT_URL = '/vendor/edit/:id(\\d+)'
export const VENDOR_DOCUMENTS = '/vendor/:id(\\d+)/documents'

export const NOTIFICATIONS_NEW_URL = '/notifications/new'
export const NOTIFICATIONS_SENT_URL = '/notifications/sent'
export const NOTIFICATIONS_RECEIVED_URL = '/notifications/received/:id?'
export const NOTIFICATIONS_RECEIVED_BASE_URL = '/notifications/received'
// export const NOTIFICATION_RECEIVED_URL = '/notifications/received/:id'
export const NOTIFICATIONS_BASE_URL = '/notifications'

export const QUES_BASE_URL = '/ques'
export const FUNDING_QUE_URL = '/ques/funding'
export const WITHDRAWAL_QUE_URL = '/ques/withdrawal'

export const ACH_DEBIT_URL = '/ach-debit'
export const ACH_CREDIT_URL = '/ach-credit'
export const ACH_FEES_URL = '/ach-fees'

export const TRANSACTIONS_URL = '/transactions/:type(iso|rep|vendor|syndicator)/:id(\\d+)/:businessName'
export const WITHDRAWAL_URL = '/withdrawal/:type(iso|rep|vendor|syndicator)/:id(\\d+)/:businessName'
export const LEGAL_FEES = '/legal-fees'
export const IMPORT_LEGAL_FEES = '/import-legal-fees'

export const NACHA_LIST_URL = '/nacha'
export const TODAYS_PAYMENT_REPORT_URL = '/todays-payment-report'

export const UPLOAD_USER_PAGE_DOCS = '/upload-docs/:type/:id'

export const USER_ROLES_URL = '/roles'
export const USER_MANAGEMENT_URL = '/user-management'

export const REP_OVERALL_DEALS_URL = '/overall-deals-summary'

export const REQUEST_FUNDER_INFO_URL = '/request-funder-info'
export const REP_PERFORMANCE_URL = '/rep-performance'

export const APPROVE_SYNDICATION_LIMIT_URL = '/approve-syndication-limit'

export const SYNDICATED_DEALS_URL = '/syndicated-deals'
export const PORTFOLIO_SUMMARY_URL = '/portfolio-summary'

export const EQUIFAX_REPORT_URL = '/equifax-report'
export const EXPERIAN_REPORT_URL = '/experian-report'
export const ENHANCED_REPORT_URL = '/enhanced-report'
export const IBV_REPORT_URL = '/ibv-report'
export const PRBC_REPORT_URL = '/prbc-report'
export const LIEN_SOLUTIONS_FILING_URL = 'https://prod2.ilienonline.com/Filing/iLien.aspx?LienRefNumber=&Location=FILINGS&type=Original&from=LN'

export const REQUEST_ANONYMOUS = '/requestAnonymous'

import React from 'react'
import { Box } from '@material-ui/core'
import ConfirmSignUpForm from 'components/ConfirmSignUpForm'

const ConfirmSignUpPage = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <ConfirmSignUpForm />
    </Box>
  )
}

export default ConfirmSignUpPage

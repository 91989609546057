import React, { FC, useMemo, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ReactComponent as PlusIcon } from 'assets/svg/PlustIcon.svg'
import { TableLastDealsSecondView, useFullDeals } from 'hooks/useFullDeals'
import { IDealsListItem } from 'typescript/interfaces/deals'
import { NEW_DEAL_URL } from 'constants/routes'
import { generatePath, useHistory } from 'react-router-dom'
import { CREATE_CLIENT_DEAL, CREATE_INHOUSE_OUTHOUSE_PERM } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'
import { useMutation, useQueryCache } from 'react-query'
import { deleteDeal } from 'services/deals'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { AxiosResponse } from 'axios'
import { IClientEntity } from 'typescript/interfaces/clients'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'

interface IDealsTableProps {
  deals: Array<IDealsListItem>
  idClient: string | number
}

const DealsTable: FC<IDealsTableProps> = ({ deals, idClient }) => {
  const queryCache = useQueryCache()
  const [openDeleteModal, setOpenDeleteModal] = useState({
    id: 0,
    open: false,
  })
  const { push } = useHistory()
  const { hasPermission } = usePermission()
  const { enqueueSnackbar } = useSnackbar()

  const urlNewDeal = useMemo(() => {
    if (hasPermission(CREATE_INHOUSE_OUTHOUSE_PERM))
      return generatePath(NEW_DEAL_URL, {
        view: 'new',
        type: 'inhouse',
        step: '1',
      })
    else if (hasPermission(CREATE_CLIENT_DEAL))
      return generatePath(NEW_DEAL_URL, {
        view: 'new',
        type: 'client-deal',
        step: '1',
      })
    return ''
  }, [hasPermission])

  const [handleDelete, { isLoading: deleteIsLoading }] = useMutation(() => deleteDeal(openDeleteModal.id.toString()), {
    onSuccess(res, variables) {
      const { id } = openDeleteModal
      enqueueSnackbar(<Typography>Successfully deleted deal</Typography>)
      setOpenDeleteModal((prevState) => ({
        ...prevState,
        open: false,
      }))
      const prevClient = queryCache.getQueryData<AxiosResponse<IClientEntity>>(['clientById', idClient.toString()])
      queryCache.setQueryData(['clientById', idClient.toString()], {
        ...prevClient,
        data: {
          ...prevClient!.data,
          deals: prevClient!.data.deals.filter((o) => o.id !== id),
        },
      })
    },
    onError() {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const { columnsForClient } = useFullDeals({
    deleteDeal: (id) => {
      setOpenDeleteModal({
        open: true,
        id: id,
      })
    },
  })

  return (
    <Paper elevation={0}>
      <Box px={12} py={8} pt={13}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" color="textSecondary">
            Deals
          </Typography>
          {urlNewDeal && (
            <Box>
              <Button
                startIcon={<PlusIcon />}
                variant="contained"
                color="primary"
                onClick={() =>
                  push({
                    pathname: urlNewDeal,
                    search: `?clientId=${idClient}`,
                  })
                }
              >
                Add Deal
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <TableLastDealsSecondView columns={columnsForClient} data={deals} />
      </Box>
      <ModalComponent open={openDeleteModal.open}>
        <ConfirmationContent
          isLoading={deleteIsLoading}
          text="Are you sure you want to delete deal?"
          handleCancel={() => {
            setOpenDeleteModal({
              open: false,
              id: 0,
            })
          }}
          handleConfirm={handleDelete}
        />
      </ModalComponent>
    </Paper>
  )
}

export default DealsTable

import React from 'react'
import { Box } from '@material-ui/core'
import SignUpInvitationForm from 'components/SignUpInvitationForm'

const SignUpInvitationPage = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <SignUpInvitationForm />
    </Box>
  )
}

export default SignUpInvitationPage

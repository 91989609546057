import * as yup from 'yup'
import { INVALID_EMAIL, INVALID_PHONE_NUMBER, NUMBERS_ONLY, REQUIRED_FIELD, ZIP_CODE_FORMAT_ERROR } from 'constants/errors'
import { zipCodeReg } from 'constants/regExp'

export const schemaOwners = yup.object().shape({
  clientId: yup.string(),
  firstName: yup.string().required(REQUIRED_FIELD),
  lastName: yup.string().required(REQUIRED_FIELD),
  birthDate: yup.string().nullable().required(REQUIRED_FIELD),
  title: yup.string().required(REQUIRED_FIELD),
  ownership: yup.number().required(REQUIRED_FIELD).typeError(NUMBERS_ONLY),
  ssn: yup.string().required(REQUIRED_FIELD),
  ownershipDate: yup.string(),
  driverLicense: yup.string().required(REQUIRED_FIELD),
  dlExpiredDate: yup.string().nullable().required(REQUIRED_FIELD),
  stateIssuedDL: yup.string().required(REQUIRED_FIELD),
  stateId: yup.string().required(REQUIRED_FIELD),
  city: yup.string().required(REQUIRED_FIELD),
  street: yup.string().required(REQUIRED_FIELD),
  zipCode: yup.string().required(REQUIRED_FIELD).matches(zipCodeReg, ZIP_CODE_FORMAT_ERROR),
  email: yup.string().required(REQUIRED_FIELD).email(INVALID_EMAIL),
  mobile: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('isFullPhoneNumber', INVALID_PHONE_NUMBER, (val) => Boolean(val && val.replace(/\s/g, '').indexOf('_') < 0)),
  fax: yup.number().typeError(NUMBERS_ONLY),
})

import React, { FC, useState, useContext } from 'react'
import Drawer from '@material-ui/core/Drawer'
import { useDrawerMenuStyles } from 'components/Drawer/style'
import Box from '@material-ui/core/Box'
import { ReactComponent as MenuIcon } from 'assets/svg/LogoMenu.svg'
import { ReactComponent as LogoutIcon } from 'assets/svg/LogoutIcon.svg'
import Divider from '@material-ui/core/Divider'
import SvgIcon from '@material-ui/core/SvgIcon'
import { Link, useHistory } from 'react-router-dom'
import { SIGN_IN_URL } from 'constants/routes'
import { API_LOG_OUT } from 'constants/apiUrl'
import { useMutation } from 'react-query'
import { JWT_TOKEN, REFRESH_TOKEN, USER_ROLE } from 'constants/localStorageKeys'
import cn from 'classnames'
import { Button, MenuItem, Popover } from '@material-ui/core'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { useMenuItems, IMenuItem, IMenuItemChildren } from 'hooks/useMenuItems'
import { UserContext } from 'contexts/userContext'
import { RESET_USER_STORE } from 'constants/actionTypes'
import { publicInstance } from 'services/axios/publicInstance'

const DrawerMenu: FC = () => {
  const { dispatch } = useContext(UserContext)
  const { menuItems } = useMenuItems()
  const history = useHistory()
  const classes = useDrawerMenuStyles()
  const [openLogout, setOpenLogout] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [listToRender, setListToRender] = React.useState<IMenuItemChildren[]>([])

  const handleButtonClick = (event: any, menuItem: IMenuItem) => {
    if (menuItem.url) {
      history.push(menuItem.url)
      return
    }
    if (menuItem.children?.length) {
      setListToRender(menuItem.children)
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    // setTimeout(() => setListToRender([]), 200)
  }

  const [logOut] = useMutation(
    () => {
      dispatch({
        type: RESET_USER_STORE,
      })
      return publicInstance.post(API_LOG_OUT)
    },
    {
      onSettled() {
        localStorage.removeItem(USER_ROLE)
        localStorage.removeItem(JWT_TOKEN)
        localStorage.removeItem(REFRESH_TOKEN)
        history.replace(SIGN_IN_URL)
      },
    },
  )

  const isActiveTab = (menuItem: IMenuItem) => {
    const { pathname } = history.location
    if (menuItem.url) {
      return pathname.includes(menuItem.url)
    } else if (menuItem.children) {
      return menuItem.children.map((child) => child.url).some((url) => pathname.includes(url))
    }
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paperAnchorLeft: classes.wrapper,
      }}
    >
      <Box px={4} py={4} display="inline-flex" justifyContent="center">
        <MenuIcon width="3rem" height="3rem" />
      </Box>
      <Divider />
      <Box flex={1}>
        {menuItems.map((item) => (
          <Button
            classes={{ label: classes.btnLabel }}
            key={item.url}
            onClick={(e) => handleButtonClick(e, item)}
            className={cn(classes.link, isActiveTab(item) && classes.activeLink)}
          >
            <SvgIcon component={item.icon} />
            {item.label}
          </Button>
        ))}
      </Box>
      <Divider />
      <Box>
        <Link
          className={cn(classes.link, classes.logOutLink)}
          to={SIGN_IN_URL}
          onClick={(e) => {
            e.preventDefault()
            setOpenLogout(true)
          }}
        >
          <SvgIcon component={LogoutIcon} />
        </Link>
      </Box>

      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        keepMounted
        classes={{ paper: classes.popover }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {listToRender.map((child) => (
          <MenuItem
            className={classes.menuItemChild}
            key={child.label}
            onClick={() => {
              handleClose()
              if (child.redirectUrl) {
                window.open(child.redirectUrl, '_blank')?.focus()
              } else {
                history.push(child.url)
              }
            }}
          >
            {child.label}
          </MenuItem>
        ))}
      </Popover>
      <ModalComponent open={openLogout} onClose={() => setOpenLogout(false)}>
        <ConfirmationContent handleCancel={() => setOpenLogout(false)} handleConfirm={logOut} text="Are you sure you want to log out?" />
      </ModalComponent>
    </Drawer>
  )
}

export default DrawerMenu

import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { BASE_APP_URL } from 'constants/routes'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useMutation } from 'react-query'
import { generatePath, useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import SelectComponent from 'UI/Select'
import MenuItem from '@material-ui/core/MenuItem'
import RequiredOption from 'UI/Select/RequiredOption'
import { useStates } from 'hooks/useStates'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getEnhancedReport } from 'services/clients'
import { Directions, StreetType, TinType } from 'typescript/enums/integrations'
import { ICreditReportForm } from 'typescript/interfaces/reports'
import { downloadFile } from 'utils/downloadFile'
import DatePicker from 'UI/DatePicker/DatePicker'
import isValidDate from 'utils/isValidDate'
import convertDateForPicker from 'utils/convertDateForPicker'
import { schemaAddEnhancedSearchReport } from './schema'

const useStyles = makeStyles((theme) => ({
  selectLabel: {
    color: theme.palette.secondary.dark,
  },
  backButton: {
    marginRight: '1.25rem',
  },
  heading: {
    fontSize: '2.25rem',
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  subHeading: {
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
    marginBottom: '2rem',
    fontWeight: 500,
  },
  paper: {
    height: '100%',
    padding: '3rem',
  },
  fullHeight: {
    height: '100%',
  },
}))

const streetTypes = Object.entries(StreetType).map(([key, value], i) => {
  const uniqueKey = `${key}-${i}`
  return (
    <MenuItem key={uniqueKey} value={key}>
      {value}
    </MenuItem>
  )
})

const directionsTypes = Object.entries(Directions).map(([key, value], i) => {
  const uniqueKey = `${key}-${i}`
  return (
    <MenuItem key={uniqueKey} value={key}>
      {value}
    </MenuItem>
  )
})

const tinTypes = Object.entries(TinType).map(([key, value], i) => {
  const uniqueKey = `${key}-${i}`
  return (
    <MenuItem key={uniqueKey} value={key}>
      {value}
    </MenuItem>
  )
})

export const EnhancedReportForm = () => {
  const { push } = useHistory()
  const snack = useSnackbar()
  const s = useStyles()
  const { data: statesData } = useStates()

  const [mutateCreateEnhancedReport, { isLoading: isCreateLoading }] = useMutation(getEnhancedReport, {
    onSuccess(data) {
      downloadFile(data)
      push(`${generatePath(BASE_APP_URL)}`)
      snack.enqueueSnackbar(<Typography>Successfully created Enhnced People Search Report</Typography>)
    },
    onError(error: AxiosError) {
      if (error.response?.data.description) {
        snack.enqueueSnackbar(error.response?.data.description)
      } else {
        snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik<ICreditReportForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: '',
      streetNum: '',
      streetName: '',
      streetType: null,
      preDirection: null,
      postDirection: null,
      apt: '',
      state: '',
      postalCode: '',
      city: '',
      tinType: null,
      taxId: '',
      birthDate: null,
    },
    validationSchema: schemaAddEnhancedSearchReport(),
    onSubmit: (formValues) => {
      mutateCreateEnhancedReport(formValues)
    },
  })

  return (
    <Grid
      container
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSubmit()
      }}
      spacing={8}
    >
      <Grid item xs={12}>
        <Paper className={s.paper}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography className={s.heading}>{'Create Enhanced People Search Report'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" color="textSecondary">
                Personal Info
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                value={values.firstName}
                name="firstName"
                onChange={handleChange}
                fullWidth
                label="Consumer's First Name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                value={values.lastName}
                name="lastName"
                onChange={handleChange}
                fullWidth
                label="Consumer's Last Name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={Boolean(touched.middleName && errors.middleName)}
                helperText={touched.middleName && errors.middleName}
                value={values.middleName}
                name="middleName"
                onChange={handleChange}
                fullWidth
                label="Consumer's Middle Name"
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                disableFuture
                name="birthDate"
                value={values.birthDate ? values.birthDate : null}
                onChange={(date) => setFieldValue('birthDate', convertDateForPicker(date))}
                error={Boolean(touched.birthDate && errors.birthDate) || !isValidDate(values.birthDate)}
                helperText={(touched.birthDate && errors.birthDate) || (!isValidDate(values.birthDate) && 'MM/DD/YYYY')}
                inputVariant="outlined"
                variant="dialog"
                type="keyboard"
                label="Birth Date"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" color="textSecondary">
                Postal Address
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={Boolean(touched.streetNum && errors.streetNum)}
                helperText={touched.streetNum && errors.streetNum}
                value={values.streetNum}
                name="streetNum"
                onChange={handleChange}
                label="Street Number"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                error={Boolean(touched.streetName && errors.streetName)}
                helperText={touched.streetName && errors.streetName}
                value={values.streetName}
                name="streetName"
                onChange={handleChange}
                fullWidth
                label="Street Name"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                helperText={touched.streetType && errors.streetType}
                error={Boolean(touched.streetType && errors.streetType)}
                value={values.streetType}
                name="streetType"
                onChange={handleChange}
                label="Street Type (ST, RD, CL, etc.)"
                fullWidth
              >
                {streetTypes}
              </SelectComponent>
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                helperText={touched.preDirection && errors.preDirection}
                error={Boolean(touched.preDirection && errors.preDirection)}
                value={values.preDirection}
                name="preDirection"
                onChange={handleChange}
                label="Pre Direction"
                fullWidth
              >
                {directionsTypes}
              </SelectComponent>
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                helperText={touched.postDirection && errors.postDirection}
                error={Boolean(touched.postDirection && errors.postDirection)}
                value={values.postDirection}
                name="postDirection"
                onChange={handleChange}
                label="Post Direction"
                fullWidth
              >
                {directionsTypes}
              </SelectComponent>
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(touched.apt && errors.apt)}
                variant="outlined"
                helperText={touched.apt && errors.apt}
                value={values.apt}
                name="apt"
                onChange={handleChange}
                label="Apartment / Unit number"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(touched.city && errors.city)}
                variant="outlined"
                helperText={touched.city && errors.city}
                value={values.city}
                name="city"
                onChange={handleChange}
                label="City"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                helperText={touched.state && errors.state}
                error={Boolean(touched.state && errors.state)}
                value={values.state}
                name="state"
                onChange={handleChange}
                label="State"
                fullWidth
              >
                {statesData?.data.map((state) => (
                  <MenuItem key={state.id} value={state.abbreviation}>
                    {state.name}
                  </MenuItem>
                ))}
              </SelectComponent>
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(touched.postalCode && errors.postalCode)}
                variant="outlined"
                helperText={touched.postalCode && errors.postalCode}
                value={values.postalCode}
                name="postalCode"
                onChange={handleChange}
                label="Postal Code"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" color="textSecondary">
                TIN Info
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SelectComponent
                helperText={touched.tinType && errors.tinType}
                error={Boolean(touched.tinType && errors.tinType)}
                value={values.tinType}
                name="tinType"
                onChange={handleChange}
                label={<RequiredOption label="TIN type" />}
                fullWidth
              >
                {tinTypes}
              </SelectComponent>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={<RequiredOption label="Tax Identification Number" />}
                name="taxId"
                value={values.taxId}
                onChange={handleChange}
                error={Boolean(touched.taxId && errors.taxId)}
                helperText={touched.taxId && errors.taxId}
              />
            </Grid>
          </Grid>
          <Box mt="auto" pt="2rem">
            <Button disabled={isCreateLoading} color="primary" variant="contained" type="submit">
              Complete
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

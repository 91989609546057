import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useAmountBlockStyles } from './style'

interface IAmountBlockProps {
  label: string
  value: string
}

const AmountBlock: FC<IAmountBlockProps> = ({ label, value }) => {
  const classes = useAmountBlockStyles()
  return (
    <Box flex="1" justifyContent="space-between" display="flex" alignItems="center" p="1.5rem">
      <Typography className={classes.label}>{label}</Typography>

      <Typography className={classes.value} variant="h4">
        {value}
      </Typography>
    </Box>
  )
}

export default AmountBlock

export const fieldToTitleMap: { [key: string]: string } = {
  ClientId: 'Client',
  PaymentFrequency: 'Payment Frequency',
  AdvanceAmount: 'Advance Amount',
  PaybackAmount: 'Payback Amount',
  Factor: 'Factor Rate',
  UpdatedDate: 'Updated Date',
  Pnl: 'Daily Payment',
  IsoId: 'ISO',
  CommissionToRep: 'Commission To Rep',
  IsoFee: 'ISO Fee',
  TotalCommission: 'Total Commission',
  RepresentativeId: 'REP',
  PayOffAmount: 'Pay Off Amount',
  NameOnBankAccount: 'Bank name',
  Account: 'Bank Account',
  NameOnCheck: 'Name On Voided Check',
  BankAccountType: 'Bank Account Type',
  BankRouting: 'Bank Routing',
  SendAsWire: 'Send As Wire',
  DealLender: 'Lender ID',
  IsLead: 'Main lender',
  LendingAmount: 'Amt Participated',
  LendingPercentage: 'Lending Percentage',
  ApprovedDate: 'Approved Date',
  PsfFee: 'Psf Fee',
  SyndicationFee: 'Syndication Fee',
  FrequentPayment: 'Frequent Payment',
  Id: 'Client Bank Account Id',
  Vendor: 'Vendor ID',
}

export const dateFields = ['UpdatedDate', 'ApprovedDate']
export const percentFields = ['LendingPercentage']
export const arrayField = ['Vendor']

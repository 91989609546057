import React, { FC } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { FUNDING_QUE_URL, WITHDRAWAL_QUE_URL } from 'constants/routes'
import FundingQue from 'pages/FundingQue'
import WithdrawalQuePage from 'pages/WithdrawalQue'

const QuesContainer: FC = () => {
  return (
    <Switch>
      <Route path={FUNDING_QUE_URL} component={FundingQue} />
      <Route path={WITHDRAWAL_QUE_URL} component={WithdrawalQuePage} />
      <Redirect to={FUNDING_QUE_URL} />
    </Switch>
  )
}

export default QuesContainer

import makeStyles from '@material-ui/core/styles/makeStyles'
import { bg3 } from 'containers/MaterialUiContainer/mainTheme'
import { fade } from '@material-ui/core'

export const useAdditionalMaterialStyle = makeStyles((theme) => ({
  wrapper: {
    marginLeft: '-3.25rem',
    marginRight: '-3.25rem',
    '@media screen and (min-width: 1280px) and (max-width: 1600px)': {
      marginLeft: '-1.5rem',
      marginRight: '-1.5rem',
    },
  },
  colorSecondaryDark: {
    color: theme.palette.secondary.dark,
  },
  boldText: {
    fontWeight: 800,
  },
  errorButton: {
    border: `1px solid ${theme.palette.error.main}`,
    backgroundColor: fade(theme.palette.error.main, 0.05),
    color: theme.palette.error.main,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: fade(theme.palette.error.main, 0.1),
    },
  },
  closeTrsModalButton: {
    padding: theme.spacing(0),
    borderRadius: '50%!important',
    border: '1px solid #D0DDE8!important',
    backgroundColor: `${bg3}!important`,
    '&:hover': {
      backgroundColor: '#D0DDE8!important',
    },
    '& svg': {
      '& path': {
        fill: '#738BA5',
      },
    },
  },
  // #E4EDF4
  greyDarkButton: {
    background: theme.palette.info.light,
  },
  checkboxLabel: {
    color: theme.palette.secondary.dark,
    fontSize: '1.0625rem',
    marginBottom: -3,
  },
  navButton: {
    '& svg': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  absoluteEditButton: {
    position: 'absolute',
    right: 15,
  },
}))

import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import React, { FC } from 'react'
import cn from 'classnames'
import { convertToPriceFormat } from 'utils/formatters'
import { IPortfolioSummaryTotal } from 'typescript/interfaces/syndicators'
import TableFooter from '@material-ui/core/TableFooter'
import { useTableFooter } from 'components/Dashboard/style'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const useStyles = makeStyles((theme) => ({
  row: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  cell: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
}))

interface IPortfolioSummaryTableFooterProps {
  stats?: IPortfolioSummaryTotal
}

const PortfolioSummaryTableFooter: FC<IPortfolioSummaryTableFooterProps> = ({ stats }) => {
  const s = useStyles()
  const classes = useTableFooter()
  const additionalClasses = useAdditionalMaterialStyle()

  return (
    <TableFooter>
      <TableRow className={s.row}>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell)} colSpan={10} />
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>Total:</TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
          {convertToPriceFormat(stats?.frequentPayment)}
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
          {convertToPriceFormat(stats?.advanceAmount)}
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
          {convertToPriceFormat(stats?.paybackAmount)}
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
          {convertToPriceFormat(stats?.syndicationFee)}
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>{`${stats?.paidOff}%`}</TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
          {convertToPriceFormat(stats?.payOffAmount)}
        </TableCell>
        <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
          {convertToPriceFormat(stats?.projectedRtr)}
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}

export default PortfolioSummaryTableFooter

import React, { FC } from 'react'
import { Box } from '@material-ui/core'

const SelectGroup: FC = ({ children }) => {
  const countChildren = React.Children.count(children)
  return (
    <Box display="flex" alignItems="center">
      {React.Children.map(React.Children.toArray(children).filter(Boolean), (child, i) => {
        const childrenNew = child as React.ReactElement
        return React.cloneElement(childrenNew, {
          smoothLeft: i !== 0,
          smoothRight: i !== countChildren - 1,
        })
      })}
    </Box>
  )
}

export default SelectGroup

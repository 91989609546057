import React, { FC, ReactElement, useMemo } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as CircleArrowIcon } from 'assets/svg/CircleArrowBottomIcon.svg'
import { ReactComponent as CircleCloseIcon } from 'assets/svg/CircleCloseIcon.svg'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete/Autocomplete'
import { useQuery } from 'react-query'
import { Value } from '@material-ui/lab'
import { getClientsLight } from 'services/clients'
import { IClientListItemLight, ILightClientListParams } from 'typescript/interfaces/clients'
import cn from 'classnames'
import { useSelectComponentStyle } from 'UI/Select'
import { Box, Chip } from '@material-ui/core'
import { useMultipleAutocompleteStyles } from 'components/RolesAutoComplete'

interface IAutoCompleteProps {
  clientId: any
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void
  error?: boolean
  helperText?: string | false
  label?: string | ReactElement
  paramsRequest?: Partial<ILightClientListParams>
  disabled?: boolean
  smoothLeft?: boolean
  smoothRight?: boolean
  className?: string
  multiple?: boolean
}

const ClientAutoComplete: FC<IAutoCompleteProps> = ({
  clientId,
  onChange,
  helperText,
  error,
  label = 'Client',
  paramsRequest,
  disabled,
  smoothLeft,
  smoothRight,
  className,
  multiple,
}) => {
  const { data, isLoading } = useQuery(['clientsLight'], () => getClientsLight({ PageSize: 4000, ...paramsRequest }))
  const arrayCompanies = useMemo(() => {
    return data ? data.data : []
  }, [data])
  const selectedOptionClient = useMemo(() => {
    if (data) {
      const findClient = data.data.find((c) => c.id === clientId) || null
      return findClient
    }
    return null
  }, [data, clientId])

  const arraySelectedOptions = useMemo(() => {
    if (Array.isArray(clientId) && data) {
      return arrayCompanies.filter((c) => clientId.includes(c.id))
    }
    return []
  }, [arrayCompanies, clientId])

  const classes = useSelectComponentStyle()
  const mClasses = useMultipleAutocompleteStyles()
  return (
    <Autocomplete
      // value={selectedOptionClient}
      value={multiple ? arraySelectedOptions : selectedOptionClient}
      multiple={Boolean(multiple)}
      disabled={disabled}
      loading={isLoading}
      options={arrayCompanies}
      getOptionLabel={(option: IClientListItemLight) => {
        return option.businessName
      }}
      classes={{ inputRoot: cn(mClasses.inputRoot, multiple), root: className }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option: IClientListItemLight, index) => <Chip label={option.businessName} {...getTagProps({ index })} />)
      }}
      getOptionSelected={(option: IClientListItemLight, value) => {
        if (value && value.id) return option.id === value.id
        return false
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={helperText}
          error={error}
          label={label}
          className={cn({ [classes.disabledFormControlLeftBorder]: smoothLeft }, { [classes.disabledFormControlRightBorder]: smoothRight })}
          InputProps={{
            ...params.InputProps,
            ref: params.InputProps.ref,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                {selectedOptionClient && (
                  <Box mr="0.25rem" display="flex" alignItems="center" justifyContent="center" style={{ cursor: 'pointer' }}>
                    <SvgIcon
                      fontSize="inherit"
                      viewBox="0 0 20 20"
                      component={CircleCloseIcon}
                      onClick={(e: any) => {
                        onChange!(e, undefined, 'clear')
                      }}
                    />
                  </Box>
                )}
                <SvgIcon fontSize="inherit" viewBox="0 0 20 20" component={CircleArrowIcon} />
              </>
            ),
          }}
          variant="outlined"
        />
      )}
    />
  )
}

export default ClientAutoComplete

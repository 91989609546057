import { IIsoInitialValues } from 'components/ISOForm/index'
import { userPageInitValues } from 'typescript/interfaces/users'

export const initValuesIso: IIsoInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  mobile: '',
  fax: '',
  nameOnCheck: '',
  businessName: '',
  city: '',
  street: '',
  zipCode: '',
  federalTaxId: '',
  stateId: '',
  userPage: userPageInitValues,
}

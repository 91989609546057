import { Box, MenuItem, Typography, CircularProgress } from '@material-ui/core'
import React from 'react'
import { ReactComponent as ProfileBlueIcon } from 'assets/svg/ProfileBlueIcon.svg'
import { useQuery } from 'react-query'
import { USER_ROLE } from 'constants/localStorageKeys'
import { getCurrentUser } from 'services/users'
import { IRole } from 'typescript/interfaces/users'
import { useHistory } from 'react-router-dom'
import { BASE_APP_URL } from 'constants/routes'
import { useStyles } from './styles'

const ChooseRoleContainer = () => {
  const classes = useStyles()
  const { push, location } = useHistory()
  const params = new URLSearchParams(location.search)
  const redirect = params.get('redirectTo')
  const { data, isLoading } = useQuery(['currentUser-role'], getCurrentUser, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(resData) {
      if (resData.data.userRoles.length === 1) {
        handleRoleChange(resData.data.userRoles[0])
      }
    },
  })

  const handleRoleChange = (role: IRole) => {
    localStorage.setItem(USER_ROLE, role.name)
    if (redirect) push(redirect)
    else push(BASE_APP_URL)
  }

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <Box width="520px" maxWidth="80%" component="form">
        <Typography className={classes.text} align="center">
          Choose Role
        </Typography>

        {data?.data.userRoles.map((role) => (
          <MenuItem onClick={() => handleRoleChange(role)} key={role.id} className={classes.menuItem}>
            <Box className={classes.iconContainer}>
              <ProfileBlueIcon />
            </Box>
            <Typography variant="h4" color="textSecondary">
              {role.name}
            </Typography>
          </MenuItem>
        ))}
      </Box>
    </Box>
  )
}

export default ChooseRoleContainer

import { AxiosResponse } from 'axios'
import { API_GET_TODAYS_PAYMENT_REPORT_LIST } from 'constants/apiUrl'
import { privateInstance } from 'services/axios/privateInstance'
import { IPagination } from 'typescript/interfaces/pagination'
import { AdditionalPaginationProps, ITodaysPaymentReportListItem } from 'typescript/interfaces/todaysPaymentReport'

export const getTodaysPaymentReportList = (params: IPagination & AdditionalPaginationProps) =>
  privateInstance.get<IPagination & AdditionalPaginationProps, AxiosResponse<Array<ITodaysPaymentReportListItem>>>(
    API_GET_TODAYS_PAYMENT_REPORT_LIST,
    {
      params: params,
    },
  )

import React, { useRef } from 'react'
import TableComponent, { TableComponentType } from 'UI/Table'
import { IColumn } from 'typescript/interfaces/tableUi'
import { Link } from 'react-router-dom'
import { convertDate } from 'utils/formatters'
import { ReactComponent as CloseCircleIcon } from 'assets/svg/DeleteCircleIcon.svg'
import IconButton from '@material-ui/core/IconButton'
import { useTableComponentStyle } from 'UI/Table/style'
import { INachaFile } from 'typescript/interfaces/achDebit'
import { NachaFilesSortFields } from 'typescript/enums/ach'

export const TableNacha = TableComponent as TableComponentType<INachaFile>

interface IUseNachaProps {
  handleDelete?: (id: number) => void
  handleDownload?: (url: string, name: string) => void
}

export const useNacha = (props?: IUseNachaProps) => {
  const tableStyle = useTableComponentStyle()
  const columns = useRef<IColumn<INachaFile>[]>([
    {
      label: 'File #',
      field: NachaFilesSortFields.id,
      sorting: true,
      render: (data) => data.id,
      bodyCellClassName: tableStyle.secondaryBodyCell,
    },
    {
      label: 'File Name',
      field: NachaFilesSortFields.fileName,
      sorting: true,
      bodyCellClassName: tableStyle.accentCell,
      render: (data) => (
        <Link
          to={data.documentUrl}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            props && props.handleDownload && props.handleDownload(data.documentUrl, data.documentUrl.split('/ach_batch_files/')[1])
          }}
        >
          {data.documentUrl.split('/ach_batch_files/')[1]}
        </Link>
      ),
    },
    {
      label: 'Created On',
      field: NachaFilesSortFields.createdOn,
      sorting: true,
      render: (data) => convertDate(data.createdDate),
      bodyCellClassName: tableStyle.secondaryDarkCell,
    },
    {
      label: 'Status',
      field: NachaFilesSortFields.status,
      sorting: true,
      render: (data) => data.status,
      bodyCellClassName: tableStyle.secondaryDarkCell,
    },
    // {
    //   label: '',
    //   field: 'markUpload',
    //   render: (data) => (
    //     <Button
    //       variant="outlined"
    //       color="secondary"
    //       size="small"
    //       startIcon={<CheckIcon width="12px!important" height="9px!important" />}
    //       onClick={() => props && props.handleMarkUploaded && props.handleMarkUploaded(data.id)}
    //     >
    //       Mark Uploaded
    //     </Button>
    //   ),
    // },
    {
      label: 'Delete',
      field: 'delete',
      render: (data) => (
        <IconButton size="small" onClick={() => props && props.handleDelete && props.handleDelete(data.id)}>
          <CloseCircleIcon />
        </IconButton>
      ),
    },
  ])

  return {
    columns: columns.current,
  }
}

import { BANKS_EDIT_URL, BANKS_NEW_URL, BANKS_URL, BANK_URL } from 'constants/routes'
import AddNewBankPage from 'pages/AddNewBank'
import BankListPage from 'pages/BankList'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ViewBank from './ViewBank'

const BanksContainer = () => {
  return (
    <Switch>
      <Route path={BANKS_URL} exact component={BankListPage} />
      <Route path={[BANKS_NEW_URL, BANKS_EDIT_URL]} exact component={AddNewBankPage} />
      <Route path={BANK_URL} exact component={ViewBank} />
    </Switch>
  )
}

export default BanksContainer

import { IClientListItem } from 'typescript/interfaces/clients'
import { BankAccountTypeEnum } from 'typescript/interfaces/bankAccountType'
import { IVendorEntity } from 'typescript/interfaces/vendor'
import { ISyndicatorDetailEntity } from 'typescript/interfaces/syndicators'
import { IIsoEntity } from 'typescript/interfaces/iso'
import { IRepEntity } from 'typescript/interfaces/rep'

export interface IUserLight {
  id: number
  email: string
}

export interface IUser {
  id: number
  email: string
  firstName: string
  lastName: string
  phone: string
  isActive: boolean
  userRoles: IRoleLight[]
}

export interface IUserFormValues {
  id?: number | string
  firstName: string
  lastName: string
  phone: string
  isActive: boolean
}

export interface IRoleLight {
  id: number
  name: string
}
export interface IRole {
  id: number
  name: string
  isUndeletable?: boolean
}

export interface ICreateRoleForm {
  name: string
  isUndeletable: boolean
}

export interface IUpdateUserRolesForm {
  roles: IRoleLight[]
}

export interface ISetUserRoles {
  userId: number
  roles: IRoleLight[]
}

export interface IActionUser {
  type: string
  payload: any
  [key: string]: any
}

export interface IUserContextStore {
  user: IUserEntity | undefined
  permissions: Array<string>
  roles: {
    isAdmin: boolean
    isISO: boolean
    isRep: boolean
    isSyndicator: boolean
    isCollector: boolean
    isFunder: boolean
    isAssistant: boolean
    isClient: boolean
  }
  client: IClientListItem | undefined
  vendor?: IVendorEntity
  syndicator?: ISyndicatorDetailEntity
  iso?: IIsoEntity
  rep?: IRepEntity
}

export interface IUserEntity {
  id: number
  email: string
  firstName: string
  lastName: string
  phone: string
  isAction: boolean
  userRoles: IRoleLight[]
}

export interface IUserPage {
  id?: string | number
  bankAccount: string
  bankRouting: string
  availableBalance: string | number
  bankAccountType?: BankAccountTypeEnum | ''
  bankName: string
}

export const userPageInitValues: IUserPage = {
  bankAccount: '',
  bankRouting: '',
  availableBalance: '',
  bankName: '',
}

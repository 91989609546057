import { Box, Paper, SvgIcon, Typography } from '@material-ui/core'
import React from 'react'
import { ReactComponent as ArrowRightTopIcon } from 'assets/svg/ArrowRightTopIcon.svg'
import { ReactComponent as ArrowRightBottomIcon } from 'assets/svg/ArrowRightBottomIcon.svg'
import { useRTRSummaryStyles } from './style'

const RTRSummary = () => {
  const classes = useRTRSummaryStyles()
  return (
    <Paper elevation={0} className={classes.rtrSummaryContainer}>
      <Box className={classes.rtrBlock} flex="1" display="flex" flexDirection="column">
        <Box className={classes.goodRtr} flex="1" px="1.5rem" py="2rem" display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box mr="1rem">
              <SvgIcon component={ArrowRightTopIcon} />
            </Box>
            <Typography color="textSecondary">Good RTR</Typography>
          </Box>
          <Typography className={classes.typography} color="textSecondary" variant="h4">
            $ 0
          </Typography>
        </Box>
        <Box flex="1" px="1.5rem" py="2rem" display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box mr="1rem">
              <SvgIcon component={ArrowRightBottomIcon} />
            </Box>
            <Typography color="textSecondary">Bad RTR</Typography>
          </Box>
          <Typography className={classes.typography} color="textSecondary" variant="h4">
            $ 0
          </Typography>
        </Box>
      </Box>
      <Box flex="1" p="2rem">
        <Typography color="textPrimary">Cash Summary</Typography>
        <Typography className={classes.typography} color="textSecondary" variant="h4">
          $ 0
        </Typography>
      </Box>
    </Paper>
  )
}

export default RTRSummary

import React, { FC, useCallback, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ReactComponent as UploadIcon } from 'assets/svg/UploadIcon.svg'
import { TableClientDocuments, useClientDocuments } from 'hooks/useClientDocuments'
import { ClientDocument, IClientEntity, IRemoveFileParams } from 'typescript/interfaces/clients'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { useMutation, useQueryCache } from 'react-query'
import { deleteFileFromClient } from 'services/clients'
import { IUploadedCategoriesKeys } from 'typescript/interfaces/deals'
import { useSnackbar } from 'notistack'
import { AxiosError, AxiosResponse } from 'axios'
import { generatePath, useHistory } from 'react-router-dom'
import { CLIENT_DOCUMENTS } from 'constants/routes'
import { getFile } from 'services/storage'
import { createLinkToDownLoad } from 'utils/downloadFile'

interface IDocumentProps {
  documents: Array<ClientDocument>
  idClient: string
  readOnly: boolean
}

const Documents: FC<IDocumentProps> = ({ documents, idClient, readOnly }) => {
  const queryCache = useQueryCache()
  const { push } = useHistory()
  const [openDeleteFileModal, setOpenDeleteFileModal] = useState<{
    id: number
    name: IUploadedCategoriesKeys
    open: boolean
  }>({
    id: 0,
    name: 'VoidedCheck',
    open: false,
  })
  const { enqueueSnackbar } = useSnackbar()
  const [mutateRemove, { isLoading }] = useMutation(({ id: docId, name }: IRemoveFileParams) => deleteFileFromClient(docId), {
    onSuccess: (_, variables) => {
      enqueueSnackbar('File successfully deleted')
      setOpenDeleteFileModal((prevState) => ({
        ...prevState,
        open: false,
      }))
      const prevClient = queryCache.getQueryData<AxiosResponse<IClientEntity>>(['clientById', idClient])
      queryCache.setQueryData(['clientById', idClient], {
        ...prevClient,
        data: {
          ...prevClient!.data,
          documents: prevClient!.data.documents.filter((o) => o.id.toString() !== variables.id),
        },
      })
    },
    onError: (err: AxiosError) => {
      setOpenDeleteFileModal((prevState) => ({
        ...prevState,
        open: false,
      }))
      if (err.response && err.response.data.description === 'CANT_DELETE_LAST_DOCUMENT')
        enqueueSnackbar('The last file of this type cannot be deleted')
    },
  })

  const handleRemoveUploaded = useCallback(async () => {
    await mutateRemove({ id: `${openDeleteFileModal.id}`, name: openDeleteFileModal.name })
  }, [mutateRemove, openDeleteFileModal.id, openDeleteFileModal.name])

  const handleDownLoadFile = useCallback((url, name) => {
    getFile(url).then((res) => {
      createLinkToDownLoad(res.data, name)
    })
  }, [])

  const { columns } = useClientDocuments({
    deleteFile: (id, name) => {
      setOpenDeleteFileModal({
        id: id,
        name: name,
        open: true,
      })
    },
    downloadFile: handleDownLoadFile,
    readOnly,
  })
  return (
    <Paper elevation={0}>
      <Box px={12} py={8} pt={13}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" color="textSecondary">
            Documents
          </Typography>
          {!readOnly && (
            <Box>
              <Button
                startIcon={<UploadIcon />}
                variant="contained"
                color="primary"
                onClick={() => {
                  push(
                    generatePath(CLIENT_DOCUMENTS, {
                      id: idClient,
                    }),
                  )
                }}
              >
                Upload Documents
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <TableClientDocuments columns={columns} data={documents} />
      </Box>
      <ModalComponent open={openDeleteFileModal.open}>
        <ConfirmationContent
          isLoading={isLoading}
          handleCancel={() =>
            setOpenDeleteFileModal((prevState) => ({
              ...prevState,
              open: false,
            }))
          }
          handleConfirm={() => handleRemoveUploaded()}
          text="Are you sure you want delete file?"
        />
      </ModalComponent>
    </Paper>
  )
}

export default Documents

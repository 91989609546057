import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { TableWithdrawalQueues, useWithdrawalQueuesTable } from 'hooks/useWithdrawalQueuesTable'
import { EnumStatusWithdrawal, SortFieldsWithdrawal } from 'typescript/enums/transactions'
import ModalComponent from 'UI/Modal'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import { useMutation, useQuery, useQueryCache } from 'react-query'
import { changeStatus, getSummaryWithdrawal, getWithdrawal } from 'services/transactions'
import { usePaginationList } from 'hooks/usePaginationList'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { IWithdrawalListItem } from 'typescript/interfaces/transactions'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import TotalBlockDeal from 'components/FundingQues/TotalBlockDeal'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const initState: {
  open: boolean
  status: EnumStatusWithdrawal
  id: number
} = {
  open: false,
  status: EnumStatusWithdrawal.scheduled,
  id: 0,
}

const WithdrawalQueContainer: FC = () => {
  const additionalStyle = useAdditionalMaterialStyle()
  const queryClient = useQueryCache()
  const [stateStatus, setStateStatus] = useState(initState)
  const { pagination, setPagination, handleSort, handleFetchMore } = usePaginationList<IWithdrawalListItem>({
    initState: {
      data: [],
      search: '',
      order: SortFieldsWithdrawal.id,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
      status: EnumStatusWithdrawal.pending,
    },
  })
  const { enqueueSnackbar } = useSnackbar()

  const { data: dataSummary, isLoading: isLoadingSummary } = useQuery(['summaryWithdrawal'], () => getSummaryWithdrawal(), {
    retry: false,
    cacheTime: 0,
  })

  const { columns } = useWithdrawalQueuesTable({
    isQueue: true,
    handleClickChangeStatus: (id, status) => {
      setStateStatus({
        open: true,
        status: status,
        id: id,
      })
    },
  })

  const [changeStatusMut, { isLoading: isLoadingStatusChange }] = useMutation(changeStatus, {
    onSuccess: (data) => {
      enqueueSnackbar(`Status successfully changed to ${stateStatus.status}`)
      const { id } = stateStatus
      setPagination((prevState) => ({
        ...prevState,
        data: prevState.data.filter((d) => d.id !== id),
      }))
      setStateStatus(initState)
      queryClient.fetchQuery(['summaryWithdrawal'], getSummaryWithdrawal, {
        retry: false,
        cacheTime: 0,
      })
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [getWithdrawalMut, { isLoading }] = useMutation(getWithdrawal, {
    onSuccess: (data) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...data.data],
        hasMore: data.data.length === 20,
      }))
    },
  })

  const handleChangeActiveStatus = useCallback((status: string) => {
    setPagination((prevState) => ({
      ...prevState,
      page: 0,
      data: status !== prevState.status ? [] : prevState.data,
      status: status,
    }))
  }, [])

  const handleCancel = useCallback(() => {
    setStateStatus(initState)
  }, [])

  const handleConfirmTransactionStatus = useCallback(() => {
    changeStatusMut({
      id: stateStatus.id,
      status: stateStatus.status,
    })
  }, [changeStatusMut, stateStatus.id, stateStatus.status])

  useEffect(() => {
    getWithdrawalMut({
      PageSize: 20,
      PageIndex: pagination.page,
      SortField: pagination.order,
      SortOrder: pagination.sortOrder,
      Status: pagination.status,
    })
  }, [pagination.page, pagination.order, pagination.sortOrder, pagination.status])

  const processingItem = useMemo(() => {
    return dataSummary ? dataSummary.data.find((item) => item.statusName === EnumStatusWithdrawal.scheduled) : undefined
  }, [dataSummary])

  const pendingItem = useMemo(() => {
    return dataSummary ? dataSummary.data.find((item) => item.statusName === EnumStatusWithdrawal.pending) : undefined
  }, [dataSummary])

  const declinedItem = useMemo(() => {
    return dataSummary ? dataSummary.data.find((item) => item.statusName === EnumStatusWithdrawal.rejected) : undefined
  }, [dataSummary])

  return (
    <>
      <Paper className={additionalStyle.wrapper} elevation={0}>
        <Box p="3rem">
          <Typography variant="h3" color="textSecondary">
            Withdrawals
          </Typography>
          <Box mt={8}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                {isLoadingSummary ? (
                  <Skeleton height={150} />
                ) : (
                  <TotalBlockDeal
                    setActiveStatus={handleChangeActiveStatus}
                    label={EnumStatusWithdrawal.pending}
                    count={pendingItem ? pendingItem.transactionsCount : 0}
                    total={pendingItem ? pendingItem.amountSum : 0}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {isLoadingSummary ? (
                  <Skeleton height={150} />
                ) : (
                  <TotalBlockDeal
                    setActiveStatus={handleChangeActiveStatus}
                    label={EnumStatusWithdrawal.scheduled}
                    count={processingItem ? processingItem.transactionsCount : 0}
                    total={processingItem ? processingItem.amountSum : 0}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {isLoadingSummary ? (
                  <Skeleton height={150} />
                ) : (
                  <TotalBlockDeal
                    setActiveStatus={handleChangeActiveStatus}
                    label={EnumStatusWithdrawal.rejected}
                    count={declinedItem ? declinedItem.transactionsCount : 0}
                    total={declinedItem ? declinedItem.amountSum : 0}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <Box className={additionalStyle.wrapper} mt="1rem">
        <Paper elevation={0}>
          <TableWithdrawalQueues
            loading={isLoading}
            hasMore={pagination.hasMore}
            handleSortChange={handleSort}
            handleGetMore={handleFetchMore}
            columns={columns}
            data={pagination.data}
          />
          <ModalComponent open={stateStatus.open}>
            <ConfirmationContent
              text={`Are you sure you want to change status to ${stateStatus.status}?`}
              isLoading={isLoadingStatusChange}
              handleCancel={handleCancel}
              handleConfirm={handleConfirmTransactionStatus}
            />
          </ModalComponent>
        </Paper>
      </Box>
    </>
  )
}

export default WithdrawalQueContainer

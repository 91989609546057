import { makeStyles } from '@material-ui/core/styles'

export const useSignUpStyles = makeStyles((theme) => ({
  text: {
    fontSize: 48,
    marginBottom: 36,
    color: '#4C5878',
  },
  smallText: {
    marginTop: 32,
    fontSize: 19,
  },
  link: {
    color: '#249AF3',
    textDecoration: 'none',
    fontSize: 19,
  },
}))

import { Box, Button, Chip, fade, IconButton, makeStyles, Paper, Typography, useTheme } from '@material-ui/core'
import React, { useContext, useRef, useState } from 'react'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteCircleIcon.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import TableComponent, { TableComponentType } from 'UI/Table'
import { deleteDeal, getDeal, submitDeal } from 'services/deals'
import { useMutation, useQuery } from 'react-query'
import { IInhouseDealResponse } from 'typescript/interfaces/deals'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { BASE_APP_URL, DEAL_INFO_URL, NEW_DEAL_URL } from 'constants/routes'
import ModalComponent from 'UI/Modal'
import cn from 'classnames'
import { useSnackbar } from 'notistack'
import { validateAllDocuments } from 'utils/validateAllDocuments'
import { IColumn } from 'typescript/interfaces/tableUi'
import { DealsStatus } from 'typescript/enums/deals'
import { AxiosError } from 'axios'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { CompanyType } from 'typescript/interfaces/companies'
import { DEAL_DELETE_PERM, DEAL_EDIT_PERM } from 'constants/permissions'
import { usePermission } from 'hooks/usePermission'
import { UserContext } from 'contexts/userContext'

const TableDeals = TableComponent as TableComponentType<IInhouseDealResponse>

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '1.625rem',
    marginBottom: '2rem',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    padding: '3rem',
    paddingBottom: 0,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: '1.5rem',
  },
  chip: {
    borderRadius: '0.5rem',
    border: `1px solid ${theme.palette.secondary.main}`,
    background: fade(theme.palette.secondary.main, 0.05),

    fontSize: '1.0625rem',
    color: theme.palette.secondary.main,
  },
  rejected: {
    border: `1px solid ${theme.palette.error.main}`,
    background: fade(theme.palette.error.main, 0.05),
    color: theme.palette.error.main,
  },
  approved: {
    background: fade(theme.palette.primary.main, 0.05),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  button: {
    marginLeft: '3rem',
    background: theme.palette.info.light,
  },
  icon: {
    '& path': {
      fill: theme.palette.info.contrastText,
    },
  },
  businessName: {
    width: '40%',
  },
}))

const CompletingForm = () => {
  const { state } = useContext(UserContext)
  const { hasPermission } = usePermission()
  const s = useStyles()
  const theme = useTheme()
  const { id, type } = useParams<{ id: string; type: string }>()
  const [openDeleteDeal, setOpenDeleteDeal] = useState(false)
  const { push, goBack } = useHistory()
  const snack = useSnackbar()
  const { data } = useQuery(['inhouse-deal', id], () => getDeal(id))

  const [handleDelete, { isLoading: deleteIsLoading }] = useMutation(() => deleteDeal(id), {
    onSuccess(res) {
      push(BASE_APP_URL)
      snack.enqueueSnackbar(<Typography>Successfully deleted deal</Typography>)
    },
    onError() {
      snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [submit] = useMutation(() => submitDeal(+id), {
    onSuccess() {
      push(
        generatePath(DEAL_INFO_URL, {
          id: id,
        }),
      )
      snack.enqueueSnackbar(<Typography>Successfully changed status</Typography>)
    },
    onError(err: AxiosError) {
      if (
        err.response &&
        (err.response.data.description === 'INVALID_LENDING_PERCENTAGE' || err.response.data.description === 'LEAD_LENDER_NOT_FOUND')
      )
        snack.enqueueSnackbar('The Percentage should be equal 100% and at least one main lead should be set')
      else if (err.response?.data.description === 'INVALID_FUND_AMOUNT')
        snack.enqueueSnackbar('Funds are not available for this syndicator for this amount. Please adjust amount or select a different syndicator')
      else snack.enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleChangeStatusSubmit = (dataRow: IInhouseDealResponse) => {
    if (validateAllDocuments(dataRow.documents)) {
      submit()
    } else {
      snack.enqueueSnackbar(
        <Typography>
          You need to upload all required documents
          <Button
            onClick={() =>
              push(
                generatePath(NEW_DEAL_URL, {
                  id: dataRow.id,
                  view: 'edit',
                  step: '2',
                  ...(type === 'inhouse' && { type: 'inhouse' }),
                  ...(type === 'client-deal' && { type: 'client-deal' }),
                }),
              )
            }
          >
            Upload
          </Button>
        </Typography>,
      )
    }
  }

  const columnsRef = useRef<Array<IColumn<IInhouseDealResponse>>>([
    {
      label: '#',
      field: 'id',
      cellStyle: {
        fontSize: '0.875rem',
        color: theme.palette.secondary.dark,
      },
    },
    {
      label: 'Business',
      field: 'businessName',
      cellStyle: {
        fontSize: '1.1875rem',
        color: theme.palette.text.secondary,
      },
      headerCellClassName: s.businessName,
      render: (dataRow: IInhouseDealResponse) => {
        return <Typography>{dataRow.client?.businessName}</Typography>
      },
    },
    {
      label: 'Review',
      field: 'status',
      cellStyle: {
        color: theme.palette.text.secondary,
      },
      render: (dataRow: IInhouseDealResponse) => {
        if (dataRow.status === DealsStatus.new) {
          return <Chip label="New" />
        }
        if (dataRow.status === DealsStatus.inUnderwriting) {
          return <Chip className={s.chip} label="InUnderwriting" />
        }
        if (dataRow.status === DealsStatus.readyToFund) {
          return <Chip className={s.chip} label="ReadyToFund" />
        }
        if (dataRow.status === DealsStatus.declined) {
          return <Chip className={cn(s.chip, s.rejected)} label="Declined" />
        }
        if (dataRow.status === DealsStatus.approved) {
          return <Chip className={cn(s.chip, s.approved)} label="Approved" />
        }
      },
    },
    ...(type === 'inhouse'
      ? [
          {
            label: 'Submit',
            field: 'submit',
            cellStyle: {
              width: '5rem',
            },
            render: (dataRow: IInhouseDealResponse) => {
              return !dataRow.isSubmitted ? (
                <Button onClick={() => handleChangeStatusSubmit(dataRow)} color="primary" variant="contained">
                  Submit
                </Button>
              ) : (
                <Button variant="contained" disabled className={s.button}>
                  Submitted
                </Button>
              )
            },
          },
        ]
      : []),
    {
      label: 'Edit',
      field: 'edit',
      cellStyle: {
        width: '5rem',
      },
      render: (dataRow: IInhouseDealResponse) => {
        const dealIsNotEditable =
          ((dataRow.status === DealsStatus.approved || dataRow.status === DealsStatus.declined) && !state.roles.isAdmin) ||
          !hasPermission(DEAL_EDIT_PERM)
        return (
          <IconButton
            disabled={dealIsNotEditable}
            onClick={() =>
              push(
                generatePath(NEW_DEAL_URL, {
                  id: dataRow.id,
                  view: 'edit',
                  step: '1',
                  ...(type === 'inhouse' && { type: 'inhouse' }),
                  ...(type === 'client-deal' && { type: 'client-deal' }),
                }),
              )
            }
          >
            <EditIcon />
          </IconButton>
        )
      },
    },
    {
      label: 'Delete',
      field: 'delete',
      cellStyle: {
        width: '5rem',
      },
      render: (dataRow: IInhouseDealResponse) => {
        const disableDelete =
          (dataRow.status !== DealsStatus.new &&
            dataRow.status !== DealsStatus.inUnderwriting &&
            dataRow.status !== DealsStatus.readyToFund &&
            dataRow.status !== DealsStatus.outhouseApproved) ||
          !hasPermission(DEAL_DELETE_PERM)
        return (
          <IconButton disabled={disableDelete} onClick={() => setOpenDeleteDeal(true)}>
            <DeleteIcon />
          </IconButton>
        )
      },
    },
  ])

  if (data && data.data.type === CompanyType.OutHouse)
    push(
      generatePath(DEAL_INFO_URL, {
        id: id,
      }),
    )

  return (
    <Box pb="2rem">
      <Paper className={s.paper}>
        <Typography className={s.sectionTitle}>Deals</Typography>

        <TableDeals columns={columnsRef.current} data={data ? [data!.data] : []} />
      </Paper>
      <Button variant="contained" onClick={goBack} startIcon={<ArrowLeftIcon className={s.icon} />} className={s.button}>
        Back
      </Button>

      <ModalComponent open={openDeleteDeal} onClose={() => setOpenDeleteDeal(false)}>
        <Box>
          <Box>
            <Typography variant="h3" color="textSecondary">
              Are you sure you want to delete this deal?
            </Typography>
          </Box>
          <Box mt="2rem" display="flex" alignItems="center" justifyContent="flex-end">
            <Button onClick={() => setOpenDeleteDeal(false)} disabled={deleteIsLoading} color="primary" variant="text">
              Cancel
            </Button>

            <Box ml="2rem">
              <Button onClick={() => handleDelete()} disabled={deleteIsLoading} color="primary" variant="contained">
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalComponent>
    </Box>
  )
}

export default CompletingForm

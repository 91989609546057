import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper/Paper'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import DocumentsForm from 'components/VendorForm/DocumentsForm'
import { VENDOR_INFO_URL } from 'constants/routes'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import React, { FC, useCallback } from 'react'
import { useMutation } from 'react-query'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { uploadVendorDocuments } from 'services/vendor'
import {
  IInitVendorDocsValues,
  IUploadVendorDocWithDocType,
  IUploadedVendorCategoriesKeys,
  IVendorDocumentToSend,
} from 'typescript/interfaces/vendor'
import { useNewVendorStyle } from './style'

const DocumentsContainer: FC = () => {
  const classes = useNewVendorStyle()
  const additionalStyle = useAdditionalMaterialStyle()
  const { push, goBack, replace } = useHistory()
  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const backUrl = params.get('backUrl')

  const [mutate, { isLoading }] = useMutation((docs: IUploadVendorDocWithDocType[]) => uploadVendorDocuments(docs, +id), {
    onSuccess() {
      push(generatePath(VENDOR_INFO_URL, { id }))
    },
  })

  const handleSubmit = useCallback(
    async (formValues: IInitVendorDocsValues) => {
      const documentsToSend: IVendorDocumentToSend[] = []

      // eslint-disable-next-line guard-for-in
      for (const category in formValues.documents) {
        formValues.documents[category as IUploadedVendorCategoriesKeys].forEach((f) => {
          documentsToSend.push({ file: f, documentType: category as IUploadedVendorCategoriesKeys })
        })
      }
      await mutate(documentsToSend)
    },
    [mutate],
  )

  return (
    <Box className={additionalStyle.wrapper}>
      <Box display="flex" alignItems="center">
        <Box ml="3rem" mr="1rem">
          <IconButton onClick={backUrl ? () => replace(backUrl) : goBack} color="inherit">
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Typography variant="h1" color="textSecondary">
          Vendor Documents
        </Typography>
      </Box>
      <Box mt="2rem">
        <Paper elevation={0} className={classes.paper}>
          <DocumentsForm
            loading={isLoading}
            back={{
              label: 'Back',
              handler: backUrl ? () => replace(backUrl) : goBack,
            }}
            submit={{
              label: 'Complete',
              handler: handleSubmit,
            }}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default DocumentsContainer

import React, { useRef } from 'react'
import { IColumn } from 'typescript/interfaces/tableUi'
import { IOwnerEntity } from 'typescript/interfaces/owners'
import TableComponent, { TableComponentType } from 'UI/Table'
import { useTableComponentStyle } from 'UI/Table/style'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteCircleIcon.svg'
import { useHistory } from 'react-router-dom'
import { EDIT_OWNER_URL } from 'constants/routes'
import { convertDate } from 'utils/formatters'

export const TableOwners = TableComponent as TableComponentType<IOwnerEntity>

interface IUseOwnersProps {
  idClient: string
  handleOpenDeleteModal: (id: string | number) => void
  readOnly: boolean
}

export const useOwners = (props: IUseOwnersProps) => {
  const classes = useTableComponentStyle()
  const { push } = useHistory()
  const columns = useRef<Array<IColumn<IOwnerEntity>>>([
    {
      field: 'id',
      label: '#',
      bodyCellClassName: classes.accentCell,
    },
    {
      field: 'name',
      label: 'Name',
      bodyCellClassName: classes.accentCell,
      render: (data) => `${data.firstName} ${data.lastName}`,
    },
    {
      field: 'ownershipDate',
      label: 'Ownership Date',
      bodyCellClassName: classes.secondaryBodyCell,
      render: (data) => convertDate(data.ownershipDate),
    },
    {
      field: 'title',
      label: 'Title',
      bodyCellClassName: classes.accentCell,
      render: (data) => data.title,
    },
    {
      field: 'ownershipPercent',
      label: 'Ownership (%)',
      bodyCellClassName: classes.accentCell,
      render: (data) => `${data.ownership}%`,
    },
    ...(!props.readOnly
      ? [
          {
            field: 'edit',
            label: 'Edit',
            render: (data: IOwnerEntity) => (
              <IconButton
                size="small"
                onClick={() => push(EDIT_OWNER_URL.replace(':id(\\d+)', props.idClient).replace(':idOwner(\\d+)', data.id.toString()))}
              >
                <EditIcon />
              </IconButton>
            ),
          },
        ]
      : []),
    ...(!props.readOnly
      ? [
          {
            field: 'delete',
            label: 'Delete',
            render: (data: IOwnerEntity) => (
              <IconButton size="small" onClick={() => props.handleOpenDeleteModal(data.id)}>
                <DeleteIcon />
              </IconButton>
            ),
          },
        ]
      : []),
  ])

  return {
    columns: columns.current,
  }
}
